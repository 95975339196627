import NetworkManager from '../../../NetworkManager';

const DeleteLessonList = (eID: string, startDate: string, endDate: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager(
      'get',
      `admin/lessonAvailability/fetchReservations?employeeID=${eID}&startDate=${startDate}&endDate=${endDate}`,
    )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default DeleteLessonList;
