import styled from 'styled-components';
import SelectDate from 'react-datepicker';
import * as color from '../../color/index';
import { darken } from 'polished';
import { BreakPoints } from '../../GlobalUtilityStyles';

/*********************************
  * 예약스케쥴 삭제 공통 스타일
  - 타석 예약 스케쥴 삭제 (modals/reservation/ReservationDelete/)
  - 타석 예약 스케쥴 삭제 (component/reservation/Delete/)
  - 코치 예약 스케쥴 삭제
***********************************/
export const Container = styled.div`
  width: 398px;
`;

export const WarningMessage = styled.p`
  color: ${color.Colors.red};
  margin-top: 10px;
  font-weight: bold;
`;

export const DateBox = styled.div`
  display: flex;
  // width: 398px;
  margin-bottom: 24px;
  justify-content: space-between;
  color: ${color.Colors.primary};

  ${BreakPoints.sm} {
    flex-wrap: wrap;
  }
`;

export const DateItem = styled.div`
  display: flex;
`;

export const DateName = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 40px;
`;

export const CustomDate = styled(SelectDate)`
  width: 100px;
  border: 2px solid ${color.Colors.line};
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  color: ${color.Colors.primary};
  padding: 11px 0 11px 7px;
  margin-left: 24px;
`;

export const Line = styled.div`
  height: 1px;
  background: ${color.Colors.line};
  margin: 19.5px 0 19.5px 0;
`;

export const BottomTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SearchButton = styled.button`
  width: 120px;
  height: 40px;
  margin-left: 30px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  outline: 0;
  transition: 0.35s;

  &:hover {
    background: ${darken(0.1, '#F9F9F9')};
    transition: 0.35s;
  }
`;

export const ReservationUserList = styled.div`
  min-height: 180px;
  margin: 12px 0 12px;
  overflow-y: auto;
  ${BreakPoints.sm} {
    height: auto;
  }
`;

export const ReservationUser = styled.div`
  border-bottom: 1px solid ${color.Colors.line};
  font-size: 16px;
  color: #4a5057;
`;

export const ReservationUserWrapper = styled.div`
  display: flex;
  padding: 12px 16px 12px 16px;
  justify-content: space-between;
  white-space: nowrap;
  line-height: 1.5;
  flex-wrap: wrap;

  ${BreakPoints.sm} {
    padding: 12px 5px 12px 5px;
  }
`;

export const UserName = styled.div`
  flex-grow: 1;
  width: 25%;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

export const ReservationDate = styled.div`
  flex-grow: 1;
`;

export const ReservationTime = styled.div`
  flex-grow: 1;
  ${BreakPoints.xs} {
    text-align: right;
  }
`;

export const ReservationCancel = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: ${color.Colors.red};
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
  ${BreakPoints.xs} {
    margin-left: auto;
  }
`;

export const NoReservationText = styled.div`
  height: 20px;
  font-size: 16px;
  color: #4a5057;
  text-align: center;
  margin-top: 80px;
`;

export const WarningText = styled.div`
  height: 20px;
  font-size: 16px;
  color: #fa5252;
  text-align: center;
  margin-bottom: 12px;
`;
