import React, { useState, ChangeEvent } from 'react';
import * as Styled from './styles';
import { GOButton, GOFloatingbox, GOInput } from '../../../components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { phoneNumberFormatting } from '../../../middlewares';
import { actionPostSMSMessage } from '../../../stores/actions';

interface smsProps {
  data?: any;
  onClickExit?: () => void;
}

const SMS = (props: smsProps) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const [SMS, setSMS] = useState<any>({
    title: '',
    body: '',
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'title') {
      setSMS({
        ...SMS,
        [name]: value,
      });
      if (value.length > 15) {
        alert('15자 이내에 작성해주세요.');
        setSMS({
          ...SMS,
          [name]: value.substring(0, 15),
        });
      }
    } else {
      setSMS({
        ...SMS,
        [name]: value,
      });
      if (value.length > 1000) {
        alert('1000자 이내에 작성해주세요.');
        setSMS({
          ...SMS,
          [name]: value.substring(0, 1000),
        });
      }
    }
  };

  const onSubmit = (v: any) => {
    const data = props.data.check;

    if (window.confirm('문자 메시지를 발송하시겠습니까?')) {
      dispatch(
        actionPostSMSMessage({ userIDs: data, ...v }, () => {
          if (props.onClickExit) props.onClickExit();
        }),
      );
    }
  };

  return (
    <GOFloatingbox>
      <Styled.Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3>문자 메시지</h3>
          <br />
          <Styled.UserList>
            <div className="label">받는사람</div>
            <div className="userBox">
              {props.data && (
                <>
                  <div className="users">
                    {props.data.checkUserData[0].name} /{' '}
                    {phoneNumberFormatting(props.data.checkUserData[0].phoneNumber)}
                  </div>
                  {props.data.checkUserData.length > 1 && (
                    <span>외 {props.data.checkUserData.length - 1}명</span>
                  )}
                </>
              )}
            </div>
          </Styled.UserList>
          <GOInput
            label={t('common.title')}
            name="title"
            rref={register}
            onChange={handleChange}
            value={SMS.title}
            required
          />
          <GOInput
            label={t('common.body')}
            name="body"
            rref={register}
            onChange={handleChange}
            value={SMS.body}
            textarea
            large
            required
          />
          <Styled.Bottom>
            <GOButton body="보내기" buttontype="green" type="submit" />
            <GOButton
              body="닫기"
              onClick={() => {
                if (props.onClickExit) {
                  props.onClickExit();
                }
              }}
            />
          </Styled.Bottom>
        </form>
      </Styled.Container>
    </GOFloatingbox>
  );
};

export default SMS;
