import React from 'react';
import * as Styled from '../chartAreaStyles';
import { GOLoading } from '../..';
import MonthlyAccountingSex from '../Convert/MonthlyAccounting/sex';
import MonthlyAccountingAge from '../Convert/MonthlyAccounting/age';
import CurrentMonthlyGenderChart from '../Chart/CurrentMonthlySales/Gender';
import CurrentMonthlyAgeChart from '../Chart/CurrentMonthlySales/Age';

const MonthlyAccountingChart = ({ loading, sex, age }: any) => {
  const sData = MonthlyAccountingSex(sex);
  const aData = MonthlyAccountingAge(age);

  const genderData = {
    labels: [`남성`, `여성`],
    datasets: [
      {
        label: '성별',
        data: [sData?.maleAmount, sData?.femaleAmount],
        backgroundColor: ['#6AA8E2', '#F5C4DE'],
        hoverOffset: 4,
      },
    ],
  };

  const ageData = {
    labels: ['20대 미만', '30대', '40대', '50대', '60대 이상'],
    datasets: [
      {
        label: '연령',
        data: [aData.amount20s, aData.amount30s, aData.amount40s, aData.amount50s, aData.amount60s],
        backgroundColor: ['#D196EE', '#7465E7', '#2F76BA', '#204D80', '#173562'],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <Styled.Container>
      {loading ? (
        <GOLoading />
      ) : sex?.length > 0 && age?.length ? (
        <Styled.FlexGroup>
          <CurrentMonthlyGenderChart label="성별" data={genderData} />
          <CurrentMonthlyAgeChart label="연령" data={ageData} />
        </Styled.FlexGroup>
      ) : (
        <>아직 데이터가 부족합니다!</>
      )}
    </Styled.Container>
  );
};

export default MonthlyAccountingChart;
