import NetworkManager from "../../../NetworkManager";

const List = (pID: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager("delete", `admin/lessonAvailability/${pID}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default List;
