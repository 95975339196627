import NetworkManager from '../../../NetworkManager';
import { toast } from 'react-toastify';

const Add = (data: any) => {
  return new Promise((resolve, reject) => {
    NetworkManager('post', 'admin/plateAvailability/createPlateAvailability', data)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        if (err.response) {
          switch (err.response.status) {
            case 406:
              toast.error(`⛔️ 이미 동일한 시간에 예약이 존재합니다 ( 406 )`);
          }
        }
        reject(err);
      });
  });
};

export default Add;
