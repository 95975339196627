import React, { forwardRef, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import GOButton from '../GOButton';
import ko from 'date-fns/locale/ko';

registerLocale('ko', ko);
setDefaultLocale('ko');

interface GODateProps {
  onChange: any;
  selected?: any | Date;
  name?: string;
  label?: string;
  button?: string;
  onClickButton?: any;
  time?: boolean;
  timeOnly?: boolean;
  startDate?: any;
  endDate?: any;
  required?: boolean;
  dataFormat?: string;
  maxDate?: any;
  minDate?: any;
  minTime?: any; //최소 선택 시간 표시
  maxTime?: any;
  placeholder?: any;
  value?: any;
  disabled?: any;
  timeInterval?: any;
  onBlur?: any;
  ref?: any;
}

// const GODate = (props: GODateProps) => {
  const GODate = forwardRef<DatePicker, GODateProps>((props: GODateProps, ref) => {
  return (
    <div className="GOInput">
      {props.label && <div className="GOInput_label">{props.label}</div>}
      <DatePicker
        locale="ko"
        ref={ref}
        name={props.name}
        {...props}
        startDate={props.startDate}
        endDate={props.endDate}
        selected={props.selected}
        onChange={props.onChange}
        showTimeSelect={props.time}
        showTimeSelectOnly={props.timeOnly}
        timeIntervals={props.timeInterval ? props.timeInterval : 10}
        dateFormat={
          props.dataFormat
            ? props.dataFormat
            : props.time
            ? `yyyy. MM. dd - aa h:mm`
            : `yyyy. MM. dd`
        }
        required={props.required}
        placeholderText={props.placeholder}
        disabled={props.disabled}
      />
      {props.button && (
        <GOButton
          body={props.button}
          buttontype="primary"
          size="small"
          onClick={props.onClickButton}
        />
      )}
    </div>
  );
});

export default GODate;
