import styled from 'styled-components';
import * as color from '../../../color/index';
import { darken } from 'polished';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const MemberInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 30px 15px;
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;

  ${BreakPoints.md} {
    padding: 15px 0;
  }

  ${BreakPoints.sm} {
    justify-content: center;
  }
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;

  ${BreakPoints.md} {
    margin-right: 0;
    padding: 0px 10px;
  }

  ${BreakPoints.sm} {
    padding: 0px;
  }

  .golfPowerRanking {
    font-size: 14px;
    font-weight: bold;
    margin: 8px 0;
    align-items: center;
    border-radius: 30px;
    background: #73ad21;
    color: white;
    padding: 10px;
  }
  .scoreRanking {
    font-size: 14px;
    font-weight: bold;
    margin-top: 0;
    align-items: center;
    border-radius: 30px;
    background: #73ad21;
    color: white;
    padding: 10px;
  }
`;

export const ProfileImage = styled.div`
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
  background: ${color.Colors.sub};
  border-radius: 120px;
`;

export const DetailText = styled.div`
  margin: 10px 0;
  color: ${color.Colors.primary};
  font-weight: bold;
  line-height: 2;
  .name {
    font-size: 24px;
  }
  .birth {
    font-size: 16px;
  }
  .sex {
    font-size: 18px;
  }
  .normal {
    font-size: 16px;
  }

  ${BreakPoints.md} {
    padding: 0 10px;
  }

  ${BreakPoints.sm} {
    // padding: 0;
  }
`;

export const TrainingRatePie = styled.div`
  width: 300px;
  margin-left: auto;

  ${BreakPoints.md} {
    width: 250px;
  }

  ${BreakPoints.sm} {
    width: 100%;
    max-width: 300px;
    margin: 10px auto 0;
  }
`;

export const ResetButton = styled.button`
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  margin-left: 50px;
  color: ${color.Colors.primary};
  font-size: 12px;
  font-weight: bold;
  background: ${color.Colors.background};
  padding: 5px 7px;
  cursor: pointer;
  transition: 0.35s;
  outline: none;

  &:hover {
    background: ${darken(0.05, `${color.Colors.background}`)};
    transition: 0.35s;
  }
`;

export const UncheckedWrapper = styled.div`
  height: 530px;
  flex: 1 1 auto;
  overflow: auto;
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .trainingManagementBoxContainer {
    display: flex;
    justify-content: center;
  }

  .firstBoxContainer {
    display: flex;
    justify-content: center;

    .profileBoxContainer {
      display: flex;
      justify-content: center;
    }

    .memberInfoBoxContainer {
      display: flex;
      justify-content: center;
    }

    .trainingRateInfoBoxContainer {
      display: flex;
      justify-content: center;
    }

    @media (max-width: 1800px) {
      margin-bottom: 10px;
    }
  }

  .secondBoxContainer {
    display: flex;
  }

  .thirdBoxContainer {
    display: flex;
  }

  .forthBoxContainer {
    display: flex;
  }

  .fifthBoxContainer {
    display: flex;
  }
`;

export const InnerContainer = styled.div`
  // border: 1px solid red;
  // padding: 0 40px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-weight: bold;
  }
  .checkboxContainer {
    display: flex;
    color: ${color.Colors.primary};
    font-size: 14px;

    .checkboxWrapper {
      display: flex;
      align-items: center;

      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
`;

export const EmptyDataText = styled.div`
  text-align: center;
  padding: 200px 0;
  font-weight: bold;
  color: ${color.Colors.line};
`;

export const BlockedTableDiv = styled.div`
  display: block;
  max-height: 600px;
  height: 600px;
  overflow-y: scroll;
`;
