import React from 'react';
import * as Styled from './styles';

interface HomePanelProps {
  icon?: string;
  body: string;
  onClick?: any;
}

const HomePanel = (props: HomePanelProps) => {
  return (
    <Styled.HomePanel>
      <Styled.PanelIcon
        style={{
          background: `url(${props.icon}) center / contain no-repeat`,
          width: 38,
          height: 34,
        }}
      />
      <div>{props.body}</div>
    </Styled.HomePanel>
  );
};

export default HomePanel;
