import React, { useState, useContext, FormEvent } from 'react';
import GOFloatingbox from '../../common/GOFloatingbox';
import { useTranslation } from 'react-i18next';
import GOButton from '../../common/GOButton';
import GODate from '../../common/GODate';
import GOInput from '../../common/GOInput';
import { Context } from '../../../stores';
import dayjs from 'dayjs';
import { NetLessonAdd } from '../../../actions';
import GODropbox from '../../common/GODropbox';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

interface PersonalAddProps {
  onClickExit?: any;
  update?: any;
  now: any;
  lessonProList?: any;
  onChange?: any;
  value?: any;
}

const PersonalAdd = (props: PersonalAddProps) => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>({
    employeeID: props.now,
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs()
      // .add(1, 'month') feature/20211104/changePwd
      .add(20, 'day')
      .format('YYYY-MM-DD'),
    memo: null,
  });
  const Store = useContext(Context);
  const sending = Store.sending;

  const handleSubmit = (e: FormEvent<EventTarget>) => {
    if (data.employeeID === undefined) alert('레슨프로를 선택해주세요');
    else {
      Store.actions.setSending(true);
      NetLessonAdd({
        ...data,
        startDate: dayjs(data.startDate).format('YYYY-MM-DDTHH:mm:ss'),
        endDate: dayjs(data.endDate).format('YYYY-MM-DDTHH:mm:ss'),
      })
        .then((res: any) => {
          alert('생성이 완료되었습니다.');
          Store.actions.setSending(false);
          if (props.update) {
            props.update();
          }
          if (props.onClickExit) {
            props.onClickExit();
          }
          if (props.onChange) {
            props.onChange(data.employeeID);
          }
        })
        .catch(() => {
          Store.actions.setSending(false);
        });
    }
  };

  return (
    <>
      <GOFloatingbox width={420}>
        <UFloatingContainer>
          <UFloatingBoxHeader>
            <h1>{t('reservation.personalAdd')}</h1>
            <p>{t('reservation.personalAddInfo')}</p>
          </UFloatingBoxHeader>
          <UFloatingBoxBody>
            <div className="flex_box">
              <GODropbox
                label="레슨프로"
                data={props.lessonProList?.map((e: any) => {
                  return { value: e.id, body: e.name };
                })}
                value={data.employeeID}
                onChange={(e: any) => setData({ ...data, employeeID: parseInt(e.target.value) })}
                isDefault
                required
              />
              <GODate
                label={t('reservation.startDate')}
                selected={new Date(data.startDate)}
                onChange={(date: any) => {
                  setData({ ...data, startDate: date });
                }}
                required
              />
              <GODate
                label={t('reservation.endDate')}
                minDate={new Date(data.startDate)}
                selected={new Date(data.endDate)}
                onChange={(date: any) => {
                  setData({ ...data, endDate: date });
                }}
                required
              />
              <GOInput
                label="메모"
                value={data.memo}
                onChange={(e: any) => setData({ ...data, memo: e.target.value })}
                textarea
              />
            </div>
          </UFloatingBoxBody>
          <UFloatingBoxFooter>
            <GOButton
              buttontype="green"
              onClick={handleSubmit}
              body={t('common.add')}
              loading={sending}
              disabled={sending}
            />
            <GOButton body={t('common.no')} onClick={props.onClickExit} disabled={sending} />
          </UFloatingBoxFooter>
        </UFloatingContainer>
      </GOFloatingbox>
    </>
  );
};

export default PersonalAdd;
