import React, { useState, useEffect } from 'react';
import { NetSmartAdvisorMemberConfiguration } from '../../../actions';
import { SAUserCount } from '../../../components';

type memberConfig = {
  filterType?: number;
};

const MemberConfiguration = ({ filterType }: memberConfig) => {
  const [sex, setSex] = useState<any>();
  const [age, setAge] = useState<any>();
  const [sum, setSum] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const currentConfigurationFetchAPI = async () => {
      // 성별 데이터 호출
      await NetSmartAdvisorMemberConfiguration(0, 'sex').then((res: any) => {
        setSex(res.data.Data.memberList);
      });
      // 연령 데이터 호출
      await NetSmartAdvisorMemberConfiguration(0, 'age').then((res: any) => {
        setAge(res.data.Data.memberList);
      });
      // 성 x 연령 데이터 호출
      await NetSmartAdvisorMemberConfiguration(0, 'both').then((res: any) => {
        setSum(res.data.Data.memberList);
        setLoading(false);
      });
    };

    const totalConfigurationFetchAPI = async () => {
      // 성별 데이터 호출
      await NetSmartAdvisorMemberConfiguration(1, 'sex').then((res: any) => {
        setSex(res.data.Data.memberList);
      });
      // 연령 데이터 호출
      await NetSmartAdvisorMemberConfiguration(1, 'age').then((res: any) => {
        setAge(res.data.Data.memberList);
      });
      // 성 x 연령 데이터 호출
      await NetSmartAdvisorMemberConfiguration(1, 'both').then((res: any) => {
        setSum(res.data.Data.memberList);
        setLoading(false);
      });
    };

    if (filterType === 0) {
      console.log('💠 현재 이용 회원 Network');
      currentConfigurationFetchAPI();
    } else {
      console.log('💠 전체 회원 Network');
      totalConfigurationFetchAPI();
    }
  }, [filterType]);

  return <SAUserCount sex={sex} age={age} sum={sum} loading={loading} />;
};

export default MemberConfiguration;
