import styled, { css } from 'styled-components';
import * as color from '../../../color/index';
import { ContentBodyPadding, BreakPoints } from '../../../GlobalUtilityStyles';

interface LayoutListProps {
  shortTopPadding?: boolean;
}

export const LayoutList = styled.div<LayoutListProps>`
  display: flex;
  padding: ${ContentBodyPadding.xl};
  flex-grow: 1;
  max-width: 1900px;
  width: 100%;
  margin: 0 auto;
  ${BreakPoints.lg} {
    padding: ${ContentBodyPadding.lg};
  }
  ${BreakPoints.md} {
    padding: ${ContentBodyPadding.md};
  }
  ${BreakPoints.sm} {
    flex-wrap: wrap;
    padding: ${ContentBodyPadding.sm};
  }

  ${props =>
    props.shortTopPadding &&
    css`
      padding-top: 10px !important;
    `}
`;

export const LayoutLeftList = styled.div`
  width: 280px;
  flex-shrink: 0;
  background: ${color.Colors.white};
  margin-right: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  height: max-content;
  border: 1px solid ${color.Colors.line};

  ${BreakPoints.sm} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    padding: 15px;
    h4 {
      font-size: 20px;
    }
  }
`;

export const LayoutRightList = styled.div`
  flex: 1;
  box-sizing: border-box;
  background: ${color.Colors.white};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  border: 1px solid ${color.Colors.line};
  padding-bottom: 20px;
  width: calc(100% - 292px);
  ${BreakPoints.sm} {
    width: 100%;
  }
`;
