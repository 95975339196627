import React, { useEffect, useState } from 'react';
import GOFloatingbox from '../../common/GOFloatingbox';
import GOButton from '../../common/GOButton';
import { useTranslation } from 'react-i18next';
import SmallTitle from '../../common/SmallTitle';
import {
  NetLessonDetail,
  NetLessonEdit,
  NetLessonDelete,
  NetReservationDetail,
  NetReservationEdit,
  NetReservationDelete,
} from '../../../actions';
import GOLabel from '../../common/GOLabel';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import GOInput from '../../common/GOInput';
import GOLoading from '../../common/GOLoading';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

interface DetailProps {
  id: string;
  onClickExit?: any;
  update?: any;
  employee?: boolean;
}

// 이용정보보기 - 타석예약 가능시간(팝업)
const Detail = (props: DetailProps) => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  dayjs.locale('ko');

  useEffect(() => {
    if (props.employee) {
      // 개인레슨
      NetLessonDetail(props.id).then((res: any) => {
        setData(res.data.Data.LessonAvailability);
        setLoading(false);
      });
    } else {
      // 그룹레슨
      NetReservationDetail(props.id).then((res: any) => {
        setData(res.data.Data.PlateAvailability);
        setLoading(false);
      });
    }
  }, [props.employee, props.id]);

  const handleSubmit = () => {
    if (props.employee) {
      // 개인레슨
      NetLessonEdit(props.id, data).then((res: any) => {
        alert('수정되었습니다.');
      });
    } else {
      // 그룹레슨
      NetReservationEdit(props.id, data).then((res: any) => {
        alert('수정되었습니다.');
      });
    }
  };

  const onDelete = () => {
    if (props.employee) {
      // 개인레슨
      if (window.confirm(t('reservation.isDel'))) {
        NetLessonDelete(props.id).then((res: any) => {
          props.update();
          props.onClickExit();
        });
      }
    } else {
      // 그룹레슨
      if (window.confirm(t('reservation.isDel'))) {
        NetReservationDelete(props.id).then((res: any) => {
          props.update();
          props.onClickExit();
        });
      }
    }
  };

  if (loading) {
    return (
      <>
        <GOFloatingbox>
          <GOLoading />
        </GOFloatingbox>
      </>
    );
  } else if (props.employee) {
    // 개인레슨
    return (
      <>
        <GOFloatingbox width={750}>
          <UFloatingContainer as="form">
            <UFloatingBoxHeader>
              {/* 예약수정 */}
              <h1>{t('reservation.edit')}</h1>
              <p>{t('reservation.editInfo')}</p>
            </UFloatingBoxHeader>
            <UFloatingBoxBody flex>
              <div className="flex_box">
                <SmallTitle body={t('reservation.personal')} />
                <GOLabel label="레슨 이름" body={data.name} />
                <GOLabel
                  label="시작"
                  body={dayjs(data.startDate).format('YYYY-MM-DD a hh:mm:ss')}
                />
                <GOLabel label="종료" body={dayjs(data.endDate).format('YYYY-MM-DD a hh:mm:ss')} />
              </div>
              <div className="flex_box">
                <SmallTitle body={t('reservation.memo')} />
                <GOInput
                  label="메모"
                  value={data.memo}
                  onChange={(e: any) => setData({ ...data, memo: e.target.value })}
                  textarea
                />
              </div>
            </UFloatingBoxBody>
            <UFloatingBoxFooter>
              <GOButton buttontype="green" onClick={handleSubmit} body={t('common.edit')} />
              <GOButton buttontype="red" onClick={onDelete} body={t('common.del')} />
              <GOButton body={t('common.no')} onClick={props.onClickExit} />
            </UFloatingBoxFooter>
          </UFloatingContainer>
        </GOFloatingbox>
      </>
    );
  } else {
    return (
      <GOFloatingbox width={750}>
        <UFloatingContainer as="form">
          <UFloatingBoxHeader>
            <h1>타석예약 가능시간</h1>
            <p>타석예약 정보를 확인하세요.</p>
          </UFloatingBoxHeader>
          <UFloatingBoxBody flex>
            <div className="flex_box">
              <SmallTitle body={t('reservation.personal')} />
              <GOLabel label="레슨 이름" body={data.name} />
              <GOLabel label="시작" body={dayjs(data.startDate).format('YYYY-MM-DD a hh:mm:ss')} />
              <GOLabel label="종료" body={dayjs(data.endDate).format('YYYY-MM-DD a hh:mm:ss')} />
              <br />
            </div>
            <div className="flex_box">
              <SmallTitle body={t('reservation.memo')} />
              <GOInput
                label="메모"
                value={data.memo}
                onChange={(e: any) => setData({ ...data, memo: e.target.value })}
                textarea
              />
            </div>
          </UFloatingBoxBody>
          <UFloatingBoxFooter>
            <GOButton buttontype="green" onClick={handleSubmit} body={t('common.edit')} />
            <GOButton buttontype="red" onClick={onDelete} body={t('common.del')} />
            <GOButton body={t('common.no')} onClick={props.onClickExit} />
          </UFloatingBoxFooter>
        </UFloatingContainer>
      </GOFloatingbox>
    );
  }
};
export default Detail;
