export default function MonthlyAccountingSex(data: any) {
  let maleAmount;
  let femaleAmount;

  const filter = {
    maleAmount: data
      ?.filter((d: any) => d.sex === 0)
      .map((d: any) => (Number(d.paymentAmount) > 0 ? Number(d.paymentAmount) : 0)),
    maleRefund: data
      ?.filter((d: any) => d.sex === 0)
      .map((d: any) => (Number(d.refundAmount) > 0 ? Number(d.refundAmount) : 0)),
    femaleAmount: data
      ?.filter((d: any) => d.sex === 1)
      .map((d: any) => (Number(d.paymentAmount) > 0 ? Number(d.paymentAmount) : 0)),
    femaleRefund: data
      ?.filter((d: any) => d.sex === 1)
      .map((d: any) => (Number(d.refundAmount) > 0 ? Number(d.refundAmount) : 0)),
  };

  maleAmount =
    filter.maleAmount &&
    filter.maleRefund &&
    (filter.maleAmount[0] - filter.maleRefund[0] > 0
      ? filter.maleAmount[0] - filter.maleRefund[0]
      : 0);
  femaleAmount =
    filter.femaleAmount &&
    filter.femaleRefund &&
    (filter.femaleAmount[0] - filter.femaleRefund[0] > 0
      ? filter.femaleAmount[0] - filter.femaleRefund[0]
      : 0);

  return { maleAmount, femaleAmount };
}
