import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const SubTitle = styled.div`
  font-size: 16px;
  color: #495057;
`;

export const ErrorText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #fa5252;
  margin-top: 5px;
`;

export const Number = styled.div`
  width: 100%;
  height: 100%;
  font-size: 36px;
  font-weight: 700;
  color: #495057;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const NumberText = styled.input`
  width: 240px;
  max-width: 100%;
  height: 32px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #12b886;
  background-color: #fff;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-bottom: 15px;
  text-align: center;
  font-size: 32px;
  color: #495057;
  letter-spacing: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:disabled {
    background: #fff;
  }
`;

export const NumberPad = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  max-width: 240px;
  overflow: hidden;
`;

export const Pad = styled.div`
  width: 58px;
  height: 58px;
  text-align: center;
  line-height: 55px;
  font-size: 36px;
  font-weight: 700;
  color: #495057;
  background: #fff;
  border: 1px solid #495057;
  border-radius: 50%;
  margin: 0px 10px 20px 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  transition: 0.35s;

  &:hover {
    background: #495057;
    color: #fff;
    border: 1px solid #495057;
    transition: 0.35s;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.3);
  }
`;

export const FormContainer = styled.div`
  max-width: 240px;
  margin: 0 auto 20px;
`;
