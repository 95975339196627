import React, { useState, useEffect } from 'react';
import { NetSmartAdvisorYearlyAccounting } from '../../../actions';
import YearlySalesChart from '../../../components/smartadvisor/YearlySales';

type yearlySalesConfigInSmartAdvisor = {
  yearFilter?: number;
};

const YearlySales = ({ yearFilter }: yearlySalesConfigInSmartAdvisor) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [category, setCategory] = useState();
  const [tickets, setTickets] = useState();
  const [sex, setSex] = useState();
  const [age, setAge] = useState();

  //feature/2021/1214/iOSMultibranch
  // const year = new Date().getFullYear();
  const year = yearFilter;

  useEffect(() => {
    const fetchAPI = async () => {
      await NetSmartAdvisorYearlyAccounting(`${year}`, 'repurchase').then((res: any) => {
        setCategory(res.data.Data.dataList);
      });
      await NetSmartAdvisorYearlyAccounting(`${year}`, 'productCategory').then((res: any) => {
        setTickets(res.data.Data.dataList);
      });
      await NetSmartAdvisorYearlyAccounting(`${year}`, 'sex').then((res: any) => {
        setSex(res.data.Data.dataList);
      });
      await NetSmartAdvisorYearlyAccounting(`${year}`, 'age').then((res: any) => {
        setAge(res.data.Data.dataList);
        setLoading(false);
      });
    };
    console.log('💠 월별 매출 현황 Network');
    fetchAPI();
  }, [year]);

  return (
    <YearlySalesChart
      loading={loading}
      year={year}
      category={category}
      tickets={tickets}
      sex={sex}
      age={age}
    />
  );
};

export default YearlySales;
