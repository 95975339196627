import styled from 'styled-components';
import * as color from '../../../color/index';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const Container = styled.div`
  display: table;
  width: 100%;
  height: 240px;
`;

export const Wrapper = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

export const EmptyText = styled.div`
  text-align: center;
  font-weight: bold;
  color: ${color.Colors.line};
  ${BreakPoints.sm} {
    font-size: 14px;
  }
`;
