import createReducers from '../controller/createReducer';
import { combineReducers } from 'redux';
import { ModalReducer } from '../modals';

const apiReducer = createReducers(
  'sign',
  'plate',
  'user',
  'locker',
  'product',
  'employee',
  'dashboard',
  'accounting',
  'settings', //2022 설정에서 타석 로그인 설정사항 추가
  'plateLoginModal', //2022 타석에서 로그인 설정 제어하기
);

const rootReducer = combineReducers({
  ...apiReducer,
  ModalReducer,
});

export default rootReducer;
