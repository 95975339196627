import styled, { keyframes } from 'styled-components';

const gosending = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Sending = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  animation: ${gosending} 0.35s;

  div {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
  }
`;
