import React, { useEffect, useState } from 'react';
import {
  HeaderBottom,
  StatusTool,
  SegmentControl,
  GOLoading,
  PlateReservationStatusGrid,
  PlateReservationStatusTime,
  LessonReservationStatusTime,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { NetLessonReservationsByDateList } from '../../../actions';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import './toastGoGridAttr.css';

dayjs.locale('ko');

const LessonReservationStatusList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState({
    startDate: dayjs()
      .subtract(1, 'day')
      .format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  });

  const [update, isUpdate] = useState(false);
  const [gridColumns, setGridColumns] = useState<any>([
    { name: 'time', header: '시간', align: 'center', soringType: 'asc', sortable: true },
    { name: 'p1114', header: '김종원프로', align: 'center' },
    { name: 'p1115', header: '양프로/오전', align: 'center' },
    { name: 'p1116', header: '김프로/오전', align: 'center' },
  ]);
  const [gridData, setGridData] = useState<any>([
    {
      time: '00:30~01:00',
      p1114: '김광성',
      p114_uid: 2933,
      p1115: '',
      p1115_uid: 0,
      p116: '',
      p116_uid: 0,
    },
    {
      time: '13:00~13:30',
      p1114: '김광성',
      p114_uid: 2933,
      p1115: '',
      p1115_uid: 0,
      p116: '',
      p116_uid: 0,
    },
    {
      time: '13:30~14:00',
      p1114: '김광성',
      p114_uid: 2933,
      p1115: '',
      p1115_uid: 0,
      p116: '',
      p116_uid: 0,
    },
  ]);

  //데이터 가져오기
  useEffect(() => {
    NetLessonReservationsByDateList(`${date.startDate}`, `${date.endDate}`).then((res: any) => {
      let gridData: any = [];
      let column: [any] = [
        { name: 'time', header: '시간', align: 'center', soringType: 'asc', sortable: true },
      ];
      res.data.Data.lessonPros.forEach((p: any) => {
        column.push({ name: `p${p.proID}`, header: `${p.proName}`, align: 'center' });
      });

      if (res.data.Data.lessonPros.length < 1) {
        setGridColumns([]);
      } else {
        setGridColumns(column);
      }

      res.data.Data.lessonReservationsByDate.forEach((t: any) => {
        let gridDataItem: any = {
          time: `${dayjs(t.startTime).format('HH:mm')}~${dayjs(t.endTime).format('HH:mm')}`,
          startTime: t.startTime,
        };
        console.log(gridDataItem);
        res.data.Data.lessonPros.forEach((p: any) => {
          const pNumber = `p${p.proID}`;
          const pUserId = `p${p.proID}_uid`;
          gridDataItem[pNumber] = '';
          gridDataItem[pUserId] = 0;
        });
        let coloredColumns: any = { column: {} };

        t.proIDs.forEach((proID: number, index: number) => {
          const pNumber = `p${proID}`;
          const pUserId = `p${proID}_uid`;
          if (index < t.userNames.length && index < t.userIDs.length && index < t.status.length) {
            gridDataItem[pNumber] = t.userNames[index];
            gridDataItem[pUserId] = t.userIDs[index];
            console.log(t.status[index], '1111');
            if (t.status[index] === 1) {
              coloredColumns.column[pNumber] = ['go_grid_cell_attend'];
            } else if (t.status[index] === 2) {
              coloredColumns.column[pNumber] = ['go_grid_cell_absent'];
            } else {
              coloredColumns.column[pNumber] = ['go_grid_cell_reserved'];
            }
          }
        });
        const attr = '_attributes';
        gridDataItem[attr] = {
          className: coloredColumns,
        };
        gridData.push(gridDataItem);
      });

      const today = dayjs().format('YYYY-MM-DD');
      const isToday = today === date.endDate;
      //adding current Time
      if (isToday) {
        let gridDataItemNow: any = {
          time: `${dayjs().format('HH:mm')} `,
          startTime: dayjs(),
          _attributes: {
            className: {
              // Add class name on a row
              row: ['go_grid_row_cur_time'],
            },
          },
        };
        gridData.push(gridDataItemNow);

        //sorting
        let sortedGridData = gridData.slice().sort((a: any, b: any) => {
          let dateA = a['time'];
          let dateB = b['time'];
          console.log(dateA, dateB);
          if (dateA < dateB) {
            return -1;
          } else if (dateA > dateB) {
            return 1;
          } else {
            return 0;
          }
        });
        setGridData(sortedGridData);
      } else {
        setGridData(gridData);
      }

      setLoading(false);
    });
  }, [date, update]);

  return (
    <div>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool type="prev" body={t('main-menu.reservation_management')} />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={'타석예약자'} />
          </>
        }
      />
      <SegmentControl
        data={[
          {
            name: t('reservation.group'),
            link: '/admin/reservation/list/group',
          },
          {
            name: t('reservation.personal'),
            link: '/admin/reservation/list/personal',
          },
          {
            name: '타석예약자',
            link: '/admin/reservation/list/plateReservationStatus',
          },
          {
            name: '개인수업예약자',
            link: '/admin/reservation/list/lessonReservationStatus',
          },
        ]}
      />
      {/* 날짜 선택 보여주는 화면 임*/}
      <LessonReservationStatusTime
        date={date}
        goLeft={() => {
          if (!loading) {
            setDate({
              startDate: dayjs(date.startDate)
                .subtract(1, 'd')
                .format('YYYY-MM-DD'),
              endDate: dayjs(date.endDate)
                .subtract(1, 'd')
                .format('YYYY-MM-DD'),
            });
            setLoading(true);
          }
        }}
        goRight={() => {
          if (!loading) {
            setDate({
              startDate: dayjs(date.startDate)
                .add(1, 'd')
                .format('YYYY-MM-DD'),
              endDate: dayjs(date.endDate)
                .add(1, 'd')
                .format('YYYY-MM-DD'),
            });
            setLoading(true);
          }
        }}
        goDate={(toDate: any) => {
          if (!loading) {
            setDate({
              startDate: dayjs(toDate)
                .subtract(1, 'd')
                .format('YYYY-MM-DD'),
              endDate: dayjs(toDate).format('YYYY-MM-DD'),
            });
            setLoading(true);
          }
        }}
      />
      {loading ? (
        <GOLoading />
      ) : (
        <PlateReservationStatusGrid
          gridColumns={gridColumns}
          gridData={gridData}
          update={() => {
            isUpdate(!update);
          }}
        />
      )}
    </div>
  );
};

export default LessonReservationStatusList;
