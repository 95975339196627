import createActions from '../controller/createActions';

const { read, onlyCreate } = createActions('plateLoginModal');

export const actionGetPlatModalStatusList = () =>
  read({
    url: 'plate/loginModalStatus',
  });

export const actionPostPlateModalStatus = (
  params: {
    modalState: string;
    all: string;
    number: number | string | null;
  },
  onSuccess?: Function,
) =>
  onlyCreate({
    url: `plate/changeModalState`,
    params,
    meta: {
      onSuccess,
    },
  });
