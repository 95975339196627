import React, { useState } from 'react';
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Styled from './styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UserManager } from '../../../actions';
import { onModal } from '../../../stores/modals';
import { useDispatch } from 'react-redux';

const Header = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onClickBtnMenuOpen = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const onClickMenuItem = (event: React.MouseEvent) => {
    const element = event.target as HTMLElement;
    if (isOpenMenu && (element.tagName === 'A' || element.tagName === 'BUTTON')) {
      setIsOpenMenu(false);
    }
  };

  return (
    <Styled.Header>
      <Styled.HeaderTop>
        <div className="wrap">
          <Link to="/admin">
            <Styled.LogoSmall />
          </Link>
          <button className={`btn-menu${isOpenMenu ? ' active' : ''}`} onClick={onClickBtnMenuOpen}>
            <FontAwesomeIcon icon={isOpenMenu ? faClose : faBars} />
          </button>
          <Styled.HeaderMenu
            className={`${isOpenMenu ? 'menu-opend' : ''}`}
            onClick={onClickMenuItem}
          >
            <li>
              <Link to="/admin/dashboard">{t('main-menu.dashboard')}</Link>
            </li>
            <li>
              <Link to="/admin/user/grid">{t('main-menu.user_management')}</Link>
            </li>

            <li>
              <Link to="/admin/product/list">{t('main-menu.product_management')}</Link>
            </li>

            <li>
              <Link to="/admin/reservation/list/group">
                {t('main-menu.reservation_management')}
              </Link>
            </li>
            <li>
              <Link to="/admin/plate/list">{t('main-menu.seat_management')}</Link>
            </li>
            <li>
              <button
                onClick={() => {
                  dispatch(onModal('accountingpassword'));
                }}
              >
                {t('main-menu.finance_management')}
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  dispatch(onModal('smartadvisorpassword'));
                }}
              >
                {t('main-menu.smart_advisor')}
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  dispatch(onModal('employeepassword'));
                }}
              >
                {t('main-menu.employee_management')}
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  dispatch(onModal('settingsPassword'));
                }}
              >
                {t('main-menu.setting')}
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  window.open(
                    'https://www.notion.so/happygolfdev/775e68bf7eb747beb8dfbeb72bc50cf2',
                  );
                }}
              >
                {t('main-menu.help')}
              </button>
            </li>
          </Styled.HeaderMenu>
          <div className="dimmed" onClick={onClickBtnMenuOpen} />
          <Styled.HeaderRight>
            <span>행복골프</span> {UserManager().getUser().name} 님<span> (v1.0.0)</span>
          </Styled.HeaderRight>
        </div>
      </Styled.HeaderTop>
    </Styled.Header>
  );
};

export default Header;
