import React, { useEffect, useState } from 'react';
import {
  HeaderBottom,
  StatusTool,
  ReservationView,
  SegmentControl,
  ReservationShowTime,
  ReservationPersonalDelete,
  GOLoading,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { NetLessonList, NetEmployeeList } from '../../../actions';
import dayjs from 'dayjs';
import PersonalAdd from '../../../components/reservation/PersonalAdd';
import LessonAddManually from '../../../components/reservation/LessonAddManually';

const PersonalList = () => {
  const { t } = useTranslation();

  const [viewPersonalAdd, setViewPersonalAdd] = useState(false);
  const [viewPersonalDel, setViewPersonalDel] = useState(false);

  const [viewLessonAddManually, setViewLessonAddManually] = useState(false);
  const [dateForLessonAddingManually, setDateForLessonAddingManually] = useState(new Date());

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState({
    startDate: dayjs()
      .day(0)
      .format('YYYY-MM-DD'),
    endDate: dayjs()
      .day(7)
      .format('YYYY-MM-DD'),
  });
  const [employee, setEmployee] = useState([]);
  const [now, setNow] = useState(0);
  const [lessonProList, setLessonProList] = useState();
  const [update, isUpdate] = useState(false);

  //2022.10.13: show add lesson time manually 2022.10.
  const showAddLessonTimeManually = (d: any) => {
    const selectedDate = new Date(`${d.d}T${d.t}:00+09:00`);
    // console.log(selectedDate);
    // console.log(dayjs(selectedDate));
    setDateForLessonAddingManually(selectedDate);
    setViewLessonAddManually(true);
  };

  //데이터 가져오기
  useEffect(() => {
    NetEmployeeList().then((eRes: any) => {
      setEmployee(eRes.data.Data.Employees);
      if (eRes.data.Data.Employees.length > 0) {
        const kwangmyeongPro = eRes.data.Data.Employees?.filter((e: any) => {
          console.log(e);
          return e.id === 103 || e.id === 188;
        });
        let isNormalBranch = true;
        console.log(kwangmyeongPro);
        if (kwangmyeongPro?.length === 2) {
          isNormalBranch = false;
        }

        const lessonPro = isNormalBranch
          ? eRes.data.Data.Employees?.filter((e: any) => e.type === 2)
          : eRes.data.Data.Employees?.filter((e: any) => e.type === 2).sort((a: any, b: any) => {
              let dateA = new Date(a['dateOfBirth']).getTime();
              let dateB = new Date(b['dateOfBirth']).getTime();
              return dateA - dateB;
            });
        // const lessonPro = eRes.data.Data.Employees?.filter((e: any) => e.type === 2);
        setLessonProList(lessonPro);
        setNow(lessonPro[0].id);
      }
    });
  }, []);

  useEffect(() => {
    if (now !== 0) {
      NetLessonList(
        now,
        `${date.startDate}T00:00:00`,
        `${dayjs(date.endDate)
          .subtract(1, 'm')
          .format('YYYY-MM-DD')}T23:59:59`,
      ).then((res: any) => {
        setData(res.data.Data.LessonAvailabilities);
        setLoading(false);
      });
    }
  }, [date, now, update]);

  return (
    <div>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool type="prev" body={t('main-menu.reservation_management')} />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={t('reservation.personal')} />
          </>
        }
      />
      <SegmentControl
        data={[
          {
            name: t('reservation.group'),
            link: '/admin/reservation/list/group',
          },
          {
            name: t('reservation.personal'),
            link: '/admin/reservation/list/personal',
          },
          {
            name: '타석예약자',
            link: '/admin/reservation/list/plateReservationStatus',
          },
          {
            name: '개인수업예약자',
            link: '/admin/reservation/list/lessonReservationStatus',
          },
        ]}
      />
      <ReservationShowTime
        data={data}
        date={date}
        employee={employee}
        setViewPersonalAdd={setViewPersonalAdd}
        setViewPersonalDel={setViewPersonalDel}
        onChange={(e: any) => setNow(e.target.value)}
        value={now}
        goLeft={() => {
          setDate({
            startDate: dayjs(date.startDate)
              .subtract(7, 'd')
              .format('YYYY-MM-DD'),
            endDate: dayjs(date.endDate)
              .subtract(7, 'd')
              .format('YYYY-MM-DD'),
          });
          setLoading(true);
        }}
        goRight={() => {
          setDate({
            startDate: dayjs(date.startDate)
              .add(7, 'd')
              .format('YYYY-MM-DD'),
            endDate: dayjs(date.endDate)
              .add(7, 'd')
              .format('YYYY-MM-DD'),
          });
          setLoading(true);
        }}
      />
      {loading ? (
        <>
          <GOLoading />
        </>
      ) : (
        // 레슨 예약 상황 보여주는 테이블 jejucampus
        //2022.10.18 여기서 레슨 넣어주는 setViewPeronalAdd 추가해준다.
        <ReservationView
          sDate={date.startDate}
          eDate={date.endDate}
          data={data}
          employee
          lessonProID={now} // public nine hole
          showAddLessonTimeManually={showAddLessonTimeManually} //2022.10.18 레슨 스케쥴 타임 클릭햇을때 팝업 보여주기
          update={() => {
            isUpdate(!update);
          }}
        />
      )}
      {viewPersonalAdd && (
        <PersonalAdd
          onClickExit={() => setViewPersonalAdd(false)}
          now={now}
          onChange={(id: any) => setNow(id)}
          update={() => isUpdate(!update)}
          lessonProList={lessonProList}
        />
      )}
      {viewPersonalDel && (
        <ReservationPersonalDelete
          onClickExit={() => setViewPersonalDel(false)}
          update={() => {
            isUpdate(!update);
          }}
          now={now}
        />
      )}
      {viewLessonAddManually && (
        <LessonAddManually
          onClickExit={() => setViewLessonAddManually(false)}
          now={now}
          selectedLessonTime={dateForLessonAddingManually}
          onChange={(id: any) => setNow(id)}
          update={() => isUpdate(!update)}
          lessonProList={lessonProList}
        />
      )}
    </div>
  );
};

export default PersonalList;
