import NetworkManager from "../../../NetworkManager";

const CheckPassword = (data: object) => {
  return new Promise((resolve, reject) => {
    NetworkManager("post", "admin/auth/pins/check", data)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export default CheckPassword;
