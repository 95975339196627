import React from 'react';
import { GOFloatingbox, GOButton, GOTextarea } from '../../components';
import { useTranslation } from 'react-i18next';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../GlobalUtilityStyles';

const Memo = ({ onClickExit, data }: any) => {
  const { t } = useTranslation();

  return (
    <GOFloatingbox width={300}>
      <UFloatingContainer>
        <UFloatingBoxHeader>
          <h1>메모</h1>
        </UFloatingBoxHeader>
        <UFloatingBoxBody>
          <div className="flex_box">
            {data.type === 'refund' ? (
              data.data.map((d: any, index: number) => <GOTextarea key={index} body={d} />)
            ) : (
              <GOTextarea body={data.data} />
            )}
          </div>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton body={t('common.no')} onClick={onClickExit} />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};

export default Memo;
