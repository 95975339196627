import styled, { keyframes } from 'styled-components';
import * as color from '../../../../color/index';
import { darken } from 'polished';

const reservationani = keyframes`
  0% {
    opacity: 0;
    top: -12px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
`;

export const IVManagerLink = styled.div`
  float: right;
  margin: 10px 100px 0 0;
`;

export const IVManagerButton = styled.div`
  width: max-content;
  padding: 10px;
  font-weight: bold;
  color: ${color.Colors.primary};
  background: ${color.Colors.white};
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background: ${darken(0.05, `${color.Colors.white}`)};
    transition: 0.3s;
  }
`;

export const PlateView = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  animation: ${reservationani} 0.35s;
  flex-wrap: wrap;

  &::after {
    content: '';
    flex: auto;
  }
`;

export const PlatePlaceholder = styled.div`
  width: 280px;
  padding: 0;
  height: 1px;
  margin: 0px 8px;
  flex: 1 1 auto;
`;
