import styled from 'styled-components';
import { BreakPoints, HorizontalGlobalPadding } from '../../../GlobalUtilityStyles';

export const FilterContainer = styled.div`
  padding: 12px ${HorizontalGlobalPadding.xl} 0px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1900px;
  width: 100%;
  margin: 0 auto;

  .GODropbox,
  .GOInput,
  .input_margin_wrapper,
  button {
    margin: 4px 0px 4px 8px;
  }

  ${BreakPoints.lg} {
    padding: 12px ${HorizontalGlobalPadding.lg} 0px;
  }

  ${BreakPoints.md} {
    padding: 12px ${HorizontalGlobalPadding.md} 0px;
  }

  ${BreakPoints.sm} {
    padding: 12px ${HorizontalGlobalPadding.sm} 0px;
    .GODropbox,
    .GOInput,
    .input_margin_wrapper,
    button {
      margin: 3px 2px 3px 2px;
    }
  }
`;

/* LeftFilter, RightFilter, CenterFilter 공통 컨테이너 스타일 (각 화면 필터 요소의 세부 스타일링은 각 페이지에서 지정 ) */
const commonStyle = `
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  flex-wrap: wrap;

  &:empty {
    display: none;
  }
`;

export const LeftFilter = styled.div`
  ${commonStyle}

  .GODropbox,
  .GOInput,
  .input_margin_wrapper,
    button {
    &:first-child {
      margin-left: 0;
    }
  }
`;

export const RightFilter = styled.div`
  ${commonStyle}
  margin-left: auto;
  justify-content: flex-end;

  ${BreakPoints.sm} {
    margin-left: -2px;
    margin-right: -2px;
    button {
      flex: 1 1 auto;
    }
  }
`;

export const CenterFilter = styled.div`
  ${commonStyle}
  margin: 0 auto;
  jusfify-content: center;
`;
export const EtcRowFilter = styled.div`
  width: 100%;
  margin-top: 10px;
`;
