const MinuteToTimeConvert = (time: any) => {
  let minutes;

  let hour = new Date(time).getHours() * 60;
  let minute = new Date(time).getMinutes();

  minutes = hour + minute;

  return minutes;
};

export default MinuteToTimeConvert;
