import React from 'react';
import DaumPostcode from 'react-daum-postcode';
import GOFloatingbox from '../GOFloatingbox';
import GOButton from '../GOButton';
import * as Styled from './styles';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

const GOPostCode = ({ handleChange, onClickExit }: any) => {
  const handleComplete = (data: any) => {
    let roadAddress = data.roadAddress;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      roadAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    handleChange({ ...data, roadAddress });
    onClickExit();
  };
  return (
    <GOFloatingbox width={420}>
      <UFloatingContainer>
        <UFloatingBoxBody flex>
          <div className="flex_box h_resize">
            <Styled.PostCodeBox>
              <DaumPostcode
                onComplete={handleComplete}
                autoResize={true}
                style={{ height: '100%' }}
              />
            </Styled.PostCodeBox>
          </div>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton body="닫기" onClick={onClickExit} noMarginLeft />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};

export default GOPostCode;
