import React, { useState, useContext, useEffect } from 'react';
import './index.scss';
import { NetGetCheckIn, UserManager } from '../../actions';
import { Context } from '../../stores';
import Modal from './Modal';

const CheckIn = () => {
  const [num, setNum] = useState<string>('');
  const [modal, setModal] = useState<any>({
    status: 0,
    data: '',
  });
  const numArr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const Store = useContext(Context);
  const branch = UserManager().getUser()?.branch;

  useEffect(() => {
    // 체크인 화면 생성시 body 스크롤 제거
    const body = document.querySelector('body');
    if (body) {
      body.style.overflowY = 'hidden';
    }

    return () => {
      if (body) {
        body.style.overflowY = 'scroll';
      }
    };
  }, []);

  // const dispatch = useDispatch();
  const handleSubmit = () => {
    Store.actions.setSending(true);
    NetGetCheckIn(num)
      .then((res: any) => {
        setModal({ status: 1, data: res.data.Data });
        Store.actions.setSending(false);
        // dispatch(onModal('checkin', { modal }));
      })
      .catch(err => {
        if (err) {
          setModal({
            status: 2,
            data: '',
          });
        }
        Store.actions.setSending(false);
        // dispatch(onModal('checkin', { modal }));
      });
  };

  return (
    <div className="checkin">
      <div>
        <div className="text">
          <img src="/images/checkin_top_logo.png" alt="top_logo" className="top-logo_image" />
          <span className="branch-name">{branch?.name}</span>
          <div className="center-text">
            안녕하세요!
            <br />
            {branch?.name}입니다.
            <br />
            오늘도 모두 행복한 골프를 즐기세요!
          </div>
        </div>
        <div className="footer">
          <div className="footer-title">행복골프GO 어플리케이션</div>
          <div className="qr-container">
            <div>
              <img className="qr-image" src="/images/qr_android.png" alt="android" />
              <div className="qr-text">안드로이드</div>
            </div>
            <div className="vertical-line" />
            <div>
              <img className="qr-image" src="/images/qr_iOS.png" alt="iOS" />
              <div className="qr-text">iOS</div>
            </div>
            {/* 위례점 와이파이 QR */}
            {branch.id === 2 && (
              <>
                <div className="vertical-line" />
                <div>
                  <img className="qr-image" src="/images/qr_wirae.png" alt="wirae_wifi" />
                  <div className="qr-text">WIFI</div>
                </div>
              </>
            )}

            {/* 서현점 와이파이 QR */}
            {branch.id === 3 && (
              <>
                <div className="vertical-line" />
                <div>
                  <img className="qr-image" src="/images/qr_seohyun.png" alt="seohyun_wifi" />
                  <div className="qr-text">WIFI</div>
                </div>
              </>
            )}

            {/* 의왕점 와이파이 QR */}
            {branch.id === 6 && (
              <>
                <div className="vertical-line" />
                <div>
                  <img className="qr-image" src="/images/qr_euiwang.png" alt="euiwang_wifi" />
                  <div className="qr-text">WIFI</div>
                </div>
              </>
            )}

            {/* 평촌점 와이파이 QR */}
            {branch.id === 7 && (
              <>
                <div className="vertical-line" />
                <div>
                  <img className="qr-image" src="/images/qr_pyeongchon.png" alt="pyeongchon_wifi" />
                  <div className="qr-text">WIFI</div>
                </div>
              </>
            )}

            {/* 동탄점 와이파이 QR */}
            {branch.id === 8 && (
              <>
                <div className="vertical-line" />
                <div>
                  <img className="qr-image" src="/images/qr_dongtan.png" alt="dongtan_wifi" />
                  <div className="qr-text">WIFI</div>
                </div>
              </>
            )}

            {/* 일산 덕이점 와이파이 QR */}
            {branch.id === 10 && (
              <>
                <div className="vertical-line" />
                <div>
                  <img className="qr-image" src="/images/qr_ilsan.png" alt="ilsan_wifi" />
                  <div className="qr-text">WIFI</div>
                </div>
              </>
            )}

            {/* 광명점 와이파이 QR */}
            {branch.id === 12 && (
              <>
                <div className="vertical-line" />
                <div>
                  <img className="qr-image" src="/images/qr_kwangmyung.png" alt="kwangmyung_wifi" />
                  <div className="qr-text">WIFI</div>
                </div>
              </>
            )}

            {/* 송도점 와이파이 QR */}
            {branch.id === 13 && (
              <>
                <div className="vertical-line" />
                <div>
                  <img className="qr-image" src="/images/qr_songdo-wifi.png" alt="songdo_wifi" />
                  <div className="qr-text">WIFI</div>
                </div>
              </>
            )}

            {/* 제주점 와이파이 QR */}
            {branch.id === 14 && (
              <>
                <div className="vertical-line" />
                <div>
                  <img className="qr-image" src="/images/qr_jeju.png" alt="jeju_wifi" />
                  <div className="qr-text">WIFI</div>
                </div>
              </>
            )}

            {/* 역삼점 와이파이 QR */}
            {branch.id === 16 && (
              <>
                <div className="vertical-line" />
                <div>
                  <img className="qr-image" src="/images/qr_yeoksam.png" alt="yeoksam_wifi" />
                  <div className="qr-text">WIFI</div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="number">
        <div className="number_text">{num}</div>
        <div className="number_pad">
          {numArr.map((n: any) => (
            <div
              key={n}
              className="pad"
              onClick={() => {
                setNum(num + n);
              }}
            >
              {n}
            </div>
          ))}
          <div
            className="pad btn_del"
            onClick={() => {
              setNum(num.slice(0, -1));
            }}
          >
            ←
          </div>
          <div
            className="pad"
            onClick={() => {
              setNum(num + '0');
            }}
          >
            0
          </div>
          <div
            className="pad btn_clear"
            onClick={() => {
              setNum('');
            }}
          >
            ✕
          </div>
        </div>
        <div className={`number_checkin ${num && `number_checkin_ok`}`} onClick={handleSubmit}>
          체크인
        </div>
      </div>
      {modal.status !== 0 && (
        <Modal
          modal={modal}
          num={num}
          onClickExit={() => {
            setModal({ status: 0, data: '' });
            setNum('');
          }}
        />
      )}
    </div>
  );
};

export default CheckIn;
