export default function UserMix(data: any) {
  let maleArr: any = [];
  let femaleArr: any = [];

  if (data) {
    const male = data.filter((d: any) => d.sex === 0);
    const female = data.filter((d: any) => d.sex === 1);

    const maleFilter = {
      percent20s: male.filter((d: any) => d.r10Age === 1).map((p: any) => p.cnt)[0],
      percent30s: male.filter((d: any) => d.r10Age === 2).map((p: any) => p.cnt)[0],
      percent40s: male.filter((d: any) => d.r10Age === 3).map((p: any) => p.cnt)[0],
      percent50s: male.filter((d: any) => d.r10Age === 4).map((p: any) => p.cnt)[0],
      percent60s: male.filter((d: any) => d.r10Age === 5).map((p: any) => p.cnt)[0],
    };

    const femaleFilter = {
      percent20s: female.filter((d: any) => d.r10Age === 1).map((p: any) => p.cnt)[0],
      percent30s: female.filter((d: any) => d.r10Age === 2).map((p: any) => p.cnt)[0],
      percent40s: female.filter((d: any) => d.r10Age === 3).map((p: any) => p.cnt)[0],
      percent50s: female.filter((d: any) => d.r10Age === 4).map((p: any) => p.cnt)[0],
      percent60s: female.filter((d: any) => d.r10Age === 5).map((p: any) => p.cnt)[0],
    };
    maleArr = [
      maleFilter.percent20s > 0 ? maleFilter.percent20s : 0,
      maleFilter.percent30s > 0 ? maleFilter.percent30s : 0,
      maleFilter.percent40s > 0 ? maleFilter.percent40s : 0,
      maleFilter.percent50s > 0 ? maleFilter.percent50s : 0,
      maleFilter.percent60s > 0 ? maleFilter.percent60s : 0,
    ];
    femaleArr = [
      femaleFilter.percent20s > 0 ? femaleFilter.percent20s : 0,
      femaleFilter.percent30s > 0 ? femaleFilter.percent30s : 0,
      femaleFilter.percent40s > 0 ? femaleFilter.percent40s : 0,
      femaleFilter.percent50s > 0 ? femaleFilter.percent50s : 0,
      femaleFilter.percent60s > 0 ? femaleFilter.percent60s : 0,
    ];
  }
  return { maleArr, femaleArr };
}
