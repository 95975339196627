import styled, { css } from 'styled-components';
import * as color from '../../../color/index';
import { BreakPoints, HorizontalGlobalPadding } from '../../../GlobalUtilityStyles';

interface styleProps {
  top?: boolean;

  red?: boolean;
  empty?: boolean;
}

export const ListAll = styled.div<styleProps>`
  background: #fff;
  width: 100%;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  padding: 20px 12px;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  font-size: 14px;
  color: ${color.Colors.primary};

  ${props => {
    if (props.top)
      return css`
        margin-top: 10px;
      `;
  }}

  .newPurchaseWrapper {
    display: flex;
    justify-content: space-between;
  }

  .newPurchase {
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
  }
`;

export const Table = styled.div<styleProps>`
  overflow: auto;
  white-space: nowrap;
  table {
    min-width: 100%;
    text-align: center;
    border-collapse: collapse;

    .deleted {
      text-decoration: line-through;

      td {
        opacity: 0.3;
      }
    }

    thead {
      font-weight: bold;
      tr {
        td {
          padding: 0px 5px 5px;
          border-bottom: 1px solid ${color.Colors.primary};
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 12px 5px;
          border-bottom: 1px solid ${color.Colors.line};
          border-right: 1px solid ${color.Colors.line};
        }
        td:last-child {
          border-right: 0;
        }

        .price {
          text-align: right;
        }
      }
    }
  }
`;

export const Circle = styled.div<styleProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${color.Colors.green};
  text-align: center;
  margin-top: 5px;
  margin-right: 5px;

  ${props => {
    if (props.red)
      return css`
        background: ${color.Colors.red};
      `;
  }}

  ${props => {
    if (props.empty)
      return css`
        background: ${color.Colors.white};
      `;
  }}
`;

export const GridContainer = styled.div`
  height: 100vh;
`;
