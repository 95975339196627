import NetworkManager from '../../../NetworkManager';

const CurrentMonthAccounting = (sDate: string, eDate: string, option?: string) => {
  return new Promise((resolve, reject) => {
    if (option === 'sex') {
      NetworkManager(
        'get',
        `admin/smartAdvisor/monthlySales?groupBy=sex&startDate=${sDate}&endDate=${eDate}`,
      )
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {});
    } else {
      NetworkManager(
        'get',
        `admin/smartAdvisor/monthlySales?groupBy=age&startDate=${sDate}&endDate=${eDate}`,
      )
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {});
    }
  });
};

export default CurrentMonthAccounting;
