import styled, { keyframes } from 'styled-components';
import * as color from '../../color/index';
import { lighten } from 'polished';
import {
  ContentBodyPadding,
  BreakPoints,
  HorizontalGlobalPadding,
} from '../../GlobalUtilityStyles';

/*********************************
  * 예약관리 - 그리드 영역 컨테이너 공통 스타일
  - 타석예약자
  - 개인수업예약자
***********************************/
const reservationAni = keyframes`
  0% {
    opacity: 0;
    top: -12px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
`;

const msgAni = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.95;
  }
`;

export const ReservationView = styled.div`
  position: relative;
  animation: ${reservationAni} 0.35s;
  padding: ${ContentBodyPadding.xl};
  padding-top: 10px !important;
  max-width: 1900px;
  width: 100%;
  margin: 0 auto;

  ${BreakPoints.lg} {
    padding: ${ContentBodyPadding.lg};
  }

  ${BreakPoints.md} {
    padding: ${ContentBodyPadding.md};
  }
  ${BreakPoints.sm} {
    padding: ${ContentBodyPadding.sm};
  }

  /* 타석 클릭 시 발생, 이용상태 레이어 팝업 */
  .msg_box {
    width: 280px;
    position: absolute;
    padding: 12px 16px;
    border-radius: 5px;
    background: ${color.Colors.white};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    animation: ${msgAni} 0.35s;
  }

  .hover_msg_for_mobile {
    display: none;
  }

  ${BreakPoints.md} {
    // 태블릿 이하 사이즈에서 하단 푸터 형태로 고정
    .msg_box {
      position: fixed;
      bottom: 0;
      left: 0 !important;
      top: auto !important;
      right: 0;
      width: 100%;
      min-height: 73px;
      border-top: 1px solid #ddd;
      border-radius: 0;
      padding: 8px 15px;
      box-shadow: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .title {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }

      .hover_msg_for_mobile {
        display: block;
        padding: 0px 10px;
        line-height: 40px;
      }
    }
  }

  .hover_box {
    display: block;
    position: absolute;
    padding: 12px 16px;
    border-radius: 5px;
    background: ${color.Colors.white};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    animation: ${msgAni} 0.35s;
    transition: 0.15s;
    opacity: 0.95;
    font-weight: bold;
    left: 0;
    top: 0;

    .hover_name {
      font-size: 20px;
    }

    ${BreakPoints.md} {
      display: none; // 태블릿, 모바일 모드에서 hover 로 일어나는 액션 지원하지 않음.
    }
  }

  .res_date {
    min-width: 768px;
    justify-content: center;
  }

  // 타석이용, 개인수업 테이블 md 이하 리사이징 금지 처리
  .tbl_panel {
    position: relative;
    ${BreakPoints.md} {
      width: calc(1024px - (${HorizontalGlobalPadding.md} * 2));
    }
  }

  // 타석이용, 개인수업에서 사용
  .res_table {
    width: 100%;
    background: ${color.Colors.white};
    padding: 20px 20px 80px;
    border-radius: 5px;
    border: 1px solid ${color.Colors.line};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
    overflow: auto;

    table {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;
      .top_summary {
        font-size: 14px;
        font-weight: bold;
        position: absolute;
        left: 0;
        top: 10px;
        letter-spacing: -1px;
        right: 0;
        line-height: 1.2;
      }
      .top_date {
        font-size: 28px;
        font-weight: normal;
        ${BreakPoints.sm} {
          font-size: 22px;
        }
      }
      .sat {
        color: ${color.Colors.blue};
      }
      .sun {
        color: ${color.Colors.red};
      }
      .today {
        border: 2px solid ${color.Colors.green};
        color: ${color.Colors.green};
        box-sizing: border-box;
      }
      thead {
        tr {
          th:first-child {
            width: 3.6em;
            box-sizing: border-box;
          }
          th {
            height: 5.5rem;
            border-bottom: 1px solid ${lighten(0.06, `${color.Colors.line}`)};
            box-sizing: border-box;
            position: relative;
          }
        }
      }
      tbody {
        tr {
          td:first-child {
            border: 0;
            font-size: 14px;
            font-weight: bold;
            color: ${color.Colors.primary};
            position: relative;
            top: -14px;
            background: inherit !important;
            box-sizing: border-box;
          }
          td {
            border: 0.05rem solid ${lighten(0.06, `${color.Colors.line}`)};
            box-sizing: border-box;
            text-align: center;
            height: 2.5rem; //제주 캠퍼스 타임테이블의 높이
            //height: 5.0rem; //제주 캠퍼스 타임테이블의 높이
            background: ${color.Colors.background};
            transition: 0.25s;
          }
          td:hover {
            background: ${lighten(0.06, `${color.Colors.line}`)};
            cursor: pointer;
            transition: 0.25s;
          }
          .now_time {
            background: #d3f9d8;
            border: none;
            border-top: 2px solid ${color.Colors.green};
            border-bottom: 2px solid ${color.Colors.green};
            color: ${color.Colors.green};
            box-sizing: border-box;
          }
        }
        tr:nth-child(2n) {
          td {
            background: inherit;
            transition: 0.25s;
          }
          td:hover {
            background: ${lighten(0.06, `${color.Colors.line}`)};
            cursor: pointer;
            transition: 0.25s;
          }
          .now_time {
            background: #d3f9d8;
            border: none;
            border-top: 2px solid ${color.Colors.green};
            border-bottom: 2px solid ${color.Colors.green};
            color: ${color.Colors.green};
            box-sizing: border-box;
          }
        }
      }
    }

    ${BreakPoints.md} {
      table {
        width: calc(1024px - (${HorizontalGlobalPadding.md} * 2));
      }
    }
  }

  // 타석이용, 개인수업에서 사용
  .campus_res_table {
    width: 100%;
    background: ${color.Colors.white};
    padding: 1.2rem;
    border-radius: 5px;
    border: 1px solid ${color.Colors.line};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
    table {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;
      .top_summary {
        font-size: 16px;
        font-weight: bold;
      }
      .top_date {
        font-size: 28px;
        font-weight: normal;
      }
      .sat {
        color: ${color.Colors.blue};
      }
      .sun {
        color: ${color.Colors.red};
      }
      .today {
        border: 2px solid ${color.Colors.green};
        color: ${color.Colors.green};
        box-sizing: border-box;
      }
      thead {
        tr {
          th:first-child {
            width: 3.6em;
            box-sizing: border-box;
          }
          th {
            height: 5.5rem;
            border-bottom: 1px solid ${lighten(0.06, `${color.Colors.line}`)};
            box-sizing: border-box;
          }
        }
      }
      tbody {
        tr {
          td:first-child {
            border: 0;
            font-size: 14px;
            font-weight: bold;
            color: ${color.Colors.primary};
            position: relative;
            top: -14px;
            background: inherit !important;
            box-sizing: border-box;
          }
          td {
            border: 0.05rem solid ${lighten(0.06, `${color.Colors.line}`)};
            box-sizing: border-box;
            text-align: center;
            //height: 2.5rem; //제주 캠퍼스 타임테이블의 높이
            height: 7.5rem; //제주 캠퍼스 타임테이블의 높이
            background: ${color.Colors.background};
            transition: 0.25s;
          }
          td:hover {
            background: ${lighten(0.06, `${color.Colors.line}`)};
            cursor: pointer;
            transition: 0.25s;
          }
          .now_time {
            background: #d3f9d8;
            border: none;
            border-top: 2px solid ${color.Colors.green};
            border-bottom: 2px solid ${color.Colors.green};
            color: ${color.Colors.green};
            box-sizing: border-box;
          }
        }
        tr:nth-child(2n) {
          td {
            background: inherit;
            transition: 0.25s;
          }
          td:hover {
            background: ${lighten(0.06, `${color.Colors.line}`)};
            cursor: pointer;
            transition: 0.25s;
          }
          .now_time {
            background: #d3f9d8;
            border: none;
            border-top: 2px solid ${color.Colors.green};
            border-bottom: 2px solid ${color.Colors.green};
            color: ${color.Colors.green};
            box-sizing: border-box;
          }
        }
      }
    }
  }
`;
