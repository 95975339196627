import React from 'react';
import * as Styled from './styles';

interface textProps {
  title?: string;
}

const GOEmptyText = (props: textProps) => {
  return (
    <Styled.Container>
      <Styled.Wrapper>
        <Styled.EmptyText>{props.title} 데이터가 없습니다.</Styled.EmptyText>
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default GOEmptyText;
