import React, { useState } from 'react';
import GOFloatingbox from '../../common/GOFloatingbox';
import GOButton from '../../common/GOButton';
import { useTranslation } from 'react-i18next';
import GODate from '../../common/GODate';
import SmallTitle from '../../common/SmallTitle';
import './index.scss';
import GOLabel from '../../common/GOLabel';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { NetUserTicketPauseAdd, NetUserTicketPauseDel } from '../../../actions';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

const TicketPause = ({ onClickExit, data, update }: any) => {
  const { t } = useTranslation();
  const [date, setDate] = useState<any>({
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs()
      .add(7, 'd')
      .format('YYYY-MM-DD'),
  });

  const onAdd = () => {
    let i = 0;
    data.pauses.forEach((part: any) => {
      if (
        (date.startDate < part.startDate && date.endDate < part.startDate) ||
        (date.startDate > part.endDate && date.endDate > part.endDate)
      ) {
      } else {
        i++;
      }
    });

    if (date.endDate > date.startDate) {
      if (i === 0) {
        NetUserTicketPauseAdd(
          {
            startDate: date.startDate,
            endDate: date.endDate,
          },
          data.id,
        ).then(() => {
          if (update) {
            update();
          }
        });
      } else {
        toast.error('겹치는 시간대가 존재합니다.');
      }
    } else {
      toast.error('시작일이 종료일보다 이전일 수 없습니다.');
    }
  };

  const onDelete = (id: any, days: any) => {
    NetUserTicketPauseDel(id).then(() => {
      if (update) {
        update();
      }
    });
  };

  return (
    <GOFloatingbox width={750}>
      <UFloatingContainer>
        <UFloatingBoxHeader>
          <h1>{t('user.pause')}</h1>
          <p>{t('user.pauseInfo')}</p>
        </UFloatingBoxHeader>
        <UFloatingBoxBody flex>
          <div className="flex_box">
            <SmallTitle body="정지 기간" />
            <GOLabel
              label="이용기간"
              body={`${dayjs(data.startDate).format('YYYY. MM. DD')} ~ ${dayjs(data.endDate).format(
                'YYYY. MM. DD',
              )}`}
            />
            <br />
            <SmallTitle body="일시정지 기간설정" />
            <GODate
              label="시작"
              minDate={new Date(data.startDate)}
              maxDate={new Date(data.endDate)}
              selected={new Date(date.startDate)}
              onChange={(data: string) =>
                setDate({
                  ...date,
                  startDate: dayjs(data).format('YYYY-MM-DDT00:00:00'),
                })
              }
            />
            {/*TODO 제주점의 경우는 방학이 있어서 일시정지 종료기간을 길게 ~~~ feature/20211104/changePwd*/}
            <GODate
              label="종료"
              minDate={new Date(data.startDate)}
              maxDate={
                data.branchID !== undefined &&
                data.branchID !== null &&
                (data.branchID === -1 || data.branchID === 14)
                  ? new Date(
                      dayjs(data.endDate)
                        .add(6, 'month')
                        .format('YYYY-MM-DDT00:00:00'),
                    )
                  : new Date(data.endDate)
              }
              selected={new Date(date.endDate)}
              onChange={(data: string) =>
                setDate({
                  ...date,
                  endDate: dayjs(data).format('YYYY-MM-DDT00:00:00'),
                })
              }
            />
            <div className="center">
              <GOButton body="추가" buttontype="primary" onClick={onAdd} />
            </div>
            <br />
          </div>
          <div className="flex_box">
            <SmallTitle body="설정된 정지기간" />
            {data.pauses.map((d: any) => (
              <div className="pause_date" key={d.id}>
                <span>
                  {dayjs(d.startDate).format('YYYY. MM. DD')} ~{' '}
                  {dayjs(d.endDate).format('YYYY. MM. DD')} (
                  {dayjs(d.endDate).diff(dayjs(d.startDate), 'd') + 1}일)
                </span>
                <div
                  className="del"
                  onClick={() => onDelete(d.id, dayjs(d.endDate).diff(dayjs(d.startDate), 'd'))}
                >
                  ✕
                </div>
              </div>
            ))}
          </div>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton body={t('common.no')} onClick={onClickExit} />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};

export default TicketPause;
