import styled, { keyframes } from 'styled-components';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const golayout = keyframes`
  0% {
    opacity: 0;
    left: -20px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
`;

export const Layout = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;

  ${BreakPoints.sm} {
    flex-direction: column;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(50% - 10px);
  flex: 1 1 auto;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: white;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  animation: ${golayout} 0.35s;
  min-height: 600px;

  &:not(:last-child) {
    margin-right: 20px;
  }

  ${BreakPoints.sm} {
    width: 100%;
    padding: 10px;
    min-height: initial;
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 15px;
    }
    &:last-child {
      width: 100%;
    }
  }
`;

export const LessonDetailList = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: bold;
  color: #495057;
  justify-content: space-between;
  animation: ${golayout} 0.3s;
`;

export const ConfirmText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #12b886;
  cursor: pointer;
`;

export const NonConfirmText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #fa5252;
  cursor: pointer;
`;

export const PendingText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #495057;
`;

export const Text = styled.div`
  font-size: 14px;
  color: #495057;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-height: 100px;
`;

export const Panel = styled.button`
  position: relative;
  width: 100%;
  flex-grow: 1;
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  margin: 0 0 8px;
  cursor: pointer;
  transition: 0.35s;
  color: #495057;
  background: white;
  display: block;
  outline: 0;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.16);
    transition: 0.35s;
  }
  &:focus {
    background-color: #495057;
    color: white;
    outline: 0;
  }

  ${BreakPoints.md} {
    padding: 8px 10px;
  }

  ${BreakPoints.sm} {
    padding: 5px 10px;
  }
`;

export const PanelTop = styled.div`
  display: flex;
  align-items: center;
`;

export const PanelCircle = styled.div`
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background: #eee;
  margin-right: 12px;
`;

export const PanelAvatar = styled.img``;

export const PanelInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const PanelName = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 6px;
`;

export const PanelDetail = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #adb5bd;
`;

export const IconBox = styled.div`
  width: 24px;
  height: 24px;
  margin-left: auto;
  ${BreakPoints.sm} {
    transform: rotate(90deg);
  }
`;
