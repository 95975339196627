import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import * as Styled from './styles';
import { SmallTitle, GOLabel, GODropbox, GOButton, GOLayout } from '../../../components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { phoneNumberFormatting } from '../../../middlewares';
import GOEmptyText from '../../common/GOEmptyText';
import ReservationCountTable from '../ReservationCountTable';
import { employeeDetailTypes } from '../../../Types/employeeType';

import ReactPaginate from 'react-paginate';
// import './paginateStyles.css';
// import { NetEmployeeUserManage } from '../../../actions';
import { makeYearDataForComboBox } from '../../../util/converter';

const Detail = ({
  employeeDetailData,
  userListData,
  onMonthChanged,
  queryMonth,
  onFilterChanged,
  filter,
  attendanceCount,
  reservationCount,
  absenceCount,
  queryYear,
  onYearChanged,
}: employeeDetailTypes) => {
  const { t } = useTranslation();
  const employeeLessonData = employeeDetailData.workTimes;
  const lessonInterval = employeeDetailData.lessonInterval;

  const yearComboData = makeYearDataForComboBox();

  const pageSize = 50;
  const [pagedData, setPagedData] = useState<any>([]);
  const [pageCount, setPageCount] = useState<number>(userListData.length / pageSize);
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    setPagedData(userListData.slice(0, pageSize));
    setPageCount(userListData.length / pageSize);
    setCurrentPage(0);
  }, [userListData]);

  const onPageChange = (data: any) => {
    // setCurrentPageIndex(data.selected);
    setCurrentPage(data.selected);
    setPagedData(userListData.slice(data.selected * pageSize, data.selected * pageSize + pageSize));
  };

  const formatPhoneNumber = (phoneNumberString: string) => {
    if (phoneNumberString.length > 1) {
      return (
        '' +
        phoneNumberString.slice(0, 3) +
        '-' +
        phoneNumberString.slice(3, 7) +
        '-' +
        phoneNumberString.slice(7, phoneNumberString.length)
      );
    }
    return null;
  };

  const formatStatus = (status: number) => {
    if (status === 0) {
      return '예약완료';
    } else if (status === 1) {
      return '출석';
    } else if (status === 2) {
      return '결석';
    } else {
      return '';
    }
  };

  const excelData = userListData.map((user: any) => {
    return {
      name: user.userName,
      phoneNumber: formatPhoneNumber(user.phoneNumber),
      reservationDate: `${dayjs(user.startDate).format('YYYY-MM-DD a h:mm')} ~ ${dayjs(
        user.endDate,
      ).format('a h:mm')}`,
      ticketName: user.ticketName,
      status: formatStatus(user.status),
      lessonPro: employeeDetailData?.employee ? employeeDetailData?.employee.name : '',
    };
  });

  const lessonData = employeeLessonData?.slice().sort((a: any, b: any) => {
    return a['day'] - b['day'];
  });

  const days = [1, 2, 3, 4, 5, 6, 0];

  const birthday = (birth: any) => {
    const today = new Date().getFullYear();
    const myBirth = new Date(birth).getFullYear();
    return today - myBirth;
  };

  const viewDay = (data: any) => {
    if (data === 1) return `월요일`;
    if (data === 2) return `화요일`;
    if (data === 3) return `수요일`;
    if (data === 4) return `목요일`;
    if (data === 5) return `금요일`;
    if (data === 6) return `토요일`;
    if (data === 0) return `일요일`;
  };

  const viewWorkStartTime = (data: any) => {
    let name = '';
    let hour = '';
    let minute = '';

    // 오전, 오후 조건
    if (data.startHour > 11) name = '오후';
    else name = '오전';
    if (data.startHour === 24) name = '오전';

    // 오전 시간 00:00 ~ 11:00
    // 오후 시간 12:00 ~ 23:00
    if (data.startHour < 10) {
      hour = `0${data.startHour}`;
    } else if (data.startHour > 10 && data.startHour < 13) {
      hour = `${data.startHour}`;
    } else if (data.startHour > 12 && data.startHour < 22) {
      hour = `0${data.startHour - 12}`;
    } else if (data.startHour > 21) {
      hour = `${data.startHour - 12}`;
    }
    if (data.startHour === 24) {
      hour = `0${data.startHour - 24}`;
    }
    if (data.startHour === 10) hour = `${data.startHour}`;

    // 분 표시
    if (data.startMinute < 10) minute = `0${data.startMinute}`;
    else minute = data.startMinute;

    return `${name} ${hour}:${minute}`;
  };

  const viewWorkEndTime = (data: any) => {
    let name = '';
    let hour = '';
    let minute = '';

    // 오전, 오후 조건
    if (data.endHour > 11) name = '오후';
    else name = '오전';
    if (data.endHour === 24) name = '오전';

    // 오전 시간 00:00 ~ 11:00
    // 오후 시간 12:00 ~ 23:00
    if (data.endHour < 10) {
      hour = `0${data.endHour}`;
    } else if (data.endHour > 10 && data.endHour < 13) {
      hour = `${data.endHour}`;
    } else if (data.endHour > 12 && data.endHour < 22) {
      hour = `0${data.endHour - 12}`;
    } else if (data.endHour > 21) {
      hour = `${data.endHour - 12}`;
    }
    if (data.endHour === 24) {
      hour = `0${data.endHour - 24}`;
    }
    if (data.endHour === 10) hour = `${data.endHour}`;

    // 분 표시
    if (data.endMinute < 10) minute = `0${data.endMinute}`;
    else minute = data.endMinute;

    return `${name} ${hour}:${minute}`;
  };

  return (
    <>
      <GOLayout
        body={
          <>
            <Styled.LayoutWrapper>
              {employeeDetailData && (
                <>
                  <Styled.Box>
                    <SmallTitle body={t('employee.info')} />
                    <Styled.UserDetailProfile>
                      <Styled.Text>
                        <h4>{employeeDetailData?.employee?.name}</h4>
                        <h6>{employeeDetailData?.employee?.dateOfBirth.split('T')[0]}</h6>
                        <h5>
                          {birthday(employeeDetailData?.employee?.dateOfBirth)}세 /{' '}
                          {employeeDetailData?.employee?.sex === 0
                            ? t('common.male')
                            : t('common.female')}
                        </h5>
                      </Styled.Text>
                    </Styled.UserDetailProfile>
                    <hr />
                    <GOLabel
                      label={t('common.address')}
                      body={
                        employeeDetailData?.address ? employeeDetailData?.address?.roadAddress : '-'
                      }
                    />
                    <GOLabel
                      label={t('common.phoneNumber')}
                      body={phoneNumberFormatting(employeeDetailData?.employee?.phoneNumber)}
                    />
                    <hr />
                    <GOLabel
                      label="직무"
                      body={
                        employeeDetailData?.employee?.type === 0
                          ? '관리자'
                          : '매니져' && employeeDetailData?.employee?.type === 2
                          ? '레슨프로'
                          : '매니져'
                      }
                    />
                    {lessonInterval &&
                      employeeDetailData?.employee?.type === 2 &&
                      lessonInterval > 0 && (
                        <>
                          <GOLabel label="레슨시간" body={`${lessonInterval}분`} />
                        </>
                      )}
                    <br />
                  </Styled.Box>
                  <Styled.Box>
                    <SmallTitle body={t('employee.schedule')} />
                    {days.map((d: any, index: number) => (
                      <React.Fragment key={index}>
                        {lessonData[d].day === d && (
                          <>
                            <GOLabel
                              label={`${viewDay(d)}`}
                              body={
                                lessonData[d].day === d &&
                                lessonData[d]?.startHour === null &&
                                lessonData[d]?.startMinute === null
                                  ? '-'
                                  : `${viewWorkStartTime(lessonData[d])} 
                   ~ ${viewWorkEndTime(lessonData[d])}`
                              }
                            />
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  </Styled.Box>
                  <Styled.Box>
                    <SmallTitle body={t('employee.confirmLesson')} />
                    {employeeDetailData?.lessonLogs?.length > 0 ? (
                      <>
                        {employeeDetailData.lessonLogs.map((lessonLog: any, index: number) => (
                          <React.Fragment key={index}>
                            <Styled.ConfirmLessonPanel>
                              <Styled.Info>{lessonLog.user.name} 회원님</Styled.Info>
                              <Styled.Start />
                              <Styled.End>
                                <div className="label">작성일</div>
                                <div>{dayjs(lessonLog.createdAt).format('YYYY-MM-DD')} /</div>
                                <div>{dayjs(lessonLog.createdAt).format('HH:mm')}</div>
                              </Styled.End>
                            </Styled.ConfirmLessonPanel>
                          </React.Fragment>
                        ))}
                      </>
                    ) : (
                      <>
                        <GOEmptyText title="레슨 포스트" />
                      </>
                    )}
                  </Styled.Box>
                </>
              )}
            </Styled.LayoutWrapper>
            {employeeDetailData.employee.type === 2 && (
              <Styled.LayoutWrapper>
                <Styled.Box fullWidth>
                  <SmallTitle
                    body={`${queryYear ? queryYear : '2022'}년 ${
                      queryMonth ? queryMonth + 1 : 1
                    }월 예약 회원 리스트 [ 총 데이타 수 : ${
                      userListData?.length
                    }, 출석수 : ${attendanceCount || '없음'}, 예약수: ${reservationCount ||
                      '없음'}, 결석수: ${absenceCount || '없음'}  ]`}
                  />

                  <Styled.Checkbox>
                    {/* feature/2021/1214iOSMultibranch*/}
                    <GODropbox
                      data={yearComboData}
                      value={queryYear}
                      onChange={(e: any) => {
                        onYearChanged && onYearChanged(Number(e.target.value));
                      }}
                      xsmall
                    />
                    <GODropbox
                      data={[
                        { body: '1월', value: 0 },
                        { body: '2월', value: 1 },
                        { body: '3월', value: 2 },
                        { body: '4월', value: 3 },
                        { body: '5월', value: 4 },
                        { body: '6월', value: 5 },
                        { body: '7월', value: 6 },
                        { body: '8월', value: 7 },
                        { body: '9월', value: 8 },
                        { body: '10월', value: 9 },
                        { body: '11월', value: 10 },
                        { body: '12월', value: 11 },
                      ]}
                      value={queryMonth}
                      onChange={(e: any) => {
                        onMonthChanged && onMonthChanged(Number(e.target.value));
                      }}
                      xsmall
                    />
                    <div className="checkbox_group">
                      <div className="inputWrapper">
                        <input
                          type="checkbox"
                          name="total"
                          onChange={onFilterChanged}
                          checked={filter?.total}
                        />
                        <label className="checkLabel">전체</label>
                      </div>
                      <div className="inputWrapper">
                        <input
                          type="checkbox"
                          name="attendance"
                          onChange={onFilterChanged}
                          checked={filter?.attendance}
                        />
                        <label className="checkLabel">출석</label>
                      </div>
                      <div className="inputWrapper">
                        <input
                          type="checkbox"
                          name="reservation"
                          onChange={onFilterChanged}
                          checked={filter?.reservation}
                        />
                        <label className="checkLabel">예약중</label>
                      </div>
                      <div className="inputWrapper">
                        <input
                          type="checkbox"
                          name="absence"
                          onChange={onFilterChanged}
                          checked={filter?.absence}
                        />
                        <label className="checkLabel">결석</label>
                      </div>
                    </div>
                    {!(
                      filter?.attendance ||
                      filter?.absence ||
                      filter?.reservation ||
                      filter?.total
                    ) ? (
                      <> </>
                    ) : (
                      <CSVLink
                        headers={[
                          { label: '이름', key: 'name' },
                          { label: '전화번호', key: 'phoneNumber' },
                          { label: '예약날짜', key: 'reservationDate' },
                          { label: '상품명', key: 'ticketName' },
                          { label: '상태', key: 'status' },
                          { label: '프로명', key: 'lessonPro' },
                        ]}
                        data={excelData}
                        filename={`${queryMonth ? queryMonth + 1 : 1}월 예약 회원 리스트.csv`}
                        target="_blank"
                      >
                        <GOButton body="엑셀로 다운로드" onClick={() => {}} />
                      </CSVLink>
                    )}
                  </Styled.Checkbox>
                  {/*<ReservationCountTable data={ userListData} />*/}
                  <ReservationCountTable
                    data={pagedData}
                    startPageNumber={pageSize * currentPage}
                  />
                  <ReactPaginate
                    pageRangeDisplayed={10}
                    marginPagesDisplayed={5}
                    previousLabel={'← 이전'}
                    nextLabel={'다음 →'}
                    pageCount={pageCount}
                    onPageChange={onPageChange}
                    containerClassName={'pagination'}
                    previousLinkClassName={'pagination__link'}
                    nextLinkClassName={'pagination__link'}
                    disabledClassName={'pagination__link--disabled'}
                    activeClassName={'pagination__link--active'}
                  />
                </Styled.Box>
              </Styled.LayoutWrapper>
            )}
          </>
        }
      />
    </>
  );
};

export default Detail;
