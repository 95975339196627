import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as color from '../../../../color/index';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface chartProps {
  data: any;
  label?: string;
  width?: number;
}

Chart.plugins.unregister(ChartDataLabels);

const TrainingPieChart = ({ data, label, width }: chartProps) => {
  useEffect(() => {
    let chart: any = null;
    const datasets = data.datasets[0];
    if (chart) {
      chart.destroy();
      chart = null;
    }
    const config: any = {
      plugins: [ChartDataLabels],
      type: 'pie',
      data: {
        labels: data.labels,
        datasets: [
          {
            label: datasets.label,
            data: datasets.data,
            backgroundColor: datasets?.backgroundColor,
            borderColor: datasets?.borderColor,
            borderWidth: 1,
            datalabels: {
              color: '#fff',
              font: {
                size: 14,
                weight: 'bold',
              },
              formatter: (value: any, ctx: any) => {
                let sum = 0;
                let dataArr: any = ctx.chart.data.datasets[0].data;
                for (let i = 0; i < dataArr.length; i++) {
                  sum += dataArr[i];
                }
                let mathValue = Math.round((value * 100) / sum);
                let percentage = mathValue + '%';
                return mathValue > 0 ? percentage : null;
              },
            },
          },
        ],
      },
      options: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
          labels: {
            usePointStyle: true,
            padding: 15,
            boxWidth: 20,
          },
        },
        tooltips: {
          displayColors: true,
          callbacks: {
            mode: 'x',
            title: (item: any, data: any) => {
              let index = item[0].index;
              return data.labels[index];
            },
            label: (item: any, data: any) => {
              let index = item.index;
              return `${data.datasets[0].data[index]}`;
            },
          },
        },
      },
    };
    const ctx: HTMLElement | any = document.getElementById('userCountSex');
    chart = new Chart(ctx, config);
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [data]);
  return (
    <Container>
      <ChartWrapper>
        <canvas id="userCountSex" width="400" height="400" />
      </ChartWrapper>
      {label && (
        <div>
          <Title>{label}</Title>
          <RowLine />
        </div>
      )}
    </Container>
  );
};

const Container = styled.div``;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: ${color.Colors.primary};
`;

const RowLine = styled.div`
  width: 28vw;
  height: 1px;
  background: ${color.Colors.sub};
  margin-top: 10px;
  margin-bottom: 30px;
`;

const ChartWrapper = styled.div`
  max-width: 400px;
  margin: auto;
`;

export default TrainingPieChart;
