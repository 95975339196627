import React, { useEffect, useState } from 'react';
import Grid from '@toast-ui/react-grid';
import { GODropbox } from '../../../components';
import * as Styled from './styles';
import { NetGolfPowerMonthlyExamStatus } from '../../../actions';
import TestStateBoard from '../../../components/smartadvisor/TestStatus';
import { makeMonthlyExamIDForComboBox } from '../../../util/converter';

const gridColumns: any = [
  {
    name: 'ranking',
    header: '평균점수순위',
    align: 'center',
    minWidth: 110,
  },
  {
    name: 'name',
    header: '응시자명',
    align: 'center',
    minWidth: 90,
  },
  {
    name: 'avg',
    header: '평균',
    align: 'center',
    minWidth: 90,
  },
  {
    name: 'putt',
    header: 'Putting력',
    align: 'center',
    minWidth: 90,
  },
  {
    name: 'sg',
    header: 'S/G력',
    align: 'center',
    minWidth: 90,
  },
  {
    name: 'iron',
    header: 'IRON력',
    align: 'center',
    minWidth: 90,
  },
  {
    name: 'drv',
    header: 'DRV력',
    align: 'center',
    minWidth: 90,
  },
  {
    name: 'wu',
    header: 'W/U력',
    align: 'center',
    minWidth: 90,
  },
  // {
  //   name: 'improvedScore',
  //   header: '향상된 점수',
  //   align: 'center',
  //   minWidth: 90,
  // },
  // {
  //   name: 'improvedRanking',
  //   header: '향상된 순위',
  //   align: 'center',
  //   minWidth: 90,
  // },
];

const pageOption: any = {
  useClient: true,
  perPage: 100,
};

const TestState = () => {
  const [loading, setLoading] = useState(true);
  const [res, setRes] = useState();

  const comboBox = makeMonthlyExamIDForComboBox();

  const [selectedYearMonth, setSelectedYearMonth] = useState(comboBox[comboBox.length - 1].value);

  console.log(comboBox);
  useEffect(() => {
    const fetchAPI = async () => {
      await NetGolfPowerMonthlyExamStatus(selectedYearMonth.toString()).then((res: any) => {
        setRes(res);
        setLoading(false);
      });
    };
    fetchAPI();
  }, [selectedYearMonth]);

  return (
    <TestStateBoard
      loading={loading}
      gridColumns={gridColumns}
      pageOption={pageOption}
      res={res}
      comboBoxData={comboBox}
      selectedYearMonth={selectedYearMonth}
      setSelectedYearMonth={setSelectedYearMonth}
    />
  );
};

export default TestState;
