import NetworkManager from "../../../NetworkManager";

const Computer = (lkey: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager("post", `admin/plate/control/computer/${lkey}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default Computer;
