import styled from 'styled-components';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const Container = styled.div``;

export const ErrorText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #fa5252;
  margin-top: 12px;
  margin-bottom: 24px;
`;

export const Number = styled.div`
  max-width: 100%;
  height: 100%;
  padding: 0px 70px 20px 70px;
  font-size: 36px;
  font-weight: 700;
  color: #495057;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${BreakPoints.sm} {
    max-width: 100%;
  }
`;

export const NumberText = styled.input`
  width: 240px;
  height: 28px;
  margin-top: 20px;
  padding-bottom: 20px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #12b886;

  text-align: center;
  font-size: 32px;
  color: #495057;
  letter-spacing: 20px;

  &:disabled {
    background: #fff;
  }

  ${BreakPoints.sm} and (orientation:landscape) {
    width: 100%;
  }
`;

export const NumberPad = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 240px;
  ${BreakPoints.sm} and (orientation:landscape) {
    width: 480px;
  }
`;

export const Pad = styled.div`
  width: 58px;
  height: 58px;
  text-align: center;
  line-height: 55px;
  font-size: 36px;
  font-weight: 700;
  color: #495057;
  background: #fff;
  border: 1px solid #495057;
  border-radius: 50%;
  margin: 0px 10px 20px 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  transition: 0.35s;

  &:hover {
    background: #495057;
    color: #fff;
    border: 1px solid #495057;
    transition: 0.35s;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.3);
  }
`;
