import React, { useState, useEffect } from 'react';
import * as Styled from './styles';
import dayjs from 'dayjs';
import { listTypes, totalListTypes } from '../../../Types/employeeType';
import { phoneNumberFormatting } from '../../../middlewares';
import { SmallTitle, GOLoading } from '../../../components';
import { Link } from 'react-router-dom';

interface tableProps {
  data?: listTypes[];
  totalData?: totalListTypes[] | any;
  total?: boolean;
  totalCount?: number;
  startPageNumber?: number;
}

const ReservationCountTable = ({
  data,
  totalData,
  total,
  totalCount,
  startPageNumber,
}: tableProps) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const statusText = (status: number) => {
    if (status === 0) return <Styled.StatusText>예약 중</Styled.StatusText>;
    else if (status === 1) return <Styled.StatusText checkin>출석</Styled.StatusText>;
    else return <Styled.StatusText absent>결석</Styled.StatusText>;
  };

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  const onScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <>
      {total ? (
        <>
          <Styled.Box>
            {totalData ? (
              <>
                <SmallTitle body={`건수 [${totalCount}]`} />
                <Styled.Table>
                  <table>
                    <thead>
                      <tr>
                        <td>번호</td>
                        <td>이름</td>
                        <td>전화번호</td>
                        <td>시작 날짜</td>
                        <td>종료 날짜</td>
                        <td>보유 티켓</td>
                        <td>티켓 종류</td>
                      </tr>
                    </thead>
                    <tbody>
                      {totalData.map((t: any, index: number) => (
                        <tr key={index}>
                          <td>{index + 1 + (startPageNumber || 0)}</td>
                          <td className="name">
                            <Link to={`/admin/user/${t.id}`}>
                              <Styled.Flexbox>
                                <Styled.ProfileImage
                                  style={{
                                    background:
                                      t.profileImageURL &&
                                      `url(${t.profileImageURL}) center / cover`,
                                  }}
                                />
                                <div>{t.userName}</div>
                              </Styled.Flexbox>
                            </Link>
                          </td>
                          <td>{phoneNumberFormatting(t.phoneNumber)}</td>
                          <td>{dayjs(t.startDate).format('YYYY. MM. DD')}</td>
                          <td>{dayjs(t.endDate).format('YYYY. MM. DD')}</td>
                          <td>{t.ticketName}</td>
                          {t.isUnlimited === 1 ? (
                            <td>
                              <Styled.ResDetail>무제한 이용권</Styled.ResDetail>
                            </td>
                          ) : (
                            <td>
                              <Styled.ResDetail total>전체: {t.totalCount}회</Styled.ResDetail>
                              <Styled.ResDetail checkin>
                                사용: {t.totalCount - t.remainingCount}회
                              </Styled.ResDetail>
                              <Styled.ResDetail remain>잔여: {t.remainingCount}회</Styled.ResDetail>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Styled.Table>
              </>
            ) : (
              <GOLoading />
            )}
          </Styled.Box>
          <Styled.Icon
            className="fas fa-arrow-circle-up"
            visible={scrollPosition > 120 ? true : false}
            onClick={handleScroll}
          />
        </>
      ) : (
        <>
          {data ? (
            <Styled.Table>
              <table>
                <thead>
                  <tr>
                    <td>번호</td>
                    <td>이름</td>
                    <td>전화번호</td>
                    <td>예약 날짜</td>
                    <td>사용 티켓</td>
                    <td>상태</td>
                  </tr>
                </thead>
                <tbody>
                  {data.map((d: any, index: number) => (
                    <tr key={index}>
                      <td>{index + 1 + (startPageNumber || 0)}</td>
                      {/*<td>*/}
                      {/*  <Link to={`/admin/user/${d.userId}`}> {d.userName} </Link>*/}
                      {/*</td>*/}

                      <td className="name">
                        <Link to={`/admin/user/${d.userId}`}>
                          <Styled.Flexbox>
                            <Styled.ProfileImage
                              style={{
                                background:
                                  d.profileImageURL && `url(${d.profileImageURL}) center / cover`,
                              }}
                            />
                            <div> {d.userName}</div>
                          </Styled.Flexbox>
                        </Link>
                      </td>

                      <td>
                        <Link to={`/admin/user/${d.userId}`}>
                          {' '}
                          {phoneNumberFormatting(d.phoneNumber)}
                        </Link>
                      </td>
                      <td>
                        <div>{dayjs(d.startDate).format('YYYY. MM. DD')}</div>
                        <div>{`${dayjs(d.startDate).format('a h:mm')} ~ ${dayjs(d.endDate).format(
                          'a h:mm',
                        )}`}</div>
                      </td>
                      <td>{d.ticketName}</td>
                      <td>{statusText(d.status)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Styled.Table>
          ) : (
            <GOLoading />
          )}
        </>
      )}
    </>
  );
};

export default ReservationCountTable;
