import React from 'react';
import { EmployeeMemberPanel } from '../..';
import ListStatusBar from '../../layout/ListStatusBar';
import * as Styled from './styles';

interface GridViewProps {
  data: any[];
}

const GridView = ({ data }: GridViewProps) => {
  return (
    <div>
      <ListStatusBar leftbody={<>&nbsp;전체</>} rightbody={`${data?.length} 명`} />
      <Styled.GridList>
        {data?.map((e: any, index: number) => (
          <React.Fragment key={index}>
            <EmployeeMemberPanel data={e} link={`/admin/employee/${e.id}`} />
          </React.Fragment>
        ))}
        {/* CSS-hack : Flex 배치를 맞추기 위한 placeholder */}

        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        {/* CSS-hack : Flex 배치를 맞추기 위한 placeholder 끝 */}
      </Styled.GridList>
    </div>
  );
};

export default GridView;
