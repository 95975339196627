import React, { useEffect, useState } from 'react';
import '../../panelPlusStyles.scss';
import { useDispatch } from 'react-redux';
import { GOSwitch } from '../../../../components';
import * as Styled from './styles';
import { actionPostPlateModalStatus } from '../../../../stores/actions/platelogin';
// import { toast } from 'react-toastify';

const Panel = ({ data, reloadPlateModalStatus }: any) => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState<boolean>(false);

  const onPlateModalStatus = () => {
    reloadPlateModalStatus();
  };

  const switchOnClick = () => {
    setLoading(true);
    dispatch(
      actionPostPlateModalStatus(
        {
          modalState: data?.status === 'y' ? 'n' : 'y',
          all: 'n',
          number: Number(data?.number),
        },
        (a: any) => {
          console.log(a.data.Data);
          try {
            const result_code = Number(a.data.Data.result_code);
            if (result_code === 0) {
              onPlateModalStatus();
            } else {
              // toast.error(' 강제 로그인 설정 중 오류 가 발생였습니다.');
              onPlateModalStatus();
            }
          } catch (e) {}
        },
      ),
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [data, dispatch]);

  return (
    <div className={`plate_panel_plus plate_panel_use}`}>
      <div className="number">{data.number > 9 ? data.number : '0' + data.number}</div>
      <hr />
      <h6 className="time">{data?.status === 'y' ? '강제 로그인 활성' : '강제 로그인 비활성'}</h6>
      <hr />
      <div className="flex">
        {isLoading ? (
          <Styled.InnerFlex>
            <div>
              {' '}
              <h6> 설정중 .... </h6>
            </div>
          </Styled.InnerFlex>
        ) : (
          <Styled.InnerFlex>
            <div>
              {' '}
              <h6> {data?.status === 'y' ? '강제 로그인' : '자율'} </h6>
            </div>
            <div>
              <GOSwitch
                style={{ margin: '0' }}
                status={data?.status === 'y'}
                onClick={switchOnClick}
              />
            </div>
          </Styled.InnerFlex>
        )}
      </div>
    </div>
  );
};

export default Panel;
