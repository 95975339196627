import NetworkManager from "../../../NetworkManager";
import { toast } from "react-toastify";

const ResRestore = (id: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager("put", `admin/plateReservation/${id}/restore`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        if (err.response) {
          switch (err.response.status) {
            case 406:
              if (
                err.response.data.message ===
                "check-in can be done within one hour"
              ) {
                toast.error(
                  "⛔️ 출석 처리는 한시간 이내에서만 가능합니다. ( 406 )"
                );
              }
              break;
            default:
              reject(err);
              break;
          }
        }
      });
  });
};

export default ResRestore;
