import React, { useState } from 'react';
import { GOFloatingbox, GOButton, GOLabel, GODropbox, GOInput } from '../../../components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { actionPutUserRefundPayment } from '../../../stores/actions';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import isSameAfter from 'dayjs/plugin/isSameOrAfter';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

dayjs.locale('ko');
dayjs.extend(isSameAfter);

const Refund = ({ setRefund, outData, doUpdate }: any) => {
  const TICKET = outData.ticket;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [penaltyPrice, setPenaltyPrice] = useState<number>(-1);
  const [memo, setMemo] = useState(null);

  const promotionPrice = TICKET.originalPrice - TICKET.discount;

  let dayLeftFromStartDate = 0;
  //startOf를 사용하지 않으면 밀리세컨트를 사용하므로 미묘한 날짜 계산 차이가 발생한다.
  //오늘 00:00시와 23:00은 하루차이가 날수 있다.
  // const day1 = dayjs('2021-10-16')
  // const day2 = dayjs('2021-10-17')
  // console.log( dayjs().startOf('day').diff(day1, 'day'))
  // console.log( dayjs().startOf('day').diff(day2, 'day'))
  // console.log( day1.diff(day2, 'day'))

  if (dayjs().isSameOrAfter(TICKET.startDate, 'day')) {
    dayLeftFromStartDate = dayjs(TICKET.endDate).diff(dayjs().startOf('day'), 'day') + 1;
  } else {
    dayLeftFromStartDate = dayjs(TICKET.endDate).diff(TICKET.startDate, 'day') + 1;
  }

  dayLeftFromStartDate = dayLeftFromStartDate < 0 ? 0 : dayLeftFromStartDate;

  // 무제한 이용권
  let unlimitedTicket = {
    // dayLeft: dayjs(TICKET.endDate).diff(dayjs(), 'day') + 1,
    dayLeft: dayLeftFromStartDate,
    period: dayjs(TICKET.endDate).diff(dayjs(TICKET.startDate), 'day') + 1,
    oneDayPrice: 0,
    priceUsed: 0,
    totalReduction: 0,
    refund: 0,
  };

  // 횟수제 이용권
  let limitedTicket = {
    remainingCount: TICKET.remainingCount,
    usedCount: TICKET.usedCount,
    oneCountPrice: 0,
    usedCountPrice: 0,
    refund: 0,
  };

  if (TICKET.isUnlimited) {
    unlimitedTicket.oneDayPrice = Math.round(TICKET.originalPrice / unlimitedTicket.period);
    unlimitedTicket.priceUsed =
      unlimitedTicket.oneDayPrice * (unlimitedTicket.period - unlimitedTicket.dayLeft);
  } else {
    if (TICKET.type === 4) {
      //락카인 경우
      limitedTicket.oneCountPrice = Math.round(TICKET.originalPrice / TICKET.period);
    } else {
      limitedTicket.oneCountPrice = Math.round(TICKET.originalPrice / TICKET.totalCount);
    }
    limitedTicket.usedCountPrice = Math.round(
      limitedTicket.oneCountPrice * limitedTicket.usedCount,
    );
  }

  if (penaltyPrice !== -1) {
    unlimitedTicket.totalReduction = unlimitedTicket.priceUsed + Number(penaltyPrice);
    unlimitedTicket.refund = Math.round(promotionPrice - unlimitedTicket.totalReduction);
    limitedTicket.refund = limitedTicket.refund = Math.round(
      promotionPrice - (limitedTicket.usedCountPrice + Number(penaltyPrice)),
    );
  } else {
    unlimitedTicket.totalReduction = unlimitedTicket.priceUsed + 0;
    unlimitedTicket.refund = Math.round(promotionPrice - unlimitedTicket.totalReduction);
    limitedTicket.refund = limitedTicket.refund = Math.round(
      promotionPrice - (limitedTicket.usedCountPrice + 0),
    );
  }

  console.log('initial state loadede');

  const [refundedAmount, setRefundedAmount] = useState<number>(
    TICKET.isUnlimited ? unlimitedTicket.refund : limitedTicket.refund,
  );

  const paymentText = (method: number) => {
    if (method === 0) return '카드';
    else if (method === 1) return '현금';
    else return '이체';
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (name === 'commission') {
      setPenaltyPrice(value);
      if (TICKET.isUnlimited) {
        if (value > 0) {
          setRefundedAmount(
            Math.round(promotionPrice - (unlimitedTicket.priceUsed + Number(value))),
          );
        } else {
          setRefundedAmount(Math.round(promotionPrice - unlimitedTicket.priceUsed));
        }
      } else {
        if (value > 0) {
          setRefundedAmount(
            Math.round(promotionPrice - (limitedTicket.usedCountPrice + Number(value))),
          );
        } else {
          setRefundedAmount(Math.round(promotionPrice - limitedTicket.usedCountPrice));
        }
      }
    } else if (name === 'refundAmount') {
      setRefundedAmount(value);
    } else if (name === 'memo') {
      setMemo(value);
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    let data;
    if (TICKET.isUnlimited) {
      data = {
        method: outData.payments[0] ? outData.payments[0].method : 1,
        amount: unlimitedTicket.refund < 0 ? 0 : unlimitedTicket.refund,
        // amount: refundedAmount,
        memo: memo,
      };
    } else {
      data = {
        method: outData.payments[0] ? outData.payments[0].method : 1,
        amount: limitedTicket.refund < 0 ? 0 : limitedTicket.refund,
        memo: memo,
      };
    }

    data.amount = refundedAmount;
    if (window.confirm('환불처리 하시겠습니까?')) {
      // validation
      if (data.amount > promotionPrice) {
        alert(`환불 금액이 ${promotionPrice}원을 초과할 수 없습니다.`);
      } else {
        dispatch(
          actionPutUserRefundPayment(outData.id, data, () => {
            setRefund();
            doUpdate();
          }),
        );
      }
    }
  };

  return (
    <GOFloatingbox width={400}>
      <UFloatingContainer as="form" onSubmit={onSubmit}>
        <UFloatingBoxHeader>
          <h1>{t('user.refund')}</h1>
          <p>{t('user.refundInfo')}</p>
        </UFloatingBoxHeader>
        <UFloatingBoxBody>
          <div className="flex_box">
            <GOLabel
              label="상품 금액(정상가)"
              body={`${TICKET.originalPrice.toLocaleString('en')} ${t('common.KRW')}`}
            />
            <GOLabel
              label="할인 금액"
              body={`${TICKET.discount.toLocaleString('en')} ${t('common.KRW')}`}
            />
            {TICKET.isUnlimited ? (
              //기간권 환불
              <>
                <GOLabel
                  label="잔여 일자"
                  body={`${unlimitedTicket.dayLeft}  ${t('common.days')}`}
                />
                <GOLabel
                  label="하루 금액"
                  body={`${unlimitedTicket.oneDayPrice.toLocaleString('en')} ${t('common.KRW')}`}
                />
                <GOLabel
                  label="위약금* (10%)"
                  body={
                    <>
                      <GODropbox
                        style={{ margin: '0' }}
                        // height="30px"
                        name="commission"
                        data={
                          TICKET.originalPrice === 0
                            ? [{ value: 0, body: '0 원' }]
                            : [
                                { value: 0, body: '0 원' },
                                {
                                  value: TICKET.originalPrice * 0.1,
                                  body: `${(TICKET.originalPrice * 0.1).toLocaleString('en')} ${t(
                                    'common.KRW',
                                  )}`,
                                },
                              ]
                        }
                        onChange={handleChange}
                        value={penaltyPrice}
                        required
                      />
                    </>
                  }
                />
                <GOLabel
                  label="사용한 금액"
                  body={`${unlimitedTicket.priceUsed.toLocaleString('en')} ${t('common.KRW')}`}
                />
                <hr />
                {/*//  기간권 환불에 대한 입력*/}
                {/*{unlimitedTicket.refund < 0 ? (*/}
                {/*  <div style={{ display: 'flex', alignItems: 'center' }}>*/}
                {/*    <GOLabel*/}
                {/*      label="환불 금액"*/}
                {/*      body={`${unlimitedTicket.refund.toLocaleString('en')} ${t('common.KRW')}`}*/}
                {/*      bodyStyle={{ color: '#fa5252', fontWeight: 'bold' }}*/}
                {/*    />*/}
                {/*    <div style={{ fontSize: '14px', marginLeft: '10px' }}>*/}
                {/*      ✽ 0 원으로 처리됩니다.*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*) : (*/}
                {/*  <GOLabel*/}
                {/*    label="환불 금액"*/}
                {/*    body={`${unlimitedTicket.refund.toLocaleString('en')} ${t('common.KRW')}`}*/}
                {/*  />*/}
                {/*)}*/}
                <GOInput
                  label="환불 금액"
                  name="refundAmount"
                  type="number"
                  min="0"
                  max="5000000"
                  value={refundedAmount}
                  onChange={handleChange}
                  required
                />
              </>
            ) : (
              //회차권 환불
              <>
                <GOLabel label="잔여 횟수" body={`${limitedTicket.remainingCount}회`} />
                <GOLabel label="사용 횟수" body={`${limitedTicket.usedCount}회`} />
                <GOLabel
                  label="1회 금액"
                  body={`${limitedTicket.oneCountPrice.toLocaleString('en')} ${t('common.KRW')}`}
                />
                <GOLabel
                  label="위약금* (10%)"
                  body={
                    <>
                      <GODropbox
                        style={{ margin: '0' }}
                        height="30px"
                        name="commission"
                        data={
                          TICKET.originalPrice === 0
                            ? [{ value: 0, body: '0 원' }]
                            : [
                                { value: 0, body: '0 원' },
                                {
                                  value: TICKET.originalPrice * 0.1,
                                  body: `${(TICKET.originalPrice * 0.1).toLocaleString('en')} ${t(
                                    'common.KRW',
                                  )}`,
                                },
                              ]
                        }
                        onChange={handleChange}
                        value={penaltyPrice}
                        xsmall
                        required
                      />
                    </>
                  }
                />
                <GOLabel
                  label="사용한 금액"
                  body={`${limitedTicket.usedCountPrice.toLocaleString('en')} ${t('common.KRW')}`}
                />
                <hr />
                {/*{limitedTicket.refund < 0 ? (*/}
                {/*  <div style={{ display: 'flex', alignItems: 'center' }}>*/}
                {/*    <GOLabel*/}
                {/*      label="환불 금액"*/}
                {/*      body={`${limitedTicket.refund.toLocaleString('en')} ${t('common.KRW')}`}*/}
                {/*      bodyStyle={{ color: '#fa5252', fontWeight: 'bold' }}*/}
                {/*    />*/}
                {/*    <div style={{ fontSize: '14px', marginLeft: '10px' }}>*/}
                {/*      ✽ 0 원으로 처리됩니다.*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*) : (*/}
                {/*  <GOLabel*/}
                {/*    label="환불 금액"*/}
                {/*    body={`${limitedTicket.refund.toLocaleString('en')} ${t('common.KRW')}`}*/}
                {/*  />*/}
                {/*)}*/}
                <GOInput
                  label="환불 금액"
                  name="refundAmount"
                  type="number"
                  min="0"
                  max="5000000"
                  value={refundedAmount}
                  onChange={handleChange}
                  required
                />
              </>
            )}
            <hr />
            {outData.payments[0] && (
              <GOLabel label="환불 방식" body={paymentText(outData.payments[0].method)} />
            )}
            <GOInput label="메모" name="memo" textarea onChange={handleChange} value={memo} />
          </div>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton body="환불처리" buttontype="green" noMarginLeft type="submit" />
          <GOButton
            body="닫기"
            onClick={() => {
              setRefund();
            }}
          />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};

export default Refund;
