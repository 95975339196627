import React from 'react';
import * as Styled from './styles';
import ListStatusBar from '../../layout/ListStatusBar';
import ProductPanel from '../ProductPanel';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onModal } from '../../../stores/modals';

interface GridViewProps {
  data: any[];
  cat?: any;
  update?: any;
  sales?: boolean;
}

const GridView = ({ data, cat, sales }: GridViewProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const buyProduct = (e: any) => {
    history.push(`${history.location.pathname}/${e.id}`);
  };

  return (
    <div>
      <ListStatusBar leftbody={cat.name ? cat.name : '전체'} rightbody={`총 ${data?.length}개`} />
      <Styled.GridList>
        {data?.map((e: any, index: number) => (
          <React.Fragment key={index}>
            <ProductPanel
              data={e}
              category={e.ProductCategory}
              onClick={() => {
                if (sales) {
                  // 상품 판매
                  buyProduct(e);
                } else {
                  // 상품 관리
                  dispatch(onModal('productAdd', { edit: e }));
                }
              }}
            />
          </React.Fragment>
        ))}
        {/* CSS-hack : Flex 배치를 맞추기 위한 placeholder */}
        <Styled.Placeholder />
        <Styled.Placeholder />
        <Styled.Placeholder />
        <Styled.Placeholder />
        <Styled.Placeholder />
        <Styled.Placeholder />
        <Styled.Placeholder />
        <Styled.Placeholder />
        <Styled.Placeholder />
        <Styled.Placeholder />
        {/* CSS-hack : Flex 배치를 맞추기 위한 placeholder 끝 */}
      </Styled.GridList>
    </div>
  );
};

export default GridView;
