import React, { useState, useEffect } from 'react';
import {
  HeaderBottom,
  StatusTool,
  GOLayoutList,
  GOButton,
  GOSearch,
  ProductGridView,
  Footer,
  GOAsyncDiv,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { randomColor } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetProductList, actionDelProductCat } from '../../../stores/actions';
import { onModal } from '../../../stores/modals';
import { UHiddenSM } from '../../../GlobalUtilityStyles';
const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionGetProductList());
  }, [dispatch]);

  // 카테고리 선택
  const [categoryNumber, setCategoryNumber] = useState(0); // 현재 카테고리 ID
  let categoryDetail: any = {};

  // 감섹 데이터
  const [search, setSearch] = useState('');

  // 데이터 Selector
  const pData = useSelector((state: any) => state.product);

  const productsData = useSelector((state: any) => {
    const data: any = state.product.data.Products;

    if (search) {
      return data.filter((pd: any) => pd.name.indexOf(search) !== -1);
    } else if (categoryNumber) {
      categoryDetail = state.product.data.ProductCategories.filter(
        (pc: any) => pc.id * 1 === categoryNumber,
      )[0];

      return data.filter((pc: any) => {
        return pc.ProductCategory.id * 1 === categoryNumber;
      });
    }

    return data;
  });

  const categoryData = useSelector((state: any) => {
    return state.product.data.ProductCategories;
  });

  const plateCategoryData = categoryData?.filter((cd: any) => cd.type === 2);

  const ProductTypeChanger = (value: any) => {
    switch (value) {
      case 0:
        return <>{t('product.personalLesson')}</>;
      case 1:
        return <>{t('product.groupLesson')}</>;
      case 2:
        return <>{t('product.usePlate')}</>;
      case 3:
        return <>{t('product.location')}</>;
      case 4:
        return <>{t('product.locker')}</>;
      case 5:
        return <>{t('product.etc')}</>;
      default:
        return <></>;
    }
  };

  const delCategory = () => {
    if (window.confirm(t('product.isDelCategory'))) {
      dispatch(
        actionDelProductCat(categoryNumber, () => {
          setCategoryNumber(0);
        }),
      );
    }
  };

  const editCategory = () => {
    dispatch(
      onModal('productAddCategory', {
        edit: true,
        ...categoryDetail,
      }),
    );
  };

  return (
    <>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={t('product.list')} />
          </>
        }
        rightmenu={<></>}
      />
      <GOAsyncDiv status={pData.status}>
        <GOLayoutList
          leftbody={
            <>
              <h4>{t('common.search')}</h4>
              <hr />
              <GOSearch
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
                handleSubmit={(e: any) => e.preventDefault()}
              />
              <br />
              <h4>{t('product.category')}</h4>
              <hr />
              <GOButton
                body={t('common.all')}
                width={'100%'}
                noMarginLeft
                style={{ marginTop: 8 }}
                onClick={() => {
                  setCategoryNumber(0);
                }}
              />
              {categoryData &&
                categoryData.map((cd: any) => (
                  <GOButton
                    body={
                      <>
                        <div
                          className="product_circle"
                          style={{
                            background: randomColor(cd.id),
                          }}
                        />
                        {ProductTypeChanger(cd.type)} / {cd.name}
                      </>
                    }
                    width={'100%'}
                    noMarginLeft
                    style={{
                      marginTop: 8,
                      textAlign: 'left',
                      padding: '0 12px',
                    }}
                    key={cd.id}
                    buttontype={cd.id === categoryNumber ? 'primary' : undefined}
                    onClick={() => {
                      setCategoryNumber(cd.id);
                    }}
                  />
                ))}
              <hr />
              <GOButton
                body={t('product.addCategory')}
                buttontype="green"
                width={'100%'}
                noMarginLeft
                onClick={() => {
                  dispatch(onModal('productAddCategory', { categoryDetail, plateCategoryData }));
                }}
              />
            </>
          }
          rightbody={<ProductGridView data={productsData} cat={categoryDetail} />}
        />
      </GOAsyncDiv>
      {categoryNumber !== 0 && (
        <Footer
          body={
            <>
              <h5>
                {categoryDetail.name}
                <UHiddenSM>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;</UHiddenSM>
              </h5>
              <GOButton
                body={t('product.add')}
                onClick={() => {
                  dispatch(onModal('productAdd', categoryDetail));
                }}
                buttontype="green"
              />
              <GOButton
                body={t('product.categoryEdit')}
                buttontype="primary"
                onClick={editCategory}
              />
              <GOButton body="분류 삭제" buttontype="red" onClick={delCategory} />
            </>
          }
        />
      )}
    </>
  );
};
export default List;
