import styled from 'styled-components';
import * as color from '../../../color/index';

export const NoSearch = styled.div`
  padding: 24px;
  text-align: center;
`;

export const TotalUser = styled.h6`
  padding: 3px 5px;
  cursor: pointer;
  transition: 0.35s;

  &:hover {
    background: ${color.Colors.sub};
    transition: 0.35s;
  }
`;
