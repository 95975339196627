import NetworkManager from "../../../NetworkManager";

const EmployeeList = () => {
  return new Promise((resolve, reject) => {
    NetworkManager("get", "admin/product/employeeList")
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default EmployeeList;
