import styled, { keyframes } from 'styled-components';
import { BreakPoints } from '../../../../GlobalUtilityStyles';

const reservationani = keyframes`
  0% {
    opacity: 0;
    top: -12px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
`;

export const LockerView = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  animation: ${reservationani} 0.35s;

  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;

  ${BreakPoints.sm} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const PlatePlaceholder = styled.div`
  width: 140px;
  padding: 0;
  height: 1px;
  margin: 0px 8px;
  flex-grow: 1;
`;
