import React, { useEffect } from 'react';
import * as Styled from './styles';

interface GOFloatingboxProps {
  body?: JSX.Element | undefined;
  children?: any;
  width?: number;
}

const GOFloatingbox = (props: GOFloatingboxProps) => {
  useEffect(() => {
    // 모바일사이즈(sm)에서 모달이 열렸을 때 body 스크롤 금지
    const body = document.querySelector('body') as HTMLBodyElement;
    body.classList.add('modal-opend');

    return function() {
      body.classList.remove('modal-opend');
    };
  }, []);

  return (
    <>
      <Styled.BackgroundView />
      <Styled.FloatingBoxContainer>
        <Styled.FloatingBoxWrapper style={{ width: props.width }}>
          {props.body}
          {props.children}
        </Styled.FloatingBoxWrapper>
      </Styled.FloatingBoxContainer>
    </>
  );
};

export default GOFloatingbox;
