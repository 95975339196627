import styled from 'styled-components';
import * as color from '../../../../color/index';

export const StartButton = styled.button`
  flex-shrink: 0;
  word-break: keep-all;
  width: 100%;
  height: 43px;
  font-size: 14px;
  font-weight: bold;
  background: ${color.Colors.primary};
  color: ${color.Colors.white};
  border-radius: 5px;
  border: 1px solid ${color.Colors.line};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  transition: 0.25s;
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;

  @media (max-width: 1300px) {
    font-size: 12px;
  }

  &:hover {
    color: ${color.Colors.white};
    background: #353a40;
    transition: 0.25s;
  }

  &:focus {
    outline: none;
  }
`;

export const EndButton = styled.button`
  flex-shrink: 0;
  word-break: keep-all;
  width: 100%;
  height: 43px;
  font-size: 14px;
  font-weight: bold;
  background: ${color.Colors.white};
  color: ${color.Colors.primary};
  border-radius: 5px;
  border: 1px solid ${color.Colors.line};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  transition: 0.25s;
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;

  @media (max-width: 1300px) {
    font-size: 12px;
  }

  &:hover {
    color: ${color.Colors.primary};
    background: #f2f2f2;
    transition: 0.25s;
  }

  &:focus {
    outline: none;
  }
`;

export const DisableButton = styled.button`
  flex-shrink: 0;
  word-break: keep-all;
  width: 100%;
  height: 43px;
  font-size: 14px;
  font-weight: bold;
  background: ${color.Colors.sub};
  color: ${color.Colors.effect};
  border-radius: 5px;
  border: 1px solid ${color.Colors.sub};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  transition: 0.25s;
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;

  @media (max-width: 1300px) {
    font-size: 12px;
  }

  &:hover {
    color: ${color.Colors.effect};
    background: ${color.Colors.subEffect};
    transition: 0.25s;
  }

  &:focus {
    outline: none;
  }
`;
