import styled from 'styled-components';
import { darken } from 'polished';
import * as color from '../../../color/index';
import { BreakPoints, HorizontalGlobalPadding } from '../../../GlobalUtilityStyles';

export const LeftItem = styled.div``;

export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${color.Colors.primary};
  text-align: left;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 40px;

  .GODropbox {
    margin-bottom: 0;
    margin-left: auto;
  }

  ${BreakPoints.sm} {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .title {
    margin-right: 10px;
    &.depth-2 {
      font-size: 18px;
    }
  }

  .grp-status {
    font-size: 14px;
    margin-right: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    line-height: 1.3;
    flex-wrap: wrap;
    letter-spacing: -1px;

    .tag-timer {
      color: #ff5252;
      padding: 0 8px;
    }
    .tag-status {
      font-size: 12px;
      padding: 0 10px;
      background: #446dff;
      color: #fff;
      border-radius: 11px;
      height: 22px;
      margin: 5px 0;
      display: flex;
      align-items: center;
    }
  }
`;

export const SummaryList = styled.ul`
  &,
  li {
    list-style: none;
    font-size: 14px;
    font-weight: 600;
  }

  display: flex;
  flex-wrap: wrap;
  margin-bottom: 28px;
`;

export const ListItem = styled.li`
  background: #f5f5f5;
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  width: 40%;
  margin-bottom: 12px;

  &:nth-of-type(odd) {
    margin-right: 12px;
  }

  .label {
    width: 160px;
    flex: 1 0 auto;
    white-space: nowrap;
  }

  .status {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
    width: 160px;
    flex: 1 0 auto;
    justify-content: flex-end;

    .txt-point {
      color: #446dff;
      font-size: 16px;
      margin-left: 4px;
    }
    .bedge {
      font-size: 12px;
      padding: 0 10px;
      background: #d3ddff;
      border-radius: 11px;
      height: 22px;
      margin-left: 10px;
      color: #2e4bb1;
      display: flex;
      align-items: center;
      min-width: 50px;
      justify-content: center;
    }
  }

  ${BreakPoints.sm} {
    width: 100%;
    &:nth-of-type(odd) {
      margin-right: 0;
    }
  }
`;
