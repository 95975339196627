import styled from 'styled-components';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const PageStyles = styled.div`
  ${BreakPoints.xs} {
    .right_menu {
      flex-wrap: wrap;
      button {
        width: 40%;
        margin-left: 5px;
        margin-bottom: 5px;
        &:nth-child(odd) {
          margin-left: 0 !important;
        }
        // &:nth-child(3),
        // &:nth-child(4) {
        //   margin-bottom: 0;
        // }
      }
    }
  }
`;
