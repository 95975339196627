import React, { useEffect, useState, useContext, FormEvent } from 'react';
import * as Styled from './styles';
import GOFloatingbox from '../../common/GOFloatingbox';
import GOButton from '../../common/GOButton';
import { useTranslation } from 'react-i18next';
import { NetReservationLoadCategory, NetReservationAdd, NetworkManager } from '../../../actions';
import GODropbox from '../../common/GODropbox';
import GOInput from '../../common/GOInput';
import SmallTitle from '../../common/SmallTitle';
import GODate from '../../common/GODate';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Context } from '../../../stores';
import { toast } from 'react-toastify';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

dayjs.extend(isBetween);
interface AddProps {
  data?: any;
  id?: string;
  startDate?: string;
  onClickExit?: any;
  update?: any;
}

const daysChange = (value: number) => {
  switch (value) {
    case 0:
      return <span className="sun">일</span>;
    case 1:
      return <span>월</span>;
    case 2:
      return <span>화</span>;
    case 3:
      return <span>수</span>;
    case 4:
      return <span>목</span>;
    case 5:
      return <span>금</span>;
    case 6:
      return <span className="sat">토</span>;
    default:
      break;
  }
};

const Add = (props: AddProps) => {
  const { t } = useTranslation();
  const start = props.startDate + 'T' + props.id + ':00';
  // const end = props.startDate + 'T' + '23:50';
  const [data, setData] = useState({
    ReservableCategoryIDs: [],
    plateNumbers: [],
    // selectedDays: [dayjs(start).day() || 7],
    selectedDays: [dayjs(start).day()],
    name: [],
    startDate: new Date(start),
    endDate: new Date(
      dayjs(start)
        .add(60, 'm')
        .format(),
    ),
    startTime: new Date(start),
    endTime: new Date(
      dayjs(start)
        .add(60, 'm')
        .format(),
    ),
    endTimeOfDay: new Date(
      dayjs()
        .endOf('day')
        .format(),
    ),
    memo: '',
    unit: 60,
  });
  const [cat, setCat] = useState([]);
  const [plate, setPlate] = useState([]);
  const days = [0, 1, 2, 3, 4, 5, 6];

  useEffect(() => {
    NetReservationLoadCategory().then((res: any) => {
      setCat(res.data.Data.ProductCategories);
    });
  }, []);

  useEffect(() => {
    NetworkManager('get', `admin/plateAvailability/plate`).then((res: any) => {
      setPlate(res.data.Data.Plates);
    });
  }, []);

  const Store = useContext(Context);

  const handleChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleCategoryChange = (e: any) => {
    if (e.target.value) {
      const v = e.target.value.split(',');
      if (data.ReservableCategoryIDs.filter((rcid: any) => rcid * 1 === v[0] * 1).length === 0) {
        setData({
          ...data,
          ReservableCategoryIDs: data.ReservableCategoryIDs.concat(v[0]),
          name: data.name.concat(v[1]),
        });
      }
    }
  };

  const delCategory = (e: any, name: string) => {
    setData({
      ...data,
      ReservableCategoryIDs: data.ReservableCategoryIDs.filter((rcid: any) => rcid * 1 !== e * 1),
      name: data.name.filter((n: any) => n !== name),
    });
  };

  const handleSubmit = (e: FormEvent<EventTarget>) => {
    e.preventDefault();
    if (data.ReservableCategoryIDs.length < 1) {
      alert('예약 가능한 이용권 분류를 1개 이상 선택하세요');
    } else if (data.plateNumbers.length < 1) {
      alert('배정 타석을 1개 이상 선택하세요');
    } else if (data.selectedDays.length < 1) {
      alert('반복할 날짜를 설정하세요');
    } else if (dayjs(data.endDate).diff(data.startDate, 'day') > 10) {
      alert('생성 기간이 10일을 초과할 수 없습니다.');
    } else if (window.confirm('기존 예약이 취소될 수 있습니다. 진행하시겠습니까?')) {
      Store.actions.setSending(true);
      NetReservationAdd({
        ...data,
        name: data.name.join(),
        startDate: dayjs(data.startDate).format('YYYY-MM-DDT00:00:00'),
        endDate: dayjs(data.endDate).format('YYYY-MM-DDT00:00:00'),
        startHour: dayjs(data.startTime).hour(),
        startMinute: dayjs(data.startTime).minute(),
        endHour: dayjs(data.endTime).hour(),
        endMinute: dayjs(data.endTime).minute(),
      })
        .then(res => {
          alert('생성이 완료되었습니다.');
          Store.actions.setSending(false);
          props.update();
          props.onClickExit();
        })
        .catch((err: any) => {
          if (err.response) {
            toast.error(`${err.response.data.message}`);
            Store.actions.setSending(false);
          }
        });
    }
  };

  // 타석예약 가능시간 등록(팝업)
  return (
    <GOFloatingbox width={1100}>
      <UFloatingContainer as="form" onSubmit={handleSubmit}>
        <UFloatingBoxHeader>
          <h1>{t('reservation.add')}</h1>
          <p>{t('reservation.addInfo')}</p>
        </UFloatingBoxHeader>
        <UFloatingBoxBody flex>
          <div className="flex_box">
            <SmallTitle body={t('reservation.info')} />
            <GODropbox
              label={t('reservation.ReservableCategory')}
              data={[
                ...cat.map((cat: any) => {
                  if (cat.type === 2) {
                    return { body: cat.name, value: [cat.id, cat.name] };
                  }
                  return {};
                }),
              ]}
              onChange={handleCategoryChange}
              value={''}
            />
            <br />
            <SmallTitle body={t('reservation.ReservableCategory') + t('reservation.rCatInfo')} />
            {data.ReservableCategoryIDs.map((catd: any) => {
              const n: any = cat.filter((c: any) => c.id * 1 === catd * 1)[0];
              return (
                <GOButton
                  key={catd}
                  body={`${n.name} ×`}
                  onClick={() => delCategory(catd, n.name)}
                  buttontype="primary"
                  style={{ marginBottom: '10px' }}
                />
              );
            })}
          </div>
          <div className="flex_box">
            <SmallTitle body="생성 기간 선택" />
            <GODate
              startDate={data.startDate}
              endDate={data.endDate}
              label={t('reservation.startDate')}
              selected={data.startDate}
              onChange={(date: any) => {
                setData({
                  ...data,
                  startDate: date,
                  endDate: date >= data.endDate ? date : data.endDate,
                });
              }}
              required
            />
            <GODate
              startDate={data.startDate}
              endDate={data.endDate}
              minDate={data.startDate}
              label={t('reservation.endDate')}
              selected={data.endDate}
              onChange={(date: any) => {
                setData({ ...data, endDate: date });
              }}
              required
            />
            <br />
            <SmallTitle body="반복 범위 설정" />
            <Styled.Plate>
              {days.map((p: any) => {
                if (
                  data.selectedDays.filter((rp: any) => {
                    return p * 1 === rp * 1;
                  }).length > 0
                ) {
                  return (
                    <div
                      key={p}
                      className="platebox platebox_choice"
                      onClick={() =>
                        setData({
                          ...data,
                          selectedDays: data.selectedDays.filter((rp: any) => rp * 1 !== p * 1),
                        })
                      }
                    >
                      {daysChange(p)}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={p}
                      className="platebox"
                      onClick={() =>
                        setData({
                          ...data,
                          selectedDays: data.selectedDays.concat(p),
                        })
                      }
                    >
                      {daysChange(p)}
                    </div>
                  );
                }
              })}
            </Styled.Plate>
            <GODate
              label="시작 시간"
              selected={data.startTime}
              time
              timeOnly
              dataFormat="aa h:mm"
              onChange={(date: any) => {
                setData({ ...data, startTime: date });
              }}
              required
            />
            <GODate
              label="종료 시간"
              selected={data.endTime}
              time
              timeOnly
              //feature/20211104/changePwd 시작 시간 보다 같거나 늦어야 한다.
              minTime={data.startTime}
              maxTime={data.endTimeOfDay}
              // maxTime={data.endTime}
              dataFormat="aa h:mm"
              onChange={(date: any) => {
                setData({ ...data, endTime: date });
              }}
              required
            />
            <GOInput
              label={t('reservation.unit')}
              name="unit"
              onChange={handleChange}
              value={data.unit}
              unit={t('common.min')}
              required
            />
          </div>
          <div className="flex_box">
            <SmallTitle body={t('reservation.plateInfo')} />
            <Styled.PlateName>
              <div>수업 이름</div>
              <input value={data.name.join()} readOnly />
            </Styled.PlateName>
            <GOInput
              label={t('reservation.memo')}
              name="memo"
              onChange={handleChange}
              value={data.memo}
              textarea
            />
            <br />
            <SmallTitle body={t('reservation.RelatedPlateNumbers')} />
            <Styled.Plate>
              {plate.map((p: any) => {
                if (
                  data.plateNumbers.filter((rp: any) => {
                    return p.number * 1 === rp * 1;
                  }).length > 0
                ) {
                  return (
                    <div
                      key={p.number}
                      className="platebox platebox_choice"
                      onClick={() =>
                        setData({
                          ...data,
                          plateNumbers: data.plateNumbers.filter(
                            (rp: any) => rp * 1 !== p.number * 1,
                          ),
                        })
                      }
                    >
                      {p.number}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={p.number}
                      className="platebox"
                      onClick={() =>
                        setData({
                          ...data,
                          plateNumbers: data.plateNumbers.concat(p.number),
                        })
                      }
                    >
                      {p.number}
                    </div>
                  );
                }
              })}
            </Styled.Plate>
            {plate.length === 0 && '배정할수 있는 타석이 없습니다.'}
          </div>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton
            buttontype="green"
            type="submit"
            body={t('common.add')}
            loading={Store.sending}
            disabled={Store.sending}
          />
          <GOButton body={t('common.no')} onClick={props.onClickExit} disabled={Store.sending} />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};
export default Add;
