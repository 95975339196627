import NetworkManager from '../../../NetworkManager';

const YearlyAccounting = (year: string, option?: string) => {
  return new Promise((resolve, reject) => {
    if (option === 'repurchase') {
      NetworkManager('get', `admin/smartAdvisor/yearlySales?groupBy=repurchase&year=${year}`)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {});
    } else if (option === 'productCategory') {
      NetworkManager('get', `admin/smartAdvisor/yearlySales?groupBy=productCategory&year=${year}`)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {});
    } else if (option === 'sex') {
      NetworkManager('get', `admin/smartAdvisor/yearlySales?groupBy=sex&year=${year}`)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {});
    } else if (option === 'age') {
      NetworkManager('get', `admin/smartAdvisor/yearlySales?groupBy=age&year=${year}`)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {});
    }
  });
};

export default YearlyAccounting;
