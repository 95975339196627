import styled from 'styled-components';
import { ContentBodyPadding, BreakPoints } from '../../../GlobalUtilityStyles';

export const PaymentView = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${ContentBodyPadding.xl};
  font-weight: 500;
  max-width: 1900px;
  width: 100%;
  margin: 0 auto;

  ${BreakPoints.md} {
    padding: ${ContentBodyPadding.md};
  }
  ${BreakPoints.sm} {
    padding: ${ContentBodyPadding.sm};
  }
`;

export const Placeholder = styled.div`
  width: 280px;
  flex-grow: 1;
  height: 0px;
  margin: 0px 8px 0;
`;
