import React, { useState } from 'react';
// import * as Styled from './styles';
import {
  GOFloatingbox,
  GOInput,
  GOButton,
  GODropbox,
  GOPostCode,
  GOError,
  SmallTitle,
} from '../../../components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NetEmployeeAdd, NetEmployeeDelete, NetEmployeeEdit } from '../../../actions';
import { actionResetEmployeePassword } from '../../../stores/actions';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

interface AddProps {
  update?: any;
  onClickExit?: () => void;
  data?: any;
  detailData?: any;
}
interface addDataType {
  phoneNumber: string;
  password: string;
  address: any;
}

interface ParamsType {
  id?: string;
}

const Add = (props: AddProps) => {
  // 레슨프로인터벌 추가
  const lessonInterval = props.detailData?.lessonInterval;

  const defaultAddData = {
    phoneNumber: '',
    password: '',
    address: null,
  };
  const [addData, setAddData] = useState<addDataType>(
    props.data
      ? {
          ...props.data,
        }
      : defaultAddData,
  );
  const { register, handleSubmit, errors } = useForm({
    defaultValues: props.data && {
      ...props.data,
      ...props.detailData,
      dateOfBirth: props.data.dateOfBirth?.split('T')[0],
    },
  });
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<ParamsType>();

  const [post, viewPost] = useState(false);

  const handleChange = (e: any) => {
    if (e.target.name === 'phoneNumber') {
      setAddData({
        ...addData,
        password: e.target.value.substring(e.target.value.length - 4, e.target.value.length),
        phoneNumber: e.target.value,
      });
    } else {
      setAddData({
        ...addData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleAddressChange = (e: any) => {
    setAddData({
      ...addData,
      address: e,
    });
  };

  const onDeleteEmployee = () => {
    let result = window.confirm('정말 삭제하시겠습니까?');
    if (result) {
      NetEmployeeDelete(props.data.id)
        .then((res: any) => {
          alert('삭제가 완료되었습니다.');
          if (props.onClickExit) {
            props.onClickExit();
          }
          history.push('/admin/employee/list');
        })
        .catch((err: any) => console.log(err));
    }
  };

  const onResetPassword = () => {
    if (window.confirm('비밀번호를 초기화 하시겠습니까?')) {
      dispatch(
        actionResetEmployeePassword(params.id, () => {
          alert('비밀번호가 휴대폰번호 뒷 4자리로 변경되었습니다.');
        }),
      );
    }
  };

  const onSubmit = (data: any) => {
    if (props.data) {
      // 수정시
      NetEmployeeEdit({ ...addData, ...data }, props.data.id).then(res => {
        alert(t('user.editOK'));
        if (props.onClickExit) {
          props.onClickExit();
        }
        if (props.update) {
          props.update();
        }
      });
    } else {
      // 등록시
      if (addData.password) {
        NetEmployeeAdd({ ...addData, ...data }).then(res => {
          alert('직원 등록이 완료되었습니다. 비밀번호는 문자로 전송됩니다.');
          setAddData(defaultAddData);
          if (props.onClickExit) {
            props.onClickExit();
          }
        });
      } else {
        alert('비밀번호를 확인해주세요.');
      }
    }
  };

  return (
    <GOFloatingbox width={750}>
      {props.data ? (
        <UFloatingContainer as="form" onSubmit={handleSubmit(onSubmit)}>
          <UFloatingBoxHeader>
            <h1>{props.data ? t('employee.edit') : t('employee.add')}</h1>
            <p>{props.data ? t('employee.editInfo') : t('employee.addInfo')}</p>
          </UFloatingBoxHeader>
          <UFloatingBoxBody flex>
            <div className="flex_box">
              <SmallTitle body={t('user.baseInfo')} />
              <GOInput
                label={t('employee.name')}
                name="name"
                rref={register({ maxLength: 8 })}
                error={errors.name}
                required
              />
              {errors.name && <GOError body={`${t('sign.nameErrorMaxLength')}`} />}
              <GODropbox
                label={t('user.sex')}
                name="sex"
                rref={register}
                required
                data={[
                  { value: 0, body: t('common.male') },
                  { value: 1, body: t('common.female') },
                ]}
              />
              <GOInput
                name="dateOfBirth"
                label={t('user.birthday')}
                type="date"
                rref={register}
                required
              />
              <GOInput
                name="phoneNumber"
                label={t('sign.phone')}
                type="number"
                rref={register}
                onChange={handleChange}
                value={addData.phoneNumber}
                required
              />
            </div>
            <div className="flex_box">
              <SmallTitle body={t('user.etcInfo')} />
              <GOInput
                label={t('user.address')}
                name="address"
                value={addData.address ? addData.address.roadAddress : addData.address}
                button={t('common.search')}
                onClickButton={() => {
                  viewPost(true);
                }}
                disabled
              />
              <GOInput label={t('user.password')} name="password" rref={register} disabled />
              <GOInput
                label="직원 타입"
                name="type"
                value={
                  props.data.type === 0
                    ? '관리자'
                    : '매니져' && props.data.type === 2
                    ? '레슨프로'
                    : '매니져'
                }
                disabled
              />
              {lessonInterval > 0 && (
                <GODropbox
                  label={'레슨시간'}
                  name="lessonInterval"
                  rref={register}
                  required
                  data={[
                    { value: 10, body: '10분' },
                    { value: 15, body: '15분' },
                    { value: 20, body: '20분' },
                    { value: 25, body: '25분' },
                    { value: 30, body: '30분' },
                    { value: 40, body: '40분' },
                    { value: 50, body: '50분' },
                    { value: 60, body: '60분' },
                  ]}
                />
              )}
              {post && (
                <GOPostCode
                  onClickExit={() => {
                    viewPost(false);
                  }}
                  handleChange={handleAddressChange}
                />
              )}
            </div>
          </UFloatingBoxBody>
          <UFloatingBoxFooter>
            <GOButton
              body="삭제"
              buttontype="red"
              onClick={() => {
                onDeleteEmployee();
              }}
            />
            <GOButton
              buttontype="green"
              body={props.data ? t('employee.edit') : t('employee.add')}
              type="submit"
            />
            <GOButton body="비밀번호 초기화" onClick={onResetPassword} />
            <GOButton body={t('common.no')} onClick={props.onClickExit} />
          </UFloatingBoxFooter>
        </UFloatingContainer>
      ) : (
        <UFloatingContainer as="form" onSubmit={handleSubmit(onSubmit)}>
          <UFloatingBoxHeader>
            <h1>{props.data ? t('employee.edit') : t('employee.add')}</h1>
            <p>{props.data ? t('employee.editInfo') : t('employee.addInfo')}</p>
          </UFloatingBoxHeader>
          <UFloatingBoxBody flex>
            <div className="flex_box">
              <SmallTitle body={t('user.baseInfo')} />
              <GOInput
                label={t('employee.name')}
                name="name"
                rref={register({ maxLength: 8 })}
                error={errors.name}
                required
              />
              {errors.name && <GOError body={`${t('sign.nameErrorMaxLength')}`} />}
              <GODropbox
                label={t('user.sex')}
                name="sex"
                rref={register}
                required
                data={[
                  { value: 0, body: t('common.male') },
                  { value: 1, body: t('common.female') },
                ]}
              />
              <GOInput
                name="dateOfBirth"
                label={t('user.birthday')}
                type="date"
                rref={register}
                required
              />
              <GOInput
                name="phoneNumber"
                label={t('sign.phone')}
                type="number"
                rref={register}
                onChange={handleChange}
                value={addData.phoneNumber}
                required
              />
            </div>
            <div className="flex_box">
              <SmallTitle body={t('user.etcInfo')} />
              <GOInput
                label={t('user.address')}
                name="address"
                value={addData.address ? addData.address.roadAddress : addData.address}
                button={t('common.search')}
                onClickButton={() => {
                  viewPost(true);
                }}
                disabled
              />
              <GOInput
                label={t('user.password')}
                name="password"
                rref={register}
                onChange={handleChange}
                value={addData.password}
              />
              <GODropbox
                label="직원 타입"
                name="type"
                rref={register}
                required
                data={[
                  { value: 0, body: '관리자' },
                  { value: 1, body: '매니져' },
                  { value: 2, body: '레슨프로' },
                ]}
              />
              {post && (
                <GOPostCode
                  onClickExit={() => {
                    viewPost(false);
                  }}
                  handleChange={handleAddressChange}
                />
              )}
            </div>
          </UFloatingBoxBody>
          <UFloatingBoxFooter>
            <GOButton
              buttontype="green"
              body={props.data ? t('employee.edit') : t('employee.add')}
              type="submit"
            />
            <GOButton body={t('common.no')} onClick={props.onClickExit} />
          </UFloatingBoxFooter>
        </UFloatingContainer>
      )}
    </GOFloatingbox>
  );
};

export default Add;
