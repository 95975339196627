import React, { useEffect } from 'react';
import * as Styled from '../../chartAreaStyles';
import Chart from 'chart.js';

interface chartProps {
  data: any;
  label?: string;
}

const AgeLine = ({ data, label }: chartProps) => {
  useEffect(() => {
    let chart: any = null;

    if (chart) {
      chart.destroy();
      chart = null;
    }

    const config: any = {
      type: 'line',
      data: {
        labels: data.labels,
        datasets: data.datasets,
      },
      options: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
          labels: {
            usePointStyle: true,
            padding: 10,
            boxWidth: 7,
          },
        },
        maintainAspectRatio: false,
        tooltips: {
          displayColors: true,
          callbacks: {
            title: (item: any, data: any) => {
              let index = item[0].datasetIndex;
              return data.datasets[index].label;
            },
            label: (item: any) => {
              return `${item.value}명`;
            },
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: (item: any) => {
                  return `${item}명`;
                },
              },
              type: 'linear',
            },
          ],
        },
      },
    };

    const ctx: HTMLElement | any = document.getElementById('MonthlyUserAge');
    chart = new Chart(ctx, config);

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [data]);
  return (
    <Styled.ChartBox>
      {label && <Styled.Title>{label}</Styled.Title>}
      <Styled.Chart className="rect_chart_response">
        <canvas id="MonthlyUserAge" />
      </Styled.Chart>
    </Styled.ChartBox>
  );
};

export default AgeLine;
