import React, { useEffect, useState } from 'react';
import * as Styled from '../reservationListStyles';
import GOFloatingbox from '../../common/GOFloatingbox';
import GOButton from '../../common/GOButton';
import { useTranslation } from 'react-i18next';
import SmallTitle from '../../common/SmallTitle';
import {
  NetReservationResList,
  NetReservationUserRes,
  NetReservationResCancel,
  NetReservationResAbsent,
  NetReservationResCheckIn,
  NetUserTicketSearch,
  NetReservationResRestore,
} from '../../../actions';
import GOLoading from '../../common/GOLoading';
import GODropbox from '../../common/GODropbox';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import isSameAfter from 'dayjs/plugin/isSameOrAfter';
import GOInput from '../../common/GOInput';
import { phoneNumberFormatting } from '../../../middlewares';

import { Link } from 'react-router-dom';
import { GOEmoji } from '../../index';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

interface ListProps {
  id: string;
  onClickExit?: any;
  update?: any;
  employee?: boolean;
}

const ResPlate = ({
  data,
  onResAbsent,
  onResCancel,
  onResCheckIn,
  onResRestoreCheckIn,
  onResRestoreAbsent,
}: any) => {
  const { t } = useTranslation();
  dayjs.locale('ko');
  dayjs.extend(isSameAfter);

  // console.log('ResPlate !!!!!!!!!!!');
  // console.log(data);

  const isShowRestoreButton =
    data.status === 0 &&
    data.PlateReservation &&
    data.PlateReservation.startDate &&
    !dayjs().isSameOrAfter(data.PlateReservation.startDate);

  const status = () => {
    if (data.PlateReservation) {
      switch (data.PlateReservation.status) {
        case 1:
          return t('reservation.resCheckIn');
        case 2:
          return t('reservation.resAbsent');
      }
    } else {
      switch (data.status) {
        case 0:
          return t('reservation.resUsing');
        case 1:
          return t('reservation.resAbled');
      }
    }
  };

  return (
    <Styled.ReservationPlate
      statusOn={data.status === 0 ? true : false}
      checkIn={data.PlateReservation?.status === 1 ? true : false}
      absent={data.PlateReservation?.status === 2 ? true : false}
    >
      <Styled.Num>{data.number > 9 ? data.number : '0' + data.number}</Styled.Num>
      {data.status === 0 && (
        <Link to={`/admin/user/${data.PlateReservation?.User?.id}`}>
          <Styled.ProfileImage
            style={{
              background:
                data.PlateReservation?.User?.profileImageURL &&
                `url(${data.PlateReservation?.User?.profileImageURL}) center / cover`,
            }}
          />
        </Link>
      )}
      <Styled.Flex>
        {/*//plateReservation.status := [예약 상태 0:예약완료, 1:출석, 2:결석]*/}
        {/* data.status -> relatedPlates: 0 예약 불가(예약됨)  relatedPlate: 1 예약 가능*/}
        {data.status === 0 && data.PlateReservation && (
          <>
            <div>
              <Link to={`/admin/user/${data.PlateReservation?.User?.id}`}>
                <div>
                  {/*feature/20211114/checkin*/}
                  {/*{data.PlateReservation.User.name}{' '}*/}
                  {data.PlateReservation.User?.name}{' '}
                  {data.PlateReservation?.User?.vaccineUser?.isVaccinated === 1 ? (
                    <GOEmoji symbol="✅" />
                  ) : (
                    <GOEmoji symbol="" />
                  )}
                </div>
                {/* featue/20211114/checkin*/}
                <div>{phoneNumberFormatting(data.PlateReservation.User?.phoneNumber)}</div>
              </Link>
              {data.PlateReservation?.status === 1 && (
                <div>{`체크인 : ${dayjs(
                  data.PlateReservation?.CheckInOutTransaction?.checkinDate,
                ).format('M월 D일 a hh:mm')}`}</div>
              )}
            </div>
            <Styled.Flex />
            {/* 출석 : 1 , 결석 : 2 */}
            {/* 타석 예약 복원 기능 추가*/}
            {/*에약완료 상태*/}
            {data.PlateReservation.status === 0 && (
              <>
                <GOButton body="출석" onClick={onResCheckIn} size="xsmall" />
                <GOButton body="결석" onClick={onResAbsent} size="xsmall" />
                <GOButton body="취소" buttontype="red" onClick={onResCancel} size="xsmall" />
              </>
            )}
            {/*출석상태 */}
            {data.PlateReservation.status === 1 && isShowRestoreButton && (
              <>
                <GOButton
                  body="출석 취소"
                  buttontype="red"
                  onClick={onResRestoreCheckIn}
                  size="xsmall"
                />
              </>
            )}
            {/*결석 상태 */}
            {data.PlateReservation.status === 2 && isShowRestoreButton && (
              <>
                <GOButton body="결석 취소" onClick={onResRestoreAbsent} size="xsmall" />
              </>
            )}
          </>
        )}
      </Styled.Flex>
      {/*출석/결석/예약가능 상태 표시*/}
      {isShowRestoreButton === true ? (
        <></>
      ) : (
        <>
          {' '}
          <h5>{status()}</h5>{' '}
        </>
      )}
      {/*<h5>{status()}</h5>*/}
    </Styled.ReservationPlate>
  );
};

const List = (props: ListProps) => {
  const { t } = useTranslation();
  dayjs.locale('ko');
  const [data, setData] = useState<any>();
  const [searchState, setSearchState] = useState<boolean>(false);
  const [totalUser, setTotalUser] = useState<any>([]);
  const [user, setUser] = useState<any>({
    search: '',
    userID: 0,
    ticketID: 0,
    plateAvailabilityID: 0,
    plateNumber: 0,
  });
  const [loading, setLoading] = useState(true);
  const [update, isUpdate] = useState(false);

  useEffect(() => {
    NetReservationResList(props.id).then((res: any) => {
      setData(res.data.Data.PlateAvailability);
      setLoading(false);
    });
  }, [props.id, update]);

  const handleChange = (e: any) => {
    if (e.target.name === 'userID') {
      totalUser?.filter((tUser: any) => {
        if (tUser.id === JSON.parse(e.target.value)) {
          setUser({
            ...user,
            name: tUser.name,
            [e.target.name]: e.target.value,
            plateAvailabilityID: props.id,
            tickets: tUser.tickets,
          });
        }
      });
    } else setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSearch = () => {
    NetUserTicketSearch(user.search, 2).then((res: any) => {
      const userData = res.data.Data.users;

      if (userData.length > 0) {
        toast(`${userData.length}건이 검색되었습니다.`);
        setTotalUser(userData);
        setSearchState(true);
        setUser({
          ...user,
          userID: 0,
          ticketID: 0,
          plateNumber: 0,
          plateAvailabilityID: 0,
          tickets: [],
        });
      } else {
        toast.error('검색 결과가 없습니다.');
        setUser({ search: user.search });
        setSearchState(false);
      }
    });
  };

  const onReservation = (e: any) => {
    e.preventDefault();
    if (
      window.confirm(
        `${user.name} / ${user.plateNumber}${t('common.numPlate')}\n${t('reservation.isRes')}`,
      )
    ) {
      NetReservationUserRes(user)
        .then(res => {
          isUpdate(!update);
        })
        .catch((err: any) => console.error(err));
    }
  };

  const onResCancel = (e: any) => {
    if (window.confirm('예약을 취소하시겠습니까?')) {
      NetReservationResCancel(e).then(res => {
        isUpdate(!update);
      });
    }
  };

  const onResCheckIn = (e: any) => {
    if (window.confirm('출석 처리하시겠습니까?')) {
      console.log(data, user);
      NetReservationResCheckIn(e).then(res => isUpdate(!update));
    }
  };

  const onResAbsent = (e: any) => {
    if (window.confirm('결석 처리하시겠습니까?')) {
      NetReservationResAbsent(e).then(res => isUpdate(!update));
    }
  };

  //출석 결석 복원
  const onResRestoreAbsent = (e: any) => {
    if (window.confirm('결석 취소 처리하시겠습니까?')) {
      NetReservationResRestore(e).then(res => isUpdate(!update));
    }
  };

  const onResRestoreCheckIn = (e: any) => {
    if (window.confirm('출석 취소 처리하시겠습니까?')) {
      NetReservationResRestore(e).then(res => isUpdate(!update));
    }
  };

  // 예약자수 카운트
  let relatedPlates,
    totalCount = 0,
    reservationCount = 0;
  if (data) {
    relatedPlates = data.RelatedPlates.sort((a: any, b: any) => {
      if (a.number > b.number) {
        return 1;
      } else if (a.number < b.number) {
        return -1;
      } else {
        return 0;
      }
    });

    data.RelatedPlates.forEach((rp: any) => {
      if (rp.status === 0) {
        reservationCount += 1;
      }
      totalCount += 1;
    });
  }

  return (
    <>
      <GOFloatingbox width={1020}>
        {loading ? (
          <GOLoading />
        ) : (
          <UFloatingContainer>
            <UFloatingBoxHeader>
              <h1>{t('reservation.resList')}</h1>
              <p>{t('reservation.resListInfo')}</p>
            </UFloatingBoxHeader>
            <UFloatingBoxBody flex>
              <div className="flex_box">
                <SmallTitle body={t('reservation.resAdd')} />
                <Styled.Box>
                  <div className="box_title">회원검색</div>
                  <div className="input_wrapper">
                    <GOInput
                      placeholder="회원이름"
                      name="search"
                      // type="tel"
                      type="text"
                      value={user.search}
                      onChange={handleChange}
                      onClickButton={onSearch}
                      medium
                    />
                  </div>
                  <GOButton body="검색" buttontype="gray" onClick={onSearch} />
                </Styled.Box>
                {totalUser?.length > 0 && (
                  <form onSubmit={onReservation}>
                    <Styled.Box>
                      <div className="box_title">
                        검색결과: {searchState ? totalUser.length : 0}건
                      </div>
                      <div className="dropbox_wrapper">
                        <GODropbox
                          name="userID"
                          data={
                            searchState
                              ? totalUser.map((tUser: any) => {
                                  return {
                                    value: tUser.id,
                                    body: `${tUser.name} / ${tUser.phoneNumber}`,
                                  };
                                })
                              : null
                          }
                          value={user.userID}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Styled.Box>
                    <hr />
                    <Styled.Box>
                      <div className="box_title">이용권 선택</div>
                      <div className="dropbox_wrapper">
                        <GODropbox
                          name="ticketID"
                          data={user?.tickets?.map((ticket: any) => {
                            const expired: boolean =
                              (dayjs(data.startDate) <= dayjs(ticket.startDate).subtract(9, 'h') &&
                                dayjs(data.endDate) <= dayjs(ticket.startDate).subtract(9, 'h')) ||
                              (dayjs(data.startDate) >= dayjs(ticket.endDate).add(15, 'h') &&
                                dayjs(data.endDate) >= dayjs(ticket.endDate).add(15, 'h'));
                            return {
                              value: ticket.id,
                              body: `${expired ? '[ 기간 외 이용권 ] ' : ' '}${
                                ticket.name
                              } / ${dayjs(ticket.startDate).format('MM. DD')} ~ ${dayjs(
                                ticket.endDate,
                              ).format('MM. DD')} / ${
                                ticket.isUnlimited
                                  ? t('product.unlimited')
                                  : `${t('product.remainingCount')} : ${ticket.remainingCount}`
                              }`,
                            };
                          })}
                          value={user.ticketID}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Styled.Box>
                    <Styled.Box>
                      <div className="box_title">배정번호</div>
                      <div className="dropbox_wrapper">
                        <GODropbox
                          name="plateNumber"
                          data={data.RelatedPlates.map((rp: any) => {
                            if (rp.status === 1) {
                              return { value: rp.number, body: rp.number };
                            } else {
                              return '';
                            }
                          })}
                          value={user.plateNumber}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Styled.Box>
                    <Styled.ButtonWrapper>
                      <GOButton body="예약" buttontype="green" type="submit" />
                    </Styled.ButtonWrapper>
                  </form>
                )}
              </div>
              <div className="flex_box">
                <SmallTitle
                  body={`${data.name} (${reservationCount}/${totalCount}${t(
                    'common.people',
                  )}) | ${dayjs(data.startDate).format('YYYY.MM.DD a hh:mm')} ~ ${dayjs(
                    data.endDate,
                  ).format('a hh:mm')}`}
                />
                <Styled.ReservationPlateBox>
                  {relatedPlates.map((rp: any) => (
                    <ResPlate
                      key={rp.id}
                      data={rp}
                      onResAbsent={() => onResAbsent(rp.PlateReservation.id)}
                      onResCancel={() => onResCancel(rp.PlateReservation.id)}
                      onResCheckIn={() => onResCheckIn(rp.PlateReservation.id)}
                      onResRestoreAbsent={() => onResRestoreAbsent(rp.PlateReservation.id)}
                      onResRestoreCheckIn={() => onResRestoreCheckIn(rp.PlateReservation.id)}
                    />
                  ))}
                </Styled.ReservationPlateBox>
              </div>
            </UFloatingBoxBody>
            <UFloatingBoxFooter>
              <GOButton
                body={t('common.no')}
                onClick={() => {
                  props.update();
                  props.onClickExit();
                }}
                noMarginLeft
              />
            </UFloatingBoxFooter>
          </UFloatingContainer>
        )}
      </GOFloatingbox>
    </>
  );
};
export default List;
