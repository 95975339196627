import styled from 'styled-components';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const Form = styled.form`
  flex: 0 1 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${BreakPoints.md} {
    justify-content: flex-end;
  }
  ${BreakPoints.sm} {
    width: 100%;
    button {
      margin-left: 5px !important;
    }
  }
`;

export const Input = styled.input`
  font-size: 14px;
  height: 40px;
  text-indent: 12px;
  border-radius: 5px;
  border: 2px solid #4a5057;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  transition: 0.35s;
  font-weight: bold;
  box-sizing: border-box;
  flex: 1 1 auto;
  width: 280px;

  ${BreakPoints.sm} {
    width: 100px;
  }

  &::placeholder {
    color: #adb5bd;
  }
`;
