import React, { useState, useEffect } from 'react';
import { GOFloatingbox, GOInput, GOButton, GODropbox, SmallTitle } from '../../../components';
import GOError from '../../../components/common/GOError';
import GOPostCode from '../../../components/common/GOPostCode';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { NetVerifySMS, NetUserIMGUpload } from '../../../actions';
import { actionPostUser } from '../../../stores/actions';
import Webcam from 'react-webcam';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';
interface AddProps {
  onClickExit?: () => void;
}
interface addDataType {
  name: string | null;
  profileImageURL: string | null;
  sex: number | string | null;
  nickname: string | null;
  dateOfBirth: string | null;
  phoneNumber: string | null;
  phoneNumberCheck: number | string | null;
  verificationNumber: number | string | null;
  password: string | null;
  address: any;
  memo: string | null;
}

const Add = (props: AddProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const webcamRef = React.useRef<any>(null);
  const [webcam, onWebcam] = useState(false);

  const defaultAddData = {
    name: null,
    profileImageURL: null,
    sex: '0',
    nickname: null,
    dateOfBirth: null,
    phoneNumber: null,
    phoneNumberCheck: 0,
    verificationNumber: null,
    password: null,
    address: undefined,
    memo: null,
  };
  const [addData, setAddData] = useState<addDataType>(defaultAddData);
  const { register, handleSubmit, errors } = useForm();
  const { t } = useTranslation();
  const [sending, setSending] = useState(false);
  const [post, viewPost] = useState(false);
  const [devices, setDevices] = useState<any>([]);
  const [cameraChange, setCameraChange] = useState<boolean>(false);

  const firstLabelName = devices[0]?.label;
  const secondLabelName = devices[1]?.label;

  const firstCameraId = {
    deviceId: devices[0]?.deviceId,
  };
  const secondCameraId = {
    deviceId: devices[1]?.deviceId,
  };

  const handleDevices = React.useCallback(
    mediaDevices => setDevices(mediaDevices.filter(({ kind }: any) => kind === 'videoinput')),
    [setDevices],
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const onChangeType = () => {
    setCameraChange(!cameraChange);
  };

  const capture = React.useCallback(() => {
    setCameraChange(false);
    const imageSrc = webcamRef.current.getScreenshot().replace('data:image/jpeg;base64,', '');

    const byteString = atob(imageSrc);

    const ab = new ArrayBuffer(imageSrc.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: 'image/jpeg' });

    const file = new File([blob], 'file.jpg', {
      type: 'image/jpeg',
    });

    const formData = new FormData();
    formData.append('file', file);

    NetUserIMGUpload(formData).then((res: any) => {
      setAddData({ ...addData, profileImageURL: res.data.Data.imageURL });
    });
  }, [webcamRef, addData]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'phoneNumber') {
      setAddData({
        ...addData,
        password: value.substring(value.length - 4, value.length),
        phoneNumber: value.slice(0, 11),
      });
    } else {
      setAddData({
        ...addData,
        [name]: value,
      });
    }
  };

  const handleAddressChange = (e: any) => {
    setAddData({
      ...addData,
      address: e,
    });
  };

  const onSubmit = (data: any) => {
    setSending(true);
    dispatch(
      actionPostUser({ ...addData, ...data }, (res: any) => {
        alert(t('user.addOK'));
        setAddData(defaultAddData);

        let result = window.confirm('상품 구매 페이지로 연결하시겠습니까?');

        if (result) {
          window.sessionStorage.setItem(`SLID${res.data.Data.user.id}`, res.data.Data.user.name);
          history.push(`/admin/user/${res.data.Data.user.id}/sales`);
        } else {
          if (props.onClickExit) {
            props.onClickExit();
          }
        }
      }),
    );
  };

  const checkVerifySMS = () => {
    NetVerifySMS({
      type: 0,
      phoneNumber: addData.phoneNumber,
      verificationNumber: addData.verificationNumber,
    }).then(res => {
      toast(t('sign.verifySMSOK'));
      setAddData({ ...addData, phoneNumberCheck: 2 });
    });
  };

  return (
    <GOFloatingbox width={1100}>
      <UFloatingContainer as="form" onSubmit={handleSubmit(onSubmit)}>
        <UFloatingBoxHeader>
          <h1>{t('user.add')}</h1>
          <p>{t('user.addInfo')}</p>
        </UFloatingBoxHeader>
        <UFloatingBoxBody flex>
          <div className="flex_box">
            <SmallTitle body={t('user.profileInfo')} />
            {addData.profileImageURL ? (
              <>
                <div
                  style={{
                    background: `url(${addData.profileImageURL}) center / cover no-repeat`,
                    width: 120,
                    height: 120,
                    borderRadius: 150,
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                />
                <GOButton
                  body="재촬영"
                  buttontype="red"
                  onClick={() => {
                    setAddData({ ...addData, profileImageURL: '' });
                  }}
                  noMarginLeft
                />
              </>
            ) : (
              <div className="web-cam-box">
                <Webcam
                  audio={false}
                  width={320}
                  height={250}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  screenshotQuality={1}
                  onUserMedia={() => {
                    onWebcam(true);
                  }}
                  onUserMediaError={() => {
                    onWebcam(false);
                  }}
                  videoConstraints={cameraChange ? secondCameraId : firstCameraId}
                />
                {webcam ? (
                  <div className="web-cam-controls">
                    <GOButton body="촬영" onClick={capture} noMarginLeft />
                    <GODropbox
                      onChange={onChangeType}
                      required
                      data={[
                        { body: firstLabelName, value: 0 },
                        { body: secondLabelName, value: 1 },
                      ]}
                      isDefault
                      small
                    />
                  </div>
                ) : (
                  <GOError body="장치를 찾는 중입니다. 잠시 기다려주세요." fontSize={12} />
                )}
              </div>
            )}
          </div>
          <div className="flex_box">
            <SmallTitle body={t('user.baseInfo')} />
            <GOInput
              label={t('user.name')}
              name="name"
              rref={register({ maxLength: 8 })}
              error={errors.name}
              required
            />
            {errors.name && <GOError body={`${t('sign.nameErrorMaxLength')}`} />}
            <GOInput
              label={t('user.nickname')}
              rref={register({ maxLength: 8 })}
              name="nickname"
              required
            />
            {errors.nickname && <GOError body={`${t('sign.nicknameErrorMaxLength')}`} />}
            <GODropbox
              label={t('user.sex')}
              name="sex"
              rref={register}
              required
              data={[
                { value: 0, body: '남성' },
                { value: 1, body: '여성' },
              ]}
            />
            <GOInput
              label={t('user.birthday')}
              type="date"
              rref={register}
              name="dateOfBirth"
              required
            />
            <GOInput
              name="phoneNumber"
              label={t('sign.phone')}
              type="number"
              rref={register({ minLength: 10 })}
              onChange={handleChange}
              disabled={addData.phoneNumberCheck !== 2 ? false : true}
              value={addData.phoneNumber}
              error={errors.phoneNumber}
              required
            />
            {errors.phoneNumber && <GOError body="휴대폰번호는 최소 10자 이상 적어주세요." />}
            {addData.phoneNumberCheck === 1 && (
              <GOInput
                name="verificationNumber"
                label={t('sign.phoneCheck')}
                button={t('common.ok')}
                type="number"
                onChange={handleChange}
                onClickButton={checkVerifySMS}
                value={addData.verificationNumber}
                required
              />
            )}
          </div>
          <div className="flex_box">
            <SmallTitle body={t('user.etcInfo')} />
            <GOInput
              label={t('user.address')}
              name="address"
              value={addData.address && addData.address.roadAddress}
              button={t('common.search')}
              onClickButton={() => viewPost(true)}
              disabled
            />
            <GOInput label={t('user.memo')} name="memo" rref={register} textarea />
            <br />
            <h5>{t('user.userInfo')}</h5>
            <hr />
            <GOInput
              label={t('user.password')}
              name="password"
              onChange={handleChange}
              value={addData.password}
              error={errors.password}
              required
            />
            {post && (
              <GOPostCode
                onClickExit={() => {
                  viewPost(false);
                }}
                handleChange={handleAddressChange}
              />
            )}
          </div>
        </UFloatingBoxBody>

        <UFloatingBoxFooter>
          <GOButton buttontype="green" body={t('user.add')} type="submit" loading={sending} />
          <GOButton
            body={t('common.no')}
            onClick={() => {
              if (props.onClickExit) props.onClickExit();
              onWebcam(false);
            }}
          />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};

export default Add;
