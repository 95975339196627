import NetworkManager from '../../../NetworkManager';

const MonthlyMemberConfiguration = (option: string, year: number = 2022) => {
  return new Promise((resolve, reject) => {
    if (option === 'sex') {
      NetworkManager(
        'get',
        `admin/smartAdvisor/monthlyMemberConfiguration_ver_1?isAllMember&groupBy=sex&year=${year}`,
        // `admin/smartAdvisor/monthlyMemberConfiguration?isAllMember&groupBy=sex`,
      )
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {});
    } else if (option === 'age') {
      // NetworkManager('get', `admin/smartAdvisor/monthlyMemberConfiguration?isAllMember&groupBy=age`)
      NetworkManager(
        'get',
        // `admin/smartAdvisor/monthlyMemberConfiguration?isAllMember&groupBy=age`,
        `admin/smartAdvisor/monthlyMemberConfiguration_ver_1?isAllMember&groupBy=age&year=${year}`,
      )
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {});
    } else if (option === 'newUserMonthly') {
      NetworkManager(
        'get',
        `admin/smartAdvisor/monthlyNewMemberConfiguration_ver_1?isAllMember&groupBy=monthly&year=${year}`,
      )
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {});
    } else if (option === 'newUserDaily') {
      NetworkManager(
        'get',
        `admin/smartAdvisor/monthlyNewMemberConfiguration_ver_1?isAllMember&groupBy=daily&year=${year}`,
      )
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {});
    } else if (option === 'expired') {
      NetworkManager(
        'get',
        `admin/smartAdvisor/monthlyNewMemberConfiguration_ver_1?isAllMember&groupBy=expired&year=${year}`,
      )
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {});
    }
  });
};

export default MonthlyMemberConfiguration;
