import NetworkManager from '../../../NetworkManager';

const Detail = (pID: string | number) => {
  return new Promise((resolve, reject) => {
    NetworkManager('get', `admin/plateAvailability/${pID}/show`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default Detail;
