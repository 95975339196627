import styled from 'styled-components';
import * as color from '../../../color/index';

export const Label = styled.div`
  display: flex;
  margin: 12px 0;
  align-items: center;
`;

export const LabelItem = styled.div`
  word-break: keep-all;
  flex-shrink: 0;
  width: 80px;
  font-size: 14px;
  font-weight: bold;
  color: ${color.Colors.primary};
`;

export const LabelBody = styled.div`
  word-break: keep-all;
  flex: 1;
  font-size: 14px;
  font-weight: normal;
`;
