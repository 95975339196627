import React from 'react';
import { SecretPin } from '../../../components';

const SettingsPassword = ({ onClickExit }: any) => {
  return (
    <div>
      <SecretPin path="/admin/settings" onClick={onClickExit} />
    </div>
  );
};

export default SettingsPassword;
