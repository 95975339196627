import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
  Footer,
  GOButton,
  GODropbox,
  GOEmoji,
  GOLayoutList,
  GOLoading,
  HeaderBottom,
  StatusTool,
  UserGridView,
  GOFilterContainer,
} from '../../../components';
import * as Styled from './styles';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { onModal } from '../../../stores/modals';
import { actionGetUserAllTickets, actionGetSettingsList } from '../../../stores/actions';
import { NetEmployeeList } from '../../../actions';
// import { actionGetUserList } from '../../../stores/actions';
import ReactPaginate from 'react-paginate';

const User = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const settings = useSelector((state: any) => {
    return state['settings'];
  });

  const [check, setCheck] = useState<any[]>([]);
  const [filter, setFilter] = useState({
    user: 0,
    name: -1,
    age: -1,
    sex: -1,
    product: -1,
    pro: -1,
  });

  const saveCurrentPageIndex = (index: string) => {
    try {
      window.localStorage.setItem('admin/user/grid/user/currentPageIndex', index);
    } catch (e) {}
  };
  const loadCurrentPageIndex = () => {
    try {
      const item = window.localStorage.getItem(`admin/user/grid/user/currentPageIndex`);
      if (item === null) {
        return 0;
      } else {
        return parseFloat(item);
      }
    } catch (e) {}
    return 0;
  };

  const saveSelection = (index: string) => {
    try {
      window.localStorage.setItem('admin/user/grid/user/selection', index);
    } catch (e) {}
  };

  const saveSelectionFilter = (index: string, type: string) => {
    try {
      window.localStorage.setItem(`admin/user/grid/user/selection/${type}`, index);
    } catch (e) {}
  };

  const resetFilterExcludingUser = (user: number) => {
    setFilter({
      user: user,
      name: -1,
      age: -1,
      sex: -1,
      product: -1,
      pro: -1,
    });
    saveSelectionFilter('-1', 'name');
    saveSelectionFilter('-1', 'age');
    saveSelectionFilter('-1', 'sex');
    saveSelectionFilter('-1', 'product');
    saveSelectionFilter('-1', 'pro');
    saveCurrentPageIndex('0');
  };

  const loadSelectionFilter = (type: string) => {
    try {
      const item = window.localStorage.getItem(`admin/user/grid/user/selection/${type}`);
      if (item === null) {
        return -1;
      } else {
        return parseFloat(item);
      }
    } catch (e) {}
    return -1;
  };

  const showSelection = (index: number) => {
    if (index === filter.user) {
      return <GOEmoji symbol="🟢" />;
    } else {
      return <GOEmoji symbol="" />;
    }
  };

  const [userData, setUserData] = useState<any>([]);
  const [summary, setSummary] = useState<any>();
  const [checkUserData, setCheckUserData] = useState<any>([]);
  const [userInfoToExport, setUserInfoToExport] = useState<any>([]);

  const [lessonProSelector, setLessonProSelector] = useState<any>([
    { value: -1, body: '프로 선택' },
  ]);

  const [pageCount, setPageCount] = useState<number>(0);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [pagedUserData, setPagedUserData] = useState<any>([]);
  const pageSize = 50;

  const uData = useSelector((state: any) => state.user).data?.Users;

  //캐쉬사용할때
  // let noticeSMS = window.localStorage.getItem('noticeSmsStatus');

  const formatPhoneNumber = (phoneNumberString: string) => {
    if (phoneNumberString.length > 1) {
      return (
        '' +
        phoneNumberString.slice(0, 3) +
        '-' +
        phoneNumberString.slice(3, 7) +
        '-' +
        phoneNumberString.slice(7, phoneNumberString.length)
      );
    }
    return null;
  };

  const onPageChange = (data: any) => {
    setCurrentPageIndex(data.selected);
    saveCurrentPageIndex(data.selected.toString());
    setPagedUserData(userData.slice(data.selected * pageSize, data.selected * pageSize + pageSize));
  };

  const findMaxEndDateInTickets = (tickets: any, type: string = 'both') => {
    try {
      if (type === 'payment') {
        let maxEndDate = 'N/A';
        tickets.forEach((t: any) => {
          if (maxEndDate === 'N/A') {
            maxEndDate =
              !!t.Transaction && !!t.Transaction.paymentDate ? t.Transaction.paymentDate : 'N/A';
          } else {
            if (!!t.Transaction && !!t.Transaction.paymentDate) {
              maxEndDate =
                Date.parse(t.Transaction.paymentDate) > Date.parse(maxEndDate)
                  ? t.Transaction.paymentDate
                  : maxEndDate;
            }
          }
        });
        return maxEndDate;
      }
      const lessonAndPlate = tickets.filter((t: any) => {
        if (type === 'both') {
          return t.type === 0 || t.type === 2;
        } else if (type === 'lesson') {
          return t.type === 0;
        } else if (type === 'plate') {
          return t.type === 2;
        }
        return false;
      });
      if (lessonAndPlate.length === 0) {
        return 'N/A';
      }

      let maxEndDate = 'N/A';
      lessonAndPlate.forEach((t: any) => {
        if (maxEndDate === 'N/A') {
          maxEndDate = t.endDate;
        } else {
          maxEndDate = Date.parse(t.endDate) > Date.parse(maxEndDate) ? t.endDate : maxEndDate;
        }
      });
      return maxEndDate;
    } catch (e) {
      return 'N/A';
    }
  };

  useEffect(() => {
    dispatch(actionGetSettingsList());
  }, [dispatch]);

  useEffect(() => {
    NetEmployeeList().then((res: any) => {
      // console.log(res.data.Data.Employees);
      const defaultSelector = [{ value: -1, body: '프로 선택' }];
      try {
        if (res.data.Data.Employees.length > 0) {
          const lessonPro = res.data.Data.Employees?.filter((e: any) => e.type === 2);
          const mapped = lessonPro.map((item: any) => {
            return { value: item.id, body: item.name };
          });
          const newProSelector = defaultSelector.concat(mapped);
          const pro = loadSelectionFilter('pro');
          if (pro > 1) {
            const found = newProSelector.filter(item => {
              return Number(item.value) === Number(pro);
            });
            if (found.length < 1) {
              console.log('pro is not matched resetting');
              saveSelectionFilter('-1', 'pro');
            } else {
              console.log('matched pro found');
            }
          }
          setLessonProSelector(newProSelector);
        }
      } catch (e) {
        console.log(e);
      }
    });
  }, []);

  useEffect(() => {
    let checkList: any = [];
    if (check.length > 0) {
      uData?.forEach((user: any) => {
        check?.forEach((c: any) => {
          if (user.id === parseInt(c)) {
            checkList.push(user);
          }
        });
      });
    }
    if (checkList.length > 0) {
      setCheckUserData(checkList);
      let excelData: {
        name: any;
        phoneNumber: any;
        sex: string;
        address: any;
        ticketName: any;
        ticketType: any;
        startDate: any;
        endDate: any;
        lessonPro: any;
        ticketUsageType: any;
        numberOfTimes: any;
      }[] = [];

      checkList.map((item: any) => {
        // { name: "Ahmed", phoneNumber:"01084118549",sex:"남", address:"서울", ticketName:"10회 쿠폰", ticketType:"레슨권", startDate:"2021-01-04", endDate:"2021-08-31", lessonPro:"지정의"},

        if (item.tickets.length === 0) {
          excelData.push({
            name: item.name,
            phoneNumber: formatPhoneNumber(item.phoneNumber),
            sex: item.sex === 0 ? '남' : '여',
            address: item.address && item.address.roadAddress ? item.address.roadAddress : '',
            ticketName: '',
            ticketType: '',
            startDate: '',
            endDate: '',
            lessonPro: '',
            ticketUsageType: '',
            numberOfTimes: '',
          });
        } else {
          item.tickets.map((t: any) => {
            //이용권 타입 0:개인 레슨, 1:그룹레슨 2: 타석이용, 3:장소, 4:락카서비스, 5:기타 ,6 제주캠퍼스
            let ticketTypeName = '개인 레슨';
            if (t.type === 0) {
              ticketTypeName = '개인레슨';
            } else if (t.type === 1) {
              ticketTypeName = '그룹레슨';
            } else if (t.type === 2) {
              ticketTypeName = '타석이용';
            } else if (t.type === 3) {
              ticketTypeName = '장소';
            } else if (t.type === 4) {
              ticketTypeName = '락카서비스';
            } else if (t.type === 5) {
              ticketTypeName = '기타';
            } else if (t.type === 6) {
              ticketTypeName = '제주캠퍼스';
            } else {
              ticketTypeName = 'ETC';
            }

            const proName = lessonProSelector.find((item: any) => {
              return t.employeeID === item.value;
            });
            let numberOfTimes = '';
            let ticketUsageType = '';

            if (t.isUnlimited !== undefined && t.remainingCount !== undefined) {
              if (t.type === 0 || t.type === 2) {
                if (t.isUnlimited === true) {
                  ticketUsageType = '기간권';
                } else {
                  numberOfTimes = `${t.remainingCount}`;
                  ticketUsageType = '회차권';
                }
              } else {
                numberOfTimes = '';
              }
            }

            excelData.push({
              name: item.name,
              phoneNumber: formatPhoneNumber(item.phoneNumber),
              sex: item.sex === 0 ? '남' : '여',
              address: item.address && item.address.roadAddress ? item.address.roadAddress : '',
              ticketName: t.name,
              ticketType: ticketTypeName,
              startDate: dayjs(t.startDate)
                .add(9, 'hour')
                .format('YYYY-MM-DD'),
              endDate: dayjs(t.endDate)
                .add(9, 'hour')
                .format('YYYY-MM-DD'),
              lessonPro: proName ? proName.body : '',
              ticketUsageType,
              numberOfTimes,
            });
            return t;
          });
        }
      });

      setUserInfoToExport(excelData);
      // userInfoToExport
    }
  }, [uData, check]);

  useEffect(() => {
    window.sessionStorage.clear();
    dispatch(actionGetUserAllTickets());
  }, [dispatch]);

  useEffect(() => {
    let sum = {
      use: 0,
      expired: 0,
      in7Days: 0,
      in30Days: 0,
      in60Days: 0,
      in3Days: 0,
      in15DaysInLesson: 0,
      in15DaysInPlate: 0,
      inactive: 0,
      thisMonthUser: 0, //금월 생성된 유저
      inLastMonthUser: 0, //지난달 생성된 유저
      inMonthBeforeLast: 0, //지지난달 생성된 유저
    };

    //이용권 타입 0:개인 레슨, 1:그룹레슨 2: 타석이용, 3:장소, 4:락카서비스, 5:기타
    // uData?.forEach((d: any) => {
    //   if (d.tickets[0]) {
    //     const diff = dayjs().diff(d.tickets[0].endDate) / 86400000;
    //     if (diff < 0) {
    //       sum.use++;
    //       if (diff > -7) {
    //         sum.in7Days++;
    //         if (diff > -3) {
    //           sum.in3Days++;
    //         }
    //       }
    //     }
    //     if (diff >= 0) {
    //       sum.expired++;
    //     }
    //   }
    // });
    //DONETODO 만료 시차 오류
    uData?.forEach((d: any) => {
      try {
        try {
          if (!!d.BranchUser.createdAt) {
            const dayjsToday = dayjs();
            const dayjsCreatedAt = dayjs(d.BranchUser.createdAt);
            const today = dayjsToday.format('YYYY-MM');
            const userCreationTime = dayjsCreatedAt.format('YYYY-MM');
            // const dayDiff = dayjsToday.diff(dayjsCreatedAt, 'day'); 최근 30일

            const oneMonthBefore = dayjs()
              .subtract(1, 'month')
              .format('YYYY-MM');

            // const twoMonthBefore = dayjs()
            //   .subtract(2, 'month')
            //   .format('YYYY-MM');

            if (oneMonthBefore === userCreationTime) {
              sum.inLastMonthUser++;
            }
            // if (twoMonthBefore === userCreationTime) {
            //   sum.inMonthBeforeLast++;
            // }
            if (today === userCreationTime) {
              sum.thisMonthUser++;
            }
          }
        } catch (e) {
          console.log(e);
        }
        //inLast30day
        if (d.tickets) {
          let ticketEndDate = findMaxEndDateInTickets(d.tickets);
          let lessonTicketEndDate = findMaxEndDateInTickets(d.tickets, 'lesson');
          let plateTicketEndDate = findMaxEndDateInTickets(d.tickets, 'plate');
          if (ticketEndDate !== 'N/A') {
            const endTimeOfTicket = dayjs(dayjs(ticketEndDate).format('YYYY-MM-DD')).startOf('day');
            const diff = Math.round(
              dayjs()
                .startOf('day')
                .diff(endTimeOfTicket) / 86400000,
            );
            if (diff < 0) {
              sum.use++;
              if (diff >= -3) {
                sum.in3Days++;
              } else if (diff >= -7) {
                sum.in7Days++;
              } else if (diff >= -30) {
                sum.in30Days++;
              } else if (diff >= -60) {
                sum.in60Days++;
              }
            }
            if (diff >= 0) {
              sum.expired++;
              sum.use++;
            }
          } else {
            sum.inactive++;
          }

          if (lessonTicketEndDate !== 'N/A') {
            const endTimeOfTicket = dayjs(dayjs(lessonTicketEndDate).format('YYYY-MM-DD')).startOf(
              'day',
            );
            const diff = Math.round(
              dayjs()
                .startOf('day')
                .diff(endTimeOfTicket) / 86400000,
            );
            if (diff < 0) {
              if (diff > -15) {
                sum.in15DaysInLesson++;
              }
            }
          }

          if (plateTicketEndDate !== 'N/A') {
            const endTimeOfTicket = dayjs(dayjs(plateTicketEndDate).format('YYYY-MM-DD')).startOf(
              'day',
            );
            const diff = Math.round(
              dayjs()
                .startOf('day')
                .diff(endTimeOfTicket) / 86400000,
            );
            if (diff < 0) {
              if (diff > -15) {
                sum.in15DaysInPlate++;
              }
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    });

    setUserData(uData);
    let defaultUserSelection: string | null = '0';
    try {
      defaultUserSelection = window.localStorage.getItem('admin/user/grid/user/selection');
    } catch (e) {}
    const name = loadSelectionFilter('name');
    const age = loadSelectionFilter('age');
    const sex = loadSelectionFilter('sex');
    const product = loadSelectionFilter('product');
    const pro = loadSelectionFilter('pro');
    setFilter({
      ...filter,
      age,
      sex,
      product,
      pro,
      name,
      user: Number(defaultUserSelection),
    });

    let tempPageCount = 0;
    if (uData?.length > 0) {
      setPageCount(uData?.length / pageSize);
      tempPageCount = uData?.length / pageSize;
    } else {
      setPageCount(0);
      tempPageCount = 0;
    }

    const loadedCurrentPageIndex = loadCurrentPageIndex();
    console.log(loadedCurrentPageIndex);
    if (
      loadedCurrentPageIndex === 0 ||
      tempPageCount === 0 ||
      loadedCurrentPageIndex >= tempPageCount
    ) {
      setCurrentPageIndex(0);
      setPagedUserData(uData?.slice(0, pageSize));
    } else if (loadedCurrentPageIndex > 0 && loadedCurrentPageIndex < tempPageCount) {
      try {
        setCurrentPageIndex(loadedCurrentPageIndex);
        setPagedUserData(
          uData?.slice(
            loadedCurrentPageIndex * pageSize,
            loadedCurrentPageIndex * pageSize + pageSize,
          ),
        );
      } catch (e) {}
    }

    setSummary(sum);
    setCheck([]);
  }, [uData]);

  useEffect(() => {
    let filterData = uData !== undefined && uData !== null ? uData : [];

    switch (filter.user) {
      // 전체 회원
      case 0:
        filterData = uData !== undefined && uData !== null ? uData : [];
        break;
      // 이용중
      //    findMaxEndDateInTickets
      case 1:
        filterData = filterData.filter((d: any) => {
          const maxEndDate = findMaxEndDateInTickets(d.tickets);
          if (maxEndDate === 'N/A') {
            return false;
          }
          return (
            Math.round(
              dayjs()
                .startOf('day')
                // .diff(maxEndDate) / 86400000,
                .diff(dayjs(dayjs(maxEndDate).format('YYYY-MM-DD')).startOf('day')) / 86400000,
            ) < 0
          );
        });
        break;
      // 만료된 회원
      case 2:
        filterData = filterData.filter((d: any) => {
          const maxEndDate = findMaxEndDateInTickets(d.tickets);
          if (maxEndDate === 'N/A') {
            return false;
          }
          return (
            Math.round(
              dayjs()
                .startOf('day')
                // .diff(maxEndDate) / 86400000,
                .diff(dayjs(dayjs(maxEndDate).format('YYYY-MM-DD')).startOf('day')) / 86400000,
            ) >= 0
          );
        });
        break;
      // 만료 7일 전
      case 3:
        filterData = filterData.filter((d: any) => {
          const maxEndDate = findMaxEndDateInTickets(d.tickets);
          if (maxEndDate === 'N/A') {
            return false;
          }
          const dayDiff = Math.round(
            dayjs()
              .startOf('day')
              .diff(dayjs(dayjs(maxEndDate).format('YYYY-MM-DD')).startOf('day')) / 86400000,
            // .diff(maxEndDate) / 86400000,
          );
          return dayDiff < 0 && dayDiff >= -7 && dayDiff < -3;
        });
        break;
      // 만료 3일 전
      case 4:
        filterData = filterData.filter((d: any) => {
          const maxEndDate = findMaxEndDateInTickets(d.tickets);
          if (maxEndDate === 'N/A') {
            return false;
          }
          const dayDiff = Math.round(
            dayjs()
              .startOf('day')
              // .diff(maxEndDate) / 86400000,
              .diff(dayjs(dayjs(maxEndDate).format('YYYY-MM-DD')).startOf('day')) / 86400000,
          );
          return dayDiff < 0 && dayDiff >= -3;
        });
        break;
      //만료 30일 전
      case 5:
        filterData = filterData.filter((d: any) => {
          const maxEndDate = findMaxEndDateInTickets(d.tickets);
          if (maxEndDate === 'N/A') {
            return false;
          }
          const dayDiff = Math.round(
            dayjs()
              .startOf('day')
              // .diff(maxEndDate) / 86400000,
              .diff(dayjs(dayjs(maxEndDate).format('YYYY-MM-DD')).startOf('day')) / 86400000,
          );
          return dayDiff < 0 && dayDiff >= -30 && dayDiff < -7;
        });
        break;
      case 6:
        filterData = filterData.filter((d: any) => {
          const maxEndDate = findMaxEndDateInTickets(d.tickets);
          if (maxEndDate === 'N/A') {
            return false;
          }
          const dayDiff = Math.round(
            dayjs()
              .startOf('day')
              .diff(dayjs(dayjs(maxEndDate).format('YYYY-MM-DD')).startOf('day')) / 86400000,
            // .diff(maxEndDate) / 86400000,
          );
          return dayDiff < 0 && dayDiff > -60 && dayDiff < -30;
        });
        break;
      case 8: //plate
        filterData = filterData.filter((d: any) => {
          const maxEndDate = findMaxEndDateInTickets(d.tickets, 'plate');
          if (maxEndDate === 'N/A') {
            return false;
          }
          const dayDiff = Math.round(
            dayjs()
              .startOf('day')
              .diff(dayjs(dayjs(maxEndDate).format('YYYY-MM-DD')).startOf('day')) / 86400000,
            // .diff(maxEndDate) / 86400000,
          );
          return dayDiff < 0 && dayDiff > -15;
        });
        break;
      case 9: //lesson
        filterData = filterData.filter((d: any) => {
          const maxEndDate = findMaxEndDateInTickets(d.tickets, 'lesson');
          if (maxEndDate === 'N/A') {
            return false;
          }
          const dayDiff = Math.round(
            dayjs()
              .startOf('day')
              // .diff(maxEndDate) / 86400000,
              .diff(dayjs(dayjs(maxEndDate).format('YYYY-MM-DD')).startOf('day')) / 86400000,
            // .diff(maxEndDate) / 86400000,
          );
          return dayDiff < 0 && dayDiff > -15;
        });
        break;
      case 10: //비활성 회원
        filterData = filterData.filter((d: any) => {
          const maxEndDate = findMaxEndDateInTickets(d.tickets);
          return maxEndDate === 'N/A';
        });
        filterData = filterData.slice().sort((a: any, b: any) => {
          const dayA = !!a.lastExpiredDate ? dayjs(a.lastExpiredDate) : dayjs('1999-12-31');
          const dayB = !!b.lastExpiredDate ? dayjs(b.lastExpiredDate) : dayjs('1999-12-31');
          return dayA.isAfter(dayB) ? -1 : 1;
        });
        break;
      case 11: // 이달의 신규회원
        filterData = filterData.filter((d: any) => {
          if (!!d.BranchUser.createdAt) {
            const today = dayjs().format('YYYY-MM');
            const userCreationTime = dayjs(d.BranchUser.createdAt).format('YYYY-MM');
            // console.log(d.BranchUser.createdAt);
            // console.log('korean' + dayjs(d.BranchUser.createdAt).format('YYYY-MM-DD'));
            // console.log(today, userCreationTime, today === userCreationTime);
            return today === userCreationTime;
          } else {
            return false;
          }
        });
        break;
      case 12: // 지난달
        filterData = filterData.filter((d: any) => {
          if (!!d.BranchUser.createdAt) {
            const twoMonthBefore = dayjs()
              .subtract(1, 'month')
              .format('YYYY-MM');
            const userCreationTime = dayjs(d.BranchUser.createdAt).format('YYYY-MM');
            return twoMonthBefore === userCreationTime;
          } else {
            return false;
          }
        });
        break;
      // case 13:
      //   filterData = filterData.filter((d: any) => {
      //     if (!!d.BranchUser.createdAt) {
      //       const oneMonthBefore = dayjs()
      //         .subtract(1, 'month')
      //         .format('YYYY-MM');
      //       const userCreationTime = dayjs(d.BranchUser.createdAt).format('YYYY-MM');
      //       return oneMonthBefore === userCreationTime;
      //     } else {
      //       return false;
      //     }
      //   });
      //   break;
    }

    // 이름 순 필터
    switch (filter.name) {
      case 0:
        filterData = filterData.slice().sort((a: any, b: any) => {
          return a.name > b.name ? 1 : -1;
        });
        break;
      case 1:
        filterData = filterData.slice().sort((a: any, b: any) => {
          return b.name > a.name ? 1 : -1;
        });
        break;
    }

    // 판매상품 별 필터
    // type => 0: 개인레슨, 1: 그룹레슨, 2: 타석이용, 4: 락커 이용, 5: 기타
    switch (filter.product) {
      case 0:
        filterData = filterData.filter((d: any) => {
          try {
            const filteredType = d.tickets.filter((t: any) => {
              return t.type === 0;
            });
            return filteredType.length > 0;
          } catch (e) {
            return false;
          }
        });
        break;
      case 1:
        filterData = filterData.filter((d: any) => {
          try {
            const filteredType = d.tickets.filter((t: any) => {
              return t.type === 1;
            });
            return filteredType.length > 0;
          } catch (e) {
            return false;
          }
        });
        break;
      case 2:
        filterData = filterData.filter((d: any) => {
          try {
            const filteredType = d.tickets.filter((t: any) => {
              return t.type === 2;
            });
            return filteredType.length > 0;
          } catch (e) {
            return false;
          }
        });
        break;
      case 4:
        filterData = filterData.filter((d: any) => {
          try {
            const filteredType = d.tickets.filter((t: any) => {
              return t.type === 4;
            });
            return filteredType.length > 0;
          } catch (e) {
            return false;
          }
        });
        break;
      case 5:
        filterData = filterData.filter((d: any) => {
          try {
            const filteredType = d.tickets.filter((t: any) => {
              return t.type === 5;
            });
            return filteredType.length > 0;
          } catch (e) {
            return false;
          }
        });
        break;
      case 6: //최근 구매일 기준
        if (filter.user !== 10) {
          try {
            if (filterData !== undefined && filterData !== null) {
              filterData = filterData.slice().sort((a: any, b: any) => {
                const lastPaymentDateA = findMaxEndDateInTickets(a.tickets, 'payment');
                const lastPaymentDateB = findMaxEndDateInTickets(b.tickets, 'payment');
                const dayA =
                  lastPaymentDateA !== 'N/A' ? dayjs(lastPaymentDateA) : dayjs('1999-12-31');
                const dayB =
                  lastPaymentDateB !== 'N/A' ? dayjs(lastPaymentDateB) : dayjs('1999-12-31');
                return dayA.isAfter(dayB) ? -1 : 1;
              });
            }
          } catch (e) {}
        }
        break;
    }

    // 성별 필터
    switch (filter.sex * 1) {
      case 0:
        filterData = filterData.filter((d: any) => d.sex === filter.sex * 1);
        break;
      case 1:
        filterData = filterData.filter((d: any) => d.sex === filter.sex * 1);
        break;
    }

    // 연령 별 필터
    switch (filter.age) {
      // 10대
      case 0:
        filterData = filterData.filter(
          (d: any) =>
            dayjs().diff(dayjs(d.dateOfBirth), 'year') >= 10 &&
            dayjs().diff(dayjs(d.dateOfBirth), 'year') < 20,
        );
        break;
      // 20대
      case 1:
        filterData = filterData.filter(
          (d: any) =>
            dayjs().diff(dayjs(d.dateOfBirth), 'year') >= 20 &&
            dayjs().diff(dayjs(d.dateOfBirth), 'year') < 30,
        );
        break;
      // 30대
      case 2:
        filterData = filterData.filter(
          (d: any) =>
            dayjs().diff(dayjs(d.dateOfBirth), 'year') >= 30 &&
            dayjs().diff(dayjs(d.dateOfBirth), 'year') < 40,
        );
        break;
      // 40대
      case 3:
        filterData = filterData.filter(
          (d: any) =>
            dayjs().diff(dayjs(d.dateOfBirth), 'year') >= 40 &&
            dayjs().diff(dayjs(d.dateOfBirth), 'year') < 50,
        );
        break;
      // 50대
      case 4:
        filterData = filterData.filter(
          (d: any) =>
            dayjs().diff(dayjs(d.dateOfBirth), 'year') >= 50 &&
            dayjs().diff(dayjs(d.dateOfBirth), 'year') < 60,
        );
        break;
      // 60대
      case 5:
        filterData = filterData.filter(
          (d: any) =>
            dayjs().diff(dayjs(d.dateOfBirth), 'year') >= 60 &&
            dayjs().diff(dayjs(d.dateOfBirth), 'year') < 70,
        );
        break;
      // 70대 이상
      case 6:
        filterData = filterData.filter(
          (d: any) => dayjs().diff(dayjs(d.dateOfBirth), 'year') >= 70,
        );
        break;
    }

    if (filter.pro > 0) {
      filterData = filterData.filter((d: any) => {
        return (
          d.tickets.filter((t: any) => {
            return t.type === 0 && t.employeeID === filter.pro;
          }).length > 0
        );
      });
    }

    if (filterData) {
      setUserData(filterData);
      let tempPageCount = 0;
      if (filterData.length > 0) {
        tempPageCount = filterData.length / pageSize;
        setPageCount(filterData.length / pageSize);
      } else {
        setPageCount(0);
        tempPageCount = 0;
      }

      // setCurrentPageIndex(0);
      // setPagedUserData(filterData.slice(0, pageSize));

      const loadedCurrentPageIndex = loadCurrentPageIndex();
      if (
        loadedCurrentPageIndex === 0 ||
        tempPageCount === 0 ||
        loadedCurrentPageIndex >= tempPageCount
      ) {
        console.log('oadedCurrentPageIndex === 0 || tempPageCount === 0');
        setCurrentPageIndex(0);
        setPagedUserData(filterData.slice(0, pageSize));
      } else if (loadedCurrentPageIndex > 0 && loadedCurrentPageIndex < tempPageCount) {
        console.log(
          'loadedCurrentPageIndex > 0 && loadedCurrentPageIndex < tempPageCount',
          loadedCurrentPageIndex,
        );
        setCurrentPageIndex(loadedCurrentPageIndex);
        setPagedUserData(
          filterData.slice(
            loadedCurrentPageIndex * pageSize,
            loadedCurrentPageIndex * pageSize + pageSize,
          ),
        );
      }

      setCheck([]);
    }
  }, [filter, uData]);

  const handleFilterChange = (e: any) => {
    if (
      e.target.name === 'age' ||
      e.target.name === 'product' ||
      e.target.name === 'sex' ||
      e.target.name === 'name' ||
      e.target.name === 'pro'
    ) {
      saveCurrentPageIndex('0');
      saveSelectionFilter(parseFloat(e.target.value).toString(), e.target.name);
      setFilter({ ...filter, [e.target.name]: parseFloat(e.target.value) });
    } else setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  return (
    <>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={t('user.list')} />
          </>
        }
        rightmenu={
          <>
            <GOButton
              buttontype="green"
              body="회원 등록"
              onClick={() => {
                dispatch(onModal('userAdd'));
              }}
            />
          </>
        }
      />
      <GOFilterContainer
        rightbody={
          <Styled.ControlCustomStyles>
            <GODropbox
              name="name"
              data={[
                { value: -1, body: '이름 순' },
                { value: 0, body: '오름차순' },
                { value: 1, body: '내림차순' },
              ]}
              onChange={handleFilterChange}
              value={filter.name}
              isDefault
              small
            />
            <GODropbox
              name="product"
              data={[
                { value: -1, body: '판매상품 별' },
                { value: 6, body: '최근 구매일' },
                { value: 0, body: '개인레슨권' },
                { value: 1, body: '그룹레슨권' },
                { value: 2, body: '타석이용권' },
                { value: 4, body: '락커이용권' },
                { value: 5, body: '기타' },
                // { value: 6, body: '제주캠퍼스' },
              ]}
              onChange={handleFilterChange}
              value={filter.product}
              isDefault
              small
            />
            <GODropbox
              name="sex"
              onChange={handleFilterChange}
              data={[
                { value: -1, body: '성별' },
                { value: 0, body: '남자' },
                { value: 1, body: '여자' },
              ]}
              value={filter.sex}
              isDefault
              small
            />
            <GODropbox
              name="age"
              onChange={handleFilterChange}
              data={[
                { value: -1, body: '연령 별' },
                { value: 0, body: '10대' },
                { value: 1, body: '20대' },
                { value: 2, body: '30대' },
                { value: 3, body: '40대' },
                { value: 4, body: '50대' },
                { value: 5, body: '60대' },
                { value: 6, body: '70대 이상' },
              ]}
              value={filter.age}
              isDefault
              small
            />
            {/* 레슨 프로 필터 lessonProSelector */}
            <GODropbox
              name="pro"
              onChange={handleFilterChange}
              data={lessonProSelector}
              value={filter.pro}
              isDefault
              small
            />
          </Styled.ControlCustomStyles>
        }
      ></GOFilterContainer>
      <GOLayoutList
        leftbody={
          uData ? (
            <Styled.LeftUserlist>
              <h4>회원</h4>
              <hr />
              <div
                className="part"
                onClick={() => {
                  saveSelection('0');
                  resetFilterExcludingUser(0);
                  // setFilter({ ...filter, user: 0 });
                }}
              >
                <h6>{showSelection(0)} 전체 회원</h6>
                <h6>{uData.length}</h6>
              </div>
              <hr />
              <div
                className="part"
                onClick={() => {
                  saveSelection('1');
                  resetFilterExcludingUser(1);
                  // setFilter({ ...filter, user: 1 });
                }}
              >
                <h6>{showSelection(1)} 이용중</h6>
                <h6>{summary?.use}</h6>
              </div>
              <div
                className="part"
                onClick={() => {
                  saveSelection('10');
                  resetFilterExcludingUser(10);
                  // setFilter({ ...filter, user: 10 });
                }}
              >
                <h6>{showSelection(10)} 비활성 회원</h6>
                <h6>{summary?.inactive}</h6>
              </div>
              <hr />
              <div
                className="part"
                onClick={() => {
                  saveSelection('2');
                  resetFilterExcludingUser(2);
                  // setFilter({ ...filter, user: 2 });
                }}
              >
                <h6>{showSelection(2)} 최근 만료된 회원</h6>
                <h6>{summary?.expired}</h6>
              </div>
              <div
                className="part"
                onClick={() => {
                  saveSelection('4');
                  resetFilterExcludingUser(4);
                  // setFilter({ ...filter, user: 4 });
                }}
              >
                <h6>{showSelection(4)} 만료 3일 전</h6>
                <h6>{summary?.in3Days}</h6>
              </div>
              <div
                className="part"
                onClick={() => {
                  saveSelection('3');
                  resetFilterExcludingUser(3);
                  // setFilter({ ...filter, user: 3 });
                }}
              >
                <h6>{showSelection(3)} 만료 7일 전</h6>
                <h6>{summary?.in7Days}</h6>
              </div>
              <div
                className="part"
                onClick={() => {
                  saveSelection('5');
                  resetFilterExcludingUser(5);
                  // setFilter({ ...filter, user: 5 });
                }}
              >
                <h6>{showSelection(5)} 만료 30일 전</h6>
                <h6>{summary?.in30Days}</h6>
              </div>
              <hr />
              <div
                className="part"
                onClick={() => {
                  saveSelection('8');
                  resetFilterExcludingUser(8);
                  // setFilter({ ...filter, user: 8 });
                }}
              >
                <h6>{showSelection(8)} 타석 만료 15일 전</h6>
                <h6>{summary?.in15DaysInPlate}</h6>
              </div>
              <hr />
              <div
                className="part"
                onClick={() => {
                  saveSelection('9');
                  resetFilterExcludingUser(9);
                  // setFilter({ ...filter, user: 9 });
                }}
              >
                <h6>{showSelection(9)} 레슨 만료 15일 전</h6>
                <h6>{summary?.in15DaysInLesson}</h6>
              </div>
              <hr />
              <div
                className="part"
                onClick={() => {
                  saveSelection('11');
                  resetFilterExcludingUser(11);
                  // setFilter({ ...filter, user: 11 });
                }}
              >
                <h6>{showSelection(11)} 이달의 신규회원</h6>
                <h6>{summary?.thisMonthUser}</h6>
              </div>
              <div
                className="part"
                onClick={() => {
                  saveSelection('12');
                  resetFilterExcludingUser(12);
                  // setFilter({ ...filter, user: 12 });
                }}
              >
                <h6>{showSelection(12)} 지난달 신규회원 </h6>
                <h6>{summary?.inLastMonthUser}</h6>
              </div>
              {/*<div*/}
              {/*  className="part"*/}
              {/*  onClick={() => {*/}
              {/*    saveSelection('12');*/}
              {/*    setFilter({ ...filter, user: 12 });*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <h6>{showSelection(12)} 지지난달 신규회원 </h6>*/}
              {/*  <h6>{summary?.inMonthBeforeLast}</h6>*/}
              {/*</div>*/}
            </Styled.LeftUserlist>
          ) : (
            <GOLoading />
          )
        }
        rightbody={
          uData ? (
            <>
              <UserGridView
                setCheck={setCheck}
                check={check}
                data={pagedUserData}
                ticketFilterType={filter.user}
                totalCount={userData?.length}
                nonPagedData={userData}
              />
              {/*<GOLayoutBox*/}
              {/*    height={900}*/}
              {/*    width={'100%'}*/}
              {/*    body={ <UserGridView setCheck={setCheck} check={check} data={pagedUserData} ticketFilterType={filter.user} totalCount={userData?.length}  nonPagedData={userData}/> }/>*/}
              {pagedUserData?.length > 0 ? (
                <ReactPaginate
                  pageRangeDisplayed={pageSize}
                  marginPagesDisplayed={5}
                  initialPage={currentPageIndex}
                  forcePage={currentPageIndex}
                  previousLabel={'← 이전'}
                  nextLabel={'다음 →'}
                  pageCount={pageCount}
                  onPageChange={onPageChange}
                  containerClassName={'pagination'}
                  previousLinkClassName={'pagination__link'}
                  nextLinkClassName={'pagination__link'}
                  disabledClassName={'pagination__link--disabled'}
                  activeClassName={'pagination__link--active'}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <GOLoading />
          )
        }
        shortTopPadding
      />
      {check.length > 0 && (
        <Footer
          body={
            <>
              <GOButton body="푸시 메시지" onClick={() => dispatch(onModal('userPush', check))} />
              {/*{noticeSMS === 'true' && (*/}
              {settings && settings.data?.settings?.noticeSmsStatus === true && (
                <GOButton
                  body="문자 메시지"
                  onClick={() => dispatch(onModal('userSMS', { check, checkUserData }))}
                />
              )}
              <CSVLink
                headers={[
                  { label: '이름', key: 'name' },
                  { label: '전화번호', key: 'phoneNumber' },
                  { label: '성별', key: 'sex' },
                  { label: '주소', key: 'address' },
                  { label: '상품명', key: 'ticketName' },
                  { label: '상품종류', key: 'ticketType' },
                  { label: '시작일', key: 'startDate' },
                  { label: '종료일', key: 'endDate' },
                  { label: '담당프로', key: 'lessonPro' },
                  { label: '회차권여부', key: 'ticketUsageType' },
                  { label: '잔여횟수', key: 'numberOfTimes' },
                ]}
                data={userInfoToExport}
                filename="회원정보.csv"
                target="_blank"
              >
                <GOButton body="엑셀로 다운로드" onClick={() => {}} />
              </CSVLink>
            </>
          }
        />
      )}
    </>
  );
};

export default User;
