import React from 'react';
import * as Styled from './styles';
import { CheckInPanel, GOFloatingbox, GOButton } from '../../../components';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

const Modal = ({ modal, onClickExit }: any) => {
  if (
    modal.status === 1 &&
    (modal.data.plateReservations.length > 0 || modal.data.lessonReservations.length > 0)
  ) {
    // 성공
    const data = modal.data;
    return (
      <GOFloatingbox width={900}>
        <UFloatingContainer>
          <UFloatingBoxHeader>
            <h1>체크인</h1>
            <Styled.PanelDescription>
              {data.userName} 회원님 안녕하세요! 체크인 할 예약을 선택해주세요.
            </Styled.PanelDescription>
          </UFloatingBoxHeader>
          <UFloatingBoxBody>
            <Styled.PanelContainer>
              {data?.plateReservations?.map((pr: any, index: number) => (
                <React.Fragment key={index}>
                  <CheckInPanel plateData={pr} plate />
                </React.Fragment>
              ))}
              {data?.lessonReservations?.map((lr: any, index: number) => (
                <React.Fragment key={index}>
                  <CheckInPanel lessonData={lr} lesson />
                </React.Fragment>
              ))}
            </Styled.PanelContainer>
          </UFloatingBoxBody>
          <UFloatingBoxFooter>
            <GOButton body="닫기" onClick={onClickExit} />
          </UFloatingBoxFooter>
        </UFloatingContainer>
      </GOFloatingbox>
    );
  } else if (
    modal.status === 1 &&
    modal.data.plateReservations.length === 0 &&
    modal.data.lessonReservations.length === 0
  ) {
    const data = modal.data;
    return (
      <GOFloatingbox>
        <UFloatingContainer>
          <UFloatingBoxHeader>
            <h1>체크인</h1>
            <Styled.PanelDescription>
              {data.userName} 회원님 안녕하세요! 아래의 예약정보를 확인해주세요!
            </Styled.PanelDescription>
          </UFloatingBoxHeader>
          <UFloatingBoxBody flex>
            <Styled.ErrorMessage>오늘 예약이 없습니다.</Styled.ErrorMessage>
          </UFloatingBoxBody>
          <UFloatingBoxFooter>
            <GOButton onClick={onClickExit} body="닫기" />
          </UFloatingBoxFooter>
        </UFloatingContainer>
      </GOFloatingbox>
    );
  } else {
    return (
      <GOFloatingbox>
        <UFloatingContainer>
          <UFloatingBoxBody flex>
            <Styled.ErrorMessage>등록되지 않은 체크인 번호입니다.</Styled.ErrorMessage>
          </UFloatingBoxBody>
          <UFloatingBoxFooter>
            <GOButton onClick={onClickExit} body="확인" buttontype="primary" />
          </UFloatingBoxFooter>
        </UFloatingContainer>
      </GOFloatingbox>
    );
  }
};

export default Modal;
