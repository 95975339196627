import React from "react";

const GOTextarea = (props: { body: string }) => {
  return (
    <div>
      {props.body &&
        props.body.split(`\n`).map((b: any, index: number) => {
          return (
            <span key={index}>
              {b}
              <br />
            </span>
          );
        })}
    </div>
  );
};

export default GOTextarea;
