import React from 'react';
import * as Styled from './styles';
import GOAsyncDiv from '../../../common/GOAsyncDiv';
import { GOLayout, GOFilterContainer } from '../../../../components';
import { PlatePanelPlus } from '../../..';

interface ViewProps {
  data: any;
}

const placeHolder = () => {
  let arr = [];
  for (let i = 0; i < 10; i++) {
    arr.push(<Styled.PlatePlaceholder key={i} />);
  }
  return arr;
};

const View = ({ data }: ViewProps) => {
  return (
    <>
      <GOFilterContainer
        rightbody={
          <Styled.IVManagerButton
            onClick={() => {
              window.open('https://mgr.impactvision.co.kr');
            }}
          >
            IV Manager 타석제어
          </Styled.IVManagerButton>
        }
      />

      <GOLayout
        shortTopPadding
        body={
          <Styled.PlateView>
            <GOAsyncDiv status={data.status}>
              {data.data &&
                data.data.plates.map((p: any) => <PlatePanelPlus key={p.id} data={p} />)}
              {placeHolder()}
            </GOAsyncDiv>
          </Styled.PlateView>
        }
      />
    </>
  );
};
export default View;
