import NetworkManager from "../../../NetworkManager";

const Upload = (data: object) => {
  return new Promise((resolve, reject) => {
    NetworkManager(
      "post",
      "admin/user/uploadProfileImage",
      null,
      { "Content-Type": "multipart/form-data" },
      data
    )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default Upload;
