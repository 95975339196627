import styled from 'styled-components';
import * as color from '../../../color/index';
import { darken } from 'polished';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const HomePanel = styled.div`
  flex-shrink: 1;
  width: 100%;
  height: 100%;
  background: ${color.Colors.white};
  border: 1px solid ${color.Colors.line};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  font-size: 16px;
  font-weight: bold;
  color: ${color.Colors.primary};
  flex-direction: column;
  transition: 0.35s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.16);
    transition: 0.35s;
    background: ${darken(0.02, `${color.Colors.white}`)};
  }

  ${BreakPoints.xs} {
    flex-direction: row;
    padding: 0px 20px;
    justify-content: flex-start;
  }
`;

export const PanelIcon = styled.div`
  margin: 24px 0;
  ${BreakPoints.xs} {
    margin: 10px 10px 10px 0;
  }
`;
