import React, { useState, useEffect } from 'react';
import {
  HeaderBottom,
  StatusTool,
  GOLayout,
  GOLayoutBox,
  SmallTitle,
  GOLoading,
  GOLabel,
  UserUsedTicketPanel,
  UserTicketPanel,
  UserUnCheckedPanel,
  GOTextarea,
  GOButton,
  UserEdit,
  GOEmoji,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './index.scss';
import * as Styled from './styles';
import dayjs from 'dayjs';
import { phoneNumberFormatting } from '../../../middlewares';
import {
  actionDeleteUserDetail,
  actionGetUserDetail,
  actionGetUserVaccineStatus,
  actionPutUserVaccineStatus,
  actionGetUserPaymentList,
  actionGetUserUncheckedList,
  actionGetTrainingManagementMenuOpen,
} from '../../../stores/actions';

interface ParamsType {
  id: string;
}

const Detail = () => {
  const { t } = useTranslation();

  // isVaccinated: 2
  // vaccineDataVisibility: 1

  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [update, isUpdate] = useState(false);
  const [userData, setUserData] = useState<any>();
  const [userCheckInOutTransaction, setUserCheckInOutTransaction] = useState<any>();
  const [originalCheckInOutTransaction, setOriginalCheckInOutTransaction] = useState<any>([]);
  const [edit, setEdit] = useState(false);
  const [userTicketData, setUserTicketData] = useState<any>();
  const [userTicketDetail, setUserTicketDetail] = useState<any>();
  const [userUncheckedList, setUserUncheckedList] = useState<any>();
  const [userUncheckedListUpdate, isUserUncheckedListUpdate] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>({
    totalChecked: true,
    plateChecked: false,
    lessonChecked: false,
    plateAbsentChecked: false,
    lessonAbsentChecked: false,
  });

  const [vaccineStatus, setVaccineStatus] = useState<any>({
    isVaccinated: 0, //0 보여주지 않는다. 1 백신패스  2: not yet
    vaccineDataVisibility: 0, //0 면에서 보여주지 않는다.
  });

  const [isVaccineChecked, setVaccineChecked] = useState<boolean>(false);

  const [isTrainingManagementMenuOpen, setTrainingManagementMenuOpen] = useState(false);

  const params = useParams<ParamsType>();

  const dispatch = useDispatch();
  const history = useHistory();

  // 미수금 데이터 확인
  const userUnpaidPriceList = userTicketDetail?.filter((td: any) => td?.unpaidPrice > 0);
  // 이용기간 남은 티켓 확인
  const userRemainedTicket = userTicketDetail?.filter(
    (userTicket: any) => dayjs().diff(dayjs(userTicket?.ticket?.endDate)) / 86400000 < 0,
  );

  // user 상세 데이터
  useEffect(() => {
    dispatch(
      actionGetUserDetail(params.id, (res: any) => {
        setLoading(false);
        setUserData(res.data.Data.User);

        try {
          let tinfo = res.data.Data.User.CheckInOutTransactions.reduce((a: any, o: any) => {
            if (a[o.tid + '_max']) {
              a[o.tid + '_max'] += 1;
            } else {
              a[o.tid + '_max'] = 1;
            }
            a[o.tid + '_cur'] = a[o.tid + '_max'];
            return a;
          }, {});

          const cinout = res.data.Data.User.CheckInOutTransactions.map((o: any, idx: number) => {
            return {
              ...o,
              ticketMaxCount: tinfo[o.tid + '_max'],
              ticketNth: tinfo[o.tid + '_cur']--,
            };
          });
          setUserCheckInOutTransaction(cinout);
          setOriginalCheckInOutTransaction(cinout);
        } catch (e) {
          setUserCheckInOutTransaction(res.data.Data.User.CheckInOutTransactions);
          setOriginalCheckInOutTransaction(res.data.Data.User.CheckInOutTransactions);
        }
        console.log('updating ---', res.data.Data.User.tickets);
        setUserTicketData(res.data.Data.User.tickets);
      }),
    );
  }, [dispatch, params.id, update]);

  //feature/20211104/chagnePwd 백신 데이타 조회
  useEffect(() => {
    dispatch(
      actionGetUserVaccineStatus(params.id, (res: any) => {
        // console.log(res.data.Data);
        // console.log(res.data.Data.VaccineInfo);
        // console.log(res.data.Data.VaccineInfo);

        setVaccineStatus({
          isVaccinated: res.data.Data.VaccineInfo.isVaccinated,
          vaccineDataVisibility: res.data.Data.VaccineInfo.vaccineDataVisibility,
        });

        if (res.data.Data.VaccineInfo.isVaccinated === 1) {
          setVaccineChecked(true);
        } else if (res.data.Data.VaccineInfo.isVaccinated === 2) {
          setVaccineChecked(false);
        }
        // setLoading(false);
        // setUserData(res.data.Data.User);
        // setUserCheckInOutTransaction(res.data.Data.User.CheckInOutTransactions);
        // setOriginalCheckInOutTransaction(res.data.Data.User.CheckInOutTransactions);
        // setUserTicketData(res.data.Data.User.tickets);
      }),
    );
  }, [dispatch, params.id, update]);

  // user ticket 결제 내역 상세 데이터
  useEffect(() => {
    dispatch(
      actionGetUserPaymentList(params.id, (res: any) => {
        setUserTicketDetail(res.data.Data.transactions);
      }),
    );
  }, [dispatch, params.id]);

  // user 미확인 예약 리스트
  useEffect(() => {
    dispatch(
      actionGetUserUncheckedList(params.id, (res: any) => {
        setUserUncheckedList(res.data.Data.uncheckedReservations);
        setLoading2(false);
      }),
    );
  }, [dispatch, params.id, userUncheckedListUpdate]);

  // 입장관리 필터
  useEffect(() => {
    let filterData = userCheckInOutTransaction;

    switch (filter.totalChecked) {
      case true:
        filterData = userCheckInOutTransaction;
        break;
    }
    switch (filter.plateChecked) {
      case true:
        filterData = filterData.filter((d: any) => d.type === 2);
        break;
    }
    switch (filter.lessonChecked) {
      case true:
        filterData = filterData.filter((d: any) => d.type === 0);
    }
    //타석결석여부
    switch (filter.plateAbsentChecked) {
      case true:
        filterData = filterData.filter((d: any) => d.type === 2 && d.status === 2);
    }
    switch (filter.lessonAbsentChecked) {
      case true:
        filterData = filterData.filter((d: any) => d.type === 0 && d.status === 2);
    }
    if (filterData) {
      setOriginalCheckInOutTransaction(filterData);
    }
  }, [filter, userCheckInOutTransaction]);

  useEffect(() => {
    dispatch(
      actionGetTrainingManagementMenuOpen((res: any) => {
        if (
          res !== null &&
          res.data !== undefined &&
          res.data !== null &&
          res.data.Data !== undefined &&
          res.data.Data !== null &&
          res.data.Data.isTrainingManagementMenuOpen !== undefined &&
          res.data.Data.isTrainingManagementMenuOpen !== null
        ) {
          setTrainingManagementMenuOpen(res.data.Data.isTrainingManagementMenuOpen);
        }
      }),
    );
  }, [dispatch, isTrainingManagementMenuOpen]);

  const decorateVaccineInfo = (data: any, vStatus: any) => {
    if (!!vStatus.vaccineDataVisibility && vStatus.vaccineDataVisibility === 1) {
      if (vStatus.isVaccinated === 0) {
        //백신 정보 보여주지 않기
        return <div className="name">{userData.name}</div>;
      } else if (vStatus.isVaccinated === 1) {
        //백신 패스
        return (
          <>
            <div className="name">
              {userData.name} <GOEmoji symbol="✅" />
            </div>
            <input
              id="vaccinedId"
              name="vaccineChecked"
              type="checkbox"
              onChange={handleVaccineCheckBox}
              checked={isVaccineChecked}
            />
            <label htmlFor="vaccinedId">방역패스</label>
          </>
        );
      } else if (vStatus.isVaccinated === 2) {
        //백신패스 아님
        return (
          <>
            <div className="name">{userData.name}</div>
            {/*<div className="checkboxWrapper">*/}
            {/*<div>백신 </div>*/}
            <input
              id="vaccinedId"
              name="vaccineChecked"
              type="checkbox"
              onChange={handleVaccineCheckBox}
              checked={isVaccineChecked}
            />
            <label htmlFor="vaccinedId">방역패스</label>
            {/*</div>*/}
          </>
        ); //체크 박스 필요
      } else {
        return <div className="name">{userData.name}</div>;
      }
    } else {
      return (
        <>
          <div className="name">{userData.name}</div>
        </>
      );
    }
  };

  const birthday = (birth: any) => {
    const today = new Date().getFullYear();
    const myBirth = new Date(birth).getFullYear();
    return today - myBirth;
  };

  const onUserDelete = () => {
    if (userUnpaidPriceList.length > 0)
      alert('미수금 내역이 있습니다. 확인 후 삭제하시기 바랍니다.');
    else if (userRemainedTicket.length > 0) {
      alert('이용권 기간이 남아있으니, 결제 내역에서 이용권을 먼저 삭제하시기 바랍니다.');
    } else {
      let result = window.confirm('회원을 삭제하시겠습니까?');
      if (result) {
        dispatch(
          actionDeleteUserDetail(params.id, () => {
            alert('삭제가 완료되었습니다.');
            history.push('/admin/user/grid');
          }),
        );
      }
    }
  };

  const handleVaccineCheckBox = (e: any) => {
    if (e.target.name === 'vaccineChecked') {
      console.log(e.target.value);
      setVaccineChecked(!isVaccineChecked);
      const vaccinated = !isVaccineChecked ? 1 : 2;
      const data = { vaccinated };
      dispatch(
        actionPutUserVaccineStatus(params.id, data, (res: any) => {
          // console.log(res.data.Data);
          console.log(res.data.Data.VaccineInfo);
          console.log(res.data.Data.VaccineInfo);

          setVaccineStatus({
            isVaccinated: res.data.Data.VaccineInfo.isVaccinated,
            vaccineDataVisibility: res.data.Data.VaccineInfo.vaccineDataVisibility,
          });

          if (res.data.Data.VaccineInfo.isVaccinated === 1) {
            setVaccineChecked(true);
          } else if (res.data.Data.VaccineInfo.isVaccinated === 2) {
            setVaccineChecked(false);
          }
        }),
      );
    }
  };

  const handleFilterChange = (e: any) => {
    if (e.target.name === 'plate') {
      setFilter({
        totalChecked: false,
        plateChecked: true,
        lessonChecked: false,
        plateAbsentChecked: false,
        lessonAbsentChecked: false,
      });
    } else if (e.target.name === 'lesson') {
      setFilter({
        totalChecked: false,
        plateChecked: false,
        lessonChecked: true,
        plateAbsentChecked: false,
        lessonAbsentChecked: false,
      });
    } else if (e.target.name === 'total') {
      setFilter({
        totalChecked: true,
        plateChecked: false,
        lessonChecked: false,
        plateAbsentChecked: false,
        lessonAbsentChecked: false,
      });
    } else if (e.target.name === 'plateAbsent') {
      setFilter({
        totalChecked: false,
        plateChecked: false,
        lessonChecked: false,
        plateAbsentChecked: true,
        lessonAbsentChecked: false,
      });
    } else if (e.target.name === 'lessonAbsent') {
      setFilter({
        totalChecked: false,
        plateChecked: false,
        lessonChecked: false,
        plateAbsentChecked: false,
        lessonAbsentChecked: true,
      });
    }
  };

  return (
    <Styled.PageStyles>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool link="/admin/user/grid" type="prev" body={t('user.list')} />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={userData && userData.name} />
          </>
        }
        rightmenu={
          <>
            <GOButton
              body="회원 삭제"
              buttontype="red"
              onClick={() => {
                onUserDelete();
              }}
            />
            <GOButton body="회원 수정" onClick={() => setEdit(true)} />
            <GOButton
              body={t('user.paymentList')}
              onClick={() => {
                window.sessionStorage.setItem(`SLID${params.id}`, userData.name);
                history.push({
                  pathname: `/admin/user/${params.id}/payment`,
                  state: { userID: params.id },
                });
              }}
            />
            {isTrainingManagementMenuOpen ? (
              <GOButton
                body="훈련 관리"
                onClick={() => {
                  window.sessionStorage.setItem(`SLID${params.id}`, userData.name);
                  console.log('training management');
                  history.push({
                    pathname: `/admin/user/${params.id}/trainingManagement`,
                    state: { userID: params.id },
                  });
                }}
              />
            ) : (
              <></>
            )}
            <GOButton
              body="판매 등록"
              buttontype="green"
              onClick={() => {
                window.sessionStorage.setItem(`SLID${params.id}`, userData.name);
                history.push(`/admin/user/${params.id}/sales`);
              }}
            />
          </>
        }
      />
      <GOLayout
        body={
          loading && loading2 ? (
            <GOLoading />
          ) : (
            <>
              {userData && (
                <>
                  <Styled.BoxWrapper>
                    <GOLayoutBox
                      // height={600}
                      body={
                        <>
                          <SmallTitle body="개인정보" />
                          <Styled.Profile>
                            <Styled.ProfileImage
                              style={{
                                background: userData.profileImageURL
                                  ? `url(${userData.profileImageURL}) center / cover`
                                  : `#f0f0f0`,
                              }}
                            />
                            <Styled.DetailText>
                              {/*<div className="name">{userData.name}</div>*/}
                              {/*<div className="birth">{userData.vaccineDataVisibility}</div>*/}
                              {decorateVaccineInfo(userData, vaccineStatus)}
                              <div className="birth">{userData.dateOfBirth?.split('T')[0]}</div>
                              <div className="sex">
                                {birthday(userData.dateOfBirth)}세 /{' '}
                                {userData.sex === 0 ? '남자' : '여자'}
                              </div>
                            </Styled.DetailText>
                          </Styled.Profile>
                          <hr />
                          <GOLabel label="닉네임" body={userData.nickname} />
                          <GOLabel
                            label="주소"
                            body={userData.address ? userData.address.roadAddress : '-'}
                          />
                          <GOLabel
                            label="연락처"
                            body={phoneNumberFormatting(userData.phoneNumber)}
                          />
                          <GOLabel label="체크인 번호" body={userData.checkinID} />
                          <GOLabel label="락커 번호" body={userData.locker} />
                          <hr />
                          <GOLabel label="메모" />
                          <h6>
                            <GOTextarea body={userData.memo ? userData.memo : '메모가 없습니다.'} />
                          </h6>
                        </>
                      }
                    />
                    <GOLayoutBox
                      // height={600}
                      body={
                        <>
                          <SmallTitle body="이용권 목록" />
                          <div className="user_ticketPanel">
                            {userTicketData?.length > 0 ? (
                              <>
                                {userTicketData.map((utd: any, index: number) => {
                                  return (
                                    <UserTicketPanel
                                      key={index}
                                      data={utd}
                                      userID={params.id}
                                      update={() => isUpdate(!update)}
                                    />
                                  );
                                })}
                              </>
                            ) : (
                              <>
                                <Styled.EmptyDataText>
                                  이용권 데이터가 없습니다.
                                </Styled.EmptyDataText>
                              </>
                            )}
                          </div>
                        </>
                      }
                    />
                    <GOLayoutBox
                      // height={600}
                      body={
                        <>
                          {/* <SmallTitle body="입장관리" /> */}
                          <Styled.TitleWrapper>
                            <div className="title">입장관리</div>
                            <div className="checkboxContainer">
                              <div className="checkboxWrapper">
                                <div>전체</div>
                                <input
                                  name="total"
                                  type="checkbox"
                                  onChange={handleFilterChange}
                                  checked={filter.totalChecked}
                                />
                              </div>
                              <div className="checkboxWrapper">
                                <div>타석</div>
                                <input
                                  name="plate"
                                  type="checkbox"
                                  onChange={handleFilterChange}
                                  checked={filter.plateChecked}
                                />
                              </div>
                              <div className="checkboxWrapper">
                                <div>개인레슨</div>
                                <input
                                  name="lesson"
                                  type="checkbox"
                                  onChange={handleFilterChange}
                                  checked={filter.lessonChecked}
                                />
                              </div>
                              {/*featue/20211026/absentList 타석 결석 추가*/}
                              <div className="checkboxWrapper">
                                <div>타석결석</div>
                                <input
                                  name="plateAbsent"
                                  type="checkbox"
                                  onChange={handleFilterChange}
                                  checked={filter.plateAbsentChecked}
                                />
                              </div>
                              <div className="checkboxWrapper">
                                <div>레슨결석</div>
                                <input
                                  name="lessonAbsent"
                                  type="checkbox"
                                  onChange={handleFilterChange}
                                  checked={filter.lessonAbsentChecked}
                                />
                              </div>
                            </div>
                          </Styled.TitleWrapper>
                          <hr />
                          <div className="user_ticketPanel">
                            {originalCheckInOutTransaction?.length > 0 ? (
                              <>
                                {originalCheckInOutTransaction.map((cot: any, idx: number) => (
                                  <UserUsedTicketPanel key={idx} ticket={cot} />
                                ))}
                              </>
                            ) : (
                              <>
                                <Styled.EmptyDataText>
                                  입장관리 데이터가 없습니다.
                                </Styled.EmptyDataText>
                              </>
                            )}
                          </div>
                        </>
                      }
                    />
                    <GOLayoutBox
                      // height={600}
                      body={
                        <>
                          <SmallTitle body="미확인 예약 리스트" />
                          <Styled.UncheckedWrapper>
                            {userUncheckedList?.length > 0 ? (
                              <>
                                <UserUnCheckedPanel
                                  data={userUncheckedList}
                                  update={() => {
                                    //TODO: 미확인 예약 업데이트 되었을때 자동 업데이트 시킨다.
                                    isUpdate(!update);
                                    isUserUncheckedListUpdate(!userUncheckedListUpdate);
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <Styled.EmptyDataText>
                                  미확인 예약 데이터가 없습니다.
                                </Styled.EmptyDataText>
                              </>
                            )}
                          </Styled.UncheckedWrapper>
                        </>
                      }
                    />
                    {edit && (
                      <UserEdit
                        data={userData}
                        onClickExit={() => {
                          setEdit(false);
                        }}
                        update={() => {
                          isUpdate(!update);
                        }}
                      />
                    )}
                  </Styled.BoxWrapper>
                </>
              )}
            </>
          )
        }
      />
    </Styled.PageStyles>
  );
};

export default Detail;
