import React, { useState } from 'react';
import { GOButton, GOFloatingbox } from '../../components';
import { NetModifyCheckPassword } from '../../actions';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../GlobalUtilityStyles';

const ChangePinNumber = ({ onClickExit }: any) => {
  const { t } = useTranslation();

  const [num, setNum] = useState<string>('');
  const [changeNum, setChangeNum] = useState<string>('');
  const [pinState, setPinState] = useState<boolean>(false);
  const [padState, setPadState] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [error1, setError1] = useState<boolean>(false);
  const [error2, setError2] = useState<boolean>(false);
  const [error3, setError3] = useState<boolean>(false);

  const numArr = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const passwordData = { oldPINS: num, newPINs: changeNum };

  const handleSubmit = () => {
    // 변경 전 핀번호 입력안했을 때
    if (num.length === 0) {
      setError(true);
    }

    // 변경할 핀번호 입력안했을 때
    if (changeNum.length === 0) {
      setError1(true);
    }

    // 변경 전 핀번호 4자리 입력안했을 때
    if (num.length > 0 && num.length < 4) {
      setError2(true);
    }

    // 변경할 핀번호 4자리 입력안했을 때
    if (changeNum.length > 0 && changeNum.length < 4) {
      setError3(true);
    }

    NetModifyCheckPassword(passwordData)
      .then((res: any) => {
        if (res) onClickExit();
      })
      .catch(err => {
        // 변경 전 핀번호 일치하지 않을 때
        if (num.length > 0 && err.response.data.message === 'incorrect pins') {
          setError(false);
          setError2(true);
        }
        if (
          num.length === 4 &&
          changeNum.length === 0 &&
          err.response.data.message === 'incorrect pins'
        ) {
          setError2(false);
        }
      });
  };

  return (
    <GOFloatingbox width={600}>
      <UFloatingContainer>
        <UFloatingBoxHeader textCenter>
          <h1>보안핀(PIN) 변경</h1>
        </UFloatingBoxHeader>
        <UFloatingBoxBody flex>
          <div className="flex_box">
            <Styled.FormContainer>
              <Styled.SubTitle>현재 보안핀</Styled.SubTitle>
              <Styled.NumberText
                type="password"
                value={num.slice(0, 4)}
                onClick={() => {
                  setPinState(false);
                  setPadState(false);
                }}
                style={{
                  borderBottom: pinState
                    ? '2px solid #ddd' && (error || error2)
                      ? '2px solid #fa5252'
                      : '2px solid #ddd'
                    : '2px solid #12b886' && (error || error2)
                    ? '2px solid #fa5252'
                    : '2px solid #12b886',
                  transition: pinState
                    ? '0.35s' && (error || error2)
                      ? '0.35s'
                      : '0.35s'
                    : '0.35s' && (error || error2)
                    ? '0.35s'
                    : '0.35s',
                }}
                readOnly
              />
              {error ? (
                <>
                  <Styled.ErrorText>* 현재 보안핀을 입력해주세요.</Styled.ErrorText>
                </>
              ) : (
                <>
                  <Styled.ErrorText />
                </>
              )}
              {error2 ? (
                <>
                  <Styled.ErrorText>* 보안핀이 일치하지 않습니다.</Styled.ErrorText>
                </>
              ) : (
                <>
                  <Styled.ErrorText />
                </>
              )}

              <Styled.SubTitle>변경할 보안핀</Styled.SubTitle>
              <Styled.NumberText
                type="password"
                value={changeNum.slice(0, 4)}
                onClick={() => {
                  setPinState(true);
                  setPadState(true);
                }}
                style={{
                  borderBottom: pinState
                    ? '2px solid #12b886' && (error1 || error3)
                      ? '2px solid #fa5252'
                      : '2px solid #12b886'
                    : '2px solid #ddd' && (error1 || error3)
                    ? '2px solid #fa5252'
                    : '2px solid #ddd',
                  transition: pinState
                    ? '0.35s' && (error1 || error3)
                      ? '0.35s'
                      : '0.35s'
                    : '0.35s' && (error1 || error3)
                    ? '0.35s'
                    : '0.35s',
                }}
                readOnly
              />
              {error1 ? (
                <>
                  <Styled.ErrorText>* 변경할 보안핀을 입력해주세요.</Styled.ErrorText>
                </>
              ) : (
                <>
                  <Styled.ErrorText />
                </>
              )}
              {error3 ? (
                <>
                  <Styled.ErrorText>* 4자리의 보안핀을 입력해주세요.</Styled.ErrorText>
                </>
              ) : (
                <>
                  <Styled.ErrorText />
                </>
              )}
            </Styled.FormContainer>
          </div>

          <div className="flex_box">
            {padState ? (
              <>
                <Styled.Number>
                  <Styled.NumberPad>
                    {numArr.map((n: any, index: number) => (
                      <Styled.Pad
                        key={index}
                        onClick={() => {
                          setChangeNum(changeNum + n);
                          setError1(false);
                          setError3(false);
                        }}
                      >
                        {n}
                      </Styled.Pad>
                    ))}
                    <Styled.Pad
                      onClick={() => {
                        setChangeNum(changeNum.slice(0, -1));
                        setError1(false);
                        setError3(false);
                      }}
                    >
                      ←
                    </Styled.Pad>
                    <Styled.Pad
                      onClick={() => {
                        setChangeNum(changeNum + '0');
                        setError1(false);
                        setError3(false);
                      }}
                    >
                      0
                    </Styled.Pad>
                    <Styled.Pad
                      onClick={() => {
                        setChangeNum('');
                        setError1(false);
                        setError3(false);
                      }}
                    >
                      ✕
                    </Styled.Pad>
                  </Styled.NumberPad>
                </Styled.Number>
              </>
            ) : (
              <>
                <Styled.Number>
                  <Styled.NumberPad>
                    {numArr.map((n: any, index: number) => (
                      <Styled.Pad
                        key={index}
                        onClick={() => {
                          setNum(num + n);
                          setError(false);
                          setError2(false);
                        }}
                      >
                        {n}
                      </Styled.Pad>
                    ))}
                    <Styled.Pad
                      onClick={() => {
                        setNum(num.slice(0, -1));
                        setError(false);
                        setError2(false);
                      }}
                    >
                      ←
                    </Styled.Pad>
                    <Styled.Pad
                      onClick={() => {
                        setNum(num + '0');
                        setError(false);
                        setError2(false);
                      }}
                    >
                      0
                    </Styled.Pad>
                    <Styled.Pad
                      onClick={() => {
                        setNum('');
                        setError(false);
                        setError2(false);
                      }}
                    >
                      ✕
                    </Styled.Pad>
                  </Styled.NumberPad>
                </Styled.Number>
              </>
            )}
          </div>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton buttontype="green" body="변경" onClick={handleSubmit} />
          <GOButton body={t('common.no')} onClick={onClickExit} />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};

export default ChangePinNumber;
