export default function YearlyAge(data: any) {
  let amount20s: number[] = [];
  let amount30s: number[] = [];
  let amount40s: number[] = [];
  let amount50s: number[] = [];
  let amount60s: number[] = [];
  let total: number[] = [];

  if (data) {
    let count20s = data.filter((d: any) => d.r10Age === 1);
    let count30s = data.filter((d: any) => d.r10Age === 2);
    let count40s = data.filter((d: any) => d.r10Age === 3);
    let count50s = data.filter((d: any) => d.r10Age === 4);
    let count60s = data.filter((d: any) => d.r10Age === 5);

    const filter = {
      c20s: count20s.map((d: any) => {
        const payment = Number(d.paymentAmount) > 0 ? Number(d.paymentAmount) : 0;
        const refund = Number(d.refundAmount) > 0 ? Number(d.refundAmount) : 0;
        return payment - refund;
      }),
      c30s: count30s.map((d: any) => {
        const payment = Number(d.paymentAmount) > 0 ? Number(d.paymentAmount) : 0;
        const refund = Number(d.refundAmount) > 0 ? Number(d.refundAmount) : 0;
        return payment - refund;
      }),
      c40s: count40s.map((d: any) => {
        const payment = Number(d.paymentAmount) > 0 ? Number(d.paymentAmount) : 0;
        const refund = Number(d.refundAmount) > 0 ? Number(d.refundAmount) : 0;
        return payment - refund;
      }),
      c50s: count50s.map((d: any) => {
        const payment = Number(d.paymentAmount) > 0 ? Number(d.paymentAmount) : 0;
        const refund = Number(d.refundAmount) > 0 ? Number(d.refundAmount) : 0;
        return payment - refund;
      }),
      c60s: count60s.map((d: any) => {
        const payment = Number(d.paymentAmount) > 0 ? Number(d.paymentAmount) : 0;
        const refund = Number(d.refundAmount) > 0 ? Number(d.refundAmount) : 0;
        return payment - refund;
      }),
    };

    let months: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    let arr20s: number[] = [];
    let arr30s: number[] = [];
    let arr40s: number[] = [];
    let arr50s: number[] = [];
    let arr60s: number[] = [];

    let index20s = 0;
    let index30s = 0;
    let index40s = 0;
    let index50s = 0;
    let index60s = 0;

    for (let i = 0; i < count20s.length; i++) {
      arr20s.push(new Date(count20s[i].cMonths).getMonth() + 1);
    }
    const set20s: any = new Set(arr20s);
    const setResult20sArr = [...set20s];

    for (let i = 0; i < count30s.length; i++) {
      arr30s.push(new Date(count30s[i].cMonths).getMonth() + 1);
    }
    const set30s: any = new Set(arr30s);
    const setResult30sArr = [...set30s];

    for (let i = 0; i < count40s.length; i++) {
      arr40s.push(new Date(count40s[i].cMonths).getMonth() + 1);
    }
    const set40s: any = new Set(arr40s);
    const setResult40sArr = [...set40s];

    for (let i = 0; i < count50s.length; i++) {
      arr50s.push(new Date(count50s[i].cMonths).getMonth() + 1);
    }
    const set50s: any = new Set(arr50s);
    const setResult50sArr = [...set50s];

    for (let i = 0; i < count60s.length; i++) {
      arr60s.push(new Date(count60s[i].cMonths).getMonth() + 1);
    }
    const set60s: any = new Set(arr60s);
    const setResult60sArr = [...set60s];

    months.forEach((m: any) => {
      // 20대 미만
      if (!setResult20sArr.includes(m)) {
        amount20s.push(0);
      } else {
        amount20s.push(filter.c20s[index20s]);
        index20s++;
      }

      // 30대
      if (!setResult30sArr.includes(m)) {
        amount30s.push(0);
      } else {
        amount30s.push(filter.c30s[index30s]);
        index30s++;
      }

      // 40대
      if (!setResult40sArr.includes(m)) {
        amount40s.push(0);
      } else {
        amount40s.push(filter.c40s[index40s]);
        index40s++;
      }

      // 50대
      if (!setResult50sArr.includes(m)) {
        amount50s.push(0);
      } else {
        amount50s.push(filter.c50s[index50s]);
        index50s++;
      }

      // 60대 이상
      if (!setResult60sArr.includes(m)) {
        amount60s.push(0);
      } else {
        amount60s.push(filter.c60s[index60s]);
        index60s++;
      }
    });
  }
  return { amount20s, amount30s, amount40s, amount50s, amount60s, total };
}
