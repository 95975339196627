import styled from "styled-components";
import * as color from "../../../color/index";

export const Checkbox = styled.div`
  display: inline-block;
`;

export const Input = styled.input`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
`;

export const Label = styled.label`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
`;
