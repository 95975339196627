import React, { useState, useEffect } from 'react';
import {
  HeaderBottom,
  StatusTool,
  GOButton,
  GOLayoutList,
  GOLoading,
  GOSearch,
  ProductGridView,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NetProductList, randomColor } from '../../../actions';

interface ParamsType {
  id: string | undefined;
}

const Sales = () => {
  const { t } = useTranslation();
  const params = useParams<ParamsType>();
  const USER_NAME = window.sessionStorage.getItem(`SLID${params.id}`);

  const [loading, setLoading] = useState<boolean>(true);
  // 전체 데이터
  const [data, setData] = useState<any>([]);

  // 왼쪽 카테고리
  const [categoryData, setCategoryData] = useState([]); // 카테고리 데이터
  const [categoryNumber, setCategoryNumber] = useState(-1); // 현재 카테고리 ID
  const [categoryDetail, setCategoryDetail] = useState<any>({}); // 현재 카테고리 상세

  // 오른쪽 상품 데이터
  const [productsData, setProductsData] = useState([]); // 상품 데이터

  // 감섹 데이터
  const [search, setSearch] = useState('');
  const [searchData, setSearchData] = useState<any>();

  const ProductTypeChanger = (value: any) => {
    switch (value) {
      case 0:
        return <>{t('product.personalLesson')}</>;
      case 1:
        return <>{t('product.groupLesson')}</>;
      case 2:
        return <>{t('product.usePlate')}</>;
      case 3:
        return <>{t('product.location')}</>;
      case 4:
        return <>{t('product.locker')}</>;
      case 5:
        return <>{t('product.etc')}</>;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    NetProductList().then(async (res: any) => {
      setData(res.data.Data);
      setCategoryData(res.data.Data.ProductCategories);
      await setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (categoryNumber !== -1) {
      setProductsData(data.Products?.filter((p: any) => p.productCategoryID === categoryNumber));
      setCategoryDetail(
        data.ProductCategories.filter((pc: any) => pc.id * 1 === categoryNumber)[0],
      );
    } else {
      setProductsData(data.Products);
      setCategoryDetail({});
    }
  }, [data, categoryNumber]);

  useEffect(() => {
    if (search) {
      setSearchData(productsData?.filter((pd: any) => pd.name.indexOf(search) !== -1));
    } else {
      setSearchData('');
    }
  }, [search, productsData]);

  return (
    <div>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool link="/admin/user/grid" type="prev" body={t('user.list')} />
            <StatusTool type="arrow" />
            <StatusTool
              link={`/admin/user/${params.id}`}
              type="prev"
              body={USER_NAME ? USER_NAME : params.id}
            />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={t('user.product')} />
          </>
        }
        rightmenu={<></>}
      />
      <GOLayoutList
        leftbody={
          loading ? (
            <GOLoading />
          ) : (
            <>
              <h4>{t('common.search')}</h4>
              <hr />
              <GOSearch
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
                handleSubmit={(e: any) => e.preventDefault()}
              />
              <br />
              <h4>{t('product.category')}</h4>
              <hr />
              <GOButton
                body={t('common.all')}
                width={'100%'}
                noMarginLeft
                style={{ marginTop: 8 }}
                onClick={() => {
                  setCategoryNumber(-1);
                }}
              />
              {categoryData.map((cd: any) => (
                <GOButton
                  body={
                    <>
                      <div
                        className="product_circle"
                        style={{
                          background: randomColor(cd.id),
                        }}
                      />
                      {ProductTypeChanger(cd.type)} / {cd.name}
                    </>
                  }
                  width={'100%'}
                  noMarginLeft
                  style={{
                    marginTop: 8,
                    textAlign: 'left',
                    padding: '0 12px',
                  }}
                  key={cd.id}
                  buttontype={cd.id === categoryNumber ? 'primary' : undefined}
                  onClick={() => {
                    setCategoryNumber(cd.id);
                  }}
                />
              ))}
            </>
          )
        }
        rightbody={
          loading ? (
            <GOLoading />
          ) : (
            <ProductGridView
              data={searchData ? searchData : productsData}
              cat={categoryDetail}
              sales
            />
          )
        }
      />
    </div>
  );
};

export default Sales;
