import styled, { keyframes } from 'styled-components';
import * as color from '../../../color/index';
import { BreakPoints, ContentBodyPadding, ContentBodyMinWidth } from '../../../GlobalUtilityStyles';

const floatingboxBg = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const floatingbox = keyframes`
  0% {
    opacity: 0;
    bottom: -60px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
`;

const floatingboxMobile = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const BackgroundView = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.2);

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: ${floatingboxBg} 0.75s;
`;

export const FloatingBoxContainer = styled.div`
  position: fixed; // absolute 시 긴 팝업일 경우, 위로 스크롤 되는 현상 있음.
  width: 100%;
  height: 100%;
  min-width: ${ContentBodyMinWidth};
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  overflow: auto;
`;

export const FloatingBoxWrapper = styled.div`
  transition: 0.35s;
  position: relative;
  padding: 32px;
  border-radius: 5px;
  background: ${color.Colors.white};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  animation: ${floatingbox} 0.35s;
  max-width: 96%;
  max-height: 96%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  ${BreakPoints.lg} {
    padding: ${ContentBodyPadding.lg};
  }

  ${BreakPoints.md} {
    padding: ${ContentBodyPadding.md};
  }

  ${BreakPoints.sm} {
    width: 100% !important;
    animation: ${floatingboxMobile} 0.35s;
    border-radius: 0;
    padding: ${ContentBodyPadding.sm};
    padding-bottom: 15px;
    max-width: 100%;
    max-height: 100%;
    min-height: 100%;
  }
`;
