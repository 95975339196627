import React from 'react';
import { HeaderBottom, StatusTool, DashboardView } from '../../components';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={t('main-menu.dashboard')} />
          </>
        }
      />
      <DashboardView />
    </>
  );
};

export default Dashboard;
