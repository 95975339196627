import { NetworkManager } from '../../actions';
import { REQ, ONLY_REQ, REQ_DETAIL } from './actionTypes';

interface actionTypes {
  type?: 'get' | 'post' | 'put' | 'delete';
  url?: string;
  params?: {};
  meta?: {};
}

export default (resourceName: any, key = 'id') => ({
  read: ({ url = resourceName, meta = {} }: actionTypes) => ({
    type: REQ,
    promise: NetworkManager('get', `admin/${url}`),
    meta: {
      ...meta,
      key,
      resourceName,
    },
  }),

  delete: ({ url = resourceName, meta = {} }: actionTypes) => ({
    type: REQ,
    promise: NetworkManager('delete', `admin/${url}`),
    meta: {
      ...meta,
      key,
      resourceName,
    },
  }),

  create: ({ url = resourceName, params = {}, meta = {} }: actionTypes) => ({
    type: REQ,
    promise: NetworkManager('post', `admin/${url}`, params),
    meta: {
      ...meta,
      key,
      resourceName,
    },
  }),

  update: ({ url = resourceName, params = {}, meta = {} }: actionTypes) => ({
    type: REQ,
    promise: NetworkManager('put', `admin/${url}`, params),
    meta: {
      ...meta,
      key,
      resourceName,
    },
  }),

  readDetail: ({ url = resourceName, meta = {} }: actionTypes) => ({
    type: REQ_DETAIL,
    promise: NetworkManager('get', `admin/${url}`),
    meta: {
      ...meta,
      key,
      resourceName,
    },
  }),

  updateDetail: ({ url = resourceName, meta = {} }: actionTypes) => ({
    type: REQ_DETAIL,
    promise: NetworkManager('put', `admin/${url}`),
    meta: {
      ...meta,
      key,
      resourceName,
    },
  }),

  onlyCreate: ({ url = resourceName, params = {}, meta = {} }: actionTypes) => ({
    type: ONLY_REQ,
    promise: NetworkManager('post', `admin/${url}`, params),
    meta: {
      ...meta,
      key,
      resourceName,
    },
  }),

  request: ({ type = 'get', url = resourceName, params = {}, meta = {} }: actionTypes) => ({
    type: REQ,
    promise: NetworkManager(type, `admin/${url}`, type === 'post' || type === 'put' ? params : ''),
    meta: {
      ...meta,
      key,
      resourceName,
    },
  }),

  onlyRequest: ({ type = 'get', url = resourceName, params = {}, meta = {} }: actionTypes) => ({
    type: ONLY_REQ,
    promise: NetworkManager(type, `admin/${url}`, params),
    meta: {
      ...meta,
      key,
      resourceName,
    },
  }),
});
