import styled, { keyframes } from 'styled-components';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const golayout = keyframes`
  0% {
    opacity: 0;
    left: -20px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #495057;
  margin-bottom: 20px;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #495057;
  margin-top: 36px;
  margin-bottom: 20px;
`;

export const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const Day = styled.div`
  position: relative;
  font-size: 20px;
  font-weight: 700;
  color: #495057;
`;

export const SwitchBox = styled.div``;

export const DateBox = styled.div`
  margin-top: 20px;
  margin-bottom: 36px;
`;

export const SubDateBox = styled.div`
  margin-top: 20px;
  margin-bottom: 90px;
`;

export const Container = styled.div`
  height: 100%;
`;

export const ViewBox = styled.div`
  animation: ${golayout} 0.35s;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div`
  font-size: 14px;
  color: #888888;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
`;

export const ConfirmButton = styled.div`
  text-align: center;
  margin-top: auto;

  ${BreakPoints.sm} {
    button {
      width: 100%;
    }
  }
`;
