import styled, { keyframes } from 'styled-components';
import * as color from '../../../color/index';
import { BreakPoints } from '../../../GlobalUtilityStyles';

const display = keyframes`
  0% {
    background: ${color.Colors.primary};
    bottom: -80px;
  }
  100% {
    bottom: 0;
    background: ${color.Colors.white};
  }
`;

export const Container = styled.div`
  bottom: 0;
  position: sticky;
  padding: 10px 15px;
  background: ${color.Colors.white};
  border-top: 1px solid ${color.Colors.line};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  animation: ${display} 0.45s;
  font-size: 14px;
  font-weight: bold;
  button,
  a {
    margin-left: 8px

    &:first-of-type {
      margin-left: 0;
    }
  }

  ${BreakPoints.sm} {
    h5 {
      width: 100%;
      margin-bottom: 10px;
      text-align: center;
    }
  }

  @media all and (max-width: 500px) {
    button,
    a {
      flex-grow: 1;
      margin-bottom: 8px;
      width: 40%;
    }

    a > button {
      width: 100%;
      margin-bottom: 0;
    }
  }
`;
