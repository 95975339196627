import React from "react";
import GOLoading from "../GOLoading";
import GOError from "../GOError";
import * as Styled from "./styles";
import {
  REQUEST_START,
  REQUEST_FINISH,
  REQUEST_SUCCESS,
  REQUEST_FAILURE,
} from "../../../stores/controller/actionTypes";

interface GOAsyncDivProps {
  status: string;
  children: any;
}

const GOAsyncDiv = ({ status, children }: GOAsyncDivProps) => {
  switch (status) {
    case REQUEST_START:
      return (
        <Styled.AsyncDiv>
          <GOLoading />
        </Styled.AsyncDiv>
      );
    case REQUEST_FINISH:
      return (
        <Styled.AsyncDiv>
          <GOLoading />
        </Styled.AsyncDiv>
      );
    case REQUEST_SUCCESS:
      return <>{children}</>;
    case REQUEST_FAILURE:
      return (
        <>
          <GOError body="서버 에러로 불러올수 없습니다." />
        </>
      );
    default:
      return (
        <>
          <GOError body="서버 통신이 발생하지 않았습니다." />
        </>
      );
  }
};
export default GOAsyncDiv;
