import NetworkManager from '../../../NetworkManager';
import { toast } from 'react-toastify';

const UserReservation = (data: any) => {
  return new Promise((resolve, reject) => {
    NetworkManager('post', 'admin/plateReservation', data)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        if (err.response) {
          switch (err.response.data.resultCode) {
            case 4102:
              toast.error(`⛔️ 최대 예약 개수가 초과하였습니다. [${err.response.data.resultCode}]`);
              break;
            case 4103:
              toast.error(
                `⛔️ 당일 중복 예약이 불가능한 예약입니다. [${err.response.data.resultCode}]`,
              );
              break;
            case 4110:
              toast.error(`⛔️ 만료된 티켓입니다. [${err.response.data.resultCode}]`);
              break;
            case 4111:
              toast.error(`⛔️ 티켓의 소유자가 다릅니다. [${err.response.data.resultCode}]`);
              break;
            case 4112:
              toast.error(`⛔️ 다른 종류의 티켓입니다. [${err.response.data.resultCode}]`);
              break;
            case 4116:
              toast.error(`⛔️ 티켓의 기간이 유효하지 않습니다. [${err.response.data.resultCode}]`);
              break;
            default:
              reject(err);
              break;
          }
        }
      });
  });
};

export default UserReservation;
