import NetworkManager from '../../../NetworkManager';
import { toast } from 'react-toastify';

const List = (pID: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager('delete', `admin/plateAvailability/${pID}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        toast.error(err.response.data.message);
      });
  });
};

export default List;
