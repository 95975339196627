export default function MonthlyUserSex(data: any) {
  let total: any = [];
  let male: any = [];
  let female: any = [];

  if (data) {
    let arr: any = [];

    for (let i = 0; i < data.length; i++) {
      arr.push(new Date(data[i].cMonths).getMonth() + 1);
    }
    const set: any = new Set(arr);
    const uniqueArr = [...set];
    let index = 0;

    const sex = {
      male: data
        .filter((d: any) => d.sex === 0)
        .map((d: any) => (Number(d.countUsers) > 0 ? Number(d.countUsers) : 0)),
      female: data
        .filter((d: any) => d.sex === 1)
        .map((d: any) => (Number(d.countUsers) > 0 ? Number(d.countUsers) : 0)),
    };

    for (let i = 1; i <= 12; i++) {
      if (!uniqueArr.includes(i)) {
        male.push(0);
        female.push(0);
        total.push(0);
      } else {
        male.push(sex.male[index]);
        female.push(sex.female[index]);
        total.push(sex.male[index] + sex.female[index]);
        index++;
      }
    }
  }
  return { total, male, female };
}
