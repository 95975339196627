import styled from 'styled-components';
import * as color from '../../color/index';
import { darken } from 'polished';
import { BreakPoints, HorizontalGlobalPadding } from '../../GlobalUtilityStyles';

/*********************************
  * 예약관리 - 그리드 상단 필터 영역 공통 스타일
  - 타석이용
  - 개인수업
  - 타석예약자
  - 개인수업예약자
***********************************/

export const LeftControlCustomStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 1;

  ${BreakPoints.sm} {
    justify-content: center;
    width: 80vw;
  }
  .arrow_btn {
    margin-left: 4px;
    width: 40px;
    height: 40px;
    background: url('/images/icon_arrow.png') ${color.Colors.white} center no-repeat;
    border: 1px solid ${color.Colors.line};
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
    display: inline-block;
    cursor: pointer;
    flex: 0 0 auto;

    &.right {
      transform: rotate(180deg);
      margin-right: 10px;
    }

    ${BreakPoints.md} {
      width: 32px;
      height: 32px;
    }
  }
`;

export const LessonInfo = styled.div`
  display: flex;
  aling-items: center;

  ${BreakPoints.sm} {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }

  .lessonTitle {
    margin: 0 10px;
    font-weight: bold;
    font-size: 18px;
    color: ${color.Colors.primary};
    display: flex;
    align-items: center;
    ${BreakPoints.md} {
      margin-left: 0;
    }

    ${BreakPoints.sm} {
      font-size: 16px;
      margin-top: 50x;
      margin-right: 0;
    }
  }

  .lessonDetail {
    font-size: 14px;
    color: ${color.Colors.primary};
    padding: 5px 10px;
    border: 1px solid ${color.Colors.line};
    border-radius: 5px;
    transition: 0.35s;
    cursor: pointer;

    &:hover {
      background: ${darken(0.1, `${color.Colors.white}`)};
      transition: 0.35s;
    }

    ${BreakPoints.sm} {
      width: 100%;
      text-align: center;
      margin-bottom: 8px;
      margin-top: 10px;
    }
  }
`;

export const CoachGroup = styled.div`
  margin-right: 20px;
  ${BreakPoints.md} {
    margin-right: 12px;
  }

  ${BreakPoints.sm} {
    margin-right: 0;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    justify-content: center;
    & > div {
      width: 100%;
    }
  }
`;

export const AlertWarpper = styled.div`
  display: flex;
  font-weight: bold;
  align-items: center;

  .alertBox {
    font-size: 14px;
    padding: 5px;
    border: 1px solid ${color.Colors.lightRed};
    border-radius: 5px;
    background: ${color.Colors.lightRed};
    color: ${color.Colors.red};
    flex-grow: 1;
    ${BreakPoints.sm} {
      margin-top: 10px;
    }
  }
`;

export const PeriodGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;

  .arrow_btn.left {
    margin-left: 0;
    margin-right: 4px;
  }

  .GOInput {
    margin: 0;
  }

  ${BreakPoints.md} {
    .GOInput input {
      height: 32px;
    }
  }
`;

export const PeriodTextGroup = styled.div`
  font-size: 32px;
  font-weight: 300;
  margin-right: 20px;
  padding-bottom: 2px;
  display: flex;
  align-items: center;

  .arrow_btn.left {
    margin-left: 10px;
  }

  ${BreakPoints.md} {
    font-size: 24px;
    margin-right: 12px;
  }

  ${BreakPoints.sm} {
    font-size: 20px;
    margin-right: 0px;
    height: 40px;
    // margin-bottom: 6px;
    padding-left: 12px;
  }
`;
