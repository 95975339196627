import styled from 'styled-components';
import * as color from '../../color/index';
import { BreakPoints } from '../../GlobalUtilityStyles';
// interface styleProps {
//   marginBottom?: boolean;
// }

export const Container = styled.div`
  // margin: 0 auto;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${color.Colors.primary};
  text-align: left;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  line-height: 40px;

  .GODropbox {
    margin-bottom: 0;
  }

  ${BreakPoints.sm} {
    font-size: 20px;
    margin-bottom: 15px;
  }
`;

export const ChartBox = styled.div`
  margin-bottom: 50px;

  ${BreakPoints.md} {
    margin-bottom: 30px;
  }
`;

export const Chart = styled.div`
  margin: auto;
`;

export const FlexGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${ChartBox} {
    flex: 1 1 auto;
    width: 260px; /* 좌우 2단 배치 차트영역 최소 너비 */

    &:first-child {
      margin-right: 50px;
    }
  }

  ${Chart} {
    max-width: 400px;
  }

  ${BreakPoints.lg} {
    ${ChartBox} {
      &:first-child {
        margin-right: 20px;
      }
    }
  }

  ${BreakPoints.md} {
    flex-direction: column;
    ${ChartBox} {
      width: 100%;
      &:first-child {
        margin-right: 0;
      }
    }
  }
`;
