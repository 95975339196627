import NetworkManager from "../../../NetworkManager";

const Game = (lkey: string, status: number) => {
  return new Promise((resolve, reject) => {
    NetworkManager(
      "post",
      `admin/plate/control/game?lockKey=${lkey}&isToBeOn=${status}`
    )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default Game;
