import NetworkManager from "../../../NetworkManager";

const ResRestore= (id: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager("put", `admin/lessonReservation/${id}/restore`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default ResRestore;
