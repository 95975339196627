// 실험실!
// 이부분은 예외적으로 Redux + Redux-pack 의 조합을 이용합니다.

import React, { useEffect } from 'react';
import { HeaderBottom, StatusTool, SegmentControl, PlateView } from '../../../components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetPlateList, actionGetSettingsList } from '../../../stores/actions';
import { UserManager } from '../../../actions';

//jeju campus
//UserManager().getUser()?.branch.id === 1

const PlateList = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const plate = useSelector((state: any) => state['plate']);

  const settings = useSelector((state: any) => {
    console.log('state -> settings', state['settings']);
    return state['settings'];
  });

  //feature/20210825/jejucampus : 제주캠퍼스의 경우는 임팩트 비전의 타석 정보가져오는 부분을 막아둔다.
  const isHiddenPlates =
    UserManager().getUser()?.branch.id === 1 || UserManager().getUser()?.branch.id === 20;

  useEffect(() => {
    dispatch(actionGetSettingsList());
    if (isHiddenPlates) {
      console.log('branch.id === 1 || branch.id === 20');
      return;
    }
    // 실행 plate 1분마다 plate 정보 다시 가져오기
    const interval = setInterval(() => {
      dispatch(actionGetPlateList());
    }, 120000);

    dispatch(actionGetPlateList()); // 최초실행 plate 정보 가져오기

    // 실행 plate Unmount (다른페이지 전환시 중단)
    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  return (
    <div>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={t('plate.list')} />
          </>
        }
      />
      {/*//제주캠퍼스 혹은 테스트 서버인 경우 타석 정보 보여주지 않는다.*/}
      {settings &&
      settings.data?.settings?.modalSettingShowInSetting === 1 &&
      settings.data?.settings?.modalSettingShowModalYN === 1 ? (
        <SegmentControl
          data={[
            {
              name: t('plate.list'),
              link: '/admin/plate/list',
            },
            {
              name: t('locker.list'),
              link: '/admin/plate/lockerlist',
            },
            {
              name: '타석 로그인',
              link: '/admin/plate/plateloginlist',
            },
          ]}
        />
      ) : (
        <SegmentControl
          data={[
            {
              name: t('plate.list'),
              link: '/admin/plate/list',
            },
            {
              name: t('locker.list'),
              link: '/admin/plate/lockerlist',
            },
          ]}
        />
      )}
      {isHiddenPlates ? <></> : <PlateView data={plate} />}
    </div>
  );
};
export default PlateList;
