import NetworkManager from '../../../NetworkManager';

const LessonReservationsByDate = (startDate: string, endDate: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager(
      'get',
      `admin/lessonAvailability/fetchLessonReservationsByDate?startDate=${startDate}&endDate=${endDate}`,
    )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default LessonReservationsByDate;
