import React from 'react';
import * as Styled from './styles';

interface GOSwitchProps {
  // Param
  // label : Input 에 들어갈 내용 설명

  label?: string;
  style?: object;
  status: boolean | number | Function | any;
  onClick?: any;
}

const GOSwitch = (props: GOSwitchProps) => {
  return (
    <Styled.Switch style={props.style}>
      {props.label && <Styled.SwitchLabel>{props.label}</Styled.SwitchLabel>}
      <Styled.SwitchBackground onClick={props.onClick} bg={props.status ? true : false}>
        <Styled.SwitchItem bg={props.status ? true : false} />
      </Styled.SwitchBackground>
    </Styled.Switch>
  );
};

export default GOSwitch;
