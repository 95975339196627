import React from "react";
import * as Styled from "./styles";

interface FooterProps {
  body?: JSX.Element | string;
}

const Footer = (props: FooterProps) => {
  return <Styled.Container>{props.body}</Styled.Container>;
};

export default Footer;
