import React from 'react';
import { HeaderBottom, StatusTool, HomePanel, Footer, GOButton, GOLayout } from '../../components';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';

import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { onModal } from '../../stores/modals';
import { NetSignOut } from '../../actions';

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  let languageTranslate = window.localStorage.getItem('i18nextLng');

  const onLogout = (data: any) => {
    let i = { identifier: data };
    NetSignOut(i)
      .then((res: any) => {
        if (res) {
          window.localStorage.removeItem('UUINFO');
          document.location.href = '/';
        }
      })
      .catch((err: any) => console.log(err));
  };

  return (
    <>
      <HeaderBottom status={<StatusTool type="prev" body={t('main-menu.home')} />} />
      <GOLayout
        flexGrow
        body={
          <Styled.HomeStyles>
            <div className="home_panels">
              <Link to="/admin/dashboard">
                <HomePanel
                  icon={'/images/dashboard.png'}
                  body={
                    languageTranslate === 'ko' || languageTranslate === 'ko-KR'
                      ? '대시보드'
                      : 'Dashboard'
                  }
                />
              </Link>
              <Link to="/admin/user/grid">
                <HomePanel
                  icon={'/images/icon_home_user@2x.png'}
                  body={t('main-menu.user_management')}
                />
              </Link>
              <Link to="/admin/product/list">
                <HomePanel
                  icon={'/images/icon_home_product@2x.png'}
                  body={t('main-menu.product_management')}
                />
              </Link>
              <Link to="/admin/reservation/list/group">
                <HomePanel
                  icon={'/images/icon_home_reservation@2x.png'}
                  body={t('main-menu.reservation_management')}
                />
              </Link>
              <Link to="/admin/plate/list">
                <HomePanel
                  icon={'/images/icon_home_seat@2x.png'}
                  body={t('main-menu.seat_management')}
                />
              </Link>
              <div
                onClick={() => {
                  dispatch(onModal('accountingpassword'));
                }}
              >
                <HomePanel
                  icon={'/images/icon_home_finance@2x.png'}
                  body={t('main-menu.finance_management')}
                />
              </div>
              <div
                onClick={() => {
                  dispatch(onModal('smartadvisorpassword'));
                }}
              >
                <HomePanel
                  icon={'/images/icon_home_chart@2x.png'}
                  body={t('main-menu.smart_advisor')}
                />
              </div>
              <div
                onClick={() => {
                  dispatch(onModal('employeepassword'));
                }}
              >
                <HomePanel
                  icon={'/images/icon_home_operation@2x.png'}
                  body={t('main-menu.employee_management')}
                />
              </div>
              <div
                onClick={() => {
                  window.open('/admin/checkin', 'popup', 'fullscreen=yes');
                }}
              >
                <HomePanel
                  icon={'/images/icon_home_usercheck-in@2x.png'}
                  body={t('main-menu.user_checkin')}
                />
              </div>
              <div
                onClick={() => {
                  dispatch(onModal('settingsPassword'));
                }}
              >
                <HomePanel
                  icon={'/images/setting.png'}
                  body={
                    languageTranslate === 'ko' || languageTranslate === 'ko-KR' ? '설정' : 'Setting'
                  }
                />
              </div>
              <div className="placeholder" />
              <div className="placeholder" />
            </div>
          </Styled.HomeStyles>
        }
      />

      <Footer
        body={
          <>
            <GOButton
              body="모바일 체크인"
              buttontype="primary"
              onClick={() => {
                history.push('/admin/checkin');
              }}
            />
            <GOButton
              body="보안핀 변경"
              buttontype="primary"
              onClick={() => {
                dispatch(onModal('changeSecretPin'));
              }}
            />
            {/* feature/20211105/changePwd*/}
            <GOButton
              body="비밀번호 변경"
              buttontype="primary"
              onClick={() => {
                dispatch(onModal('changePassword'));
              }}
            />
            <GOButton
              body="로그아웃"
              buttontype="primary"
              onClick={() => {
                onLogout(window.localStorage.getItem('Identifier'));
              }}
            />
          </>
        }
      />
    </>
  );
};

export default Home;
