import React from 'react';
import * as Styled from './styles';

interface SearchBarProps {
  placeholder?: string;
  style?: object;
  value?: any;
  onChange?: any;
  handleSubmit?: any;
}

const SearchBar = (props: SearchBarProps) => {
  return (
    <Styled.SearchBar onSubmit={props.handleSubmit}>
      <input
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        type="search"
      />
      <Styled.HeaderSearchIcon />
    </Styled.SearchBar>
  );
};

export default SearchBar;
