import React, { useState, useEffect, FormEvent } from 'react';
import * as Styled from './styles';
import { useParams, useHistory } from 'react-router-dom';
import {
  HeaderBottom,
  StatusTool,
  GOLayout,
  GOLoading,
  GOLayoutBox,
  GOLabel,
  SmallTitle,
  ProductPanel,
  SalesTable,
  GODropbox,
  GOInput,
  Footer,
  GOButton,
  GOSwitch,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { NetProductDetail, NetProductEmployeeList, NetSalesRegister } from '../../../actions';
import dayjs from 'dayjs';

import { toast } from 'react-toastify';

interface ParamsType {
  id: string;
  productID: string;
}

const Sales = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [productAllData, setProductAllData] = useState<any>({});
  const [productData, setProductData] = useState<any>({});
  const [employeeData, setEmployeeData] = useState<any>([]);
  const [salesData, setSalesData] = useState<any>({
    price: 0,
    card: 0,
    cash: 0,
    transfer: 0,
    discount: 0,
    totalPrice: 0,
    unpaidPrice: 0,
  });
  const [paymentData, setPaymentData] = useState<any>({
    name: '',
    managerName: '',
    paymentDate: '',
    startDate: '',
    memo: '',
    employeeID: undefined,
    ticket: {},
  });

  const [validationTotalCount, setValidationTotalCount] = useState<boolean>(true);

  const params = useParams<ParamsType>();
  const USER_NAME = window.sessionStorage.getItem(`SLID${params.id}`);
  const { t } = useTranslation();

  const history = useHistory();

  const employeeListFilter = employeeData.filter((e: any) => e.type === 2);

  useEffect(() => {
    const today = dayjs().format('YYYY-MM-DD');

    NetProductDetail(params.productID).then((res: any) => {
      setProductAllData(res.data.Data.ProductCategory);
      setProductData({
        ...res.data.Data.ProductCategory.Products[0],
        usedCount: 0,
        remainingCount: res.data.Data.ProductCategory.Products[0].totalCount,
        startDate: dayjs().format('YYYY-MM-DD'),
        endDate: dayjs()
          .add(res.data.Data.ProductCategory.Products[0].period - 1, 'day')
          .format('YYYY-MM-DD'),
      });
      setPaymentData({
        name: res.data.Data.ProductCategory.Products[0]?.name,
        managerName: res.data.Data.ProductCategory.Products[0].Employee?.name,
        paymentDate: today,
        startDate: today,
        memo: '',
        employeeID: res.data.Data.ProductCategory.Products[0].Employee?.id,
        ticket: {},
      });
      setLoading(false);
    });
  }, [params]);

  useEffect(() => {
    NetProductEmployeeList().then((res: any) => {
      setEmployeeData(res.data.Data.Employees);
    });
  }, []);

  const handleDate = (startDate: any, period: any, pData?: any) => {
    const endDate = dayjs(startDate)
      .add(period - 1, 'day')
      .format('YYYY-MM-DD');
    if (pData) {
      setProductData({ ...pData, period, startDate, endDate });
    } else {
      setProductData({ ...productData, period, startDate, endDate });
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (productData.startDate === 0) {
      const today = dayjs().format('YYYY-MM-DD');
      const endDate = dayjs()
        .add(productData.period - 1, 'day')
        .format('YYYY-MM-DD');
      setProductData({
        ...productData,
        startDate: today,
        endDate,
      });
    } else if (name === 'startDate') {
      const startDate = value;
      handleDate(startDate, productData.period);
      setPaymentData({ ...paymentData, startDate: dayjs(value).format('YYYY-MM-DD') });
    } else if (name === 'employeeID') {
      setPaymentData({ ...paymentData, [name]: parseInt(value) });
    } else setPaymentData({ ...paymentData, [name]: value });
  };

  const ticketHandleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'employeeID') {
      setProductData({ ...productData, [name]: parseInt(value) });
    } else if (name === 'period') {
      setProductData({ ...productData, [name]: value });
    } else if (name === 'totalCount') {
      if (
        (productData.type === 2 || productData.type === 0) &&
        productData.isUnlimited === false &&
        value < 1
      ) {
        setValidationTotalCount(false);
      } else {
        setValidationTotalCount(true);
      }
      setProductData({ ...productData, [name]: value, remainingCount: value });
    } else setProductData({ ...productData, [name]: value });
  };

  const handleSubmit = (e: FormEvent<EventTarget>) => {
    e.preventDefault();

    let hour = new Date().getHours();
    let minute = new Date().getMinutes();
    let second = new Date().getSeconds();

    const temp = {
      userID: params.id,
      Payment: {
        ...paymentData,
        paymentDate: dayjs(`${paymentData.paymentDate}T${hour}:${minute}:${second}`).format(
          'YYYY-MM-DDTHH:mm:ss',
        ),
        managerName: employeeData.filter((ed: any) => ed.id * 1 === paymentData.employeeID * 1)[0]
          ?.name,
        ...salesData,
        ticket: productData,
      },
    };
    //DONETODO: 회차권인데 최대 횟수가 0인 경우 판매등록 되지 않게 한다.

    let isViolatedTicketCondition = false;
    try {
      isViolatedTicketCondition =
        (productData.type === 2 || productData.type === 0) &&
        productData.isUnlimited === false &&
        productData.totalCount < 1;
    } catch (e) {
      console.log(e);
      isViolatedTicketCondition = false;
    }

    console.log(productData);
    console.log(isViolatedTicketCondition);

    if (isViolatedTicketCondition) {
      setValidationTotalCount(false);
      toast(
        `무제한 여부가 꺼져있어 회차권인데 총 사용 가능 횟수가 0입니다. 전체 횟수를 입력하여 주십시요. `,
      );
      alert(
        `무제한 여부가 꺼져있어 회차권인데 총 사용 가능 횟수가 0입니다. 전체 횟수를 입력하여 주십시요. `,
      );
    } else if (temp.Payment.unpaidPrice < 0) {
      alert('금액이 맞지 않습니다. 다시 확인해주세요!');
    } else if (temp.Payment.unpaidPrice > 0) {
      if (window.confirm('[주의] 미수금이 있습니다. 판매등록 하시겠습니까?')) {
        NetSalesRegister(temp).then(() => {
          alert('판매가 등록되었습니다.');
          history.push(`/admin/user/${params.id}`);
        });
      }
    } else if (window.confirm('판매등록 하시겠습니까?')) {
      NetSalesRegister(temp).then(() => {
        alert('판매가 등록되었습니다.');
        history.push(`/admin/user/${params.id}`);
      });
    }
  };

  const typeString = () => {
    const pType = productData?.type;

    if (pType === 0) return '개인레슨';
    else if (pType === 1) return '그룹레슨';
    else if (pType === 2) return '타석이용';
    else if (pType === 3) return '장소';
    else if (pType === 4) return '락카서비스';
    else if (pType === 5) return '기타';
    else if (pType === 6) return '제주캠퍼스';
  };

  return (
    <>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool link="/admin/user/grid" type="prev" body={t('user.list')} />
            <StatusTool type="arrow" />
            <StatusTool
              link={`/admin/user/${params.id}`}
              type="prev"
              body={USER_NAME ? USER_NAME : params.id}
            />
            <StatusTool type="arrow" />
            <StatusTool
              link={`/admin/user/${params.id}/sales`}
              type="prev"
              body={t('user.product')}
            />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={t('sales.register')} />
          </>
        }
        rightmenu={<></>}
      />
      <Styled.PageForm onSubmit={handleSubmit}>
        <GOLayout
          body={
            loading ? (
              <GOLoading />
            ) : (
              <Styled.BoxWrapper>
                <GOLayoutBox
                  body={
                    <>
                      <SmallTitle body={t('sales.productInfo')} />
                      <ProductPanel
                        data={productData}
                        link={`/admin/product/${productData.id}`}
                        category={productAllData}
                        view
                      />
                      <br />
                      <br />
                      <SmallTitle body={t('sales.userInfo')} />
                      <GOLabel label={t('user.name')} body={USER_NAME} />
                      <GOLabel label={t('user.number')} body={params.id} />
                    </>
                  }
                />
                <GOLayoutBox
                  body={
                    <>
                      <SmallTitle body={t('sales.payment')} />
                      <SalesTable
                        productData={productData}
                        salesData={salesData}
                        setSalesData={setSalesData}
                      />
                      <br />
                      <SmallTitle body={t('sales.detail')} />
                      <GOInput
                        label="결제 담당"
                        value={productData.Employee?.name}
                        disabled={true}
                      />
                      <GOInput
                        label="결제일자"
                        name="paymentDate"
                        type="date"
                        value={paymentData.paymentDate}
                        onChange={handleChange}
                        required
                      />
                      <GOInput
                        label="이용시작"
                        name="startDate"
                        type="date"
                        value={paymentData.startDate}
                        onChange={handleChange}
                        required
                      />
                      <GOInput
                        label="결제메모"
                        name="memo"
                        value={paymentData.memo}
                        onChange={handleChange}
                        textarea
                      />
                    </>
                  }
                />
                <GOLayoutBox
                  body={
                    <>
                      <SmallTitle body={'이용권 상세'} />
                      <Styled.Panel>
                        <SmallTitle body={`${typeString()} / ${productData.name}`} />
                        <GOInput
                          label="기간"
                          name="period"
                          unit="일"
                          type="number"
                          min="0"
                          max="999"
                          value={productData.period}
                          onChange={(e: any) => {
                            ticketHandleChange(e);
                            handleDate(productData.startDate, e.target.value, productData);
                          }}
                          required
                        />
                        {productData.type === 4 ? (
                          <div style={{ marginBottom: '80px' }} />
                        ) : (
                          <>
                            <GOInput
                              style={
                                validationTotalCount
                                  ? {}
                                  : { border: '2px solid red', transition: '0.25s' }
                              }
                              label="전체횟수"
                              name="totalCount"
                              unit="번"
                              type="number"
                              min="0"
                              max="999"
                              value={productData.totalCount}
                              onChange={ticketHandleChange}
                              disabled={productData.isUnlimited}
                            />
                            <Styled.SwitchBox>
                              <GOSwitch
                                label="무제한 여부"
                                status={productData.isUnlimited}
                                onClick={() => {
                                  if (
                                    (productData.type === 2 || productData.type === 0) &&
                                    productData.isUnlimited &&
                                    productData.totalCount < 1
                                  ) {
                                    setValidationTotalCount(false);
                                  } else {
                                    setValidationTotalCount(true);
                                  }
                                  setProductData({
                                    ...productData,
                                    isUnlimited: !productData.isUnlimited,
                                  });
                                }}
                              />
                              <GOSwitch
                                label={t('product.onlyOnceToday')}
                                status={productData.onlyOnceToday}
                                onClick={() => {
                                  setProductData({
                                    ...productData,
                                    onlyOnceToday: !productData.onlyOnceToday,
                                  });
                                }}
                              />
                            </Styled.SwitchBox>
                          </>
                        )}
                        {(productData.type === 0 || productData.type === 1) && (
                          <>
                            <GODropbox
                              name="employeeID"
                              label={t('product.employee')}
                              data={[
                                ...employeeListFilter?.map((el: any) => {
                                  return { body: el.name, value: el.id };
                                }),
                              ]}
                              onChange={ticketHandleChange}
                              value={productData.employeeID}
                              required
                            />
                          </>
                        )}
                      </Styled.Panel>
                    </>
                  }
                />
              </Styled.BoxWrapper>
            )
          }
        />
        <Footer
          body={
            <>
              <GOButton buttontype="green" body={t('sales.register')} type="submit" />
            </>
          }
        />
      </Styled.PageForm>
    </>
  );
};

export default Sales;
