import React, { useEffect } from 'react';
import { HeaderBottom, StatusTool, EmployeeLessonList, GOLayout } from '../../../components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetUserLessonList } from '../../../stores/actions';
import { employeeDetailTypes } from '../../../Types/employeeType';

interface LocationType {
  data: employeeDetailTypes;
  employeeName: string;
  employeeID: number | any;
}

const LessonList = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const location = useLocation<LocationType>();

  const employeeDetailData = location.state?.data;
  const employeeID = location.state?.employeeID;
  const employeeName = location.state?.employeeName;

  const lesson = useSelector((state: any) => state.employee);
  const lessonData = lesson.data.users;

  useEffect(() => {
    dispatch(actionGetUserLessonList(employeeID));
  }, [employeeID, dispatch]);

  return (
    <>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool link="/admin/employee/list" type="prev" body={t('employee.list')} />
            <StatusTool type="arrow" />
            <StatusTool
              data={employeeDetailData}
              link={`/admin/employee/${employeeID}`}
              type="prev"
              body={employeeName}
            />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={t('employee.lessonList')} />
          </>
        }
      />
      <>
        <GOLayout body={<EmployeeLessonList data={lessonData} />} />
      </>
    </>
  );
};

export default LessonList;
