import MainUser from "./user";

interface UserManager<T> {
  KEY: String;
  getUser(): T;
  setUser(user: T): void;
}

const MainUserManager = (): UserManager<MainUser> => {
  const KEY: string = "UUINFO";

  const getUser = (): MainUser => {
    const dataString = window.localStorage.getItem(String(KEY));
    if (dataString == null) {
      return {
        isSignIn: false,
        id: 0,
        name: "",
        username: "",
        companyNumber: "",
        phoneNumber: "",
        clientSecretKey: "",
        isApproved: false,
        accessToken: "",
      };
    }
    return JSON.parse(dataString);
  };

  const setUser = (user: MainUser): void => {
    const userString = JSON.stringify(user);
    window.localStorage.setItem(String(KEY), userString);
  };

  return { KEY, getUser, setUser };
};

export default MainUserManager;
