import React, { useEffect, useState } from 'react';
import {
  HeaderBottom,
  StatusTool,
  GOLayoutList,
  ListStatusBar,
  GOTable,
  Footer,
  GOButton,
  GOLoading,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useTable } from '../../../hooks';
import { NetUserList } from '../../../actions';

const User = () => {
  const { t } = useTranslation();
  const { check, setCheck } = useTable();
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    NetUserList().then((res: any) => {
      setUserData(res.data.Data.Users);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={t('user.list')} />
          </>
        }
      />
      <GOLayoutList
        leftbody={
          <>
            <h4>회원</h4>
            <hr />
            <h6>전체 회원</h6>
          </>
        }
        rightbody={
          loading ? (
            <GOLoading />
          ) : (
            <>
              <ListStatusBar leftbody="전체회원" rightbody="100개의 리스트" />
              <GOTable
                columns={[
                  { header: '이름', link: 'name' },
                  { header: '성별', link: 'sex' },
                  { header: '전화번호', link: 'phoneNumber' },
                  { header: '주소', link: 'address' },
                ]}
                data={userData}
                check={check}
                setCheck={setCheck}
                checkbox
                link={'/admin/user'}
              />
            </>
          )
        }
      />
      {check.length > 0 && (
        <Footer
          body={
            <>
              <GOButton body="푸시 메시지" />
              <GOButton buttontype="red" body="회원삭제" />
            </>
          }
        />
      )}
    </>
  );
};

export default User;
