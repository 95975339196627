import styled from 'styled-components';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const PanelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-weight: 700;
  align-items: center;
  background: #f9f9f9;
  padding: 8px 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  ${BreakPoints.sm} {
    margin-bottom: 10px;
    padding: 8px 10px;
  }
`;

export const PlateBox = styled.div`
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  width: 200px;
  ${BreakPoints.sm} {
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
  }
`;

export const PlateCheckWrapper = styled.div`
  width: 44px;
  margin-right: 10px;
  ${BreakPoints.sm} {
    width: 36px;
  }
`;

export const PlateCheckSuccessIcon = styled.i`
  font-size: 36px;
  color: #12b886;
`;

export const PlateCheckIcon = styled.i`
  font-size: 36px;
`;

export const PlateNonCheckIcon = styled.i`
  font-size: 36px;
  color: #f9f9f9;
`;

export const PlateTitle = styled.div`
  font-size: 20px;
  color: #495057;
  ${BreakPoints.sm} {
    font-size: 16px;
  }
`;

export const PlateTicketName = styled.div`
  font-size: 18px;
  color: #989898;
  ${BreakPoints.sm} {
    font-size: 14px;
  }
`;

export const PlateTimeBox = styled.div`
  width: 260px;
  display: flex;
  text-align: center;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 10px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  margin: 0 20px;
  flex: 1 0 auto;
  .time_group {
    width: 45%;
    &:first-child {
      background: url(/images/time_arrow.png) no-repeat right center;
      width: 55%;
    }
  }
  .label {
    color: #495057;
  }
  .time {
    color: #989898;
  }

  ${BreakPoints.sm} {
    padding-left: 0;
    padding-right: 0;
    border-left: 0;
    border-right: 0;
    margin: 0 8px 0 0;
    flex: 0 0 auto;
    font-size: 14px;
    width: auto;
    .time_group {
      width: auto;
      display: flex;
      &:first-child {
        width: auto;
        background: url(/images/time_arrow.png) no-repeat right center;
        padding-right: 20px;
        margin-right: 8px;
      }
      .label {
        margin-right: 8px;
      }
    }
  }

  ${BreakPoints.xs} {
    .time_group {
      &:first-child {
        background: none;
        padding-right: 0;
      }
    }
  }
`;

export const UsePlateBox = styled.div`
  text-align: center;
  font-size: 18px;
  flex: 1 1 auto;
  width: 130px;
  ${BreakPoints.sm} {
    display: flex;
    font-size: 14px;
    .label {
      margin-right: 10px;
    }
  }
`;

export const UsePlateName = styled.div`
  color: #989898;
`;
