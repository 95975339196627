import styled from 'styled-components';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const Panel = styled.button`
  position: relative;
  width: 365px;
  flex-basis: 256px;
  flex-grow: 1;
  height: 84px;
  padding: 18px 0 18px 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  margin: 0 4px 8px;
  cursor: pointer;
  transition: 0.35s;
  color: #495057;
  background: white;
  display: inline-block;

  &:hover {
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.16);
    transition: 0.35s;
  }
  &:focus {
    background-color: #495057;
    color: white;
    outline: 0;
  }

  ${BreakPoints.sm} {
    padding: 15px;
  }
  ${BreakPoints.xs} {
    padding: 10px;
  }
`;

export const PanelTop = styled.div`
  display: flex;
  height: 54px;
`;

export const PanelCircle = styled.div`
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background: #eee;
  margin-right: 12px;
`;

export const PanelInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const PanelName = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 6px;
`;

export const PanelDetail = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #adb5bd;
`;
