import styled from 'styled-components';
import * as color from '../../../color/index';

export const PageForm = styled.form`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

export const Panel = styled.div`
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  background: ${color.Colors.background};
  padding: 10px;
`;

export const SwitchBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: ${color.Colors.primary};
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
