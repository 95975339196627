import React, { ChangeEvent } from 'react';
import * as Styled from '../settingStyles';
import { GOSwitch } from '../../../components';

type lessonSettingProps = {
  settingData: any;
  setSettingData: any;
  save: boolean;
  setSave: any;
  edit: boolean;
};

const LessonSettings = ({
  settingData,
  setSettingData,
  save,
  setSave,
  edit,
}: lessonSettingProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    console.log(name, value);
    setSave(false);
    if (name === 'lessonCancellationTimeLimit') {
      setSettingData({ ...settingData, [name]: value });
    } else {
      if (parseInt(value) < 1) setSettingData({ ...settingData, [name]: '1' });
      else setSettingData({ ...settingData, [name]: value.slice(0, 2) });
    }
  };
  return (
    <Styled.BoxWrapper>
      <div className="subTitle">레슨 관련 설정</div>
      <div className="box-list">
        <div className="box">
          <div className="inner">
            <div className="label">레슨 최대 예약 개수</div>
            <Styled.Flexbox>
              <Styled.Input
                name="maxLessonReservationCount"
                type="number"
                onChange={handleChange}
                value={settingData?.maxLessonReservationCount || ''}
                disabled={edit}
                required
              />
              <Styled.Unit>개</Styled.Unit>
            </Styled.Flexbox>
          </div>
          <div className="description">
            <div>레슨 스케줄에서 회원이 예약할 수 있는 최대 개수를 설정합니다.</div>
            <div>기본 설정은 3개이며 최소 1개, 최대 20개로 설정 가능합니다.</div>
          </div>
        </div>
        <div className="box">
          <div className="inner">
            <div className="label">레슨 예약 가능 일자</div>
            <Styled.Flexbox>
              <Styled.Input
                name="lessonScheduleDaysToOpen"
                type="number"
                onChange={handleChange}
                value={settingData?.lessonScheduleDaysToOpen || ''}
                disabled={edit}
                required
              />
              <Styled.Unit>일</Styled.Unit>
            </Styled.Flexbox>
          </div>
          <div className="description">
            <div>행복골프 앱에서 레슨예약이 가능한 날짜 범위를 설정합니다.</div>
            <div>최소 1일(당일), 최대 30일(1달)까지 설정 가능합니다.</div>
          </div>
        </div>
        <div className="box">
          <div className="inner">
            <div className="label">레슨 예약 취소 제한 </div>
            <Styled.Flexbox>
              <Styled.SelectBox
                name="lessonCancellationTimeLimit"
                onChange={handleChange}
                value={settingData.lessonCancellationTimeLimit}
                disabled={edit}
              >
                <option value={-1}>전일</option>
                <option value={20}>20시</option>
                <option value={21}>21시</option>
                <option value={22}>22시</option>
                <option value={23}>23시</option>
              </Styled.SelectBox>
            </Styled.Flexbox>
          </div>
          <div className="description">
            <div>
              행복골프 앱에서 레슨 당일 취소 불가이며, 전일이라도 특정시간 이후로는 취소 불가로
              설정합니다.{' '}
            </div>
            <div>
              {' '}
              '전일'인 경우, 레슨 하루 전에 언제든 취소 가능합니다. 20시로 설정할 경우, 내일 레슨
              예약을 오늘 20시(저녁 8시) 이후에는 에약 취소가 안됩니다.
            </div>
          </div>
        </div>
        <div className="box">
          <div className="inner">
            <div className="label">레슨 스케줄 생성 시간</div>
            <Styled.Flexbox>
              <Styled.SelectBox
                name="lessonClassDuration"
                onChange={handleChange}
                value={settingData.lessonClassDuration}
                disabled={edit}
              >
                <option value={10}>10 분</option>
                <option value={15}>15 분</option>
                <option value={20}>20 분</option>
                <option value={25}>25 분</option>
                <option value={30}>30 분</option>
                <option value={40}>40 분</option>
                <option value={50}>50 분</option>
                <option value={60}>60 분</option>
              </Styled.SelectBox>
            </Styled.Flexbox>
          </div>
          <div className="description">
            <div>생성되는 레슨 프로의 스케줄 시간을 설정합니다.</div>
            <div>
              기본 설정은 30분으로 설정되어 있으며 최소 10분, 최대 60분 사이로 설정 가능합니다.
            </div>
          </div>
        </div>
        <div className="box">
          <div className="inner">
            <div className="label">레슨 예약 알림 설정</div>
            <div>
              <GOSwitch
                style={{ margin: '0' }}
                status={settingData.lessonPushStatus}
                onClick={() => {
                  if (!edit) {
                    setSettingData({
                      ...settingData,
                      lessonPushStatus: !settingData.lessonPushStatus,
                    });
                    setSave(!save);
                  } else {
                    alert('상단 수정 버튼을 눌러주세요.');
                    window.scrollTo(0, 0);
                  }
                }}
              />
            </div>
          </div>
          <div className="description" style={{ marginTop: '0px' }}>
            <div>레슨 예약에 대한 PUSH 알림을 설정합니다.</div>
            <div>사용자에게 레슨 예약에 대한 내용이 예약 1일 전, 오후 6시에 자동 발송됩니다.</div>
          </div>
        </div>
        <div className="box">
          <div className="inner">
            <div className="label">레슨 예약 문자 설정</div>
            <div>
              <GOSwitch
                style={{ margin: '0' }}
                status={settingData.lessonSmsStatus}
                onClick={() => {
                  if (!edit) {
                    setSettingData({
                      ...settingData,
                      lessonSmsStatus: !settingData.lessonSmsStatus,
                    });
                    setSave(!save);
                  } else {
                    alert('상단 수정 버튼을 눌러주세요.');
                    window.scrollTo(0, 0);
                  }
                }}
              />
            </div>
          </div>
          <div className="description" style={{ marginTop: '0px' }}>
            <div>레슨 예약에 대한 단문 문자 메시지(SMS)를 설정합니다.</div>
            <div>
              사용자에게 레슨 예약에 대한 내용이 예약 1일 전, 오후 6시 경에 자동 발송됩니다.
            </div>
            <div style={{ color: '#fa5252', textDecoration: 'underline' }}>
              단문 메시지 (SMS)의 건당 비용은 10원이고 부가세 별도이며, 익월 로열티 정산에
              포함됩니다.
            </div>
          </div>
        </div>
      </div>
    </Styled.BoxWrapper>
  );
};

export default LessonSettings;
