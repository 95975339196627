import React from "react";
import { useTranslation } from "react-i18next";

const useTypeChanger = (value: number) => {
  const { t } = useTranslation();

  switch (value) {
    case 0:
      return <>{t("product.personalLesson")}</>;
    case 1:
      return <>{t("product.groupLesson")}</>;
    case 2:
      return <>{t("product.usePlate")}</>;
    case 3:
      return <>{t("product.location")}</>;
    case 4:
      return <>{t("product.locker")}</>;
    case 5:
      return <>{t("product.etc")}</>;
    default:
      return <></>;
  }
};

export default useTypeChanger;
