import styled, { css } from 'styled-components';
import { darken } from 'polished';
import * as color from '../../../color/index';
import { BreakPoints } from '../../../GlobalUtilityStyles';

interface buttonProps {
  disable?: boolean;
  red?: boolean;
  green?: boolean;
  primary?: boolean;
  gray?: boolean;
  darkGray?: boolean;
  noMarginLeft?: boolean;
  xxlarge?: boolean;
  xlarge?: boolean;
  large?: boolean;
  medium?: boolean;
  small?: boolean;
  xsmall?: boolean;
  xxsmall?: boolean;
}

export const Button = styled.button<buttonProps>`
  flex-shrink: 0;
  // word-break: normal;
  white-space: nowrap;
  padding: 0px 12px;
  height: 40px;
  text-align: center;
  color: black;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  background: ${color.Colors.white};
  border: 1px solid ${color.Colors.line};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  transition: 0.25s;
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
  margin-left: 8px;

  ${BreakPoints.sm} {
    margin-left: 5px;
  }

  &:hover {
    background: ${darken(0.05, `${color.Colors.background}`)};
    transition: 0.25s;
  }

  ${props => {
    if (props.noMarginLeft) {
      return css`
        margin-left: 0;
        ${BreakPoints.sm} {
          margin-left: 0;
        }
      `;
    }
  }}

  &:focus {
    outline: none;
  }

  ${props => {
    if (props.red)
      return css`
        background: ${color.Colors.red};
        border: 1px solid ${color.Colors.red};
        color: ${color.Colors.white};

        &:hover {
          background: ${darken(0.05, `${color.Colors.red}`)};
          transition: 0.25s;
        }
        &:focus {
          outline: none;
        }
      `;
    if (props.primary)
      return css`
        background: ${color.Colors.primary};
        border: 1px solid ${color.Colors.primary};
        color: ${color.Colors.white};
        box-shadow: 0 3px 6px rgba(73, 80, 87, 0.3);

        &:hover {
          background: ${darken(0.05, `${color.Colors.primary}`)};
          transition: 0.25s;
        }
        &:focus {
          outline: none;
        }
      `;
    if (props.green)
      return css`
        background: ${color.Colors.green};
        border: 1px solid ${color.Colors.green};
        color: ${color.Colors.white};

        &:hover {
          background: ${darken(0.05, `${color.Colors.green}`)};
          transition: 0.25s;
        }
        &:focus {
          outline: none;
        }
      `;
    if (props.disable)
      return css`
        background: ${color.Colors.sub};
        color: ${color.Colors.effect};
        border: 1px solid ${color.Colors.sub};
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);

        &:hover {
          background: ${darken(0.05, `${color.Colors.sub}`)};
          transition: 0.25s;
        }
        &:focus {
          outline: none;
        }
      `;
    if (props.gray)
      return css`
        background: ${color.Colors.background};
        color: black;
        border: 1px solid ${color.Colors.line};
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);

        &:hover {
          background: ${darken(0.05, `${color.Colors.background}`)};
          transition: 0.25s;
        }
        &:focus {
          outline: none;
        }
      `;
    if (props.darkGray)
      return css`
        background: #efefef;
        color: black;
        border: 1px solid ${color.Colors.line};
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);

        &:hover {
          background: ${darken(0.05, `#EFEFEF`)};
          transition: 0.25s;
        }
        &:focus {
          outline: none;
        }
      `;
  }}

  ${props => {
    if (props.xxlarge)
      return css`
        width: 270px;
        height: 54px;
        font-size: 30px;
      `;
    if (props.xlarge)
      return css`
        width: 180px;
        height: 40px;
        font-size: 14px;
        @media (max-width: 1300px) {
          width: 150px;
          font-size: 12px;
        }
      `;
    if (props.large)
      return css`
        width: 160px;
        height: 48px;
        font-size: 16px;
      `;
    if (props.medium)
      return css`
        width: 80px;
        height: 40px;
      `;
    if (props.small)
      return css`
        width: 68px;
        height: 40px;
      `;
    if (props.xsmall)
      return css`
        width: 56px;
        height: 32px;
      `;
    if (props.xxsmall) return css``;
  }}
`;

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
