import styled from 'styled-components';
import * as color from '../../../color/index';

export const Plate = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .platebox {
    flex-shrink: 0;
    margin: 5px;
    margin-bottom: 8px;
    width: 32px;
    height: 32px;
    border: 1px solid ${color.Colors.line};
    border-radius: 5px;
    text-align: center;
    line-height: 32px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    transition: 0.35s;
    cursor: pointer;
  }

  .sat {
    color: ${color.Colors.blue};
  }

  .sun {
    color: ${color.Colors.red};
  }

  .platebox_no {
    background: ${color.Colors.red};
    border: 1px solid ${color.Colors.red};
  }

  .platebox:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
    transition: 0.35s;
  }

  .platebox_choice {
    background: ${color.Colors.green};
    color: ${color.Colors.white};
    border: 1px solid ${color.Colors.green};
    box-shadow: 0 3px 6px rgba(18, 184, 134, 0.3);
    font-weight: bold;

    span {
      color: ${color.Colors.white};
    }
  }
`;

export const PlateName = styled.div`
  display: flex;
  color: ${color.Colors.primary};
  font-weight: bold;
  font-size: 14px;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  input {
    width: 235px;
    border: none;
    border-bottom: 2px solid ${color.Colors.line};
    font-size: 14px;
    font-weight: bold;
    color: ${color.Colors.primary};
  }
`;
