export default function YearlySalesSex(data: any) {
  let male: number[] = [];
  let female: number[] = [];
  let total: number[] = [];

  if (data) {
    let countMale = data.filter((d: any) => d.sex === 0);
    let countFemale = data.filter((d: any) => d.sex === 1);

    const filter = {
      male: countMale.map((d: any) => {
        let payment = Number(d.paymentAmount) > 0 ? Number(d.paymentAmount) : 0;
        let refund = Number(d.refundAmount) > 0 ? Number(d.refundAmount) : 0;
        let result = payment - refund;
        return result >= 0 ? result : 0;
      }),
      female: countFemale.map((d: any) => {
        let payment = Number(d.paymentAmount) > 0 ? Number(d.paymentAmount) : 0;
        let refund = Number(d.refundAmount) > 0 ? Number(d.refundAmount) : 0;
        let result = payment - refund;
        return result >= 0 ? result : 0;
      }),
    };

    let months: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    let maleArr: number[] = [];
    let femaleArr: number[] = [];

    let maleIndex = 0;
    let femaleIndex = 0;

    for (let i = 0; i < countMale.length; i++) {
      maleArr.push(new Date(countMale[i].cMonths).getMonth() + 1);
    }
    const setMaleArr: any = new Set(maleArr);
    const resultMaleArr = [...setMaleArr];

    for (let i = 0; i < countFemale.length; i++) {
      femaleArr.push(new Date(countFemale[i].cMonths).getMonth() + 1);
    }
    const setFemaleArr: any = new Set(femaleArr);
    const resultFemaleArr = [...setFemaleArr];

    months.forEach((m: any) => {
      if (!resultMaleArr.includes(m)) {
        male.push(0);
      } else {
        male.push(filter.male[maleIndex]);
        maleIndex++;
      }

      if (!resultFemaleArr.includes(m)) {
        female.push(0);
      } else {
        female.push(filter.female[femaleIndex]);
        femaleIndex++;
      }
    });
  }
  return { total, male, female };
}
