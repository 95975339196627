import { KEY, LIFECYCLE } from "redux-pack";
import { toast } from "react-toastify";
import createActions from "../stores/controller/createActions";

const middleware = (store: any) => (next: any) => (action: any) => {
  const { meta } = action;
  if (meta && meta.notification) {
    const { successs, error } = meta.notification;
    if (successs && meta[KEY.LIFECYCLE] === LIFECYCLE.SUCCESS) {
    } else if (error && meta[KEY.LIFECYCLE] === LIFECYCLE.FAILURE) {
    }
  }

  if (meta && meta[KEY.LIFECYCLE] === LIFECYCLE.FAILURE) {
    const errData = action.payload.response;
    if (errData) {
      // Result Code로 분리
      switch (errData.data.resultCode) {
        case 4108:
          toast.error(
            `[ ${errData.data.resultCode} ] 타석이 이미 사용되고 있습니다. `
          );
          break;
        case 4109:
          toast.error(
            `[ ${errData.data.resultCode} ] 타석이 예약이 있습니다. `
          );
          break;
        default:
          // 그외 에러코드로 분리
          switch (errData.status) {
            case 406:
              if (errData.data.message === "invalid ticket type") {
                toast.error(
                  "⛔️ 사용자가 예약할 수 없는 티켓을 가지고 있습니다. ( 406 )"
                );
              } else if (errData.data.message === "unavailable ticket") {
                toast.error(
                  "⛔️ 사용된 티켓이거나, 사용할 수 없는 티켓입니다. ( 406 )"
                );
              }
              break;
            default:
              break;
          }
          break;
      }
    }
  }

  // POST,PUT 이후 다시 데이터를 가져와야할 경우 사용함!
  if (meta && meta.read && meta[KEY.LIFECYCLE] === LIFECYCLE.SUCCESS) {
    const { read } = createActions(meta.resourceName);

    store.dispatch(
      read({
        url: meta.read,
      })
    );
  }

  // POST,PUT 이후 다시 데이터를 가져와야할 경우 사용함!
  if (meta && meta.readDetail && meta[KEY.LIFECYCLE] === LIFECYCLE.SUCCESS) {
    const { readDetail } = createActions(meta.resourceName);

    store.dispatch(
      readDetail({
        url: meta.readDetail,
      })
    );
  }

  return next(action);
};

export default middleware;
