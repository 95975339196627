import styled from 'styled-components';
import * as color from '../../../color/index';

export const Progressbar = styled.progress`
  align-items: center;
  display: flex;
  border: 0;

  :after {
    font-size: 12px;
    font-weight: bold;
    color: ${color.Colors.primary};
    margin-left: 50px;
    content: attr(value) '%';
  }

  &::-webkit-progress-bar {
    width: 200px;
    border: 1px solid ${color.Colors.line};
    border-radius: 10px;
    background-color: #f3f3f3;
  }

  &::-webkit-progress-value {
    background-color: ${color.Colors.green};
    border-radius: 10px;
  }
`;
