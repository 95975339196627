import React, { useState, useEffect } from 'react';
import { HeaderBottom, StatusTool, GOLayout, SmallTitle, GOButton } from '../../../components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import ReservationCountTable from '../../../components/employee/ReservationCountTable';
import { NetEmployeeUserManage } from '../../../actions';
import * as Styled from './styles';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import ReactPaginate from 'react-paginate';

interface LocationType {
  data: any;
  employeeName: string;
  employeeID: number | any;
}

const UserManage = () => {
  const { t } = useTranslation();
  const location = useLocation<LocationType>();
  const dispatch = useDispatch();

  const { data, employeeID, employeeName } = location.state;
  const [userData, setUserData] = useState<any>([]);

  const [filteredData, setFilteredData] = useState<any>([]);

  const [excelData, setExcelData] = useState<any>([]);

  const [pagedData, setPagedData] = useState<any>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const pageSize = 50;

  const [filter, setFilter] = useState<any>({
    total: false,
    active: false,
    expired: false,
  });

  const [ticketInfo, setTicketInfo] = useState<any>({
    totalCount: 0,
    activeCount: 0,
    expiredCount: 0,
  });

  const formatStatus = (status: number) => {
    if (status === 0) {
      return '유효';
    } else {
      return '만료';
    }
  };

  const filterData = (filter: any) => {
    const newFilterData = userData.filter((item: any) => {
      //0: active, 1: expired
      if (filter.total) {
        return true;
      }
      if (filter.active && item.status !== undefined && item.status === 0) {
        return true;
      }
      if (filter.expired && item.status !== undefined && item.status === 1) {
        return true;
      }
      return !filter.total && !filter.active && !filter.expired;
    });

    const excelData = newFilterData.map((item: any) => {
      try {
        return {
          name: item.userName,
          phoneNumber: item.phoneNumber,
          startDate: dayjs(item.startDate)
            .add(9, 'hour')
            .format('YYYY-MM-DD'),
          endDate: dayjs(item.endDate)
            .add(9, 'hour')
            .format('YYYY-MM-DD'),
          ticketName: item.ticketName,
          status: formatStatus(item.status),
          totalCount: item.totalCount,
          usedCount: item.usedCount,
          remainingCount: item.remainingCount,
          lessonPro: employeeName,
        };
      } catch (e) {
        return {
          name: '',
          phoneNumber: '',
          startDate: '',
          endDate: '',
          ticketName: '',
          status: '',
          totalCount: '',
          usedCount: '',
          remainingCount: '',
          lessonPro: '',
        };
      }
    });

    setExcelData(excelData);

    setFilteredData(newFilterData);

    setPagedData(newFilterData.slice(0, pageSize));
    setPageCount(newFilterData.length / pageSize);
    setCurrentPage(0);
  };

  const onPageChange = (data: any) => {
    // setCurrentPageIndex(data.selected);
    setCurrentPage(data.selected);
    setPagedData(filteredData.slice(data.selected * pageSize, data.selected * pageSize + pageSize));
  };

  const onFilterChanged = (e: any) => {
    const name = e.target.name;
    const isChecked = e.target.checked;
    let newFilter = { ...filter };
    if (name === 'total') {
      if (isChecked) {
        newFilter = {
          total: true,
          active: false,
          expired: false,
        };
      } else {
        newFilter = {
          total: false,
          active: false,
          expired: false,
        };
      }
    } else if (name === 'active' || name === 'expired') {
      if (isChecked) {
        newFilter = {
          ...filter,
          total: false,
          [name]: isChecked,
        };
      } else {
        newFilter = {
          ...filter,
          [name]: isChecked,
        };
      }
    }
    filterData(newFilter);
    setFilter({
      ...newFilter,
    });
  };

  useEffect(() => {
    NetEmployeeUserManage(employeeID)
      .then((res: any) => {
        // console.log(res.data.Data.users);
        let totalCount = 0;
        let activeCount = 0;
        let expiredCount = 0;

        res.data.Data.users.filter((item: any) => {
          totalCount = totalCount + 1;
          if (item.status === 0) {
            activeCount = activeCount + 1;
          } else if (item.status === 1) {
            expiredCount = expiredCount + 1;
          }
          return true;
        });

        setFilteredData(res.data.Data.users);

        setPagedData(res.data.Data.users.slice(0, pageSize));
        setPageCount(res.data.Data.users.length / pageSize);
        setCurrentPage(0);

        setUserData(res.data.Data.users);
        setFilter({
          total: false,
          expired: false,
          active: false,
        });

        setTicketInfo({
          totalCount: totalCount,
          activeCount: activeCount,
          expiredCount: expiredCount,
        });
      })
      .catch((err: any) => console.error(err));
  }, [employeeID, dispatch]);

  return (
    <>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool link="/admin/employee/list" type="prev" body={t('employee.list')} />
            <StatusTool type="arrow" />
            <StatusTool
              data={data}
              link={`/admin/employee/${employeeID}`}
              type="prev"
              body={employeeName}
            />
            <StatusTool type="arrow" />
            <StatusTool type="now" body="회원 관리" />
          </>
        }
      />
      <GOLayout
        body={
          <>
            <Styled.Layout>
              <Styled.FilterBox>
                <SmallTitle
                  body={`레슨 이용권 정보 [ 총: ${ticketInfo.totalCount ||
                    '없음'} , 유효 이용권수 ${ticketInfo.activeCount ||
                    '없음'},  만료 이용권수 ${ticketInfo.expiredCount || '없음'} ]`}
                />
                <Styled.Checkbox>
                  <div className="inputWrapper">
                    <input
                      type="checkbox"
                      name="total"
                      onChange={onFilterChanged}
                      checked={filter?.total}
                    />
                    <label className="checkLabel">전체</label>
                  </div>
                  <div className="inputWrapper">
                    <input
                      type="checkbox"
                      name="active"
                      onChange={onFilterChanged}
                      checked={filter?.active}
                    />
                    <label className="checkLabel">유효 </label>
                  </div>
                  <div className="inputWrapper">
                    <input
                      type="checkbox"
                      name="expired"
                      onChange={onFilterChanged}
                      checked={filter?.expired}
                    />
                    <label className="checkLabel">만료 </label>
                  </div>
                  <div>
                    {!(filter?.active || filter?.expired || filter?.total) ? (
                      <> </>
                    ) : (
                      <CSVLink
                        headers={[
                          { label: '이름', key: 'name' },
                          { label: '전화번호', key: 'phoneNumber' },
                          { label: '시작날짜', key: 'startDate' },
                          { label: '종료날짜', key: 'endDate' },
                          { label: '상품명', key: 'ticketName' },
                          { label: '상태', key: 'status' },
                          { label: '전체', key: 'totalCount' },
                          { label: '사용', key: 'usedCount' },
                          { label: '잔여', key: 'remainingCount' },
                          { label: '프로명', key: 'lessonPro' },
                        ]}
                        // data={[{name:"김미지", phoneNumber:"010-2207-3084", reservationDate:"2021-08-31", ticketName:"왕초보 머리올리기 - 개인레슨", status: "출석", lessonPro: "이동훈"}]}
                        data={excelData}
                        filename={`${employeeName} 예약 회원 리스트.csv`}
                        target="_blank"
                      >
                        <GOButton body="엑셀로 다운로드" onClick={() => {}} noMarginLeft />
                      </CSVLink>
                    )}
                  </div>
                </Styled.Checkbox>
              </Styled.FilterBox>
              {/*<ReservationCountTable totalData={userData} total />*/}
              {/*<ReservationCountTable totalData={filteredData} total />*/}
              <ReservationCountTable
                totalData={pagedData}
                total
                totalCount={filteredData.length}
                startPageNumber={pageSize * currentPage}
              />

              <ReactPaginate
                pageRangeDisplayed={10}
                marginPagesDisplayed={5}
                previousLabel={'← 이전'}
                nextLabel={'다음 →'}
                pageCount={pageCount}
                onPageChange={onPageChange}
                containerClassName={'pagination'}
                previousLinkClassName={'pagination__link'}
                nextLinkClassName={'pagination__link'}
                disabledClassName={'pagination__link--disabled'}
                activeClassName={'pagination__link--active'}
              />
            </Styled.Layout>
          </>
        }
      />
    </>
  );
};

export default UserManage;
