import React, { useEffect, useState } from 'react';
import * as Styled from '../chartAreaStyles';
import { GOLoading } from '../..';
import GenderChart from '../Chart/MonthlyUserConfiguration/GenderMix';
import MonthlyUserSex from '../Convert/MonthlyUserChart/sex';
// import MonthlyUserAge from '../Convert/MonthlyUserChart/age';
import AgeLine from '../Chart/MonthlyUserConfiguration/AgeLine';
// import UserSex from '../Convert/UserChart/sex';

function MonthlyUserAgeV1(data: any) {
  if (data) {
    console.log('data  not empty');
    return {
      age10s: data.age10s,
      age20s: data.age20s,
      age30s: data.age30s,
      age40s: data.age40s,
      age50s: data.age50s,
      age60s: data.age60s,
      ageTotal: data.ageTotal,
    };
  } else {
    console.log('empty data');
    return {
      age10s: [],
      age20s: [],
      age30s: [],
      age40s: [],
      age50s: [],
      age60s: [],
      ageTotal: [],
    };
  }
}
const MonthlyUserChart = ({ loading, sex, age, year }: any) => {
  const [genderData, setGenderData] = useState<any>({
    labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    datasets: [
      {
        type: 'line',
        label: '전체',
        data: [],
        // yAxisID: '전체',
        backgroundColor: '#606060',
        borderColor: '#606060',
        borderDash: [5, 3],
        pointBorderWidth: 7,
        fill: false,
      },
      {
        type: 'bar',
        label: '남성',
        data: [],
        backgroundColor: '#6AA8E2',
        borderColor: '#6AA8E2',
        borderWidth: 1,
      },
      {
        type: 'bar',
        label: '여성',
        data: [],
        backgroundColor: '#F5C4DE',
        borderColor: '#F5C4DE',
        pointBorderWidth: 7,
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    const sData = MonthlyUserSex(sex);
    const genderData = {
      labels: [
        '1월',
        '2월',
        '3월',
        '4월',
        '5월',
        '6월',
        '7월',
        '8월',
        '9월',
        '10월',
        '11월',
        '12월',
      ],
      datasets: [
        {
          type: 'line',
          label: '전체',
          data: sData.total,
          // yAxisID: '전체',
          backgroundColor: '#606060',
          borderColor: '#606060',
          borderDash: [5, 3],
          pointBorderWidth: 7,
          fill: false,
        },
        {
          type: 'bar',
          label: '남성',
          data: sData.male,
          backgroundColor: '#6AA8E2',
          borderColor: '#6AA8E2',
          borderWidth: 1,
        },
        {
          type: 'bar',
          label: '여성',
          data: sData.female,
          backgroundColor: '#F5C4DE',
          borderColor: '#F5C4DE',
          pointBorderWidth: 7,
          borderWidth: 1,
        },
      ],
    };
    setGenderData(genderData);
  }, [sex]);

  const [ageData, setAgeData] = useState<any>({
    labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    datasets: [
      {
        type: 'line',
        label: '전체',
        backgroundColor: '#606060',
        borderColor: '#606060',
        pointBorderWidth: 5,
        data: [],
        fill: false,
        borderDash: [5, 3],
      },
      {
        type: 'line',
        label: '10대 미만',
        backgroundColor: '#12B886',
        borderColor: '#12B886',
        pointBorderWidth: 5,
        fill: false,
        data: [],
      },
      {
        type: 'line',
        label: '20대 미만',
        backgroundColor: '#E67BFF',
        borderColor: '#E67BFF',
        pointBorderWidth: 5,
        fill: false,
        data: [],
      },
      {
        type: 'line',
        label: '30대',
        backgroundColor: '#A000D0',
        borderColor: '#A000D0',
        pointBorderWidth: 5,
        fill: false,
        data: [],
      },
      {
        type: 'line',
        label: '40대',
        backgroundColor: '#0055DD',
        borderColor: '#0055DD',
        pointBorderWidth: 5,
        fill: false,
        data: [],
      },
      {
        type: 'line',
        label: '50대',
        backgroundColor: '#359BB7',
        borderColor: '#359BB7',
        pointBorderWidth: 5,
        fill: false,
        data: [],
      },
      {
        type: 'line',
        label: '60대 이상',
        backgroundColor: '#38557E',
        borderColor: '#38557E',
        pointBorderWidth: 5,
        fill: false,
        data: [],
      },
    ],
  });

  useEffect(() => {
    const aData = MonthlyUserAgeV1(age);
    const ageData = {
      labels: [
        '1월',
        '2월',
        '3월',
        '4월',
        '5월',
        '6월',
        '7월',
        '8월',
        '9월',
        '10월',
        '11월',
        '12월',
      ],
      datasets: [
        {
          type: 'line',
          label: '전체',
          backgroundColor: '#606060',
          borderColor: '#606060',
          pointBorderWidth: 5,
          data: aData.ageTotal,
          fill: false,
          borderDash: [5, 3],
        },
        {
          type: 'line',
          label: '10대 미만',
          backgroundColor: '#12B886',
          borderColor: '#12B886',
          pointBorderWidth: 5,
          fill: false,
          data: aData.age10s,
        },
        {
          type: 'line',
          label: '20대 미만',
          backgroundColor: '#E67BFF',
          borderColor: '#E67BFF',
          pointBorderWidth: 5,
          fill: false,
          data: aData.age20s,
        },
        {
          type: 'line',
          label: '30대',
          backgroundColor: '#A000D0',
          borderColor: '#A000D0',
          pointBorderWidth: 5,
          fill: false,
          data: aData.age30s,
        },
        {
          type: 'line',
          label: '40대',
          backgroundColor: '#0055DD',
          borderColor: '#0055DD',
          pointBorderWidth: 5,
          fill: false,
          data: aData.age40s,
        },
        {
          type: 'line',
          label: '50대',
          backgroundColor: '#359BB7',
          borderColor: '#359BB7',
          pointBorderWidth: 5,
          fill: false,
          data: aData.age50s,
        },
        {
          type: 'line',
          label: '60대 이상',
          backgroundColor: '#38557E',
          borderColor: '#38557E',
          pointBorderWidth: 5,
          fill: false,
          data: aData.age60s,
        },
      ],
    };
    setAgeData(ageData);
  }, [age]);
  return (
    <Styled.Container>
      {loading ? (
        <GOLoading />
      ) : (
        sex &&
        age && (
          <>
            {sex.length > 0 ? (
              <GenderChart label={`${year}_성별`} data={genderData} />
            ) : (
              <>아직 데이터가 부족합니다!</>
            )}
            {age.ageTotal.length > 0 ? (
              <AgeLine label={`${year}_연령별`} data={ageData} />
            ) : (
              <>아직 데이터가 부족합니다!</>
            )}
          </>
        )
      )}
    </Styled.Container>
  );
};

export default MonthlyUserChart;
