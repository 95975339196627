import React, { useState, useEffect, FormEvent } from 'react';
import * as Styled from '../settingStyles';
import { GOButton, GOLoading } from '../../../components';
import { toast } from 'react-toastify';
import { NetSettingsEdit } from '../../../actions';
import { settingDataProps } from '../../../Types/settingsType';
import PlateSettings from '../Plate';
import LessonSettings from '../Lesson';
import EtcSettings from '../Etc';

interface settingProps {
  data?: settingDataProps;
  update?: () => void;
}

const View = ({ data, update }: settingProps) => {
  const [settingData, setSettingData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [save, setSave] = useState<boolean>(true);
  const [edit, setEdit] = useState<boolean>(true);
  const [timeAlert, setTimeAlert] = useState<boolean>(false);

  const handleSubmit = (e: FormEvent<EventTarget>) => {
    e.preventDefault();

    let data = {
      ...settingData,
      plateScheduleDaysToOpen: parseInt(settingData.plateScheduleDaysToOpen) - 1,
      lessonScheduleDaysToOpen: parseInt(settingData.lessonScheduleDaysToOpen) - 1,
    };

    // validation
    if (settingData.plateBookingOpenTime > settingData.plateBookingCloseTime) {
      alert('오픈시간이 종료시간보다 클 수 없습니다!');
      setTimeAlert(true);
    } else if (parseInt(data.maxPlateReservationCount) > 20) {
      alert('타석 최대 예약 개수가 20개를 넘어갈 수 없습니다!');
      setSettingData({ ...settingData, maxPlateReservationCount: '20' });
    } else if (parseInt(data.plateScheduleDaysToOpen) >= 30) {
      alert('타석 예약 가능 일자가 30일이 넘어갈 수 없습니다!');
      setSettingData({ ...settingData, plateScheduleDaysToOpen: '30' });
    } else if (parseInt(data.maxLessonReservationCount) > 20) {
      alert('레슨 최대 예약 개수가 20개를 넘어갈 수 없습니다!');
      setSettingData({ ...settingData, maxLessonReservationCount: '20' });
    } else if (parseInt(data.lessonScheduleDaysToOpen) >= 30) {
      alert('레슨 예약 가능 일자가 30일이 넘어갈 수 없습니다!');
      setSettingData({ ...settingData, lessonScheduleDaysToOpen: '30' });
    } else if (parseInt(data.lessonClassDuration) > 60) {
      alert('레슨 스케줄 생성시간이 60분을 넘어갈 수 없습니다!');
      setSettingData({ ...settingData, lessonClassDuration: '60' });
    } else if (parseInt(data.maxPauseCount) > 10) {
      alert('이용권 일시 정지 횟수가 10번을 넘어갈 수 없습니다!');
      setSettingData({ ...settingData, maxPauseCount: '10' });
    } else if (parseInt(data.checkinTime) > 3) {
      alert('체크인 가능시간이 3시간보다 클 수 없습니다!');
      setSettingData({ ...settingData, checkinTime: '3' });
    } else {
      // POST 통신
      NetSettingsEdit(data)
        .then(() => {
          alert('설정 내용이 수정되었습니다.');
          window.localStorage.setItem('noticeSmsStatus', data?.noticeSmsStatus);
          setEdit(true);
          setSave(true);
          setTimeAlert(false);
          if (update) {
            update();
          }
        })
        .catch((err: any) => {
          if (err.response) {
            toast.error(err.response.data?.message);
          } else toast.error(err.message);
        });
    }
  };

  useEffect(() => {
    window.localStorage.setItem('noticeSmsStatus', data?.noticeSmsStatus);
    if (data) {
      let sData = {
        ...data,
        plateScheduleDaysToOpen: data?.plateScheduleDaysToOpen + 1,
        lessonScheduleDaysToOpen: data?.lessonScheduleDaysToOpen + 1,
      };
      setSettingData(sData);
      setLoading(true);
    } else setLoading(false);
  }, [data]);

  return (
    <>
      {loading ? (
        <Styled.Container>
          <form onSubmit={handleSubmit}>
            <Styled.TitleArea>
              <div className="title">설정</div>
              <GOButton body="수정" size="medium" onClick={() => setEdit(false)} />
              <GOButton
                body="저장"
                type="submit"
                buttontype={save ? 'disabled' : 'green'}
                size="medium"
                disabled={save}
              />
            </Styled.TitleArea>
            {/* 타석 설정 */}
            <PlateSettings
              settingData={settingData}
              setSettingData={setSettingData}
              setSave={setSave}
              edit={edit}
              timeAlert={timeAlert}
            />

            {/* 레슨 설정 */}
            <LessonSettings
              settingData={settingData}
              setSettingData={setSettingData}
              save={save}
              setSave={setSave}
              edit={edit}
            />

            {/* 기타 설정 */}
            <EtcSettings
              settingData={settingData}
              setSettingData={setSettingData}
              save={save}
              setSave={setSave}
              edit={edit}
            />
          </form>
        </Styled.Container>
      ) : (
        <GOLoading />
      )}
    </>
  );
};

export default View;
