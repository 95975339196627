import React from 'react';
import styled from 'styled-components';

const AreaConfiguration = () => {
  return (
    <Container>
      <Text>서비스 준비 중입니다.</Text>
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  padding: 185px 0;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: '#495057';
`;
export default AreaConfiguration;
