import React from 'react';
import * as Styled from './styles';

const SmallTitle = (props: { body?: string; className?: string }) => {
  return (
    <Styled.TitleArea className={props.className}>
      <h5>{props.body}</h5>
    </Styled.TitleArea>
  );
};
export default SmallTitle;
