import React, { useState } from 'react';
import * as Styled from './styles';
import dayjs from 'dayjs';
import { NetReservationCheckIn } from '../../../actions';

interface CheckInProps {
  plateData?: any;
  lessonData?: any;
  plate?: any;
  lesson?: any;
}

function CheckInPanel(props: CheckInProps) {
  const [clicked, setClicked] = useState<boolean>(false);
  const [secondClicked, setSecondClicked] = useState<boolean>(false);

  // 체크인 했을 때 통신 부분
  const onPlateSubmit = (pr: any) => {
    if (!clicked && pr.status === 1) {
      NetReservationCheckIn(pr.id, 0)
        .then((res: any) => {
          setClicked(true);
        })
        .catch((err: any) => console.log(err));
    }
  };

  const onLessonSubmit = (lr: any) => {
    if (!secondClicked && lr.status === 1) {
      NetReservationCheckIn(lr.id, 1)
        .then((res: any) => {
          setSecondClicked(true);
        })
        .catch((err: any) => console.log(err));
    }
  };

  const viewPlateCheckIcon = (pr: any) => {
    if (pr.status === 0) {
      return (
        <>
          <Styled.PlateCheckIcon className="far fa-check-circle" style={{ color: '#12B886' }} />
        </>
      );
    } else if (pr.status === 1) {
      return (
        <>
          {clicked ? (
            <Styled.PlateCheckIcon className="far fa-check-circle" style={{ color: '#12B886' }} />
          ) : (
            <Styled.PlateCheckIcon className="far fa-check-circle" style={{ color: '#dddddd' }} />
          )}
        </>
      );
    } else {
      return (
        <>
          <Styled.PlateCheckIcon className="far fa-check-circle" style={{ color: '#f9f9f9' }} />
        </>
      );
    }
  };

  const viewLessonCheckIcon = (lr: any) => {
    if (lr.status === 0) {
      return (
        <>
          <Styled.PlateCheckIcon className="far fa-check-circle" style={{ color: '#12B886' }} />
        </>
      );
    } else if (lr.status === 1) {
      return (
        <>
          {secondClicked ? (
            <Styled.PlateCheckIcon className="far fa-check-circle" style={{ color: '#12B886' }} />
          ) : (
            <Styled.PlateCheckIcon className="far fa-check-circle" style={{ color: '#dddddd' }} />
          )}
        </>
      );
    } else {
      return (
        <>
          <Styled.PlateCheckIcon className="far fa-check-circle" style={{ color: '#f9f9f9' }} />
        </>
      );
    }
  };

  const viewPlateStatusText = (pr: any) => {
    if (pr.status === 0) {
      return (
        <>
          <Styled.UsePlateName>체크인 완료</Styled.UsePlateName>
        </>
      );
    } else if (pr.status === 1) {
      if (clicked) {
        return (
          <>
            <Styled.UsePlateName>체크인 완료</Styled.UsePlateName>
          </>
        );
      }
      return (
        <>
          <Styled.UsePlateName>체크인 가능</Styled.UsePlateName>
        </>
      );
    } else {
      return (
        <>
          <Styled.UsePlateName>-</Styled.UsePlateName>
        </>
      );
    }
  };

  const viewLessonStatusText = (lr: any) => {
    if (lr.status === 0) {
      return (
        <>
          <Styled.UsePlateName>체크인 완료</Styled.UsePlateName>
        </>
      );
    } else if (lr.status === 1) {
      if (secondClicked) {
        return (
          <>
            <Styled.UsePlateName>체크인 완료</Styled.UsePlateName>
          </>
        );
      }
      return (
        <>
          <Styled.UsePlateName>체크인 가능</Styled.UsePlateName>
        </>
      );
    } else if (lr.status === 1 && secondClicked) {
      return (
        <>
          <Styled.UsePlateName>체크인 완료</Styled.UsePlateName>
        </>
      );
    } else {
      return (
        <>
          <Styled.UsePlateName>-</Styled.UsePlateName>
        </>
      );
    }
  };

  const pData = props.plateData;
  const lData = props.lessonData;
  return (
    <>
      {props.plate ? (
        <>
          <Styled.PanelWrapper
            key={pData.id}
            onClick={() => {
              onPlateSubmit(pData);
            }}
          >
            <Styled.PlateBox>
              <Styled.PlateCheckWrapper key={pData.id}>
                {viewPlateCheckIcon(pData)}
              </Styled.PlateCheckWrapper>
              <div>
                <Styled.PlateTitle>타석이용</Styled.PlateTitle>
                <Styled.PlateTicketName>{pData.ticketName.substring(0, 18)}</Styled.PlateTicketName>
              </div>
            </Styled.PlateBox>
            <Styled.PlateTimeBox>
              <div className="time_group">
                <div className="label">시작시간</div>
                <div className="time">{dayjs(pData.startDate).format('HH:mm')}</div>
              </div>
              <div className="time_group">
                <div className="label">종료시간</div>
                <div className="time">{dayjs(pData.endDate).format('HH:mm')}</div>
              </div>
            </Styled.PlateTimeBox>
            <Styled.UsePlateBox>
              <div className="label">상태</div>
              {viewPlateStatusText(pData)}
            </Styled.UsePlateBox>
          </Styled.PanelWrapper>
        </>
      ) : (
        <>
          <Styled.PanelWrapper
            key={lData.id}
            onClick={() => {
              onLessonSubmit(lData);
            }}
          >
            <Styled.PlateBox>
              <Styled.PlateCheckWrapper key={lData.id}>
                {viewLessonCheckIcon(lData)}
              </Styled.PlateCheckWrapper>
              <div>
                <Styled.PlateTitle>개인레슨</Styled.PlateTitle>
                <Styled.PlateTicketName>{lData.ticketName}</Styled.PlateTicketName>
              </div>
            </Styled.PlateBox>
            <Styled.PlateTimeBox>
              <div className="time_group">
                <div className="label">시작시간</div>
                <div className="time">{dayjs(lData?.startDate).format('HH:mm')}</div>
              </div>
              <div className="time_group">
                <div className="label">종료시간</div>
                <div className="time">{dayjs(lData?.endDate).format('HH:mm')}</div>
              </div>
            </Styled.PlateTimeBox>
            <Styled.UsePlateBox>
              <div className="label">상태</div>
              {viewLessonStatusText(lData)}
            </Styled.UsePlateBox>
          </Styled.PanelWrapper>
        </>
      )}
    </>
  );
}

export default CheckInPanel;
