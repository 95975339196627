import styled from 'styled-components';
import * as color from '../../../../color/index';
import { darken } from 'polished';

export const LockerPanel = styled.div`
  width: 140px;
  padding: 12px;
  background: $white-color;
  border: 1px solid $line-color;
  display: inline-block;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  transition: 0.35s;
  margin: 0 8px 16px;
  flex-grow: 1;

  &:hover {
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.16);
    transition: 0.35s;
    background: ${darken(0.02, `${color.Colors.white}`)};
  }

  button {
    width: 100%;
  }
`;

export const Number = styled.div`
  font-size: 32px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .span {
    font-size: 14px;
    font-weight: regular;
  }
`;

export const PanelNoUse = styled.div`
  background: ${color.Colors.white};
  border: 1px solid ${color.Colors.line};
  box-shadow: 0 3px 6px rgba(18, 184, 134, 0.3);

  &:hover {
    background: ${color.Colors.white};
  }
`;

export const PanelUse = styled.div`
  background: ${color.Colors.green};
  color: ${color.Colors.white};
  border: 1px solid ${color.Colors.green};
  box-shadow: 0 3px 6px rgba(18, 184, 134, 0.3);

  &:hover {
    background: ${color.Colors.green};
    color: white;
  }
`;
