import styled, { keyframes, css } from 'styled-components';
import { ContentBodyPadding, BreakPoints } from '../../../GlobalUtilityStyles';
// import { Colors } from '../../../color/index';

interface GOLayoutProps {
  shortTopPadding?: boolean;
  flexGrow?: boolean;
}

export const golayout = keyframes`
    0% {
    opacity: 0;
    left: -20px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
`;

export const GOLayoutContainer = styled.div<GOLayoutProps>`
  display: block;
  flex: 1 1 auto;
  margin: 0 auto;
  max-width: 1900px;
  width: 100%;
  margin: 0 auto;
  padding: ${ContentBodyPadding.xl};
  ${BreakPoints.lg} {
    padding: ${ContentBodyPadding.lg};
  }

  ${BreakPoints.md} {
    padding: ${ContentBodyPadding.md};
  }
  ${BreakPoints.sm} {
    padding: ${ContentBodyPadding.sm};
  }

  ${props =>
    props.shortTopPadding &&
    css`
      padding-top: 10px !important;
    `};

  ${props =>
    props.flexGrow &&
    css`
      display: flex;
      flex-grow: 1;
    `};
`;

/* 카드형 박스 */
export const LayoutBox = styled.div`
  width: calc(25% - 16px);
  flex-grow: 1;
  margin: 0 8px 16px;
  max-width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  animation: ${golayout} 0.35s;

      hr {
        display: block;
        width: 100%;
        height: 1px;
      }
    }
  
    ${BreakPoints.lg} {
        width: calc(50% - 16px);
        min-width: initial;
    }
    ${BreakPoints.sm} {
        width: 100%;
        margin: 0 0 16px;

    }


`;
