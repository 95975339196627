import React, { useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { GOFloatingbox, GOButton, GODropbox, GOInput, GOLabel } from '../../../components';
import { actionPostCompletePayment } from '../../../stores/actions';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

const Unpaid = ({ setUnPaid, outData, doUpdate }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [unpaidData, setUnpaidData] = useState({
    transactionID: outData.id,
    paymentMethod: '',
    amount: 0,
  });

  const result = outData.unpaidPrice - unpaidData.amount * 1;

  function comma(str: any) {
    str = String(str);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  function unComma(str: any) {
    str = String(str);
    return str.replace(/[^\d]+/g, '');
  }

  const handleChange = (e: any) => {
    if (e.target.name === 'amount') {
      setUnpaidData({ ...unpaidData, [e.target.name]: unComma(e.target.value) });
    } else setUnpaidData({ ...unpaidData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: FormEvent<EventTarget>) => {
    e.preventDefault();

    if (result > 0) {
      alert('미수금 잔액이 남아있습니다!');
    } else {
      if (window.confirm('미수금을 처리하시겠습니까?')) {
        dispatch(
          actionPostCompletePayment(unpaidData, () => {
            alert('처리되었습니다.');
            setUnPaid();
            doUpdate();
          }),
        );
      }
    }
  };

  return (
    <GOFloatingbox width={340}>
      <UFloatingContainer as="form" onSubmit={handleSubmit}>
        <UFloatingBoxHeader>
          <h3>{t('user.unpaid')}</h3>
          <h5 style={{ fontWeight: 'normal' }}>{t('user.unpaidInfo')}</h5>
        </UFloatingBoxHeader>
        <UFloatingBoxBody>
          <div className="flex_box">
            <GODropbox
              name="paymentMethod"
              label="결제 방식"
              data={[
                { value: 0, body: '카드' },
                { value: 1, body: '현금' },
                { value: 2, body: '이체' },
              ]}
              onChange={handleChange}
              required
            />
            <GOInput
              name="amount"
              label="금액"
              unit={t('common.KRW')}
              onChange={handleChange}
              value={comma(unComma(unpaidData.amount))}
              required
            />
            <hr />
            <span className="red">
              <GOLabel
                label="미수금 잔액"
                body={`${result.toLocaleString('en')} ${t('common.KRW')}`}
              />
            </span>
          </div>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton body={t('common.ok')} type="submit" buttontype="green" />
          <GOButton
            body={t('common.no')}
            onClick={() => {
              setUnPaid();
            }}
          />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};

export default Unpaid;
