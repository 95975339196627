import styled, { keyframes } from 'styled-components';
import SelectDate from 'react-datepicker';
import * as color from '../../../color/index';
import { BreakPoints, HorizontalGlobalPadding } from '../../../GlobalUtilityStyles';

export const Golayout = keyframes`
    0% {
        opacity: 0;
        left: -20px;
    }
    100% {
        opacity: 1;
        left: 0;
    }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const HeaderBox = styled.div`
  padding-top: 20px;
  background: ${color.Colors.white};
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${color.Colors.primary};
`;

export const CalendarBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .calendar {
    display: flex;
  }
`;

export const BtnArrow = styled.div`
  width: 36px;
  height: 36px;
  border: 2px solid ${color.Colors.line};
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CustomDate = styled(SelectDate)`
  height: 18px;
  width: 100px;
  border: 2px solid ${color.Colors.line};
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  word-spacing: -10px;
  color: ${color.Colors.primary};
  padding: 7px;
  padding-left: 15px;
  margin: 0px 8px;
`;

export const Checkbox = styled.div`
  display: flex;

  .inputWrapper {
    display: flex;
    align-items: center;
    color: ${color.Colors.primary};
  }

  .checkLabel {
    margin: 0 10px 0 3px;
  }

  ${BreakPoints.sm} {
    padding: 5px 10px;
    background: #f1f1f1;
    display: flex;
    flex-wrap: wrap;
    border-radius: 3px;
    width: 100%;
    margin-top: 10px;
    white-space: nowrap;
    line-height: 2;
  }
`;

export const BottomBox = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px 20px 20px;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  background: ${color.Colors.white};
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  animation: ${Golayout} 0.35s;
  // height: 750px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  ${BreakPoints.sm} {
    padding: 0 ${HorizontalGlobalPadding.sm} ${HorizontalGlobalPadding.sm};
  }
`;

export const PanelBox = styled.div`
  flex: 1 1 auto;
  height: 480px;
  overflow-y: auto;

  ${BreakPoints.sm} {
    height: auto;
  }
`;

// export const ListWrapper = styled.div`
//   height: 480px;
//   overflow-y: auto;
//   ${BreakPoints.sm} {
//     height: auto;
//   }
// `;

export const NonConfirmPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px 20px 10px 20px;
  font-weight: 700;
  color: ${color.Colors.primary};
  background: ${color.Colors.background};
  justify-content: space-between;

  ${BreakPoints.lg} {
    flex-direction: column;
  }

  ${BreakPoints.md} {
    padding: 10px 15px 10px 15px;
  }
`;

export const InnerBox = styled.div`
  word-spacing: -5px;
  flex: 1 1 auto;
`;

export const InnerWrapper = styled.div`
  display: flex;
`;

export const NTitle = styled.div`
  font-size: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  ${BreakPoints.md} {
    font-size: 18px;
    margin-right: 5px;
  }
  ${BreakPoints.sm} {
    font-size: 16px;
  }
`;

export const NText = styled.div`
  display: flex;
  font-size: 24px;
  ${BreakPoints.md} {
    font-size: 20px;
  }
  ${BreakPoints.sm} {
    font-size: 16px;
  }
`;

export const NInfo = styled.div`
  display: flex;
  font-size: 24px;
  line-height: 1.5;
  word-break: break-word;
  ${BreakPoints.lg} {
    margin-bottom: 10px;
  }

  ${BreakPoints.sm} {
    font-size: 20px;
    margin-top: 5px;
  }
`;

export const NDate = styled.div`
  font-size: 20px;
  color: #989898;
`;

export const Slash = styled.span`
  margin-left: 5px;
  margin-right: 5px;
`;

export const NReservation = styled.div`
  font-size: 23px;
  color: ${color.Colors.primary};
  margin-top: 10px;
`;

export const SecondInnerBox = styled.div`
  display: flex;
  align-items: center;

  button:first-child {
    margin-left: 0;
  }

  ${BreakPoints.xs} {
    button {
      flex: 0 1 auto;
      width: auto;
    }
  }
`;

export const StateText = styled.div`
  position: absolute;
  left: 93%;
`;

export const NoDataText = styled.div`
  font-size: 16px;
  color: #888888;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 250px;
  padding-bottom: 35px;
  ${BreakPoints.sm} {
    padding-bottom: 0;
  }
`;
