import React from 'react';
import { CSVLink } from 'react-csv';
import * as Styled from './styles';
import { GODate, GOButton, GODropbox, GOFilterContainer } from '../../../components';
import { UserManager } from '../../../actions';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const Filter = ({ filter, setFilter, changeFilter, setPurchase, purchase, data }: any) => {
  const { t } = useTranslation();

  const statusFormat = (status: number) => {
    if (status === 0) {
      return '결제 미완료';
    } else if (status === 1) {
      return '결제';
    } else if (status === 2) {
      return '환불';
    } else if (status === 3) {
      return '삭제됨';
    } else {
      return '';
    }
  };

  let excelData = [];
  try {
    excelData =
      data.transactions !== undefined && Array.isArray(data.transactions)
        ? data.transactions.map((t: any) => {
            try {
              return {
                no: t.no,
                purchaseDate: dayjs(t.purchaseDate)
                  //DONETODO: 매출 버그 수정 2022.03.10
                  // .add(9, 'hour')
                  .format('YYYY-MM-DD'),
                username: t.username,
                phoneNumber: t.phoneNumber,
                ticketName: t.ticketName,
                status: statusFormat(t.status),
                originalPrice: t.originalPrice,
                discount: t.discount,
                unpaidPrice: t.unpaidPrice,
                paidAmount: t.paidAmount,
                refund: t.refund,
                employee: t.employee,
                transactionMemo: t.transactionMemo,
              };
            } catch (e) {
              console.log(e);
              return {
                no: '',
                purchaseDate: '',
                username: '',
                phoneNumber: '',
                ticketName: '',
                status: '',
                originalPrice: '',
                discount: '',
                unpaidPrice: '',
                paidAmount: '',
                refund: '',
                employee: '',
                transactionMemo: '',
              };
            }
          })
        : [];
  } catch (e) {
    console.log(e);
  }
  return (
    <GOFilterContainer
      leftbody={
        <>
          <Styled.DateWrapper>
            <h5>{t('accounting.period')}</h5>
            <Styled.DateBox>
              <GODate
                startDate={new Date(filter.startDate)}
                endDate={new Date(filter.endDate)}
                onChange={(data: any) => {
                  setFilter({ ...filter, type: null, startDate: data });
                }}
                selected={new Date(filter.startDate)}
                required
              />
            </Styled.DateBox>
            <span>~</span>
            <Styled.DateBox>
              <GODate
                startDate={new Date(filter.startDate)}
                endDate={new Date(filter.endDate)}
                onChange={(data: any) => {
                  setFilter({ ...filter, type: null, endDate: data });
                }}
                selected={new Date(filter.endDate)}
                required
              />
            </Styled.DateBox>
          </Styled.DateWrapper>
          <Styled.ControlWrapper>
            <GOButton
              body={t('common.yesterday')}
              buttontype={filter.type === -1 ? 'green' : undefined}
              onClick={() => changeFilter(-1)}
            />
            <GOButton
              body={t('common.today')}
              buttontype={filter.type === 0 ? 'green' : undefined}
              onClick={() => changeFilter(0)}
            />
            <GOButton
              body={t('common.this_week')}
              buttontype={filter.type === 1 ? 'green' : undefined}
              onClick={() => changeFilter(1)}
            />
            <GOButton
              body={t('common.this_month')}
              buttontype={filter.type === 2 ? 'green' : undefined}
              onClick={() => changeFilter(2)}
            />
            <GOButton
              body="저번달"
              buttontype={filter.type === 3 ? 'green' : undefined}
              onClick={() => changeFilter(3)}
            />
          </Styled.ControlWrapper>
        </>
      }
      rightbody={
        <Styled.RightFilterWrapper>
          {/* 본사에서 지점별 매출액 확인하는 Dropbox */}
          {UserManager().getUser()?.branch.id === 1 && (
            <Styled.DropboxWrapper>
              <div className="label">지점 선택</div>
              <GODropbox
                data={[
                  { body: '선택하세요', value: -1 },
                  { body: '훈련소 전체', value: 0 },
                  { body: '위례점', value: 2 },
                  { body: '서현점', value: 3 },
                  { body: '의왕점', value: 6 },
                  { body: '평촌점', value: 7 },
                  { body: '동탄삼성점', value: 8 },
                  { body: '잠실점', value: 9 },
                  { body: '일산 덕이점', value: 10 },
                  { body: '광명점', value: 12 },
                  { body: '송도점', value: 13 },
                  { body: '제주점', value: 14 },
                  { body: '원주점', value: 15 },
                  { body: '역삼점', value: 16 },
                  { body: '다산점', value: 17 },
                  { body: '대구혁신도시점', value: 18 },
                  { body: '수원 장안점', value: 19 },
                  { body: '제주 캠퍼스', value: 20 },
                  { body: '부산 해운대', value: 21 },
                  { body: '전주 혁신점', value: 22 },
                  { body: '군포점', value: 23 },
                  { body: '문정점', value: 24 },
                  { body: '부산 주례점', value: 25 },
                  { body: '대구테크노점', value: 26 },
                  { body: '안산점', value: 27 },
                  { body: '죽전점', value: 28 },
                  { body: '인천논현점', value: 29 },
                  { body: '역북점', value: 30 },
                  { body: '오산점', value: 31 },
                  { body: '상대원', value: 32 },
                  { body: '대구성서점', value: 33 },
                  { body: '길동점', value: 34 },
                  { body: '춘천점', value: 35 },
                  { body: '파주교하점', value: 36 },
                  { body: '수원인계점', value: 37 },
                  { body: '의왕부곡점', value: 38 },
                  { body: '배곧서울대점', value: 39 },
                  { body: '인천시청점', value: 40 },
                  { body: '수락노원점', value: 41 },
                  { body: '이천점', value: 42 },
                  // { body: '제주 캠퍼스', value: 20 },
                ]}
                value={filter.branchID}
                onChange={(e: any) => setFilter({ ...filter, branchID: parseInt(e.target.value) })}
                xsmall
                isDefault
              />
            </Styled.DropboxWrapper>
          )}
          <Styled.DropboxWrapper>
            <div className="label">성별</div>
            <GODropbox
              data={[
                { body: '전체', value: 2 },
                { body: '남자', value: 0 },
                { body: '여자', value: 1 },
              ]}
              value={filter.sex}
              onChange={(e: any) => setFilter({ ...filter, sex: e.target.value })}
              xsmall
            />
          </Styled.DropboxWrapper>
          <Styled.DropboxWrapper>
            <div className="label">나이대</div>
            <GODropbox
              data={[
                { body: '전체', value: 0 },
                { body: '10대', value: 1 },
                { body: '20대', value: 2 },
                { body: '30대', value: 3 },
                { body: '40대', value: 4 },
                { body: '50대', value: 5 },
                { body: '60대', value: 6 },
                { body: '70대', value: 7 },
              ]}
              value={filter.age}
              onChange={(e: any) => setFilter({ ...filter, age: e.target.value })}
              xsmall
            />
          </Styled.DropboxWrapper>
          <CSVLink
            headers={[
              { label: '번호', key: 'no' },
              { label: '결제일자', key: 'purchaseDate' },
              { label: '이름', key: 'username' },
              { label: '전화번호', key: 'phoneNumber' },
              { label: '결제내역', key: 'ticketName' },
              { label: '상태', key: 'status' },
              { label: '상품금액', key: 'originalPrice' },
              { label: '할인금액', key: 'discount' },
              { label: '미수금', key: 'unpaidPrice' },
              { label: '결재금액', key: 'paidAmount' },
              { label: '환불금액', key: 'refund' },
              { label: '결제담당', key: 'employee' },
              { label: '결제메모', key: 'transactionMemo' },
            ]}
            data={excelData}
            filename={`메출.csv`}
            target="_blank"
          >
            <GOButton body="엑셀로 다운로드" onClick={() => {}} />
          </CSVLink>
        </Styled.RightFilterWrapper>
      }
    >
      <Styled.SegmentBox state={purchase}>
        <div
          className="totalPurchase"
          onClick={() => {
            setPurchase((prevState: boolean) => {
              if (prevState) return false;
            });
            window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
          }}
        >
          총 매출
        </div>
        <div
          className="newPurchase"
          onClick={() => {
            setPurchase((prevState: boolean) => {
              if (!prevState) return true;
            });
            window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
          }}
        >
          신규 매출
        </div>
      </Styled.SegmentBox>
    </GOFilterContainer>
  );
};

export default Filter;
