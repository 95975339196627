import styled, { keyframes, css } from 'styled-components';
import * as color from '../../../color/index';
import { darken } from 'polished';
import { BreakPoints } from '../../../GlobalUtilityStyles';

interface boxProps {
  longHeight?: boolean;
  fullWidth?: boolean;
}

export const golayout = keyframes`
  0% {
    opacity: 0;
    left: -20px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
`;

export const Layout = styled.div``;

export const LayoutWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const FilterBox = styled.div<boxProps>`
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  padding: 16px;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  background: ${color.Colors.white};
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  animation: golayout 0.35s;
  //min-height: 300px;
  //max-height: 500px;
  overflow: auto;
  animation: ${golayout} 0.35s;

  ${props => {
    if (props.longHeight)
      return css`
        height: 600px;
      `;
  }}

  ${props => {
    if (props.fullWidth)
      return css`
        width: 100%;
        max-height: 600px;
        overflow: auto;
      `;
  }}

  ${BreakPoints.sm} {
    margin-bottom: 15px;
  }
`;

export const Box = styled.div<boxProps>`
  position: relative;
  width: 448px;
  margin-right: 8px;
  padding: 16px;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  background: ${color.Colors.white};
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  animation: golayout 0.35s;
  min-height: 300px;
  max-height: 500px;
  overflow: auto;
  animation: ${golayout} 0.35s;

  ${props => {
    if (props.longHeight)
      return css`
        height: 600px;
      `;
  }}

  ${props => {
    if (props.fullWidth)
      return css`
        width: 100%;
        max-height: 600px;
        overflow: auto;
      `;
  }}
`;

export const UserDetailProfile = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: space-between;
`;

export const ResetButton = styled.button`
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  margin-left: 50px;
  color: ${color.Colors.primary};
  font-size: 12px;
  font-weight: bold;
  background: ${color.Colors.background};
  padding: 5px 7px;
  cursor: pointer;
  transition: 0.35s;
  outline: none;

  &:hover {
    background: ${darken(0.05, `${color.Colors.background}`)};
    transition: 0.35s;
  }
`;

export const Text = styled.div`
  color: ${color.Colors.primary};
  h5 {
    color: ${color.Colors.primary};
  }
  h4 {
    line-height: 28px;
  }
  h6 {
    margin-bottom: 4px;
  }
`;

export const ConfirmLessonPanel = styled.div`
  background: ${color.Colors.background};
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const Info = styled.div`
  width: 50%;
  box-sizing: border-box;
  padding-left: 24px;
  font-weight: bold;
`;

export const Start = styled.div`
  width: 25%;
  font-size: 12px;
  font-weight: bold;
  color: ${color.Colors.primary};
`;

export const End = styled.div`
  width: 25%;
  font-size: 12px;
  font-weight: bold;
  padding-right: 24px;
  color: ${color.Colors.primary};
`;

export const Checkbox = styled.div`
  display: flex;
  flex-wrap: wrap;

  .inputWrapper {
    display: flex;
    align-items: center;
    min-height: 40px;
    color: ${color.Colors.primary};
  }

  .checkLabel {
    margin: 0 10px 0 3px;
  }
`;
