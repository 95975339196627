import styled from 'styled-components';
import * as color from '../../../color/index';

export const ReservationPanel = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
//TODO: 상단  탑 여백을 줄인다.
export const ReservationPanelWrapper = styled.div`
  border: 1px solid ${color.Colors.line};
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  flex: 1;
  height: calc(100% - 6px);
  overflow: hidden;
  margin-left: 6px;
  box-sizing: border-box;
  padding: 0px 5px 0px 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  div {
    overflow: hidden;
    word-break: keep-all;
    font-size: 12px;
    text-overflow: ellipsis;
  }

  .res_title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1 1 auto;
  }
  .res_people {
    margin-left: auto;
    margin-right: 5px;
  }
`;

export const ReservationPlatePanelWrapper = styled.div`
  border: 1px solid ${color.Colors.line};
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  flex: 1;
  height: calc(100% - 6px);
  overflow: hidden;
  margin-left: 6px;
  box-sizing: border-box;
  padding: 0px 5px 0px 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  div {
    overflow: hidden;
    word-break: keep-all;
    font-size: 12px;
    text-overflow: ellipsis;
    flex: 1;
  }

  .res_title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 2px;
    flex: 1 1 auto;
  }
  .res_people {
    margin-left: auto;
    margin-right: 5px;
  }
`;
