import React, { ChangeEvent } from 'react';
import * as Styled from '../settingStyles';
import { GOSwitch } from '../../../components';
import { UserManager } from '../../../actions';

type etcSettingProps = {
  settingData: any;
  setSettingData: any;
  save: boolean;
  setSave: any;
  edit: boolean;
};

const EtcSettings = ({ settingData, setSettingData, save, setSave, edit }: etcSettingProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSave(false);
    console.log(value.slice(0, 2));
    if (parseInt(value) < 1) setSettingData({ ...settingData, [name]: '1' });
    else setSettingData({ ...settingData, [name]: value.slice(0, 2) });
  };

  const handleChangeSelect = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSave(false);

    if (parseInt(value) < 1) setSettingData({ ...settingData, [name]: '1' });
    else setSettingData({ ...settingData, [name]: value.slice(0, 2) });
  };

  return (
    <Styled.BoxWrapper>
      <div className="subTitle">기타 설정</div>
      <div className="box-list">
        <div className="box">
          <div className="inner">
            <div className="label">이용권 최대 일시정지 횟수</div>
            <Styled.Flexbox>
              <Styled.Input
                name="maxPauseCount"
                type="number"
                onChange={handleChange}
                value={settingData?.maxPauseCount || ''}
                disabled={edit}
                required
              />
              <Styled.Unit>번</Styled.Unit>
            </Styled.Flexbox>
          </div>
          <div className="description">
            <div>이용권에 대한 일시정지 횟수를 설정합니다.</div>
            <div>기본 설정은 4번으로 설정되어 있으며 최대 10번까지 설정할 수 있습니다.</div>
          </div>
        </div>
        <div className="box">
          <div className="inner">
            <div className="label">체크인 가능시간</div>
            <Styled.Flexbox>
              <Styled.Input
                name="checkinTime"
                type="number"
                onChange={handleChange}
                value={settingData?.checkinTime || ''}
                disabled={edit}
                required
                style={{ width: '100px', paddingRight: '53px' }}
              />
              <Styled.Unit>시간 전</Styled.Unit>
            </Styled.Flexbox>
          </div>
          <div className="description">
            <div>사용자가 예약한 시간을 기준으로 체크인 할 수 있는 시간을 설정합니다.</div>
            <div>최소 1시간 전, 최대 3시간 전 사이로 설정할 수 있습니다.</div>
            <div>예시) 3시간 전 : 예약한 시간기준 3시간 이전부터 체크인 가능</div>
          </div>
        </div>
        <div className="box">
          <div className="inner">
            <div className="label">문자메시지 설정</div>
            <div>
              <GOSwitch
                style={{ margin: '0' }}
                status={settingData.noticeSmsStatus}
                onClick={() => {
                  if (!edit) {
                    setSettingData({
                      ...settingData,
                      noticeSmsStatus: !settingData.noticeSmsStatus,
                    });
                    setSave(!save);
                  } else {
                    alert('상단 수정 버튼을 눌러주세요.');
                    window.scrollTo(0, 0);
                  }
                }}
              />
            </div>
          </div>
          <div className="description" style={{ marginTop: '0px' }}>
            <div>장문 문자 메시지(LMS) 개인 혹은 그룹 전송 여부를 설정합니다.</div>
            <div>
              회원관리에서 전체 회원 혹은 개별 회원 선택 후 장문 문자 메시지(LMS)를 발송할 수
              있습니다.
            </div>
            <div style={{ color: '#fa5252', textDecoration: 'underline' }}>
              문자 메시지(LMS)의 건당 비용은 30원이고 부가세 별도이며, 익월 로열티 정산에
              포함됩니다.
            </div>
          </div>
        </div>
        <div className="box">
          <div className="inner">
            <div className="label">방역패스 관리 </div>
            <div>
              <GOSwitch
                style={{ margin: '0' }}
                status={settingData.vaccineDataVisibility}
                onClick={() => {
                  if (!edit) {
                    setSettingData({
                      ...settingData,
                      vaccineDataVisibility: !settingData.vaccineDataVisibility,
                    });
                    setSave(!save);
                  } else {
                    alert('상단 수정 버튼을 눌러주세요.');
                    window.scrollTo(0, 0);
                  }
                }}
              />
            </div>
          </div>
          <div className="description" style={{ marginTop: '0px' }}>
            <div>타석 및 레슨 예약 회원의 방역패스 보유 여부를 관리하는 데 사용합니다.</div>
            <div>
              방역패스 관리 기능을 활성화하면 회원 정보 수정 화면에서 해당 회원의 방역패스 보유
              여부를 기록할 수 있게 되고 고객관리-회원 목록 화면에 방역패스 보유 여부가 표시됩니다.
            </div>
          </div>
        </div>
        {(UserManager().getUser()?.branch.id === 1 || UserManager().getUser()?.branch.id === 13) &&
          settingData.multipleSelectionMaxLimit && (
            <div className="box">
              <div className="inner">
                <div className="label">예약 시간 다중 선택</div>
                <div>
                  <Styled.Flexbox>
                    <Styled.SelectBox
                      name="multipleSelectionMaxLimit"
                      onChange={handleChangeSelect}
                      value={settingData.multipleSelectionMaxLimit}
                      disabled={edit}
                    >
                      <option value={1}>1 개</option>
                      <option value={2}>2 개</option>
                      <option value={3}>3 개</option>
                    </Styled.SelectBox>
                  </Styled.Flexbox>
                </div>
              </div>
              <div className="description" style={{ marginTop: '0px' }}>
                <div> 예약 시간을 한번에 최대 3개 까지 다중 선택 하여 예약</div>
                <div>
                  타석 예약에만 적용되며, 회원이 모바일 앱에서 예약 시간을 다중 선택할 수 있도록
                  하는 설정
                </div>
              </div>
            </div>
          )}
      </div>
    </Styled.BoxWrapper>
  );
};

export default EtcSettings;
