import React from "react";
import { SecretPin } from "../../../components";

const EmployeePassword = ({ onClickExit }: any) => {
  return (
    <div>
      <SecretPin path="/admin/employee/list" onClick={onClickExit} />
    </div>
  );
};

export default EmployeePassword;
