import styled, { keyframes } from 'styled-components';
import * as color from '../../../../color/index';
import { darken } from 'polished';
import { BreakPoints } from '../../../../GlobalUtilityStyles';

const reservationani = keyframes`
  0% {
    opacity: 0;
    top: -12px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
`;

export const IVManagerButton = styled.button`
  width: max-content;
  padding: 10px;
  font-weight: bold;
  color: ${color.Colors.primary};
  background: ${color.Colors.white};
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background: ${darken(0.05, `${color.Colors.white}`)};
    transition: 0.3s;
  }

  ${BreakPoints.sm} {
    flex-grow: 1;
  }
`;

export const PlateView = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  animation: ${reservationani} 0.35s;

  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;

  ${BreakPoints.sm} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const PlatePlaceholder = styled.div`
  width: 280px;
  padding: 0;
  height: 1px;
  margin: 0px 8px;
  flex-grow: 1;
`;
