import React, { useState } from 'react';
import { GOInput, GOButton, GOError } from '../../../components';
import { useForm } from 'react-hook-form';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actionPostSignIn } from '../../../stores/actions';
import uid from 'uid';

const SignBox = () => {
  const { register, handleSubmit, errors } = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({
    username: '',
    password: '',
    fcmToken: window.localStorage.getItem('FCMToken'),
    operatingSystem: window.navigator.userAgent.indexOf('Mac') ? '2' : '3',
    identifier: '',
    systemLanguage:
      window.localStorage.getItem('i18nextLng') === 'ko' || 'ko-KR'
        ? '0'
        : window.localStorage.getItem('i18nextLng') === 'en' || 'en-US'
        ? '1'
        : '2',
  });

  let identifier = '';

  const onCreateIdentifier = () => {
    if (window.localStorage.getItem('Identifier')) {
      setData({
        ...data,
        identifier: window.localStorage.getItem('Identifier'),
      });
    } else {
      identifier = uid();
      window.localStorage.setItem('Identifier', identifier);
      setData({
        ...data,
        identifier: identifier,
      });
    }
  };

  const onSubmit = (value: any) => {
    if (value.username === 'dev' && value.password === 'default') {
      // 개발서버 전환 id : devcode | pw : serverchange
      window.localStorage.setItem('SERVER', '');
      document.location.reload(true);
    } else if (value.username === 'dev' && value.password === 'dev') {
      // 개발서버 전환 id : devcode | pw : serverchange
      window.localStorage.setItem('SERVER', 'dev');
      document.location.reload(true);
    } else if (value.username === 'dev' && value.password === 'test') {
      // 개발서버 전환 id : devcode | pw : serverchange
      window.localStorage.setItem('SERVER', 'test');
      document.location.reload(true);
    } else {
      setLoading(true);
      dispatch(
        actionPostSignIn(
          data,
          () => {
            history.push('/admin');
          },
          () => {
            setLoading(false);
          },
        ),
      );
    }
  };

  return (
    <div className="signin">
      <div className="gologo"></div>
      <form onSubmit={handleSubmit(onSubmit)} className="signin_box">
        <GOInput
          // 로그인 계정 12자리에서 24자리로 변경
          rref={register({ maxLength: 24 })}
          label="아이디"
          name="username"
          error={errors.username}
          onChange={(e: any) => setData({ ...data, username: e.target.value })}
          required
        />
        {errors.username && <GOError body={`${t('sign.usernameErrorOnlyEnglish')}`} />}
        <GOInput
          rref={register()}
          label="비밀번호"
          name="password"
          type="password"
          error={errors.password}
          onChange={(e: any) => setData({ ...data, password: e.target.value })}
          required
        />
        <br />
        <GOButton
          type={!loading ? 'submit' : 'button'}
          buttontype="primary"
          width="100%"
          noMarginLeft
          body="로그인"
          onClick={onCreateIdentifier}
          loading={loading}
        />
      </form>
    </div>
  );
};

export default SignBox;
