// 액션!=
import createActions from '../controller/createActions';
import { toast } from 'react-toastify';

const { read, readDetail, onlyCreate, onlyRequest, request } = createActions('user');

export const actionGetUserList = () => read({});

export const actionGetUserAllTickets = () =>
  read({
    url: 'user/allTickets_ver_1',
  });

export const actionPostUser = (data: any, onSuccess?: Function) =>
  onlyRequest({
    type: 'post',
    url: 'user',
    params: data,
    meta: {
      read: 'user',
      onSuccess,
      onFailure: (err: any) => {
        if (err.response) {
          switch (err.response.data.resultCode) {
            case 4114:
              toast.error(
                `⛔️ 임팩트비전에 이미 가입되어 있습니다. 행복골프 본사에 연락이 필요합니다. [${err.response.data.resultCode}]`,
              );
              break;
            case 4115:
              toast.error(`⛔️ 이미 가입된 휴대폰 번호 입니다. [${err.response.data.resultCode}]`);
              break;
            default:
              break;
          }
        }
      },
    },
  });

export const actionGetUserPaymentList = (uid: string, onSuccess?: Function) =>
  onlyRequest({
    type: 'get',
    url: `user/sales/${uid}`,
    meta: { onSuccess },
  });

export const actionGetUserUncheckedList = (uid: string, onSuccess?: Function) =>
  onlyRequest({
    type: 'get',
    // url: `user/uncheckedReservations_ver_1_0/${uid}`,
    url: `user/uncheckedReservations_ver_1_1/${uid}`,
    meta: { onSuccess },
  });

export const actionGetUserDetail = (uid: string, onSuccess?: Function) =>
  readDetail({
    url: `user/${uid}`,
    meta: { onSuccess },
  });

//featue/20211104/changePwd
//백신여부 조회
export const actionGetUserVaccineStatus = (uid: string, onSuccess?: Function) =>
  readDetail({
    url: `user/${uid}/vaccineStatus`,
    meta: { onSuccess },
  });

export const actionPutUserVaccineStatus = (uid: string, data: any, onSuccess?: Function) =>
  onlyRequest({
    type: 'put',
    params: data,
    url: `user/${uid}/vaccineStatusUpdate`,
    meta: {
      onSuccess,
      onFailure: (err: any) => {
        if (err.response.data.resultCode) {
          toast.error(`⛔️ 백신정보 갱신에 실패하였습니다. [${err.response.data.resultCode}]`);
        } else toast.error(`⛔️ 백신정보 갱신에 실패하였습니다. [${err.response.data.statusCode}]`);
      },
    },
  });

// actionGetTrainingManagementGolfPowerRanking,
// actionGetTrainingManagementGolfScoreRanking,
export const actionGetTrainingManagementMenuOpen = (onSuccess?: Function) =>
  readDetail({
    url: `trainingManagement/isMenuOpen`,
    meta: { onSuccess },
  });

export const actionGetTrainingManagementLinkImpactVisionId = (uid: string, onSuccess?: Function) =>
  readDetail({
    url: `trainingManagement/link/impactVisionID/${uid}}`,
    meta: { onSuccess },
  });

export const actionGetTrainingManagementSearchImpactVisionId = (
  uid: string,
  onSuccess?: Function,
) =>
  readDetail({
    url: `trainingManagement/search/impactVisionID/${uid}}`,
    meta: { onSuccess },
  });

export const actionGetTrainingManagementGolfPowerGames = (
  uid: string,
  pageIdx: number,
  onSuccess?: Function,
) =>
  read({
    url: `trainingManagement/golfPowerGame/${uid}?pageIdx=${pageIdx}`,
    meta: { onSuccess },
  });
//feature/2021/1201/gameLog
export const actionGetTrainingManagementGameLog = (
  uid: string,
  queryType: string,
  onSuccess?: Function,
) =>
  read({
    url: `trainingManagement/gameLog/${uid}?queryType=${queryType}`,
    meta: { onSuccess },
  });

export const actionGetTrainingManagementYearlyPlateAttendance = (
  uid: string,
  year: number,
  onSuccess?: Function,
) =>
  readDetail({
    url: `trainingManagement/yearlyPlateAttendance/${uid}?year=${year}`,
    // url: `trainingManagement/yearlyPlateAttendance/${uid}`,
    meta: { onSuccess },
  });

export const actionGetTrainingManagementYearlyLessonAttendance = (
  uid: string,
  year: number,
  onSuccess?: Function,
) =>
  readDetail({
    // url: `trainingManagement/yearlyLessonAttendance/${uid}`,
    url: `trainingManagement/yearlyLessonAttendance/${uid}?year=${year}`,
    meta: { onSuccess },
  });

export const actionGetTrainingManagementGolfPowerRanking = (uid: string, onSuccess?: Function) =>
  readDetail({
    url: `trainingManagement/golfPowerRanking/${uid}`,
    meta: { onSuccess },
  });

export const actionGetTrainingManagementGolfScoreRanking = (uid: string, onSuccess?: Function) =>
  readDetail({
    url: `trainingManagement/golfScoreRanking/${uid}`,
    meta: { onSuccess },
  });

export const actionGetTrainingManagementLessonStat = (uid: string, onSuccess?: Function) =>
  readDetail({
    url: `trainingManagement/lessonStat/${uid}`,
    meta: { onSuccess },
  });

export const actionGetTrainingManagementPlateStat = (uid: string, onSuccess?: Function) =>
  readDetail({
    url: `trainingManagement/plateStat/${uid}`,
    meta: { onSuccess },
  });

export const actionGetTrainingManagementInfo = (uid: string, onSuccess?: Function) =>
  readDetail({
    url: `trainingManagement/info/${uid}`,
    meta: { onSuccess },
  });

export const actionDeleteUserDetail = (uid: string, onSuccess?: Function) =>
  onlyRequest({
    type: 'put',
    url: `user/inactivate/${uid}`,
    meta: {
      onSuccess,
      onFailure: (err: any) => {
        if (err.response.data.resultCode) {
          toast.error(`⛔️ 이미 삭제된 아이디입니다. [${err.response.data.resultCode}]`);
        } else toast.error(`⛔️ 해당 회원이 존재하지 않습니다. [${err.response.data.statusCode}]`);
      },
    },
  });

export const actionPutUserDetail = (uid: string, data: any, onSuccess?: Function) =>
  onlyRequest({
    type: 'put',
    url: `user/${uid}`,
    params: data,
    meta: {
      onSuccess,
      onFailure: (err: any) => {
        if (err) toast.error(`⛔️ 이미 존재하는 휴대폰 번호입니다 ( 404 )`);
      },
    },
  });

export const actionPutUserTicketDetail = (tid: string, data: any, onSuccess?: Function) =>
  onlyRequest({
    type: 'put',
    url: `user/${tid}/ticketEdit_ver_1_0`,
    params: data,
    meta: {
      onSuccess,
    },
  });

//이용권 상세 정보 조회 actionGetUserVaccineStatus
export const actionGetUserTicketDetail = (
  tid: string,
  onSuccess?: Function,
  onFailure?: Function,
) =>
  readDetail({
    url: `user/${tid}/ticketDetail`,
    meta: {
      onSuccess,
      onFailure,
      // onFailure: (err: any) => {
      //   if (err.response.data.resultCode) {
      //     toast.error(`⛔️ 이용권 조회 오류 입니다.  [${err.response.data.resultCode}]`);
      //   } else toast.error(`⛔️ 이용권 조회 오류 입니다.  [${err.response.data.statusCode}]`);
      // },
    },
  });

//extendLessonPeriod
export const actionPutUserTicketExtendLessonPeriod = (
  tid: string,
  onSuccess?: Function,
  onFailure?: Function,
) =>
  onlyRequest({
    type: 'put',
    url: `user/${tid}/extendLessonPeriod`,
    // params: data,
    meta: {
      onSuccess,
      onFailure,
    },
  });

export const actionPostCompletePayment = (data: any, onSuccess?: Function) =>
  onlyRequest({
    type: 'post',
    url: `sales/completePayment`,
    params: data,
    meta: {
      onSuccess,
    },
  });

export const actionPutUserRefundPayment = (tid: string, data: any, onSuccess?: Function) =>
  onlyRequest({
    type: 'put',
    url: `sales/refund_ver_1/${tid}`,
    params: data,
    meta: {
      onSuccess,
    },
  });

export const actionPutUserTicketEdit = (tid: string, data: any, onSuccess?: Function) =>
  onlyRequest({
    type: 'put',
    url: `user/sales/${tid}`,
    params: data,
    meta: {
      onSuccess,
    },
  });

export const actionDeleteUserTicket = (tid: string, onSuccess?: Function) =>
  onlyRequest({
    type: 'delete',
    url: `sales/${tid}`,
    meta: {
      onSuccess,
    },
  });

export const actionResetUserPassword = (uid: string, onSuccess?: Function) =>
  onlyRequest({
    type: 'put',
    url: `user/resetPassword/${uid}`,
    meta: {
      onSuccess,
    },
  });

export const actionPostPushMessage = (data: any, onSuccess?: Function) =>
  onlyCreate({
    url: 'user/message',
    params: data,
    meta: {
      onSuccess,
      onFailure: (err: any) => {
        if (err.response && err.response.data.resultCode === 4113) {
          toast.error('선택한 사용자가 앱을 설치하지 않았습니다.');
        }
      },
    },
  });

export const actionPostSMSMessage = (data: any, onSuccess?: Function) =>
  onlyCreate({
    url: 'user/sms',
    params: data,
    meta: {
      onSuccess,
      onFailure: (err: any) => {
        if (err) toast.error('설정 탭에서 문자보내기를 활성화 시켜주세요.');
      },
    },
  });
