import React, { useContext } from 'react';
import * as Styled from './styles';
import { Context } from '../../../stores';
import Loading from 'react-loading';
import { useTranslation } from 'react-i18next';

const GOSending = () => {
  const Store = useContext(Context);
  const { t } = useTranslation();

  if (Store.sending) {
    return (
      <>
        {Store.sending ? (
          <Styled.Sending>
            <div>
              <Loading type={'bubbles'} color={'white'} width={52} height={52} />
              <div>{t('common.working')}</div>
            </div>
          </Styled.Sending>
        ) : (
          <></>
        )}
      </>
    );
  } else {
    return null;
  }
};

export default GOSending;
