import React from 'react';
import * as Styled from './styles';
import GOAsyncDiv from '../../../common/GOAsyncDiv';
import { PlateLoginPanelPlus } from '../../..';
import { GOLayout } from '../../../../components';
interface ViewProps {
  data: any;
  reloadPlateModalStatus: any;
}

const placeHolder = () => {
  let arr = [];
  for (let i = 0; i < 10; i++) {
    arr.push(<Styled.PlatePlaceholder key={i} />);
  }
  return arr;
};

const View = ({ data, reloadPlateModalStatus }: ViewProps) => {
  return (
    <GOLayout
      body={
        <>
          {/*<Styled.IVManagerLink>*/}
          {/*  <Styled.IVManagerButton*/}
          {/*    onClick={() => {*/}
          {/*      window.open('https://mgr.impactvision.co.kr');*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    IV Manager 타석제어*/}
          {/*  </Styled.IVManagerButton>*/}
          {/*</Styled.IVManagerLink>*/}
          <Styled.PlateView>
            <GOAsyncDiv status={data.status}>
              {data.data &&
                data.data.plateStatus.map((p: any) => (
                  <PlateLoginPanelPlus
                    key={p.id}
                    data={p}
                    reloadPlateModalStatus={reloadPlateModalStatus}
                  />
                ))}
              {placeHolder()}
            </GOAsyncDiv>
          </Styled.PlateView>
        </>
      }
    />
  );
};
export default View;
