import React, { useEffect, useState } from 'react';
import {
  HeaderBottom,
  StatusTool,
  SegmentControl,
  PlateLoginView,
  GOButton,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetPlatModalStatusList, actionGetSettingsList } from '../../../stores/actions';
import { UserManager } from '../../../actions';
import { onModal } from '../../../stores/modals';
import { actionPostPlateModalStatus } from '../../../stores/actions/platelogin';

//jeju campus
//UserManager().getUser()?.branch.id === 1

const PlateLoginList = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const plateLoginModal = useSelector((state: any) => {
    console.log('state -> plateLoginModal', state['plateLoginModal']);
    return state['plateLoginModal'];
  });

  const settings = useSelector((state: any) => {
    console.log('state -> settings', state['settings']);
    return state['settings'];
  });

  const [isLoading, setLoading] = useState<boolean>(false);

  const setAllModalStatus = (isOn: boolean) => {
    setLoading(true);
    dispatch(
      actionPostPlateModalStatus(
        {
          modalState: isOn ? 'y' : 'n',
          all: 'y',
          number: 0,
        },
        (a: any) => {
          console.log(a.data.Data);
          try {
            const result_code = Number(a.data.Data.result_code);
            if (result_code === 0) {
              reloadPlateModalStatus();
            } else {
              // toast.error(' 강제 로그인 설정 중 오류 가 발생였습니다.');
              reloadPlateModalStatus();
            }
          } catch (e) {}
        },
      ),
    );
  };

  //feature/20210825/jejucampus : 제주캠퍼스의 경우는 임팩트 비전의 타석 정보가져오는 부분을 막아둔다.
  const isHiddenPlates =
    UserManager().getUser()?.branch.id === 0 || UserManager().getUser()?.branch.id === 20;

  const reloadPlateModalStatus = () => {
    console.log('useEffect --> on Plate/PlateLoginList/index.tsx');
    setLoading(false);
    dispatch(actionGetSettingsList());
    if (isHiddenPlates) {
      console.log('branch.id === 1 || branch.id === 20');
      return;
    }
    dispatch(actionGetPlatModalStatusList());
  };

  useEffect(() => {
    reloadPlateModalStatus();
  }, [dispatch]);

  return (
    <div>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={t('plate.list')} />
          </>
        }
        rightmenu={
          <>
            {/* 강제 로그인 설정 */}
            <GOButton
              buttontype="green"
              body={'전 타석 로그인  설정'}
              onClick={() => setAllModalStatus(true)}
            />
            <GOButton
              buttontype="gray"
              body={'전 타석 로그인 해제'}
              onClick={() => setAllModalStatus(false)}
            />
          </>
        }
      />
      {/*//제주캠퍼스 혹은 테스트 서버인 경우 타석 정보 보여주지 않는다.*/}
      {settings &&
      settings.data?.settings?.modalSettingShowInSetting === 1 &&
      settings.data?.settings?.modalSettingShowModalYN === 1 ? (
        <SegmentControl
          data={[
            {
              name: t('plate.list'),
              link: '/admin/plate/list',
            },
            {
              name: t('locker.list'),
              link: '/admin/plate/lockerlist',
            },
            {
              name: '타석 로그인',
              link: '/admin/plate/plateloginlist',
            },
          ]}
        />
      ) : (
        <SegmentControl
          data={[
            {
              name: t('plate.list'),
              link: '/admin/plate/list',
            },
            {
              name: t('locker.list'),
              link: '/admin/plate/lockerlist',
            },
          ]}
        />
      )}
      {isHiddenPlates ? (
        <></>
      ) : isLoading ? (
        <></>
      ) : (
        <PlateLoginView data={plateLoginModal} reloadPlateModalStatus={reloadPlateModalStatus} />
      )}
    </div>
  );
};
export default PlateLoginList;
