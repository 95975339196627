import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GOButton, GOFloatingbox } from '../../../components';
import { NetUserTicketHistory } from '../../../actions';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'tui-grid/dist/tui-grid.css';
import 'tui-pagination/dist/tui-pagination.css';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

import TuiGrid from 'tui-grid';
import Grid from '@toast-ui/react-grid';

TuiGrid.setLanguage('ko');
TuiGrid.applyTheme('striped');

interface filterType {
  type: 'text';
  showApplyBtn: boolean;
  showClearBtn: boolean;
}

interface PageOptions {
  useClient?: boolean;
  perPage?: number;
  page?: number;
  totalCount?: number;
  type?: 'scroll' | 'pagination';
  position?: 'top' | 'bottom';
  visiblePages?: number;
  centerAlign?: boolean;
  firstItemClassName?: string;
  lastItemClassName?: string;
  template?: Record<string, string | Function>;
}

interface GridColumnType {
  header: string;
  name: string;
  sortingType?: 'desc' | 'asc' | undefined;
  sortable?: boolean;
  filter?: filterType;
}

const LessonTicketHistory = ({ onClickExit, data }: any) => {
  console.log(data);
  const { t } = useTranslation();

  const [ticketHistory, setTicketHistory] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    NetUserTicketHistory({}, data.id)
      .then((res: any) => {
        if (res) {
          if (res.data?.Data?.history?.length > 0) {
            let history: any[] = [];
            const map = res.data.Data.history.map((o: any) => {
              if (o.isUnlimited !== o.oldIsUnlimited) {
                history.push({
                  type: '무제한 여부',
                  before: o.oldIsUnlimited ? '무제한' : '제한',
                  after: o.isUnlimited ? '무제한' : '제한',
                  issuedAt: dayjs(o.createdAt)
                    // .add(9, 'hour')
                    .format('YYYY-MM-DD hh:mm'),
                });
              }
              if (o.remainingCount !== o.oldRemainingCount) {
                history.push({
                  type: '잔여횟수',
                  before: o.oldRemainingCount,
                  after: o.remainingCount,
                  issuedAt: dayjs(o.createdAt)
                    // .add(9, 'hour')
                    .format('YYYY-MM-DD hh:mm'),
                });
              }
              if (o.startDate !== o.oldStartDate) {
                history.push({
                  type: '시작일',
                  before: dayjs(o.oldStartDate)
                    .add(9, 'hour')
                    .format('YYYY-MM-DD'),
                  after: dayjs(o.startDate)
                    .add(9, 'hour')
                    .format('YYYY-MM-DD'),
                  issuedAt: dayjs(o.createdAt)
                    // .add(9, 'hour')
                    .format('YYYY-MM-DD hh:mm'),
                });
              }
              if (o.endDate !== o.oldEndDate) {
                history.push({
                  type: '종료일',
                  before: dayjs(o.oldEndDate)
                    .add(9, 'hour')
                    .format('YYYY-MM-DD'),
                  after: dayjs(o.endDate)
                    .add(9, 'hour')
                    .format('YYYY-MM-DD'),
                  issuedAt: dayjs(o.createdAt)
                    // .add(9, 'hour')
                    .format('YYYY-MM-DD hh:mm'),
                });
              }
              return {};
            });
            setTicketHistory(history);
          }
        }
        setLoading(false);
      })
      .catch(err => {
        if (err?.response?.data?.message) {
          toast.error(`오류: ${err.response.data.message}`);
        }
        setLoading(false);
      });
  }, [data]);

  const columns: any = [
    { name: 'type', header: '항목', align: 'center', minWidth: 110 },
    { name: 'before', header: '변경 전', align: 'center', minWidth: 110 },
    { name: 'after', header: '변경 후', align: 'center', minWidth: 110 },
    { name: 'issuedAt', header: '수정일시', align: 'center', minWidth: 140 },
  ];

  const pageOption: PageOptions = {
    useClient: true,
    perPage: 7,
  };

  return (
    <GOFloatingbox width={670}>
      <UFloatingContainer>
        <UFloatingBoxHeader textCenter>
          <h1>
            {isLoading ? '(로딩중)' : ''} {data.name || ''}
          </h1>
        </UFloatingBoxHeader>
        <UFloatingBoxBody>
          <div className="flex_box">
            <Grid
              data={ticketHistory}
              columns={columns}
              rowHeight={24}
              bodyHeight={320}
              heightResizable={false}
              rowHeaders={['rowNum']}
              pageOptions={pageOption}
              columnOptions={{ resizable: true }}
              scrollX={true}
            />
          </div>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton body={t('common.no')} onClick={onClickExit} />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};

export default LessonTicketHistory;
