import NetworkManager from "../../../NetworkManager";

const Edit = (data: object, id: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager("put", `admin/employee/${id}`, data)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default Edit;
