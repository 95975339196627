import styled from 'styled-components';
import * as color from '../../../color/index';

export const Container = styled.div`
  width: 400px;
`;

export const UserList = styled.div`
  max-width: 500px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;

  .label {
    color: ${color.Colors.primary};
    margin-right: 32px;
  }

  .userBox {
    display: flex;
    align-items: center;
  }

  .users {
    color: ${color.Colors.primary};
  }

  span {
    font-weight: normal;
    color: ${color.Colors.primary};
    margin-left: 8px;
  }
`;

export const Bottom = styled.div`
  text-align: center;
  margin-top: 40px;
`;
