import styled from 'styled-components';
import * as color from '../../../color/index';
import { darken } from 'polished';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const Profile = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
`;

export const ProfileImage = styled.div`
  width: 120px;
  height: 120px;
  margin: 4px;
  margin-right: 24px;
  background: ${color.Colors.sub};
  border-radius: 120px;

  @media (max-width: 1200px) {
    width: 80px;
    height: 80px;
  }
`;

export const DetailText = styled.div`
  color: ${color.Colors.primary};
  font-weight: bold;

  .name {
    font-size: 24px;
  }
  .birth {
    font-size: 14px;
  }
  .sex {
    font-size: 18px;
  }
`;

export const ResetButton = styled.button`
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  margin-left: 50px;
  color: ${color.Colors.primary};
  font-size: 12px;
  font-weight: bold;
  background: ${color.Colors.background};
  padding: 5px 7px;
  cursor: pointer;
  transition: 0.35s;
  outline: none;

  &:hover {
    background: ${darken(0.05, `${color.Colors.background}`)};
    transition: 0.35s;
  }
`;

export const UncheckedWrapper = styled.div`
  height: 530px;
  overflow: hidden auto;
  flex: 1 1 auto;

  ${BreakPoints.sm} {
    height: auto;
  }
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .title {
    font-weight: bold;
    flex-shrink: 0;
  }
  .checkboxContainer {
    display: flex;
    flex-wrap: wrap;
    color: ${color.Colors.primary};
    font-size: 14px;
    padding-top: 5px;

    .checkboxWrapper {
      display: flex;
      align-items: center;

      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
`;

export const EmptyDataText = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: ${color.Colors.line};
  ${BreakPoints.sm} {
    height: 100px;
  }
`;

export const PageStyles = styled.div`
  ${BreakPoints.xs} {
    .right_menu {
      flex-wrap: wrap;
      button {
        width: 40%;
        margin-left: 5px;
        margin-bottom: 5px;
        &:nth-child(odd) {
          margin-left: 0 !important;
        }
        // &:nth-child(3),
        // &:nth-child(4) {
        //   margin-bottom: 0;
        // }
      }
    }
  }
`;
