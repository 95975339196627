import React, { useEffect, useState } from 'react';
import * as Styled from './styles';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { HeaderBottom, StatusTool, GOLoading } from '../../../components';
import { useTranslation } from 'react-i18next';
import PaymentListView from '../../../components/user/PaymentListView';
import { actionGetUserPaymentList } from '../../../stores/actions';

interface LocationType {
  userID: string;
}

interface ParamsType {
  id: string;
}

const PaymentList = () => {
  const params = useParams<ParamsType>();
  const [userData, setUserData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [update, isUpdate] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const location = useLocation<LocationType>();
  const userID = location.state?.userID;

  const USER_NAME = window.sessionStorage.getItem(`SLID${params.id}`);

  useEffect(() => {
    dispatch(
      actionGetUserPaymentList(params.id, (res: any) => {
        setUserData(res.data.Data);
        setLoading(false);
      }),
    );
  }, [dispatch, params.id, update]);

  return (
    <>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool link="/admin/user/grid" type="prev" body={t('user.list')} />
            <StatusTool type="arrow" />
            <StatusTool type="prev" link={`/admin/user/${params.id}`} body={USER_NAME} />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={t('user.paymentList')} />
          </>
        }
        rightmenu={<></>}
      />
      {loading ? (
        <GOLoading />
      ) : (
        <>
          {userData?.transactions.length > 0 ? (
            <>
              <PaymentListView data={userData} userID={userID} doUpdate={() => isUpdate(!update)} />
            </>
          ) : (
            <>
              <Styled.EmptyMessage>결제 내역이 없습니다.</Styled.EmptyMessage>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PaymentList;
