import produce from "immer";

const initialState = { type: "", data: {} };

export const ModalReducer = (state: any = initialState, action: any) => {
  const { type, payload } = action;

  return produce(state, (draft: any) => {
    switch (type) {
      case "onModal":
        return payload;
      case "offModal":
        return initialState;
      default:
        return draft;
    }
  });
};

export const onModal = (type: string, data: any = {}) => ({
  type: "onModal",
  payload: {
    type,
    data,
  },
});

export const offModal = () => ({
  type: "offModal",
});
