export default function YearlySalesCategory(data: any) {
  let total: number[] = [];
  let newPurchase: number[] = [];
  let rePurchase: number[] = [];
  let refund: number[] = [];

  if (data) {
    const newPurchaseArr = data.filter((d: any) => d.purchaseCode === '신규구매');
    const rePurchaseArr = data.filter((d: any) => d.purchaseCode === '재구매');

    const filter = {
      newPurchase: data
        .filter((d: any) => d.purchaseCode === '신규구매')
        .map((d: any) => (Number(d.paymentAmount) > 0 ? Number(d.paymentAmount) : 0)),
      newRefund: data
        .filter((d: any) => d.purchaseCode === '신규구매')
        .map((d: any) => (Number(d.refundAmount) > 0 ? Number(d.refundAmount) * -1 : 0)),
      rePurchase: data
        .filter((d: any) => d.purchaseCode === '재구매')
        .map((d: any) => (Number(d.paymentAmount) > 0 ? Number(d.paymentAmount) : 0)),
      reRefund: data
        .filter((d: any) => d.purchaseCode === '재구매')
        .map((d: any) => (Number(d.refundAmount) > 0 ? Number(d.refundAmount) * -1 : 0)),
    };

    let newArr: number[] = [];
    let reArr: number[] = [];

    for (let i = 0; i < newPurchaseArr.length; i++) {
      newArr.push(new Date(newPurchaseArr[i].cMonths).getMonth() + 1);
    }
    for (let i = 0; i < rePurchaseArr.length; i++) {
      reArr.push(new Date(rePurchaseArr[i].cMonths).getMonth() + 1);
    }

    const newPurchaseSet: any = new Set(newArr);
    const rePurchaseSet: any = new Set(reArr);

    const uniqueNewPurchaseArr = [...newPurchaseSet];
    const uniqueRePurchaseArr = [...rePurchaseSet];

    let newIndex = 0;
    let reIndex = 0;
    let newRefund: number[] = [];
    let reRefund: number[] = [];

    // 신규구매 배열 만들기
    for (let i = 1; i <= 12; i++) {
      if (!uniqueNewPurchaseArr.includes(i)) {
        newPurchase.push(0);
        newRefund.push(0);
      } else {
        newPurchase.push(filter.newPurchase[newIndex]);
        newRefund.push(filter.newRefund[newIndex]);
        newIndex++;
      }
    }

    // 재구매 배열 만들기
    for (let i = 1; i <= 12; i++) {
      if (!uniqueRePurchaseArr.includes(i)) {
        rePurchase.push(0);
        reRefund.push(0);
      } else {
        rePurchase.push(filter.rePurchase[reIndex]);
        reRefund.push(filter.reRefund[reIndex]);
        reIndex++;
      }
    }

    // 환불 값 합치기
    for (let i = 0; i <= 11; i++) {
      refund.push(newRefund[i] + reRefund[i]);
    }

    // 총매출 배열 만들기
    for (let i = 0; i <= 11; i++) {
      total.push(newPurchase[i] + rePurchase[i] - refund[i]);
    }
  }
  return { total, newPurchase, rePurchase, refund };
}
