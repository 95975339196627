import React from 'react';
import { useTranslation } from 'react-i18next';
import { GOFloatingbox, GOButton, GOInput } from '../../../components';
import { useDispatch } from 'react-redux';
import { actionPostLocker } from '../../../stores/actions';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

interface AddProps {
  onClickExit?: any;
}

const Add = (props: AddProps) => {
  const { t } = useTranslation();
  let data = 0;
  const dispatch = useDispatch();

  const onAdd = () => {
    if (data > 0) {
      if (window.confirm(t('locker.isAdd'))) {
        dispatch(
          actionPostLocker(data, () => {
            props.onClickExit();
          }),
        );
      }
    } else {
      alert(t('locker.isZero'));
    }
  };

  return (
    <GOFloatingbox width={380}>
      <UFloatingContainer>
        <UFloatingBoxHeader>
          <h1>{t('locker.add')}</h1>
          <p>{t('locker.addInfo')}</p>
        </UFloatingBoxHeader>
        <UFloatingBoxBody>
          <div className="flex_box">
            <GOInput
              label={t('locker.addNumber')}
              onChange={(e: any) => {
                data = e.target.value * 1;
              }}
            />
          </div>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton body={t('common.ok')} buttontype="green" onClick={onAdd} />
          <GOButton body={t('common.no')} onClick={props.onClickExit} />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};

export default Add;
