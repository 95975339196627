import styled from 'styled-components';
import * as color from '../../../color/index';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const Panel = styled.div`
  padding: 10px 15px;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  background: ${color.Colors.background};
  font-size: 13px;
  font-weight: bold;
  color: ${color.Colors.primary};
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const LeftInfo = styled.div`
  // display: flex;

  .year {
    color: #000000;
    margin-bottom: 10px;
  }

  .userInfo {
  }
`;

export const TicketItem = styled.div`
  display: flex;
  margin-bottom: 10px;
  > div:not(:last-child) {
    margin-right: 8px;
  }
`;

export const UserItem = styled.div``;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  ${BreakPoints.md} {
  }
`;
