import styled from 'styled-components';

export const FormContainer = styled.div`
  max-width: 240px;
  margin: 0 auto 20px;
`;

export const SubTitle = styled.div`
  font-size: 16px;
  color: #495057;
`;

export const ErrorText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #fa5252;
  margin-top: 5px;
`;

export const NumberText = styled.input`
  width: 100%;
  height: 42px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #12b886;
  background-color: #fff;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  color: #495057;
  letter-spacing: 4px;
  //autocomplet

  &:last-of-type {
    margin-bottom: 0;
  }

  &:disabled {
    background: #fff;
  }
`;
