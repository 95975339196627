import React, { useEffect } from 'react';
import * as Styled from '../../chartAreaStyles';
import Chart from 'chart.js';

interface chartProps {
  data: any;
  label?: string;
}

const GenderChart = ({ data, label }: chartProps) => {
  useEffect(() => {
    let genderChart: any = null;

    if (genderChart) {
      genderChart.destroy();
      genderChart = null;
    }
    const ctx: any = document.getElementById('MonthlyUserGender');
    const datasets = data.datasets[0];
    const secondDatasets = data.datasets[1];
    const thirdDatasets = data.datasets[2];

    genderChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: data.labels,
        datasets: [
          {
            type: datasets.type,
            label: datasets.label,
            data: datasets.data,
            backgroundColor: datasets.backgroundColor,
            borderColor: datasets.borderColor,
            borderDash: datasets.borderDash,
            pointBorderWidth: datasets.pointBorderWidth,
            fill: false,
          },
          {
            type: secondDatasets.type,
            label: secondDatasets.label,
            data: secondDatasets.data,
            backgroundColor: secondDatasets.backgroundColor,
            borderColor: secondDatasets.borderColor,
            borderWidth: secondDatasets.borderWidth,
            datalabels: {
              color: '#fff',
              font: {
                size: 14,
                weight: 'bold',
              },
              formatter: (value: any) => {
                return value + '명';
              },
            },
          },
          {
            type: thirdDatasets.type,
            label: thirdDatasets.label,
            data: thirdDatasets.data,
            backgroundColor: thirdDatasets.backgroundColor,
            borderColor: thirdDatasets.borderColor,
            borderWidth: thirdDatasets.borderWidth,
            pointBorderWidth: thirdDatasets.pointBorderWidth,
            datalabels: {
              color: '#fff',
              font: {
                size: 14,
                weight: 'bold',
              },
              formatter: (value: any) => {
                return value + '명';
              },
            },
          },
        ],
      },
      options: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
          labels: {
            padding: 10,
            boxWidth: 15,
          },
        },
        maintainAspectRatio: false,
        tooltips: {
          displayColors: true,
          callbacks: {
            title: (item: any, data: any) => {
              let index = item[0].datasetIndex;
              return data.datasets[index].label;
            },
            label: (item: any) => {
              return `${item.value}명`;
            },
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: (item: any) => {
                  return `${item}명`;
                },
              },
              type: 'linear',
            },
          ],
        },
      },
    });

    return () => {
      if (genderChart) {
        genderChart.destroy();
      }
    };
  }, [data]);
  return (
    <Styled.ChartBox>
      {label && <Styled.Title>{label}</Styled.Title>}
      <Styled.Chart className="rect_chart_response">
        <canvas id="MonthlyUserGender" />
      </Styled.Chart>
    </Styled.ChartBox>
  );
};

export default GenderChart;
