import React, { useState, useEffect } from 'react';
import { HeaderBottom, StatusTool, SettingItem, GOLayout } from '../../../components';
import { useTranslation } from 'react-i18next';
import { NetSettingsList } from '../../../actions';

const SettingsView = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>();
  const [update, setUpdate] = useState<boolean>(false);

  useEffect(() => {
    NetSettingsList()
      .then((res: any) => {
        setData(res.data.Data.settings);
      })
      .catch((err: any) => console.error(err));
  }, [update]);
  return (
    <div>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={t('main-menu.setting')} />
          </>
        }
      />
      <GOLayout
        body={
          <>
            <SettingItem data={data} update={() => setUpdate(!update)} />
          </>
        }
      />
    </div>
  );
};

export default SettingsView;
