import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GOButton, GOFloatingbox } from '../../../components';
import { NetCheckPassword } from '../../../actions';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

interface SecretPinProps {
  onClick?: any;
  path?: string;
}

const SecretPin = (props: SecretPinProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [num, setNum] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const numArr = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  let result = { pins: num };

  if (num.length === 4) {
    NetCheckPassword(result)
      .then((res: any) => {
        if (res) {
          history.push(`${props.path}`);
        }
      })
      .catch(err => {
        if (err) {
          setError(true);
          setNum('');
        }
      });
  }

  return (
    <GOFloatingbox width={450}>
      <UFloatingContainer>
        <UFloatingBoxHeader textCenter>
          <h1>보안핀(PIN) 확인</h1>
        </UFloatingBoxHeader>
        <UFloatingBoxBody>
          <Styled.Number>
            <Styled.NumberText
              type="password"
              value={num?.slice(0, 4)}
              style={{
                borderBottom: error ? '2px solid #fa5252' : '2px solid #12b886',
                transition: error ? '0.35s' : '0.35s',
              }}
              readOnly
            />
            {error ? (
              <>
                <Styled.ErrorText>* 보안핀이 일치하지 않습니다.</Styled.ErrorText>
              </>
            ) : (
              <>
                <Styled.ErrorText></Styled.ErrorText>
              </>
            )}
            <Styled.NumberPad>
              {numArr.map((n: any, index: number) => (
                <Styled.Pad
                  key={index}
                  onClick={() => {
                    setNum(num + n);
                  }}
                >
                  {n}
                </Styled.Pad>
              ))}
              <Styled.Pad
                onClick={() => {
                  setNum(num.slice(0, -1));
                  setError(false);
                }}
              >
                ←
              </Styled.Pad>
              <Styled.Pad
                onClick={() => {
                  setNum(num + '0');
                }}
              >
                0
              </Styled.Pad>
              <Styled.Pad
                onClick={() => {
                  setNum('');
                  setError(false);
                }}
              >
                ✕
              </Styled.Pad>
            </Styled.NumberPad>
          </Styled.Number>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton body={t('common.no')} onClick={props.onClick} />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};

export default SecretPin;
