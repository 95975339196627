import { useState, createContext } from "react";
import storeType from "./storeType";

const Context = createContext<storeType>({
  sending: true,
  actions: {},
});
const Store = (): storeType => {
  // User 관리
  const [sending, setSending] = useState(false);

  const actions = {
    setSending: (value: boolean) => {
      setSending(value);
    },
  };

  return { sending, actions };
};

export { Context, Store };
