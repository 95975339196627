// 액션!=
import createActions from '../controller/createActions';

const { read, onlyCreate } = createActions('plate');

export const actionGetPlateList = () => read({});

export const actionPostPlateOpen = (
  plateID: string,
  params: {
    startDate: string;
    endDate: string;
    ticketID: number | string | null;
    userID: number | string | null;
  },
  onSuccess?: Function,
) =>
  onlyCreate({
    url: `plate/open/${plateID}`,
    params,
    meta: {
      onSuccess,
      read: `plate`,
    },
  });

export const actionPostPlateClose = (plateID: string, onSuccess?: Function) =>
  onlyCreate({
    url: `plate/shutdown/${plateID}`,
    meta: {
      onSuccess,
      read: `plate`,
    },
  });
