import NetworkManager from "../../../NetworkManager";

const GetCheckIn = (num: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager("get", `admin/checkIn/${num}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export default GetCheckIn;
