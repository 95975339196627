import styled from 'styled-components';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 48px;

  ${BreakPoints.md} {
    font-size: 24px;
  }

  ${BreakPoints.sm} {
    font-size: 20px;
  }
`;

export const DropboxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${BreakPoints.sm} {
    .GODropbox {
      flex-grow: 1;
    }
  }
`;
