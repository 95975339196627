import NetworkManager from "../../../NetworkManager";
import { toast } from "react-toastify";

const UserReservation = (data: any) => {
  return new Promise((resolve, reject) => {
    NetworkManager("post", "admin/lessonReservation", data)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        if (err.response) {
          switch (err.response.status) {
            case 406:
              if (
                err.response.data.message === "invalid ticket type" ||
                err.response.data.message === "unavailable ticket"
              ) {
                toast.error("⛔️ 사용자가 예약할 수 없는 티켓입니다. ( 406 )");
              } else if (
                err.response.data.message === "PlateReservation already exists"
              ) {
                toast.error("⛔️ 중복 예약입니다. ( 406 )");
              } else {
                toast.error("⛔️ 예약은 최대 3개 까지 가능합니다. ( 406 )");
              }
              break;
            default:
              reject(err);
              break;
          }
        }
      });
  });
};

export default UserReservation;
