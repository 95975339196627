import styled, { css } from 'styled-components';
import * as color from '../../../color/index';
import { darken, lighten } from 'polished';
import { BreakPoints } from '../../../GlobalUtilityStyles';

interface segmentProps {
  state?: boolean;
}

export const Filter = styled.div`
  // width: 100%;
  // display: flex;
  // flex-wrap: wrap;
  // margin-bottom: 24px;
`;

export const DateBox = styled.div`
  width: 140px;
`;

export const DropboxWrapper = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-left: 20px;
  }
  .label {
    font-weight: bold;
    margin-right: 20px;
  }

  ${BreakPoints.sm} {
    margin: 0px 0px 10px;
    width: 100%;
    height: 40px;
    & + & {
      margin-left: 0;
    }
    .label {
      width: 4em;
    }
    .GODropbox {
      flex-grow: 1;
    }
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;

  h5 {
    margin-right: 16px;
    white-space: nowrap;
  }

  span {
    margin: 0 10px;
  }
  ${BreakPoints.md} {
    & > div {
      flex: 1 1 auto;
      width: 120px;
    }
  }

  ${BreakPoints.sm} {
    width: 100%;
    & > div {
      width: 100px;
    }
  }
  ${BreakPoints.xs} {
    flex-wrap: wrap;
    h5 {
      width: 100%;
      margin-bottom: 5px;
    }
  }
`;

export const ControlWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  ${BreakPoints.sm} {
    width: 100%;
    margin-left: 0;
    button {
      padding: 0 5px;
      font-size: 13px;
      flex: 1 1 auto;
      &:first-child {
        margin-left: 0 !important;
      }
    }
  }
`;

export const RightFilterWrapper = styled.div`
  display: flex;
  margin-left: auto;
  white-space: nowrap;
  ${BreakPoints.md} {
    width: 100%;
    padding: 12px 0;
    margin: 12px 0px 16px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  ${BreakPoints.sm} {
    flex-wrap: wrap;
    a {
      width: 100%;
    }
    button {
      margin-left: 0 !important;
      width: 100%;
    }
  }
`;

export const SegmentBox = styled.div<segmentProps>`
  display: flex;
  font-size: 24px;
  font-weight: bold;
  color: ${lighten(0.3, `${color.Colors.primary}`)};
  transition: 0.35s;
  margin-top: 20px;

  .totalPurchase {
    margin-right: 20px;
    cursor: pointer;

    ${props => {
      if (!props.state)
        return css`
          color: ${darken(0.03, `${color.Colors.primary}`)};
          border-bottom: 3px solid ${darken(0.03, `${color.Colors.primary}`)};
        `;
    }}
  }
  .newPurchase {
    cursor: pointer;

    ${props => {
      if (props.state)
        return css`
          color: ${darken(0.03, `${color.Colors.primary}`)};
          border-bottom: 3px solid ${darken(0.03, `${color.Colors.primary}`)};
        `;
    }}
  }

  ${BreakPoints.md} {
    margin-top: 0;
  }

  ${BreakPoints.sm} {
    font-size: 18px;
  }
`;
