import React from 'react';
import * as Styled from './styles';

interface GOLayoutListProps {
  leftbody?: JSX.Element;
  rightbody?: JSX.Element;
  shortTopPadding?: boolean;
}

/**
 * 사용된 레이아웃 (좌측 날개 퀵메뉴, 우측 컨텐츠 -  좌우 배치 형태의 레이아웃)
 - 고객관리 목록
 - 상품관리 목록
 - 스마트 어드바이저
 - 직원관리 목록
**/
const GOLayoutList = (props: GOLayoutListProps) => {
  return (
    <Styled.LayoutList shortTopPadding={props.shortTopPadding}>
      {/* 왼쪽 리스트 */}
      <Styled.LayoutLeftList>{props.leftbody}</Styled.LayoutLeftList>
      {/* 오른쪽 리스트 */}
      <Styled.LayoutRightList>{props.rightbody}</Styled.LayoutRightList>
    </Styled.LayoutList>
  );
};

export default GOLayoutList;
