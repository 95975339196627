import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as color from '../../../../color/index';
import Chart from 'chart.js';
import GODropbox from '../../../common/GODropbox';
import * as Styled from '../../../smartadvisor/chartAreaStyles';

interface monthlyAttendanceBarProps {
  data: any;
  label?: string;
  idForCanvas: string;
  yearComboData?: any;
  year: number;
  onChangeYear?: (year: number) => void;
}

const MonthlyAttendanceBar = ({
  data,
  label,
  idForCanvas,
  yearComboData,
  year,
  onChangeYear,
}: monthlyAttendanceBarProps) => {
  useEffect(() => {
    let chart: any = null;

    if (chart) {
      chart.destroy();
      chart = null;
    }
    const ctx: any = document.getElementById(idForCanvas);
    const attendanceDataSets = data.datasets[0];

    chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: data.labels,
        datasets: [
          {
            type: attendanceDataSets.type,
            label: attendanceDataSets.label,
            data: attendanceDataSets.data,
            backgroundColor: attendanceDataSets.backgroundColor,
            borderColor: attendanceDataSets.borderColor,
            borderWidth: attendanceDataSets.borderWidth,
            pointBorderWidth: attendanceDataSets.pointBorderWidth,
            datalabels: {
              color: '#fff',
              font: {
                size: 14,
                weight: 'bold',
              },
              formatter: (value: any) => {
                return value;
              },
            },
          },
        ],
      },
      options: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
          labels: {
            padding: 20,
            boxWidth: 15,
          },
        },
        maintainAspectRatio: false,
        tooltips: {
          displayColors: true,
          callbacks: {
            title: (item: any, data: any) => {
              let index = item[0].datasetIndex;
              return data.datasets[index].label;
            },
            label: (item: any) => {
              return `${item.value}`;
            },
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: (item: any) => {
                  return `${item}`;
                },
              },
              type: 'linear',
            },
          ],
        },
      },
    });
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [idForCanvas, data, year]);
  return (
    <Styled.ChartBox style={{ width: '100%' }}>
      {label && (
        <>
          <Styled.Title>
            {label}
            <div style={{ width: '130px' }}>
              <GODropbox
                data={
                  yearComboData ?? [
                    { body: '2022', value: 2022 },
                    { body: '2023', value: 2023 },
                  ]
                }
                value={year}
                onChange={(e: any) => {
                  if (!!onChangeYear) {
                    onChangeYear(Number(e.target.value));
                  }
                }}
                isDefault
              />
            </div>
          </Styled.Title>
        </>
      )}
      <Styled.Chart className="rect_chart_response" style={{ height: '350px' }}>
        <canvas id={idForCanvas} />
      </Styled.Chart>
    </Styled.ChartBox>
  );
};

export default MonthlyAttendanceBar;
