import React, { useState, useEffect } from 'react';
import { UserMemberPanel } from '../..';
import ListStatusBar from '../../layout/ListStatusBar';
import './index.scss';
import { UHiddenXS } from '../../../GlobalUtilityStyles';

interface GridViewProps {
  setCheck: Function;
  check: string[];
  data: any[];
  ticketFilterType?: number;
  totalCount?: number;
  nonPagedData?: any[];
}

const GridView = ({
  setCheck,
  check,
  data,
  ticketFilterType,
  totalCount,
  nonPagedData,
}: GridViewProps) => {
  const [allCheck, setAllCheck] = useState<boolean>(false);

  useEffect(() => {
    if (nonPagedData === undefined) {
      if (data?.length > 0) {
        if (data?.length === check?.length) {
          setAllCheck(true);
        } else {
          setAllCheck(false);
        }
      }
    } else {
      if (nonPagedData?.length > 0) {
        if (nonPagedData?.length === check?.length) {
          setAllCheck(true);
        } else {
          setAllCheck(false);
        }
      }
    }
  }, [data, check]);

  const handleChange = (e: any) => {
    if (e.target.checked) {
      setCheck(check.concat(e.target.id));
    } else {
      setCheck(check.filter((d: any) => d * 1 !== e.target.id * 1));
    }
  };

  const nowChecked = (e: any) => {
    return check.filter((d: any) => d * 1 === e.id).length > 0;
  };

  const allCheckBox = (e: any) => {
    setAllCheck(!allCheck);
    if (nonPagedData === undefined) {
      const IID = data.map((d: any) => JSON.stringify(d.id));
      if (e.target.checked) {
        setCheck(IID);
      } else {
        setCheck([]);
      }
    } else {
      const IID = nonPagedData.map((d: any) => JSON.stringify(d.id));
      if (e.target.checked) {
        setCheck(IID);
      } else {
        setCheck([]);
      }
    }
  };

  return (
    <div>
      <ListStatusBar
        leftbody={
          <>
            <input type="checkbox" onChange={allCheckBox} checked={allCheck} />
            &nbsp;전체회원 : {totalCount}명 중 {check?.length}명 선택
            <UHiddenXS>되었습니다.</UHiddenXS>
          </>
        }
        rightbody={`${totalCount} 명`}
      />
      <div className="gridlist">
        {data?.map((e: any, index: number) => (
          <React.Fragment key={index}>
            <UserMemberPanel
              tickets={e.tickets}
              name={e.name}
              sex={e.sex}
              id={e.id}
              img={e.profileImageURL}
              tel={e.phoneNumber}
              birth={e.dateOfBirth}
              onChange={handleChange}
              checked={nowChecked(e)}
              lastExpiredDate={e.lastExpiredDate}
              isVaccinated={
                e.vaccineUser !== undefined &&
                e.vaccineUser !== null &&
                e.vaccineUser.isVaccinated !== null &&
                e.vaccineUser.isVaccinated !== undefined &&
                e.vaccineUser.isVaccinated === 1
                  ? 1
                  : 0
              }
              link={`/admin/user/${e.id}`}
              ticketFilterType={ticketFilterType || 1}
            />
          </React.Fragment>
        ))}
        {/* CSS-hack : Flex 배치를 맞추기 위한 placeholder */}
        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        <div className="gridlist-placeholder" />
        {/* CSS-hack : Flex 배치를 맞추기 위한 placeholder 끝 */}
      </div>
    </div>
  );
};

export default GridView;
