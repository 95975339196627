import styled, { keyframes, css } from 'styled-components';
import * as color from '../../color/index';
import SelectDate from 'react-datepicker';
import { BreakPoints } from '../../GlobalUtilityStyles';

/*********************************
  * 설정 관련 공통 스타일
  - settings/Items/index.jsx
  - settings/Etc/index.jsx
  - settings/Plate/index.jsx
  - settings/Lesson/index.jsx
***********************************/
export const golayout = keyframes`
  0% {
    opacity: 0;
    left: -20px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
`;

export const Container = styled.div`
  max-width: 900px;
  font-size: 18px;
  font-weight: bold;
  color: ${color.Colors.primary};
  background: ${color.Colors.white};
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  padding: 20px 25px;
  animation: ${golayout} 0.35s;
  margin: 0 auto;
  ${BreakPoints.sm} {
    background: transparent;
    border: none;
    padding: 0;
  }
`;

export const TitleArea = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;

  ${BreakPoints.md} {
    margin-bottom: 30px;
  }
  ${BreakPoints.sm} {
    margin-bottom: 20px;
    background: #f9f9f9;
    position: sticky;
    top: 50px;
    padding-top: 10px;
    z-index: 1;
  }

  .title {
    flex-grow: 1;
    font-size: 24px;
  }
`;

export const BoxWrapper = styled.div`
  display: flex;
  max-width: 700px;
  margin: 0 auto 30px;

  .subTitle {
    width: 120px;
    line-height: 30px;
    flex-shrink: 0;
    margin-right: 20px;
  }

  .box-list {
    margin-left: auto;
    flex: 1 1 auto;
  }

  .box {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .inner {
      display: flex;
      flex-wrap: wrap;

      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    .description {
      font-size: 14px;
      color: #989898;
      margin-top: 10px;
    }
  }

  ${BreakPoints.md} {
    & {
      flex-wrap: wrap;
      .subTitle {
        width: 100%;
        margin-right: 0;
      }

      .label {
        font-size: 14px;
        line-height: 35px;
      }
    }
  }
`;

export const Input = styled.input`
  width: 80px;
  height: 32px;
  border: 2px solid ${color.Colors.line};
  border-radius: 5px;
  font-weight: bold;
  padding: 0px 25px 0px 12px;
  box-sizing: border-box;
`;

export const SelectBox = styled.select`
  width: 80px;
  height: 32px;
  padding-left: 5px;
  border: 2px solid ${color.Colors.line};
  border-radius: 5px;
  font-weight: bold;
  box-sizing: border-box;
`;

export const Flexbox = styled.div`
  display: flex;
  position: relative;

  .item {
    display: flex;
    font-size: 12px;
    align-items: center;

    &:first-child {
      margin-right: 15px;
    }
  }

  // 타석 예약 가능 시간
  &.response_extra {
    @media all and (max-width: 400px) {
      width: 100%;
      flex-wrap: wrap;
      .item {
        width: 100%;
        padding: 0px 0px 5px;
        justify-content: space-between;
        &:first-child {
          margin-right: 0;
        }
      }
    }
  }
`;

export const Unit = styled.div`
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 14px;
`;

export const CustomDate = styled(SelectDate)`
  height: 15px;
  width: 66px;
  border: 2px solid ${color.Colors.line};
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  color: ${color.Colors.primary};
  padding: 7px;
  margin-left: 10px;
`;
