import React, { ChangeEvent } from 'react';
import * as Styled from '../settingStyles';
import dayjs from 'dayjs';
import TimeToMinutesConvert, { TimeToMinutesConvertVer1 } from '../Convert/timeConvert';
import MinuteToTimeConvert from '../Convert/minuteConvert';

type plateSettingProps = {
  settingData: any;
  setSettingData: any;
  setSave: any;
  edit: boolean;
  timeAlert: boolean;
};

const PlateSettings = ({
  settingData,
  setSettingData,
  setSave,
  edit,
  timeAlert,
}: plateSettingProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSave(false);

    if (parseInt(value) < 1) setSettingData({ ...settingData, [name]: '1' });
    else setSettingData({ ...settingData, [name]: value.slice(0, 2) });
  };

  const handleChangeInSelectBox = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSave(false);
    setSettingData({ ...settingData, [name]: parseInt(value) });
  };

  return (
    <Styled.BoxWrapper>
      <div className="subTitle">타석 관련 설정</div>
      <div className="box-list">
        <div className="box">
          <div className="inner">
            <div className="label">타석 최대 예약 개수</div>
            <Styled.Flexbox>
              <Styled.Input
                name="maxPlateReservationCount"
                type="number"
                onChange={handleChange}
                value={settingData?.maxPlateReservationCount || ''}
                disabled={edit}
                required
              />
              <Styled.Unit>개</Styled.Unit>
            </Styled.Flexbox>
          </div>
          <div className="description">
            <div>타석 스케줄에서 회원이 예약할 수 있는 최대 개수를 설정합니다.</div>
            <div>기본 설정은 3개이며 최소 1개, 최대 20개로 설정 가능합니다.</div>
          </div>
        </div>
        <div className="box">
          <div className="inner">
            <div className="label">타석 예약 가능 시간</div>
            <Styled.Flexbox className="response_extra">
              <div className="item">
                <div>오픈 시간</div>
                <Styled.CustomDate
                  name="plateBookingOpenTime"
                  placeholderText="시간을 선택하세요"
                  value={
                    dayjs(TimeToMinutesConvert(settingData?.plateBookingOpenTime)).format(
                      'a h:mm',
                    ) === 'Invalid Date'
                      ? ''
                      : dayjs(TimeToMinutesConvert(settingData?.plateBookingOpenTime)).format(
                          'a h:mm',
                        )
                  }
                  onChange={(time: any) => {
                    setSettingData({
                      ...settingData,
                      plateBookingOpenTime: MinuteToTimeConvert(time),
                    });
                    setSave(false);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  disabled={edit}
                />
              </div>
              <div className="item">
                <div>종료 시간</div>
                <Styled.CustomDate
                  name="plateBookingCloseTime"
                  placeholderText="시간을 선택하세요"
                  value={
                    dayjs(TimeToMinutesConvertVer1(settingData?.plateBookingCloseTime)).format(
                      'a h:mm',
                    ) === 'Invalid Date'
                      ? ''
                      : dayjs(TimeToMinutesConvertVer1(settingData?.plateBookingCloseTime)).format(
                          'a h:mm',
                        )
                  }
                  onChange={(time: any) => {
                    setSettingData({
                      ...settingData,
                      plateBookingCloseTime: MinuteToTimeConvert(time),
                    });
                    setSave(false);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={10}
                  disabled={edit}
                />
              </div>
            </Styled.Flexbox>
          </div>
          <div className="description">
            행복골프 앱에서 보여지는 타석예약의 시간 범위를 설정합니다.
          </div>
          {timeAlert && <div style={{ color: 'red' }}>시간 범위를 다시 설정해주세요!</div>}
        </div>
        <div className="box">
          <div className="inner">
            <div className="label">타석 예약 가능 일자</div>
            <Styled.Flexbox>
              <Styled.Input
                name="plateScheduleDaysToOpen"
                type="number"
                onChange={handleChange}
                value={settingData?.plateScheduleDaysToOpen || ''}
                disabled={edit}
                required
              />
              <Styled.Unit>일</Styled.Unit>
            </Styled.Flexbox>
          </div>
          <div className="description">
            <div>행복골프 앱에서 타석 예약이 가능한 날짜 범위를 설정합니다.</div>
            <div>최소 1일(당일), 최대 30일(1달)까지 설정 가능합니다.</div>
          </div>
        </div>
        {settingData?.modalSettingShowInSetting === 1 && (
          <div className="box">
            <div className="inner">
              <div>강제 로그인 기능 활성화</div>
              <Styled.Flexbox>
                <Styled.SelectBox
                  name="modalSettingShowModalYN"
                  onChange={handleChangeInSelectBox}
                  value={settingData.modalSettingShowModalYN}
                  disabled={edit}
                >
                  <option value={0}>미적용</option>
                  <option value={1}>적용</option>
                </Styled.SelectBox>
              </Styled.Flexbox>
            </div>
            <div className="description">
              <div>강제 로그인 기능 사용 여부를 선택합니다.</div>
              <div>타석별로 다르게 설정할 수 있습니다.</div>
            </div>
          </div>
        )}
      </div>
    </Styled.BoxWrapper>
  );
};

export default PlateSettings;
