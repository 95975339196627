import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { GOButton, GOFloatingbox } from '../../components';
import { NetModifyPassword, NetSignOut } from '../../actions';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../GlobalUtilityStyles';

const ChangePassword = ({ onClickExit }: any) => {
  const { t } = useTranslation();

  const [focusIndex, setFocusIndex] = useState<number>(1);
  const [errorInPwd, setErrorInPwd] = useState<number>(0);
  const [state, setState] = useState<any>({
    password: '',
    newPassword: '',
    newPasswordAgain: '',
  });

  const errorMessageInPwd = (errorIndex: number) => {
    if (errorIndex === 0) {
      return (
        <>
          <Styled.ErrorText />
        </>
      );
    } else if (errorIndex === 1) {
      return (
        <>
          <Styled.ErrorText>* 현재 비밀번호를 입력해주세요.</Styled.ErrorText>
        </>
      );
    } else if (errorIndex === 3) {
      return (
        <>
          <Styled.ErrorText>* 현재 비밀 번호가 일치하지 않습니다. </Styled.ErrorText>
        </>
      );
    } else {
      return (
        <>
          <Styled.ErrorText>* 현재 비밀번호를 입력해주세요.</Styled.ErrorText>
        </>
      );
    }
  };

  const [errorInNewPwd, setErrorInNewPwd] = useState<number>(0);

  const errorMessageInNewPwd = (errorIndex: number) => {
    if (errorIndex === 0) {
      return (
        <>
          <Styled.ErrorText />
        </>
      );
    } else if (errorIndex === 1) {
      return (
        <>
          <Styled.ErrorText>* 새 비밀번호를 입력해주세요.</Styled.ErrorText>
        </>
      );
    } else if (errorIndex === 2) {
      return (
        <>
          <Styled.ErrorText>* 비밀 번호는 5글자 이상입니다.</Styled.ErrorText>
        </>
      );
    } else {
      return (
        <>
          <Styled.ErrorText />
        </>
      );
    }
  };

  const [errorInNewPwdAgain, setErrorInNewPwdAgain] = useState<number>(0);

  const errorMessageInNewPwdAgain = (errorIndex: number) => {
    if (errorIndex === 0) {
      return (
        <>
          <Styled.ErrorText />
        </>
      );
    } else if (errorIndex === 1) {
      return (
        <>
          <Styled.ErrorText>* 새 비밀번호(확인)를 입력해주세요.</Styled.ErrorText>
        </>
      );
    } else if (errorIndex === 2) {
      return (
        <>
          <Styled.ErrorText>* 새 비밀번호와 일치하지 않습니다.</Styled.ErrorText>
        </>
      );
    } else {
      return (
        <>
          <Styled.ErrorText />
        </>
      );
    }
  };

  // const passwordData = { oldPINS: num, newPINs: changeNum };
  const onChange = (e: any) => {
    if (e.target.name === 'password') {
      if (e.target.value.length > 0 && errorInPwd === 1) {
        setErrorInPwd(0);
      }
      setFocusIndex(1);
    } else if (e.target.name === 'newPassword') {
      if (e.target.value.length > 0 && errorInNewPwd === 1) {
        setErrorInNewPwd(0);
      }

      if (e.target.value.length < 5) {
        setErrorInNewPwd(2);
      } else {
        if (e.target.value === state.newPasswordAgain) {
          setErrorInNewPwd(0);
          setErrorInNewPwdAgain(0);
        } else {
          setErrorInNewPwd(0);
          setErrorInNewPwdAgain(2);
        }
      }
      setFocusIndex(2);
    } else if (e.target.name === 'newPasswordAgain') {
      setFocusIndex(3);
      if (e.target.value.length > 0 && errorInNewPwdAgain === 1) {
        setErrorInNewPwdAgain(0);
      }

      if (e.target.value.length > 0) {
        if (e.target.value === state.newPassword) {
          setErrorInNewPwdAgain(0);
        } else {
          setErrorInNewPwdAgain(2);
        }
      }
    }
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const onLogout = (data: any) => {
    let i = { identifier: data };
    NetSignOut(i)
      .then((res: any) => {
        console.log('NetSignOUt');
        console.log(res);
        if (res) {
          window.localStorage.removeItem('UUINFO');
          document.location.href = '/';
        }
      })
      .catch((err: any) => console.log(err));
  };

  const handleSubmit = () => {
    //password validation
    let isOkay = true;
    if (state.password.length < 1) {
      setErrorInPwd(1);
      isOkay = false;
    }

    if (state.newPassword.length < 1) {
      setErrorInNewPwd(1);
      isOkay = false;
    }

    if (state.newPasswordAgain.length < 1) {
      setErrorInNewPwdAgain(1);
      isOkay = false;
    }

    if (state.newPassword !== state.newPasswordAgain) {
      isOkay = false;
    }

    if (!isOkay) {
      return;
    }

    const passwordData = { ...state };
    NetModifyPassword(passwordData)
      .then((res: any) => {
        if (res) {
          onLogout(window.localStorage.getItem('Identifier'));
          // onClickExit();
        }
      })
      .catch(err => {
        if (err.response.data.message === 'in correct password') {
          toast.error('현재 비밀번호가 일치하지 않습니다.');
        } else {
          toast.error(`오류: ${err.response.data.message}`);
        }
      });
  };

  return (
    <GOFloatingbox width={660}>
      <UFloatingContainer>
        <UFloatingBoxHeader textCenter>
          <h1>비밀번호 변경</h1>
        </UFloatingBoxHeader>
        <UFloatingBoxBody>
          <div className="flex_box">
            <Styled.FormContainer>
              <Styled.SubTitle>현재 비밀번호</Styled.SubTitle>
              <Styled.NumberText
                autoComplete="new-password"
                type="password"
                name="password"
                onChange={onChange}
                value={state.password}
                onClick={() => {
                  setFocusIndex(1);
                }}
                style={{
                  borderBottom:
                    errorInPwd > 0
                      ? '2px solid #fa5252'
                      : focusIndex === 1
                      ? '2px solid #12b886'
                      : '2px solid #ddd',
                  transition: '0.35s',
                }}
                // readOnly
              />
              {errorMessageInPwd(errorInPwd)}
              <Styled.SubTitle>새 비밀번호</Styled.SubTitle>
              <Styled.NumberText
                autoComplete="new-password"
                name="newPassword"
                type="password"
                onChange={onChange}
                onClick={() => {
                  setFocusIndex(2);
                }}
                style={{
                  borderBottom:
                    errorInNewPwd > 0
                      ? '2px solid #fa5252'
                      : focusIndex === 2
                      ? '2px solid #12b886'
                      : '2px solid #ddd',
                  transition: '0.35s',
                }}
              />
              {errorMessageInNewPwd(errorInNewPwd)}
              <Styled.SubTitle>새 비밀번호(확인)</Styled.SubTitle>
              <Styled.NumberText
                autoComplete="new-password"
                name="newPasswordAgain"
                type="password"
                onChange={onChange}
                onClick={() => {
                  setFocusIndex(3);
                }}
                style={{
                  borderBottom:
                    errorInNewPwdAgain > 0
                      ? '2px solid #fa5252'
                      : focusIndex === 3
                      ? '2px solid #12b886'
                      : '2px solid #ddd',
                  transition: '0.35s',
                }}
              />
              {errorMessageInNewPwdAgain(errorInNewPwdAgain)}
            </Styled.FormContainer>
          </div>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton buttontype="green" body="변경" onClick={handleSubmit} />
          <GOButton body={t('common.no')} onClick={onClickExit} />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};

export default ChangePassword;
