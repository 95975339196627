import React from 'react';
import { LockerPanel, GOAsyncDiv } from '../../..';
import * as Styled from './styles';
import lockerTypes from '../Types/lockerType';
import { GOLayout } from '../../../../components';

interface ViewProps {
  data: any;
}

const View = ({ data }: ViewProps) => {
  const lockerData: lockerTypes[] = data.data ? data.data.Lockers : [];

  const placeHolder = () => {
    let arr = [];
    for (let i = 0; i < 10; i++) {
      arr.push(<Styled.PlatePlaceholder key={i} />);
    }
    return arr;
  };

  return (
    <GOLayout
      body={
        <Styled.LockerView>
          <GOAsyncDiv status={data.status}>
            {lockerData.map((d: any) => (
              <LockerPanel key={d.id} data={d} lockerData={data.data} />
            ))}
            {placeHolder()}
          </GOAsyncDiv>
        </Styled.LockerView>
      }
    />
  );
};
export default View;
