import NetworkManager from '../../../NetworkManager';
import { toast } from 'react-toastify';

const AddManually = (data: any) => {
  return new Promise((resolve, reject) => {
    NetworkManager('post', 'admin/lessonAvailability/registerManually', data)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        if (err.response) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          }
        }
        reject(err);
      });
  });
};

export default AddManually;
