import NetworkManager from "../../../NetworkManager";

const ReservationCheckIn = (rID: string, rType: any) => {
  return new Promise((resolve, reject) => {
    NetworkManager(
      "put",
      `admin/checkin?reservationID=${rID}&reservationType=${rType}`
    )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export default ReservationCheckIn;
