import React from 'react';
import * as Styled from './styles';

interface ReservationDateProps {
  name?: string;
  dateName?: string;
  date: any;
  time?: any;
  onChange: any;
  placeholder?: string;
  timeInterval?: any;
}

const ReservationDate = (props: ReservationDateProps) => {
  return (
    <Styled.DateItem>
      <Styled.DateName>{props.name}</Styled.DateName>
      {props.time ? (
        <>
          <Styled.CustomDate
            name={props.dateName}
            value={props.date?.format(`a hh:mm`)}
            placeholderText="시간을 선택하세요."
            onChange={props.onChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={props.timeInterval ? props.timeInterval : 30}
          />
        </>
      ) : (
        <>
          <Styled.CustomDate
            name={props.dateName}
            value={props.date?.format('YYYY.MM.DD')}
            placeholderText="날짜를 선택하세요."
            onChange={props.onChange}
          />
        </>
      )}
    </Styled.DateItem>
  );
};

export default ReservationDate;
