import NetworkManager from '../../NetworkManager';
import { UserManager } from '../..';
import { toast } from 'react-toastify';

const RefreshToken = () => {
  const dataObject: any = UserManager().getUser();

  return new Promise((resolve, reject) => {
    NetworkManager('post', 'admin/auth/token', {
      clientSecretKey: dataObject.clientSecretKey,
    })
      .then(async (res: any) => {
        window.location.reload(true);
        console.log('✅ 토큰이 생성되었습니다. ( Token Refresh, 200 )');
        const userString = JSON.stringify({
          ...dataObject,
          accessToken: res.data.Data.accessToken,
        });
        await window.localStorage.setItem('UUINFO', userString);
        console.log('🔁 기존 통신을 재개합니다. ( Network Restart )');
        await resolve(res);
      })
      .catch((err: any) => {
        if (err.response) {
          switch (err.response.status) {
            case 401:
              toast.error('⛔️ 중복 로그인으로 로그아웃 됩니다. ( 401 )');
              window.localStorage.removeItem('UUINFO');
              document.location.reload(true);
              break;
            case 404:
              toast.error('⛔️ 중복 로그인으로 로그아웃 됩니다. ( 404 )');
              UserManager().setUser({
                isSignIn: false,
                id: 0,
                name: '',
                username: '',
                companyNumber: '',
                phoneNumber: '',
                clientSecretKey: '',
                isApproved: false,
                accessToken: '',
              });
              document.location.reload(true);
              break;
            default:
              reject(err);
              break;
          }
        }
      });
  });
};

export default RefreshToken;
