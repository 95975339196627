import React, { useState, FormEvent } from 'react';
import * as Styled from './styles';
import {
  GODropbox,
  GODate,
  GOLabel,
  SmallTitle,
  GOFloatingbox,
  GOButton,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { lockerTypeEdit } from '../../../Types/lockerType';
import { NetUserTicketSearch } from '../../../actions';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import {
  actionPutLocker,
  actionDeleteLocker,
  actionPutLockerRelease,
  actionPutLockerAssign,
} from '../../../stores/actions';
import { useDispatch } from 'react-redux';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

interface editProps {
  lockerData: {
    data: lockerTypeEdit;
    lockerData: {
      Lockers: [];
    };
  };
  onClickExit: () => void;
}

const Edit = ({ lockerData, onClickExit }: editProps) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [data, setData] = useState<any>(lockerData?.data);
  const [defaultStartDate, setStartDate] = useState({});
  const [defaultEndDate, setEndDate] = useState({});
  const [user, setUser] = useState<any>('');
  const [userID, setUserID] = useState<number>(0);
  const [totalUser, setTotalUser] = useState<any>();
  const [searchState, setSearchState] = useState<boolean>(false);
  const isEdit = data?.User ? true : false;
  const unUsedLocker = lockerData.lockerData?.Lockers.filter((d: any) => d.startDate === null);

  let defaultData = {
    ...data,
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  };

  const onSearch = () => {
    NetUserTicketSearch(user, 4).then((res: any) => {
      const userData = res.data.Data.users;

      if (userData.length > 0) {
        toast(`${userData.length}건이 검색되었습니다.`);
        setTotalUser(userData);
        setData({
          ...data,
          userID: 0,
          ticketID: '',
          tickets: [],
          startDate: null,
          endDate: null,
        });
        setUserID(0);
        setSearchState(true);
      } else {
        toast.error('검색 결과가 없습니다.');
        setUser(user);
        setSearchState(false);
      }
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (name === 'selectUser') {
      totalUser?.filter((tUser: any) => {
        if (tUser.id === JSON.parse(value)) {
          setData({
            ...data,
            id: data.id,
            userID: tUser.id,
            tickets: tUser.tickets,
          });
          setUserID(tUser.id);
        }
      });
    } else if (name === 'newNumber') {
      setData({
        ...data,
        newNumber: JSON.parse(value),
      });
    } else {
      data?.tickets.forEach((ticket: any) => {
        if (ticket.id === JSON.parse(value)) {
          setData({
            ...data,
            startDate: new Date(ticket.startDate),
            endDate: new Date(ticket.endDate),
            ticketID: JSON.parse(value),
          });
          setStartDate(new Date(ticket.startDate));
          setEndDate(new Date(ticket.endDate));
        }
      });
    }
  };

  const handleSubmit = (e: FormEvent<EventTarget>) => {
    e.preventDefault();

    if (isEdit) {
      // 수정
      if (window.confirm('락커를 수정하시겠습니까?')) {
        dispatch(
          actionPutLockerAssign(data.id, data, () => {
            alert('락커가 수정되었습니다.');
            onClickExit();
          }),
        );
      }
    } else {
      // 추가
      if (data.startDate > new Date()) {
        alert('이용 시작 날짜가 현재 날짜보다 앞선 이용권으로 배정할 수 없습니다.');
      } else if (data.startDate && data.endDate && data.startDate > data.endDate) {
        alert('시작 날짜가 종료 날짜보다 클 수 없습니다.');
      } else {
        if (data.userID && window.confirm('락커를 배정하시겠습니까?')) {
          dispatch(
            actionPutLockerAssign(data.id, data && defaultData, () => {
              alert('락커가 배정되었습니다.');
              onClickExit();
            }),
          );
        }
      }
    }
  };

  const onDelete = () => {
    if (window.confirm(t('locker.isDel'))) {
      dispatch(
        actionDeleteLocker(lockerData.data.id, () => {
          alert(t('locker.delOK'));
          onClickExit();
        }),
      );
    }
  };

  const onEdit = () => {
    if (data.startDate && data.endDate && dayjs(data.startDate) > dayjs(data.endDate)) {
      alert('시작 날짜가 종료 날짜보다 클 수 없습니다.');
    } else if (window.confirm(t('locker.isEdit'))) {
      dispatch(
        actionPutLocker(lockerData.data.id, data, () => {
          alert(t('locker.editOK'));
          onClickExit();
        }),
      );
    }
  };

  const onFinish = () => {
    if (window.confirm(t('locker.isFinish'))) {
      dispatch(
        actionPutLockerRelease(lockerData.data.id, () => {
          alert(t('locker.finishOK'));
          onClickExit();
        }),
      );
    }
  };

  if (isEdit) {
    // 배정된 락커
    return (
      <Styled.PageStyles>
        <GOFloatingbox width={920}>
          <UFloatingContainer>
            <UFloatingBoxHeader>
              <h1>{t('locker.lockerEdit')}</h1>
              <p>{t('locker.lockerEditInfo')}</p>
            </UFloatingBoxHeader>
            <UFloatingBoxBody flex>
              <div className="flex_box">
                <SmallTitle body="락커" />
                <GOLabel label="락커번호" body={data.number} />
                <GOLabel label="상태" body={data.User ? '사용불가(사용중)' : '사용가능'} />
              </div>
              <div className="flex_box">
                <SmallTitle body="배정 기간" />
                <GODate
                  startDate={data.startDate && new Date(data.startDate)}
                  endDate={data.endDate && new Date(data.endDate)}
                  label={t('reservation.startDate')}
                  selected={data.startDate && new Date(data.startDate)}
                  onChange={(date: any) => {
                    setData({ ...data, startDate: date });
                  }}
                  required
                />
                <GODate
                  startDate={data.startDate && new Date(data.startDate)}
                  endDate={data.endDate && new Date(data.endDate)}
                  label={t('reservation.endDate')}
                  selected={data.endDate && new Date(data.endDate)}
                  onChange={(date: any) => {
                    setData({ ...data, endDate: date });
                  }}
                  required
                />
                <div style={{ marginTop: '20px' }}>
                  <SmallTitle body="락커 변경" />
                  <Styled.ChangeLocker>
                    <div>
                      <div className="title">사용 중인 락커</div>
                      <div>{data.number} 번</div>
                    </div>
                    <div className="arrow">→</div>
                    <div>
                      <div>이동할 락커 번호</div>
                      <select name="newNumber" onChange={handleChange} value={data?.newNumber}>
                        <option value="">선택</option>
                        {unUsedLocker?.map((u: any, index: number) => (
                          <React.Fragment key={index}>
                            <option key={index} value={u.number}>
                              {u.number} 번
                            </option>
                          </React.Fragment>
                        ))}
                      </select>
                    </div>
                  </Styled.ChangeLocker>
                </div>
              </div>
            </UFloatingBoxBody>
            <UFloatingBoxFooter>
              <GOButton buttontype="green" body={t('common.edit')} onClick={onEdit} />
              <GOButton body={t('locker.finish')} buttontype="primary" onClick={onFinish} />
              <GOButton body={t('common.no')} onClick={onClickExit} />
            </UFloatingBoxFooter>
          </UFloatingContainer>
        </GOFloatingbox>
      </Styled.PageStyles>
    );
  } else {
    return (
      // 배정안된 락커
      <Styled.PageStyles>
        <GOFloatingbox width={920}>
          <UFloatingContainer as="form" onSubmit={handleSubmit}>
            <UFloatingBoxHeader>
              <h1>{t('locker.lockerAdd')}</h1>
              <p>{t('locker.lockerAddInfo')}</p>
            </UFloatingBoxHeader>
            <UFloatingBoxBody flex>
              <div className="flex_box">
                <SmallTitle body="회원 선택" />
                <Styled.Box>
                  <div className="box_title">회원검색</div>
                  <div className="input_wrapper">
                    <Styled.Input
                      placeholder="회원이름"
                      name="name"
                      value={user}
                      onChange={(e: any) => setUser(e.target.value)}
                      onKeyDown={(e: any) => (e.keyCode === 13 ? onSearch() : '')}
                    />
                  </div>
                  <GOButton body="검색" onClick={onSearch} />
                </Styled.Box>
                {totalUser?.length > 0 && (
                  <>
                    <Styled.Box>
                      <div className="box_title">
                        검색결과: {totalUser.length === 0 ? 1 : totalUser?.length}건
                      </div>
                      <div className="dropbox_wrapper">
                        <GODropbox
                          name="selectUser"
                          data={
                            searchState
                              ? totalUser.map((tUser: any) => {
                                  return {
                                    value: tUser.id,
                                    body: `${tUser.name} / ${tUser.phoneNumber}`,
                                  };
                                })
                              : null
                          }
                          onChange={handleChange}
                          value={userID}
                          required
                        />
                      </div>
                    </Styled.Box>
                    <Styled.Box>
                      <div className="box_title">이용권 선택</div>
                      <div className="dropbox_wrapper">
                        <GODropbox
                          name="ticketID"
                          data={data.tickets?.map((ticket: any) => {
                            return {
                              value: ticket.id,
                              body: `${ticket.name} ${
                                ticket.isUnlimited
                                  ? `(무제한)`
                                  : `( ${ticket.remainingCount} / ${ticket.totalCount} )`
                              }`,
                            };
                          })}
                          onChange={handleChange}
                          value={data?.ticketID}
                          required
                        />
                      </div>
                    </Styled.Box>
                    <br />
                    <SmallTitle body="배정 기간" />
                    <Styled.Box>
                      <div className="box_title">시작 기간</div>
                      <div className="dropbox_wrapper">
                        <GODate
                          startDate={data.startDate}
                          endDate={data.endDate}
                          selected={data.ticketID ? defaultStartDate : data.startDate}
                          onChange={(date: any) => {
                            setData({
                              ...data,
                              startDate: date,
                            });
                            setStartDate(date);
                          }}
                          disabled
                          required
                          placeholder={data.ticketID ? defaultStartDate : ''}
                        />
                      </div>
                    </Styled.Box>
                    <Styled.Box>
                      <div className="box_title">종료 기간</div>
                      <div className="dropbox_wrapper">
                        <GODate
                          startDate={data.startDate}
                          endDate={data.endDate}
                          selected={data.ticketID ? defaultEndDate : data.endDate}
                          onChange={(date: any) => {
                            setData({
                              ...data,
                              endDate: date,
                            });
                            setEndDate(date);
                          }}
                          minDate={data.startDate}
                          required
                          disabled
                          placeholder={data.ticketID ? defaultEndDate : ''}
                        />
                      </div>
                    </Styled.Box>
                  </>
                )}
              </div>
              <div className="flex_box">
                <SmallTitle body="락커" />
                <GOLabel label="락커번호" body={data?.number} />
                <GOLabel label="상태" body={data?.User ? '사용불가(사용중)' : '사용가능'} />
              </div>
            </UFloatingBoxBody>
            <UFloatingBoxFooter>
              <GOButton type="submit" buttontype="green" body={t('locker.lockerAdd')} />
              <GOButton body={t('common.del')} buttontype="red" onClick={onDelete} />
              <GOButton body={t('common.no')} onClick={onClickExit} />
            </UFloatingBoxFooter>
          </UFloatingContainer>
        </GOFloatingbox>
      </Styled.PageStyles>
    );
  }
};

export default Edit;
