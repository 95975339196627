import React from 'react'; //comments: 상단에 요약 정보 보여 주려고
import * as Styled from './../reservationViewStyles';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { useHistory } from 'react-router-dom';
import 'tui-grid/dist/tui-grid.css';
import 'tui-pagination/dist/tui-pagination.css';
import TuiGrid from 'tui-grid';
import Grid from '@toast-ui/react-grid';

TuiGrid.setLanguage('ko');
TuiGrid.applyTheme('striped');

interface LessonReservationStatusGridProps {
  gridColumns: any;
  gridData: any;
  update?: any;
}

const LessonReservationStatusGrid = ({
  gridColumns,
  gridData,
}: LessonReservationStatusGridProps) => {
  dayjs.locale('ko');

  const history = useHistory();

  const handleDbClick = (evt?: any) => {
    const cellNameWithPrefix = /p\d+/.exec(evt?.columnName);

    if (evt?.targetType === 'cell' && cellNameWithPrefix !== null) {
      const id = evt?.rowKey;
      if (id !== null && !isNaN(id) && id < gridData.length) {
        console.log(cellNameWithPrefix);
        const userID = gridData[id][`${evt?.columnName}_uid`];
        console.log(userID);
        if (userID !== undefined && !isNaN(userID) && userID !== 0) {
          history.push(`/admin/user/${userID}`);
        }
        console.log(userID);
      }
    }
    console.log(evt);
  };

  const pageOption: any = {
    useClient: true,
    perPage: 100,
  };

  return (
    <Styled.ReservationView>
      <Grid
        data={gridData}
        columns={gridColumns}
        rowHeight={25}
        bodyHeight={1200}
        heightResizable={false}
        rowHeaders={['rowNum']}
        pageOptions={pageOption}
        columnOptions={{ resizable: true }}
        onDblclick={handleDbClick}
        scrollX={true}
      />
    </Styled.ReservationView>
  );
};
export default LessonReservationStatusGrid;
