import NetworkManager from '../../../NetworkManager';
import { toast } from 'react-toastify';

const Add = (data: any) => {
  return new Promise((resolve, reject) => {
    NetworkManager('post', 'admin/lessonAvailability/register', data)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        if (err.response) {
          switch (err.response.status) {
            case 404:
              toast.error('⛔️ 먼저 직원을 등록해주세요. ( 400 )');
              break;
            case 406:
              if (
                err.response.data.message === 'invalid ticket type' ||
                err.response.data.message === 'unavailable ticket'
              ) {
                toast.error('⛔️ 사용자가 예약할 수 없는 티켓입니다. ( 406 )');
              } else if (err.response.data.message === 'LessonAvailabilities already exist') {
                toast.error(
                  '⛔️ 코치 스케줄이 등록되지 않았습니다. 근무시간을 확인해주세요. ( 406 )',
                );
              }
              break;
            default:
              break;
          }
        }
        reject(err);
      });
  });
};

export default Add;
