import React, { useState } from 'react';
import { HeaderBottom, StatusTool, SAFilter, GOLayoutList } from '../../../components';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';
import MemberConfiguration from '../MemberConfiguration';
import MonthlyMemberConfiguration from '../MonthlyMemberConfiguration';
import MonthlyNewMemberConfiguration from '../MonthlyNewMemberConfiguration';
import MonthlySales from '../MonthlySales';
import YearlySales from '../YearlySales';
import AreaConfiguration from '../AreaConfiguration';
import TestStaus from '../TestStaus';

//DONE feature/2021/1214/iOSMultibranch
const SmartAdvisorView = () => {
  const { t } = useTranslation();
  const [filterType, setFilterType] = useState({
    type: 0,
  });
  const [detailData, setDetailData] = useState({
    title: '회원 현황',
    id: 0,
  });
  const [selected, setSelected] = useState<any>(0);
  const titleArr = [
    {
      id: 0,
      title: '회원 현황',
      state: false,
    },
    {
      id: 1,
      title: '월별 활성 회원 현황',
      state: false,
    },
    {
      id: 2,
      title: '당월 매출 현황',
      state: false,
    },
    {
      id: 3,
      title: '월별 매출 현황',
      state: false,
    },
    // 준비 상태로 방치되어 있을 바에야 일단 가린다.
    // {
    //   id: 4,
    //   title: '지역별 분포 현황',
    //   state: false,
    // },
    {
      id: 5,
      title: '신규 회원 현황',
      state: false,
    },
    {
      id: 6,
      title: '시험 현황',
      state: false,
    },
  ];

  const [filterTypeYear, setFilterTypeYear] = useState<any>(new Date().getFullYear());

  const selectStatus = (str: string, id: number) => {
    // 선택별 현황 네트워크
    setDetailData({ ...detailData, title: str, id: id });
  };
  return (
    <>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool
              link="/admin/employee/list"
              type="now"
              body={t('main-menu.smart_advisor')}
            />
          </>
        }
        rightmenu={<></>}
      />
      <SAFilter
        filterType={filterType}
        setFilterType={setFilterType}
        filterTypeYear={filterTypeYear}
        setFilterTypeYear={setFilterTypeYear}
        title={detailData.title}
        id={detailData.id}
      />
      <GOLayoutList
        shortTopPadding
        leftbody={
          <Styled.LeftItem>
            <div className="title">
              <h4>스마트 어드바이저</h4>
              <hr />
            </div>
            {titleArr.map((t: any, index: number) => (
              <React.Fragment key={index}>
                <Styled.Button
                  style={{
                    backgroundColor: selected === index ? '#747474' : 'white',
                    color: selected === index ? 'white' : '#495057',
                  }}
                  onClick={() => {
                    setSelected(index);
                    selectStatus(t.title, t.id);
                  }}
                >
                  <Styled.FlexBox>
                    <div>{t.title}</div>
                    {selected !== index && <img src="/images/ButtonArrow.png" alt=">" />}
                  </Styled.FlexBox>
                </Styled.Button>
              </React.Fragment>
            ))}
          </Styled.LeftItem>
        }
        rightbody={
          <Styled.RightContainer>
            {detailData.id === 0 && <MemberConfiguration filterType={Number(filterType.type)} />}
            {detailData.id === 1 && (
              <MonthlyMemberConfiguration yearFilter={Number(filterTypeYear)} />
            )}
            {detailData.id === 2 && <MonthlySales />}
            {detailData.id === 3 && <YearlySales yearFilter={Number(filterTypeYear)} />}
            {detailData.id === 4 && <AreaConfiguration />}
            {detailData.id === 5 && (
              <MonthlyNewMemberConfiguration yearFilter={Number(filterTypeYear)} />
            )}
            {detailData.id === 6 && <TestStaus />}
          </Styled.RightContainer>
        }
      />
    </>
  );
};

export default SmartAdvisorView;
