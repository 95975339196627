import styled from 'styled-components';
import * as color from '../../../color/index';

export const EmptyMessage = styled.div`
  font-weight: bold;
  font-size: 24px;
  color: ${color.Colors.line};
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;
