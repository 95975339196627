import React from 'react';
import * as Styled from './styles';
// import GODropbox from '../../common/GODropbox';
import { GOFilterContainer, GODropbox } from '../../../components';
import { makeYearDataForComboBox } from '../../../util/converter';
//DONE: feature/2021/1214/iOSMultibranch
const Filter = ({
  filterType,
  setFilterType,
  filterTypeYear,
  setFilterTypeYear,
  title,
  id,
}: any) => {
  const yearComboData = makeYearDataForComboBox();
  return (
    <GOFilterContainer
      leftbody={<Styled.Title>{title}</Styled.Title>}
      rightbody={
        <>
          <Styled.DropboxWrapper>
            {id === 0 && (
              <GODropbox
                data={[
                  { value: 0, body: '현재 이용 회원' },
                  { value: 1, body: '전체 회원' },
                ]}
                value={filterType.type}
                onChange={(e: any) => setFilterType({ ...filterType, type: e.target.value })}
                isDefault
              />
            )}
            {id === 1 && (
              <GODropbox
                // data={[
                //   { value: 2021, body: '2021년' },
                //   { value: 2022, body: '2022년' },
                // ]}
                data={yearComboData}
                value={filterTypeYear}
                onChange={(e: any) => setFilterTypeYear(Number(e.target.value))}
                isDefault
              />
            )}
            {id === 3 && (
              <GODropbox
                data={yearComboData}
                value={filterTypeYear}
                onChange={(e: any) => setFilterTypeYear(Number(e.target.value))}
                isDefault
              />
            )}
            {id === 5 && (
              <GODropbox
                data={yearComboData}
                value={filterTypeYear}
                onChange={(e: any) => setFilterTypeYear(Number(e.target.value))}
                isDefault
              />
            )}
          </Styled.DropboxWrapper>
        </>
      }
    />
  );
};

export default Filter;
