import styled, { keyframes } from 'styled-components';
import * as color from '../../../color/index';

const displayError = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ErrorContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 8px 0;
  height: 48px;
  background: ${color.Colors.red};
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(249, 83, 84, 0.3);
  color: ${color.Colors.white};
  font-weight: bold;
  animation: ${displayError} 0.35s;
  text-align: left;
`;

export const ErrorIcon = styled.div`
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 20px;
  background: ${color.Colors.white};
  margin: 0 8px 0px 12px;
  color: ${color.Colors.red};
`;
