import { useState } from "react";

interface useTableProps {
  data?: {}[];
}

const useTable = () => {
  const [check, setCheck] = useState<any[]>([]);

  return { check, setCheck };
};

export default useTable;
