import NetworkManager from "../../../NetworkManager";
import { toast } from "react-toastify";

const VerifySMS = (data: object) => {
  return new Promise((resolve, reject) => {
    NetworkManager("post", "admin/auth/verifySMS", data)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              toast.error("⛔️ 인증번호가 틀립니다. ( 400 )");
              break;
            case 406:
              toast.error("⛔️ 정보가 맞지 않습니다. ( 406 )");
              break;
            default:
              break;
          }
        }
      });
  });
};

export default VerifySMS;
