// 액션!=
import createActions from '../controller/createActions';
import { toast } from 'react-toastify';
import { UserManager } from '../../actions';

const { onlyCreate } = createActions('sign');

export const actionPostSignIn = (data: any, onSuccess: Function, onFailure: Function) =>
  onlyCreate({
    params: data,
    url: 'auth/signIn',
    meta: {
      onSuccess: (res: any) => {
        UserManager().setUser({
          ...res.data.Data,
          ...res.data.Data.admin,
          isSignIn: true,
        });
        onSuccess();
      },
      onFailure: () => {
        toast.error('로그인에 실패했습니다.');
        onFailure();
      },
    },
  });
