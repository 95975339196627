import styled, { keyframes, css } from 'styled-components';
import * as color from '../../../color/index';
import { lighten, darken } from 'polished';

interface panelProps {
  view?: boolean;
}

const productpanel = keyframes`
  0% {
    opacity: 0;
    bottom: -24px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
`;

export const ProductPanel = styled.div<panelProps>`
  position: relative;
  width: 240px;
  flex-basis: 260px;
  flex-grow: 1;
  padding: 18px 20px;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  margin: 0 4px 8px;
  cursor: pointer;
  transition: 0.35s;
  color: ${color.Colors.primary};
  display: inline-block;
  animation: ${productpanel} 0.35s;
  h5 {
    color: ${lighten(0.2, `${color.Colors.primary}`)};
  }

  &:hover {
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.16);
    transition: 0.35s;
    background: ${darken(0.02, `${color.Colors.white}`)};
  }

  ${props => {
    if (props.view)
      return css`
        box-shadow: none;
        border-radius: 0;
        margin: 0;
        width: 100%;
        cursor: auto;
        animation: none;

        &:hover {
          box-shadow: none;
          transition: none;
          background: none;
        }
      `;
  }}
`;

export const PanelCircle = styled.div`
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
  border-radius: 32px;
  display: inline-block;
  margin-right: 4px;
`;

export const ProductPanelCircle = styled.div`
  position: relative;
  font-weight: normal;

  h5 {
    font-size: 20px;
  }
`;

export const ProductPanelPrice = styled.h4`
  position: relative;
  font-weight: normal;
  span {
    font-size: 20px;
  }
`;
