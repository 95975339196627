import React from 'react';
import * as Styled from './styles';
import './index.scss';
import GOButton from '../GOButton';

// MARK : GOInput : Input Text 컴포넌트
// NOTE : 앱 내의 1줄 짜리 Input 컴포넌트

interface GOInputProps {
  // Param
  // label : Input 에 들어갈 내용 설명
  // button : Input 오른쪽에 배치될 버튼
  // error : 오류 발생시 Input 색상 변경

  // Input의 기본요소
  // placeholder, name, onChange,rref(ref)

  label?: string;
  button?: any;
  type?: string;
  error?: any;
  unit?: string;
  textarea?: boolean;
  maxLength?: any;
  textAreaMax?: number;
  min?: any;
  max?: any;
  required?: boolean;
  disabled?: boolean;
  onClickButton?: any;
  onClick?: any;
  defaultValue?: string | number;
  value?: string | number | undefined | null;
  autoComplete?: string;
  style?: any;

  readOnly?: boolean;
  placeholder?: string;
  name?: string;
  rref?: any;
  onChange?: any;

  medium?: boolean;
  large?: boolean;
}

const GOInput = (props: GOInputProps) => {
  return (
    <>
      <Styled.Input>
        {props.label && <Styled.InputLabel>{props.label}</Styled.InputLabel>}
        {props.textarea ? (
          <Styled.TextArea
            name={props.name}
            onChange={props.onChange}
            error={props.error ? true : false}
            placeholder={props.placeholder}
            readOnly={props.readOnly}
            ref={props.rref}
            maxLength={props.textAreaMax}
            large={props.large}
            value={props.value === null ? '' : props.value}
            autoComplete="off"
          />
        ) : (
          <Styled.InputItem
            name={props.name}
            style={props.style}
            onChange={props.onChange}
            error={props.error ? true : false}
            placeholder={props.placeholder}
            type={props.type}
            date={props.type === 'date' ? true : false}
            time={props.type === 'time' ? true : false}
            datetime={props.type === 'datetime' ? true : false}
            medium={props.medium ? true : false}
            defaultValue={props.defaultValue}
            ref={props.rref}
            min={props.min}
            maxLength={props.maxLength}
            required={props.required}
            disabled={props.disabled}
            value={props.value === null ? '' : props.value}
            max={props.type === 'date' ? '2037-12-12' : props.max}
            autoComplete="off"
            onKeyDown={(e: any) => {
              if (props.onClickButton && e.keyCode === 13) {
                props.onClickButton();
              }
            }}
          />
        )}
        {props.unit && <Styled.InputUnit>{props.unit}</Styled.InputUnit>}
        {props.button && (
          <GOButton
            body={props.button}
            buttontype="primary"
            size="small"
            onClick={props.onClickButton}
          />
        )}
      </Styled.Input>
    </>
  );
};

export default GOInput;
