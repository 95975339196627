import React, { useState, useEffect } from 'react';
import * as Styled from './styles';
import {
  HeaderBottom,
  StatusTool,
  AccountingFilter,
  AccountingView,
  GOLayout,
} from '../../components';

import TotalBranchSummary from '../../components/accounting/PurchaseTable/TotalBranchSummary';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import {
  actionGetAccounting,
  actionGetBranchAccounting,
  actionGetBranchAccountingSummary,
  actionGetNewPurchaseUser,
  actionGetBranchNewPurchaseUser,
} from '../../stores/actions';
import { UserManager } from '../../actions';

const Accounting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    branchID: -1,
    type: 0,
    startDate: dayjs().format('YYYY-MM-DDT00:00:00'),
    endDate: dayjs().format('YYYY-MM-DDT23:59:59'),
    sex: 2,
    age: 0,
  });
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<object>({});
  const [summaryData, setSummaryData] = useState<any[]>([]);
  const [newPurchaseData, setNewPurchaseData] = useState<object>({});
  const [purchase, setPurchase] = useState<boolean>(false);

  const changeFilter = (type: number) => {
    switch (type) {
      case -2: // 저번주
        setFilter({
          ...filter,
          type,
          startDate: dayjs()
            .subtract(1, 'w')
            .day(1)
            .format('YYYY-MM-DDT00:00:00'),
          endDate: dayjs()
            .subtract(1, 'w')
            .day(7)
            .format('YYYY-MM-DDT23:59:59'),
        });
        break;
      case -1: // 어제
        setFilter({
          ...filter,
          type,
          startDate: dayjs()
            .subtract(1, 'd')
            .format('YYYY-MM-DDT00:00:00'),
          endDate: dayjs()
            .subtract(1, 'd')
            .format('YYYY-MM-DDT23:59:59'),
        });
        break;
      case 0: // 오늘
        setFilter({
          ...filter,
          type,
          startDate: dayjs().format('YYYY-MM-DDT00:00:00'),
          endDate: dayjs().format('YYYY-MM-DDT23:59:59'),
        });
        break;
      case 1: // 이번 주
        setFilter({
          ...filter,
          type,
          startDate: dayjs()
            .day(1)
            .format('YYYY-MM-DDT00:00:00'),
          endDate: dayjs()
            .day(7)
            .format('YYYY-MM-DDT23:59:59'),
        });
        break;
      case 2: // 이번 달
        setFilter({
          ...filter,
          type,
          startDate: dayjs()
            .startOf('month')
            .format('YYYY-MM-DDT00:00:00'),
          endDate: dayjs()
            .endOf('month')
            .format('YYYY-MM-DDT23:59:59'),
        });
        break;
      case 3: // 저번 달
        setFilter({
          ...filter,
          type,
          startDate: dayjs()
            .subtract(1, 'month')
            .startOf('month')
            .format('YYYY-MM-DDT00:00:00'),
          endDate: dayjs()
            .subtract(1, 'month')
            .endOf('month')
            .format('YYYY-MM-DDT23:59:59'),
        });
        break;
      default:
        break;
    }
  };

  const propsAll = {
    filter,
    setFilter,
    changeFilter,
    loading,
    data,
    newPurchaseData,
    purchase,
    setPurchase,
  };

  useEffect(() => {
    if (UserManager().getUser()?.branch.id === 1) {
      if (filter.branchID === 0) {
        setLoading(true);
        dispatch(
          actionGetBranchAccountingSummary(filter.startDate, filter.endDate, (res: any) => {
            setLoading(false);
            console.log(res.data.Data);
            setSummaryData(res.data.Data.data);
          }),
        );
      } else if (filter.branchID !== -1) {
        setLoading(true);
        dispatch(
          actionGetBranchAccounting(
            filter.startDate,
            filter.endDate,
            filter.sex,
            filter.age,
            filter.branchID,
            (res: any) => {
              setData(res.data.Data);
              dispatch(
                actionGetBranchNewPurchaseUser(
                  filter.startDate,
                  filter.endDate,
                  filter.sex,
                  filter.age,
                  filter.branchID,
                  (res: any) => {
                    setLoading(false);
                    setNewPurchaseData(res.data.Data);
                  },
                ),
              );
            },
          ),
        );
      } else {
        setLoading(true);
        dispatch(
          actionGetAccounting(
            filter.startDate,
            filter.endDate,
            filter.sex,
            filter.age,
            (res: any) => {
              setData(res.data.Data);
              dispatch(
                actionGetNewPurchaseUser(
                  filter.startDate,
                  filter.endDate,
                  filter.sex,
                  filter.age,
                  (res: any) => {
                    setLoading(false);
                    setNewPurchaseData(res.data.Data);
                  },
                ),
              );
            },
          ),
        );
      }
    } else {
      setLoading(true);
      dispatch(
        actionGetAccounting(
          filter.startDate,
          filter.endDate,
          filter.sex,
          filter.age,
          (res: any) => {
            setData(res.data.Data);
            dispatch(
              actionGetNewPurchaseUser(
                filter.startDate,
                filter.endDate,
                filter.sex,
                filter.age,
                (res: any) => {
                  setLoading(false);
                  setNewPurchaseData(res.data.Data);
                },
              ),
            );
          },
        ),
      );
    }
  }, [dispatch, filter]);

  return (
    <>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool
              link="/admin/employee/list"
              type="now"
              body={t('main-menu.finance_management')}
            />
          </>
        }
        rightmenu={<></>}
      />
      <AccountingFilter {...propsAll} />
      <GOLayout
        body={
          <>
            {filter && filter.branchID === 0 ? (
              <TotalBranchSummary saleSummary={summaryData} />
            ) : (
              <AccountingView {...propsAll} />
            )}
          </>
        }
      />
    </>
  );
};

export default Accounting;
