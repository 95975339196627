import NetworkManager from "../../../NetworkManager";

const Detail = (id: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager("get", `admin/product/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default Detail;
