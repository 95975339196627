import React, { useState, useEffect } from 'react';
import {
  HeaderBottom,
  StatusTool,
  GOButton,
  EmployeeAdd,
  EmployeeDetail,
  GOLoading,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import * as Styled from './styles';
import dayjs from 'dayjs';

// import timezone  from 'dayjs/plugin/timezone';
// import utc  from 'dayjs/plugin/utc'

import { useDispatch, useSelector } from 'react-redux';
import {
  actionGetDetailEmployee,
  actionGetDetailEmployeeLessons,
  // actionGetTrainingManagementLessonStat,
} from '../../../stores/actions';

interface ParamsType {
  id: string;
}

const Detail = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const [edit, setEdit] = useState<boolean>(false);
  const [update, isUpdate] = useState<boolean>(false);

  // dayjs.extend(utc);
  // dayjs.extend(timezone);

  // console.log('korean' + dayjs().tz("Asia/Seoul").format("YYYY-MM-DD hh:mm:ss"));
  // console.log('utc' + dayjs().tz('UTC').format("YYYY-MM-DD hh:mm:ss"));

  const [filter, setFilter] = useState<any>({
    total: false,
    attendance: false,
    reservation: false,
    absence: false,
  });

  //dayjs().get('month') + 1
  const [queryMonth, setQueryMonth] = useState<number>(dayjs().get('month'));
  const [queryYear, setQueryYear] = useState<number>(dayjs().get('year'));

  const [attendanceCount, setAttendanceCount] = useState<number>(0);
  const [reservationCount, setReservationCount] = useState<number>(0);
  const [absenceCount, setAbsenceCount] = useState<number>(0);

  //  console.log( dayjs().
  // feature/2021/1214iOSMultibranc

  const [startDate, setStartDate] = useState<any>(
    dayjs()
      .startOf('month')
      .subtract(9, 'hour')
      .format('YYYY-MM-DDT15:00:00'),
  );

  const [endDate, setEndDate] = useState<any>(
    dayjs()
      .endOf('month')
      .subtract(9, 'hour')
      .format('YYYY-MM-DDT15:00:00'),
  );

  const employeeStore = useSelector((state: any) => state.employee);
  // const employeeLessonListforMonth = employeeStore.data.lessons;
  const employeeDetailData = employeeStore.detail;

  const [montlyLessonData, setMonthlyLessonData] = useState([]);
  const [filteredMontlyLessonData, setFilteredMonthlyLessonData] = useState([]);

  // feature/2021/1214iOSMultibranc
  const onMonthChanged = (month: Number) => {
    setQueryMonth(month.valueOf());
    const theMonth = dayjs(`${queryYear}`).month(month.valueOf());

    setStartDate(
      theMonth
        .startOf('month')
        .subtract(9, 'hour')
        .format('YYYY-MM-DDT15:00:00'),
    );
    setEndDate(
      theMonth
        .endOf('month')
        .subtract(9, 'hour')
        .format('YYYY-MM-DDT15:00:00'),
    );
  };

  const onYearChanged = (year: Number) => {
    setQueryYear(year.valueOf());
    const theMonth = dayjs(`${year}`).month(queryMonth.valueOf());

    setStartDate(
      theMonth
        .startOf('month')
        .subtract(9, 'hour')
        .format('YYYY-MM-DDT15:00:00'),
    );
    setEndDate(
      theMonth
        .endOf('month')
        .subtract(9, 'hour')
        .format('YYYY-MM-DDT15:00:00'),
    );
  };

  const onFilterChanged = (e: any) => {
    const name = e.target.name;
    const isChecked = e.target.checked;

    let newFilter = { total: false, attendance: false, reservation: false, absence: false };

    let laterFilter = { ...filter };
    if (name === 'total') {
      if (isChecked === true) {
        newFilter.total = true;
        laterFilter = { ...newFilter };
        setFilter(newFilter);
      } else {
        setFilter(newFilter);
        laterFilter = { ...newFilter };
      }
    } else if (name === 'attendance' || name === 'reservation' || name === 'absence') {
      if (isChecked === true) {
        setFilter({
          ...filter,
          [name]: e.target.checked,
          total: false,
        });
        laterFilter = { ...filter, [name]: e.target.checked, total: false };
      } else {
        setFilter({
          ...filter,
          [name]: e.target.checked,
        });
        laterFilter = { ...filter, [name]: e.target.checked };
      }
    }

    setFilteredMonthlyLessonData(
      montlyLessonData.filter((item: any) => {
        if (laterFilter.total === true) {
          return true;
        }

        if (laterFilter.reservation === true) {
          if (item.status === 0) {
            return true;
          }
        }

        if (laterFilter.attendance === true) {
          if (item.status === 1) {
            return true;
          }
        }

        if (laterFilter.absence === true) {
          if (item.status === 2) {
            return true;
          }
        }

        return (
          !laterFilter.total &&
          !laterFilter.reservation &&
          !laterFilter.attendance &&
          !laterFilter.absence
        );
      }),
    );
  };

  const params = useParams<ParamsType>();

  useEffect(() => {
    dispatch(actionGetDetailEmployee(params.id));
  }, [update, params.id, dispatch]); //queryMonth, Iron, endDate, dispatch]);

  useEffect(() => {
    dispatch(
      actionGetDetailEmployeeLessons(params.id, startDate, endDate, (res: any) => {
        try {
          let attendanceCount = 0;
          let reservationCount = 0;
          let absenceCount = 0;

          setMonthlyLessonData(
            res.data.Data.lessons.filter((item: any) => {
              if (item.status !== undefined) {
                if (item.status === 0) {
                  reservationCount = reservationCount + 1;
                } else if (item.status === 1) {
                  attendanceCount = attendanceCount + 1;
                } else if (item.status === 2) {
                  absenceCount = absenceCount + 1;
                }
              }
              return true;
            }),
          );

          setAttendanceCount(attendanceCount);
          setReservationCount(reservationCount);
          setAbsenceCount(absenceCount);

          setFilteredMonthlyLessonData(
            res.data.Data.lessons.filter((item: any) => {
              if (filter.total === true) {
                return true;
              }

              if (filter.reservation === true) {
                if (item.status === 0) {
                  return true;
                }
              }

              if (filter.attendance === true) {
                if (item.status === 1) {
                  return true;
                }
              }

              if (filter.absence === true) {
                if (item.status === 2) {
                  return true;
                }
              }

              return !filter.total && !filter.reservation && !filter.attendance && !filter.absence;
            }),
          );
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [params.id, startDate, endDate, dispatch]);

  return (
    <Styled.PageStyles>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool link="/admin/employee/list" type="prev" body={t('employee.list')} />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={employeeDetailData?.employee?.name} />
          </>
        }
        rightmenu={
          <>
            <GOButton
              body="회원 관리"
              buttontype={employeeDetailData.employee?.type === 2 ? `green` : `disabled`}
              onClick={() => {
                history.push({
                  pathname: `/admin/employee/${employeeDetailData.employee.id}/userManaged`,
                  state: {
                    data: employeeDetailData,
                    employeeName: employeeDetailData?.employee?.name,
                    employeeID: employeeDetailData.employee.id,
                  },
                });
              }}
              disabled={employeeDetailData.employee?.type === 2 ? false : true}
            />
            <GOButton
              body={t('employee.scheduleAdd')}
              onClick={() => {
                history.push({
                  pathname: `/admin/employee/${employeeDetailData.employee.id}/scheduleManaged`,
                  state: {
                    data: employeeDetailData,
                    employeeName: employeeDetailData?.employee?.name,
                    employeeID: employeeDetailData.employee.id,
                  },
                });
              }}
            />
            <GOButton
              body={t('employee.edit')}
              onClick={() => {
                setEdit(true);
              }}
            />
            <GOButton
              body="레슨 포스트"
              onClick={() => {
                history.push({
                  pathname: `/admin/employee/${employeeDetailData.employee.id}/lessonLogs`,
                  state: {
                    data: employeeDetailData,
                    employeeName: employeeDetailData?.employee?.name,
                    employeeID: employeeDetailData.employee.id,
                  },
                });
              }}
            />
          </>
        }
      />
      {employeeDetailData ? (
        <EmployeeDetail
          employeeDetailData={employeeDetailData}
          userListData={filteredMontlyLessonData}
          onMonthChanged={onMonthChanged}
          onYearChanged={onYearChanged}
          queryMonth={queryMonth}
          queryYear={queryYear}
          filter={filter}
          onFilterChanged={onFilterChanged}
          attendanceCount={attendanceCount}
          reservationCount={reservationCount}
          absenceCount={absenceCount}
        />
      ) : (
        <GOLoading />
      )}
      {edit && (
        <EmployeeAdd
          data={employeeDetailData.employee}
          detailData={employeeDetailData}
          onClickExit={() => {
            setEdit(false);
          }}
          update={() => {
            isUpdate(!update);
          }}
        />
      )}
    </Styled.PageStyles>
  );
};

export default Detail;
