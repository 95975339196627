import styled from 'styled-components';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const Container = styled.div`
  padding: 10px 0;
  background: #fff;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.04);
  .wrap {
    justify-content: space-between;
  }
  ${BreakPoints.md} {
    .wrap {
      flex-wrap: wrap;
    }
  }
`;

export const HeaderGroup = styled.div`
  display: inline-flex;
  justify-content: space-between;
  ${BreakPoints.md} {
    width: 100%;
    margin-top: 10px;
    flex-wrap: wrap;
  }
`;

export const HeaderStatus = styled.div`
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const HeaderSearchBar = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  ${BreakPoints.md} {
    justify-content: flex-start;
  }
`;

export const HeaderRightMenu = styled.div`
  text-align: right;
  ${BreakPoints.md} {
    width: 100%;
    display: flex;

    &:not(:empty) {
      margin-top: 10px;
    }

    button {
      flex: 1 1 auto;
      width: 25%;
      &:first-child {
        margin-left: 0 !important;
      }
      ${BreakPoints.sm} {
        width: 20%;
        padding: 0;
      }
    }
  }

  ${BreakPoints.sm} {
    button:not(:first-child) {
      margin-left: 5px !important;
    }
  }
`;
