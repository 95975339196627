// 액션!=
import createActions from '../controller/createActions';

const { request, onlyRequest, read } = createActions('locker');

export const actionGetLockerList = () => read({});

export const actionPostLocker = (number: string | number, onSuccess?: Function) =>
  request({
    type: 'post',
    params: { number },
    meta: { onSuccess },
  });

export const actionPutLocker = (lockerID: string | number, params: object, onSuccess?: Function) =>
  onlyRequest({
    type: 'put',
    url: `locker/edit/${lockerID}`,
    params,
    meta: { onSuccess, read: 'locker' },
  });

export const actionDeleteLocker = (lockerID: string | number, onSuccess?: Function) =>
  onlyRequest({
    type: 'delete',
    url: `locker/${lockerID}`,
    meta: { onSuccess, read: 'locker' },
  });

export const actionPutLockerRelease = (lockerID: string | number, onSuccess?: Function) =>
  onlyRequest({
    type: 'put',
    url: `locker/${lockerID}/release`,
    meta: { onSuccess, read: 'locker' },
  });

export const actionPutLockerAssign = (
  lockerID: string | number,
  params: object,
  onSuccess?: Function,
) =>
  onlyRequest({
    type: 'put',
    url: `locker/${lockerID}/assign`,
    params,
    meta: { onSuccess, read: 'locker' },
  });
