import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { offModal } from '../../stores/modals';
import UserAddModal from '../user/Add';
import UserEditModal from '../user/Edit';
import UserTicketEditModal from '../user/TicketEdit';
import UserPaymentUnpaid from '../user/Unpaid';
import Memo from '../memo';
import Push from '../user/Push';
import SMS from '../user/SMS';
import AddModal from '../plates/Add';
import AddProductCategory from '../product/AddCategory';
import AddProduct from '../product/Add';
import AddLocker from '../locker/Add';
import EditLocker from '../locker/Edit';
import EmployeeEdit from '../employee/Edit';
import LessonTime from '../employee/LessonTime';
import SmartAdvisorPassword from '../smartAdvisor/password';
import AccountingPassword from '../accounting/password';
import EmployeePassword from '../employee/password';
import EmployeeDetailPassword from '../employee/DetailPassword';
import SettingsPassword from '../settings/password';
import ChangeSecretPin from '../changePinNumber';
import ChangePassword from '../changePassword'; //feature/20211104/changePwd
import LessonTicketHistory from '../ticketHistory/LessonTicketHistory';

import { useLocation } from 'react-router-dom';

const Root = () => {
  const modal = useSelector((state: any) => state.ModalReducer);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(offModal());
  }, [dispatch, location]);

  switch (modal.type) {
    // Product 상품
    case 'productAddCategory':
      return <AddProductCategory data={modal.data} onClickExit={() => dispatch(offModal())} />;
    case 'productAdd':
      return <AddProduct data={modal.data} onClickExit={() => dispatch(offModal())} />;
    // 타석 관리
    case 'plateAdd':
      return <AddModal data={modal.data} onClickExit={() => dispatch(offModal())} />;
    // 락커 관리
    case 'lockerAdd':
      return <AddLocker onClickExit={() => dispatch(offModal())} />;
    case 'lockerEdit':
      return <EditLocker lockerData={modal.data} onClickExit={() => dispatch(offModal())} />;
    // 회원 관리
    case 'userAdd':
      return <UserAddModal onClickExit={() => dispatch(offModal())} />;
    case 'userEdit':
      return <UserEditModal data={modal.data} onClickExit={() => dispatch(offModal())} />;
    case 'userTicketEdit':
      return <UserTicketEditModal data={modal.data} onClickExit={() => dispatch(offModal())} />;
    case 'userPaymentRefund':
      return <UserPaymentUnpaid data={modal.data} onClickExit={() => dispatch(offModal())} />;
    case 'memo':
      return <Memo data={modal.data} onClickExit={() => dispatch(offModal())} />;
    case 'userPush':
      return <Push data={modal.data} onClickExit={() => dispatch(offModal())} />;
    case 'userSMS':
      return <SMS data={modal.data} onClickExit={() => dispatch(offModal())} />;
    // 직원 관리
    case 'lessonTime':
      return <LessonTime data={modal.data} onClickExit={() => dispatch(offModal())} />;
    case 'employeeEdit':
      return <EmployeeEdit data={modal.data} onClickExit={() => dispatch(offModal())} />;
    // 스마트 어드비아저
    case 'smartadvisorpassword':
      return <SmartAdvisorPassword data={modal.data} onClickExit={() => dispatch(offModal())} />;
    // 패스워드 체크 모달
    case 'accountingpassword':
      return <AccountingPassword data={modal.data} onClickExit={() => dispatch(offModal())} />;
    case 'employeepassword':
      return <EmployeePassword data={modal.data} onClickExit={() => dispatch(offModal())} />;
    case 'employeeDetailPassword':
      return <EmployeeDetailPassword data={modal.data} onClickExit={() => dispatch(offModal())} />;
    case 'settingsPassword':
      return <SettingsPassword data={modal.data} onClickExit={() => dispatch(offModal())} />;
    case 'changeSecretPin':
      return <ChangeSecretPin data={modal.data} onClickExit={() => dispatch(offModal())} />;
    case 'changePassword':
      return <ChangePassword data={modal.data} onClickExit={() => dispatch(offModal())} />;
    case 'lessonTicketHistory':
      return <LessonTicketHistory data={modal.data} onClickExit={() => dispatch(offModal())} />;
    default:
      return <></>;
  }
};

export default Root;
