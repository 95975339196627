import React, { useState, useEffect } from 'react';
import { NetSmartAdvisorMonthlyMemberConfiguration } from '../../../actions';
import MonthlyUserChart from '../../../components/smartadvisor/MonthlyUser';

type MonthlyMemberConfigurationProps = {
  yearFilter?: number;
};

const MonthlyMemberConfiguration = ({ yearFilter }: MonthlyMemberConfigurationProps) => {
  const [sex, setSex] = useState();
  const [age, setAge] = useState();
  const [loading, setLoading] = useState<boolean>(true);

  const queryYear = !!yearFilter ? yearFilter : new Date().getFullYear();

  useEffect(() => {
    const fetchAPI = async () => {
      await NetSmartAdvisorMonthlyMemberConfiguration('sex', queryYear).then((res: any) => {
        console.log(res);
        setSex(res.data.Data.memberList);
      });
      await NetSmartAdvisorMonthlyMemberConfiguration('age', queryYear).then((res: any) => {
        setAge(res.data.Data.memberList);
        setLoading(false);
      });
    };
    console.log('💠 월별 활성회원 Network');
    fetchAPI();
  }, [queryYear]);
  return <MonthlyUserChart year={queryYear} sex={sex} age={age} loading={loading} />;
};

export default MonthlyMemberConfiguration;
