import React, { useEffect, useState } from 'react';
import GOLoading from '../../common/GOLoading';
import YearlySalesCategory from '../Convert/YearlySales/category';
import YearlySalesTickets from '../Convert/YearlySales/tickets';
import YearlySalesSex from '../Convert/YearlySales/sex';
import YearlySalesAge from '../Convert/YearlySales/age';
import CategoryBarChart from '../Chart/YearlySales/CategoryBar';
import TicketsBarChart from '../Chart/YearlySales/TicketsBar';
import GenderBarChart from '../Chart/YearlySales/genderBar';
import AgeBarChart from '../Chart/YearlySales/AgeBar';
import * as Styled from '../chartAreaStyles';

const months = [
  '1월',
  '2월',
  '3월',
  '4월',
  '5월',
  '6월',
  '7월',
  '8월',
  '9월',
  '10월',
  '11월',
  '12월',
];

const YearlySalesChart = ({ loading, year, category, tickets, sex, age }: any) => {
  const [categoryData, setCategoryDate] = useState<any>({
    labels: months,
    datasets: [
      {
        label: '신규',
        backgroundColor: '#7F7ABD',
        data: [],
      },
      {
        label: '재구매',
        backgroundColor: '#DCDAFF',
        data: [],
      },
      {
        label: '환불',
        backgroundColor: '#767676',
        data: [],
      },
    ],
  });

  useEffect(() => {
    const cData = YearlySalesCategory(category);
    const categoryData = {
      labels: months,
      datasets: [
        {
          label: '신규',
          backgroundColor: '#7F7ABD',
          data: cData.newPurchase,
        },
        {
          label: '재구매',
          backgroundColor: '#DCDAFF',
          data: cData.rePurchase,
        },
        {
          label: '환불',
          backgroundColor: '#767676',
          data: cData.refund,
        },
      ],
    };
    setCategoryDate(categoryData);
  }, [category]);

  const [ticketData, setTicketData] = useState<any>({
    labels: months,
    datasets: [
      {
        label: '레슨',
        backgroundColor: '#D2A9D6',
        data: [],
      },
      {
        label: '타석',
        backgroundColor: '#9395E7',
        data: [],
      },
      {
        label: '기타',
        backgroundColor: '#464280',
        data: [],
      },
    ],
  });

  useEffect(() => {
    const tData = YearlySalesTickets(tickets);
    const ticketData = {
      labels: months,
      datasets: [
        {
          label: '레슨',
          backgroundColor: '#D2A9D6',
          data: tData.lesson,
        },
        {
          label: '타석',
          backgroundColor: '#9395E7',
          data: tData.plate,
        },
        {
          label: '기타',
          backgroundColor: '#464280',
          data: tData.etc,
        },
      ],
    };
    setTicketData(ticketData);
  }, [tickets]);

  const [genderData, setGenderData] = useState<any>({
    labels: months,
    datasets: [
      {
        label: '남성',
        backgroundColor: '#6AA8E2',
        data: [],
      },
      {
        label: '여성',
        backgroundColor: '#F5C4DE',
        data: [],
      },
    ],
  });

  useEffect(() => {
    const sData = YearlySalesSex(sex);
    const genderData = {
      labels: months,
      datasets: [
        {
          label: '남성',
          backgroundColor: '#6AA8E2',
          data: sData.male,
        },
        {
          label: '여성',
          backgroundColor: '#F5C4DE',
          data: sData.female,
        },
      ],
    };
    setGenderData(genderData);
  }, [sex]);

  const [ageData, setAgeData] = useState<any>({
    labels: months,
    datasets: [
      {
        label: '20대 미만',
        backgroundColor: '#D196EE',
        data: [],
      },
      {
        label: '30대',
        backgroundColor: '#7465E7',
        data: [],
      },
      {
        label: '40대',
        backgroundColor: '#2F76BA',
        data: [],
      },
      {
        label: '50대',
        backgroundColor: '#204D80',
        data: [],
      },
      {
        label: '60대 이상',
        backgroundColor: '#173562',
        data: [],
      },
    ],
  });

  useEffect(() => {
    const aData = YearlySalesAge(age);
    const ageData = {
      labels: months,
      datasets: [
        {
          label: '20대 미만',
          backgroundColor: '#D196EE',
          data: aData.amount20s,
        },
        {
          label: '30대',
          backgroundColor: '#7465E7',
          data: aData.amount30s,
        },
        {
          label: '40대',
          backgroundColor: '#2F76BA',
          data: aData.amount40s,
        },
        {
          label: '50대',
          backgroundColor: '#204D80',
          data: aData.amount50s,
        },
        {
          label: '60대 이상',
          backgroundColor: '#173562',
          data: aData.amount60s,
        },
      ],
    };
    setAgeData(ageData);
  }, [age]);

  return (
    <Styled.Container>
      {loading ? (
        <GOLoading />
      ) : category && tickets && sex && age ? (
        <>
          <CategoryBarChart label={`${year}_구매종류별`} data={categoryData} />
          <TicketsBarChart label={`${year}_이용권별`} data={ticketData} />
          <GenderBarChart label={`${year}_성별`} data={genderData} />
          <AgeBarChart label={`${year}_연령별`} data={ageData} />
        </>
      ) : (
        <div>데이터가 부족합니다!</div>
      )}
    </Styled.Container>
  );
};

export default YearlySalesChart;
