import NetworkManager from '../../../NetworkManager';

const UserManage = (id: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager('get', `admin/employee/detail/tickets/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default UserManage;
