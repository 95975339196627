import React, { useState, useEffect } from 'react';
import { SmallTitle, EmployeeLessonDetail } from '../../../components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as Styled from './styles';
import dayjs from 'dayjs';
import { NetLessonReport } from '../../../actions';
import { phoneNumberFormatting } from '../../../middlewares';

interface ParamsType {
  id: string;
}

const LessonList = ({ data }: any) => {
  const { t } = useTranslation();

  const [viewLessonDetail, setViewLessonDetail] = useState(false);
  const [update, isUpdate] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [userReportData, setUserReportData] = useState<any[]>([]);
  const [selected, setSelected] = useState<any>('');
  const [selectUserData, setSelectUserData] = useState<any>({});
  const [selectModalData, setSelectModalData] = useState<any>({});

  const params = useParams<ParamsType>();

  const birthday = (birth: any) => {
    const today = new Date().getFullYear();
    const myBirth = new Date(birth).getFullYear();
    return today - myBirth;
  };

  const selectLessonDetailData = (lessonList: any) => {
    NetLessonReport(lessonList.id, params.id).then((res: any) => {
      try {
        const sortedLessonLogs = res.data.Data.lessonLogs.slice().sort((a: any, b: any) => {
          let dateA = new Date(a['createdAt']).getTime();
          let dateB = new Date(b['createdAt']).getTime();
          return dateB - dateA;
        });
        setUserReportData(sortedLessonLogs);
      } catch (e) {
        setUserReportData(res.data.Data.lessonLogs);
      }
    });
  };

  useEffect(() => {
    if (update) {
      NetLessonReport(selectUserData.id, params.id).then((res: any) => {
        try {
          const sortedLessonLogs = res.data.Data.lessonLogs.slice().sort((a: any, b: any) => {
            let dateA = new Date(a['createdAt']).getTime();
            let dateB = new Date(b['createdAt']).getTime();
            return dateB - dateA;
          });
          setUserReportData(sortedLessonLogs);
        } catch (e) {
          setUserReportData(res.data.Data.lessonLogs);
        }
        isUpdate(!update);
      });
    }
  }, [update, params.id, selectUserData.id]);

  function dedupe(arr: any) {
    return (
      arr &&
      arr.reduce(
        function(p: any, c: any) {
          let id = [c.id, c.name].join('|');
          if (p.temp.indexOf(id) === -1) {
            p.out.push(c);
            p.temp.push(id);
          }
          return p;
        },
        {
          temp: [],
          out: [],
        },
      ).out
    );
  }

  return (
    <>
      <Styled.Layout>
        <Styled.Box>
          <SmallTitle body={t('employee.userList')} />

          {dedupe(data)?.length > 0 ? (
            <div>
              {dedupe(data).map((lessonList: any, index: number) => (
                <React.Fragment key={index}>
                  <Styled.Panel
                    style={{
                      backgroundColor: selected === index ? '#495057' : 'white',
                      color: selected === index ? 'white' : '#495057',
                    }}
                    onClick={() => {
                      setSelected(index);
                      setLoading(true);
                      selectLessonDetailData(lessonList);
                      setSelectUserData(lessonList);
                    }}
                  >
                    <Styled.PanelTop>
                      <Styled.PanelCircle
                        style={{
                          background: lessonList.profileImageURL
                            ? `url(${lessonList.profileImageURL}) center / cover`
                            : '#eee',
                        }}
                      >
                        {/* <Styled.PanelAvatar
                        src="/images/arrow_primary.png"
                        alt=">"
                        width="7"
                        height="14"
                        style={{
                          marginLeft: '355px',
                          marginTop: '15px',
                        }}
                      /> */}
                      </Styled.PanelCircle>
                      <Styled.PanelInfo>
                        <Styled.PanelName>{lessonList.name}</Styled.PanelName>
                        <Styled.PanelDetail>
                          {birthday(lessonList.dateOfBirth)}세 /{' '}
                          {lessonList.sex === 0 ? '남' : '여'} /{' '}
                          {phoneNumberFormatting(lessonList.phoneNumber)}{' '}
                        </Styled.PanelDetail>
                      </Styled.PanelInfo>
                      <Styled.IconBox>
                        {selected === index ? (
                          <img src="/images/white_arrow.png" alt="whiteArrow" />
                        ) : (
                          <img src="/images/primary_arrow.png" alt="primaryArrow" />
                        )}
                      </Styled.IconBox>
                    </Styled.PanelTop>
                  </Styled.Panel>
                </React.Fragment>
              ))}
            </div>
          ) : (
            <>
              <Styled.Text>레슨 회원이 없습니다.</Styled.Text>
            </>
          )}
        </Styled.Box>
        <Styled.Box>
          {loading ? (
            <>
              <SmallTitle body={t('employee.lessonInfo') + '(레슨 시작시간)'} />
              <div>
                {userReportData.map((lessonLog: any, index: number) => (
                  <React.Fragment key={index}>
                    <Styled.LessonDetailList>
                      {/*{dayjs(lessonLog.createdAt).format('YYYY.MM.DD HH:mm:ss')}*/}
                      {dayjs(lessonLog.createdAt).format('YYYY.MM.DD HH:mm')}
                      {lessonLog.isConfirmed ? (
                        <Styled.ConfirmText
                          onClick={() => {
                            setSelectModalData({ lessonLog });
                            setViewLessonDetail(true);
                          }}
                        >
                          승인 완료
                        </Styled.ConfirmText>
                      ) : (
                          <Styled.NonConfirmText
                            onClick={() => {
                              setSelectModalData({ lessonLog });
                              setViewLessonDetail(true);
                            }}
                          >
                            승인 대기중
                          </Styled.NonConfirmText>
                        ) && lessonLog.isConfirmed === null ? (
                        <>
                          <Styled.PendingText>미작성</Styled.PendingText>
                        </>
                      ) : (
                        <>
                          <Styled.NonConfirmText
                            onClick={() => {
                              setSelectModalData({ lessonLog });
                              setViewLessonDetail(true);
                            }}
                          >
                            승인 대기중
                          </Styled.NonConfirmText>
                        </>
                      )}
                    </Styled.LessonDetailList>
                    <hr />
                  </React.Fragment>
                ))}
              </div>
            </>
          ) : (
            <>
              <SmallTitle body={t('employee.lessonInfo')} />
              <Styled.Text>먼저 회원을 선택해주세요.</Styled.Text>
            </>
          )}
        </Styled.Box>
      </Styled.Layout>
      {viewLessonDetail && (
        <EmployeeLessonDetail
          onClickExit={() => {
            setViewLessonDetail(false);
          }}
          data={selectModalData}
          update={() => {
            isUpdate(!update);
          }}
        />
      )}
    </>
  );
};

export default LessonList;
