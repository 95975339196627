import styled, { css } from 'styled-components';
import * as color from '../../../color/index';

interface backgroundProps {
  bg?: boolean;
}

export const Switch = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0;
  height: 40px;
  width: 100%;
`;

export const SwitchLabel = styled.div`
  word-break: keep-all;
  flex-shrink: 0;
  width: 80px;
  font-size: 14px;
  font-weight: bold;
`;

export const SwitchBackground = styled.div<backgroundProps>`
  background: ${color.Colors.subEffect};
  width: 60px;
  height: 36px;
  border-radius: 50px;
  box-shadow: inset 0 3px 10px rgba(0, 0, 0, 0.08);
  transition: 0.35s;
  cursor: pointer;

  ${props => {
    if (props.bg)
      return css`
        background: #12b886;
        transition: 0.35s;
      `;
  }}
`;

export const SwitchItem = styled.div<backgroundProps>`
  position: relative;
  left: 6px;
  top: 6px;
  width: 24px;
  height: 24px;
  border-radius: 32px;
  background: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
  transition: 0.35s;

  ${props => {
    if (props.bg)
      return css`
        left: 30px;
      `;
  }}
`;
