import React from 'react';
import * as Styled from './../reservationGridFilterStyles';
import GODate from '../../common/GODate';
import { GOFilterContainer } from '../../../components';

interface LessonReservationStatusTimeProps {
  date: any;
  goLeft: any;
  goRight: any;
  goDate?: any;
}

// 개인수업예약자 - 그리드 필터 영역
const LessonReservationStatusTime = (props: LessonReservationStatusTimeProps) => {
  return (
    <GOFilterContainer
      leftbody={
        <Styled.LeftControlCustomStyles>
          {/*<span>{dayjs(props.date.endDate).format('MM. DD')}</span>*/}
          <Styled.PeriodGroup className="input_margin_wrapper">
            <div className="arrow_btn left" onClick={props.goLeft} />
            <GODate
              onChange={(date: any) => {
                props.goDate(date);
              }}
              selected={new Date(props.date.endDate)}
              // required
            />
            <div className="arrow_btn right" onClick={props.goRight} />
          </Styled.PeriodGroup>
        </Styled.LeftControlCustomStyles>
      }
    />
  );
};

export default LessonReservationStatusTime;
