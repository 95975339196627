import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { GOFloatingbox, GOButton, GODate, GOSwitch } from '../../../components';
import { actionPutEmployeeLessonTime } from '../../../stores/actions';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

interface LessonTimeProps {
  data: any;
  onClickExit: () => void;
}

const LessonTime = (props: LessonTimeProps) => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>(props.data.lessonTimes);
  const [sending, setSending] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    setSending(true);
    let error = false;
    let error1 = false;
    let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    days.forEach(days => {
      if (data[days + 'Start']) {
        if (data[days + 'Start'] >= data[days + 'End']) {
          error1 = true;
        } else if (!data[days + 'End']) {
          error = true;
        }
      } else if (data[days + 'End']) {
        if (!data[days + 'Start']) {
          error = true;
        }
      }
    });

    if (error) {
      alert('하루에 시작시간과 끝시간이 모두 있어야 합니다.');
      setSending(false);
    } else if (error1) {
      alert('시작시간이 종료시간보다 클거나 같을 수 없습니다.');
      setSending(false);
    } else {
      dispatch(
        actionPutEmployeeLessonTime(props.data.employee.id, data, () => {
          props.onClickExit();
        }),
      );
    }
  };

  return (
    <GOFloatingbox>
      <UFloatingContainer>
        <UFloatingBoxHeader>
          <h1>{t('employee.scheduleAdd')}</h1>
          <p>{t('employee.scheduleAddInfo')}</p>
        </UFloatingBoxHeader>
        <UFloatingBoxBody>
          <div className="flex_box">
            <GOSwitch
              label={t('common.monday')}
              status={data.mondayStart}
              onClick={() => setData({ ...data, mondayStart: null, mondayEnd: null })}
            />
            <GODate
              label={t('employee.startTime')}
              onChange={(d: any) => setData({ ...data, mondayStart: d })}
              time
              timeOnly
              placeholder="눌러서 설정하세요"
              dataFormat="aa h:mm"
              selected={data.mondayStart && new Date(data.mondayStart)}
            />
            <GODate
              label={t('employee.endTime')}
              onChange={(d: any) => setData({ ...data, mondayEnd: d })}
              time
              timeOnly
              placeholder="눌러서 설정하세요"
              dataFormat="aa h:mm"
              selected={data.mondayEnd && new Date(data.mondayEnd)}
            />
            <hr />
            <GOSwitch
              label={t('common.tuesday')}
              status={data.tuesdayStart}
              onClick={() =>
                setData({
                  ...data,
                  tuesdayStart: null,
                  tuesdayEnd: null,
                })
              }
            />
            <GODate
              label={t('employee.startTime')}
              onChange={(d: any) => setData({ ...data, tuesdayStart: d })}
              time
              timeOnly
              placeholder="눌러서 설정하세요"
              dataFormat="aa h:mm"
              selected={data.tuesdayStart && new Date(data.tuesdayStart)}
            />
            <GODate
              label={t('employee.endTime')}
              onChange={(d: any) => setData({ ...data, tuesdayEnd: d })}
              time
              timeOnly
              placeholder="눌러서 설정하세요"
              dataFormat="aa h:mm"
              selected={data.tuesdayEnd && new Date(data.tuesdayEnd)}
            />
            <hr />
            <GOSwitch
              label={t('common.wednesday')}
              status={data.wednesdayStart}
              onClick={() =>
                setData({
                  ...data,
                  wednesdayStart: null,
                  wednesdayEnd: null,
                })
              }
            />
            <GODate
              label={t('employee.startTime')}
              onChange={(d: any) => setData({ ...data, wednesdayStart: d })}
              time
              timeOnly
              placeholder="눌러서 설정하세요"
              dataFormat="aa h:mm"
              selected={data.wednesdayStart && new Date(data.wednesdayStart)}
            />
            <GODate
              label={t('employee.endTime')}
              onChange={(d: any) => setData({ ...data, wednesdayEnd: d })}
              time
              timeOnly
              placeholder="눌러서 설정하세요"
              dataFormat="aa h:mm"
              selected={data.wednesdayEnd && new Date(data.wednesdayEnd)}
            />
          </div>
          <div className="flex_box">
            <GOSwitch
              label={t('common.thursday')}
              status={data.thursdayStart}
              onClick={() =>
                setData({
                  ...data,
                  thursdayStart: null,
                  thursdayEnd: null,
                })
              }
            />
            <GODate
              label={t('employee.startTime')}
              onChange={(d: any) => setData({ ...data, thursdayStart: d })}
              time
              timeOnly
              placeholder="눌러서 설정하세요"
              dataFormat="aa h:mm"
              selected={data.thursdayStart && new Date(data.thursdayStart)}
            />
            <GODate
              label={t('employee.endTime')}
              onChange={(d: any) => setData({ ...data, thursdayEnd: d })}
              time
              timeOnly
              placeholder="눌러서 설정하세요"
              dataFormat="aa h:mm"
              selected={data.thursdayEnd && new Date(data.thursdayEnd)}
            />
            <hr />
            <GOSwitch
              label={t('common.friday')}
              status={data.fridayStart}
              onClick={() =>
                setData({
                  ...data,
                  fridayStart: null,
                  fridayEnd: null,
                })
              }
            />
            <GODate
              label={t('employee.startTime')}
              onChange={(d: any) => setData({ ...data, fridayStart: d })}
              time
              timeOnly
              placeholder="눌러서 설정하세요"
              dataFormat="aa h:mm"
              selected={data.fridayStart && new Date(data.fridayStart)}
            />
            <GODate
              label={t('employee.endTime')}
              onChange={(d: any) => setData({ ...data, fridayEnd: d })}
              time
              timeOnly
              placeholder="눌러서 설정하세요"
              dataFormat="aa h:mm"
              selected={data.fridayEnd && new Date(data.fridayEnd)}
            />
          </div>
          <div className="flex_box">
            <GOSwitch
              label={t('common.saturday')}
              status={data.saturdayStart}
              onClick={() =>
                setData({
                  ...data,
                  saturdayStart: null,
                  saturdayEnd: null,
                })
              }
            />
            <GODate
              label={t('employee.startTime')}
              onChange={(d: any) => setData({ ...data, saturdayStart: d })}
              time
              timeOnly
              placeholder="눌러서 설정하세요"
              dataFormat="aa h:mm"
              selected={data.saturdayStart && new Date(data.saturdayStart)}
            />
            <GODate
              label={t('employee.endTime')}
              onChange={(d: any) => setData({ ...data, saturdayEnd: d })}
              time
              timeOnly
              placeholder="눌러서 설정하세요"
              dataFormat="aa h:mm"
              selected={data.saturdayEnd && new Date(data.saturdayEnd)}
            />
            <hr />
            <GOSwitch
              label={t('common.sunday')}
              status={data.sundayStart}
              onClick={() =>
                setData({
                  ...data,
                  sundayStart: null,
                  sundayEnd: null,
                })
              }
            />
            <GODate
              label={t('employee.startTime')}
              onChange={(d: any) => setData({ ...data, sundayStart: d })}
              time
              timeOnly
              placeholder="눌러서 설정하세요"
              dataFormat="aa h:mm"
              selected={data.sundayStart && new Date(data.sundayStart)}
            />
            <GODate
              label={t('employee.endTime')}
              onChange={(d: any) => setData({ ...data, sundayEnd: d })}
              time
              timeOnly
              placeholder="눌러서 설정하세요"
              dataFormat="aa h:mm"
              selected={data.sundayEnd && new Date(data.sundayEnd)}
            />
          </div>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton
            buttontype="green"
            body={t('employee.scheduleAdd')}
            loading={sending}
            onClick={handleSubmit}
          />
          <GOButton body={t('common.no')} onClick={props.onClickExit} />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};
export default LessonTime;
