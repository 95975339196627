import NetworkManager from '../../../NetworkManager';

const GolfPowerMontlyExamStatus = (yearMonth: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager('get', `admin/smartAdvisor/golfPowerMonthlyExam?yearMonth=${yearMonth}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

//
// export default CurrentMonthAccounting;
export default GolfPowerMontlyExamStatus;
