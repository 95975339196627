import React from 'react';
import './index.scss';
import { NavLink } from 'react-router-dom';

// MARK : SegmentControl - 탭으로 나눠야 하는 페이지
// params : data에 배열을 통해 각 탭을 설정합니다.
// 각 배열에는 { link : 사이트 링크, name : 탭 이름 } 을 넣어주면 됩니다.

interface tabTypes {
  link: string;
  name: string;
}

interface SegmentControlProps {
  data: tabTypes[];
}

const SegmentControl = (props: SegmentControlProps) => {
  return (
    <div className="segmentcontrol">
      <div className="segmentcontrol_wrap">
        {props.data.map((d: any, index: number) => (
          <NavLink key={index} to={d.link} activeClassName="segmentcontrol_active">
            <div className="segmentcontrol_tab">
              <h5>{d.name}</h5>
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default SegmentControl;
