import React, { useState, useEffect } from 'react';
import { NetSmartAdvisorMonthlyMemberConfiguration } from '../../../actions';
import MonthlyNewUserChart from '../../../components/smartadvisor/MonthlyNewUser';
// import dayjs from 'dayjs';

type MonthlyNewMemberConfigurationProps = {
  yearFilter?: number;
};

const MonthlyNewMemberConfiguration = ({ yearFilter }: MonthlyNewMemberConfigurationProps) => {
  const [sex, setSex] = useState();
  const [age, setAge] = useState();
  const [expired, setExpired] = useState();
  const [loading, setLoading] = useState<boolean>(true);

  const queryYear = !!yearFilter ? yearFilter : new Date().getFullYear();

  useEffect(() => {
    const fetchAPI = async () => {
      await NetSmartAdvisorMonthlyMemberConfiguration('newUserMonthly', queryYear).then(
        (res: any) => {
          setSex(res.data.Data.memberList);
        },
      );
      await NetSmartAdvisorMonthlyMemberConfiguration('newUserDaily', queryYear).then(
        (res: any) => {
          console.log('new user daily basis ');
          setAge(res.data.Data.memberList);
        },
      );
      await NetSmartAdvisorMonthlyMemberConfiguration('expired', queryYear).then((res: any) => {
        console.log(res);
        setExpired(res.data.Data.memberList);
        setLoading(false);
      });
    };
    console.log('💠 월별 활성회원 Network');
    fetchAPI();
  }, [queryYear]);
  return (
    <MonthlyNewUserChart year={queryYear} sex={sex} age={age} expired={expired} loading={loading} />
  );
};

export default MonthlyNewMemberConfiguration;
