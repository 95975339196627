import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { lighten } from 'polished';
import { BreakPoints } from '../../../GlobalUtilityStyles';

interface StatusToolProps {
  type: 'prev' | 'arrow' | 'now';
  link?: string | object;
  body?: string | undefined | null;
  data?: any;
}

const StatusTool = (props: StatusToolProps) => {
  switch (props.type) {
    case 'prev':
      if (props.link) {
        return (
          <Link to={`${props.link}`}>
            <StatusText>{props.body}</StatusText>
          </Link>
        );
      } else {
        return <StatusText>{props.body}</StatusText>;
      }
    case 'arrow':
      return <StatusArrowIcon src="/images/status_arrow@2x.png" alt=">" width="16" height="16" />;
    case 'now':
      return <StatusTextLast>{props.body}</StatusTextLast>;
  }
};

const status = keyframes`
  0% {
    opacity: 0;
    left: -24px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
`;

const StatusText = styled.div`
  color: ${lighten(0.2, '#495057')};

  @media (max-width: 1300px) {
    font-size: 14px;
  }
`;

const StatusTextLast = styled.div`
  color: #495057;
  position: relative;
  animation: ${status} 0.45s;
`;

const StatusArrowIcon = styled.img`
  margin: 0 20px;
  position: relative;
  ${BreakPoints.sm} {
    margin: 0px 10px;
  }
`;

export default StatusTool;
