import NetworkManager from '../../../NetworkManager';

const DeletePlateSchedule = (sDate: string, eDate: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager('delete', `admin/plateAvailability/schedule?startDate=${sDate}&endDate=${eDate}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        console.log(err);
      });
  });
};

export default DeletePlateSchedule;
