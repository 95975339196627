import styled from 'styled-components';
import { BreakPoints } from '../../GlobalUtilityStyles';

export const HomeStyles = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  .home_panels {
    display: flex;
    flex-wrap: wrap;
    max-width: 850px;
    justify-content: center;
    align-items: center;

    & > a,
    & > div {
      width: 152px;
      height: 152px;
      margin: 8px;
    }

    ${BreakPoints.sm} {
      margin-left: -8px;
      margin-right: -8px;
      margin-top: -8px;
      & > a,
      & > div {
        flex: 1 0 auto;
        width: 140px;
      }
    }

    ${BreakPoints.xs} {
      & > a,
      & > div {
        width: 80%;
        height: 80px;
      }
    }
  }

  .placeholder {
    height: 0 !important;
    margin: 0px 8px !important;
  }
`;
