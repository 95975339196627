import React, { useState, useContext, FormEvent } from 'react';
import GOFloatingbox from '../../common/GOFloatingbox';
import { useTranslation } from 'react-i18next';
import GOButton from '../../common/GOButton';
import GODate from '../../common/GODate';
import GOInput from '../../common/GOInput';
import { Context } from '../../../stores';
import dayjs from 'dayjs';
import { NetLessonAddManually } from '../../../actions';
import GODropbox from '../../common/GODropbox';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

interface LessonAddManuallyProps {
  onClickExit?: any;
  update?: any;
  now: any;
  lessonProList?: any;
  onChange?: any;
  value?: any;
  selectedLessonTime?: any;
}

//TODO: 레슨 임시
const LessonAddManually = (props: LessonAddManuallyProps) => {
  const { t } = useTranslation();

  console.log('props.selectedLessonTime', props.selectedLessonTime);

  const selectedLessonTime = props.selectedLessonTime ? props.selectedLessonTime : new Date();

  const [data, setData] = useState<any>({
    employeeID: props.now,
    startDate: selectedLessonTime,
    startTime: selectedLessonTime,
    endTime: dayjs(selectedLessonTime)
      .add(30, 'minute')
      .toDate(),
    lessonInterval: 30,
    memo: null,
  });

  const Store = useContext(Context);
  const sending = Store.sending;

  const handleSubmit = (e: FormEvent<EventTarget>) => {
    console.log(props.now);
    console.log(data.startDate, data.startDate.toUTCString());
    console.log(data.startTime, data.startTime.toUTCString());
    // return;
    if (data.employeeID === undefined) alert('레슨프로를 선택해주세요');
    else {
      Store.actions.setSending(true); //열심히 작업중 이라는 메시지를 띄운다.
      NetLessonAddManually({
        ...data,
        startDateDayjs: dayjs(data.startDate).format('YYYY-MM-DDTHH:mm:ss'),
        endDateDayjs: dayjs(data.endDate).format('YYYY-MM-DDTHH:mm:ss'),
      })
        .then((res: any) => {
          alert('생성이 완료되었습니다.');
          Store.actions.setSending(false); //열심히 작업중이라는 메시지를 감춘다.
          if (props.update) {
            props.update();
          }
          if (props.onClickExit) {
            props.onClickExit();
          }
          if (props.onChange) {
            //레슨 프로가 변경된 경우
            props.onChange(data.employeeID);
          }
        })
        .catch(() => {
          Store.actions.setSending(false); //열심히 작업중이라는 메시지를 감춘다.
        });
    }
  };

  return (
    <>
      <GOFloatingbox width={500}>
        <UFloatingContainer>
          <UFloatingBoxHeader>
            {/*<h3>{t('reservation.personalAdd')}</h3>*/}
            <h1>비정기적 레슨 스케쥴 수동 생성</h1>
            <p style={{ fontWeight: 'bold', color: '#fa5252', marginTop: '10px' }}>
              레슨 프로의 기본 스케쥴 이외의 레슨 시간을 생성합니다.{' '}
            </p>
            <p style={{ fontWeight: 'bold', color: '#fa5252' }}>
              레슨 날짜/시작시간/종료시간/레슨 시간을 설정합니다.
            </p>
            <p style={{ fontWeight: 'bold', color: '#fa5252' }}>
              레슨 시간은 30분이 기본값이며 선택 가능합니다.
            </p>
            <p style={{ fontWeight: 'bold', color: '#fa5252' }}>
              레슨 프로의 근무 시간 외의 예약 생성은 반드시
            </p>
            <p style={{ fontWeight: 'bold', color: '#fa5252' }}>레슨 프로의 동의 후 생성합니다.</p>
          </UFloatingBoxHeader>
          <UFloatingBoxBody>
            <div className="flex_box">
              <GODropbox
                label="레슨프로"
                data={props.lessonProList?.map((e: any) => {
                  return { value: e.id, body: e.name };
                })}
                value={data.employeeID}
                onChange={(e: any) => setData({ ...data, employeeID: parseInt(e.target.value) })}
                isDefault
                required
              />
              <GODate
                label={'레슨 날짜'}
                selected={new Date(data.startDate)}
                onChange={(date: any) => {
                  setData({ ...data, startDate: date });
                }}
                required
              />
              <GODate
                label={'시작 시간'}
                onChange={(d: any) => {
                  console.log('startTime', d);
                  setData({ ...data, startTime: d });
                }}
                time
                timeOnly
                placeholder="눌러서 설정하세요"
                dataFormat="aa h:mm"
                selected={data.startTime && new Date(data.startTime)}
              />
              <GODate
                label={'종료 시간'}
                onChange={(d: any) => {
                  console.log('endTime', d);
                  setData({ ...data, endTime: d });
                }}
                time
                timeOnly
                placeholder="눌러서 설정하세요"
                dataFormat="aa h:mm"
                selected={data.endTime && new Date(data.endTime)}
              />
              <GODropbox
                label={'레슨시간'}
                value={data.lessonInterval}
                onChange={(e: any) => {
                  console.log('lessonInterval', e.target.value);
                  setData({ ...data, lessonInterval: e.target.value });
                }}
                isDefault
                required
                data={[
                  { value: 10, body: '10분' },
                  { value: 15, body: '15분' },
                  { value: 20, body: '20분' },
                  { value: 25, body: '25분' },
                  { value: 30, body: '30분' },
                  { value: 40, body: '40분' },
                  { value: 50, body: '50분' },
                  { value: 60, body: '60분' },
                ]}
              />
              <GOInput
                label="메모"
                value={data.memo}
                onChange={(e: any) => setData({ ...data, memo: e.target.value })}
                textarea
              />
            </div>
          </UFloatingBoxBody>
          <UFloatingBoxFooter>
            <GOButton
              buttontype="green"
              onClick={handleSubmit}
              body={t('common.add')}
              loading={sending}
              disabled={sending}
            />
            <GOButton body={t('common.no')} onClick={props.onClickExit} disabled={sending} />
          </UFloatingBoxFooter>
        </UFloatingContainer>
      </GOFloatingbox>
    </>
  );
};

export default LessonAddManually;
