import React, { useState, useEffect } from 'react';
import {
  GOFloatingbox,
  GOInput,
  GOButton,
  GODropbox,
  GOError,
  GOLoading,
  SmallTitle,
} from '../../../components';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NetUserIMGUpload } from '../../../actions';
import GOPostCode from '../../../components/common/GOPostCode';
import Webcam from 'react-webcam';
import { actionPutUserDetail } from '../../../stores/actions';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

interface EditProps {
  update?: () => void;
  onClickExit?: () => void;
  data: {
    name: string;
    imageURL: string;
    sex: string | number;
    nickname: string;
    dateOfBirth: string;
    phoneNumber: string; // 전화번호 바뀌지 않은경우에도 반드시 줄것, 전화번호가 바뀐경우 인증번호 전송후 인증된지 확인함
    address: string;
    memo: string;
  };
}

const Edit = (props: EditProps) => {
  const webcamRef = React.useRef<any>(null);
  const [webcam, onWebcam] = useState(false);

  const [editData, setEditData] = useState<any>();

  const { register, handleSubmit, errors } = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [post, viewPost] = useState(false);
  const [devices, setDevices] = useState<any>([]);
  const [cameraChange, setCameraChange] = useState<boolean>(false);

  const firstLabelName = devices[0]?.label;
  const secondLabelName = devices[1]?.label;

  const firstCameraId = {
    deviceId: devices[0]?.deviceId,
  };
  const secondCameraId = {
    deviceId: devices[1]?.deviceId,
  };

  const handleDevices = React.useCallback(
    mediaDevices => setDevices(mediaDevices.filter(({ kind }: any) => kind === 'videoinput')),
    [setDevices],
  );

  const onChangeType = () => {
    setCameraChange(!cameraChange);
  };

  const capture = React.useCallback(() => {
    setCameraChange(false);
    const imageSrc = webcamRef.current.getScreenshot().replace('data:image/jpeg;base64,', '');

    const byteString = atob(imageSrc);

    const ab = new ArrayBuffer(imageSrc.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: 'image/jpeg' });

    const file = new File([blob], 'file.jpg', {
      type: 'image/jpeg',
    });

    const formData = new FormData();
    formData.append('file', file);

    NetUserIMGUpload(formData).then((res: any) => {
      setEditData({ ...editData, profileImageURL: res.data.Data.imageURL });
    });
  }, [webcamRef, editData]);

  useEffect(() => {
    // let userData = props.data;
    // userData.dateOfBirth = userData?.dateOfBirth?.split('T')[0];
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
    setEditData(props.data);
    setLoading(false);
  }, [handleDevices, props.data]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      [name]: value,
    });
  };

  const handleAddressChange = (e: any) => {
    setEditData({
      ...editData,
      address: e,
    });
  };

  const onSubmit = () => {
    if (props.data?.phoneNumber !== editData?.phoneNumber) {
      alert('비밀번호와 체크인번호는 변경한 휴대폰번호 뒷 4자리로 설정됩니다');
      dispatch(
        actionPutUserDetail(editData.id, editData, () => {
          alert(t('user.editOK'));
          if (props.onClickExit) {
            props.onClickExit();
          }
        }),
      );
    } else {
      dispatch(
        actionPutUserDetail(editData.id, editData, () => {
          alert(t('user.editOK'));
          if (props.onClickExit) {
            props.onClickExit();
          }
        }),
      );
    }
  };

  return (
    <GOFloatingbox width={1100}>
      {loading ? (
        <GOLoading />
      ) : (
        <UFloatingContainer as="form" onSubmit={handleSubmit(onSubmit)}>
          <UFloatingBoxHeader>
            <h1>{t('user.edit')}</h1>
            <p>{t('user.editInfo')}</p>
          </UFloatingBoxHeader>
          <UFloatingBoxBody flex>
            <div className="flex_box">
              <SmallTitle body={t('user.profileInfo')} />
              {editData.profileImageURL ? (
                <>
                  <div
                    style={{
                      background: `url(${editData.profileImageURL}) center / cover no-repeat`,
                      width: 120,
                      height: 120,
                      borderRadius: 150,
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  />
                  <GOButton
                    body="재촬영"
                    buttontype="red"
                    onClick={() => {
                      setEditData({ ...editData, profileImageURL: '' });
                    }}
                    noMarginLeft
                  />
                </>
              ) : (
                <div className="web-cam-box">
                  <Webcam
                    audio={false}
                    width={320}
                    height={250}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    screenshotQuality={1}
                    onUserMedia={() => {
                      onWebcam(true);
                    }}
                    onUserMediaError={() => {
                      onWebcam(false);
                    }}
                    videoConstraints={cameraChange ? secondCameraId : firstCameraId}
                  />
                  {webcam ? (
                    <div className="web-cam-controls">
                      <GOButton
                        body="촬영"
                        style={{ marginTop: '8px' }}
                        onClick={capture}
                        noMarginLeft
                      />
                      <GODropbox
                        onChange={onChangeType}
                        required
                        data={[
                          { body: firstLabelName, value: 0 },
                          { body: secondLabelName, value: 1 },
                        ]}
                        isDefault
                        small
                      />
                    </div>
                  ) : (
                    <GOError body="장치를 찾는 중입니다. 잠시 기다려주세요." fontSize={12} />
                  )}
                </div>
              )}
            </div>
            <div className="flex_box">
              <SmallTitle body={t('user.baseInfo')} />
              <GOInput
                label={t('user.name')}
                name="name"
                rref={register({ maxLength: 8 })}
                error={errors.name}
                onChange={handleChange}
                value={editData.name}
                required
              />
              <GOInput
                label={t('user.nickname')}
                name="nickname"
                onChange={handleChange}
                value={editData.nickname}
              />
              {errors.name && <GOError body={`${t('sign.nameErrorMaxLength')}`} />}
              <GODropbox
                label={t('user.sex')}
                name="sex"
                rref={register()}
                onChange={handleChange}
                value={editData.sex}
                required
                data={[
                  { value: 0, body: '남성' },
                  { value: 1, body: '여성' },
                ]}
              />
              <GOInput
                label={t('user.birthday')}
                type="date"
                rref={register()}
                name="dateOfBirth"
                onChange={handleChange}
                // value={editData.dateOfBirth}
                value={editData.dateOfBirth?.split('T')[0]}
                required
              />
              <GOInput
                label={t('sign.phone')}
                rref={register()}
                name="phoneNumber"
                onChange={handleChange}
                value={editData.phoneNumber}
                required
              />
            </div>
            <div className="flex_box">
              <SmallTitle body={t('user.etcInfo')} />
              <GOInput
                label={t('user.address')}
                name="address"
                rref={register()}
                value={editData.address ? editData.address.roadAddress : editData.address}
                button={t('common.search')}
                onClickButton={() => {
                  viewPost(true);
                }}
                disabled
              />
              <GOInput
                label={t('user.memo')}
                name="memo"
                rref={register()}
                onChange={handleChange}
                value={editData.memo === null ? '' : editData.memo}
                textarea
              />
              {post && (
                <GOPostCode
                  onClickExit={() => {
                    viewPost(false);
                  }}
                  handleChange={handleAddressChange}
                />
              )}
            </div>
          </UFloatingBoxBody>
          <UFloatingBoxFooter>
            <GOButton buttontype="green" body={t('user.edit')} type="submit" />
            <GOButton body={t('common.no')} onClick={props.onClickExit} />
          </UFloatingBoxFooter>
        </UFloatingContainer>
      )}
    </GOFloatingbox>
  );
};

export default Edit;
