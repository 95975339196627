import NetworkManager from '../../../NetworkManager';
import { toast } from 'react-toastify';

const SignOut = (data: object) => {
  return new Promise((resolve, reject) => {
    NetworkManager('post', 'admin/auth/signOut', data)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        if (err.response) {
          console.log(err.response);
          switch (err.response.status) {
            case 404:
              toast.error(`⛔️ ${err.response.data.message}`);
              break;
            case 409:
              toast.error(`⛔️  ${err.response.data.message}`);
              break;
            default:
              break;
          }
          reject(err);
        }
      });
  });
};

export default SignOut;
