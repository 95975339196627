import React, { useEffect } from 'react';
import {
  HeaderBottom,
  StatusTool,
  GOLayout,
  EmployeeScheduleManage,
  GOLoading,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetDetailEmployee } from '../../../stores/actions';

interface ParamsType {
  id: string;
}

const ScheduleManage = () => {
  const { t } = useTranslation();
  const params = useParams<ParamsType>();
  const dispatch = useDispatch();

  const employeeStore = useSelector((state: any) => state.employee);
  const employeeDetailData = employeeStore.detail;

  useEffect(() => {
    dispatch(actionGetDetailEmployee(params.id));
  }, [params.id, dispatch]);

  return (
    <>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool link="/admin/employee/list" type="prev" body={t('employee.list')} />
            <StatusTool type="arrow" />
            <StatusTool
              data={employeeDetailData}
              link={`/admin/employee/${params.id}`}
              type="prev"
              body={employeeDetailData.employee?.name}
            />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={t('employee.scheduleAdd')} />
          </>
        }
      />
      {employeeDetailData ? (
        <GOLayout body={<EmployeeScheduleManage data={employeeDetailData} />} />
      ) : (
        <GOLoading />
      )}
    </>
  );
};

export default ScheduleManage;
