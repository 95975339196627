import React, { useState, useEffect } from 'react';
import * as Styled from './styles';
import { useTranslation } from 'react-i18next';
import { GOLoading, AccountingPurchaseTable } from '../../../components';

const View = ({ loading, data, newPurchaseData, purchase }: any) => {
  const { t } = useTranslation();

  const [totalData, setTotalData] = useState<any>({
    originalPrice: 0,
    discount: 0,
    unpaidPrice: 0,
    paidPrice: 0,
    refundPrice: 0,
  });
  const [newUserData, setNewUserData] = useState<any>({
    originalPrice: 0,
    discount: 0,
    unpaidPrice: 0,
    paidPrice: 0,
    refundPrice: 0,
  });

  const originalPrice = data.transactions?.length;
  const discount = data.transactions?.filter((t: any) => t.discount > 0);
  const unpaidPrice = data.transactions?.filter((t: any) => t.unpaidPrice > 0);

  const newOriginalPrice = newPurchaseData.transactions?.length;
  const newDiscount = newPurchaseData.transactions?.filter((t: any) => t.discount > 0);
  const newUnpaidPrice = newPurchaseData.transactions?.filter((t: any) => t.unpaidPrice > 0);

  useEffect(() => {
    let totalOriginalPrice = 0;
    let totalDiscount = 0;
    let totalUnpaidPrice = 0;
    let totalPaidPrice = 0;
    let totalRefundPrice = 0;

    let newOriginalPrice = 0;
    let newDiscount = 0;
    let newUnpaidPrice = 0;
    let newPaidPrice = 0;
    let newRefundPrice = 0;

    // 총 구매 합계
    if (data.transactions?.length > 0) {
    }
    data.transactions?.forEach((t: any) => {
      setTotalData({
        originalPrice: totalOriginalPrice += t.originalPrice,
        discount: totalDiscount += t.discount,
        unpaidPrice: totalUnpaidPrice += t.unpaidPrice,
        paidPrice: totalPaidPrice += t.paidAmount,
        refundPrice: totalRefundPrice += t.refund,
      });
    });

    // 신규 구매 합계
    newPurchaseData.transactions?.forEach((t: any) => {
      setNewUserData({
        originalPrice: newOriginalPrice += t.originalPrice,
        discount: newDiscount += t.discount,
        unpaidPrice: newUnpaidPrice += t.unpaidPrice,
        paidPrice: newPaidPrice += t.paidAmount,
        refundPrice: newRefundPrice += t.refund,
      });
    });
  }, [data, newPurchaseData]);

  return loading ? (
    <GOLoading />
  ) : (
    <>
      <Styled.Summary>
        <Styled.Container>
          <Styled.Total>
            <Styled.Part default>
              <Styled.Title>총 이용권 구매 금액</Styled.Title>
              <Styled.Body>
                {purchase
                  ? `${newPurchaseData.summary.grossAmount?.toLocaleString('en')} 원`
                  : `${data.summary.grossAmount?.toLocaleString('en')} 원`}
              </Styled.Body>
            </Styled.Part>
            <Styled.Symbol>
              <div className="minus" />
            </Styled.Symbol>
            <Styled.Part refund>
              <Styled.Title>총 환불 금액</Styled.Title>
              <Styled.Body>
                {purchase
                  ? `${newPurchaseData.summary.refundedAmount?.toLocaleString('en')} 원`
                  : `${data.summary.refundedAmount?.toLocaleString('en')} 원`}
              </Styled.Body>
            </Styled.Part>
            <Styled.Symbol>
              <div className="result" />
            </Styled.Symbol>
            <Styled.Part total>
              <Styled.Title largeFont>총 매출 금액</Styled.Title>
              <Styled.Body largeFont>
                {purchase
                  ? `${newPurchaseData.summary.netAmount?.toLocaleString('en')} 원`
                  : `${data.summary.netAmount?.toLocaleString('en')} 원`}
              </Styled.Body>
            </Styled.Part>
          </Styled.Total>
          <div className="table-container">
            <Styled.Table bottom>
              <table>
                <tbody>
                  <tr>
                    <td>{t('common.card')}</td>
                    <td>
                      {purchase
                        ? `${newPurchaseData.summary.detail?.creditCardCount} 건`
                        : `${data.summary.detail?.creditCardCount} 건`}
                    </td>
                    <td>
                      {purchase
                        ? `${newPurchaseData.summary.detail.creditCardAmount?.toLocaleString(
                            'en',
                          )} ${t('common.KRW')}`
                        : `${data.summary.detail.creditCardAmount?.toLocaleString('en')} ${t(
                            'common.KRW',
                          )}`}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('common.cash')}</td>
                    <td>
                      {purchase
                        ? `${newPurchaseData.summary.detail?.cashCount} 건`
                        : `${data.summary.detail?.cashCount} 건`}
                    </td>
                    <td>
                      {purchase
                        ? `${newPurchaseData.summary.detail.cashAmount?.toLocaleString('en')} ${t(
                            'common.KRW',
                          )}`
                        : `${data.summary.detail.cashAmount?.toLocaleString('en')} ${t(
                            'common.KRW',
                          )}`}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('common.transfer')}</td>
                    <td>
                      {purchase
                        ? `${newPurchaseData.summary.detail?.transferCount} 건`
                        : `${data.summary.detail?.transferCount} 건`}
                    </td>
                    <td>
                      {purchase
                        ? `${newPurchaseData.summary.detail.transferAmount?.toLocaleString(
                            'en',
                          )} ${t('common.KRW')}`
                        : `${data.summary.detail.transferAmount?.toLocaleString('en')} ${t(
                            'common.KRW',
                          )}`}
                    </td>
                  </tr>
                  <tr className="red">
                    <td>{t('common.refund_cash')}</td>
                    <td>
                      {purchase
                        ? `${newPurchaseData.summary.detail?.cashTransferRefundCount} 건`
                        : `${data.summary.detail?.cashTransferRefundCount} 건`}
                    </td>
                    <td>
                      {purchase
                        ? `${newPurchaseData.summary.detail.cashTransferRefundAmount?.toLocaleString(
                            'en',
                          )} ${t('common.KRW')}`
                        : `${data.summary.detail.cashTransferRefundAmount?.toLocaleString(
                            'en',
                          )} ${t('common.KRW')}`}
                    </td>
                  </tr>
                  <tr className="red">
                    <td>{t('common.refund_card')}</td>
                    <td>
                      {purchase
                        ? `${newPurchaseData.summary.detail?.creditCardRefundCount} 건`
                        : `${data.summary.detail?.creditCardRefundCount} 건`}
                    </td>
                    <td>
                      {purchase
                        ? `${newPurchaseData.summary.detail.creditCardRefundAmount?.toLocaleString(
                            'en',
                          )} ${t('common.KRW')}`
                        : `${data.summary.detail.creditCardRefundAmount?.toLocaleString('en')} ${t(
                            'common.KRW',
                          )}`}
                    </td>
                  </tr>
                  <tr className="total">
                    <td>결제 완료</td>
                    <td>
                      {purchase
                        ? `${newPurchaseData.summary.detail?.totalPaymentCount} 건`
                        : `${data.summary.detail?.totalPaymentCount} 건`}
                    </td>
                    <td>
                      {purchase
                        ? `${newUserData?.paidPrice?.toLocaleString('en')} ${t('common.KRW')}`
                        : `${totalData?.paidPrice?.toLocaleString('en')} ${t('common.KRW')}`}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Styled.Table>
            <Styled.Table>
              <table>
                <tbody>
                  <tr className="etc">
                    <td>합계</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="etcItem">
                    <td>총 판매상품</td>
                    <td>{purchase ? `${newOriginalPrice} 건` : `${originalPrice} 건`}</td>
                    <td>
                      {purchase
                        ? `${newUserData?.originalPrice?.toLocaleString('en')} ${t('common.KRW')}`
                        : `${totalData?.originalPrice?.toLocaleString('en')} ${t('common.KRW')}`}
                    </td>
                  </tr>
                  <tr>
                    <td>할인</td>
                    <td>{purchase ? `${newDiscount.length} 건` : `${discount.length} 건`}</td>
                    <td>
                      {purchase
                        ? `${newUserData?.discount?.toLocaleString('en')} ${t('common.KRW')}`
                        : `${totalData?.discount?.toLocaleString('en')} ${t('common.KRW')}`}
                    </td>
                  </tr>
                  <tr>
                    <td>미수금</td>
                    <td>{purchase ? `${newUnpaidPrice.length} 건` : `${unpaidPrice.length} 건`}</td>
                    <td>
                      {purchase
                        ? `${newUserData?.unpaidPrice?.toLocaleString('en')} ${t('common.KRW')}`
                        : `${totalData?.unpaidPrice?.toLocaleString('en')} ${t('common.KRW')}`}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Styled.Table>
          </div>
        </Styled.Container>
      </Styled.Summary>
      <AccountingPurchaseTable
        data={purchase ? newPurchaseData : data}
        title={purchase ? '신규 매출' : '총 매출'}
        summaryData={purchase ? newUserData : totalData}
      />
    </>
  );
};

export default View;
