import styled, { css } from 'styled-components';
import * as color from '../../../color/index';

interface elementProps {
  inputType?: any;
  error?: boolean;

  medium?: boolean;
  large?: boolean;

  date?: boolean;
  time?: boolean;
  datetime?: boolean;
}

export const Input = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 0px 12px;
  width: 100%;
`;

export const InputLabel = styled.div`
  word-break: keep-all;
  flex-shrink: 0;
  width: 80px;
  font-size: 14px;
  font-weight: bold;
  color: ${color.Colors.primary};
`;

export const InputUnit = styled.div`
  width: 0px;
  position: relative;
  right: 26px;
  font-size: 14px;
  font-weight: bold;
`;

export const TextArea = styled.textarea<elementProps>`
  width: 100%;
  font-size: 14px;
  height: 80px;
  padding: 4px 8px;
  border-radius: 5px;
  border: 2px solid ${color.Colors.line};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  transition: 0.35s;
  font-weight: bold;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border: 2px solid ${color.Colors.blue};
    box-shadow: 0 3px 6px rgba(101, 123, 242, 0.2);
    transition: 0.35s;
  }

  ${props => {
    if (props.error)
      return css`
        border: 2px solid ${color.Colors.red};
        box-shadow: 0 3px 6px rgba(250, 82, 82, 0.3);
        &:focus {
          border: 2px solid ${color.Colors.red};
          box-shadow: 0 3px 6px rgba(250, 82, 82, 0.3);
        }
      `;

    if (props.large)
      return css`
        height: 160px;
      `;
  }}
`;

export const InputItem = styled.input<elementProps>`
  flex: 1;
  min-width: 0px;
  font-size: 14px;
  height: 40px;
  text-indent: 12px;
  border-radius: 5px;
  border: 2px solid ${color.Colors.line};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  transition: 0.35s;
  font-weight: bold;
  box-sizing: border-box;

  &:focus {
    border: 2px solid ${color.Colors.blue};
    box-shadow: 0 3px 6px rgba(101, 123, 242, 0.2);
    transition: 0.35s;
  }
  &::placeholder {
    color: ${color.Colors.line};
  }

  ${props => {
    if (props.medium)
      return css`
        max-width: 172px;
      `;
  }}

  ${props => {
    if (props.date || props.time || props.datetime) {
      return css`
        text-indent: 4px;
        font-family: inherit;
      `;
    }

    if (props.error) {
      return css`
        border: 2px solid ${color.Colors.red};
        box-shadow: 0 3px 6px rgba(250, 82, 82, 0.3);
        &:focus {
          border: 2px solid ${color.Colors.red};
          box-shadow: 0 3px 6px rgba(250, 82, 82, 0.3);
        }
      `;
    }
  }}
`;
