import React, { Suspense } from "react";
import "./App.scss";
import Router from "./router";
import { Context, Store } from "./stores";
import { ToastContainer } from "react-toastify";
import "./react-toastify.css";
import { GOSending } from "./components";
import { RootModal } from "./modals";
import { BrowserRouter } from "react-router-dom";

const App: React.FC = () => {
  return (
    <Context.Provider value={{ ...Store() }}>
      <Suspense fallback="">
        <BrowserRouter>
          <Router />
          <ToastContainer autoClose={4000} position="top-center" />
          <GOSending />
          <RootModal />
        </BrowserRouter>
      </Suspense>
    </Context.Provider>
  );
};

export default App;
