import React, { useState, useEffect } from 'react';
import GOFloatingbox from '../../common/GOFloatingbox';
import { useTranslation } from 'react-i18next';
import GOButton from '../../common/GOButton';
import dayjs from 'dayjs';
import * as Styled from '../reservationDeleteStyles';
import {
  NetReservationDeleteLessonList,
  NetLessonResCancel,
  NetReservationDeleteLessonSchedule,
} from '../../../actions';
import ReservationDate from '../ReservationDate';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

interface PersonalReservationDeleteProps {
  onClickExit?: any;
  update?: any;
  now: any;
}

function PersonalDelete(props: PersonalReservationDeleteProps) {
  const { t } = useTranslation();

  const [data, setData] = useState<any>();
  const [startDate, setStartDate] = useState<any>(null);
  const [startTime, setStartTime] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [endTime, setEndTime] = useState<any>(null);
  const [delState, setDelState] = useState<boolean>(false);
  const [searchState, setSearchState] = useState<boolean>(false);
  const [update, isUpdate] = useState<boolean>(false);

  let combineDate = {
    startDay: `${dayjs(startDate).format('YYYY-MM-DD')}T${
      dayjs(startTime).hour() < 10 ? `0${dayjs(startTime).hour()}` : `${dayjs(startTime).hour()}`
    }:${
      dayjs(startTime).minute() < 10
        ? `0${dayjs(startTime).minute()}`
        : `${dayjs(startTime).minute()}`
    }:00`,
    endDay: `${dayjs(endDate).format('YYYY-MM-DD')}T${
      dayjs(endTime).hour() < 10 ? `0${dayjs(endTime).hour()}` : `${dayjs(endTime).hour()}`
    }:${
      dayjs(endTime).minute() < 10 ? `0${dayjs(endTime).minute()}` : `${dayjs(endTime).minute()}`
    }:00`,
  };

  const onLessonList = () => {
    // 날짜입력 예외처리
    if (startDate === null) {
      alert('시작날짜가 입력되지 않았습니다.');
    } else if (startTime === null) {
      alert('시작시간이 입력되지 않았습니다.');
    } else if (endDate === null) {
      alert('종료날짜가 입력되지 않았습니다.');
    } else if (endTime === null) {
      alert('종료시간이 입력되지 않았습니다.');

      // 통신
    } else {
      NetReservationDeleteLessonList(props.now, combineDate.startDay, combineDate.endDay)
        .then((res: any) => {
          setData(res.data.Data.lessonReservations);
        })
        .catch((err: any) => console.log(err));
    }
  };

  const onReservationCancel = (id: string) => {
    // 예약취소 통신
    NetLessonResCancel(id)
      .then((res: any) => isUpdate(!update))
      .catch((err: any) => console.log(err));
  };

  const handleSubmit = () => {
    // 날짜입력확인 예외처리
    if (startDate === null || startTime === null || endDate === null || endTime === null) {
      alert('날짜 또는 시간을 확인해주세요.');
    } else if (data?.length > 0) {
      setDelState(true);

      // 통신
    } else if (searchState) {
      NetReservationDeleteLessonSchedule(props.now, combineDate.startDay, combineDate.endDay)
        .then((res: any) => {
          alert('삭제가 완료되었습니다.');
          setDelState(false);
          if (props.onClickExit) {
            props.onClickExit();
            props.update();
          }
        })
        .catch((err: any) => {
          if (err) alert('삭제하는데 실패했습니다. 다시 시도해주세요.');
        });
    } else alert('예약 회원이 있는지 먼저 확인해 주세요.');
  };

  useEffect(() => {
    if (update) {
      NetReservationDeleteLessonList(props.now, combineDate.startDay, combineDate.endDay)
        .then((res: any) => {
          setData(res.data.Data.lessonReservations);
          isUpdate(false);
        })
        .catch((err: any) => console.log(err));
    }
  }, [update, combineDate.startDay, combineDate.endDay, props.now]);

  return (
    <GOFloatingbox width={520}>
      <UFloatingContainer>
        <UFloatingBoxHeader>
          <h1>코치예약 스케쥴 삭제</h1>
          <p>생성된 예약 스케쥴을 삭제합니다.</p>
          <Styled.WarningMessage>
            * 스케쥴 삭제시 등록되어 있는 예약이 취소되니 유의해 주세요.
          </Styled.WarningMessage>
        </UFloatingBoxHeader>
        <UFloatingBoxBody>
          <div className="flex_box">
            <Styled.DateBox>
              <ReservationDate
                name="시작날짜"
                dateName="startDate"
                date={startDate}
                onChange={(date: any) => {
                  setStartDate(dayjs(date));
                }}
              />
              <ReservationDate
                name="시간"
                dateName="startTime"
                date={startTime}
                onChange={(date: any) => {
                  setStartTime(dayjs(date));
                }}
                time
              />
            </Styled.DateBox>
            <Styled.DateBox>
              <ReservationDate
                name="종료날짜"
                dateName="endDate"
                date={endDate}
                onChange={(date: any) => {
                  setEndDate(dayjs(date));
                }}
              />
              <ReservationDate
                name="시간"
                dateName="endTime"
                date={endTime}
                onChange={(date: any) => {
                  setEndTime(dayjs(date));
                }}
                time
              />
            </Styled.DateBox>
            <Styled.Line />
            <div>
              <Styled.BottomTitleBox>
                <Styled.DateName>예약 회원 확인</Styled.DateName>
                <GOButton
                  body="예약회원 검색"
                  onClick={() => {
                    onLessonList();
                    setSearchState(true);
                  }}
                />
              </Styled.BottomTitleBox>
              {data?.length > 0 ? (
                <>
                  <Styled.ReservationUserList>
                    {data?.map((d: any, index: number) => (
                      <React.Fragment key={index}>
                        <Styled.ReservationUser>
                          <Styled.ReservationUserWrapper>
                            <Styled.UserName>{d.userName}</Styled.UserName>
                            <Styled.ReservationDate>
                              {dayjs(d.startDate).format('YYYY.MM.DD')}
                            </Styled.ReservationDate>
                            <Styled.ReservationTime>
                              {dayjs(d.startDate).format('a hh:mm')}
                            </Styled.ReservationTime>
                            <Styled.ReservationCancel
                              onClick={() => {
                                onReservationCancel(d.lessonReservationID);
                              }}
                            >
                              예약취소
                            </Styled.ReservationCancel>
                          </Styled.ReservationUserWrapper>
                        </Styled.ReservationUser>
                      </React.Fragment>
                    ))}
                  </Styled.ReservationUserList>
                </>
              ) : (
                <>
                  <Styled.ReservationUserList>
                    <Styled.NoReservationText>
                      {data?.length === 0 && <div>예약회원이 없습니다.</div>}
                    </Styled.NoReservationText>
                  </Styled.ReservationUserList>
                </>
              )}
            </div>
            {delState ? (
              <>
                <Styled.WarningText>
                  * 삭제를 하시려면 위의 예약을 취소해 주세요.
                </Styled.WarningText>
              </>
            ) : (
              <>
                <Styled.WarningText></Styled.WarningText>
              </>
            )}
          </div>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton
            buttontype="red"
            onClick={handleSubmit}
            body="삭제"
            loading={delState}
            disabled={delState}
          />
          <GOButton
            body={t('common.no')}
            onClick={() => {
              props.onClickExit();
              if (searchState || delState) {
                props.update();
              }
            }}
            disabled={delState}
          />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
}

export default PersonalDelete;
