export default function MonthlyAccountingAge(data: any) {
  let amount20s = 0;
  let amount30s = 0;
  let amount40s = 0;
  let amount50s = 0;
  let amount60s = 0;

  data?.map((d: any) => {
    const payment = Number(d.paymentAmount) > 0 ? Number(d.paymentAmount) : 0;
    const refund = Number(d.refundAmount) > 0 ? Number(d.refundAmount) : 0;
    switch (d.r10Age) {
      case 1:
        amount20s = payment - refund > 0 ? payment - refund : 0;
        break;
      case 2:
        amount30s = payment - refund > 0 ? payment - refund : 0;
        break;
      case 3:
        amount40s = payment - refund > 0 ? payment - refund : 0;
        break;
      case 4:
        amount50s = payment - refund > 0 ? payment - refund : 0;
        break;
      case 5:
        amount60s = payment - refund > 0 ? payment - refund : 0;
        break;
    }
  });
  return { amount20s, amount30s, amount40s, amount50s, amount60s };
}
