import React from 'react';
import './index.scss';
import dayjs from 'dayjs';
import useTypeChanger from '../../../actions/Product/typeChanger';
import { useTranslation } from 'react-i18next';

interface UsedTicketPanelProps {
  ticket?: any;
}

const UsedTicketPanel = (props: UsedTicketPanelProps) => {
  const translateType = (value: number) => {
    let ticketTypeName = '';
    if (value === 0) {
      ticketTypeName = '개인레슨';
    } else if (value === 1) {
      ticketTypeName = '그룹레슨';
    } else if (value === 2) {
      ticketTypeName = '타석이용';
    } else if (value === 3) {
      ticketTypeName = '장소';
    } else if (value === 4) {
      ticketTypeName = '락카서비스';
    } else if (value === 5) {
      ticketTypeName = '기타';
    } else if (value === 6) {
      ticketTypeName = '제주캠퍼스';
    } else {
      ticketTypeName = 'ETC';
    }
    return ticketTypeName;
  };

  const decoratedTicketName = (ticket: any) => {
    try {
      if (ticket.ticketMaxCount && ticket.ticketNth) {
        return `${ticket.name}(${ticket.ticketNth}/${ticket.ticketMaxCount})`;
      } else {
        return ticket.name;
      }
    } catch (e) {}
    return ticket.name;
  };

  // console.log(props.ticket);
  return (
    <div className="UsedTicketPanel">
      {/* TODO 제주캠퍼스 */}
      <div className="UsedTicketPanel_info">
        {props.ticket.name === undefined || props.ticket.name === null
          ? translateType(props.ticket.type)
          : decoratedTicketName(props.ticket)}
      </div>
      {/*<div className="UsedTicketPanel_info">{<>{'타석페키지이용권전체데이타보기를 하기위한 긴문장 작성'}</>}</div>*/}
      <div className="UsedTicketPanel_start">
        <h6
          style={
            props.ticket.status !== undefined &&
            props.ticket.status !== null &&
            props.ticket.status === 2
              ? { color: 'red' }
              : {}
          }
        >
          {props.ticket.status !== undefined &&
          props.ticket.status !== null &&
          props.ticket.status === 2
            ? '결석/예약시작'
            : '입장시간'}
        </h6>
        <div>{dayjs(props.ticket.checkinDate).format('YYYY. MM. DD')}</div>
        <div>{dayjs(props.ticket.checkinDate).format('HH:mm')}</div>
      </div>
      <div className="UsedTicketPanel_end">
        {/*<h6>퇴장시간</h6>*/}
        <h6
          style={
            props.ticket.status !== undefined &&
            props.ticket.status !== null &&
            props.ticket.status === 2
              ? { color: 'red' }
              : {}
          }
        >
          {props.ticket.status !== undefined &&
          props.ticket.status !== null &&
          props.ticket.status === 2
            ? '결석/예약종료'
            : '퇴장시간'}
        </h6>
        <div>{dayjs(props.ticket.checkoutDate).format('YYYY. MM. DD')}</div>
        <div>{dayjs(props.ticket.checkoutDate).format('HH:mm')}</div>
      </div>
    </div>
  );
};

export default UsedTicketPanel;
