import React, { useEffect, useState } from 'react';
import {
  HeaderBottom,
  StatusTool,
  SegmentControl,
  GOLoading,
  PlateReservationStatusGrid,
  PlateReservationStatusTime,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { NetPlateReservationsByDateList } from '../../../actions';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import '../LessonReservationStatus/toastGoGridAttr.css';

dayjs.locale('ko');

const PlateReservationStatusList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState({
    startDate: dayjs()
      .subtract(1, 'day')
      .format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  });

  const [update, isUpdate] = useState(false);
  const [gridColumns, setGridColumns] = useState<any>([]);
  const [gridData, setGridData] = useState<any>([]);

  //데이터 가져오기
  useEffect(() => {
    NetPlateReservationsByDateList(`${date.startDate}`, `${date.endDate}`).then((res: any) => {
      let gridData: any = [];
      //set up grid column headers
      let column: [any] = [
        {
          name: 'time',
          header: '시간',
          align: 'center',
          width: 80,
          soringType: 'asc',
          sortable: true,
        },
      ];
      res.data.Data.plates.forEach((p: any) => {
        column.push({
          name: `p${p.number}`,
          header: `타석${p.number}`,
          align: 'center',
          width: 80,
        });
      });
      setGridColumns(column);

      res.data.Data.plateReservationsByDate.forEach((t: any) => {
        let gridDataItem: any = {
          time: `${dayjs(t.startTime).format('HH:mm')}~${dayjs(t.endTime).format('HH:mm')}`,
        };
        console.log(gridDataItem);
        res.data.Data.plates.forEach((p: any) => {
          const pNumber = `p${p.number}`;
          const pUserId = `p${p.number}_uid`;
          gridDataItem[pNumber] = '';
          gridDataItem[pUserId] = 0;
        });

        let coloredColumns: any = { column: {} };
        t.plateNumbers.forEach((plateNumber: number, index: number) => {
          const pNumber = `p${plateNumber}`;
          const pUserId = `p${plateNumber}_uid`;
          if (index < t.userNames.length && index < t.userIDs.length && index < t.status.length) {
            gridDataItem[pNumber] = t.userNames[index];
            gridDataItem[pUserId] = t.userIDs[index];
            if (t.status[index] === 1) {
              coloredColumns.column[pNumber] = ['go_grid_cell_attend'];
            } else if (t.status[index] === 2) {
              coloredColumns.column[pNumber] = ['go_grid_cell_absent'];
            } else {
              coloredColumns.column[pNumber] = ['go_grid_cell_reserved'];
            }
            console.log(t.status[index]);
          }
        });
        const attr = '_attributes';
        gridDataItem[attr] = {
          className: coloredColumns,
        };
        gridData.push(gridDataItem);
      });
      const today = dayjs().format('YYYY-MM-DD');
      const isToday = today === date.endDate;
      //adding current Time
      if (isToday) {
        let gridDataItemNow: any = {
          time: `${dayjs().format('HH:mm')} `,
          startTime: dayjs(),
          _attributes: {
            className: {
              // Add class name on a row
              row: ['go_grid_row_cur_time'],
            },
          },
        };
        gridData.push(gridDataItemNow);

        //sorting
        let sortedGridData = gridData.slice().sort((a: any, b: any) => {
          let dateA = a['time'];
          let dateB = b['time'];
          console.log(dateA, dateB);
          if (dateA < dateB) {
            return -1;
          } else if (dateA > dateB) {
            return 1;
          } else {
            return 0;
          }
        });
        setGridData(sortedGridData);
      } else {
        setGridData(gridData);
      }
      setLoading(false);
    });
  }, [date, update]);

  return (
    <div>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool type="prev" body={t('main-menu.reservation_management')} />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={'타석예약자'} />
          </>
        }
      />
      <SegmentControl
        data={[
          {
            name: t('reservation.group'),
            link: '/admin/reservation/list/group',
          },
          {
            name: t('reservation.personal'),
            link: '/admin/reservation/list/personal',
          },
          {
            name: '타석예약자',
            link: '/admin/reservation/list/plateReservationStatus',
          },
          {
            name: '개인수업예약자',
            link: '/admin/reservation/list/lessonReservationStatus',
          },
        ]}
      />
      {/* 날짜 선택 보여주는 화면 임*/}
      <PlateReservationStatusTime
        date={date}
        goLeft={() => {
          if (!loading) {
            setDate({
              startDate: dayjs(date.startDate)
                .subtract(1, 'd')
                .format('YYYY-MM-DD'),
              endDate: dayjs(date.endDate)
                .subtract(1, 'd')
                .format('YYYY-MM-DD'),
            });
            setLoading(true);
          }
        }}
        goRight={() => {
          if (!loading) {
            setDate({
              startDate: dayjs(date.startDate)
                .add(1, 'd')
                .format('YYYY-MM-DD'),
              endDate: dayjs(date.endDate)
                .add(1, 'd')
                .format('YYYY-MM-DD'),
            });
            setLoading(true);
          }
        }}
        goDate={(toDate: any) => {
          if (!loading) {
            setDate({
              startDate: dayjs(toDate)
                .subtract(1, 'd')
                .format('YYYY-MM-DD'),
              endDate: dayjs(toDate).format('YYYY-MM-DD'),
            });
            setLoading(true);
          }
        }}
      />
      {loading ? (
        <GOLoading />
      ) : (
        <PlateReservationStatusGrid
          gridColumns={gridColumns}
          gridData={gridData}
          update={() => {
            isUpdate(!update);
          }}
        />
      )}
    </div>
  );
};

export default PlateReservationStatusList;
