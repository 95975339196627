import React, { useState } from 'react';
import { GOFloatingbox, GOButton, GOInput, SmallTitle, GODropbox } from '../../../components';
import { useTranslation } from 'react-i18next';
import { NetUserTicketSearch } from '../../../actions';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { actionPostPlateOpen } from '../../../stores/actions/plates';
import dayjs from 'dayjs';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

const PlateAddModal = ({ onClickExit, data }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let inData = { search: '', date: 60, dateGuest: 60 };
  const [user, setUser] = useState<any>({
    name: '',
    id: '',
    ticketID: 0,
    tickets: [],
  });
  const [totalUser, setTotalUser] = useState<any>();
  const [searchState, setSearchState] = useState<boolean>(false);

  const onSearch = () => {
    NetUserTicketSearch(inData.search, 2).then((res: any) => {
      const userData = res.data.Data.users;
      if (userData?.length > 0) {
        toast(`${userData.length}건이 검색되었습니다.`);
        setTotalUser(userData);
        setSearchState(true);
        setUser({
          ...user,
          name: '',
          id: 0,
          ticketID: 0,
          tickets: [],
        });
      } else {
        toast.error('검색 결과가 없습니다.');
        setUser(user);
        setSearchState(false);
      }
    });
  };

  const handleChange = (e: any) => {
    if (e.target.name === 'userID') {
      totalUser?.filter((tUser: any) => {
        if (tUser.id === JSON.parse(e.target.value)) {
          setUser({
            ...user,
            name: tUser.name,
            id: tUser.id,
            tickets: tUser.tickets,
          });
        }
      });
    } else setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onOpen = (type: number) => {
    if (type === 1 && user.id && user.ticketID) {
      dispatch(
        actionPostPlateOpen(
          data.id,
          {
            startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
            endDate: dayjs()
              .add(inData.date, 'minute')
              .format('YYYY-MM-DDTHH:mm:ss'),
            userID: user.id,
            ticketID: user.ticketID,
          },
          () => {
            onClickExit();
          },
        ),
      );
    } else if (type === 2) {
      dispatch(
        actionPostPlateOpen(
          data.id,
          {
            startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
            endDate: dayjs()
              .add(inData.dateGuest, 'minute')
              .format('YYYY-MM-DDTHH:mm:ss'),
            userID: null,
            ticketID: null,
          },
          () => {
            onClickExit();
          },
        ),
      );
    } else {
      alert('선택한 티켓이 없습니다.');
    }
  };

  return (
    <GOFloatingbox width={740}>
      <UFloatingContainer as="form">
        <UFloatingBoxHeader>
          <h1>타석 열기</h1>
        </UFloatingBoxHeader>
        <UFloatingBoxBody flex>
          <div className="flex_box">
            <SmallTitle body="사용자 선택으로 열기" />
            <GOInput
              label="사용자 검색"
              onChange={(e: any) => (inData.search = e.target.value)}
              button="검색"
              onClickButton={onSearch}
              placeholder="이름, 전화번호"
            />
            {searchState && (
              <>
                <hr />
                <br />
                <GODropbox
                  name="userID"
                  label={`검색 결과: ${totalUser?.length === 0 ? 1 : totalUser?.length}건`}
                  style={{ marginBottom: '20px' }}
                  data={
                    searchState
                      ? totalUser?.map((tUser: any) => {
                          return {
                            value: tUser.id,
                            body: `${tUser.name} / ${tUser.phoneNumber}`,
                          };
                        })
                      : null
                  }
                  value={user.id}
                  onChange={handleChange}
                  required
                />
                <GODropbox
                  name="ticketID"
                  label="이용권 선택"
                  style={{ marginBottom: '20px' }}
                  data={user.tickets?.map((ticket: any) => {
                    const expired: boolean =
                      (dayjs(data.startDate) <= dayjs(ticket.startDate).subtract(9, 'h') &&
                        dayjs(data.endDate) <= dayjs(ticket.startDate).subtract(9, 'h')) ||
                      (dayjs(data.startDate) >= dayjs(ticket.endDate).add(15, 'h') &&
                        dayjs(data.endDate) >= dayjs(ticket.endDate).add(15, 'h'));
                    return {
                      value: ticket.id,
                      body: `${expired ? '[ 기간 외 이용권 ] ' : ' '}${ticket.name} / ${dayjs(
                        ticket.startDate,
                      ).format('MM. DD')} ~ ${dayjs(ticket.endDate).format('MM. DD')} / ${
                        ticket.isUnlimited
                          ? t('product.unlimited')
                          : `${t('product.remainingCount')} : ${ticket.remainingCount}`
                      }`,
                    };
                  })}
                  value={user.ticketID}
                  onChange={handleChange}
                  required
                />
                <GOInput
                  label="시간 설정"
                  defaultValue={60}
                  onChange={(e: any) => (inData.date = e.target.value)}
                  type="number"
                  min="0"
                  max="999"
                  unit="분"
                  button="열기"
                  onClickButton={() => onOpen(1)}
                />
              </>
            )}
          </div>
          <div className="flex_box">
            <SmallTitle body="게스트 열기" />
            <GOInput
              label="시간 설정"
              defaultValue={60}
              onChange={(e: any) => (inData.dateGuest = e.target.value)}
              type="number"
              min="0"
              max="999"
              unit="분"
              button="열기"
              onClickButton={() => onOpen(2)}
            />
          </div>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton body="취소" onClick={onClickExit} />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};

export default PlateAddModal;
