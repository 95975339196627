import NetworkManager from "../../../NetworkManager";

const LessonReport = (uId: string, eId: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager(
      "get",
      `admin/employee/lessonLogs/list/info?userID=${uId}&employeeID=${eId}`
    )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default LessonReport;
