import React, { useEffect } from 'react';
import {
  HeaderBottom,
  StatusTool,
  SegmentControl,
  LockerView,
  GOButton,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { actionGetLockerList, actionGetSettingsList } from '../../../stores/actions';
import { useDispatch, useSelector } from 'react-redux';
import { onModal } from '../../../stores/modals';

const LockerList = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const locker = useSelector((state: any) => {
    return state['locker'];
  });

  const settings = useSelector((state: any) => {
    console.log('state -> settings', state['settings']);
    return state['settings'];
  });

  useEffect(() => {
    dispatch(actionGetLockerList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actionGetSettingsList());
  }, [dispatch]);

  return (
    <div>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={t('locker.list')} />
          </>
        }
        rightmenu={
          <>
            <GOButton
              buttontype="green"
              body={t('locker.add')}
              onClick={() => dispatch(onModal('lockerAdd'))}
            />
          </>
        }
      />
      {settings &&
      settings.data?.settings?.modalSettingShowInSetting === 1 &&
      settings.data?.settings?.modalSettingShowModalYN === 1 ? (
        <SegmentControl
          data={[
            {
              name: t('plate.list'),
              link: '/admin/plate/list',
            },
            {
              name: t('locker.list'),
              link: '/admin/plate/lockerlist',
            },
            {
              name: '타석 로그인',
              link: '/admin/plate/plateloginlist',
            },
          ]}
        />
      ) : (
        <SegmentControl
          data={[
            {
              name: t('plate.list'),
              link: '/admin/plate/list',
            },
            {
              name: t('locker.list'),
              link: '/admin/plate/lockerlist',
            },
          ]}
        />
      )}
      <LockerView data={locker} />
    </div>
  );
};
export default LockerList;
