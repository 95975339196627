import styled from 'styled-components';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const PostCodeBox = styled.div`
  flex: 1 1 auto;
  display: flex;
  position: relative;
  overflow: auto;

  ${BreakPoints.sm} {
    & div {
      position: static !important;
      min-width: initial !important;
    }
    iframe {
      min-width: initial !important;
    }
  }
`;
