import React from "react";
import { SecretPin } from "../../../components";

const SmartAdvisorPassword = ({ onClickExit }: any) => {
  return (
    <div>
      <SecretPin path="/admin/smartadvisor" onClick={onClickExit} />
    </div>
  );
};

export default SmartAdvisorPassword;
