import React, { useState, useEffect, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import * as Styled from './styles';
import { NetProductEmployeeList, ProductTypeChanger } from '../../../actions';
import {
  GOFloatingbox,
  GODropbox,
  GOInput,
  GOButton,
  GOLabel,
  GOTextarea,
  SmallTitle,
  GOSwitch,
} from '../../../components';
import { useDispatch } from 'react-redux';
import { actionPostProduct, actionPutProduct, actionDelProduct } from '../../../stores/actions';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

interface dataType {
  id: number | string;
  type: number;
  name: string;
  place: string;
  memo: string;
  Products?: any;
  edit?: any;
}

const Add = (props: { onClickExit: any; data: dataType; update?: any }) => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    id: 0,
    categoryID: props.data.id,
    period: '',
    name: '',
    totalCount: 0,
    isUnlimited: false,
    onlyOnceToday: true,
    price: '',
    memo: '',
    employeeID: '',
  });
  const [employeeList, setEmployeeList] = useState([{ id: 0, name: '', type: 0 }]);

  const employeeListFilter = employeeList.filter((e: any) => e.type === 2);

  const dispatch = useDispatch();

  useEffect(() => {
    NetProductEmployeeList().then((res: any) => setEmployeeList(res.data.Data.Employees));
    if (props.data.edit) {
      setData(props.data.edit);
    }
  }, [props.data]);

  function comma(str: any) {
    str = String(str);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  function unComma(str: any) {
    str = String(str);
    return str.replace(/[^\d]+/g, '');
  }

  const handleChange = (e: any) => {
    if (e.target.name === 'price') {
      setData({ ...data, [e.target.name]: unComma(e.target.value) });
    } else if (e.target.name === 'employeeID') {
      setData({ ...data, [e.target.name]: parseInt(e.target.value) });
    } else setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: FormEvent<EventTarget>) => {
    e.preventDefault();
    if (props.data.edit) {
      if (props.data.edit?.employeeID !== data.employeeID) {
        if (
          window.confirm(
            '담당프로를 수정하시겠습니까? \n수정 후에는 해당 상품을 구매한 모든 회원에 적용됩니다.',
          )
        ) {
          dispatch(
            actionPutProduct(data.id, data, () => {
              props.onClickExit();
            }),
          );
        }
      }
      // 수정
      else if (window.confirm(t('product.isEdit'))) {
        dispatch(
          actionPutProduct(data.id, data, () => {
            props.onClickExit();
          }),
        );
      }
    } else {
      // 추가
      if (window.confirm(t('product.isAdd'))) {
        dispatch(
          actionPostProduct(data, () => {
            props.onClickExit();
          }),
        );
      }
    }
  };

  const delProduct = () => {
    if (window.confirm(t('product.isDel')))
      dispatch(
        actionDelProduct(data.id, () => {
          props.onClickExit();
        }),
      );
  };

  return (
    <GOFloatingbox width={1100}>
      <UFloatingContainer as="form" onSubmit={handleSubmit}>
        <UFloatingBoxHeader>
          <h1>{props.data.edit ? t('product.edit') : t('product.add')}</h1>
          <p>{props.data.edit ? t('product.editInfo') : t('product.addInfo')}</p>
        </UFloatingBoxHeader>
        <UFloatingBoxBody flex>
          <div className="flex_box">
            <SmallTitle body={t('product.category')} />
            <GOLabel
              label={t('product.serviceCategory')}
              body={
                props.data.edit
                  ? ProductTypeChanger(props.data.edit.type)
                  : ProductTypeChanger(props.data.type)
              }
            />
            <GOLabel
              label={t('product.categoryName')}
              body={props.data.edit ? props.data.edit.ProductCategory.name : props.data.name}
            />
            <hr />
            <h6>
              <GOTextarea body={props.data.memo} />
            </h6>
          </div>
          <div className="flex_box">
            <SmallTitle body={t('product.add')} />
            <GOInput
              label={t('product.name')}
              name="name"
              type="text"
              value={data.name}
              onChange={handleChange}
              required
            />
            <GOInput
              label={t('product.period')}
              unit="일"
              name="period"
              value={data.period}
              min="0"
              max="999"
              onChange={handleChange}
              type="number"
              required
            />
            {props.data.type !== 4 ? (
              <>
                <GOInput
                  label={t('product.totalCount')}
                  unit="번"
                  name="totalCount"
                  value={data.totalCount}
                  onChange={handleChange}
                  type="number"
                  min="0"
                  max="999"
                  disabled={data.isUnlimited}
                  required
                />
                <GOSwitch
                  label={t('product.isUnlimited')}
                  onClick={() => {
                    setData({ ...data, isUnlimited: !data.isUnlimited });
                  }}
                  status={data.isUnlimited}
                />
              </>
            ) : (
              <></>
            )}
            <GOInput
              label={t('product.price')}
              unit="원"
              name="price"
              value={comma(unComma(data.price))}
              onChange={handleChange}
              type="text"
              required
            />
            <GOSwitch
              label={t('product.onlyOnceToday')}
              onClick={() => {
                setData({
                  ...data,
                  onlyOnceToday: !data.onlyOnceToday,
                });
              }}
              status={data.onlyOnceToday}
            />
            {props.data.edit?.type === 0 || props.data.type === 0 ? (
              <>
                <GODropbox
                  name="employeeID"
                  label={t('product.employee')}
                  data={[
                    ...employeeListFilter.map((el: any) => {
                      return { body: el.name, value: el.id };
                    }),
                  ]}
                  onChange={handleChange}
                  value={data.employeeID}
                  required
                />
                {props.data.edit && (
                  <>
                    {props.data.edit?.employeeID !== data.employeeID && (
                      <Styled.AlertMessage>
                        * 담당프로 수정시, 해당 티켓을 보유한 모든 회원에게 적용됩니다.
                      </Styled.AlertMessage>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <GODropbox
                  name="employeeID"
                  label={t('product.employee')}
                  data={[
                    ...employeeList.map((el: any) => {
                      return { body: el.name, value: el.id };
                    }),
                  ]}
                  onChange={handleChange}
                  value={data.employeeID}
                  required
                />
              </>
            )}
          </div>
          <div className="flex_box">
            <SmallTitle body={t('product.pmemo')} />
            <GOInput
              label={t('product.memo')}
              name="memo"
              value={data.memo}
              onChange={handleChange}
              textarea
            />
          </div>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton buttontype="green" body={t('common.ok')} type="submit" />
          {props.data.edit && (
            <GOButton buttontype="red" body={t('common.del')} onClick={delProduct} />
          )}
          <GOButton body={t('common.no')} onClick={props.onClickExit} />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};
export default Add;
