import styled from 'styled-components';
import * as color from '../../../color/index';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  ${BreakPoints.md} {
    margin-bottom: 10px;
  }
`;

export const Day = styled.div`
  position: relative;
  font-size: 20px;
  font-weight: 700;
  color: ${color.Colors.primary};
`;

export const SwitchBox = styled.div`
  & > div {
    margin: 0;
  }
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${color.Colors.primary};
`;

export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${color.Colors.primary};
  margin-top: 20px;
`;
