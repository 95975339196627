export const Colors = {
  white: '#fff',
  primary: '#495057',
  effect: '#adb5bd',
  background: '#f9f9f9',
  line: '#ddd',
  sub: '#eaecef',
  green: '#12b886',
  subEffect: '#ced4da',
  red: '#fa5252',
  deepRed: '#f76707',
  lightRed: '#ffe3e3',
  blue: '#657bf2',
};
