import React from "react";
import ReactLoading from "react-loading";
import * as Styled from "./styles";

const GOLoading = () => {
  return (
    <Styled.Loading>
      <ReactLoading type={"bubbles"} color={"black"} width={52} height={52} />
      &nbsp; 불러오는 중...
    </Styled.Loading>
  );
};

export default GOLoading;
