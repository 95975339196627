import produce from 'immer';

import {
  REQ,
  ONLY_REQ,
  REQ_DETAIL,
  REQUEST_WAITING,
  REQUEST_START,
  REQUEST_SUCCESS,
  REQUEST_FAILURE,
} from './actionTypes';
import { handle } from 'redux-pack';

export default (...reducerNames: any) => {
  return reducerNames.reduce((apiReducers: any, name: any) => {
    const initialState = {
      status: REQUEST_WAITING,
      statusDetail: REQUEST_WAITING,
      data: '',
      detail: '',
    };

    apiReducers[name] = (state: any = initialState, action: any) => {
      const { type, payload, meta } = action;
      const { resourceName } = meta || {};

      if (resourceName !== name) {
        return state;
      }

      return produce(state, (draft: any) => {
        switch (type) {
          case REQ:
            return handle(state, action, {
              start: prevState => ({
                ...prevState,
                status: state.status === REQUEST_WAITING ? REQUEST_START : state.status,
              }),
              success: prevState => ({
                ...prevState,
                status: REQUEST_SUCCESS,
                data: payload.data.Data,
              }),
              failure: prevState => ({
                ...prevState,
                status: REQUEST_FAILURE,
                err: payload.response,
              }),
            });
          case REQ_DETAIL:
            return handle(state, action, {
              start: prevState => ({
                ...prevState,
                statusDetail: REQUEST_WAITING,
              }),
              success: prevState => ({
                ...prevState,
                statusDetail: REQUEST_SUCCESS,
                detail: payload.data.Data,
              }),
              failure: prevState => ({
                ...prevState,
                statusDetail: REQUEST_FAILURE,
                err: payload.response,
              }),
            });
          case ONLY_REQ:
          default:
            return draft;
        }
      });
    };
    return apiReducers;
  }, {});
};
