import React, { useState, useEffect } from 'react';
import { NetSmartAdvisorCurrentMonthAccounting } from '../../../actions';
import dayjs from 'dayjs';
import MonthlySalesChart from '../../../components/smartadvisor/MonthlySales';

const MonthlySales = () => {
  const [age, setAge] = useState();
  const [sex, setSex] = useState();
  const [loading, setLoading] = useState(true);
  const startDate = dayjs()
    .startOf('month')
    .format('YYYY-MM-DD');
  const endDate = dayjs()
    .endOf('month')
    .format('YYYY-MM-DD');

  useEffect(() => {
    const fetchAPI = async () => {
      await NetSmartAdvisorCurrentMonthAccounting(startDate, endDate, 'sex').then((res: any) => {
        setSex(res.data.Data.dataList);
      });
      await NetSmartAdvisorCurrentMonthAccounting(startDate, endDate, 'age').then((res: any) => {
        setAge(res.data.Data.dataList);
        setLoading(false);
      });
    };
    console.log('💠 당월 매출 현황 Network');
    fetchAPI();
  }, [startDate, endDate]);

  return <MonthlySalesChart age={age} sex={sex} loading={loading} />;
};

export default MonthlySales;
