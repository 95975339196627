import styled from 'styled-components';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const PanelTitle = styled.div`
  font-size: 2.63vw;
  font-weight: 700;
  color: #495057;
`;

export const PanelDescription = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #495057;
  ${BreakPoints.sm} {
    font-size: 16px;
  }
`;

export const PanelContainer = styled.div`
  max-height: 600px;
  overflow: auto;
  ${BreakPoints.sm} {
    max-height: initial;
  }
`;

export const PanelWrapper = styled.div`
  display: flex;
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-weight: 700;
  align-items: center;
  background: #f9f9f9;
  padding: 8px 15px;
  margin-bottom: 20px;
  border-radius: 5px;
`;

export const PlateBox = styled.div`
  width: 18.5vw;
  height: 3.6vw;
  margin-right: 2vw;
`;

export const LessonPlateBox = styled.div`
  width: 9.6vw;
  height: 3.6vw;
`;

export const PlateCheckWrapper = styled.div`
  margin-top: 1vw;
  margin-right: 1vw;
  margin-left: 0.5vw;
`;

export const PlateTitle = styled.div`
  font-size: 1.66vw;
  color: #495057;
`;

export const PlateTicketName = styled.div`
  font-size: 1vw;
  color: #989898;
`;

export const VerticalLine = styled.div`
  width: 1px;
  height: 2.47vw;
  background-color: #ddd;
  margin-top: 1.04vw;
  // margin-left: 2.6vw;
  margin-right: 2vw;
`;

export const SecondVerticalLine = styled.div`
  width: 1px;
  height: 2.47vw;
  background-color: #ddd;
  margin-top: 1.04vw;
  margin-left: 2vw;
  margin-right: 2vw;
`;

export const PlateTimeBox = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  width: 15.28vw;
  height: 3.6vw;
  margin-top: 0.6vw;
  font-size: 1.4vw;
`;

export const PlateTimeWrapper = styled.div``;

export const PlateStartTime = styled.div`
  color: #989898;
`;

export const PlateEndTime = styled.div`
  color: #989898;
`;

export const PlateIcon = styled.div`
  margin-top: 1.04vw;
  margin-left: 1.95vw;
  margin-right: 1.95vw;
  // color: #495057;
`;

export const UsePlateBox = styled.div`
  width: 6.95vw;
  height: 3.6vw;
  margin-top: 0.6vw;
  text-align: center;
  font-size: 1.4vw;
`;

export const UsePlateName = styled.div`
  color: #989898;
`;

export const ErrorMessage = styled.div`
  font-weight: 700;
  color: #989898;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const ConfirmButton = styled.button`
  width: 9.11vw;
  height: 2.5vw;
  cursor: pointer;
  border: 1px solid #12b886;
  border-radius: 5px;
  text-align: center;
  font-size: 0.8vw;
  font-weight: bold;
  background: #12b886;
  color: #fff;
  box-shadow: 0 3px 6px rgba(18, 184, 134, 0.3);
  transition: 0.25s;
  box-sizing: border-box;

  &:hover {
    background: #149268;
    transition: 0.25s;
  }

  &:focus {
    outline: none;
  }
`;

export const CloseButton = styled.button`
  width: 9.11vw;
  height: 2.5vw;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  font-size: 0.8vw;
  font-weight: bold;
  background: #fff;
  color: #495057;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  transition: 0.25s;
  box-sizing: border-box;

  &:hover {
    background: #f2f2f2;
    transition: 0.25s;
  }

  &:focus {
    outline: none;
  }
`;
