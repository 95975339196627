export default function UserAge(data: any) {
  let count20s;
  let count30s;
  let count40s;
  let count50s;
  let count60s;

  if (data) {
    const filter = {
      underTweties: data?.filter((a: any) => a.r10Age === 1),
      thirties: data?.filter((a: any) => a.r10Age === 2),
      fourties: data?.filter((a: any) => a.r10Age === 3),
      fifties: data?.filter((a: any) => a.r10Age === 4),
      overSixties: data?.filter((a: any) => a.r10Age === 5),
    };

    count20s =
      filter.underTweties && filter.underTweties[0]?.cnt + filter.underTweties[1]?.cnt > 0
        ? filter.underTweties[0]?.cnt + filter.underTweties[1]?.cnt
        : 0;
    count30s =
      filter.thirties && filter.thirties[0]?.cnt + filter.thirties[1]?.cnt > 0
        ? filter.thirties[0]?.cnt + filter.thirties[1]?.cnt
        : 0;
    count40s =
      filter.fourties && filter.fourties[0]?.cnt + filter.fourties[1]?.cnt > 0
        ? filter.fourties[0]?.cnt + filter.fourties[1]?.cnt
        : 0;
    count50s =
      filter.fifties && filter.fifties[0]?.cnt + filter.fifties[1]?.cnt > 0
        ? filter.fifties[0]?.cnt + filter.fifties[1]?.cnt
        : 0;
    count60s =
      filter.overSixties && filter.overSixties[0]?.cnt + filter.overSixties[1]?.cnt > 0
        ? filter.overSixties[0]?.cnt + filter.overSixties[1]?.cnt
        : 0;
  }

  return { count20s, count30s, count40s, count50s, count60s };
}
