import React from 'react';
import * as Styled from './styles';

interface GOLabelProps {
  label?: string | number | any;
  body?: string | number | JSX.Element | any;
  style?: any;
  bodyStyle?: object;
}

const GOLabel = (props: GOLabelProps) => {
  return (
    <Styled.Label style={props.style}>
      <Styled.LabelItem className="go_label">{props.label}</Styled.LabelItem>
      <Styled.LabelBody style={props.bodyStyle}>{props.body}</Styled.LabelBody>
    </Styled.Label>
  );
};

export default GOLabel;
