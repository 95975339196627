import React, { useState, useEffect } from 'react';
import * as Styled from './styles';
import { GOButton } from '../../../components';
import dayjs from 'dayjs';
import {
  NetUncheckReservation,
  NetReservationResCheckIn,
  NetReservationResAbsent,
  NetReservationResCancel,
  NetLessonResCheckIn,
  NetLessonResAbsent,
  NetLessonResCancel,
} from '../../../actions';
import { phoneNumberFormatting } from '../../../middlewares';
import GOLoading from '../../common/GOLoading';

const NonConfirmReservations = () => {
  const [currentDay, setCurrentDay] = useState<any>(
    new Date(new Date().setDate(new Date().getDate() - 1)),
  );
  const [update, isUpdate] = useState<any>(false);
  const [data, setData] = useState<any>([]);
  const [originalData, setOriginalData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<any>({
    totalChecked: false,
    plateChecked: false,
    lessonChecked: false,
  });

  let Year = currentDay.getFullYear();
  let Month = currentDay.getMonth() + 1;
  let Day = currentDay.getDate();

  let currentFullDay = `${Year}.${Month < 10 ? `0${Month}` : `${Month}`}.${
    Day < 10 ? `0${Day}` : `${Day}`
  }`;

  const handleSelectDay = (date: any) => {
    setCurrentDay(new Date(date));
  };

  const handlePreviousDay = () => {
    setCurrentDay(new Date(new Date(currentDay).setDate(new Date(currentDay).getDate() - 1)));
  };

  const handleNextDay = () => {
    setCurrentDay(new Date(new Date(currentDay).setDate(new Date(currentDay).getDate() + 1)));
  };

  const onResCheckIn = (rId: any, rType: any) => {
    if (rType === 0) {
      if (window.confirm('출석 처리하시겠습니까?')) {
        NetReservationResCheckIn(rId).then(res => {
          alert('출석 처리되었습니다.');
          isUpdate(!update);
        });
      }
    } else {
      if (window.confirm('출석 처리하시겠습니까?')) {
        NetLessonResCheckIn(rId).then(res => {
          alert('출석 처리되었습니다.');
          isUpdate(!update);
        });
      }
    }
  };

  const onResAbsent = (rId: any, rType: any) => {
    if (rType === 0) {
      if (window.confirm('결석 처리하시겠습니까?')) {
        NetReservationResAbsent(rId).then(res => {
          alert('결석 처리되었습니다.');
          isUpdate(!update);
        });
      }
    } else {
      if (window.confirm('결석 처리하시겠습니까?')) {
        NetLessonResAbsent(rId).then(res => {
          alert('결석 처리되었습니다.');
          isUpdate(!update);
        });
      }
    }
  };

  const onResCancel = (rId: any, rType: any) => {
    if (rType === 0) {
      if (window.confirm('예약을 취소하시겠습니까?')) {
        NetReservationResCancel(rId).then(res => {
          alert('예약이 취소되었습니다.');
          isUpdate(!update);
        });
      }
    } else {
      if (window.confirm('예약을 취소하시겠습니까?')) {
        NetLessonResCancel(rId).then(res => {
          alert('예약이 취소되었습니다.');
          isUpdate(!update);
        });
      }
    }
  };

  const handleFilterChange = (e: any) => {
    if (e.target.name === 'total') {
      setFilter({
        totalChecked: true,
        plateChecked: false,
        lessonChecked: false,
      });
    } else if (e.target.name === 'plate') {
      setFilter({
        totalChecked: false,
        plateChecked: true,
        lessonChecked: false,
      });
    } else if (e.target.name === 'lesson') {
      setFilter({
        totalChecked: false,
        plateChecked: false,
        lessonChecked: true,
      });
    }
  };

  useEffect(() => {
    NetUncheckReservation(currentFullDay)
      .then((res: any) => {
        setData(res.data.Data.uncheckedReservations);
        setOriginalData(res.data.Data.uncheckedReservations);
        setLoading(false);
      })
      .catch((err: any) => console.log(err));
  }, [currentFullDay, update]);

  // 타석, 개인레슨 필터
  useEffect(() => {
    let filterData = originalData;

    switch (filter.totalChecked) {
      case true:
        filterData = originalData;
        break;
    }

    switch (filter.plateChecked) {
      case true:
        filterData = filterData.filter((d: any) => d.type === 0);
        break;
    }

    switch (filter.lessonChecked) {
      case true:
        filterData = filterData.filter((d: any) => d.type === 1);
        break;
    }

    if (filterData) {
      setData(filterData);
    }
  }, [filter, originalData]);

  let sortingList = data.sort((a: any, b: any) => {
    let dateA = new Date(a['startDate']).getTime();
    let dateB = new Date(b['startDate']).getTime();

    if (dateA < dateB) return -1;
    else return 0;
  });

  return (
    <Styled.Container>
      <Styled.BottomBox>
        <Styled.HeaderBox>
          <div>
            <Styled.Title>미확인 예약</Styled.Title>
            <hr />
            <Styled.CalendarBox>
              <div className="calendar">
                <Styled.BtnArrow onClick={handlePreviousDay}>
                  <img src="/images/left_arrow.png" alt="arrow" />
                </Styled.BtnArrow>
                <Styled.CustomDate
                  selected={currentDay}
                  value={currentFullDay}
                  onChange={(date: any) => {
                    handleSelectDay(date);
                  }}
                />
                <Styled.BtnArrow onClick={handleNextDay}>
                  <img src="/images/right_arrow.png" alt="arrow" />
                </Styled.BtnArrow>
              </div>
              <Styled.Checkbox>
                <div className="inputWrapper">
                  <input
                    type="checkbox"
                    name="total"
                    onChange={handleFilterChange}
                    checked={filter.totalChecked}
                  />
                  <label className="checkLabel">전체</label>
                </div>
                <div className="inputWrapper">
                  <input
                    type="checkbox"
                    name="plate"
                    onChange={handleFilterChange}
                    checked={filter.plateChecked}
                  />
                  <label className="checkLabel">타석</label>
                </div>
                <div className="inputWrapper">
                  <input
                    type="checkbox"
                    name="lesson"
                    onChange={handleFilterChange}
                    checked={filter.lessonChecked}
                  />
                  <label className="checkLabel">개인레슨</label>
                </div>
              </Styled.Checkbox>
            </Styled.CalendarBox>
          </div>
        </Styled.HeaderBox>
        <Styled.PanelBox>
          {loading ? (
            <>
              <GOLoading />
            </>
          ) : (
            <>
              {sortingList?.length > 0 ? (
                <>
                  {sortingList.map((nData: any, index: number) => (
                    <React.Fragment key={index}>
                      <Styled.NonConfirmPanel>
                        <Styled.InnerBox>
                          <Styled.InnerWrapper>
                            <Styled.NTitle>{nData.type === 0 ? `타석` : `개인레슨`}</Styled.NTitle>
                            <Styled.NText>
                              <div>{dayjs(nData.startDate).format('HH:mm')}</div>
                              <Styled.Slash>~</Styled.Slash>
                              <div>{dayjs(nData.endDate).format('HH:mm')}</div>
                            </Styled.NText>
                          </Styled.InnerWrapper>
                          <Styled.InnerWrapper>
                            <Styled.NInfo>
                              <div>
                                <span>{nData.userName}</span>
                                <Styled.Slash>/</Styled.Slash>
                                <span>{phoneNumberFormatting(nData.phoneNumber)}</span>
                              </div>
                            </Styled.NInfo>
                          </Styled.InnerWrapper>
                        </Styled.InnerBox>
                        <Styled.SecondInnerBox>
                          <GOButton
                            body={'출석'}
                            size="medium"
                            onClick={() => {
                              onResCheckIn(nData.reservationID, nData.type);
                            }}
                          />
                          <GOButton
                            body={'결석'}
                            size="medium"
                            onClick={() => {
                              onResAbsent(nData.reservationID, nData.type);
                            }}
                          />
                          <GOButton
                            body={'예약취소'}
                            size="medium"
                            buttontype="red"
                            onClick={() => {
                              onResCancel(nData.reservationID, nData.type);
                            }}
                          />
                        </Styled.SecondInnerBox>
                      </Styled.NonConfirmPanel>
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <>
                  <Styled.NoDataText>미확인 예약이 없습니다.</Styled.NoDataText>
                </>
              )}
            </>
          )}
        </Styled.PanelBox>
      </Styled.BottomBox>
    </Styled.Container>
  );
};

export default NonConfirmReservations;
