import React from 'react';
import Grid from '@toast-ui/react-grid';
import { GODropbox, GOLoading } from '../../../components';
import * as Styled from './styles';

const TestStateBoard = ({
  loading,
  gridColumns,
  pageOption,
  res,
  comboBoxData,
  selectedYearMonth,
  setSelectedYearMonth,
}: any) => {
  return (
    <>
      {loading ? (
        <GOLoading />
      ) : (
        <>
          <Styled.Title>
            <div className="title">월말 시험</div>
            <div className="grp-status">
              {`${Number(selectedYearMonth.toString().slice(-2))}`}월 시험{' '}
              <span className="tag-timer"></span>
              <span className="tag-status">
                {res.data.Data.examSummary.topInBranch.name == '-' ? '미정' : '완료'}
              </span>
            </div>
            <GODropbox
              data={comboBoxData}
              isDefault
              value={selectedYearMonth}
              style={{ width: '120px' }}
              onChange={(e: any) => {
                setSelectedYearMonth(e.target.value);
              }}
            />
          </Styled.Title>
          <Styled.SummaryList>
            <Styled.ListItem>
              <div className="label">참여도</div>
              <div className="status">
                <span className="txt-point">
                  {res.data.Data.examSummary.myParticipantStatus.count}
                </span>
                명 참여
                <span className="bedge">
                  {res.data.Data.examSummary.myParticipantStatus.ranking}위
                </span>
              </div>
            </Styled.ListItem>
            <Styled.ListItem>
              <div className="label">참여도 전국 1위</div>
              <div className="status">
                <span className="txt-point">
                  {res.data.Data.examSummary.topParticipantStatus.count}
                </span>
                명 참여
                <span className="bedge">
                  {res.data.Data.examSummary.topParticipantStatus.ranking}위
                </span>
              </div>
            </Styled.ListItem>
            <Styled.ListItem>
              <div className="label">평균성적</div>
              <div className="status">
                <span className="txt-point">{res.data.Data.examSummary.averageScore.score}</span>점
                <span className="bedge">{res.data.Data.examSummary.averageScore.ranking}위</span>
              </div>
            </Styled.ListItem>
            <Styled.ListItem>
              <div className="label">우리 훈련소 최고점자</div>
              <div className="status">
                {res.data.Data.examSummary.topInBranch.name}
                <span className="bedge">{res.data.Data.examSummary.topInBranch.score}</span>
              </div>
            </Styled.ListItem>
            {/*<Styled.ListItem>*/}
            {/*  <div className="label">향상도</div>*/}
            {/*  <div className="status">*/}
            {/*    총 <span className="txt-point">331</span>점<span className="bedge">15위</span>*/}
            {/*  </div>*/}
            {/*</Styled.ListItem>*/}
            {/*<Styled.ListItem>*/}
            {/*  <div className="label">우리 훈련소 최고 향상자</div>*/}
            {/*  <div className="status">*/}
            {/*    최미소<span className="bedge">+51점</span>*/}
            {/*  </div>*/}
            {/*</Styled.ListItem>*/}
          </Styled.SummaryList>

          <Styled.Title>
            <div className="title depth-2">상세정보</div>
            {/*<GODropbox*/}
            {/*  data={[*/}
            {/*    { value: 0, body: '평균성적순' },*/}
            {/*    { value: 1, body: '향상점수순' },*/}
            {/*  ]}*/}
            {/*  isDefault*/}
            {/*  style={{ width: '120px' }}*/}
            {/*/>*/}
          </Styled.Title>
          <Grid
            data={res.data.Data.dataList}
            columns={gridColumns}
            rowHeight={24}
            // bodyHeight={1200}
            // heightResizable={false}
            // rowHeaders={['rowNum']}
            pageOptions={pageOption}
            columnOptions={{ resizable: true }}
          />
        </>
      )}
    </>
  );
};

export default TestStateBoard;
