import styled from 'styled-components';
import * as color from '../../../color/index';

export const Layout = styled.div`
  background: ${color.Colors.white};
  margin: 16px 28px;
  padding-bottom: 20px;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  justify-content: center;
`;
