import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { GOButton } from '../../../components';
import { actionPutEmployeeLessonTime } from '../../../stores/actions';
import * as Styled from './styles';
import dayjs from 'dayjs';
import ScheduleManageDetailBox from '../ScheduleManageDetailBox';

interface LessonTimeProps {
  data: any;
  date: any;
  update: boolean;
}

const ScheduleManageDetail = (props: LessonTimeProps) => {
  const data = props.data.workTimes?.slice().sort((a: any, b: any) => {
    return a['day'] - b['day'];
  });

  return (
    <Styled.Container>
      {props.update ? (
        <Styled.ViewBox>
          <ScheduleManageDetailBox
            selectedDayIndex={props.date}
            workTimes={data}
            employID={props.data.employee.id}
          />
        </Styled.ViewBox>
      ) : (
        <Styled.ViewBox>
          <Styled.Text>요일을 선택해주세요.</Styled.Text>
        </Styled.ViewBox>
      )}
    </Styled.Container>
  );
};

export default ScheduleManageDetail;
