import { toast } from 'react-toastify';
import NetworkManager from '../../../NetworkManager';

const Edit = (data: object, id: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager('put', `admin/user/${id}`, data)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        if (err) toast.error(`⛔️ 이미 존재하는 휴대폰 번호입니다 ( 404 )`);
      });
  });
};

export default Edit;
