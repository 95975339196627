import styled, { css } from 'styled-components';

/***********************************
 * 공통 변수
 ***********************************/

export const BreakPoints = {
  xs: '@media (max-width: 360px)',
  sm: '@media (max-width: 768px)',
  md: '@media (max-width: 1024px)',
  lg: '@media (max-width: 1280px)',
  xl: '@media (min-width: 1281px)',
};

export const HorizontalGlobalPadding = {
  sm: '15px',
  md: '20px',
  lg: '30px',
  xl: '50px',
};

export const ContentBodyPadding = {
  sm: '15px 15px 30px',
  md: '30px 20px 30px',
  lg: '30px 30px 40px',
  xl: '30px 50px 40px',
};

export const ContentBodyMaxWidth = '1480px';
export const ContentBodyMinWidth = '280px';

/***********************************
 * 모달 관련 (FloatingBox)
 * 구조
 * <UFloatingContainer>
 *  <UFloatingBoxHeader />
 *  <UFloatingBoxBody />
 *  <UFloatingBoxFooter />
 * </UFloatingContainer>
 ***********************************/

/* 모달 내 루트 컨테이너 : 폼 일경우 <UFloatingContainer as="form"> 옵션 추가 */
export const UFloatingContainer = styled.section`
  flex-grow: 1;
  ${BreakPoints.sm} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

interface uFloatingBoxHeaderProps {
  textCenter?: boolean;
}

export const UFloatingBoxHeader = styled.header<uFloatingBoxHeaderProps>`
  margin-bottom: 20px;
  text-align: ${props => (props.textCenter ? 'center' : 'left')};
  h1 {
    font-size: 32px;
    font-weight: 700;
    color: #495057;
  }

  ${BreakPoints.sm} {
    margin-bottom: 15px;
    h1 {
      font-size: 26px;
    }
  }
`;

interface uFloatingBoxBodyProps {
  flex?: boolean;
}

export const UFloatingBoxBody = styled.div<uFloatingBoxBodyProps>`
  overflow: auto;
  flex: 1 1 auto;
  overflow-x: hidden;

  .flex_box {
    padding: 0 16px;
    width: 100%;
    flex: 1 1 auto;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &:not(:last-child) {
      border-right: 1px solid #ddd;
    }

    ${BreakPoints.md} {
      &:nth-of-type(even) {
        border-right: none;
        padding-right: 0;
      }
      &:nth-of-type(odd) {
        padding-left: 0;
      }
    }

    ${BreakPoints.sm} {
      padding: 0;
      flex: 0;
      &:not(:last-child) {
        border-right: none;
      }

      & + .flex_box {
        margin-top: 10px;
      }

      // sm 사이즈에서 .flex_box - height 리사이징 되도록 처리(높이 100%)
      &.h_resize {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }

  // flex 옵션이 있을 경우
  ${props => {
    if (props.flex)
      return css`
        display: flex;
        ${BreakPoints.md} {
          flex-wrap: wrap;
        }

        ${BreakPoints.sm} {
          flex-direction: column;
        }

        .flex_box {
          width: 200px; // 최소사이즈
          ${BreakPoints.md} {
            width: 50%;
          }
          ${BreakPoints.sm} {
            width: 100%;
          }
        }
      `;
  }}
`;

export const UFloatingBoxFooter = styled.footer`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  button {
    padding: 0 30px;
  }

  > button:first-child {
    margin-left: 0 !important;
  }

  ${BreakPoints.sm} {
    margin-top: auto;
    padding-top: 10px;
    > button {
      flex-grow: 1;
      padding: 0px 10px;
    }
  }
`;

/***********************************
 * E: 반응형 특수클래스
 ***********************************/

// sm 사이즈 이하에서  hidden 처리
export const UHiddenSM = styled.span`
  ${BreakPoints.sm} {
    display: none;
  }
`;
// xs 사이즈 이하에서 hidden 처리
export const UHiddenXS = styled.span`
  ${BreakPoints.xs} {
    display: none;
  }
`;
