// 액션!=
import createActions from '../controller/createActions';
import { toast } from 'react-toastify';

const { read, onlyRequest } = createActions('product');

export const actionGetProductList = () => read({});

// 상품 목록

export const actionPostProduct = (data: any, onSuccess: Function) =>
  onlyRequest({
    type: 'post',
    params: data,
    meta: { read: 'product', onSuccess },
  });

export const actionPutProduct = (pID: string | number, data: any, onSuccess: Function) =>
  onlyRequest({
    type: 'put',
    url: `product/${pID}`,
    params: data,
    meta: { read: 'product', onSuccess },
  });

export const actionDelProduct = (pID: string | number, onSuccess: Function, onFailure?: Function) =>
  onlyRequest({
    type: 'delete',
    url: `product/${pID}`,
    meta: {
      read: 'product',
      onSuccess,
      onFailure: (err: any) => {
        if (err.response.data) {
          toast.error(`⛔️ ${err.response.data.message} [${err.response.data.statusCode}]`);
        }
      },
    },
  });

// 상품 카테고리

export const actionPostProductCat = (data: any, onSuccess: Function) =>
  onlyRequest({
    type: 'post',
    url: `product/category`,
    params: data,
    meta: { read: 'product', onSuccess },
  });

export const actionPutProductCat = (catID: string | number, data: any, onSuccess: Function) =>
  onlyRequest({
    type: 'put',
    url: `product/category/${catID}`,
    params: data,
    meta: { read: 'product', onSuccess },
  });

export const actionDelProductCat = (catID: string | number, onSuccess: Function) =>
  onlyRequest({
    type: 'delete',
    url: `product/category/${catID}`,
    meta: { read: 'product', onSuccess },
  });
