import React from 'react';
import * as Styled from './styles';

interface FilterProps {
  body?: JSX.Element;
  leftbody?: JSX.Element;
  rightbody?: JSX.Element;
  children?: any;
}

/* 매출관리 */
const GOFilterContainer = (props: FilterProps) => {
  return (
    <Styled.FilterContainer className="filter_container">
      {/* 왼쪽 필터 배치  */}
      <Styled.LeftFilter className="left_filter">{props.leftbody}</Styled.LeftFilter>

      {/* 가운데 필터 배치 */}
      <Styled.CenterFilter className="center_filter">{props.body}</Styled.CenterFilter>

      {/* 오른쪽 필터 배치 */}
      <Styled.RightFilter className="right_filter">{props.rightbody}</Styled.RightFilter>

      {/* 별도 라인을 사용하는 필터 */}
      {props.children && <Styled.EtcRowFilter>{props.children}</Styled.EtcRowFilter>}
    </Styled.FilterContainer>
  );
};

export default GOFilterContainer;
