import React, { useState, useEffect, FormEvent } from 'react';
import * as Styled from './styles';
import PanelLabel from '../PanelLabel';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {
  GOButton,
  GOInput,
  GOSwitch,
  GOFloatingbox,
  GODropbox,
  SmallTitle,
  GOEmoji,
} from '../../../components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ProductTypeChanger, NetEmployeeList } from '../../../actions';
import TicketPause from '../TicketPause';
import { toast } from 'react-toastify';
import { actionPutUserTicketDetail, actionGetUserTicketDetail } from '../../../stores/actions';
//import { EditHistory } from './styles';
import { onModal } from '../../../stores/modals';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

dayjs.extend(isBetween);

interface TicketPanelProps {
  data?: any;
  setData?: any;
  update?: () => void;
  handleDate?: any;
  sales?: boolean;
  userID?: any;
}

const TicketPanel = ({ data, update, setData, handleDate, sales }: TicketPanelProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ticketData, setTicketData] = useState<any>({
    ...data,
    startDate: data.startDate,
    endDate: data.endDate,
    fromRemainingCount: data.remainingCount,
    remainingCount: data.remainingCount,
  });
  const [viewTicketEdit, setTicketEdit] = useState(false);
  const [viewTicketPause, setTicketPause] = useState(false);
  const [employeeList, setEmployeeList] = useState([{ id: 0, name: '', type: 0 }]);

  const employeeListFilter = employeeList.filter((e: any) => e.type === 2);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setTicketData({ ...ticketData, [name]: value });
  };

  const handleSubmit = (e: FormEvent<EventTarget>) => {
    e.preventDefault();
    if (ticketData.endDate > ticketData.startDate) {
      if (window.confirm('수정하시겠습니까?')) {
        dispatch(
          actionPutUserTicketDetail(ticketData.id, ticketData, () => {
            setTicketEdit(false);
            if (update) {
              update();
            }
          }),
        );
      }
    } else {
      toast.error(t('sign.EndDateBiggerStartDateError'));
    }
  };

  const goEdit = () => {
    handleDate(data.startDate, ticketData.period, ticketData);
    setTicketEdit(false);
    if (update) {
      update();
    }
  };

  useEffect(() => {
    if (viewTicketEdit) {
      dispatch(
        actionGetUserTicketDetail(
          data.id,
          (res: any) => {
            if (res.data.Data.Ticket) {
              setTicketData({
                ...res.data.Data.Ticket,
                startDate: res.data.Data.Ticket.startDate,
                endDate: res.data.Data.Ticket.endDate,
                fromRemainingCount: res.data.Data.Ticket.remainingCount,
                remainingCount: res.data.Data.Ticket.remainingCount,
              });
            }
          },
          (err: any) => {
            if (err.response.data.resultCode) {
              toast.error(`⛔️ 이용권 조회 오류 입니다. [${err.response.data.resultCode}]`);
            } else {
              toast.error(`⛔️ 이용권 조회 오류 입니다. [${err.response.data.statusCode}]`);
            }
            setTicketEdit(false);
            if (update) {
              update();
            }
          },
        ),
      );

      NetEmployeeList().then((res: any) => {
        setEmployeeList(res.data.Data.Employees);
      });
    }
  }, [dispatch, viewTicketEdit]);

  return (
    <Styled.Container>
      <Styled.TopBox>
        <div style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              flexWrap: 'wrap',
              whiteSpace: 'nowrap',
            }}
          >
            <div style={{ flex: '1 0 0', lineHeight: '1.2' }}>
              <h6>{ProductTypeChanger(data.type)} </h6>
            </div>
            {!sales && (data.type === 0 || data.type === 2) && (
              <Styled.EditHistory
                onClick={() => {
                  dispatch(onModal('lessonTicketHistory', data));
                  console.log('edit history');
                }}
              >
                <GOEmoji symbol="📝" />
                수정 이력
              </Styled.EditHistory>
            )}
            {data.pauses?.length > 0 &&
              data.pauses.map((p: any) => (
                <React.Fragment key={p.id}>
                  {dayjs().isBetween(`${p.startDate}`, `${p.endDate}`) && (
                    <Styled.PauseBox>일시정지 중</Styled.PauseBox>
                  )}
                </React.Fragment>
              ))}
          </div>
          <Styled.PanelTitle>{data.name}</Styled.PanelTitle>
        </div>
      </Styled.TopBox>
      <hr />
      <Styled.PanelDate>
        {dayjs(data.startDate).format('YYYY.MM.DD')} ~ {dayjs(data.endDate).format('YYYY.MM.DD')}
      </Styled.PanelDate>
      {data.type === 4 ? (
        <div style={{ marginBottom: '80px' }} />
      ) : (
        <>
          <PanelLabel
            label={t('product.totalCount')}
            body={
              data.isUnlimited ? t('product.unlimited') : `${data.totalCount} ${t('common.count')}`
            }
          />
          {!data.isUnlimited && (
            <PanelLabel
              label={t('product.remainingCount')}
              body={`${data.remainingCount} ${t('common.count')}`}
            />
          )}
          <PanelLabel
            label={t('product.usedCount')}
            body={`${data.usedCount} ${t('common.count')}`}
          />
          <PanelLabel
            label={t('product.reservationType')}
            body={data.onlyOnceToday ? t('product.onlyOnceTodayLabel') : t('product.always')}
          />
        </>
      )}
      <Styled.PanelButton>
        {!sales && (
          <GOButton
            size="xlarge"
            buttontype="primary"
            body={t('user.pause')}
            onClick={() => {
              setTicketPause(true);
            }}
            noMarginLeft
          />
        )}

        <GOButton
          size="xlarge"
          buttontype="primary"
          body={t('common.edit')}
          onClick={() => {
            setTicketEdit(true);
          }}
        />
      </Styled.PanelButton>
      {viewTicketEdit && (
        <GOFloatingbox width={750}>
          {!setData && (
            <UFloatingContainer as="form" onSubmit={handleSubmit}>
              <UFloatingBoxHeader>
                <h1>이용권 정보 수정</h1>
                <p>포함 이용권 정보 수정</p>
              </UFloatingBoxHeader>
              <UFloatingBoxBody flex>
                <div className="flex_box">
                  <SmallTitle body="날짜 수정" />
                  <GOInput
                    label="시작 날짜"
                    name="startDate"
                    type="date"
                    value={dayjs(ticketData?.startDate).format('YYYY-MM-DD')}
                    onChange={handleChange}
                    required
                  />
                  <GOInput
                    label="종료 날짜"
                    name="endDate"
                    type="date"
                    value={dayjs(ticketData?.endDate).format('YYYY-MM-DD')}
                    onChange={handleChange}
                    required
                  />
                </div>
                {ticketData.type !== 4 && (
                  <div className="flex_box">
                    <SmallTitle body="일반 이용권" />
                    <GOSwitch
                      label="무제한 여부"
                      status={ticketData.isUnlimited}
                      onClick={() => {
                        setTicketData({
                          ...ticketData,
                          isUnlimited: !ticketData.isUnlimited,
                        });
                      }}
                    />
                    <GOInput
                      label="잔여횟수"
                      type="number"
                      unit="번"
                      min="0"
                      max="999"
                      name="remainingCount"
                      value={ticketData.remainingCount}
                      onChange={handleChange}
                      disabled={ticketData.isUnlimited}
                    />
                    <GOSwitch
                      label={t('product.onlyOnceToday')}
                      status={ticketData.onlyOnceToday}
                      onClick={() => {
                        setTicketData({
                          ...ticketData,
                          onlyOnceToday: !ticketData.onlyOnceToday,
                        });
                      }}
                    />
                    {ticketData.type === 0 ? (
                      <>
                        <GODropbox
                          name="employeeID"
                          label={t('product.employee')}
                          data={[
                            ...employeeListFilter.map((el: any) => {
                              return { body: el.name, value: el.id };
                            }),
                          ]}
                          onChange={handleChange}
                          value={ticketData.employeeID}
                          required
                        />
                      </>
                    ) : (
                      <>
                        {/* <GODropbox
                            name="employeeID"
                            label={t('product.employee')}
                            data={[
                              ...employeeList.map((el: any) => {
                                return { body: el.name, value: el.id };
                              }),
                            ]}
                            onChange={handleChange}
                            value={ticketData.employeeID}
                            required
                          /> */}
                      </>
                    )}
                  </div>
                )}
              </UFloatingBoxBody>
              <UFloatingBoxFooter>
                <GOButton
                  body={t('common.edit')}
                  buttontype="green"
                  type={setData ? 'button' : 'submit'}
                  onClick={setData && goEdit}
                />
                <GOButton
                  body={t('common.no')}
                  onClick={() => {
                    console.log('exit ~~~~~~~');
                    setTicketEdit(false);
                    if (update) {
                      update();
                    }
                  }}
                />
              </UFloatingBoxFooter>
            </UFloatingContainer>
          )}
        </GOFloatingbox>
      )}

      {viewTicketPause && (
        <TicketPause
          data={data}
          update={update}
          onClickExit={() => {
            setTicketPause(false);
          }}
        />
      )}
    </Styled.Container>
  );
};

export default TicketPanel;
