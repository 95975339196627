import React from 'react';
import * as Styled from './../reservationGridFilterStyles';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import GODropbox from '../../common/GODropbox';
import GOButton from '../../common/GOButton';
import { useTranslation } from 'react-i18next';
import { onModal } from '../../../stores/modals';
import { GOFilterContainer } from '../../../components';

interface ShowTimeProps {
  data?: any;
  date: any;
  schedule?: any;
  employee?: any;
  onChange?: any;
  value?: string | number; //개인수업의 프로의 id
  goLeft: any;
  goRight: any;
  setViewPersonalAdd?: any;
  setViewPersonalDel?: any;
  setViewReservationDel?: any;
  now?: any;
  check?: boolean;
  checkID?: string;
}

const ShowTime = (props: ShowTimeProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //jejucampus

  const kwangmyeongPro = props.employee?.filter((e: any) => {
    return e.id === 103 || e.id === 188;
  });
  let isNormalBranch = true;
  if (kwangmyeongPro?.length === 2) {
    isNormalBranch = false;
  }

  const lessonPro = isNormalBranch
    ? props.employee?.filter((e: any) => e.type === 2)
    : props.employee
        ?.filter((e: any) => e.type === 2)
        .sort((a: any, b: any) => {
          let dateA = new Date(a['dateOfBirth']).getTime();
          let dateB = new Date(b['dateOfBirth']).getTime();
          return dateA - dateB;
        });
  // const lessonPro = eRes.data.Data.Employees?.filter((e: any) => e.type === 2);
  // const lessonPro = props.employee?.filter((e: any) => e.type === 2);

  const lessonCount = props.data?.filter((d: any) => d.LessonReservation !== null);
  const remainingDayfromCreatedSchedule = dayjs(
    dayjs(props.schedule?.startDate).format('YYYY-MM-DDT00:00:00Z'),
  ).diff(dayjs().format('YYYY-MM-DDT00:00:00Z'), 'day');

  return (
    <GOFilterContainer
      leftbody={
        <Styled.LeftControlCustomStyles>
          {props.employee && (
            <Styled.CoachGroup>
              <GODropbox
                data={lessonPro.map((e: any) => {
                  return { value: e.id, body: e.name };
                })}
                value={props.value}
                onChange={props.onChange}
                small
              />
            </Styled.CoachGroup>
          )}
          <Styled.PeriodTextGroup>
            {dayjs(props.date.startDate).format('MM. DD')} ~{' '}
            {dayjs(props.date.endDate)
              .subtract(1, 'm')
              .format('MM. DD')}
            <div className="arrow_btn left" onClick={props.goLeft} />
            <div className="arrow_btn right" onClick={props.goRight} />
          </Styled.PeriodTextGroup>
          {props.employee ? (
            <Styled.LessonInfo>
              <div className="lessonTitle">이번주 총 레슨 수 : {lessonCount?.length}개</div>
              <div
                className="lessonDetail"
                onClick={() => dispatch(onModal('employeeDetailPassword', props.value))}
              >
                예약정보 자세히 보기
              </div>
            </Styled.LessonInfo>
          ) : (
            <>
              {remainingDayfromCreatedSchedule > 0 && remainingDayfromCreatedSchedule <= 10 && (
                <Styled.AlertWarpper>
                  <div className="alertBox">
                    {`${dayjs()
                      .add(remainingDayfromCreatedSchedule, 'day')
                      .format('M월 D일')}`}{' '}
                    이후 생성된 스케줄 없음
                  </div>
                </Styled.AlertWarpper>
              )}
            </>
          )}
        </Styled.LeftControlCustomStyles>
      }
      rightbody={
        <>
          {!props.employee && (
            <GOButton
              buttontype="red"
              body="타석 스케쥴 삭제"
              onClick={() => props.setViewReservationDel(true)}
            />
          )}
          {props.employee && (
            <>
              <GOButton
                buttontype="red"
                body="코치 스케쥴 삭제"
                onClick={() => props.setViewPersonalDel(true)}
              />
              <GOButton
                buttontype="green"
                body={t('reservation.personalAdd')}
                onClick={() => props.setViewPersonalAdd(true)}
              />
            </>
          )}
        </>
      }
    />
  );
};
export default ShowTime;
