import React from 'react';
import * as Styled from './styles';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { onModal } from '../../../stores/modals';
import { phoneNumberFormatting } from '../../../middlewares';

import { Link } from 'react-router-dom';

interface dataProps {
  title?: string;
  data?: {
    summary: any;
    transactions: any;
  };
  summaryData?: {
    originalPrice: number;
    discount: number;
    unpaidPrice: number;
    paidPrice: number;
    refundPrice: number;
  };
}

const dynamicState = (data: any) => {
  switch (data.status) {
    case 0:
      return { type: 0, status: '결제 미완료' };
    case 1:
      return { type: 1, status: '결제' };
    case 2:
      return { type: 2, status: '환불' };
    case 3:
      return { type: 3, status: '삭제됨', className: 'deleted' };
    default:
      return { status: '' };
  }
};

const TotalPurchaseTable = ({ title, data, summaryData }: dataProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div>
      {data ? (
        <Styled.ListAll top>
          <div className="newPurchaseWrapper">
            <div className="newPurchase">
              {title} [{data.transactions?.length > 0 ? data.transactions?.length : 0}
              건]
              <span>
                {data.summary?.newUserCount >= 0 && `, 신규 회원 [${data.summary?.newUserCount}명]`}
              </span>
            </div>
          </div>
          <hr />
          <br />
          <Styled.Table>
            <table>
              <thead>
                <tr>
                  <td>{t('accounting.order_no')}</td>
                  <td>{t('accounting.payment_date')}</td>
                  <td>{t('accounting.name')}</td>
                  <td>{t('accounting.tel')}</td>
                  <td>{t('accounting.payment')}</td>
                  <td>{t('accounting.status')}</td>
                  <td>{t('accounting.originalPrice')}</td>
                  <td>{t('accounting.discount')}</td>
                  <td>{t('accounting.unpaidPrice')}</td>
                  <td>{t('accounting.paidAmount')}</td>
                  <td>{t('accounting.refund')}</td>
                  <td>결제방식</td>
                  <td>{t('accounting.employee')}</td>
                  <td>{t('accounting.memo1')}</td>
                  <td>{t('accounting.refund_memo')}</td>
                </tr>
              </thead>
              <tbody>
                {data.transactions?.map((n: any) => {
                  const nowState = dynamicState(n);
                  return (
                    <tr className={nowState.className} key={n.no}>
                      <td>{n.no}</td>
                      <td>{dayjs(n.purchaseDate).format('YYYY. MM. DD')}</td>
                      <td>
                        <Link to={`/admin/user/${n.userid}`}> {n.username} </Link>
                      </td>
                      <td>
                        <Link to={`/admin/user/${n.userid}`}>
                          {' '}
                          {phoneNumberFormatting(n.phoneNumber)}
                        </Link>
                      </td>
                      <td>{n.ticketName}</td>
                      {nowState?.type === 0 && (
                        <td>
                          <div style={{ display: 'flex' }}>
                            <Styled.Circle red />
                            {nowState?.status}
                          </div>
                        </td>
                      )}
                      {nowState?.type === 1 && (
                        <td>
                          <div style={{ display: 'flex' }}>
                            <Styled.Circle />
                            {nowState?.status}
                          </div>
                        </td>
                      )}
                      {nowState?.type === 2 && (
                        <td>
                          <div style={{ display: 'flex' }}>
                            <Styled.Circle empty />
                            {nowState?.status}
                          </div>
                        </td>
                      )}
                      {nowState?.type === 3 && (
                        <td>
                          <div style={{ display: 'flex' }}>
                            <Styled.Circle empty />
                            {nowState?.status}
                          </div>
                        </td>
                      )}
                      <td className="price">
                        {n.originalPrice.toLocaleString('en')} {t('common.KRW')}
                      </td>
                      <td className="price">
                        {n.discount.toLocaleString('en')} {t('common.KRW')}
                      </td>
                      <td className="price">
                        {n.unpaidPrice.toLocaleString('en')} {t('common.KRW')}
                      </td>
                      <td className="price">
                        {n.paidAmount && n.paidAmount.toLocaleString('en')} {t('common.KRW')}
                      </td>
                      <td className="price">
                        {n.refund.toLocaleString('en')} {t('common.KRW')}
                      </td>
                      <td>{n.method}</td>
                      <td>{n.employee}</td>
                      <td>
                        {n.transactionMemo && n.transactionMemo.length > 0 && (
                          <div
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              dispatch(
                                onModal('memo', {
                                  type: 'memo',
                                  data: n.transactionMemo,
                                }),
                              )
                            }
                          >
                            보기
                          </div>
                        )}
                      </td>
                      <td>
                        {n.refundMemos && n.refundMemos.length > 0 && (
                          <div
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              dispatch(
                                onModal('memo', {
                                  type: 'refund',
                                  data: n.refundMemos,
                                }),
                              )
                            }
                          >
                            보기
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={6} style={{ fontWeight: 'bold' }}>
                    합계
                  </td>
                  <td className="price">{summaryData?.originalPrice.toLocaleString('en')} 원</td>
                  <td className="price">{summaryData?.discount.toLocaleString('en')} 원</td>
                  <td className="price">{summaryData?.unpaidPrice.toLocaleString('en')} 원</td>
                  <td className="price">{summaryData?.paidPrice.toLocaleString('en')} 원</td>
                  <td className="price">{summaryData?.refundPrice.toLocaleString('en')} 원</td>
                  <td colSpan={4}></td>
                </tr>
              </tbody>
            </table>
          </Styled.Table>
        </Styled.ListAll>
      ) : (
        <>
          <div>데이터를 불러오는데 실패했습니다.</div>
        </>
      )}
    </div>
  );
};

export default TotalPurchaseTable;
