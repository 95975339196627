import styled from 'styled-components';
import SelectDate from 'react-datepicker';
import * as color from '../../../color/index';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const DateItem = styled.div`
  display: flex;
  flex: 1 1 auto;
  & + & {
    margin-left: 30px;
  }
  .react-datepicker-wrapper {
    flex: 1;
    .react-datepicker__input-container {
      display: flex;
      input {
        width: 100px;
      }
    }
  }
  ${BreakPoints.sm} {
    width: 100%;
    margin-bottom: 10px;
    & + & {
      margin: 0;
    }
  }
`;

export const DateName = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 40px;
  ${BreakPoints.sm} {
    width: 4em;
  }
`;

export const CustomDate = styled(SelectDate)`
  border: 2px solid ${color.Colors.line};
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  color: ${color.Colors.primary};
  padding: 11px 0 11px 7px;
  margin-left: 20px;
  flex: 1 1 auto;
  ${BreakPoints.sm} {
  }
`;
