import styled from 'styled-components';
import * as color from '../../../color/index';

export const LayoutBox = styled.div`
  width: 290px;
  height: 400px;

  video {
    margin-bottom: 20px;
    border-radius: 5px;
    outline: none;
  }

  .lessonProComment {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }
`;

export const VerticalLine = styled.div`
  width: 1px;
  height: 500px;
  background: ${color.Colors.line};
  margin-left: 30px;
  margin-right: 30px;
`;

export const UserText = styled.div`
  font-size: 14px;
  max-height: 200px;
  padding: 10px;
  color: #495057;
  word-break: break-all;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  overflow: auto;
`;
