import styled, { keyframes, css } from 'styled-components';
import * as color from '../../../color/index';
import { BreakPoints } from '../../../GlobalUtilityStyles';
interface iconProps {
  small?: boolean;
}

export const Golayout = keyframes`
    0% {
        opacity: 0;
        left: -20px;
    }
    100% {
        opacity: 1;
        left: 0;
    }
`;

export const BottomBox = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px 20px 20px;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  background: ${color.Colors.white};
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  animation: ${Golayout} 0.35s;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const TitleBox = styled.div`
  background: ${color.Colors.white};
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-top: 20px;
  color: ${color.Colors.primary};
`;

export const PanelBox = styled.div`
  flex-grow: 1;
  height: 500px;
  overflow: auto;
  flex: 1 1 auto;

  ${BreakPoints.sm} {
    height: auto;
  }
`;

export const NoticePanel = styled.div`
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  margin-top: 10px;
  padding: 13px 20px 13px 20px;
  font-weight: 700;
  background: ${color.Colors.background};

  ${BreakPoints.md} {
    padding: 13px 15px 13px 15px;
  }
`;

export const NDate = styled.div`
  font-size: 16px;
  color: #989898;
`;

export const NReservation = styled.div`
  font-size: 20px;
  color: ${color.Colors.primary};
  margin-top: 5px;
`;

export const NoDataText = styled.div`
  text-align: center;
  font-size: 16px;
  color: #888888;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 250px;
`;

export const DeleteIcon = styled.i<iconProps>`
  color: ${color.Colors.line};
  font-size: 24px;
  transition: 0.35s;
  //margin-top: 20px;
  margin-top: 4px;

  cursor: pointer;

  &:hover {
    color: #4a5057;
    transition: 0.35s;
  }

  ${props => {
    if (props.small)
      return css`
        font-size: 18px;
        margin: 0;
      `;
  }}
`;

export const Checkbox = styled.div`
  display: flex;
  margin-top: 20px;

  .inputWrapper {
    display: flex;
    align-items: center;
    color: ${color.Colors.primary};
  }

  .checkLabel {
    //margin: 0 20px 0 3px;
    margin: 0 20px 0 3px;
  }
`;
