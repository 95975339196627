import React from 'react';
import GOButton from '../GOButton';
import * as Styled from './styles';

interface HeaderSearchBarProps {
  placeholder?: string;
  style?: object;
  value?: any;
  onChange?: any;
  handleSubmit?: any;
}

const HeaderSearchBar = (props: HeaderSearchBarProps) => {
  return (
    <Styled.Form onSubmit={props.handleSubmit}>
      <Styled.Input
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        type="search"
      />
      <GOButton body="검색" type="submit" buttontype="darkGray" />
    </Styled.Form>
  );
};

export default HeaderSearchBar;
