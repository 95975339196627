import NetworkManager from "../../../NetworkManager";

const List = () => {
  return new Promise((resolve, reject) => {
    NetworkManager("get", "admin/product")
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default List;
