import styled from 'styled-components';
import * as color from '../../../color/index';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const ListStatusbar = styled.div`
  width: 100%;
  min-height: 32px;
  background: ${color.Colors.primary};
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;
  padding: 10px 12px;
  color: ${color.Colors.white};
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const ListStatusbarLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const ListStatusbarRight = styled.div`
  margin-left: auto;
  // ${BreakPoints.sm} {
  //   display: none;
  // }
`;
