import NetworkManager from "../../../NetworkManager";

const LessonReportConfirm = (id: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager("put", `admin/employee/lessonLogs/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default LessonReportConfirm;
