import NetworkManager from '../../../NetworkManager';

const UncheckReservation = (date: any) => {
  return new Promise((resolve, reject) => {
    // NetworkManager('get', `admin/dashboard/uncheckedReservations?dateToCheck=${date}`)
    NetworkManager('get', `admin/dashboard/uncheckedReservations_ver_1_0?dateToCheck=${date}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default UncheckReservation;
