import React from 'react';
import * as Styled from './styles';

// MARK : 에러를 표시합니다.
// NOTE : 에러 표시를 위한 공통적인 컴포넌트입니다.
interface GOErrorProps {
  // body : 들어갈 내용

  body: string;
  fontSize?: number;
}

const GOError = (props: GOErrorProps) => {
  return (
    <Styled.ErrorContainer>
      <Styled.ErrorIcon>!</Styled.ErrorIcon>
      <div style={{ fontSize: `${props.fontSize}px` }}>{props.body}</div>
    </Styled.ErrorContainer>
  );
};

export default GOError;
