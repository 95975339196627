import React, { useState, useEffect } from 'react';
import {
  HeaderBottom,
  StatusTool,
  GOLayoutList,
  GOButton,
  EmployeeGridView,
  EmployeeAdd,
  GOAsyncDiv,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetEmployee } from '../../../stores/actions';

const Employee = () => {
  const dispatch = useDispatch();
  const EmployeeData = useSelector((state: any) => state.employee);

  const { t } = useTranslation();
  const [Add, viewAdd] = useState<boolean>(false);

  useEffect(() => {
    window.sessionStorage.clear();
    dispatch(actionGetEmployee());
  }, [dispatch, Add]);

  return (
    <>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={t('employee.list')} />
          </>
        }
        rightmenu={
          <>
            <GOButton
              buttontype="green"
              body={t('employee.add')}
              onClick={() => {
                viewAdd(true);
              }}
            />
          </>
        }
      />
      <GOLayoutList
        leftbody={
          <>
            <h4>{t('employee.list')}</h4>
            <hr />
            <h6>전체 직원</h6>
          </>
        }
        rightbody={
          <GOAsyncDiv status={EmployeeData.status}>
            <EmployeeGridView data={EmployeeData.data.Employees} />
          </GOAsyncDiv>
        }
      />
      {Add && (
        <EmployeeAdd
          onClickExit={() => {
            viewAdd(false);
          }}
        />
      )}
    </>
  );
};

export default Employee;
