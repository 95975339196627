import NetworkManager from "../../../NetworkManager";

const LessonUserDetail = (id: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager("get", `admin/employee/lessonLogs/detail/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default LessonUserDetail;
