// 액션!=
import createActions from '../controller/createActions';

const { read, readDetail, onlyRequest } = createActions('employee');

// 직원 리스트 가져오기
export const actionGetEmployee = () => read({});

// 직원 상세 정보 가져오기
export const actionGetDetailEmployee = (eID: string, onSuccess?: Function) =>
  readDetail({
    url: `employee/detail/${eID}`,
    meta: { onSuccess },
  });

// 직원 상세 1달치 회원 리스트 가져오기
export const actionGetDetailEmployeeLessons = (
  eID: string,
  sDate: string,
  eDate: string,
  onSuccess?: Function,
) =>
  read({
    url: `employee/detail/lessons/${eID}?startDate=${sDate}&endDate=${eDate}`,
    meta: { onSuccess },
  });

// 회원 관리 리스트 가져오기
export const actionGetDetailEmployeeTotalUser = (eID: string, onSuccess?: Function) =>
  onlyRequest({
    url: `employee/detail/tickets/${eID}`,
    meta: { onSuccess },
  });

// 직원 삭제
export const actionDelEmployee = (eID: string | number, onSuccess: Function) =>
  onlyRequest({
    type: 'delete',
    url: `employee/inactivate/${eID}`,
    meta: { read: 'employee', onSuccess },
  });

// 직원 레슨 일지 회원 목록 가져오기
export const actionGetUserLessonList = (eID: string, onSuccess?: Function) =>
  read({
    url: `employee/lessonLogs/${eID}`,
    meta: { onSuccess },
  });

// 선택된 회원 레슨 일지 리스트 가져오기
export const actionGetSelectLessonList = (lID: string, eID: string, onSuccess?: Function) =>
  readDetail({
    url: `employee/lessonLogs/list/info?user=${lID}&employeeID=${eID}`,
    meta: { onSuccess },
  });

// 직원 등록
export const actionPostEmployee = (data: any, onSuccess: Function) =>
  onlyRequest({
    type: 'post',
    params: data,
    meta: { read: 'employee', onSuccess },
  });

// 직원 수정
export const actionPutEmployee = (eID: string | number, params: any, onSuccess?: Function) =>
  onlyRequest({
    type: 'put',
    url: `employee/${eID}`,
    params,
    meta: { readDetail: `employee/${eID}`, onSuccess },
  });

// 직원 스케쥴 수정
export const actionPutEmployeeLessonTime = (
  eID: string | number,
  params: any,
  onSuccess?: Function,
) =>
  onlyRequest({
    type: 'put',
    url: `employee/workTimes/${eID}`,
    params,
    meta: { onSuccess },
  });

// 레슨 일지 확인
export const actionPutEmployeeReportConfirm = (
  eID: string | number,
  params: any,
  onSuccess?: Function,
) =>
  onlyRequest({
    type: 'put',
    url: `employee/lessonLogs/${eID}`,
    params,
    meta: { readDetail: `employee/lessonLogs/${eID}`, onSuccess },
  });

export const actionResetEmployeePassword = (eid: string | any, onSuccess?: Function) =>
  onlyRequest({
    type: 'put',
    url: `employee/resetPassword/${eid}`,
    meta: {
      onSuccess,
    },
  });
