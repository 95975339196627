import styled, { keyframes } from 'styled-components';
import {
  ContentBodyPadding,
  BreakPoints,
  ContentBodyMaxWidth,
  HorizontalGlobalPadding,
} from '../../../GlobalUtilityStyles';

export const Golayout = keyframes`
    0% {
        opacity: 0;
        left: -20px;
    }
    100% {
        opacity: 1;
        left: 0;
    }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: ${ContentBodyPadding.xl};
  flex-grow: 1;

  ${BreakPoints.md} {
    padding: ${ContentBodyPadding.md};
  }
  ${BreakPoints.sm} {
    padding: ${ContentBodyPadding.sm};
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  position: relative;
  width: 100%;
  max-width: ${ContentBodyMaxWidth};
  grid-template-columns: 55fr 45fr;

  ${BreakPoints.md} {
    grid-template-columns: auto;
    grid-gap: ${HorizontalGlobalPadding.sm};
  }
`;
