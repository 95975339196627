import styled from 'styled-components';
import * as color from '../../../color/index';

export const Label = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  font-size: 14px;
  color: ${color.Colors.primary};

  .title {
    width: 90px;
    text-align: center;
    font-weight: bold;
  }
`;
