import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { GOButton, GODate, GOSwitch } from '../../../components';
import * as Styled from './styles';
import { useTranslation } from 'react-i18next';
import * as StyledOfParent from '../ScheduleManageDetail/styles';
import { convertToDayWithNumber } from '../../../util/converter';
import { LessonProWorkTime, LessonProBreakTimePayload } from '../../../Types/employeeType';
// import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { actionPutEmployeeLessonTime } from '../../../stores/actions';
import DatePicker from 'react-datepicker';

// [
//   {
//     "startHour": null,
//     "startMinute": null,
//     "endHour": null,
//     "endMinute": null,
//     "day": 0,
//     "status": 0,
//     "breakTime": {
//       "startHour": null,
//       "startMinute": null,
//       "endHour": null,
//       "endMinute": null,
//       "status": 0
//     }
//   },
//   {
//     "startHour": 5,
//     "startMinute": 0,
//     "endHour": 9,
//     "endMinute": 0,
//     "day": 1,
//     "status": 1,
//     "breakTime": {
//       "startHour": 7,
//       "startMinute": 0,
//       "endHour": 8,
//       "endMinute": 0,
//       "status": 0
//     }
//   },

interface boxProps {
  data?: any;
  selectedDayIndex: number;
  workTimes: [LessonProWorkTime];
  employID: number;
}

//     "startHour": null,
//     "startMinute": null,
//     "endHour": null,
//     "endMinute": null,
//     "day": 0,
//     "status": 0,
//     "breakTime": {
//       "startHour": null,
//       "startMinute": null,
//       "endHour": null,
//       "endMinute": null,
//       "status": 0
//     }

function setDateWithHourAndMin(hour: number, min: number) {
  if (hour !== null && min !== null) {
    const newDate = new Date();
    newDate.setHours(hour, min);
    return newDate;
  } else {
    return new Date();
  }
}

function resetTimeState(
  hour: null | number,
  min: null | number,
  setStateTimeValue: React.Dispatch<React.SetStateAction<Date | null>>,
) {
  if (hour !== null && min != null) {
    const date = setDateWithHourAndMin(hour, min);
    setStateTimeValue(date);
  } else {
    setStateTimeValue(null);
  }
}

function makeWorkTimesPayload(
  onDutyBeginTime: Date | null,
  onDutyEndTime: Date | null,
  offDutyBeginTime: Date | null,
  offDutyEndTime: Date | null,
  selectedDayIndex: number,
  workTimes: [LessonProWorkTime],
) {
  let payload: LessonProBreakTimePayload = { workTimes: [] };
  //all items are null
  let workTimeStatus: 0 | 1 = 0;
  let breakTimeStatus: 0 | 1 = 0;

  if (
    [onDutyBeginTime, onDutyEndTime, offDutyBeginTime, offDutyEndTime].every(item => item === null)
  ) {
    workTimeStatus = 0;
    breakTimeStatus = 0;
  } else {
    if (onDutyBeginTime !== null && onDutyEndTime !== null) {
      workTimeStatus = 1;
    }
    if (offDutyBeginTime !== null && offDutyEndTime !== null) {
      breakTimeStatus = 1;
    }
  }

  payload.workTimes = workTimes.map(wt => {
    if (wt.day === selectedDayIndex) {
      return {
        ...wt,
        status: workTimeStatus,
        startHour: workTimeStatus === 1 ? onDutyBeginTime?.getHours() ?? null : null,
        startMinute: workTimeStatus === 1 ? onDutyBeginTime?.getMinutes() ?? null : null,
        endHour: workTimeStatus === 1 ? onDutyEndTime?.getHours() ?? null : null,
        endMinute: workTimeStatus === 1 ? onDutyEndTime?.getMinutes() ?? null : null,
        breakTime: {
          status: breakTimeStatus,
          startHour: breakTimeStatus === 1 ? offDutyBeginTime?.getHours() ?? null : null,
          startMinute: breakTimeStatus === 1 ? offDutyBeginTime?.getMinutes() ?? null : null,
          endHour: breakTimeStatus === 1 ? offDutyEndTime?.getHours() ?? null : null,
          endMinute: breakTimeStatus === 1 ? offDutyEndTime?.getMinutes() ?? null : null,
        },
      };
    } else {
      return { ...wt };
    }
  });
  return payload;
}

const ScheduleManageDetailBox = ({ data, selectedDayIndex, workTimes, employID }: boxProps) => {
  console.log('ScheduleManageDetailBox');

  const dispatch = useDispatch();
  // let result: any = { workTimes: [] };
  //   dispatch(
  //     actionPutEmployeeLessonTime(props.data.employee.id, result, () => {
  //       window.location.reload(false);
  //       // window.location.reload();
  //     }),
  //   );
  // }
  const { t } = useTranslation();

  const onDutyBeginTimeInputRef = useRef<DatePicker>(null);
  const onDutyEndTimeInputRef = useRef<DatePicker>(null);
  const offDutyBeginTimeInputRef = useRef<DatePicker>(null);
  const offDutyEndTimeInputRef = useRef<DatePicker>(null);

  // const maxTimeOfDay = new Date(
  //   dayjs()
  //     .endOf('day')
  //     .format(),
  // );
  // const minTimeOfDay = new Date(
  //   dayjs()
  //     .startOf('day')
  //     .format(),
  // );

  // const [workTime, setWorkTime] = useState<LessonProWorkTime>(workTimes[selectedDayIndex]);
  const [onDutyBeginTime, setOnDutyBeginTime] = useState<Date | null>(null);
  const [onDutyEndTime, setOnDutyEndTime] = useState<Date | null>(null);
  const [offDutyBeginTime, setOffDutyBeginTime] = useState<Date | null>(null);
  const [offDutyEndTime, setOffDutyEndTime] = useState<Date | null>(null);

  const [isOffDuty, setIsOffDuty] = useState<boolean>(false);

  const loadStates = useCallback(
    (isIncludeSwitch: boolean) => {
      const currentWorkTime = workTimes[selectedDayIndex];
      // setWorkTime(currentWorkTime);
      console.log(currentWorkTime);

      if (isIncludeSwitch) {
        setIsOffDuty(currentWorkTime.status === 0);
      }
      // setHasNoBreakTime( currentWorkTime.breakTime.status === 0);
      resetTimeState(currentWorkTime.startHour, currentWorkTime.startMinute, setOnDutyBeginTime);
      resetTimeState(currentWorkTime.endHour, currentWorkTime.endMinute, setOnDutyEndTime);

      if (currentWorkTime.breakTime.status === 0) {
        resetTimeState(null, null, setOffDutyBeginTime);
        resetTimeState(null, null, setOffDutyEndTime);
      } else {
        resetTimeState(
          currentWorkTime.breakTime.startHour,
          currentWorkTime.breakTime.startMinute,
          setOffDutyBeginTime,
        );
        resetTimeState(
          currentWorkTime.breakTime.endHour,
          currentWorkTime.breakTime.endMinute,
          setOffDutyEndTime,
        );
      }
    },
    [workTimes, selectedDayIndex, employID],
  );

  const resetStates = useCallback(
    (isClearAll: boolean) => {
      if (isClearAll) {
        setOnDutyBeginTime(null);
        setOnDutyEndTime(null);
        setOffDutyBeginTime(null);
        setOffDutyEndTime(null);
        // setHasNoBreakTime(true);
        setIsOffDuty(true);
      } else {
        loadStates(false);
      }
    },
    [loadStates],
  );

  useEffect(() => {
    loadStates(true);
    console.log('useEffect');
  }, [selectedDayIndex, data, loadStates, employID]);

  const dayInWeek = useMemo<String>(() => convertToDayWithNumber(selectedDayIndex), [
    selectedDayIndex,
  ]);

  const onClickSwitch = useCallback(() => {
    console.log('onSwitch click', isOffDuty);
    const nextIsOffDuty = !isOffDuty;
    setIsOffDuty(nextIsOffDuty);
    resetStates(nextIsOffDuty);
  }, [isOffDuty, resetStates]);

  const handleCommit = useCallback(() => {
    console.log('handlecommit');
    if (isOffDuty) {
      //휴무일
      // makeWorkTimesPayload( onDutyBeginTime, onDutyEndTime, offDutyBeginTime, offDutyEndTime, selectedDayIndex, workTimes);
      const payload = makeWorkTimesPayload(null, null, null, null, selectedDayIndex, workTimes);
      dispatch(
        actionPutEmployeeLessonTime(employID, payload, () => {
          window.location.reload();
        }),
      );
    } else {
      //근무일
      let errorMessage = '';

      if (onDutyEndTime === null && onDutyBeginTime !== null) {
        onDutyBeginTimeInputRef.current?.setFocus();
        return;
      }
      if (onDutyEndTime !== null && onDutyBeginTime === null) {
        onDutyBeginTimeInputRef.current?.setFocus();
        return;
      }

      if (onDutyBeginTime !== null && onDutyEndTime !== null) {
        if (offDutyBeginTime !== null && offDutyEndTime === null) {
          offDutyEndTimeInputRef.current?.setFocus();
          return;
        }
        if (offDutyBeginTime === null && offDutyEndTime !== null) {
          offDutyBeginTimeInputRef.current?.setFocus();
          return;
        }
      } else if (onDutyBeginTime === null && onDutyEndTime === null) {
        if (offDutyBeginTime !== null || offDutyEndTime !== null) {
          alert('근무시간을 입력하시거나, 휴식시간이 불필요합니다.');
          return;
        }
      }
      //check time
      if (onDutyBeginTime !== null && onDutyEndTime !== null) {
        const startTime = onDutyBeginTime.getHours() * 60 + onDutyBeginTime.getMinutes();
        const endTime = onDutyEndTime.getHours() * 60 + onDutyEndTime.getMinutes();
        if (endTime <= startTime) {
          alert('근무종료시간을 확인해 주십시요.종료시간이 시작시간 전입니다.');
          return;
        }
        if (offDutyBeginTime !== null && offDutyEndTime !== null) {
          const offStartTime = offDutyBeginTime.getHours() * 60 + offDutyBeginTime.getMinutes();
          const offEndTime = offDutyEndTime.getHours() * 60 + offDutyEndTime.getMinutes();
          if (offEndTime <= offStartTime) {
            alert('휴식 종료시간을 확인해 주십시요. 휴식 종료시간이 휴식 시작시간 전입니다.');
            return;
          }
          if (offStartTime < startTime) {
            alert('휴식 시작 시간이 근무시작시간 전입니다.');
            return;
          }
          if (offEndTime > endTime) {
            alert('휴식 종료 시간이 근무 종료 시간 후 입니다.');
            return;
          }
        }
      } //endof onDutyBegintime !== null && onDutyEntime !== null
      const payload = makeWorkTimesPayload(
        onDutyBeginTime,
        onDutyEndTime,
        offDutyBeginTime,
        offDutyEndTime,
        selectedDayIndex,
        workTimes,
      );
      dispatch(
        actionPutEmployeeLessonTime(employID, payload, () => {
          window.location.reload();
        }),
      );
    }
  }, [
    selectedDayIndex,
    dispatch,
    employID,
    isOffDuty,
    workTimes,
    employID,
    onDutyBeginTime,
    onDutyEndTime,
    offDutyBeginTime,
    offDutyEndTime,
  ]);

  return (
    <>
      <Styled.HeaderBox>
        <Styled.Day>{dayInWeek}</Styled.Day>
        <Styled.SwitchBox>
          <GOSwitch status={!isOffDuty} onClick={onClickSwitch} />
        </Styled.SwitchBox>
      </Styled.HeaderBox>
      <Styled.Title>근무시간 등록 / 변경</Styled.Title>
      <div>
        <GODate
          label={'시작시간'}
          onChange={(d: Date) => {
            console.log(d);
            setOnDutyBeginTime(d);
          }}
          time
          timeOnly
          placeholder="근무 시작시간을 선택해 주세요."
          dataFormat="HH:mm"
          selected={onDutyBeginTime}
          disabled={isOffDuty}
          ref={onDutyBeginTimeInputRef}
        />
        <GODate
          label={t('employee.endTime')}
          onChange={(d: Date) => {
            setOnDutyEndTime(d);
          }}
          time
          timeOnly
          placeholder="근무 종료시간을 선택해 주세요."
          dataFormat="HH:mm"
          selected={onDutyEndTime}
          disabled={isOffDuty}
          ref={onDutyEndTimeInputRef}
        />
        <hr />
        <Styled.SubTitle>휴식시간 등록 / 변경</Styled.SubTitle>
        <GODate
          label={t('employee.startTime')}
          onChange={(d: Date) => {
            setOffDutyBeginTime(d);
          }}
          time
          timeOnly
          placeholder="휴식의 시작시간을 선택해 주세요."
          dataFormat="HH:mm"
          selected={offDutyBeginTime}
          disabled={isOffDuty}
          ref={offDutyBeginTimeInputRef}
        />
        <GODate
          label={t('employee.endTime')}
          onChange={(d: Date) => {
            setOffDutyEndTime(d);
          }}
          time
          timeOnly
          placeholder="휴식의 종료시간을 선택해 주세요."
          dataFormat="HH:mm"
          selected={offDutyEndTime}
          disabled={isOffDuty}
          ref={offDutyEndTimeInputRef}
        />
      </div>
      <StyledOfParent.ConfirmButton>
        <GOButton
          buttontype="green"
          size="medium"
          body={`저장`}
          onClick={handleCommit}
          noMarginLeft
        />
      </StyledOfParent.ConfirmButton>
    </>
  );
};

export default ScheduleManageDetailBox;
