import React, { useState, useEffect } from 'react';
import * as Styled from './styles';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionClearAllNotification,
  actionPutNotification,
  actionGetNotification,
} from '../../../stores/actions';

const NoticeView = () => {
  const [update, isUpdate] = useState<boolean>(false);

  const dispatch = useDispatch();

  const notificationData = useSelector((state: any) => state.dashboard);

  const [descendingOrder, setDescendingOrder] = useState<any>(true);
  console.log('loading ');

  const data = descendingOrder
    ? notificationData.data.notifications
    : notificationData.data.notifications?.slice().sort((a: any, b: any) => {
        return b['id'] - a['id'];
      });

  const handleFilterChange = (e: any) => {
    // name="descent"
    if (e.target.name === 'descent') {
      const order = !descendingOrder;
      setDescendingOrder(order);
    }
  };

  const onClear = () => {
    dispatch(
      actionClearAllNotification(() => {
        alert('알림이 전체 삭제되었습니다.');
        isUpdate(!update);
      }),
    );
  };

  const onRemove = (nData: any) => {
    dispatch(
      actionPutNotification(nData.id, () => {
        isUpdate(!update);
      }),
    );
  };

  useEffect(() => {
    dispatch(actionGetNotification());
  }, [dispatch]);

  return (
    <>
      <Styled.BottomBox>
        <Styled.TitleBox>
          <Styled.TitleWrapper>
            <Styled.Title>알림</Styled.Title>
            <Styled.Checkbox>
              <div className="inputWrapper">
                <input
                  type="checkbox"
                  name="descent"
                  onChange={handleFilterChange}
                  checked={descendingOrder}
                />
                <label className="checkLabel">발송시간순</label>
              </div>
              <Styled.DeleteIcon className="far fa-times-circle" onClick={onClear} />
            </Styled.Checkbox>
          </Styled.TitleWrapper>
          <hr />
        </Styled.TitleBox>
        <Styled.PanelBox>
          {data?.length > 0 ? (
            <>
              {data?.map((nData: any, index: number) => (
                <React.Fragment key={index}>
                  <Styled.NoticePanel>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Styled.NDate>
                        {dayjs(nData.createdAt).format('YYYY.MM.DD HH:mm')}
                      </Styled.NDate>
                      <Styled.DeleteIcon
                        className="far fa-times-circle"
                        onClick={() => {
                          onRemove(nData);
                        }}
                        small
                      />
                    </div>
                    <Styled.NReservation>{nData.body}</Styled.NReservation>
                  </Styled.NoticePanel>
                </React.Fragment>
              ))}
            </>
          ) : (
            <>
              <Styled.NoDataText>알림이 없습니다.</Styled.NoDataText>
            </>
          )}
        </Styled.PanelBox>
      </Styled.BottomBox>
    </>
  );
};

export default NoticeView;
