// 상수들!
export const REQ = "request";
export const ONLY_REQ = "request/only";
export const REQ_DETAIL = "request/detail";

export const REQUEST_GET = "get/request";
export const REQUEST_POST = "post/request";
export const REQUEST_PUT = "put/request";
export const REQUEST_DELETE = "delete/request";

export const REQUEST_WAITING = "start/waiting";
export const REQUEST_START = "start/request";
export const REQUEST_FINISH = "finish/request";
export const REQUEST_SUCCESS = "success/request";
export const REQUEST_FAILURE = "failure/request";
