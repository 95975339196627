import React from 'react';
import * as Styled from './styles';
import dayjs from 'dayjs';

interface PanelProps {
  id?: string;
  style: object;
  name: string;
  startDate: string;
  endDate: string;
  totalCount: number;
  reservationCount: number;
  edit: any;
  onMouseMove?: any;
  onMouseOut?: any;
  employee?: any;
  heightFactor?: any;
}

const time = (inDate: any) => {
  const date = new Date(inDate);

  return (
    <>
      {date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:
      {date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}
    </>
  );
};

// const paddingByHeightFactor = (heightFactor?: any) => {
//   if (heightFactor) {
//     if (heightFactor === 5.0) {
//       return `0px 10px`;
//     } else if (heightFactor === 6.6) {
//       return `0px 10px`;
//     } else if (heightFactor === 7.5) {
//       return `0px 10px`;
//     } else if (heightFactor === 10.0) {
//       return `0px 10px`;
//     } else {
//       return `0px 10px`;
//     }
//   } else {
//     return `0px 10px`;
//   }
// };

const Panel = (props: PanelProps) => {
  const percent = (props.reservationCount / props.totalCount) * 100;
  // const padding = paddingByHeightFactor(props.heightFactor);
  const panelStyle = () => {
    const afterToday = dayjs(props.endDate).diff(dayjs()) > 0;
    if (afterToday) {
      // 오늘 이후
      if (props.employee) {
        return {
          border: props.employee.LessonReservation ? `1px solid #9775fa` : `1px solid #d0bfff`,
          color: props.employee.LessonReservation ? `#ffffff` : `#6741d9`,
          // padding: `${padding}`,
          background: props.employee.LessonReservation ? `#9775fa` : `#f3f0ff`,
        };
      } else {
        return {
          border: `1px solid #BEC8FB`,
          color: `#3B4EC1`,
          background: `linear-gradient(90deg, #BDC8FC ${percent}%, #F0F2FE ${percent}%)`,
        };
      }
    } else {
      // 오늘 이전
      if (props.employee) {
        return {
          border: props.employee.LessonReservation ? `1px solid #f1f3f5` : `1px solid #dee2e6`,
          color: `#495057`,
          background: props.employee.LessonReservation ? `#ced4da` : `#f1f3f5`,
          // padding: `${padding}`,
        };
      } else {
        return {
          border: `1px solid #dee2e6`,
          color: `#495057`,
          background: `linear-gradient(90deg, #ced4da ${percent}%, #f1f3f5 ${percent}%)`,
        };
      }
    }
  };

  return (
    <Styled.ReservationPanel
      style={props.style}
      onClick={props.edit}
      onMouseMove={props.onMouseMove}
      onMouseOut={props.onMouseOut}
    >
      {/* {props.employee ? ( */}
      <Styled.ReservationPanelWrapper style={panelStyle()}>
        <div className="res_title">
          {time(props.startDate)} ~ {time(props.endDate)}
          <div className="res_people">
            {props.employee ? props.name : `${props.reservationCount} / ${props.totalCount} 명 `}
          </div>
          {!props.employee && <div>{props.name}</div>}
        </div>
      </Styled.ReservationPanelWrapper>
      {/* ) : (
        <Styled.ReservationPlatePanelWrapper style={panelStyle()}>
          <div className="res_title">
            {time(props.startDate)} ~ {time(props.endDate)}
            <div className="res_people">
              {props.employee ? props.name : `${props.reservationCount} / ${props.totalCount} 명 `}
            </div>
            {!props.employee && <div>{props.name}</div>}
          </div>
        </Styled.ReservationPlatePanelWrapper>
      )} */}
    </Styled.ReservationPanel>
  );
};

export default Panel;
