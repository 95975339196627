import NetworkManager from "../../../NetworkManager";

const TicketPauseDel = (id: any) => {
  return new Promise((resolve, reject) => {
    NetworkManager("delete", `admin/user/pauses/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default TicketPauseDel;
