import axios from 'axios';
import { toast } from 'react-toastify';
import RefreshToken from '../Network/RefreshToken';
// import { UserManager } from "..";
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko');

/*
 * 네크워크 통신을 위한 function
 */
const NetworkManager = (
  method: 'get' | 'post' | 'put' | 'delete',
  url: string,
  data?: object | null,
  header?: object,
  formData?: any,
) => {
  // 재귀용 함수
  const Network = (resolve: any, reject: any) => {
    const dataString: any = window.localStorage.getItem(String('UUINFO'));
    const SERVER = window.localStorage.getItem('SERVER');
    let AUTHORIZATION = {};
    if (dataString) {
      AUTHORIZATION = { authorization: JSON.parse(dataString).accessToken };
    }
    const now = dayjs().format('MM.DD a h:mm:ss');
    if (data) {
      console.log(`${now} 📡 서버 통신 ( ${method.toUpperCase()} ) /${url}`, data);
    } else {
      console.log(`${now} 📡 서버 통신 ( ${method.toUpperCase()} ) /${url}`);
    }

    axios({
      method,
      url: `${
        SERVER === 'dev'
          ? `http://devapi/development/${url}`
          : SERVER === 'test'
          ? ''
          : // ? `http://13.125.243.104/happygolfgo/${url}`
            `https://api.happygolfgoapi.com/happygolfgo/${url}`
      }`,
      data: formData ? formData : { Data: data },
      params: (method === 'get' || method === 'delete') && data,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, Content-Length, X-Requested-With',
        ...header,
        ...AUTHORIZATION,
      },
    })
      .then(res => {
        console.log(`${now} ✅ 서버 통신 ( ${method.toUpperCase()} ) /${url}`, res.data.Data);
        console.log(`----------------- 통신 세션 종료`);
        resolve(res);
      })
      .catch(err => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              console.log(
                `${now} ⛔️ 잘못된 요청 ( Bad Request, 400 ) /${url} - ${err.response.data.message}`,
              );
              reject(err);
              break;
            case 401:
              console.log(
                `${now} ⛔️ 인증 실패 ( Unauthorized, 401 ) /${url} - ${err.response.data.message}`,
              );
              if (document.location.pathname === '/') {
                reject(err);
              } else {
                toast.error(`⛔️ 비밀번호를 확인해주세요.`);
                // toast.error(
                //   `⛔️ 중복 로그인으로 로그아웃 됩니다. ( 401 ) - ${err.response.data.message}`
                // );
                // UserManager().setUser({
                //   isSignIn: false,
                //   id: 0,
                //   name: "",
                //   username: "",
                //   companyNumber: "",
                //   phoneNumber: "",
                //   clientSecretKey: "",
                //   isApproved: false,
                //   accessToken: "",
                // });
                // setTimeout(() => {
                //   document.location.href = "/";
                // }, 1500);
              }
              break;
            case 403:
              console.log(
                `${now} ⛔️ 권한 없음 ( Forbidden, 403 ) /${url} - ${err.response.data.message}`,
              );
              if (document.location.pathname === '/') {
                reject(err);
              } else {
                toast.error(
                  `⛔️ 권한이 없습니다. ( Forbidden, 403 ) - ${err.response.data.message}`,
                );
              }
              break;
            case 404:
              console.log(
                `${now} ⛔️ 데이터 없음 ( Not Found, 404 ) /${url} - ${err.response.data.message}`,
              );
              reject(err);
              // toast.error("데이터가 없습니다. ( No Data, 404 )");
              break;
            case 406:
              console.log(
                `${now} ⛔️ 서버에서 원하는 규격이 아님 ( Not Acceptable, 406 ) /${url} - ${err.response.data.message}`,
              );
              reject(err);
              break;
            case 409:
              console.log(
                `${now} ⛔️ 요청 충돌 ( Confilct, 409 ) /${url} - ${err.response.data.message}`,
              );
              // toast.error(
              //   `⛔️ 입력하신 정보가 중복됩니다. ( Confilct, 409 ) - ${err.response.data.message}`
              // );
              reject(err);
              break;
            case 419:
              console.log(
                `${now} ⛔️ 토큰 만료 ( Token Expired, 419 ) /${url} - ${err.response.data.message}`,
              );
              RefreshToken().then(res => Network(resolve, reject));
              break;
            case 500:
              console.log(
                `${now} ⛔️ 서버 에러 ( Server Error, 500 ) /${url} - ${err.response.data.message}`,
              );
              toast.error(
                `⛔️ 서버 오류가 발생했습니다. ( Server Error, 500 ) - ${err.response.data.message}`,
              );
              break;
            default:
              reject(err);
              console.log(err.response);
              break;
          }

          switch (err.response.resultcode) {
            // 판매 에러코드
            case 4100:
              console.log(
                `${now} ⛔️ ${err.response.status} : 총 금액이 모두 더한 값에 맞지 않습니다. /${url} - ${err.response.data.message}`,
              );
              break;
            case 4101:
              console.log(
                `${now} ⛔️ ${err.response.status} : 미수금과 총 금액이 맞지 않습니다. /${url} - ${err.response.data.message}`,
              );
              break;
            default:
              break;
          }
        } else if (err.request) {
          toast.error(`❗️ 통신에 실패하였습니다.`);
        } else {
          toast.error(`❗️❗️ ${err.message}`);
        }

        console.log(`----------------- 통신 세션 종료`);
      });
  };

  return new Promise((resolve: any, reject: any) => Network(resolve, reject));
};

export default NetworkManager;
