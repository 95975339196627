import React, { useEffect, useState } from 'react';
import '../../panelPlusStyles.scss';
import { useDispatch } from 'react-redux';
import { onModal } from '../../../../stores/modals';
import { actionPostPlateClose, actionGetPlateList } from '../../../../stores/actions/plates';
import dayjs from 'dayjs';
import GOButton from '../../../common/GOButton';
import { NetPlateComputer, NetPlateGame, NetPlateProjector } from '../../../../actions';
import * as Styled from './styles';

const Panel = ({ data }: any) => {
  const dispatch = useDispatch();

  const [update, isUpdate] = useState(false);
  const [computerOffLoading, isComputerOffLoading] = useState<boolean>(false);
  const [projectorOffLoading, isProjectorOffLoading] = useState<boolean>(false);
  const [projectorOnLoading, isProjectorOnLoading] = useState<boolean>(false);
  const [projectorNullLoading, isProjectorNullLoading] = useState<boolean>(false);
  const [gameOffLoading, isGameOffLoading] = useState<boolean>(false);
  const [gameOnLoading, isGameOnLoading] = useState<boolean>(false);

  const onComputerChange = () => {
    NetPlateComputer(data.lockKey).then((res: any) => console.log(res));
    // 타석 컴퓨터가 OFF 일 때
    if (data.isComputerOn === false) {
      isComputerOffLoading(false);
      isGameOnLoading(false);
      NetPlateGame(data.lockKey, 0).then((res: any) => console.log(res));
    }
    // 타석 컴퓨터가 ON 일 때
    if (data.isComputerOn) {
      isComputerOffLoading(!computerOffLoading);

      if (data.isGameOn) {
        isGameOffLoading(!gameOffLoading);

        setTimeout(() => {
          isGameOffLoading(false);
        }, 8000);
      }

      setTimeout(() => {
        dispatch(actionGetPlateList());
        isUpdate(!update);
        isComputerOffLoading(false);
      }, 70000);
    }
  };

  const onProjectorChange = () => {
    // 프로젝터가 ON 일 때
    if (data.isProjectorOn) {
      NetPlateProjector(data.lockKey).then((res: any) => console.log(res));
      isProjectorOffLoading(!projectorOffLoading);
      isProjectorOnLoading(false);

      setTimeout(() => {
        dispatch(actionGetPlateList());
        isUpdate(!update);
        isProjectorOffLoading(false);
      }, 100000);
    }
    // 프로젝터가 OFF 일 때
    if (data.isProjectorOn === false) {
      NetPlateProjector(data.lockKey).then((res: any) => console.log(res));
      isProjectorOnLoading(!projectorOnLoading);

      setTimeout(() => {
        dispatch(actionGetPlateList());
        isUpdate(!update);
        isProjectorOnLoading(false);
      }, 8000);
    }

    // 프로젝터가 NULL 일 때
    if (data.isProjectorOn === null) {
      NetPlateProjector(data.lockKey).then((res: any) => console.log(res));
      isProjectorNullLoading(true);

      setTimeout(() => {
        dispatch(actionGetPlateList());
        isUpdate(!update);
        isProjectorNullLoading(false);
      }, 100000);
    }
  };

  const onGameChange = () => {
    // 게임이 ON 일 때
    if (data.isGameOn) {
      NetPlateGame(data.lockKey, 0).then((res: any) => console.log(res));
      isGameOffLoading(!gameOffLoading);

      setTimeout(() => {
        dispatch(actionGetPlateList());
        isUpdate(!update);
        isGameOffLoading(false);
      }, 8000);
    }
    // 게임이 OFF 일 때
    if (data.isGameOn === false) {
      NetPlateGame(data.lockKey, 1).then((res: any) => console.log(res));
      isGameOnLoading(!gameOnLoading);

      setTimeout(() => {
        dispatch(actionGetPlateList());
        isUpdate(!update);
        isGameOnLoading(false);
      }, 20000);
    }
  };

  const viewComputerButton = () => {
    if (data.status === 0) {
      if (computerOffLoading) {
        return (
          <>
            <Styled.EndButton>타석 컴퓨터 종료 중...</Styled.EndButton>
          </>
        );
      } else if (data.isComputerOn && computerOffLoading === false) {
        return (
          <>
            <Styled.EndButton onClick={onComputerChange}>타석 컴퓨터 종료</Styled.EndButton>
          </>
        );
      } else if (data.isComputerOn === false && computerOffLoading === false) {
        return (
          <>
            <Styled.DisableButton>타석 컴퓨터 시작</Styled.DisableButton>
          </>
        );
      }
    } else
      return (
        <>
          <Styled.DisableButton>타석 컴퓨터 종료</Styled.DisableButton>
        </>
      );
  };

  const viewProjectorButton = () => {
    if (data.status === 0) {
      if (projectorNullLoading) {
        return (
          <>
            <Styled.StartButton>프로젝터 제어 중...</Styled.StartButton>
          </>
        );
      }
      if (projectorOffLoading) {
        return (
          <>
            <Styled.EndButton>프로젝터 종료 중...</Styled.EndButton>
          </>
        );
      } else if (data.isProjectorOn) {
        return (
          <>
            <Styled.EndButton onClick={onProjectorChange}>프로젝터 종료</Styled.EndButton>
          </>
        );
      } else if (projectorOnLoading) {
        return (
          <>
            <Styled.StartButton>프로젝터 시작 중...</Styled.StartButton>
          </>
        );
      } else if (data.isComputerOn && data.isProjectorOn === false) {
        return (
          <>
            <Styled.StartButton onClick={onProjectorChange}>프로젝터 시작</Styled.StartButton>
          </>
        );
      } else if (data.isComputerOn && data.isProjectorOn === null) {
        return (
          <>
            <Styled.StartButton onClick={onProjectorChange}>프로젝터 제어</Styled.StartButton>
          </>
        );
      } else if (data.isComputerOn === false && data.isProjectorOn === null) {
        return (
          <>
            <Styled.DisableButton>프로젝터 제어</Styled.DisableButton>
          </>
        );
      } else if (data.isComputerOn === false) {
        return (
          <>
            <Styled.DisableButton>프로젝터 시작</Styled.DisableButton>
          </>
        );
      }
    } else
      return (
        <>
          <Styled.DisableButton>프로젝터 종료</Styled.DisableButton>
        </>
      );
  };

  const viewGameButton = () => {
    if (data.status === 0) {
      if (gameOffLoading) {
        return (
          <>
            <Styled.EndButton>게임 종료 중...</Styled.EndButton>
          </>
        );
      } else if (data.isGameOn) {
        return (
          <>
            <Styled.EndButton onClick={onGameChange}>게임 종료</Styled.EndButton>
          </>
        );
      } else if (gameOnLoading) {
        return (
          <>
            <Styled.StartButton>게임 시작 중...</Styled.StartButton>
          </>
        );
      } else if (data.isComputerOn && data.isGameOn === false) {
        return (
          <>
            <Styled.StartButton onClick={onGameChange}>게임 시작</Styled.StartButton>
          </>
        );
      } else if (data.isComputerOn === false || gameOnLoading === false) {
        return (
          <>
            <Styled.DisableButton>게임 시작</Styled.DisableButton>
          </>
        );
      }
    } else
      return (
        <>
          <Styled.DisableButton>게임 종료</Styled.DisableButton>
        </>
      );
  };

  const onOpen = () => {
    if (data.isGameOn && data.isComputerOn) {
      dispatch(onModal('plateAdd', data));
    }
  };

  const onClose = () => {
    if (window.confirm('타석을 종료하시겠습니까?')) {
      dispatch(actionPostPlateClose(data.id));
    }
  };

  useEffect(() => {
    let timeout: any;
    if (data.status === 1) {
      timeout = setTimeout(() => {
        isUpdate(!update);
      }, 1000);
    }

    if (data.status === 1 && dayjs(data.endDate).diff(dayjs(), 'second') < 0) {
      dispatch(actionGetPlateList());
    }

    return () => {
      // setTimeout clean up
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [data, update, dispatch]);

  return (
    <div className={`plate_panel_plus ${data.status === 1 ? 'plate_panel_use' : ''}`}>
      <div className="number">{data.number > 9 ? data.number : '0' + data.number}</div>
      <hr />
      <h6>남은시간</h6>
      {data.status === 1 ? (
        <>
          <h4 className="time">
            {dayjs(data.endDate).diff(dayjs(), 'm') < 1
              ? `${dayjs(data.endDate).diff(dayjs(), 's')} 초`
              : `${dayjs(data.endDate).diff(dayjs(), 'm')} 분`}
          </h4>
          <hr />
          <h6>{data.user ? `이용회원 : ${data.user.name}` : '게스트'}</h6>
        </>
      ) : (
        <>
          <h4 className="waiting time">-</h4>
          <hr />
          <h6 className="waiting">이용회원 : 없음</h6>
        </>
      )}
      <hr />
      <div className="flex">
        {viewComputerButton()}
        {viewProjectorButton()}
        {viewGameButton()}
        {data.status === 1 ? (
          <GOButton
            noMarginLeft
            body="타석 사용종료"
            width={'100%'}
            style={{ marginTop: '20px', height: '50px' }}
            onClick={onClose}
          />
        ) : (
          <GOButton
            noMarginLeft
            body="타석 사용시작"
            width={'100%'}
            style={{ marginTop: '20px', height: '50px' }}
            buttontype={data.isComputerOn && data.isGameOn ? 'primary' : 'disabled'}
            disabled={data.isComputerOn && data.isGameOn ? false : true}
            onClick={onOpen}
          />
        )}
      </div>
    </div>
  );
};

export default Panel;
