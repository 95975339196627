import React, { useState } from 'react';
import * as Styled from './styles';
import PaymentPanel from '../PaymentPanel';
import Unpaid from '../Unpaid';
import Refund from '../Refund';
import EditSales from '../EditSales';

const PaymentListView = ({ data, userID, doUpdate }: any) => {
  const [unpaid, setUnPaid] = useState<any>();
  const [refund, setRefund] = useState<any>();
  const [edit, setEdit] = useState<any>();

  return (
    <Styled.PaymentView>
      {data.transactions.map((d: any) => (
        <PaymentPanel
          data={d}
          key={d.id}
          userID={userID}
          setUnPaid={setUnPaid}
          setRefund={setRefund}
          setEdit={setEdit}
          doUpdate={doUpdate}
        />
      ))}
      {/* CSS-hack : Flex 배치를 맞추기 위한 placeholder */}
      <Styled.Placeholder />
      <Styled.Placeholder />
      <Styled.Placeholder />
      <Styled.Placeholder />
      <Styled.Placeholder />
      {unpaid && <Unpaid setUnPaid={setUnPaid} outData={unpaid} doUpdate={doUpdate} />}
      {refund && <Refund setRefund={setRefund} outData={refund} doUpdate={doUpdate} />}
      {edit && <EditSales setEdit={setEdit} outData={edit} doUpdate={doUpdate} />}
    </Styled.PaymentView>
  );
};

export default PaymentListView;
