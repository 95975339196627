import React, { useState, FormEvent } from 'react';
import * as Styled from './styles';
import { GOFloatingbox, GOButton } from '../../../components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import SalesTable from '../../sales/SalesTable';
import { actionPutUserTicketEdit } from '../../../stores/actions';
import _ from 'lodash';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

const EditSales = ({ setEdit, outData, doUpdate }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const makeAmount = (number: number) => {
    const temp = outData.payments.filter((p: any) => p.method === number);

    if (temp.length > 0) {
      return temp.reduce((prev: any, now: any) => {
        return { ...prev, amount: prev.amount + now.amount };
      });
    }
    return { amount: 0, id: null };
  };

  const [oriData] = useState({
    card: makeAmount(0).amount, // 카드
    cash: makeAmount(1).amount, // 현금
    transfer: makeAmount(2).amount, // 이체
    totalPrice: outData.totalPrice, // 총 결제 금액 (card + cash + transfer + discount)
    unpaidPrice: outData.unpaidPrice, // 미수금
    discount: outData.ticket.discount, // 할인
    price: outData.ticket.originalPrice, // 상품 금액
  });

  // 0:카드, 1:현금, 2:이체
  const [salesData, setSalesData] = useState<any>({
    card: makeAmount(0).amount, // 카드
    cash: makeAmount(1).amount, // 현금
    transfer: makeAmount(2).amount, // 이체
    totalPrice: outData.totalPrice, // 총 결제 금액 (card + cash + transfer + discount)
    unpaidPrice: outData.unpaidPrice, // 미수금
    discount: outData.ticket.discount, // 할인
    price: outData.ticket.originalPrice, // 상품 금액
  });

  const handleSubmit = (e: FormEvent<EventTarget>) => {
    e.preventDefault();
    const body = { ...salesData, payments: [] };
    if (body.card > 0) {
      body.payments.push({ method: 0, amount: body.card });
    }
    if (body.cash > 0) {
      body.payments.push({ method: 1, amount: body.cash });
    }
    if (body.transfer > 0) {
      body.payments.push({
        method: 2,
        amount: body.transfer,
      });
    }

    if (body.unpaidPrice < 0) {
      alert('미수금이 마이너스가 될 수 없습니다. 다시한번 확인해주세요.');
    } else {
      if (
        _.isEqualWith(
          oriData,
          _.mapValues(salesData, (num: any) => num * 1),
        )
      ) {
        alert('수정되었습니다.');
        setEdit('');
      } else {
        dispatch(
          actionPutUserTicketEdit(outData.id, body, () => {
            alert('수정되었습니다.');
            setEdit('');
            doUpdate();
          }),
        );
      }
    }
  };

  return (
    <GOFloatingbox width={350}>
      <UFloatingContainer as="form" onSubmit={handleSubmit}>
        <UFloatingBoxHeader>
          <h1>결제 수정</h1>
          <p>결제 금액을 수정합니다.</p>
        </UFloatingBoxHeader>
        <UFloatingBoxBody>
          <div className="flex_box">
            <Styled.Label>
              <div className="title">상품금액</div>
              <div>
                {salesData.price?.toLocaleString()} {t('common.KRW')}
              </div>
            </Styled.Label>
            <hr />
            <SalesTable
              productData={salesData}
              salesData={salesData}
              setSalesData={setSalesData}
              edit
            />
          </div>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton body={t('common.ok')} type="submit" buttontype="green" />
          <GOButton
            body={t('common.no')}
            onClick={() => {
              setEdit();
            }}
          />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};

export default EditSales;
