import NetworkManager from "../../../NetworkManager";
import { toast } from "react-toastify";

const UserSearch = (keyword: string, ticketType: number = 99) => {
  return new Promise((resolve, reject) => {
    if (keyword) {
      NetworkManager("get", `admin/user/search/users?keyword=${keyword}`)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {});
    } else {
      toast.error("검색어가 없습니다.");
    }
  });
};

export default UserSearch;
