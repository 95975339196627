import NetworkManager from '../../../NetworkManager';

const MemberConfiguration = (isAllMember: number, option: string) => {
  return new Promise((resolve, reject) => {
    if (option === 'sex') {
      NetworkManager(
        'get',
        `admin/smartAdvisor/memberConfiguration?isAllMember=${isAllMember}&groupBy=sex`,
      )
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {});
    } else if (option === 'age') {
      NetworkManager(
        'get',
        `admin/smartAdvisor/memberConfiguration?isAllMember=${isAllMember}&groupBy=age`,
      )
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {});
    } else {
      NetworkManager(
        'get',
        `admin/smartAdvisor/memberConfiguration?isAllMember=${isAllMember}&groupBy=both`,
      )
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {});
    }
  });
};

export default MemberConfiguration;
