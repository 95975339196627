import createActions from '../controller/createActions';

const { onlyRequest } = createActions('accounting');

export const actionGetAccounting = (
  startDate: string,
  endDate: string,
  sex: number,
  age: number,
  onSuccess?: Function,
) =>
  onlyRequest({
    type: 'get',
    url: `accounting?startDate=${startDate}&endDate=${endDate}&sex=${sex}&age=${age}`,
    meta: {
      onSuccess,
    },
  });

export const actionGetBranchAccounting = (
  startDate: string,
  endDate: string,
  sex: number,
  age: number,
  branchID: number,
  onSuccess?: Function,
) =>
  onlyRequest({
    type: 'get',
    url: `accounting?startDate=${startDate}&endDate=${endDate}&sex=${sex}&age=${age}&targetBranchID=${branchID}`,
    meta: {
      onSuccess,
    },
  });

export const actionGetBranchAccountingSummary = (
  startDate: string,
  endDate: string,
  onSuccess?: Function,
) =>
  onlyRequest({
    type: 'get',
    url: `accounting/summary?startDate=${startDate}&endDate=${endDate}`,
    meta: {
      onSuccess,
    },
  });

// 신규 구매 데이터
export const actionGetNewPurchaseUser = (
  startDate: string,
  endDate: string,
  sex: number,
  age: number,
  onSuccess?: Function,
) =>
  onlyRequest({
    type: 'get',
    url: `accounting/newPurchase?startDate=${startDate}&endDate=${endDate}&sex=${sex}&age=${age}`,
    meta: {
      onSuccess,
    },
  });

export const actionGetBranchNewPurchaseUser = (
  startDate: string,
  endDate: string,
  sex: number,
  age: number,
  branchID: number,
  onSuccess?: Function,
) =>
  onlyRequest({
    type: 'get',
    url: `accounting/newPurchase?startDate=${startDate}&endDate=${endDate}&sex=${sex}&age=${age}&targetBranchID=${branchID}`,
    meta: {
      onSuccess,
    },
  });

// 재구매 데이터
export const actionGetRepurchaseUser = (
  startDate: string,
  endDate: string,
  sex: number,
  age: number,
  onSuccess?: Function,
) =>
  onlyRequest({
    type: 'get',
    url: `accounting/rePurchase?startDate=${startDate}&endDate=${endDate}&sex=${sex}&age=${age}`,
    meta: {
      onSuccess,
    },
  });
