import styled from 'styled-components';
import * as color from '../../../color/index';
import { BreakPoints } from '../../../GlobalUtilityStyles';

const headerVariables = {
  mdHeaderHeight: '50px',
};

export const Header = styled.div`
  // height: 36px;
  // min-width: 786px;
  position: sticky;
  top: 0;
  z-index: 9;
  .dimmed {
    display: none;
  }

  ${BreakPoints.md} {
    height: ${headerVariables.mdHeaderHeight};
  }
`;

export const HeaderTop = styled.div`
  height: 100%;
  background: ${color.Colors.primary};
  display: flex;
  justify-content: center;
  .wrap {
    flex-wrap: wrap;
  }
  .btn-menu {
    display: none;
    background: transparent;
    order: -1;
    width: 30px;
    height: 100%;
    font-size: 20px;
    border: 0;
    color: #fff;
    cursor: pointer;
    margin-right: 10px;
    &.active {
      // background: red;
    }
  }
  ${BreakPoints.md} {
    .btn-menu {
      display: block;
    }
  }

  // ${BreakPoints.lg} {
  //   background: green;
  // }

  // ${BreakPoints.md} {
  //   background: red;
  // }

  // ${BreakPoints.sm} {
  //   background: dodgerblue;
  // }
`;

export const LogoSmall = styled.div`
  width: 98px;
  height: 100%;
  background: url('/images/logo_white.svg') center / contain no-repeat;
`;

export const HeaderMenu = styled.ul`
  flex: 1;
  margin: 0;
  padding-left: 32px;
  display: flex;
  align-items: center;
  color: ${color.Colors.white};
  display: inline-block;

  li {
    display: inline-block;
    margin-right: 16px;
    a,
    button {
      font-size: 12px;
      font-weight: bold;
      display: block;
      line-height: inherit;
      padding: 0;
      background: transparent;
      border: 0;
      color: #fff;
      line-height: 36px;
      cursor: pointer;
      width: 100%;
      text-align: left;
    }
  }

  ${BreakPoints.lg} {
    min-width: 100%;
    order: 5;
    padding: 0;
  }
  ${BreakPoints.md} {
    background: #333;
    box-sizing: border-box;
    position: fixed;
    left: -250px;
    top: ${headerVariables.mdHeaderHeight};
    bottom: 0;
    width: 250px;
    min-width: inherit;
    display: block;
    padding: 15px;
    transition: left 0.3s;

    li {
      display: block;
      margin-right: 0;
      a,
      button {
        font-size: 14px;
      }
    }

    &.menu-opend {
      left: 0;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      z-index: 10;
    }

    &.menu-opend + .dimmed {
      background: rgba(0, 0, 0, 0.4);
      position: fixed;
      top: ${headerVariables.mdHeaderHeight};
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      z-index: 8;
    }
  }
`;

export const HeaderRight = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${color.Colors.white};
  line-height: 36px;
  ${BreakPoints.lg} {
    margin-left: auto;
  }
  ${BreakPoints.md} {
    line-height: ${headerVariables.mdHeaderHeight};
  }
  ${BreakPoints.xs} {
    span {
      display: none;
    }
  }
`;
