import NetworkManager from '../../../NetworkManager';

//TicketEdit
const TicketHistory = (data: object, id: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager('get', `admin/user/${id}/history`, data)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export default TicketHistory;
