import React, { useEffect, useState } from 'react';
import * as Styled from '../chartAreaStyles';
import { GOLoading } from '../..';
import MonthlyNewUserBarChart from '../Chart/MonthlyNewUserConfiguration/MonthlyNewUserChart';
// import MonthlyUserSex from '../Convert/MonthlyUserChart/sex';
// import MonthlyUserAge from '../Convert/MonthlyUserChart/age';
import DailyNewUserBarChart from '../Chart/MonthlyNewUserConfiguration/DailyNewUserChart';
import MonthlyExpiredUserBarChart from '../Chart/MonthlyNewUserConfiguration/MonthlyExpiredUserChart';
// import UserSex from '../Convert/UserChart/sex';
// import AgeLine from '../Chart/MonthlyUserConfiguration/AgeLine';

function MonthlyNewUserSex(data: any) {
  let total: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let male: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let female: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  try {
    if (data) {
      console.log('sdfdsfsdfsd', data);
      if (data.length > 0) {
        //setting year-month
        data.map((item: any) => {
          const yearMonth = item.regMonth.split('-');
          if (yearMonth.length === 2) {
            const nMonth = Number(yearMonth[1]);
            if (nMonth > 0 && nMonth < 13) {
              const index = nMonth - 1;
              total[index] = item.total;
              male[index] = item.male;
              female[index] = item.female;
            }
          }
          return 0;
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
  return { total, male, female };
}

function MonthlyReregisteredMember(data: any) {
  let total: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let male: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let female: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  try {
    if (data) {
      if (data.length > 0) {
        //setting year-month
        data.map((item: any) => {
          const yearMonth = item.month.split('-');
          if (yearMonth.length === 2) {
            const nMonth = Number(yearMonth[1]);
            if (nMonth > 0 && nMonth < 13) {
              const index = nMonth - 1;
              if (item.sex === 0) {
                //남자
                male[index] = Number(item.userCount);
              } else {
                female[index] = Number(item.userCount);
              }
              total[index] = male[index] + female[index];
            }
          }
          return 0;
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
  console.log(total, male, female);
  return { total, male, female };
}

const MonthlyNewUserChart = ({ loading, sex, age, expired, year }: any) => {
  const [monthlyNewUserData, setMonthlyNewUserData] = useState<any>({
    labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    datasets: [
      {
        type: 'line',
        label: '전체',
        data: [],
        // yAxisID: '전체',
        backgroundColor: '#606060',
        borderColor: '#606060',
        borderDash: [5, 3],
        pointBorderWidth: 7,
        fill: false,
      },
      {
        type: 'bar',
        label: '남성',
        data: [],
        backgroundColor: '#6AA8E2',
        borderColor: '#6AA8E2',
        borderWidth: 1,
      },
      {
        type: 'bar',
        label: '여성',
        data: [],
        backgroundColor: '#F5C4DE',
        borderColor: '#F5C4DE',
        pointBorderWidth: 7,
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    const sData = MonthlyNewUserSex(sex);
    const monthlyNewUserData = {
      labels: [
        '1월',
        '2월',
        '3월',
        '4월',
        '5월',
        '6월',
        '7월',
        '8월',
        '9월',
        '10월',
        '11월',
        '12월',
      ],
      datasets: [
        {
          type: 'line',
          label: '전체',
          data: sData.total,
          // yAxisID: '전체',
          backgroundColor: '#606060',
          borderColor: '#606060',
          borderDash: [5, 3],
          pointBorderWidth: 7,
          fill: false,
        },
        {
          type: 'bar',
          label: '남성',
          data: sData.male,
          backgroundColor: '#6AA8E2',
          borderColor: '#6AA8E2',
          borderWidth: 1,
        },
        {
          type: 'bar',
          label: '여성',
          data: sData.female,
          backgroundColor: '#F5C4DE',
          borderColor: '#F5C4DE',
          pointBorderWidth: 7,
          borderWidth: 1,
        },
      ],
    };
    setMonthlyNewUserData(monthlyNewUserData);
  }, [sex]);

  const [ageData, setAgeData] = useState<any>({
    labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    datasets: [
      {
        type: 'line',
        label: '전체',
        data: [],
        // yAxisID: '전체',
        backgroundColor: '#606060',
        borderColor: '#606060',
        borderDash: [5, 3],
        pointBorderWidth: 7,
        fill: false,
      },
      {
        type: 'bar',
        label: '남성',
        data: [],
        backgroundColor: '#6AA8E2',
        borderColor: '#6AA8E2',
        borderWidth: 1,
      },
      {
        type: 'bar',
        label: '여성',
        data: [],
        backgroundColor: '#F5C4DE',
        borderColor: '#F5C4DE',
        pointBorderWidth: 7,
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    const aData = MonthlyReregisteredMember(age);
    const ageData = {
      labels: [
        '1월',
        '2월',
        '3월',
        '4월',
        '5월',
        '6월',
        '7월',
        '8월',
        '9월',
        '10월',
        '11월',
        '12월',
      ],
      datasets: [
        {
          type: 'line',
          label: '전체',
          data: aData.total,
          // yAxisID: '전체',
          backgroundColor: '#606060',
          borderColor: '#606060',
          borderDash: [5, 3],
          pointBorderWidth: 7,
          fill: false,
        },
        {
          type: 'bar',
          label: '남성',
          data: aData.male,
          backgroundColor: '#6AA8E2',
          borderColor: '#6AA8E2',
          borderWidth: 1,
        },
        {
          type: 'bar',
          label: '여성',
          data: aData.female,
          backgroundColor: '#F5C4DE',
          borderColor: '#F5C4DE',
          pointBorderWidth: 7,
          borderWidth: 1,
        },
      ],
    };
    setAgeData(ageData);
  }, [age]);

  const [expiredData, setExpiredData] = useState<any>({
    labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    datasets: [
      {
        type: 'line',
        label: '전체',
        data: [],
        // yAxisID: '전체',
        backgroundColor: '#606060',
        borderColor: '#606060',
        borderDash: [5, 3],
        pointBorderWidth: 7,
        fill: false,
      },
      {
        type: 'bar',
        label: '남성',
        data: [],
        backgroundColor: '#6AA8E2',
        borderColor: '#6AA8E2',
        borderWidth: 1,
      },
      {
        type: 'bar',
        label: '여성',
        data: [],
        backgroundColor: '#F5C4DE',
        borderColor: '#F5C4DE',
        pointBorderWidth: 7,
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    const eData = MonthlyReregisteredMember(expired);
    const expiredData = {
      labels: [
        '1월',
        '2월',
        '3월',
        '4월',
        '5월',
        '6월',
        '7월',
        '8월',
        '9월',
        '10월',
        '11월',
        '12월',
      ],
      datasets: [
        {
          type: 'line',
          label: '전체',
          data: eData.total,
          // yAxisID: '전체',
          backgroundColor: '#606060',
          borderColor: '#606060',
          borderDash: [5, 3],
          pointBorderWidth: 7,
          fill: false,
        },
        {
          type: 'bar',
          label: '남성',
          data: eData.male,
          backgroundColor: '#6AA8E2',
          borderColor: '#6AA8E2',
          borderWidth: 1,
        },
        {
          type: 'bar',
          label: '여성',
          data: eData.female,
          backgroundColor: '#F5C4DE',
          borderColor: '#F5C4DE',
          pointBorderWidth: 7,
          borderWidth: 1,
        },
      ],
    };
    setExpiredData(expiredData);
  }, [expired]);

  return (
    <Styled.Container>
      {loading ? (
        <GOLoading />
      ) : (
        sex &&
        age &&
        expired && (
          <>
            {sex.length > 0 ? (
              <MonthlyNewUserBarChart label={`${year}_신규회원`} data={monthlyNewUserData} />
            ) : (
              <>아직 데이터가 부족합니다!</>
            )}
            {age.length > 0 ? (
              <DailyNewUserBarChart label={`${year}_재등록 회원`} data={ageData} />
            ) : (
              <>아직 데이터가 부족합니다!</>
            )}
            {expired.length > 0 ? (
              <MonthlyExpiredUserBarChart label={`${year}_만료 회원`} data={expiredData} />
            ) : (
              <>아직 데이터가 부족합니다!</>
            )}
          </>
        )
      )}
    </Styled.Container>
  );
};

export default MonthlyNewUserChart;
