import styled from 'styled-components';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const Panel = styled.button`
  position: relative;
  width: 100%;
  flex-grow: 1;
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  margin: 0 0 8px;
  cursor: pointer;
  transition: 0.35s;
  color: #495057;
  background: white;
  display: block;
  outline: 0;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.16);
    transition: 0.35s;
  }
  &:focus {
    background-color: #495057;
    color: white;
    outline: 0;
  }

  ${BreakPoints.md} {
    padding: 8px 10px;
  }

  ${BreakPoints.sm} {
    padding: 5px 10px;
  }
`;

export const PanelTop = styled.div`
  display: flex;
  align-items: center;
`;

export const PanelDate = styled.div`
  font-size: 26px;
  font-weight: 700;
  color: #495057;

  ${BreakPoints.md} {
    font-size: 22px;
  }

  ${BreakPoints.sm} {
    font-size: 20px;
  }
`;

export const PanelInfoBox = styled.div`
  margin-left: 20px;
  ${BreakPoints.md} {
    margin-left: 10px;
  }
`;

export const PanelInfo = styled.div`
  font-size: 16px;
  text-align: left;
  line-height: 1.5;

  ${BreakPoints.md} {
    font-size: 14px;
  }

  ${BreakPoints.sm} {
    font-size: 13px;
  }
`;

export const IconBox = styled.div`
  width: 24px;
  height: 24px;
  margin-left: auto;
  ${BreakPoints.sm} {
    transform: rotate(90deg);
  }
`;
