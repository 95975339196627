import React from "react";
import "./index.scss";

interface PanelLabelProps {
  label?: string | number | any;
  body?: string | number | JSX.Element | any;
  style?: any;
  class?: string;
}

const PanelLabel = (props: PanelLabelProps) => {
  return (
    <div className={`PanelLabel ${props.class}`} style={props.style}>
      <div className="Panel_label">{props.label}</div>
      <div className="Panel_body">{props.body}</div>
    </div>
  );
};

export default PanelLabel;
