import NetworkManager from '../../../NetworkManager';

const ModifyCheckPassword = (data: object) => {
  return new Promise((resolve, reject) => {
    NetworkManager('put', 'admin/auth/password/update', data)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export default ModifyCheckPassword;
