import React, { useState, useEffect, useCallback } from 'react';
import {
  HeaderBottom,
  StatusTool,
  GOLayout,
  GOLayoutBox,
  // SmallTitle,
  GOLoading,
  // GOLabel,
  // UserUsedTicketPanel,
  // UserTicketPanel,
  // UserUnCheckedPanel,
  // GOTextarea,
  GOButton,
  // UserEdit,
  TrainingPieChart,
} from '../../../components';
import { useTranslation } from 'react-i18next';
// import { useParams, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './index.scss';
import * as Styled from './styles';
import dayjs from 'dayjs';
// import dayjs from 'dayjs';
// import { phoneNumberFormatting } from '../../../middlewares';

import {
  actionGetTrainingManagementInfo,
  actionGetTrainingManagementLessonStat,
  actionGetTrainingManagementPlateStat,
  actionGetTrainingManagementGolfPowerRanking,
  actionGetTrainingManagementGolfScoreRanking,
  actionGetTrainingManagementYearlyLessonAttendance,
  actionGetTrainingManagementYearlyPlateAttendance,
  // actionGetTrainingManagementGolfPowerGames, //feature/2021/1201/gameLog,
  actionGetTrainingManagementGameLog, //feature/2021/1201/gameLog
  actionGetTrainingManagementSearchImpactVisionId,
  actionGetTrainingManagementLinkImpactVisionId,
} from '../../../stores/actions';

import MonthlyAttendanceBar from '../../../components/user/Chart/MonthlyLessonPlateBar/MonthlyAttendanceBar';
// import GolfPowerDiagnosisTable from '../../../components/user/GolfPowerDiagnosisTable';
import GolfPowerGameTable from '../../../components/user/GolfPowerTable';

import ReactPaginate from 'react-paginate';
import './paginateStyles.css';
import { golfPowerGameDataType } from '../../../Types/trainingManagementType';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import 'tui-grid/dist/tui-grid.css';
import 'tui-pagination/dist/tui-pagination.css';
// import './toastUIGridCustom.css';

import TuiGrid from 'tui-grid';
import Grid from '@toast-ui/react-grid';
import { makeYearDataForComboBox } from '../../../util/converter';

TuiGrid.setLanguage('ko');
TuiGrid.applyTheme('striped');

interface ParamsType {
  id: string;
}

const Detail = () => {
  const { t } = useTranslation();

  //초기 훈련관리데이타의 정보데이타  회원명/생년월일/나이/성별/최초등록일을 가져온다.
  const [isLoadingTrainingManagementInfo, setLoadingrainingManagementInfo] = useState(true);

  const [trainerInfo, setTrainerInfo] = useState<any>({
    name: '',
    birthDay: '1990-01-04',
    sex: '',
    registeredAt: '2021-01-01',
  });

  const [lessonStat, setLessonStat] = useState<any>({
    lessonTotalCount: 0, //레슨 총 구매수
    lessonAttendanceCount: 0, //레슨 출석 수
  });

  const [plateStat, setPlateState] = useState<any>({
    plateAttendanceCount: 0, // 타석 총 출석 수
  });

  const [scoreRanking, setScoreRanking] = useState(0);
  const [powerRanking, setPowerRanking] = useState(0);

  const params = useParams<ParamsType>();

  const dispatch = useDispatch();

  const [trainingGamingRate, setTrainingGamingRate] = useState({
    labels: [`연습`, `게임`],
    datasets: [
      {
        label: '성별',
        data: [50, 50],
        backgroundColor: ['#6AA8E2', '#F5C4DE'],
        hoverOffset: 4,
      },
    ],
  });

  const [monthlyLesson, setMonthlyLesson] = useState<any>({
    labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    datasets: [
      {
        type: 'bar',
        label: '출석수',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: '#2CB101',
        borderColor: '#2CB101',
        borderWidth: 1,
      },
    ],
  });

  const [monthlyPlate, setMonthlyPlate] = useState<any>({
    labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    datasets: [
      {
        type: 'bar',
        label: '출석수',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: '#277C77',
        borderColor: '#277C77',
        borderWidth: 1,
      },
    ],
  });

  const [isImpactVisionIdCreated, setImpactVisionIdCreated] = useState(true);

  const [isRequestingLinkingImpactVisionId, setRequestingLinkingImpactVisionId] = useState(false);

  const yearComboData = makeYearDataForComboBox();

  const [lessonQueryYear, setLesonQueryYear] = useState<number>(new Date().getFullYear());
  const [plateQueryYear, setPlateQueryYear] = useState<number>(new Date().getFullYear());

  const onChangeLessonYear = (year: number) => {
    setLesonQueryYear(year);
  };

  const onChangePlateYear = (year: number) => {
    setPlateQueryYear(year);
  };

  const USER_NAME = window.sessionStorage.getItem(`SLID${params.id}`);

  useEffect(() => {
    dispatch(
      actionGetTrainingManagementInfo(params.id, (res: any) => {
        try {
          setLoadingrainingManagementInfo(false);
          setTrainerInfo(res.data.Data);
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [dispatch, params.id]);

  useEffect(() => {
    dispatch(
      actionGetTrainingManagementLessonStat(params.id, (res: any) => {
        try {
          setLessonStat(res.data.Data);
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [dispatch, params.id]);

  useEffect(() => {
    dispatch(
      actionGetTrainingManagementPlateStat(params.id, (res: any) => {
        try {
          setPlateState(res.data.Data);
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [dispatch, params.id]);

  useEffect(() => {
    dispatch(
      actionGetTrainingManagementGolfScoreRanking(params.id, (res: any) => {
        try {
          setScoreRanking(res.data.Data.golfScoreRanking);
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [dispatch, params.id]);

  useEffect(() => {
    dispatch(
      actionGetTrainingManagementGolfPowerRanking(params.id, (res: any) => {
        try {
          setPowerRanking(res.data.Data.golfPowerRanking);
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [dispatch, params.id]);

  useEffect(() => {
    dispatch(
      actionGetTrainingManagementYearlyLessonAttendance(params.id, lessonQueryYear, (res: any) => {
        try {
          setMonthlyLesson(res.data.Data);
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [dispatch, params.id, lessonQueryYear]);

  useEffect(() => {
    dispatch(
      actionGetTrainingManagementYearlyPlateAttendance(params.id, plateQueryYear, (res: any) => {
        try {
          setMonthlyPlate(res.data.Data);
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [dispatch, params.id, plateQueryYear]);
  //
  // feature/2021/1201/gameLog 공프력 게임 받아오는 API 대신에 디비에서 긇어옴
  // useEffect(() => {
  //   dispatch(
  //     actionGetTrainingManagementGolfPowerGames(params.id, golfPowerPageIdx, (res: any) => {
  //       try {
  //         setGolfPowerGameData({
  //           total_count: res.data.Data.total_count,
  //           page_idx: res.data.Data.page_idx,
  //           page_line: res.data.Data.page_line,
  //           diagnose: res.data.Data.diagnose,
  //         });
  //         const pageCount =
  //           Math.floor(Number.parseInt(res.data.Data.total_count) / res.data.Data.page_line) + 1;
  //         if (
  //           Number.parseInt(res.data.Data.page_idx) === 0 &&
  //           Number.parseInt(res.data.Data.total_count) > 0
  //         ) {
  //           setGolfPowerPageCount(pageCount);
  //         }
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     }),
  //   );
  // }, [dispatch, params.id, golfPowerPageIdx]);

  useEffect(() => {
    dispatch(
      actionGetTrainingManagementSearchImpactVisionId(params.id, (res: any) => {
        try {
          if (res.data.Data.isFound !== null && res.data.Data.isFound !== undefined) {
            setImpactVisionIdCreated(res.data.Data.isFound);
          }
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [dispatch, params.id]);

  const birthday = (birth: any) => {
    const today = new Date().getFullYear();
    const myBirth = new Date(birth).getFullYear();
    return today - myBirth;
  };

  //feature/2021/1201/gameLog
  const [gameTabIndex, setGameTabIndex] = useState(0);
  const [trainingTabIndex, setTrainingTabIndex] = useState(0);

  const onSelectGameTab = useCallback(
    (index: any) => {
      if (index === 0) {
        requestGameLogCapaGame();
      } else if (index === 1) {
        requestGameLogCapaExam();
      } else if (index === 2) {
        requestGameLogRound();
      }
      setGameTabIndex(index);
    },
    [params.id],
  );

  const onSelectTrainingTab = useCallback(
    (index: any) => {
      if (index === 0) {
        requestGameLogPutt();
      } else if (index === 1) {
        requestGameLogAps();
      } else if (index === 2) {
        requestGameLogRunAps();
      } else if (index === 3) {
        requestGameLogFdr();
      } else if (index === 4) {
        requestGameLogIron();
      } else if (index === 5) {
        requestGameLogDr();
      }
      setTrainingTabIndex(index);
    },
    [params.id],
  );

  const pracColumn: any = [
    { name: 'regDate', header: '일시', align: 'center', sortingType: 'desc', sortable: true },
    { name: 'startDate', header: '시작', align: 'center' },
    { name: 'endDate', header: '종료', align: 'center' },
    { name: 'timeGm', header: '골프력', align: 'right', sortable: true },
    { name: 'timeEx', header: '골프력진단', align: 'right', sortable: true },
    { name: 'timeDr', header: '드라이버', align: 'right', sortable: true },
    { name: 'timeAp', header: '어프로치', align: 'right', sortable: true },
    { name: 'timeRunAp', header: '러닝어프로치', align: 'right', sortable: true },
    { name: 'timePutt', header: '퍼팅', align: 'right', sortable: true },
    { name: 'timeFDr', header: '드라이버티샷', align: 'right', sortable: true },
    { name: 'timeFree', header: '필드연습', align: 'right', sortable: true },
  ];

  const capaGameColumn: any = [
    { name: 'regDate', header: '일시', align: 'center', sortingType: 'desc', sortable: true },
    { name: 'type', header: '게임종류', align: 'center', sortable: true },
    { name: 'club', header: '클럽', align: 'center', sortable: true },
    { name: 'result', header: '결과', align: 'center', sortable: true },
  ];

  const capaExColumn: any = [
    { name: 'regDate', header: '일시', align: 'center', sortingType: 'desc', sortable: true },
    { name: 'holeType', header: '홀타입', align: 'center', sortable: true },
    { name: 'category', header: '카테고리', align: 'center', sortable: true },
    { name: 'result', header: '결과', align: 'center', sortable: true },
  ];

  const roundColumn: any = [
    { name: 'regDate', header: '일시', align: 'center', sortingType: 'desc', sortable: true },
    { name: 'cc', header: 'CC', align: 'center' },
    { name: 'holeIndex', header: '홀', align: 'center' },
    { name: 'par', header: '파', align: 'center' },
    { name: 'score', header: '스코어', align: 'center' },
  ];
  //

  const puttColumn: any = [
    { name: 'regDate', header: '일시', align: 'center', sortingType: 'desc', sortable: true },
    { name: 'dist', header: '거리', align: 'center' },
    { name: 'onTarget', header: '타겟안착율', align: 'center' },
    { name: 'cnt', header: '연습볼수', align: 'center' },
    { name: 'score', header: '스코어', align: 'center' },
    { name: 'type', header: '단위계', align: 'center' },
  ];

  const apsColumn: any = [
    { name: 'regDate', header: '일시', align: 'center', sortingType: 'desc', sortable: true },
    { name: 'dist', header: '거리', align: 'center' },
    { name: 'onTarget', header: '타겟안착율', align: 'center' },
    { name: 'cnt', header: '연습볼수', align: 'center' },
    { name: 'score', header: '스코어', align: 'center' },
    { name: 'type', header: '단위계', align: 'center' },
  ];

  const runApsColumn: any = [
    { name: 'regDate', header: '일시', align: 'center', sortingType: 'desc', sortable: true },
    { name: 'dist', header: '거리', align: 'center' },
    { name: 'onTarget', header: '타겟안착율', align: 'center' },
    { name: 'cnt', header: '전체샷', align: 'center' },
    { name: 'score', header: '성공샷', align: 'center' },
    { name: 'type', header: '단위계', align: 'center' },
  ];

  const fdrColumn: any = [
    { name: 'regDate', header: '일시', align: 'center', sortingType: 'desc', sortable: true },
    { name: 'cc', header: 'CC', align: 'center' },
    { name: 'dist', header: '평균비거리', align: 'center' },
    { name: 'maxDist', header: '최대비거리', align: 'center' },
    { name: 'shotType', header: '구질', align: 'center' },
    { name: 'fairway', header: '페어웨이적중률', align: 'center' },
    { name: 'cnt', header: '연습볼수', align: 'center' },
  ];

  const ironColumn: any = [
    { name: 'regDate', header: '일시', align: 'center', sortingType: 'desc', sortable: true },
    { name: 'cc', header: 'CC', align: 'center' },
    { name: 'onGreen', header: '그린적중률', align: 'center' },
    { name: 'onTarget', header: '타겟안착율', align: 'center' },
    { name: 'score', header: '스코어', align: 'center' },
    { name: 'cnt', header: '연습볼수', align: 'center' },
  ];

  const drColumn: any = [
    { name: 'regDate', header: '일시', align: 'center', sortingType: 'desc', sortable: true },
    { name: 'club', header: '클럽', align: 'center' },
    { name: 'dist', header: '평균비거리', align: 'center' },
    { name: 'carry', header: '캐리', align: 'center' },
    { name: 'speed', header: '볼스피드', align: 'center' },
    { name: 'maxDist', header: '최대비거리', align: 'center' },
    { name: 'angle', header: '발사각', align: 'center' },
    { name: 'backspin', header: '백스핀', align: 'center' },
    { name: 'shotType', header: '최대구질', align: 'center' },
    { name: 'cnt', header: '연습볼수', align: 'center' },
  ];

  const pageOption: any = {
    useClient: true,
    perPage: 12,
  };

  const [ivmGamePrac, setIvmGamePrac] = useState<any[]>([]);
  const [ivmGameCapaGame, setIvmGameCapaGame] = useState<any[]>([]);
  const [ivmGameCapaEx, setIvmGameCapaEx] = useState<any[]>([]);
  const [ivmGameRound, setIvmGameRound] = useState<any[]>([]);

  const [ivmGamePutt, setIvmGamePutt] = useState<any[]>([]);
  const [ivmGameAps, setIvmGameAps] = useState<any[]>([]);
  const [ivmGameRunAps, setIvmGameRunAps] = useState<any[]>([]);
  const [ivmGameFdr, setIvmGameFdr] = useState<any[]>([]);
  const [ivmGameIron, setIvmGameIron] = useState<any[]>([]);
  const [ivmGameDr, setIvmGameDr] = useState<any[]>([]);

  const toMin = (tm: any) => {
    let ret = 0;
    try {
      ret = Number(tm) / 60;
    } catch (e) {
      return 0;
    }
    return ret.toFixed();
  };

  const requestGameLogPrac = useCallback(() => {
    dispatch(
      actionGetTrainingManagementGameLog(params.id, 'prac', (res: any) => {
        try {
          let pracDataFromServer: any[] = [];
          res.data?.Data?.data?.data?.map((o: any) => {
            pracDataFromServer.push({
              // ...o,
              timeGm: toMin(o.timeGm),
              timeEx: toMin(o.timeEx),
              // startDate: dayjs(o.sttartDate).format('hh:mm'), //2022.12.08 오타
              startDate: dayjs(o.startDate).format('hh:mm'),
              endDate: dayjs(o.endDate).format('hh:mm'),
              timeDr: toMin(o.timeDr),
              timeAp: toMin(o.timeAp),
              timeRunAp: toMin(o.timeRunAp),
              timePutt: toMin(o.timePutt),
              timeFDr: toMin(o.timeFDr),
              timeFree: toMin(o.timeFree),
              regDate: dayjs(o.regDate).format('YYYY-MM-DD'),
            });
            return 0;
          });
          setIvmGamePrac(pracDataFromServer);
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [params.id]);

  const requestGameLogCapaGame = useCallback(() => {
    dispatch(
      actionGetTrainingManagementGameLog(params.id, 'capaGames', (res: any) => {
        try {
          let dataFromServer: any[] = [];
          res.data?.Data?.data?.data?.map((o: any) => {
            dataFromServer.push({
              pracID: o.pracID,
              memID: o.memID,
              type: o.type,
              club: o.club,
              result: o.result,
              regDate: dayjs(o.regDate).format('YYYY-MM-DD'),
            });
            return 0;
          });
          setIvmGameCapaGame(dataFromServer);
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [params.id]);

  const requestGameLogCapaExam = useCallback(() => {
    dispatch(
      actionGetTrainingManagementGameLog(params.id, 'capaExams', (res: any) => {
        try {
          let dataFromServer: any[] = [];
          res.data?.Data?.data?.data?.map((o: any) => {
            dataFromServer.push({
              holeType: o.holeType,
              category: o.category,
              result: o.result,
              regDate: dayjs(o.regDate).format('YYYY-MM-DD'),
            });
            return 0;
          });
          setIvmGameCapaEx(dataFromServer);
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [params.id]);

  const requestGameLogRound = useCallback(() => {
    dispatch(
      actionGetTrainingManagementGameLog(params.id, 'rounds', (res: any) => {
        try {
          let dataFromServer: any[] = [];
          res.data?.Data?.data?.data?.map((o: any) => {
            dataFromServer.push({
              regDate: dayjs(o.regDate).format('YYYY-MM-DD'),
              cc: o.cc,
              holeIndex: o.holeIndex,
              par: o.par,
              score: o.score,
              putt: o.putt,
              drType: o.drType,
              longHit: o.longHit,
              onFairway: o.onFairway,
              onGreen: o.onGreen,
              parSave: o.parSave,
              longestPutt: o.longestPutt,
              mulligan: o.mulligan,
            });
            return 0;
          });
          setIvmGameRound(dataFromServer);
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [params.id]);

  const requestGameLogPutt = useCallback(() => {
    dispatch(
      actionGetTrainingManagementGameLog(params.id, 'putt', (res: any) => {
        try {
          let dataFromServer: any[] = [];
          res.data?.Data?.data?.data?.map((o: any) => {
            dataFromServer.push({
              regDate: dayjs(o.regDate).format('YYYY-MM-DD'),
              dist: o.dist,
              onTarget: o.onTarget,
              cnt: o.cnt,
              score: o.score,
              type: o.type,
            });
            return 0;
          });
          setIvmGamePutt(dataFromServer);
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [params.id]);

  const requestGameLogAps = useCallback(() => {
    dispatch(
      actionGetTrainingManagementGameLog(params.id, 'aps', (res: any) => {
        try {
          let dataFromServer: any[] = [];
          res.data?.Data?.data?.data?.map((o: any) => {
            dataFromServer.push({
              regDate: dayjs(o.regDate).format('YYYY-MM-DD'),
              dist: o.dist,
              onTarget: o.onTarget,
              cnt: o.cnt,
              score: o.score,
              type: o.type,
            });
            return 0;
          });
          setIvmGameAps(dataFromServer);
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [params.id]);

  const requestGameLogRunAps = useCallback(() => {
    dispatch(
      actionGetTrainingManagementGameLog(params.id, 'runApps', (res: any) => {
        try {
          let dataFromServer: any[] = [];
          res.data?.Data?.data?.data?.map((o: any) => {
            dataFromServer.push({
              regDate: dayjs(o.regDate).format('YYYY-MM-DD'),
              dist: o.dist,
              onTarget: o.onTarget,
              cnt: o.cnt,
              score: o.score,
              type: o.type,
            });
            return 0;
          });
          setIvmGameRunAps(dataFromServer);
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [params.id]);

  const requestGameLogFdr = useCallback(() => {
    dispatch(
      actionGetTrainingManagementGameLog(params.id, 'fdr', (res: any) => {
        try {
          let dataFromServer: any[] = [];
          res.data?.Data?.data?.data?.map((o: any) => {
            dataFromServer.push({
              regDate: dayjs(o.regDate).format('YYYY-MM-DD'),
              cc: o.cc,
              dist: o.dist,
              maxDist: o.maxDist,
              shotType: o.shotType,
              shotRate: o.shotRate,
              fairway: o.fairway,
              cnt: o.cnt,
            });
            return 0;
          });
          setIvmGameFdr(dataFromServer);
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [params.id]);

  const requestGameLogIron = useCallback(() => {
    dispatch(
      actionGetTrainingManagementGameLog(params.id, 'iron', (res: any) => {
        try {
          let dataFromServer: any[] = [];
          res.data?.Data?.data?.data?.map((o: any) => {
            dataFromServer.push({
              regDate: dayjs(o.regDate).format('YYYY-MM-DD'),
              cc: o.cc,
              onGreen: o.onGreen,
              onTarget: o.onTarget,
              score: o.score,
              cnt: o.cnt,
            });
            return 0;
          });
          setIvmGameIron(dataFromServer);
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [params.id]);

  const requestGameLogDr = useCallback(() => {
    dispatch(
      actionGetTrainingManagementGameLog(params.id, 'dr', (res: any) => {
        try {
          let dataFromServer: any[] = [];
          res.data?.Data?.data?.data?.map((o: any) => {
            dataFromServer.push({
              // pracID, regDate
              regDate: dayjs(o.regDate).format('YYYY-MM-DD'),
              club: o.club,
              dist: o.dist,
              carry: o.carry,
              speed: o.speed,
              maxDist: o.maxDist,
              angle: o.angle,
              backspin: o.backspin,
              shotType: o.shotType,
              cnt: o.cnt,
            });
            return 0;
          });
          setIvmGameDr(dataFromServer);
        } catch (e) {
          console.log(e);
        }
      }),
    );
  }, [params.id]);

  useEffect(() => {
    requestGameLogPrac();
    requestGameLogCapaGame();
    requestGameLogPutt();
  }, [dispatch, params.id]);

  return (
    <>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool link="/admin/user/grid" type="prev" body={t('user.list')} />
            <StatusTool type="arrow" />
            <StatusTool
              link={`/admin/user/${params.id}`}
              type="prev"
              body={USER_NAME ? USER_NAME : params.id}
            />
            <StatusTool type="arrow" />
            <StatusTool type="now" body="훈련 관리" />
          </>
        }
        rightmenu={<></>}
      />
      <GOLayout
        body={
          isLoadingTrainingManagementInfo ? (
            <GOLoading />
          ) : (
            <>
              {trainerInfo && (
                <>
                  <Styled.BoxWrapper>
                    <div className="trainingManagementBoxContainer">
                      <GOLayoutBox
                        height={300}
                        width={1200}
                        body={
                          <Styled.InnerContainer>
                            {/*<SmallTitle body="회원정보" />*/}
                            <div>
                              {isImpactVisionIdCreated === true ? (
                                <div>
                                  {' '}
                                  <h2> 회원정보 </h2> <hr />{' '}
                                </div>
                              ) : (
                                <div>
                                  <h2> 회원정보</h2>
                                  <div>
                                    <GOButton
                                      body={'훈련 데이타 연동'}
                                      onClick={() => {
                                        if (!isRequestingLinkingImpactVisionId) {
                                          setRequestingLinkingImpactVisionId(true);
                                          dispatch(
                                            actionGetTrainingManagementLinkImpactVisionId(
                                              params.id,
                                              (res: any) => {
                                                setRequestingLinkingImpactVisionId(true);
                                                if (
                                                  res.data.Data.isCreated !== null &&
                                                  res.data.Data.isCreated !== undefined
                                                ) {
                                                  if (res.data.Data.isCreated === true) {
                                                    setImpactVisionIdCreated(true);
                                                  }
                                                }
                                              },
                                            ),
                                          );
                                        }
                                      }}
                                      buttontype={
                                        isRequestingLinkingImpactVisionId === false
                                          ? 'green'
                                          : 'disabled'
                                      }
                                      noMarginLeft={true}
                                    />
                                  </div>
                                  <hr />
                                </div>
                              )}
                            </div>
                            <Styled.MemberInfo>
                              <Styled.Profile>
                                <Styled.ProfileImage
                                  style={{
                                    background: trainerInfo.profileImageURL
                                      ? `url(${trainerInfo.profileImageURL}) center / cover`
                                      : `#f0f0f0`,
                                  }}
                                />
                                <div className="golfPowerRanking">
                                  {' '}
                                  {powerRanking < 1
                                    ? '골프력 랭킹 준비중'
                                    : `골프력 랭킹 ${powerRanking} 위`}{' '}
                                </div>
                                <div className="scoreRanking">
                                  {' '}
                                  {scoreRanking < 1
                                    ? '스코어 랭킹 준비중'
                                    : `스코어 랭킹 ${scoreRanking} 위`}{' '}
                                </div>
                              </Styled.Profile>
                              <Styled.DetailText>
                                <div className="name">{trainerInfo.name}</div>
                                <div className="normal">
                                  {trainerInfo.dateOfBirth?.split('T')[0]}
                                </div>
                                <div className="normal">
                                  {birthday(trainerInfo.dateOfBirth)}세 /{' '}
                                  {trainerInfo.sex === 0 ? '남자' : '여자'}
                                </div>
                                <div className="normal">
                                  {' '}
                                  최초 회원 등록일 : {trainerInfo.registeredAt?.split('T')[0]}
                                </div>
                                <div className="normal">
                                  {' '}
                                  레슨 총 출석수: {lessonStat.lessonAttendanceCount}
                                </div>
                                <div className="normal">
                                  {' '}
                                  타석 총 출석수 : {plateStat.plateAttendanceCount}
                                </div>
                              </Styled.DetailText>
                              <Styled.TrainingRatePie>
                                <TrainingPieChart data={trainingGamingRate} />
                              </Styled.TrainingRatePie>
                            </Styled.MemberInfo>
                          </Styled.InnerContainer>
                        }
                      />
                      {/*<GOLayoutBox*/}
                      {/*  height={600}*/}
                      {/*  body={*/}
                      {/*    <>*/}
                      {/*      <SmallTitle body="이용권 목록" />*/}
                      {/*    </>*/}
                      {/*  }*/}
                      {/*/>*/}
                    </div>
                    <div className="trainingManagementBoxContainer">
                      <GOLayoutBox
                        height={600}
                        width={`100%`}
                        body={
                          <Styled.InnerContainer>
                            <Styled.TitleWrapper>
                              <MonthlyAttendanceBar
                                yearComboData={yearComboData}
                                label={`${lessonQueryYear}년 월별 레슨 출석수`}
                                year={lessonQueryYear}
                                data={monthlyLesson}
                                onChangeYear={onChangeLessonYear}
                                idForCanvas="MonthlyLessonAttendanceId"
                              />
                            </Styled.TitleWrapper>
                            <Styled.TitleWrapper>
                              <MonthlyAttendanceBar
                                label={`${plateQueryYear}년 월별 타석 출석수`}
                                yearComboData={yearComboData}
                                year={plateQueryYear}
                                data={monthlyPlate}
                                onChangeYear={onChangePlateYear}
                                idForCanvas="MonthlyPlateAttendanceId"
                              />
                            </Styled.TitleWrapper>
                          </Styled.InnerContainer>
                        }
                      />
                    </div>
                    {/*<div className="trainingManagementBoxContainer">*/}
                    {/*  <GOLayoutBox*/}
                    {/*    height={600}*/}
                    {/*    width={`100%`}*/}
                    {/*    body={*/}
                    {/*      <>*/}
                    {/*        <Styled.TitleWrapper>*/}
                    {/*          <div className="title">*/}
                    {/*            연습 시간 {golfPowerPageCount < 1 && '(데이타 없음)'}{' '}*/}
                    {/*          </div>*/}
                    {/*        </Styled.TitleWrapper>*/}
                    {/*        <hr />*/}
                    {/*        <Styled.BlockedTableDiv>*/}
                    {/*          <GolfPowerGameTable*/}
                    {/*            total_count={golfPowerGameData.total_count}*/}
                    {/*            page_idx={golfPowerGameData.page_idx}*/}
                    {/*            page_line={golfPowerGameData.page_line}*/}
                    {/*            diagnose={golfPowerGameData.diagnose}*/}
                    {/*          />*/}
                    {/*        </Styled.BlockedTableDiv>*/}
                    {/*        <hr />*/}
                    {/*        {golfPowerPageCount > 0 ? (*/}
                    {/*          <ReactPaginate*/}
                    {/*            pageRangeDisplayed={2}*/}
                    {/*            marginPagesDisplayed={5}*/}
                    {/*            previousLabel={'← 이전'}*/}
                    {/*            nextLabel={'다음 →'}*/}
                    {/*            pageCount={golfPowerPageCount}*/}
                    {/*            onPageChange={onPageChange}*/}
                    {/*            containerClassName={'pagination'}*/}
                    {/*            previousLinkClassName={'pagination__link'}*/}
                    {/*            nextLinkClassName={'pagination__link'}*/}
                    {/*            disabledClassName={'pagination__link--disabled'}*/}
                    {/*            activeClassName={'pagination__link--active'}*/}
                    {/*          />*/}
                    {/*        ) : (*/}
                    {/*          <hr />*/}
                    {/*        )}*/}
                    {/*      </>*/}
                    {/*    }*/}
                    {/*  />*/}
                    {/*</div>*/}

                    <div className="trainingManagementBoxContainer">
                      <GOLayoutBox
                        height={600}
                        width={`100%`}
                        body={
                          <Styled.InnerContainer>
                            <Styled.TitleWrapper>
                              <div className="title" style={{ marginBottom: '1.0em' }}>
                                연습시간 (분단위)
                              </div>
                            </Styled.TitleWrapper>
                            {/*<hr />*/}
                            <div style={{ width: '100%', margin: '0 auto', fontSize: '14px' }}>
                              <Grid
                                data={ivmGamePrac}
                                columns={pracColumn}
                                rowHeight={25}
                                bodyHeight={520}
                                heightResizable={false}
                                rowHeaders={['rowNum']}
                                pageOptions={pageOption}
                                columnOptions={{ resizable: true }}
                              />
                            </div>
                          </Styled.InnerContainer>
                        }
                      />
                    </div>
                    {/*<Grid*/}
                    {/*  data={ticketHistory}*/}
                    {/*  columns={columns}*/}
                    {/*  rowHeight={24}*/}
                    {/*  bodyHeight={320}*/}
                    {/*  heightResizable={false}*/}
                    {/*  rowHeaders={['rowNum']}*/}
                    {/*  pageOptions={pageOption}*/}
                    {/*  columnOptions={{ resizable: true }}*/}
                    {/*/>*/}

                    <div className="trainingManagementBoxContainer">
                      <GOLayoutBox
                        height={600}
                        width={`100%`}
                        body={
                          <Styled.InnerContainer>
                            <Tabs selectedIndex={gameTabIndex} onSelect={onSelectGameTab}>
                              <TabList>
                                <Tab>
                                  <span>
                                    <strong>골프력</strong>
                                  </span>
                                </Tab>
                                <Tab>
                                  <span>
                                    <strong>골프력진단</strong>
                                  </span>
                                </Tab>
                                <Tab>
                                  <span>
                                    <strong>스트로크플레이</strong>
                                  </span>
                                </Tab>
                              </TabList>
                              {/*<div>now is {gameTabIndex}</div>*/}
                              <TabPanel style={{ height: '100%' }}>
                                <div>
                                  <div
                                    style={{
                                      width: '100%',
                                      margin: '0 auto',
                                      fontSize: '14px',
                                    }}
                                  >
                                    <Grid
                                      data={ivmGameCapaGame}
                                      columns={capaGameColumn}
                                      rowHeight={24}
                                      bodyHeight={520}
                                      heightResizable={false}
                                      rowHeaders={['rowNum']}
                                      pageOptions={pageOption}
                                      columnOptions={{ resizable: true }}
                                    />
                                  </div>
                                </div>
                              </TabPanel>
                              <TabPanel style={{ height: '100%' }}>
                                <div>
                                  <div
                                    style={{
                                      width: '100%',
                                      margin: '0 auto',
                                      fontSize: '14px',
                                    }}
                                  >
                                    <Grid
                                      data={ivmGameCapaEx}
                                      columns={capaExColumn}
                                      rowHeight={24}
                                      bodyHeight={520}
                                      heightResizable={false}
                                      rowHeaders={['rowNum']}
                                      pageOptions={pageOption}
                                      columnOptions={{ resizable: true }}
                                    />
                                  </div>
                                </div>
                              </TabPanel>
                              <TabPanel style={{ height: '100%' }}>
                                <div>
                                  <div
                                    style={{
                                      width: '100%',
                                      margin: '0 auto',
                                      fontSize: '14px',
                                    }}
                                  >
                                    <Grid
                                      data={ivmGameRound}
                                      columns={roundColumn}
                                      rowHeight={24}
                                      bodyHeight={520}
                                      heightResizable={false}
                                      rowHeaders={['rowNum']}
                                      pageOptions={pageOption}
                                      columnOptions={{ resizable: true }}
                                    />
                                  </div>
                                </div>
                              </TabPanel>
                            </Tabs>
                          </Styled.InnerContainer>
                        }
                      />
                    </div>
                    <div className="trainingManagementBoxContainer">
                      <GOLayoutBox
                        height={600}
                        width={`100%`}
                        body={
                          <Styled.InnerContainer>
                            <Tabs selectedIndex={trainingTabIndex} onSelect={onSelectTrainingTab}>
                              <TabList>
                                <Tab>
                                  <span>
                                    <strong>퍼팅</strong>
                                  </span>
                                </Tab>
                                <Tab>
                                  <span>
                                    <strong>어프로치</strong>
                                  </span>
                                </Tab>
                                <Tab>
                                  <span>
                                    <strong>러닝 어프로치</strong>
                                  </span>
                                </Tab>
                                <Tab>
                                  <span>
                                    <strong>드라이버티샷</strong>
                                  </span>
                                </Tab>
                                <Tab>
                                  <span>
                                    <strong>아이언티샷</strong>
                                  </span>
                                </Tab>
                                <Tab>
                                  <span>
                                    <strong>장타</strong>
                                  </span>
                                </Tab>
                              </TabList>
                              {/*<div>now is {gameTabIndex}</div>*/}
                              <TabPanel style={{ height: '100%' }}>
                                <div>
                                  <div
                                    style={{
                                      width: '100%',
                                      margin: '0 auto',
                                      fontSize: '14px',
                                    }}
                                  >
                                    <Grid
                                      data={ivmGamePutt}
                                      columns={puttColumn}
                                      rowHeight={24}
                                      bodyHeight={520}
                                      heightResizable={false}
                                      rowHeaders={['rowNum']}
                                      pageOptions={pageOption}
                                      columnOptions={{ resizable: true }}
                                    />
                                  </div>
                                </div>
                              </TabPanel>
                              <TabPanel style={{ height: '100%' }}>
                                <div>
                                  <div
                                    style={{
                                      width: '100%',
                                      margin: '0 auto',
                                      fontSize: '14px',
                                    }}
                                  >
                                    <Grid
                                      data={ivmGameAps}
                                      columns={apsColumn}
                                      rowHeight={24}
                                      bodyHeight={520}
                                      heightResizable={false}
                                      rowHeaders={['rowNum']}
                                      pageOptions={pageOption}
                                      columnOptions={{ resizable: true }}
                                    />
                                  </div>
                                </div>
                              </TabPanel>
                              <TabPanel style={{ height: '100%' }}>
                                <div>
                                  <div
                                    style={{
                                      width: '100%',
                                      margin: '0 auto',
                                      fontSize: '14px',
                                    }}
                                  >
                                    <Grid
                                      data={ivmGameRunAps}
                                      columns={runApsColumn}
                                      rowHeight={24}
                                      bodyHeight={520}
                                      heightResizable={false}
                                      rowHeaders={['rowNum']}
                                      pageOptions={pageOption}
                                      columnOptions={{ resizable: true }}
                                    />
                                  </div>
                                </div>
                              </TabPanel>
                              <TabPanel style={{ height: '100%' }}>
                                <div>
                                  <div
                                    style={{
                                      width: '100%',
                                      margin: '0 auto',
                                      fontSize: '14px',
                                    }}
                                  >
                                    <Grid
                                      data={ivmGameFdr}
                                      columns={fdrColumn}
                                      rowHeight={24}
                                      bodyHeight={520}
                                      heightResizable={false}
                                      rowHeaders={['rowNum']}
                                      pageOptions={pageOption}
                                      columnOptions={{ resizable: true }}
                                    />
                                  </div>
                                </div>
                              </TabPanel>
                              <TabPanel style={{ height: '100%' }}>
                                <div>
                                  <div
                                    style={{
                                      width: '100%',
                                      margin: '0 auto',
                                      fontSize: '14px',
                                    }}
                                  >
                                    <Grid
                                      data={ivmGameIron}
                                      columns={ironColumn}
                                      rowHeight={24}
                                      bodyHeight={520}
                                      heightResizable={false}
                                      rowHeaders={['rowNum']}
                                      pageOptions={pageOption}
                                      columnOptions={{ resizable: true }}
                                    />
                                  </div>
                                </div>
                              </TabPanel>
                              <TabPanel style={{ height: '100%' }}>
                                <div>
                                  <div
                                    style={{
                                      width: '100%',
                                      margin: '0 auto',
                                      fontSize: '14px',
                                    }}
                                  >
                                    <Grid
                                      data={ivmGameDr}
                                      columns={drColumn}
                                      rowHeight={24}
                                      bodyHeight={520}
                                      heightResizable={false}
                                      rowHeaders={['rowNum']}
                                      pageOptions={pageOption}
                                      columnOptions={{ resizable: true }}
                                    />
                                  </div>
                                </div>
                              </TabPanel>
                            </Tabs>
                          </Styled.InnerContainer>
                        }
                      />
                    </div>
                  </Styled.BoxWrapper>
                </>
              )}
            </>
          )
        }
      />
    </>
  );
};

export default Detail;
