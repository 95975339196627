import React, { useState, FormEvent } from 'react';
import * as Styled from './styles';
import { HeaderSearch } from '../..';
import { useHistory } from 'react-router-dom';

interface HeaderBottomProps {
  status: JSX.Element;
  rightmenu?: JSX.Element;
}

const HeaderBottom = (props: HeaderBottomProps) => {
  const history = useHistory();

  const [search, setSearch] = useState({ body: '' });
  const handleSubmit = (e: FormEvent<EventTarget>) => {
    if (search.body) {
      e.preventDefault();
      setSearch({ ...search });
      history.push(`/admin/user/search/${search.body}`);
    }
  };

  return (
    <Styled.Container>
      <div className="wrap">
        <Styled.HeaderStatus>{props.status}</Styled.HeaderStatus>
        <Styled.HeaderGroup>
          <Styled.HeaderSearchBar>
            <HeaderSearch
              placeholder="회원 검색"
              value={search.body}
              onChange={(e: any) => {
                setSearch({ ...search, body: e.target.value });
              }}
              handleSubmit={handleSubmit}
            />
          </Styled.HeaderSearchBar>
          <Styled.HeaderRightMenu className="right_menu">{props.rightmenu}</Styled.HeaderRightMenu>
        </Styled.HeaderGroup>
      </div>
    </Styled.Container>
  );
};

export default HeaderBottom;
