import React from 'react';
import * as Styled from './styles';
import { Link } from 'react-router-dom';
import { phoneNumberFormatting } from '../../../middlewares';

interface MemberPanelProps {
  id?: any;
  value?: string | undefined;
  link?: any;
  data?: any;
}

const MemberPanel = (props: MemberPanelProps) => {
  const birthday = (birth: any) => {
    const today = new Date().getFullYear();
    const myBirth = new Date(birth).getFullYear();
    return today - myBirth;
  };

  return (
    <Styled.Panel>
      <Styled.EmployeePanel>
        <Link to={props.link}>
          <Styled.PanelTop>
            <Styled.PanelInfo>
              <Styled.PanelName>{props.data.name}</Styled.PanelName>
              <Styled.PanelDetail>
                {birthday(props.data.dateOfBirth)}세 / {props.data.sex === 0 ? '남' : '여'} /{' '}
                {phoneNumberFormatting(props.data.phoneNumber)}
              </Styled.PanelDetail>
            </Styled.PanelInfo>
          </Styled.PanelTop>
          <hr />
          <Styled.PanelBottom>
            <Styled.PanelBottomBox>
              <Styled.PanelBottomBoxLeft>직무</Styled.PanelBottomBoxLeft>
              <div className="MemberPanel_bottom_box_right">
                {props.data.type === 0
                  ? '관리자'
                  : '매니져' && props.data.type === 2
                  ? '레슨프로'
                  : '매니져'}
              </div>
            </Styled.PanelBottomBox>
          </Styled.PanelBottom>
        </Link>
      </Styled.EmployeePanel>
    </Styled.Panel>
  );
};

export default MemberPanel;
