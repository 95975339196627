import NetworkManager from "../../../NetworkManager";

const Add = (data: any) => {
  return new Promise((resolve, reject) => {
    NetworkManager("post", "admin/employee", data)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default Add;
