export default function YearlyTickets(data: any) {
  let plate: number[] = [];
  let lesson: number[] = [];
  let etc: number[] = [];

  if (data) {
    // category type 별로 filter
    let plateArr: any = data.filter((d: any) => d.productCateType === 2);
    let lessonArr: any = data.filter(
      (d: any) => d.productCateType === 0 || d.productCateType === 1,
    );
    let etcArr: any = data.filter(
      (d: any) => d.productCateType !== 0 && d.productCateType !== 1 && d.productCateType !== 2,
    );

    // 데이터 있는 달 array 생성
    let pArr: number[] = [];
    let lArr: number[] = [];
    let eArr: number[] = [];

    for (let i = 0; i < plateArr.length; i++) {
      pArr.push(new Date(plateArr[i].cMonths).getMonth() + 1);
    }
    const plateSet: any = new Set(pArr);
    const uniquePlateArr = [...plateSet];

    for (let i = 0; i < lessonArr.length; i++) {
      lArr.push(new Date(lessonArr[i].cMonths).getMonth() + 1);
    }
    const lessonSet: any = new Set(lArr);
    const uniqueLessonArr = [...lessonSet];

    for (let i = 0; i < etcArr.length; i++) {
      eArr.push(new Date(etcArr[i].cMonths).getMonth() + 1);
    }
    const etcSet: any = new Set(eArr);
    const uniqueEtcArr: any = [...etcSet];

    // 같은 달 찾아 amount 합쳐주기
    const filterPlate = plateArr.reduce((acc: any, cur: any) => {
      const dateData = cur['cMonths'];
      const amount = Number(cur['paymentAmount'] - cur['refundAmount']);
      acc[dateData] = acc[dateData] ? acc[dateData] + amount : amount;
      return acc;
    }, {});

    const filterLesson = lessonArr.reduce((acc: any, cur: any) => {
      const dateData = cur['cMonths'];
      const amount = Number(cur['paymentAmount'] - cur['refundAmount']);
      acc[dateData] = acc[dateData] ? acc[dateData] + amount : amount;
      return acc;
    }, {});

    const filterEtc = etcArr.reduce((acc: any, cur: any) => {
      const dateData = cur['cMonths'];
      const amount = Number(cur['paymentAmount'] - cur['refundAmount']);
      acc[dateData] = acc[dateData] ? acc[dateData] + amount : amount;
      return acc;
    }, {});

    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    let resultPlate: any = [];
    let resultLesson: any = [];
    let resultEtc: any = [];
    let pIndex = 0;
    let lIndex = 0;
    let eIndex = 0;

    months.forEach((m: any) => {
      // plate array
      if (!uniquePlateArr.includes(m)) {
        resultPlate.push(0);
      } else {
        resultPlate.push(Object.values(filterPlate)[pIndex]);
        pIndex++;
      }

      // lesson array
      if (!uniqueLessonArr.includes(m)) {
        resultLesson.push(0);
      } else {
        resultLesson.push(Object.values(filterLesson)[lIndex]);
        lIndex++;
      }

      // etc array
      if (!uniqueEtcArr.includes(m)) {
        resultEtc.push(0);
      } else {
        resultEtc.push(Object.values(filterEtc)[eIndex]);
        eIndex++;
      }
    });

    plate = [...resultPlate];
    lesson = [...resultLesson];
    etc = [...resultEtc];
  }
  return { plate, lesson, etc };
}
