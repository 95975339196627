import React from 'react';
import * as Styled from './styles';
import GOButton from '../../../common/GOButton';
import lockerTypes from '../Types/lockerType';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { onModal } from '../../../../stores/modals';

interface panelProps {
  data: lockerTypes;
  lockerData?: any;
}

const Panel = ({ data, lockerData }: panelProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let diffDays = dayjs(`${data.endDate}`)
    .endOf('day')
    .diff(dayjs(), 'day', true);

  return (
    <Styled.LockerPanel
      style={{
        background: data.User
          ? '#12b886' && diffDays <= 3 && diffDays > 0
            ? '#f9b353'
            : '#12b886' && diffDays <= 0
            ? '#fa5252'
            : '#12b886'
          : '#fff',
        color: data.User ? '#fff' : '',
      }}
    >
      {data.User ? (
        <>
          <Styled.Number>{data.number > 9 ? data.number : '0' + data.number} </Styled.Number>
          <h6>{data.User.name}</h6>
          <hr />
          <h6>
            {dayjs(`${data.startDate}`).format('MM. DD')} ~{' '}
            {dayjs(`${data.endDate}`).format('MM. DD')}
          </h6>
          <h6>
            {diffDays <= 0
              ? `∙ 만료됨`
              : `∙ 만료 ${Math.floor(diffDays)}일 전` && diffDays > 0 && diffDays < 1
              ? `∙ ${dayjs(`${data.endDate}`)
                  .endOf('day')
                  .diff(dayjs(), 'hour')}시간 후 만료`
              : diffDays > 3
              ? `∙ 사용중`
              : `∙ ${dayjs(`${data.endDate}`)
                  .endOf('day')
                  .diff(dayjs(), 'day')}일 후 만료`}
          </h6>
          <hr />
          <GOButton
            noMarginLeft
            body={t('locker.lockerEdit')}
            onClick={() => dispatch(onModal('lockerEdit', { data, lockerData }))}
          />
        </>
      ) : (
        <>
          <Styled.Number>{data.number > 9 ? data.number : '0' + data.number} </Styled.Number>
          <h6>-</h6>
          <hr />
          <h6>-</h6>
          <h6>-</h6>
          <hr />
          <GOButton
            noMarginLeft
            buttontype="primary"
            body={t('locker.lockerAdd')}
            onClick={() => dispatch(onModal('lockerEdit', { data }))}
          />
        </>
      )}
    </Styled.LockerPanel>
  );
};
export default Panel;
