import React from 'react';
import * as Styled from './styles';

interface ListStatusBarProps {
  leftbody?: string | JSX.Element;
  rightbody?: string | number;
}

const ListStatusBar = (props: ListStatusBarProps) => {
  return (
    <Styled.ListStatusbar>
      <Styled.ListStatusbarLeft>{props.leftbody}</Styled.ListStatusbarLeft>
      <Styled.ListStatusbarRight>{props.rightbody}</Styled.ListStatusbarRight>
    </Styled.ListStatusbar>
  );
};

export default ListStatusBar;
