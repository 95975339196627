import styled from 'styled-components';
import * as color from '../../../color/index';

export const PageStyles = styled.div`
  .box_title {
    width: 100px;
    color: #5e6367;
    font-size: 14px;
    font-weight: bold;
    margin-right: 19px;
  }

  .dropbox_wrapper {
    flex: 1 1 auto;
    > div {
      margin-bottom: 0;
    }
  }

  .input_wrapper {
    width: 100px;
    flex: 1 1 auto;
    > div {
      margin-bottom: 0;
    }

    input {
      max-width: initial;
      width: 100%;
    }
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .box_title {
    width: 100px;
    color: #5e6367;
    font-size: 14px;
    font-weight: bold;
    margin-right: 19px;
  }
  .dropbox_wrapper {
    flex: 1 1 auto;

    > div {
      margin-bottom: 0;
    }
  }

  .input_wrapper {
    width: 100px;
    flex: 1 1 auto;
    display: flex;
    > div {
      margin-bottom: 0;
    }

    input {
      max-width: initial;
    }
  }
  .GOInput {
    margin-top: 0;
  }
`;

export const Input = styled.input`
  width: 159px;
  height: 36px;
  border: 2px solid ${color.Colors.line};
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  padding-left: 11px;
  flex: 1 1 auto;

  &::placeholder {
    color: ${color.Colors.line};
  }
`;

export const ChangeLocker = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: ${color.Colors.primary};

  .arrow {
    line-height: 40px;
    margin: 0 30px;
  }

  .title {
    margin-bottom: 6px;
  }

  select {
    border: 2px solid ${color.Colors.line};
    border-radius: 5px;
    font-weight: bold;
    color: ${color.Colors.primary};
    margin-top: 3px;
    padding-left: 3px;
    width: 75px;
    height: 25px;
    transition: 0.35s;

    &:focus {
      border: 2px solid ${color.Colors.blue};
      box-shadow: 0 3px 6px rgba(101, 123, 242, 0.2);
      transition: 0.35s;
    }
  }
`;
