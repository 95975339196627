import React, { useEffect, useState } from 'react';
import {
  HeaderBottom,
  StatusTool,
  ReservationView,
  SegmentControl,
  GOLoading,
  ReservationShowTime,
  ReservationDelete,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { NetReservationList, NetReservationLoadPlate } from '../../../actions';
import dayjs from 'dayjs';

const GroupList = () => {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState({
    startDate: dayjs()
      .day(0)
      .format('YYYY-MM-DD'),
    endDate: dayjs()
      .day(7)
      .format('YYYY-MM-DD'),
  });
  const [createdSchedule, setCreatedSchedule] = useState<any>();
  const [viewReservationDel, setViewReservationDel] = useState<boolean>(false);
  const [update, isUpdate] = useState(false);

  //데이터 가져오기
  useEffect(() => {
    NetReservationLoadPlate().then((res: any) => {
      if (res.data.Data.Plates) {
        window.sessionStorage.setItem('allPlates', res.data.Data.Plates.length);
      } else {
        window.sessionStorage.setItem('allPlates', '0');
      }
      setCreatedSchedule(res.data.Data.PlateAvailabilities);
    });

    NetReservationList(
      `${date.startDate}T00:00:00`,
      `${dayjs(date.endDate)
        .subtract(1, 'm')
        .format('YYYY-MM-DD')}T23:59:59`,
    ).then((res: any) => {
      setData(res.data.Data.PlateAvailabilities);
      setLoading(false);
    });
  }, [date, update]);

  return (
    <div>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool type="prev" body={t('main-menu.reservation_management')} />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={t('reservation.group')} />
          </>
        }
      />
      <SegmentControl
        data={[
          {
            name: t('reservation.group'),
            link: '/admin/reservation/list/group',
          },
          {
            name: t('reservation.personal'),
            link: '/admin/reservation/list/personal',
          },
          {
            name: '타석예약자',
            link: '/admin/reservation/list/plateReservationStatus',
          },
          {
            name: '개인수업예약자',
            link: '/admin/reservation/list/lessonReservationStatus',
          },
        ]}
      />
      <ReservationShowTime
        data={data}
        date={date}
        schedule={createdSchedule}
        setViewReservationDel={setViewReservationDel}
        goLeft={() => {
          setDate({
            startDate: dayjs(date.startDate)
              .subtract(7, 'd')
              .format('YYYY-MM-DD'),
            endDate: dayjs(date.endDate)
              .subtract(7, 'd')
              .format('YYYY-MM-DD'),
          });
          setLoading(true);
        }}
        goRight={() => {
          setDate({
            startDate: dayjs(date.startDate)
              .add(7, 'd')
              .format('YYYY-MM-DD'),
            endDate: dayjs(date.endDate)
              .add(7, 'd')
              .format('YYYY-MM-DD'),
          });
          setLoading(true);
        }}
      />
      {loading ? (
        <GOLoading />
      ) : (
        <ReservationView
          sDate={date.startDate}
          eDate={date.endDate}
          data={data}
          update={() => {
            isUpdate(!update);
          }}
        />
      )}
      {viewReservationDel && (
        <ReservationDelete
          onClickExit={() => setViewReservationDel(false)}
          update={() => {
            isUpdate(!update);
          }}
          now
        />
      )}
    </div>
  );
};

export default GroupList;
