import createActions from '../controller/createActions';

const { read, onlyRequest } = createActions('dashboard');

export const actionGetNotification = () =>
  read({
    type: 'get',
    url: `dashboard/notifications`,
  });

export const actionPutNotification = (nID: number, onSuccess: Function) =>
  onlyRequest({
    type: 'put',
    url: `dashboard/notification/${nID}`,
    meta: { read: 'dashboard/notifications', onSuccess },
  });

export const actionClearAllNotification = (onSuccess: Function) =>
  onlyRequest({
    type: 'put',
    url: `dashboard/notification/read/all`,
    meta: { read: 'dashboard/notifications', onSuccess },
  });
