import { isInteger } from 'lodash';

const TimeToMinutesConvert = (minutes: any) => {
  let time;

  const Year = new Date().getFullYear();
  const Month =
    new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1;
  const Day = new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate();

  let hour = minutes / 60;
  let minute = 0;

  if (isInteger(hour)) {
    time = new Date(`${Year}-${Month}-${Day}T${hour < 10 ? `0${hour}` : hour}:0${minute}`);
  } else {
    let sHour = hour - (hour - Math.floor(hour));
    let sMinute = (hour - Math.floor(hour)) * 60;

    time = new Date(
      `${Year}-${Month}-${Day}T${sHour < 10 ? `0${sHour}` : sHour}:${
        sMinute < 10 ? `0${sMinute}` : sMinute
      }`,
    );
  }

  return time;
};

export const  TimeToMinutesConvertVer1 = (minutes: any) => {
  const now = new Date();
  const midnight = now.setHours(0,0,0,0); //set to midnight
  const time =  new Date( now.getTime() + minutes * 60000);
  return time;
};

export default TimeToMinutesConvert;
