import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { phoneNumberFormatting } from '../../../middlewares';

import { GOEmoji } from '../../index';

interface MemberPanelProps {
  id?: any;
  name?: string | undefined;
  value?: string | undefined;
  checked?: boolean | undefined | any;
  onChange?: (e: any) => void | undefined;
  link?: any;
  sex?: number | string;
  tel?: number | string;
  birth?: number | string;
  tickets: any[];
  img?: string;
  ticketFilterType?: number; // 8 타석 만료 15일전, 9레슨 만료 15일전
  isVaccinated?: number; //0 not show 1: vaccinated
  lastExpiredDate?: string | null;
}

const MemberPanel = (props: MemberPanelProps) => {
  dayjs.extend(relativeTime);
  dayjs.locale('ko');

  const birthday = (birth: any) => {
    const today = new Date().getFullYear();
    const myBirth = new Date(birth).getFullYear();
    return today - myBirth;
  };

  const arrayForSort = [...props.tickets];
  //Don't try to sort array itself

  const lastTicket =
    props.tickets &&
    arrayForSort.sort((a: any, b: any) => {
      try {
        if (a.endDate === undefined || b.endDate === undefined) {
          return 1;
        }

        if (a.type !== 0 && a.type !== 2 && (b.type === 0 || b.type === 2)) {
          return 1;
        }

        if (b.type !== 0 && b.type !== 2 && (a.type === 0 || a.type === 2)) {
          return -1;
        }

        if (b.type !== 0 && b.type !== 2) {
          return 1;
        }

        if (props.ticketFilterType === 8) {
          //타석
          if (a.type === 2 && b.type === 0) {
            return -1;
          }
          if (a.type === 0 && b.type === 2) {
            return 1;
          }
        }

        if (props.ticketFilterType === 9) {
          //레슨
          if (a.type === 2 && b.type === 0) {
            return 1;
          }
          if (a.type === 0 && b.type === 2) {
            return -1;
          }
        }

        if (a.endDate > b.endDate) {
          return -1;
        } else if (a.endDate === b.endDate) {
          return 0;
        } else {
          return 1;
        }
      } catch (e) {
        return -1;
      }
    });

  //const lastTicket = props.tickets[0];
  let now = 0;
  //DONETODO 만료 시차 오류
  if (props.tickets && lastTicket[0]) {
    const diff = Math.round(
      dayjs()
        .startOf('day')
        .diff(dayjs(dayjs(lastTicket[0].endDate).format('YYYY-MM-DD')).startOf('day')) / 86400000,
    );
    if (diff <= 0) {
      if (diff >= -7) {
        // 7일 전
        now = 7;
        if (diff >= -3) {
          // 3일 전
          now = 3;
        }
      } else {
        // 그외
        now = 1;
      }
    } else {
      // 만료
      now = -1;
    }
  }

  const commentFromDate = (endDate: any) => {
    try {
      // dayjs(dayjs(lastTicket[0].endDate).format('YYYY-MM-DD')).endOf('day')
      const diff = Math.round(
        dayjs(dayjs(lastTicket[0].endDate).format('YYYY-MM-DD'))
          .startOf('day')
          .diff(dayjs().startOf('day')) / 86400000,
      );
      if (diff < 1) {
        const hour = Math.floor(diff * 1000 * 60);
        return `오늘 만료`;
      } else {
        return `만료 ${Math.floor(diff)} 일전`;
      }
    } catch (e) {}
    return dayjs(lastTicket[0].endDate).toNow();
  };

  //DONETODO 만료일 시차 오류
  const expireDate = () => {
    switch (now) {
      case 0:
        return !!props.lastExpiredDate
          ? `${dayjs(props.lastExpiredDate).format('YYYY-MM-DD')}`
          : '-';
      case 1:
        // return `${dayjs(lastTicket[0].endDate).format('YYYY-MM-DD')} (만료 ${
        //   dayjs(
        //   lastTicket[0].endDate,
        // ).toNow()})`;
        return `${dayjs(lastTicket[0].endDate).format('YYYY-MM-DD')} (${commentFromDate(
          lastTicket[0].endDate,
        )})`;
      case -1:
        return `${dayjs(lastTicket[0].endDate).format('YYYY-MM-DD')} (만료)`;
      default:
        return `${dayjs(lastTicket[0].endDate).format('YYYY-MM-DD')} (${
          commentFromDate(lastTicket[0].endDate)
          //   dayjs(
          //   lastTicket[0].endDate,
          // ).toNow()
        })`;
    }
  };

  return (
    <div className={`MemberPanel ${props.checked ? `MemberPanel_checked` : ''} `}>
      <Link to={props.link}>
        <div className={`MemberPanel_top`}>
          {now > 1 && props.tickets && (
            // <div className={`MemberPanel_alert ${now === 3 && `MemberPanel_alert_red`}`}>
            //   만료 {dayjs(lastTicket[0].endDate).toNow()}
            // </div>
            <div className={`MemberPanel_alert ${now === 3 && `MemberPanel_alert_red`}`}>
              {commentFromDate(lastTicket[0].endDate)}
            </div>
          )}
          <div
            className="MemberPanel_circle"
            style={{
              background: props.img ? `url(${props.img}) center / cover` : '#eee',
            }}
          />
          <div className="MemberPanel_info">
            <div className="MemberPanel_name">
              {props.name} {props.isVaccinated === 1 ? <GOEmoji symbol="✅" /> : <GOEmoji />}
            </div>
            <div className="MemberPanel_detail">
              {birthday(props.birth)}세 / {props.sex === 0 ? '남' : '여'} /{' '}
              {phoneNumberFormatting(`${props.tel}`)}
            </div>
          </div>
        </div>
        <hr />
        <div className="MemberPanel_bottom">
          <div className="MemberPanel_bottom_box">
            <div className="MemberPanel_bottom_box_left">최근결제</div>
            <div className="MemberPanel_bottom_box_right">
              {props.tickets && lastTicket[0] ? lastTicket[0].name.substring(0, 22) : '-'}
            </div>
          </div>
          <div className="MemberPanel_bottom_box">
            <div className="MemberPanel_bottom_box_left">만료일</div>
            <div className={`MemberPanel_bottom_box_right`}>{props.tickets && expireDate()}</div>
          </div>
        </div>
      </Link>
      <input
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        type="checkbox"
        className="MemberPanel_checkbox"
        checked={props.checked}
      />
    </div>
  );
};

export default MemberPanel;
