import React from 'react';
import * as Styled from './styles';
import dayjs from 'dayjs';
import { GOButton } from '../../../components';
import {
  NetReservationResCheckIn,
  NetReservationResAbsent,
  NetReservationResCancel,
  NetLessonResCheckIn,
  NetLessonResAbsent,
  NetLessonResCancel,
} from '../../../actions';

interface addProps {
  data?: any;
  update?: () => void;
}

const UnCheckedPanel = (props: addProps) => {
  const onResCheckIn = (rId: any, rType: any) => {
    if (rType === 0) {
      if (window.confirm('출석 처리하시겠습니까?')) {
        NetReservationResCheckIn(rId).then(res => {
          if (props.update) {
            props.update();
          }
        });
      }
    } else {
      if (window.confirm('출석 처리하시겠습니까?')) {
        NetLessonResCheckIn(rId).then(res => {
          if (props.update) {
            props.update();
          }
        });
      }
    }
  };

  const onResAbsent = (rId: any, rType: any) => {
    if (rType === 0) {
      if (window.confirm('결석 처리하시겠습니까?')) {
        NetReservationResAbsent(rId).then(res => {
          if (props.update) {
            props.update();
          }
        });
      }
    } else {
      if (window.confirm('결석 처리하시겠습니까?')) {
        NetLessonResAbsent(rId).then(res => {
          if (props.update) {
            props.update();
          }
        });
      }
    }
  };

  const onResCancel = (rId: any, rType: any) => {
    if (rType === 0) {
      if (window.confirm('예약을 취소하시겠습니까?')) {
        NetReservationResCancel(rId).then(res => {
          if (props.update) {
            props.update();
          }
        });
      }
    } else {
      if (window.confirm('예약을 취소하시겠습니까?')) {
        NetLessonResCancel(rId).then(res => {
          if (props.update) {
            props.update();
          }
        });
      }
    }
  };
  return (
    <>
      {props.data.map((d: any, index: number) => (
        <React.Fragment key={index}>
          <Styled.Panel>
            <Styled.LeftInfo>
              <div className="userInfo">
                <div className="year">{dayjs(d.startDate).format('YYYY-MM-DD')}</div>
                <Styled.TicketItem>
                  {/*<div className="name">{d.type === 0 ? '타석' : '개인레슨'}</div>*/}
                  <div>{d.type === 0 ? '타석' : '개인레슨'}</div>
                  <div>
                    {dayjs(d.startDate).format('HH:mm')} ~ {dayjs(d.endDate).format('HH:mm')}
                  </div>
                  <div>
                    {d.ticketName === undefined || d.ticketName === null ? '' : d.ticketName}
                  </div>
                </Styled.TicketItem>
              </div>
              <Styled.ButtonWrapper>
                <GOButton
                  body="출석"
                  height={35}
                  fontSize={12}
                  noMarginLeft
                  onClick={() => {
                    onResCheckIn(d.reservationID, d.type);
                  }}
                />
                <GOButton
                  body="결석"
                  height={35}
                  fontSize={12}
                  onClick={() => {
                    onResAbsent(d.reservationID, d.type);
                  }}
                />
                <GOButton
                  body="예약취소"
                  height={35}
                  fontSize={12}
                  buttontype="red"
                  onClick={() => {
                    onResCancel(d.reservationID, d.type);
                  }}
                />
              </Styled.ButtonWrapper>
            </Styled.LeftInfo>
          </Styled.Panel>
        </React.Fragment>
      ))}
    </>
  );
};

export default UnCheckedPanel;
