import React, { useEffect } from 'react';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as Styled from '../../chartAreaStyles';

interface chartProps {
  data: any;
  label?: string;
  width?: number;
}

Chart.plugins.unregister(ChartDataLabels);

const PieChart = ({ data, label, width }: chartProps) => {
  useEffect(() => {
    let chart: any = null;
    const datasets = data.datasets[0];
    if (chart) {
      chart.destroy();
      chart = null;
    }
    const config: any = {
      plugins: [ChartDataLabels],
      type: 'pie',
      data: {
        labels: data.labels,
        datasets: [
          {
            label: datasets.label,
            data: datasets.data,
            backgroundColor: datasets?.backgroundColor,
            borderColor: datasets?.borderColor,
            borderWidth: 1,
            datalabels: {
              color: '#fff',
              font: {
                size: 14,
                weight: 'bold',
              },
              formatter: (value: any, ctx: any) => {
                let sum = 0;
                let dataArr: any = ctx.chart.data.datasets[0].data;
                for (let i = 0; i < dataArr.length; i++) {
                  sum += dataArr[i];
                }
                let mathValue = Math.round((value * 100) / sum);
                let percentage = mathValue + '%';
                return mathValue > 0 ? percentage : null;
              },
            },
          },
        ],
      },
      options: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
          labels: {
            usePointStyle: true,
            padding: 10,
            boxWidth: 20,
          },
        },
        tooltips: {
          displayColors: true,
          callbacks: {
            mode: 'x',
            title: (item: any, data: any) => {
              let index = item[0].index;
              return data.labels[index];
            },
            label: (item: any, data: any) => {
              let index = item.index;
              return `${data.datasets[0].data[index]}명`;
            },
          },
        },
      },
    };
    const ctx: HTMLElement | any = document.getElementById('userCountSex');
    chart = new Chart(ctx, config);
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [data]);
  return (
    <Styled.ChartBox>
      {label && <Styled.Title>{label}</Styled.Title>}
      <Styled.Chart>
        <canvas id="userCountSex" width="400" height="400" />
      </Styled.Chart>
    </Styled.ChartBox>
  );
};

export default PieChart;
