export default function UserSex(data: any) {
  let male;
  let female;

  const filter = {
    male: data?.filter((d: any) => d.sex === 0).map((s: any) => s.cnt),
    female: data?.filter((d: any) => d.sex === 1).map((s: any) => s.cnt),
  };

  if (filter.male && filter.female) {
    male = filter.male[0];
    female = filter.female[0];
  }

  return { male, female };
}
