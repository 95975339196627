import React, { useState, useEffect, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductTypeChanger } from '../../../actions';
import { GOFloatingbox, GOLabel, GODropbox, GOInput, GOButton } from '../../../components';
import { useDispatch } from 'react-redux';
import { actionPutProductCat, actionPostProductCat } from '../../../stores/actions';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

interface dataType {
  edit?: any;
  id: number | string;
  type: number;
  name: string;
  place: string;
  memo: string;
  plateCategoryData?: any;
}

const AddCategory = (props: { onClickExit: any; data: dataType }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<dataType>({
    id: 0,
    type: 0,
    name: '',
    place: '',
    memo: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.data.edit) {
      setData(props.data);
    }
  }, [props.data]);

  const handleChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: FormEvent<EventTarget>) => {
    e.preventDefault();
    if (props.data.edit) {
      // 수정
      if (window.confirm(t('product.isEditCategory'))) {
        dispatch(actionPutProductCat(data.id, data, () => props.onClickExit()));
      }
    } else {
      // 추가
      // if (props.data.plateCategoryData?.length >= 4) {
      // 임시로 추가
      if (props.data.plateCategoryData?.length >= 5) {
        alert('이용권 분류는 4개까지 생성 가능합니다.');
      } else {
        if (window.confirm(t('product.isAddCategory'))) {
          dispatch(actionPostProductCat(data, () => props.onClickExit()));
        }
      }
    }
  };

  return (
    <GOFloatingbox width={360}>
      <UFloatingContainer as="form" onSubmit={handleSubmit}>
        <UFloatingBoxHeader>
          <h1>{props.data.edit ? t('product.editCategory') : t('product.addCategory')}</h1>
          <p>{props.data.edit ? t('product.editCategoryInfo') : t('product.addCategoryInfo')}</p>
        </UFloatingBoxHeader>
        <UFloatingBoxBody>
          {props.data.edit ? (
            <GOLabel label="서비스 분류" body={ProductTypeChanger(data.type)} />
          ) : (
            <GODropbox
              label={t('product.serviceCategory')}
              data={[
                { value: 0, body: t('product.personalLesson') },
                { value: 1, body: t('product.groupLesson') },
                { value: 2, body: t('product.usePlate') },
                { value: 3, body: t('product.location') },
                { value: 4, body: t('product.locker') },
                { value: 5, body: t('product.etc') },
                { value: 6, body: t('제주캠퍼스') },
              ]}
              name="type"
              value={data.type}
              onChange={handleChange}
              required
            />
          )}
          <GOInput
            name="name"
            label={t('product.categoryName')}
            value={data.name}
            onChange={handleChange}
            required
          />
          <GOInput
            label={t('product.location')}
            name="place"
            value={data.place}
            onChange={handleChange}
            required
          />
          <hr />
          <GOInput
            label={t('product.detail')}
            name="memo"
            value={data.memo}
            onChange={handleChange}
            textarea
          />
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton buttontype="green" body={t('common.ok')} type="submit" />
          <GOButton body={t('common.no')} onClick={props.onClickExit} />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};
export default AddCategory;
