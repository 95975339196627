import React from 'react';
// import TuiGrid from 'tui-grid';
import Grid from '@toast-ui/react-grid';
import * as Styled from './styles';

interface totalBranchSummaryProps {
  saleSummary: any;
}

const TotalBranchSummary = ({ saleSummary }: totalBranchSummaryProps) => {
  const gridColumn: any = [
    {
      name: 'name',
      header: '훈련소',
      align: 'center',
      minWidth: 180,
      sortingType: 'desc',
      sortable: true,
      formatter: (o: any) => {
        return `${o.row.name.replace('행복골프 훈련소', '')}`;
      },
    },
    {
      name: 'sales',
      header: '총 이용권 구매 금액',
      align: 'center',
      minWidth: 150,
      sortable: true,
      formatter: (o: any) => `${Number(o.row.sales).toLocaleString()}`,
    },

    //   styles: {
    //   color: 'red',
    //     padding: '0',
    //     margin: '0',
    // },
    {
      name: 'refund',
      header: '총 환불 금액',
      align: 'center',
      minWidth: 150,
      sortable: true,
      renderer: {
        styles: {
          color: 'red',
          padding: '0',
          margin: '0',
        },
      },
      formatter: (o: any) => `${Number(o.row.refund).toLocaleString()}`,
    },
    {
      name: 'profit',
      header: '총 매출 금액',
      align: 'center',
      minWidth: 150,
      sortable: true,
      formatter: (o: any) => `${Number(o.row.profit).toLocaleString()}`,
    },
  ];
  const pageOption: any = {
    useClient: true,
    perPage: 100,
  };

  return (
    <Styled.GridContainer>
      <Grid
        data={saleSummary}
        columns={gridColumn}
        // rowHeight={20}
        bodyHeight={800}
        heightResizable={true}
        rowHeaders={['rowNum']}
        pageOptions={pageOption}
        columnOptions={{ resizable: true }}
        scrollX={true}
      />
    </Styled.GridContainer>
  );
};

export default TotalBranchSummary;
