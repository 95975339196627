import styled, { keyframes, css } from 'styled-components';
import * as color from '../../../color/index';
import { darken } from 'polished';

interface styleProps {
  absent?: boolean;
  checkin?: boolean;
  res?: boolean;

  total?: boolean;
  remain?: boolean;
}

interface iconProps {
  visible?: boolean;
}

export const golayout = keyframes`
  0% {
    opacity: 0;
    left: -20px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
`;

export const Box = styled.div`
  position: relative;
  padding: 16px;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  background: ${color.Colors.white};
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  animation: ${golayout} 0.35s;
`;

export const Table = styled.div`
  overflow: auto;
  white-space: nowrap;
  margin-top: 10px;
  table {
    min-width: 100%;
    text-align: center;
    border-collapse: collapse;
    min-height: 80px;
    border-bottom: 1px solid #ddd;
  }

  thead {
    font-weight: bold;
    tr {
      td {
        padding: 0px 5px 5px;
        border-bottom: 1px solid ${color.Colors.primary};
      }
    }
  }

  tbody {
    tr {
      .name {
        width: 200px;
      }
      td {
        padding: 5px;
        border-bottom: 1px solid ${color.Colors.line};
        border-right: 1px solid ${color.Colors.line};

        &:last-child {
          border-right: none;
        }
      }

      &:hover {
        background: ${color.Colors.background};
        transition: 0.35s;
      }
    }
  }
`;

export const ResDetail = styled.div<styleProps>`
  font-weight: bold;

  ${props => {
    if (props.checkin)
      return css`
        color: ${color.Colors.red};
      `;
    if (props.total)
      return css`
        text-decoration: underline;
        margin-bottom: 5px;
      `;
    if (props.remain)
      return css`
        color: ${color.Colors.green};
      `;
  }}
`;

export const Flexbox = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusText = styled.div<styleProps>`
  font-weight: bold;
  color: ${color.Colors.primary};

  ${props => {
    if (props.absent)
      return css`
        color: ${color.Colors.red};
      `;
    if (props.checkin)
      return css`
        color: ${color.Colors.green};
      `;
  }}
`;

export const ProfileImage = styled.div`
  width: 50px;
  height: 50px;
  margin-left: 25px;
  margin-right: 25px;
  background-color: ${color.Colors.background};
  border-radius: 120px;
`;

export const Icon = styled.i<iconProps>`
  position: fixed;
  right: 0;
  bottom: 0;
  padding-bottom: 20px;
  padding-right: 40px;
  font-size: 50px;
  color: ${color.Colors.green};
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s linear 0s, visibility;
  visibility: hidden;

  &:hover {
    color: ${darken(0.1, `${color.Colors.green}`)};
    transition: 0.35s;
  }

  @media (max-width: 1430px) {
    visibility: hidden;
  }
  ${props => {
    if (props.visible)
      return css`
        opacity: 1;
        transition: opacity 0.2s linear, 0s visibility;
        visibility: visible;
      `;
  }}
`;
