import React from 'react';
import { GOButton, GOFloatingbox, GOInput } from '../../../components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { actionPostPushMessage } from '../../../stores/actions';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

const Push = ({ data, onClickExit }: any) => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const { t } = useTranslation();

  const onSubmit = (v: any) => {
    if (window.confirm('푸시 메시지를 발송하시겠습니까?')) {
      dispatch(
        actionPostPushMessage({ userIDs: data, ...v }, () => {
          onClickExit();
        }),
      );
    }
  };

  return (
    <GOFloatingbox width={400}>
      <UFloatingContainer as="form" onSubmit={handleSubmit(onSubmit)}>
        <UFloatingBoxHeader>
          <h3>{t('user.push')}</h3>
        </UFloatingBoxHeader>
        <UFloatingBoxBody>
          <div className="flex_box">
            <GOInput
              label={t('common.title')}
              name="title"
              rref={register}
              maxLength={15}
              required
            />
            <GOInput label={t('common.body')} name="body" rref={register} textarea required />
          </div>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton buttontype="green" body={t('common.ok')} type="submit" />
          <GOButton body={t('common.no')} onClick={onClickExit} />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};

export default Push;
