import React, { useEffect } from 'react';
import { Header } from '../components';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import {
  Home,
  Dashboard,
  UserGridList,
  UserDetail,
  UserTableList,
  ProductList,
  ReservationGroupList,
  ReservationPersonalList,
  PlateReservationStatusList,
  LessonReservationStatusList,
  SignIn,
  UserSalesList,
  UserSales,
  EmployeeList,
  EmployeeDetail,
  EmployeeLessonList,
  EmployeeScheduleManage,
  EmployeeUserManage,
  PlateList,
  LockerList,
  PlateLoginList,
  CheckIn,
  UserSearch,
  Accounting,
  UserPaymentList,
  SmartAdvisor,
  Settings,
  TrainingManagement,
} from '../containers';
import { UserManager } from '../actions';

const DevDiv = ({ title }: any) => (
  <h4
    style={{
      position: 'fixed',
      bottom: 10,
      left: 10,
      padding: 8,
      background: 'rgba(0,0,0,0.6)',
      color: 'white',
      zIndex: 999,
    }}
  >
    {title}
  </h4>
);

const MainRouter = () => {
  const USER = UserManager().getUser();

  const location = useLocation();
  const history = useHistory();
  const SERVER = window.localStorage.getItem('SERVER');

  useEffect(() => {
    if (USER && !USER.isSignIn && location.pathname !== '/') {
      history.push('/');
    } else if (USER && USER.isSignIn && location.pathname === '/') {
      history.push('/admin');
    }
  }, [USER, history, location]);

  return (
    <>
      {SERVER === 'dev' ? (
        <DevDiv title={SERVER} />
      ) : SERVER === 'test' ? (
        <DevDiv title={SERVER} />
      ) : (
        <></>
      )}
      {USER && USER.isSignIn === true ? <Header /> : <Redirect push to="/" />}
      <CacheSwitch>
        {/* 로그인 */}
        <Route exact path="/">
          <SignIn />
        </Route>
        {/* 라우팅 */}
        <Route exact path="/admin">
          <Home />
        </Route>
        <CacheRoute exact path="/admin/dashboard" className="cache-route-container">
          <Dashboard />
        </CacheRoute>
        {/* 고객관리 */}
        <Route exact path="/admin/user/grid">
          <UserGridList />
        </Route>
        <CacheRoute exact path="/admin/user/table" className="cache-route-container">
          <UserTableList />
        </CacheRoute>
        <Route exact path="/admin/user/:id">
          <UserDetail />
        </Route>
        <Route exact path="/admin/user/search/:keyword">
          <UserSearch />
        </Route>
        <Route exact path="/admin/user/:id/sales">
          <UserSalesList />
        </Route>
        <Route exact path="/admin/user/:id/sales/:productID">
          <UserSales />
        </Route>
        <Route exact path="/admin/user/:id/payment">
          <UserPaymentList />
        </Route>
        {/* trainingManagement */}
        <Route exact path="/admin/user/:id/trainingManagement">
          <TrainingManagement />
        </Route>
        {/* 상품관리 */}
        <Route exact path="/admin/product/list">
          <ProductList />
        </Route>
        {/* 예약관리 */}
        <Route exact path="/admin/reservation/list/group">
          <ReservationGroupList />
        </Route>
        <Route exact path="/admin/reservation/list/personal">
          <ReservationPersonalList />
        </Route>
        <Route exact path="/admin/reservation/list/plateReservationStatus">
          <PlateReservationStatusList />
        </Route>
        <Route exact path="/admin/reservation/list/lessonReservationStatus">
          <LessonReservationStatusList />
        </Route>
        {/* 직원 관리 */}
        <Route exact path="/admin/employee/list">
          <EmployeeList />
        </Route>
        <Route exact path="/admin/employee/:id">
          <EmployeeDetail />
        </Route>
        <Route exact path="/admin/employee/:id/lessonLogs/">
          <EmployeeLessonList />
        </Route>
        <Route exact path="/admin/employee/:id/scheduleManaged/">
          <EmployeeScheduleManage />
        </Route>
        <Route exact path="/admin/employee/:id/userManaged">
          <EmployeeUserManage />
        </Route>
        {/* 타석/락커 관리 */}
        <Route exact path="/admin/plate/list">
          <PlateList />
        </Route>
        <Route exact path="/admin/plate/lockerlist">
          <LockerList />
        </Route>
        <Route exact path="/admin/plate/plateloginlist">
          <PlateLoginList />
        </Route>
        {/* 체크인 */}
        <Route exact path="/admin/checkin">
          <CheckIn />
        </Route>
        {/* 회계관리 */}
        <Route exact path="/admin/accounting">
          <Accounting />
        </Route>
        {/* 분석 */}
        <Route exact path="/admin/smartadvisor">
          <SmartAdvisor />
        </Route>
        {/* 설정 */}
        <Route exact path="/admin/settings">
          <Settings />
        </Route>
        {/* 라우팅 끝 */}
      </CacheSwitch>
    </>
  );
};

export default MainRouter;
