import React from 'react';
import * as Styled from './styles';

interface ScheduleManagePanelProps {
  selected: string;
  selectedItem: string | number;
  update: boolean;
  date: string;
  onClick: any;
  workInfo: any;
  restInfo: any;
}

const ScheduleManagePanel = (props: ScheduleManagePanelProps) => {
  return (
    <>
      <Styled.Panel
        style={{
          backgroundColor: props.selected === props.selectedItem ? '#495057' : '#fff',
          color: props.selected === props.selectedItem ? '#fff' : '#495057',
        }}
        onClick={props.onClick}
      >
        <Styled.PanelTop>
          <Styled.PanelDate
            style={{
              backgroundColor: props.selected === props.selectedItem ? '#495057' : '#fff',
              color: props.selected === props.selectedItem ? '#fff' : '#495057',
              transition: props.selected === props.selectedItem ? '0.35s' : '0.35s',
            }}
          >
            {props.date}
          </Styled.PanelDate>
          <Styled.PanelInfoBox>
            <Styled.PanelInfo>{props.workInfo}</Styled.PanelInfo>
            <Styled.PanelInfo>{props.restInfo}</Styled.PanelInfo>
          </Styled.PanelInfoBox>
          {props.selected === props.selectedItem && props.update ? (
            <>
              <Styled.IconBox>
                <img src="/images/white_arrow.png" alt="whiteArrow" />
              </Styled.IconBox>
            </>
          ) : (
            <>
              <Styled.IconBox>
                <img src="/images/primary_arrow.png" alt="primaryArrow" />
              </Styled.IconBox>
            </>
          )}
        </Styled.PanelTop>
      </Styled.Panel>
    </>
  );
};

export default ScheduleManagePanel;
