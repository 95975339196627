import NetworkManager from "../../../NetworkManager";
import { toast } from "react-toastify";

const SendSMS = (data: object) => {
  //0:user / 1:admin / 2:employee
  return new Promise((resolve, reject) => {
    NetworkManager("post", "admin/auth/sendSMS", data)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        if (err.response) {
          switch (err.response.status) {
            case 406:
              toast.error("⛔️ 이미 존재하는 번호입니다. ( 406 )");
              break;
            default:
              break;
          }
        }
      });
  });
};

export default SendSMS;
