import React, { useState, useEffect } from 'react';
import { GOFloatingbox, GOButton, SmallTitle, GOLabel } from '../../../components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NetLessonReportConfirm, NetLessonUserDetail } from '../../../actions';
import * as Styled from './styles';
import dayjs from 'dayjs';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

interface LessonDetailProps {
  onClickExit?: () => void;
  update?: () => void;
  data?: any;
}

const LessonDetail = (props: LessonDetailProps) => {
  const { handleSubmit } = useForm({});
  const { t } = useTranslation();

  const [lessonData, setLessonData] = useState<any>({});

  const onSubmit = () => {
    if (lessonData?.lessonLog?.isConfirmed === false) {
      if (lessonData?.lessonLog?.comment === null && lessonData?.lessonLog?.ratings === null) {
        const result = window.confirm('회원 코멘트가 작성되지 않았습니다. 승인 하시겠습니까?');

        if (result) {
          NetLessonReportConfirm(props.data.lessonLog.id).then((res: any) => {
            alert('레슨 포스트 확인이 완료되었습니다.');
            if (props.update) {
              props.update();
            }
            if (props.onClickExit) {
              props.onClickExit();
            }
          });
        }
      } else {
        NetLessonReportConfirm(props.data.lessonLog.id).then((res: any) => {
          alert('레슨 포스트 확인이 완료되었습니다.');
          if (props.update) {
            props.update();
          }
          if (props.onClickExit) {
            props.onClickExit();
          }
        });
      }
    }
  };

  useEffect(() => {
    NetLessonUserDetail(props.data.lessonLog.id).then((res: any) => {
      setLessonData(res.data.Data);
    });
  }, [props, props.update]);

  return (
    <GOFloatingbox width={400}>
      <UFloatingContainer as="form" onSubmit={handleSubmit(onSubmit)}>
        <UFloatingBoxHeader>
          <h1>{t('employee.lessonInfo')}</h1>
          <p>{t('employee.lessonInfoSub')}</p>
        </UFloatingBoxHeader>
        <UFloatingBoxBody>
          <Styled.LayoutBox>
            <SmallTitle body={t('user.baseInfo')} />
            <GOLabel label="프로 이름" body={lessonData?.lessonLog?.employee.name} />
            <GOLabel label="회원 이름" body={lessonData?.lessonLog?.user.name} />
            <GOLabel
              label="레슨 날짜"
              body={dayjs(lessonData?.lessonDate).format('YYYY.MM.DD HH:mm:ss')}
            />
            <GOLabel
              label="확인 날짜"
              body={
                lessonData?.lessonLog?.confirmedDate === null ? (
                  <div>-</div>
                ) : (
                  dayjs(lessonData?.lessonLog?.confirmedDate).format('YYYY.MM.DD HH:mm:ss')
                )
              }
            />
          </Styled.LayoutBox>
          <Styled.VerticalLine />
          <Styled.LayoutBox>
            <SmallTitle body={t('employee.proLessonInfo')} />
            <video controls width="290" height="190" src={lessonData?.lessonLog?.videoURL} />
            <div className="lessonProComment">담당프로 한줄평</div>
            <Styled.UserText>{lessonData?.lessonLog?.body}</Styled.UserText>
          </Styled.LayoutBox>
          <Styled.VerticalLine />
          <Styled.LayoutBox>
            <SmallTitle body={t('employee.userAfter')} />
            {lessonData?.lessonLog?.ratings === null ? (
              <GOLabel label="별점⭐️" body="-" />
            ) : (
              <GOLabel label="별점⭐️" body={lessonData?.lessonLog?.ratings} />
            )}
            {lessonData?.lessonLog?.comment && (
              <GOLabel label="코멘트" body={lessonData.lessonLog.comment} />
            )}
          </Styled.LayoutBox>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          {lessonData?.lessonLog?.isConfirmed === false ? (
            <>
              <GOButton body={t('employee.lessonConfirm')} buttontype="green" type="submit" />
              <GOButton body={t('common.no')} onClick={props.onClickExit} />
            </>
          ) : (
            <GOButton body={t('common.no')} onClick={props.onClickExit} />
          )}
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};

export default LessonDetail;
