import NetworkManager from '../../../NetworkManager';
import { toast } from 'react-toastify';

const Delete = (eID: any) => {
  return new Promise((resolve, reject) => {
    NetworkManager('put', `admin/employee/inactivate/${eID}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        if (err.response.data.resultCode) {
          toast.error(`⛔️ ${err.response.data.message}`);
        } else toast.error(`⛔️ ${err.response.data.message}`);
      });
  });
};

export default Delete;
