import styled from 'styled-components';
import * as color from '../../../color/index';

export const SearchBar = styled.form`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  input {
    width: 100%;
    font-size: 14px;
    height: 40px;
    text-indent: 12px;
    border-radius: 5px;
    border: 2px solid #4a5057;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
    transition: 0.35s;
    font-weight: bold;
    box-sizing: border-box;

    &::placeholder {
      color: ${color.Colors.effect};
    }
  }
`;

export const HeaderSearchIcon = styled.div`
  flex-shrink: 0;
  position: relative;
  right: 16px;
  background: url('/images/icon_search@2x.png') center / cover;
  width: 18px;
  height: 18px;
  margin-left: -16px;
`;
