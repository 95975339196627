import styled from 'styled-components';
import * as color from '../../../color/index';

export const Container = styled.div`
  background: ${color.Colors.background};
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  width: 100%;
  padding: 16px;
  padding-bottom: 4px;
  color: ${color.Colors.primary};
  margin-bottom: 12px;
`;

export const PanelTitle = styled.h4`
  font-size: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
`;

export const PanelDate = styled.h6`
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const PanelButton = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  button {
    flex: 1 1 auto;
  }
`;

export const TopBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PauseBox = styled.div`
  padding: 3px 7px;
  background: ${color.Colors.lightRed};
  border: 1px solid ${color.Colors.lightRed};
  border-radius: 5px;
  color: ${color.Colors.deepRed};
  font-size: 11px;
  font-weight: bold;
  white-space: nowrap;
`;

export const EditHistory = styled.div`
  cursor: pointer;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #495057;
`;
