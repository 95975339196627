import React from 'react';
import * as Styled from './styles';
import { NonConfirmReservations, Notifications } from '../../../components';

const DashboardView = () => {
  return (
    <Styled.Container>
      <Styled.Wrapper>
        <NonConfirmReservations />
        <Notifications />
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default DashboardView;
