import NetworkManager from '../../../NetworkManager';

const SettingsList = () => {
  return new Promise((resolve, reject) => {
    NetworkManager('get', 'admin/settings')
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default SettingsList;
