import styled from 'styled-components';
import { darken } from 'polished';
import * as color from '../../../color/index';
import { BreakPoints, HorizontalGlobalPadding } from '../../../GlobalUtilityStyles';

export const LeftItem = styled.div`
  .title {
    margin-bottom: 20px;
    line-height: 40px;
  }

  button {
    width: 100%;
  }
`;

export const Button = styled.button`
  flex-shrink: 0;
  word-break: keep-all;
  text-align: left;
  width: 237px;
  height: 40px;
  padding: 0 20px;
  color: black;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  background: ${color.Colors.white};
  border: 1px solid ${color.Colors.line};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  transition: 0.25s;
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
  margin-bottom: 8px;
  transition: 0.35s;

  &:hover {
    background: ${darken(0.05, `${color.Colors.background}`)};
    transition: 0.25s;
  }
  &:focus {
    outline: none;
  }
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RightContainer = styled.div`
  padding: 20px 50px;
  overflow: hidden;

  ${BreakPoints.lg} {
    padding: 20px ${HorizontalGlobalPadding.lg};
  }

  ${BreakPoints.md} {
    padding: 20px ${HorizontalGlobalPadding.md};
  }

  ${BreakPoints.sm} {
    padding: 15px ${HorizontalGlobalPadding.sm};
  }
`;
