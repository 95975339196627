import React, { useEffect } from 'react';
import './index.scss';

interface SalesTableProps {
  productData: { price: number };
  salesData: any;
  setSalesData: any;
  edit?: boolean;
}

const SalesTable = ({ productData, salesData, setSalesData, edit }: SalesTableProps) => {
  const PRODUCT_PRICE = productData.price;

  useEffect(() => {
    if (!edit) {
      setSalesData({
        price: PRODUCT_PRICE,
        card: 0,
        cash: 0,
        transfer: 0,
        discount: 0,
        totalPrice: 0,
        unpaidPrice: PRODUCT_PRICE,
      });
    }
  }, [edit, setSalesData, PRODUCT_PRICE]);

  function comma(str: any) {
    str = String(str);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  function unComma(str: any) {
    str = String(str);
    return str.replace(/[^\d]+/g, '');
  }

  // 자동 계산
  const handleChange = (e: any) => {
    let temp = {
      ...salesData,
      [e.target.name]: isNaN(parseInt(unComma(e.target.value)))
        ? 0
        : parseInt(unComma(e.target.value)),
    };

    temp.totalPrice = temp.card * 1 + temp.cash * 1 + temp.transfer * 1 + temp.discount;
    temp.unpaidPrice = temp.price * 1 - temp.totalPrice * 1;

    setSalesData(temp);
  };

  return (
    <div className="salestable">
      <table>
        <tbody>
          <tr>
            <th>카드</th>
            <td>
              <input
                name="card"
                min="0"
                max="9999999999"
                value={comma(unComma(salesData.card))}
                onChange={handleChange}
                type="text"
                required
              />
            </td>
          </tr>
          <tr>
            <th>현금</th>
            <td>
              <input
                name="cash"
                min="0"
                max="9999999999"
                value={comma(unComma(salesData.cash))}
                onChange={handleChange}
                type="text"
                required
              />
            </td>
          </tr>
          <tr>
            <th>이체</th>
            <td>
              <input
                name="transfer"
                min="0"
                max="9999999999"
                value={comma(unComma(salesData.transfer))}
                onChange={handleChange}
                type="text"
                required
              />
            </td>
          </tr>
          <tr>
            <th>할인</th>
            <td>
              <input
                name="discount"
                min="0"
                max="9999999999"
                value={comma(unComma(salesData.discount))}
                onChange={handleChange}
                type="text"
                required
              />
            </td>
          </tr>
          <tr className="result">
            <th>총 결제금액</th>
            <td>{salesData.totalPrice?.toLocaleString()} 원</td>
          </tr>
          <tr className="exception">
            <th>미수금</th>
            <td>{salesData.unpaidPrice?.toLocaleString()} 원</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default SalesTable;
