import React from 'react';
import * as Styled from './styles';
import { useTranslation } from 'react-i18next';
import { randomColor } from '../../../actions';

interface ProductPanelProps {
  data: any;
  category: any;
  link?: any;
  body?: any;
  onClick?: any;
  view?: boolean;
}

const ProductPanel = (props: ProductPanelProps) => {
  const { t } = useTranslation();

  const ProductTypeChanger = (value: any) => {
    switch (value) {
      case 0:
        return <>{t('product.personalLesson')}</>;
      case 1:
        return <>{t('product.groupLesson')}</>;
      case 2:
        return <>{t('product.usePlate')}</>;
      case 3:
        return <>{t('product.location')}</>;
      case 4:
        return <>{t('product.locker')}</>;
      case 5:
        return <>{t('product.etc')}</>;
      default:
        return <></>;
    }
  };

  return (
    <Styled.ProductPanel view={props.view ? true : false} onClick={props.onClick}>
      <h5>
        <Styled.PanelCircle style={{ background: randomColor(props.category.id) }} />
        {ProductTypeChanger(props.data.type)} / {props.category.name}
      </h5>
      <h4>{props.data.name}</h4>
      <h6>
        [ {props.data.Employee && props.data.Employee.name} ]{' '}
        {props.data.isUnlimited || props.data.type === 4
          ? `${t('product.unlimited')}`
          : `${props.data.totalCount} ${t('common.count')}`}{' '}
        / {props.data.period} {t('common.days')}{' '}
        {props.data.onlyOnceToday && `: ${t('product.onlyOnceTodayLabel')}`}
      </h6>
      <hr />
      <Styled.ProductPanelPrice>
        {(props.data.price * 1).toLocaleString()} <span>{t('common.KRW')}</span>
      </Styled.ProductPanelPrice>
      {props.body}
    </Styled.ProductPanel>
  );
};

export default ProductPanel;
