import React, { useState, useEffect, useRef } from 'react'; //comments: 상단에 요약 정보 보여 주려고
import * as Styled from './../reservationViewStyles';
import Panel from '../Panel';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { ReservationAdd, ReservationDetail, ReservationList, ReservationPersonalList } from '../..';
import GOButton from '../../common/GOButton';
import SmallTitle from '../../common/SmallTitle';
import { useTranslation } from 'react-i18next';

interface viewProps {
  sDate: string;
  eDate: any;
  data: any;
  update?: any;
  employee?: boolean;
  lessonProID?: number | string;
  showAddLessonTimeManually?: Function;
}

//comments: 높이값 조절
//campus_res_table --> height 조절,
const View = ({
  sDate,
  eDate,
  data,
  update,
  employee,
  lessonProID,
  showAddLessonTimeManually, //2022.10.13 레슨 시간 셀을 클릭했을때 스케쥴 넣는 화면 띄워주기
}: viewProps) => {
  dayjs.locale('ko');

  const [viewAddReservation, setViewAddReservation] = useState('');
  const [viewEditReservation, setViewEditReservation] = useState('');
  const [viewMsgReservation, setViewMsgReservation] = useState('');
  const [viewList, setViewList] = useState('');
  const [viewPersonalList, setViewPersonalList] = useState('');
  const [point, setPoint] = useState({ x: 0, y: 0 });
  const [mouseOver, setMouseOver] = useState<any>({ x: '', y: '', data: {} });
  const [nowDate, setNowDate] = useState('');
  const [viewTable, setTable] = useState<any>();

  const isPublicNineHole = Number(lessonProID) === 146;

  //the below lines, console log,  commented because it looks too messy
  // useEffect(() => {
  //   console.log('mouseOver', mouseOver);
  // }, [mouseOver]);

  //comments: 예약상단에 요약 정보 표시
  const summaryInfos = useRef([
    { reservationCount: new Set(), attendantCount: new Set() },
    { reservationCount: new Set(), attendantCount: new Set() },
    { reservationCount: new Set(), attendantCount: new Set() },
    { reservationCount: new Set(), attendantCount: new Set() },
    { reservationCount: new Set(), attendantCount: new Set() },
    { reservationCount: new Set(), attendantCount: new Set() },
    { reservationCount: new Set(), attendantCount: new Set() },
  ]);

  const timeMaker = () => {
    let result = [];
    // console.log(isPublicNineHole);
    for (let i = 0; i < 24; i++) {
      let tableDataSetting = []; // ex) 00:00 ~ 01:00처럼 1시간 단위 데이터 세팅
      let tableDataSetting30minute = []; // ex) 00:30 ~ 01:30처럼 1시간 단위 데이터 세팅
      if (isPublicNineHole) {
        if (i < 5 || i > 18) {
          continue;
        }
      }

      for (let t = 0; t < 7; t++) {
        const nowTime = `${dayjs(sDate)
          .add(t, 'd')
          .format(`YYYY-MM-DDT${i < 10 ? `0${i}` : i}:00:00`)}`;
        // nowTime : 현재 시간 (YYYY-MM-DDT00:00:00 ~ YYYY-MM-DDT24:00:00)
        // now : table상의 현재 시간
        const now = dayjs(nowTime).diff(dayjs()) > -1800000 && dayjs(nowTime).diff(dayjs()) < 0;
        // table 상단 누르면 에약가능시간 등록 이동
        tableDataSetting.push(
          <td
            id={`${i < 10 ? `0${i}` : i}:00`}
            key={t}
            className={`${now && 'now_time'}`}
            onClick={(e: any) => {
              if (!employee) {
                setNowDate(
                  dayjs(sDate)
                    .add(t, 'd')
                    .format('YYYY-MM-DD'),
                );
                setViewAddReservation(e.target.id); //제주 캠퍼스 테이블 하단에 셀을 눌렀을때 표시하는 팝업
              } else {
                //2022.10.13 레슨일 경우 띄워준다.
                if (showAddLessonTimeManually)
                  showAddLessonTimeManually({
                    d: dayjs(sDate)
                      .add(t, 'd')
                      .format('YYYY-MM-DD'),
                    t: `${i < 10 ? `0${i}` : i}:00`,
                  });
              }
            }}
          />,
        );
      }
      for (let t = 0; t < 7; t++) {
        const nowTime = `${dayjs(sDate)
          .add(t, 'd')
          .format('YYYY-MM-DD')}T${i < 10 ? `0${i}` : i}:30:00`;
        const now = dayjs(nowTime).diff(dayjs()) > -1800000 && dayjs(nowTime).diff(dayjs()) < 0;

        // table 상단 누르면 에약가능시간 등록 이동
        tableDataSetting30minute.push(
          <td
            id={`${i < 10 ? `0${i}` : i}:30`}
            key={t}
            className={`${now && 'now_time'}`}
            onClick={(e: any) => {
              if (!employee) {
                setNowDate(
                  dayjs(sDate)
                    .add(t, 'd')
                    .format('YYYY-MM-DD'),
                );
                setViewAddReservation(e.target.id);
              } else {
                //2022.10.13 레슨일 경우 띄워준다.
                if (showAddLessonTimeManually)
                  showAddLessonTimeManually({
                    d: dayjs(sDate)
                      .add(t, 'd')
                      .format('YYYY-MM-DD'),
                    t: `${i < 10 ? `0${i}` : i}:30`,
                  });
              }
            }}
          />,
        );
      }
      // 테이블 왼쪽에 시간 표시 및 시간에 맞춰 테이블 상단 하단에 테이블데이터 추가
      //제주캠퍼스 개인수업 수정
      result.push(
        <React.Fragment key={i}>
          <tr>
            {i !== 0 ? <td>{i < 10 ? `0${i}` : i}:00</td> : <td />}
            {tableDataSetting}
          </tr>
          <tr>
            <td />
            {tableDataSetting30minute}
          </tr>
        </React.Fragment>,
      );
    }

    return result;
  };

  const startDate = new Date(sDate);
  const days7 = ['일', '월', '화', '수', '목', '금', '토'];
  const today = dayjs();

  const { t } = useTranslation();

  useEffect(() => {
    const endDate = new Date(eDate).getTime();
    const allPlate: any = window.sessionStorage.getItem('allPlates')
      ? window.sessionStorage.getItem('allPlates')
      : 1;

    for (let i = 0; i < 7; i++) {
      summaryInfos.current[i].reservationCount.clear();
      summaryInfos.current[i].attendantCount.clear();
    }

    if (data[0] && !data[0].overlap) {
      data.forEach((d: any) => {
        // (60 * 60 * 24) = 1일
        // (60 * 60 * 9) = 9시간
        // (60 * 60) = 1시간
        const dEnd = new Date(d.endDate).getTime() / 1000 + 60 * 60 * 9;
        // dEnd : 1970.01.01 부터 2020.08.23까지의 초 단위 + 9시간 = local endDate
        const dStart = new Date(d.startDate).getTime() / 1000 + 60 * 60 * 9;
        // dStart : 1970.01.01 부터 2020.08.23까지의 초 단위 + 9시간 = local startDate
        const todayDate = 6 - Math.floor((endDate / 1000 - dStart - 1) / (60 * 60 * 24));
        const nowTime =
          (60 * 60 * 24 - (endDate / 1000 - dStart - 60 * 60 * 24 * (6 - todayDate))) / (60 * 60);

        const allTime = (dEnd - dStart) / (60 * 60);

        if (!employee) {
          d.overlap = d.relatedPates.length;
          d.arr = [];
          d.relatedPates.forEach((rp: any) => {
            d.arr.push(rp.number);
            //comments 예약 요약 정보 추가 중
            try {
              if (rp.PlateReservation !== null && rp.PlateReservation !== undefined) {
                if (
                  rp.PlateReservation.status !== undefined &&
                  rp.PlateReservation.status !== null &&
                  rp.PlateReservation.User !== undefined &&
                  rp.PlateReservation.User !== null &&
                  rp.PlateReservation.User.id !== undefined &&
                  rp.PlateReservation.User.id !== null
                ) {
                  summaryInfos.current[todayDate].reservationCount.add(rp.PlateReservation.User.id);
                  if (rp.PlateReservation.status === 0) {
                    //
                  } else if (rp.PlateReservation.status === 1) {
                    summaryInfos.current[todayDate].attendantCount.add(rp.PlateReservation.User.id);
                    //
                  } else if (rp.PlateReservation.status === 2) {
                  }
                }
              }
            } catch (e) {
              console.log(e);
            }
          });
          d.location = Math.min(...d.arr);
        } else {
          d.overlap = allPlate;
          d.location = 1;
          try {
            if (d.LessonReservation !== undefined && d.LessonReservation !== null) {
              if (
                d.LessonReservation.status !== undefined &&
                d.LessonReservation.status !== null &&
                d.LessonReservation.User !== undefined &&
                d.LessonReservation.User !== null &&
                d.LessonReservation.User.id !== undefined &&
                d.LessonReservation.User.id !== null
              ) {
                summaryInfos.current[todayDate].reservationCount.add(d.LessonReservation.User.id);
                if (d.LessonReservation.status === 0) {
                } else if (d.LessonReservation.status === 1) {
                  summaryInfos.current[todayDate].attendantCount.add(d.LessonReservation.User.id);
                } else if (d.LessonReservation.status === 2) {
                }
              }
            }
          } catch (e) {
            console.log(e);
          }
        }
        d.nowTime = nowTime;
        d.todayDate = todayDate;
        d.allTime = allTime;
        d.heightFactor = 5.0;
        if (allTime >= 0.5) {
          d.heightFactor = 5.0;
        } else if (allTime >= 0.33) {
          d.heightFactor = 6.6;
        } else if (allTime >= 0.25) {
          d.heightFactor = 7.5;
        } else if (allTime >= 0.16) {
          d.heightFactor = 10.0;
        }
        console.log('heightFactor', d.heightFactor);
      });
    }
    setTable(
      <div className="tbl_panel">
        {data.map((d: any, index: number) => {
          //TEST code
          // console.log('d.allTime', d.allTime);
          // console.log('d.nowTime', d.nowTime);
          if (employee) {
            if (isPublicNineHole) {
              return (
                // 퍼블릭
                // 시간데 높이값 수정
                //comments: 예약 화면 테입르 셀
                <React.Fragment key={index}>
                  <Panel
                    id={d.id}
                    key={index}
                    style={{
                      left: `calc((((100% - 3.5em) / 7 * ${d.todayDate}) + 3.6em) + ${13 *
                        (1 / 15) -
                        0.9}%)`,
                      //제주 캠퍼스 높이를 조절한다.
                      transform: `translateY(${5 * 3 * (d.nowTime - 5) + 7.25}rem)`,
                      height: `${5 * 3 * d.allTime}rem`,
                      // transform: `translateY(${10 * d.nowTime + 6.85}rem)`,
                      // height: `${10 * d.allTime}rem`,
                      width: `13%`,
                    }}
                    name={d.LessonReservation?.User?.name}
                    startDate={d.startDate}
                    endDate={d.endDate}
                    totalCount={d.totalCount}
                    reservationCount={d.reservationCount}
                    edit={(e: any) => {
                      setPoint({
                        x: e.clientX - 40,
                        y: e.pageY - 260,
                      });
                      setViewMsgReservation(d.id);
                    }}
                    onMouseMove={(e: any) =>
                      setMouseOver({
                        x: e.clientX - 40,
                        y: e.pageY - 220,
                        data: d,
                      })
                    }
                    onMouseOut={() => setMouseOver({ x: '', y: '', data: {} })}
                    employee={d}
                  />
                </React.Fragment>
              );
            } else {
              return (
                // 개인수업
                <React.Fragment key={index}>
                  <Panel
                    id={d.id}
                    key={index}
                    style={{
                      left: `calc((((100% - 3.5em) / 7 * ${d.todayDate}) + 3.6em) + ${13 *
                        (1 / 15) -
                        0.9}%)`,
                      //제주 캠퍼스 높이를 조절한다.
                      transform: `translateY(${5 * d.nowTime + 5.65}rem)`,
                      height: `${5 * d.allTime}rem`, // 원래값
                      // height: `${d.heightFactor * d.allTime}rem`, // 원래값
                      // transform: `translateY(${10 * d.nowTime + 6.85}rem)`,
                      // height: `${10 * d.allTime}rem`,
                      width: `13%`,
                    }}
                    name={d.LessonReservation?.User?.name}
                    startDate={d.startDate}
                    endDate={d.endDate}
                    totalCount={d.totalCount}
                    reservationCount={d.reservationCount}
                    heightFactor={d.heightFactor}
                    edit={(e: any) => {
                      setPoint({
                        x: e.clientX - 40,
                        y: e.pageY - 260,
                      });
                      setViewMsgReservation(d.id);
                    }}
                    onMouseMove={(e: any) =>
                      setMouseOver({
                        x: e.clientX - 40,
                        y: e.pageY - 220,
                        data: d,
                      })
                    }
                    onMouseOut={() => setMouseOver({ x: '', y: '', data: {} })}
                    employee={d}
                  />
                </React.Fragment>
              );
            }
          } else {
            return (
              // 타석 이용
              <React.Fragment key={index}>
                <Panel
                  id={d.id}
                  key={index}
                  style={{
                    left: `calc((((100% - 3.5em) / 7 * ${d.todayDate}) + 3.6em) + ${13 *
                      ((d.location - 1) / allPlate)}% )`,
                    //제주캠퍼스 높이를 조절한다.
                    transform: `translateY(${5 * d.nowTime + 5.65}rem)`,
                    height: `${5 * d.allTime}rem`,
                    // transform: `translateY(${10 * d.nowTime + 6.85}rem)`,
                    // height: `${10 * d.allTime}rem`,
                    width: `${13 * (d.overlap / allPlate)}%`,
                  }}
                  name={d.name}
                  startDate={d.startDate}
                  endDate={d.endDate}
                  totalCount={d.totalCount}
                  reservationCount={d.reservationCount}
                  edit={(e: any) => {
                    setPoint({
                      x: e.clientX - 40,
                      y: e.pageY - 260,
                    });
                    setViewMsgReservation(d.id);
                  }}
                  onMouseMove={(e: any) =>
                    setMouseOver({
                      x: e.clientX - 40,
                      y: e.pageY - 220,
                      data: d,
                    })
                  }
                  onMouseOut={() => setMouseOver({ x: '', y: '', data: {} })}
                />
              </React.Fragment>
            );
          }
        })}
      </div>,
    );
  }, [data, eDate, employee]);
  const ticketName = mouseOver.data.LessonReservation?.name;
  const totalCount = mouseOver.data.LessonReservation?.Ticket?.totalCount;
  const remainingCount = mouseOver.data.LessonReservation?.Ticket?.remainingCount;
  const reservationCount = mouseOver.data.LessonReservation?.User?.reservationCount;
  const isUnlimited = mouseOver.data.LessonReservation?.Ticket?.isUnlimited;
  //comments: 해당 날자의 예약자 수 표시
  const getTodaysReservationCount = (index: number) => {
    try {
      if (summaryInfos.current[index].reservationCount.size === 0) {
        return ``;
      }
      const rev = `${summaryInfos.current[index].reservationCount.size}명 예약`;
      const att =
        summaryInfos.current[index].attendantCount.size === 0
          ? ''
          : `${summaryInfos.current[index].attendantCount.size}명 출석/`;
      return `${att} ${rev}`;
    } catch (e) {
      console.log(e);
      return '';
    }
  };

  return (
    <Styled.ReservationView>
      <div className={isPublicNineHole ? 'campus_res_table' : 'res_table'}>
        {viewTable}
        <table>
          <colgroup>
            <col style={{ width: '60px' }} />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th></th>
              {days7.map((d: any, index: number) => {
                const thisday = dayjs(startDate)
                  .add(index, 'd')
                  .format('YYYY-MM-DD');
                const todayfor = today.format('YYYY-MM-DD');
                return (
                  <th
                    key={index}
                    className={`${index === 0 ? 'sun' : index === 6 ? 'sat' : ''} ${
                      thisday === todayfor ? 'today' : ''
                    }`}
                  >
                    <span className="top_summary">{getTodaysReservationCount(index)}</span>
                    <br />
                    <span className="top_date">
                      {dayjs(startDate)
                        .add(index, 'd')
                        .format('MM. DD')}
                    </span>
                    <br />
                    <span className="top_days">{d}요일</span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>{timeMaker()}</tbody>
        </table>
      </div>
      {mouseOver.x && (
        <div
          className="hover_box"
          style={{
            top: mouseOver.y + 30,
            // left: mouseOver.x > 800 ? mouseOver.x - 270 : mouseOver.x,
            left: mouseOver.x - 70,
          }}
        >
          <div className="hover_name">{mouseOver.data.name}</div>
          <hr />
          {employee ? (
            <div>
              <div className="hover_Date">
                시작 : {dayjs(mouseOver.data.startDate).format('YYYY. MM. DD a hh:mm')}
              </div>
              <div className="hover_Date">
                종료 : {dayjs(mouseOver.data.endDate).format('YYYY. MM. DD a hh:mm')}
              </div>

              {mouseOver.data.LessonReservation?.User?.name && (
                <>
                  <hr />
                  <div className="hover_total">
                    회원 : {mouseOver.data.LessonReservation?.User?.name}
                  </div>
                  <br />
                  <div className="hover_res">사용티켓 : {ticketName}</div>
                  <div className="hover_res">
                    {isUnlimited === 1
                      ? '무제한 이용권'
                      : `잔여 / 전체 : ${remainingCount}번 / ${totalCount}번`}
                  </div>
                  <div className="hover_res">현재 예약 건수 : {reservationCount} 건</div>
                </>
              )}
            </div>
          ) : (
            <>
              <div className="hover_total">총 타석 : {mouseOver.data.totalCount}</div>
              <div className="hover_res">예약 타석 : {mouseOver.data.reservationCount}</div>

              {mouseOver.data.users.length > 0 && (
                <>
                  <hr />
                  <div>
                    예약 회원 :{/*feature/20211114/checkin nullability check */}
                    {mouseOver.data.users?.map((u: any) => (
                      <>{u !== null ? <span> {u.name}</span> : <span> </span>}</>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}

      {viewAddReservation && (
        <ReservationAdd
          id={viewAddReservation}
          startDate={nowDate}
          onClickExit={() => {
            setViewAddReservation('');
          }}
          update={update}
          data={data}
        />
      )}
      {viewMsgReservation && (
        <div
          className="msg_box"
          style={{
            top: point.y + 30,
            left: point.x > 800 ? point.x - 250 : point.x + 50,
          }}
          onMouseLeave={() => setViewMsgReservation('')}
        >
          <SmallTitle body="이용 상태" className="title" />
          <div className="hover_msg_for_mobile">(2023.08.08 월 00:00 ~ 01:00)</div>
          <div>
            <GOButton
              body={t('reservation.availabilityEdit')}
              noMarginLeft
              buttontype="primary"
              onClick={() => {
                setViewEditReservation(viewMsgReservation);
              }}
            />
            <GOButton
              body={t('reservation.reservationList')}
              onClick={() => {
                if (employee) {
                  setViewPersonalList(viewMsgReservation);
                } else {
                  setViewList(viewMsgReservation);
                }
              }}
            />
          </div>
        </div>
      )}
      {viewEditReservation && (
        <ReservationDetail
          id={viewEditReservation}
          onClickExit={() => {
            setViewEditReservation('');
          }}
          update={update}
          employee={employee}
        />
      )}
      {viewList && (
        <ReservationList
          id={viewList}
          onClickExit={() => {
            setViewList('');
          }}
          update={update}
        />
      )}
      {viewPersonalList && (
        <ReservationPersonalList
          id={viewPersonalList}
          onClickExit={() => {
            setViewPersonalList('');
          }}
          update={update}
        />
      )}
    </Styled.ReservationView>
  );
};
export default View;
