import React, { useEffect } from 'react';
import Chart from 'chart.js';
import * as Styled from '../../chartAreaStyles';

interface chartProps {
  data?: any;
  label?: string;
}

const AgeBarChart = ({ data, label }: chartProps) => {
  const comma = (str: any) => {
    str = String(str);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  };

  const unComma = (str: any) => {
    str = String(str);
    return str.replace(/[^\d]+/g, '');
  };
  useEffect(() => {
    let chart: any = null;
    if (chart) {
      chart.destroy();
      chart = null;
    }
    const config: any = {
      type: 'bar',
      data: data,
      options: {
        tooltips: {
          displayColors: true,
          callbacks: {
            mode: 'x',
            title: (item: any, data: any) => {
              let index = item[0].datasetIndex;
              return data.datasets[index].label;
            },
            label: (item: any) => {
              return `${comma(unComma(item.value))}원`;
            },
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
                callback: (item: any) => {
                  return `${comma(unComma(item))}원`;
                },
              },
              type: 'linear',
            },
          ],
        },
        // responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
          labels: {
            padding: 10,
            boxWidth: 15,
          },
        },
      },
    };
    const ctx: HTMLElement | any = document.getElementById('yearlyAge');
    chart = new Chart(ctx, config);
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [data]);
  return (
    <Styled.ChartBox>
      {label && <Styled.Title>{label}</Styled.Title>}
      <Styled.Chart className="rect_chart_response">
        <canvas id="yearlyAge" />
      </Styled.Chart>
    </Styled.ChartBox>
  );
};

export default AgeBarChart;
