import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  div {
    font-size: 14px;
    font-weight: normal;
    margin-left: 5px;
  }
`;
