import NetworkManager from '../../../NetworkManager';

const DeleteLessonSchedule = (eID: string, sDate: string, eDate: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager(
      'delete',
      `admin/lessonAvailability/schedule/delete?employeeID=${eID}&startDate=${sDate}&endDate=${eDate}`,
    )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        console.log(err);
      });
  });
};

export default DeleteLessonSchedule;
