import React from 'react';
import * as Styled from './styles';

interface GOLayoutProps {
  body?: JSX.Element;
  height?: string | number;
  shortTopPadding?: boolean;
  flexGrow?: boolean;
}

interface BoxProps {
  body?: JSX.Element;
  height?: string | number;
  width?: string | number;
  style?: any;
}

const Box = (props: BoxProps) => {
  return (
    <Styled.LayoutBox
      style={{
        minHeight: props.height ? props.height : 'auto',
        width: props.width ? props.width : undefined,
      }}
    >
      {props.body}
    </Styled.LayoutBox>
  );
};

/**
 * 사용된 레이아웃 (상하좌우 여백만 잡힌 기본 레이아웃)
 - 고객관리 > 고객상세 
 - 직원관리 > 직원상세
 - 설정
**/
const GOLayout = (props: GOLayoutProps) => {
  return (
    <Styled.GOLayoutContainer
      style={{ minHeight: props.height ? props.height : 'auto' }}
      shortTopPadding={props.shortTopPadding}
      flexGrow={props.flexGrow}
    >
      {props.body}
    </Styled.GOLayoutContainer>
  );
};

export { GOLayout, Box };
