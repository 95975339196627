import NetworkManager from '../../../NetworkManager';

const SettingsEdit = (data: any) => {
  return new Promise((resolve, reject) => {
    NetworkManager('post', 'admin/settings', data)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export default SettingsEdit;
