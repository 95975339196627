import styled, { keyframes } from 'styled-components';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const golayout = keyframes`
  0% {
    opacity: 0;
    left: -20px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
`;

export const Layout = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;

  ${BreakPoints.sm} {
    flex-direction: column;
  }
`;

export const Box = styled.div`
  position: relative;
  width: calc(50% - 10px);
  flex: 1 1 auto;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: white;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  animation: ${golayout} 0.35s;
  &:not(:last-child) {
    margin-right: 20px;
  }

  ${BreakPoints.sm} {
    width: 100%;
    padding: 10px;
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 15px;
    }
    &:last-child {
      width: 100%;
    }
  }
`;

export const Text = styled.div`
  font-size: 14px;
  color: #495057;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
