import React, { useState } from 'react';
import {
  SmallTitle,
  EmployeeScheduleManageDetail,
  EmployeeScheduleManagePanel,
} from '../../../components';
import * as Styled from './styles';

const ScheduleManage = ({ data }: any) => {
  const originalData = data.workTimes;
  const lessonData = originalData?.slice().sort((a: any, b: any) => {
    return a['day'] - b['day'];
  });

  const [update, isUpdate] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>('');

  const days = [1, 2, 3, 4, 5, 6, 0];

  const viewDay = (data: any) => {
    if (data === 1) return `월`;
    if (data === 2) return `화`;
    if (data === 3) return `수`;
    if (data === 4) return `목`;
    if (data === 5) return `금`;
    if (data === 6) return `토`;
    if (data === 0) return `일`;
  };

  const viewWorkStartTime = (data: any) => {
    let name = '';
    let hour = '';
    let minute = '';

    if (data.startHour > 11) name = '오후';
    else name = '오전';
    if (data.startHour === 24) name = '오전';

    if (data.startHour < 10) {
      hour = `0${data.startHour}`;
    } else if (data.startHour > 10 && data.startHour < 13) {
      hour = `${data.startHour}`;
    } else if (data.startHour > 12 && data.startHour < 22) {
      hour = `0${data.startHour - 12}`;
    } else if (data.startHour > 21) {
      hour = `${data.startHour - 12}`;
    }
    if (data.startHour === 24) {
      hour = `0${data.startHour - 24}`;
    }
    if (data.startHour === 10) hour = `${data.startHour}`;

    if (data.startMinute < 10) minute = `0${data.startMinute}`;
    else minute = data.startMinute;

    return `${name} ${hour}:${minute}`;
  };

  const viewWorkEndTime = (data: any) => {
    let name = '';
    let hour = '';
    let minute = '';

    if (data.endHour > 11) name = '오후';
    else name = '오전';
    if (data.endHour === 24) name = '오전';

    if (data.endHour < 10) {
      hour = `0${data.endHour}`;
    } else if (data.endHour > 10 && data.endHour < 13) {
      hour = `${data.endHour}`;
    } else if (data.endHour > 12 && data.endHour < 22) {
      hour = `0${data.endHour - 12}`;
    } else if (data.endHour > 21) {
      hour = `${data.endHour - 12}`;
    }
    if (data.endHour === 24) {
      hour = `0${data.endHour - 24}`;
    }
    if (data.endHour === 10) hour = `${data.endHour}`;

    if (data.endMinute < 10) minute = `0${data.endMinute}`;
    else minute = data.endMinute;

    return `${name} ${hour}:${minute}`;
  };

  return (
    <>
      <Styled.Layout>
        <Styled.Box>
          <SmallTitle body="근무시간 현황" />
          {days.map((d: any, index: number) => (
            <React.Fragment key={index}>
              <EmployeeScheduleManagePanel
                selected={selected}
                selectedItem={d}
                update={update}
                workInfo={
                  <>
                    {lessonData[d]?.startHour === null && lessonData[d]?.startMinute === null ? (
                      <>-</>
                    ) : (
                      <>
                        근무시간: {viewWorkStartTime(lessonData[d])} ~{' '}
                        {viewWorkEndTime(lessonData[d])}
                      </>
                    )}
                  </>
                }
                restInfo={
                  <>
                    {lessonData[d]?.breakTime.startHour === null &&
                    lessonData[d]?.breakTime.startMinute === null ? (
                      <>-</>
                    ) : (
                      <>
                        휴식시간: {viewWorkStartTime(lessonData[d].breakTime)} ~{' '}
                        {viewWorkEndTime(lessonData[d].breakTime)}
                      </>
                    )}
                  </>
                }
                date={`${viewDay(d)}`}
                onClick={() => {
                  setSelected(d);
                  isUpdate(true);
                }}
              />
            </React.Fragment>
          ))}
        </Styled.Box>
        <Styled.Box>
          <EmployeeScheduleManageDetail data={data} date={selected} update={update} />
        </Styled.Box>
      </Styled.Layout>
    </>
  );
};

export default ScheduleManage;
