import React from 'react';
import * as Styled from './styles';
import ReactLoading from 'react-loading';
import { BreakPoints } from '../../../GlobalUtilityStyles';
// MARK : GOButton : Button 컴포넌트
// NOTE : 앱 내의 모든 버튼으로 사용합니다.

interface GOButtonProps {
  // Param
  // body : Button 에 들어갈 내용 설명
  // width, height : 가로, 세로값 변경
  // buttontype : 버튼의 디자인적 타입 결정
  // size : 버튼의 정해진 크기 결정

  // button의 기본요소
  // type (기본 : button), onClick
  body?: string | JSX.Element;
  width?: number | string;
  height?: number;
  fontSize?: number;
  buttontype?: 'primary' | 'disabled' | 'red' | 'green' | 'gray' | 'darkGray' | undefined;
  size?: 'xxlarge' | 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall';
  noMarginLeft?: boolean;
  style?: object;
  loading?: boolean;

  onClick?: any;
  disabled?: any;
  onKeyDown?: any;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const GOButton = (props: GOButtonProps) => {
  return (
    <Styled.Button
      type={props.type && !props.loading ? props.type : 'button'}
      value="Submit"
      style={{
        width: props.width,
        height: props.height,
        fontSize: props.fontSize,
        ...props.style,
      }}
      onKeyDown={props.onKeyDown}
      onClick={props.loading ? undefined : props.onClick}
      xxlarge={props.size === 'xxlarge' ? true : false}
      xlarge={props.size === 'xlarge' ? true : false}
      large={props.size === 'large' ? true : false}
      medium={props.size === 'medium' ? true : false}
      small={props.size === 'small' ? true : false}
      xsmall={props.size === 'xsmall' ? true : false}
      primary={props.buttontype === 'primary' ? true : false}
      red={props.buttontype === 'red' ? true : false}
      green={props.buttontype === 'green' ? true : false}
      gray={props.buttontype === 'gray' ? true : false}
      darkGray={props.buttontype === 'darkGray' ? true : false}
      disable={props.buttontype === 'disabled' ? true : false}
      disabled={props.disabled}
      noMarginLeft={props.noMarginLeft}
    >
      {props.loading ? (
        <Styled.Loading>
          <ReactLoading type={'bubbles'} color={'white'} width={32} height={32} />
        </Styled.Loading>
      ) : (
        props.body
      )}
    </Styled.Button>
  );
};

export default GOButton;
