import React from 'react';
import './index.scss';
import { randomColor } from '../../../actions';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { GOButton, GOLabel } from '../../../components';
import {
  actionDeleteUserTicket,
  actionPutUserTicketExtendLessonPeriod,
} from '../../../stores/actions';
import { toast } from 'react-toastify';

dayjs.extend(isBetween);

const dynamicState = (TICKET: any) => {
  switch (TICKET && TICKET.status) {
    case 2:
      return {
        className: 'payment_panel payment_refund',
        status: 'refund',
      };
    case 3:
      return {
        className: 'payment_panel payment_deleted',
        status: 'deleted',
      };
    default:
      if (TICKET) {
        if (
          dayjs().diff(dayjs(TICKET.endDate)) > 0 ||
          TICKET.status === 1 ||
          (!TICKET.isUnlimited && TICKET.remainingCount === 0)
        ) {
          if (TICKET.type === 4 && TICKET.status === 0) {
            //락카 배정이 안된 경우
          } else if (
            TICKET.type === 4 &&
            TICKET.status === 1 &&
            dayjs().diff(dayjs(TICKET.endDate)) < 0
          ) {
            return {
              className: 'payment_panel payment_panel',
              status: 'paid',
              refund: true,
            };
          } else {
            return {
              className: 'payment_panel payment_expired',
              status: 'expired',
            };
          }
        }
      }
      return {
        className: 'payment_panel payment_panel',
        status: 'paid',
        refund: true,
      };
  }
};

const isRemovalLockerTicket = (TICKET: any, nowState: any): boolean => {
  try {
    if (
      nowState.status === 'expired' &&
      TICKET.type === 4 &&
      dayjs(TICKET.startDate).isSameOrAfter(dayjs(), 'month')
    ) {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
};

const isRemovalLessonTicket = (TICKET: any, nowState: any): boolean => {
  try {
    if (
      nowState.status === 'expired' &&
      TICKET.type === 0 &&
      TICKET.isUnlimited === false &&
      TICKET.remainingCount > 0 &&
      dayjs(TICKET.endDate)
        .add(1, 'month')
        .isSameOrAfter(dayjs(), 'month')
    ) {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
};

const showExpiredTicketInfo = (nowState: any, TICKET: any) => {
  try {
    if (
      TICKET.type === 4 &&
      nowState.status === 'expired' &&
      dayjs().diff(dayjs(TICKET.endDate)) > 0
    ) {
      return <GOLabel label="락카" body={`만료된 상품권`} />;
    } else if (TICKET.type === 4 && nowState.status === 'expired') {
      return <></>;
    } else if (nowState.status === 'expired') {
      return <GOLabel label="만료" body={`만료된 상품권`} />;
    } else {
      return <></>;
    }
  } catch (e) {
    return <GOLabel label="오류" body={`오류`} />;
  }
};

const showLockerInfo = (nowState: any, TICKET: any) => {
  try {
    let bodyMessage = `-`;
    let bodyStyle = { color: 'black', fontWeight: 'bold' };

    if (TICKET.status === 0) {
      bodyMessage = `미배정`;
      bodyStyle = { color: 'red', fontWeight: 'bold' };
    } else if (TICKET.status === 1 && dayjs().diff(dayjs(TICKET.endDate)) > 0) {
      bodyMessage = `-`;
      bodyStyle = { color: 'black', fontWeight: 'regular' };
    } else if (TICKET.status === 1) {
      bodyMessage = `배정 완료`;
      bodyStyle = { color: 'black', fontWeight: 'bold' };
    } else {
      bodyMessage = `-`;
      bodyStyle = { color: 'black', fontWeight: 'regular' };
    }
    return <GOLabel label="락카" body={bodyMessage} bodyStyle={bodyStyle} />;
  } catch (e) {
    return <GOLabel label="락카" body={`-`} />;
  }
};

const PaymentPanel = ({ data, setUnPaid, setRefund, setEdit, doUpdate }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const TICKET = data.ticket;
  const PRODUCT = data.ticket && data.ticket.Product;
  // console.log(TICKET)

  const nowState = dynamicState(TICKET);

  const isRemovalExpiredLockerTicket = isRemovalLockerTicket(TICKET, nowState);
  const isRemovalExpiredLessonTicket = isRemovalLessonTicket(TICKET, nowState);

  // if (TICKET.type === 4) {
  //   console.log(TICKET.name + '  ' + TICKET.startDate + ' ' + isRemovalExpiredLockerTicket);
  // }

  const startMonth = dayjs()
    .startOf('month')
    .format('YYYY-MM-DDT00:00:00');
  const endMonth = dayjs()
    .endOf('month')
    .format('YYYY-MM-DDT23:59:59');

  const onDelete = () => {
    if (window.confirm('정말 결제내역을 삭제하시겠습니까?')) {
      dispatch(
        actionDeleteUserTicket(TICKET.id, () => {
          alert('삭제 되었습니다.');
          doUpdate();
        }),
      );
    }
  };

  const onExtendPeriod = () => {
    if (window.confirm('회차가 남은 레슨을 연장하시겠습니까?')) {
      dispatch(
        actionPutUserTicketExtendLessonPeriod(
          TICKET.id,
          () => {
            alert('연장되었습니다.');
            doUpdate();
          },
          (err: any) => {
            toast.error('기간 연장에 실패하였습니다.');
          },
        ),
      );
    }
  };

  const onEdit = () => {
    setEdit(data);
  };

  return (
    <div className={nowState.className}>
      {TICKET ? (
        <>
          <div
            className="Panel_circle"
            style={{
              background:
                PRODUCT.ProductCategory && PRODUCT.ProductCategory.id === null
                  ? ''
                  : randomColor(PRODUCT.ProductCategory && PRODUCT.ProductCategory.id),
            }}
          />{' '}
          {nowState.status === 'refund' && `( 환불 )`}{' '}
          <span>{PRODUCT.ProductCategory && PRODUCT.ProductCategory.name}</span>
          {/*{nowState.status === 'paid' && dayjs(TICKET.paymentDate).isBetween(startMonth, endMonth) && (*/}
          {nowState.status === 'paid' && dayjs(data.paymentDate).isBetween(startMonth, endMonth) && (
            <>
              <div className="payment_delete_icon" onClick={onDelete}>
                삭제
              </div>
              <div className="payment_edit_icon" onClick={onEdit}>
                수정
              </div>
            </>
          )}
          {isRemovalExpiredLockerTicket && (
            <>
              <div className="payment_delete_icon" onClick={onDelete}>
                삭제
              </div>
              {/*<div className="payment_edit_icon" onClick={onEdit}>*/}
              {/*  수정*/}
              {/*</div>*/}
            </>
          )}
          {isRemovalExpiredLessonTicket && (
            <>
              <div className="payment_delete_icon" onClick={onExtendPeriod}>
                기간연장
              </div>
              {/*<div className="payment_edit_icon" onClick={onEdit}>*/}
              {/*  수정*/}
              {/*</div>*/}
            </>
          )}
          <h4>{PRODUCT.name}</h4>
          <GOLabel label="구매일" body={dayjs(data.paymentDate).format('YY. MM. DD / HH:mm:ss')} />
          <GOLabel
            label="기간"
            body={`${dayjs(TICKET.startDate).format('YY. MM. DD')} ~ ${dayjs(TICKET.endDate).format(
              'YY. MM. DD',
            )}`}
          />
          {TICKET.type === 4 ? (
            showLockerInfo(nowState, TICKET)
          ) : (
            <GOLabel
              label="잔여횟수"
              body={
                TICKET.isUnlimited
                  ? `무제한`
                  : `${TICKET.totalCount}회 중 ${TICKET.remainingCount}회 남음`
              }
            />
          )}
          <hr />
          <GOLabel
            label="상품금액"
            body={`${TICKET.originalPrice.toLocaleString('en')} ${t('common.KRW')}`}
          />
          <GOLabel
            label="할인금액"
            body={`${TICKET.discount.toLocaleString('en')} ${t('common.KRW')}`}
          />
          <hr />
          <GOLabel
            label="결제금액"
            body={
              <>
                {(TICKET.price - data.unpaidPrice).toLocaleString('en')} {t('common.KRW')}
              </>
            }
          />
          {/* 2022.12.08 만료된 락커 상품권 수정 중*/}
          {/*{nowState.status === 'expired' ? <GOLabel label="만료" body={`만료된 상품권`} /> : <></>}*/}
          {showExpiredTicketInfo(nowState, TICKET)}
          <span className="red">
            {data.unpaidPrice ? (
              <GOLabel
                label="미수금"
                body={`${data.unpaidPrice.toLocaleString('en')} ${t('common.KRW')}`}
              />
            ) : (
              <></>
            )}
            {nowState.status === 'refund' && <GOLabel label="환불" body={`환불 처리 됨`} />}
            {nowState.status === 'deleted' && <GOLabel label="삭제" body={`삭제된 결제내역`} />}
          </span>
          <div className="center">
            {nowState.status !== 'deleted' && data.unpaidPrice ? (
              <GOButton
                buttontype="red"
                body="미수금 정산"
                onClick={() => {
                  setUnPaid(data);
                }}
                noMarginLeft
              />
            ) : (
              ''
            )}
            {nowState.refund && (
              <GOButton
                body="환불"
                noMarginLeft
                onClick={() => {
                  setRefund(data);
                }}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <GOLabel label="상품" body={`삭제된 상품`} />
          <span className="red">
            {data.unpaidPrice ? (
              <GOLabel
                label="미수금"
                body={`${data.unpaidPrice.toLocaleString('en')} ${t('common.KRW')}`}
              />
            ) : (
              <></>
            )}
            {data.status === 2 && <GOLabel label="환불" body={`환불 처리 됨`} />}
          </span>
        </>
      )}
    </div>
  );
};

export default PaymentPanel;
