import React from "react";
import { SecretPin } from "../../../components";

const AccountingPassword = ({ onClickExit }: any) => {
  return (
    <div>
      <SecretPin path="/admin/accounting" onClick={onClickExit} />
    </div>
  );
};

export default AccountingPassword;
