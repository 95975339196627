import styled, { css } from 'styled-components';
import * as color from '../../../color/index';
import { BreakPoints } from '../../../GlobalUtilityStyles';

interface styleProps {
  top?: boolean;
  bottom?: boolean;

  default?: boolean;
  refund?: boolean;
  total?: boolean;

  largeFont?: boolean;
}

export const Summary = styled.div`
  margin-bottom: 12px;

  .table-container {
    ${BreakPoints.md} {
      display: flex;
    }

    ${BreakPoints.sm} {
      flex-direction: column;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  ${BreakPoints.md} {
    flex-direction: column;
  }
`;

export const Total = styled.div`
  background: #fff;
  flex: 1;
  margin-right: 12px;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  padding: 12px;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 200px;

  ${BreakPoints.xs} {
    flex-direction: column;
  }

  ${BreakPoints.md} {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
    min-height: initial;
  }

  ${BreakPoints.sm} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const Part = styled.div<styleProps>`
  flex: 1;
  padding-left: 32px;
  font-weight: bold;

  ${BreakPoints.md} {
    padding-left: 0;
  }

  ${BreakPoints.sm} {
    flex: 1 0 auto;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }



  ${props => {
    if (props.default)
      return css`
        color: ${color.Colors.primary};
      `;
  }}

  ${props => {
    if (props.refund)
      return css`
        color: ${color.Colors.red};
      `;
  }}

  ${props => {
    if (props.total)
      return css`
        color: ${color.Colors.green};
      `;
  }}
`;

export const Title = styled.div<styleProps>`
  font-size: 14px;

  ${props => {
    if (props.largeFont)
      return css`
        font-size: 16px;
      `;
  }}

  ${BreakPoints.md} {
    text-align: center;
  }
`;

export const Body = styled.div<styleProps>`
  margin-top: 4px;
  font-size: 20px;
  /* font-weight: 500; */

  ${props => {
    if (props.largeFont)
      return css`
        font-size: 20px;
      `;
  }}

  ${BreakPoints.md} {
    text-align: center;
  }
`;

export const Symbol = styled.div`
  border-left: 1px solid ${color.Colors.line};
  width: 20px;
  height: 50%;
  display: flex;
  align-items: center;

  .minus {
    width: 17px;
    height: 17px;
    background: url('/images/icon_minus.png') center / cover no-repeat;
    position: relative;
    left: -8.5px;
  }

  .result {
    width: 17px;
    height: 17px;
    background: url('/images/icon_result.png') center / cover no-repeat;
    position: relative;
    left: -8.5px;
  }

  ${BreakPoints.sm} {
    border-left: 0px none;
    .minus,
    .result {
      margin-bottom: 10px;
      left: 0;
    }
  }
`;

export const Table = styled.div<styleProps>`
  background: #fff;
  width: 400px;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  font-weight: 500;

  ${BreakPoints.md} {
    flex: 1 1 auto;
    ${props => {
      if (props.bottom)
        return css`
          margin-right: 10px;
          margin-bottom: 0;
        `;
    }}
  }

  ${BreakPoints.sm} {
    width: 100%;
    ${props => {
      if (props.bottom)
        return css`
          margin-right: 0;
          margin-bottom: 10px;
        `;
    }}
  }

  ${props => {
    if (props.top)
      return css`
        margin-top: 10px;
      `;
  }}

  ${props => {
    if (props.bottom)
      return css`
        margin-bottom: 10px;
      `;
  }}

  table {
    width: 100%;
    height: 100%;
    text-align: right;
    border-collapse: collapse;
    color: ${color.Colors.primary};

    td:first-child {
      padding-bottom: 6px;
      width: 40%;
      text-align: left;
    }

    td:last-child {
      width: 45%;
    }

    .red {
      color: ${color.Colors.red};
    }

    .total {
      color: ${color.Colors.green};
      font-weight: bold;

      td {
        padding-top: 8px;
        border-top: 1px solid ${color.Colors.line};
      }
    }

    .etc {
      font-weight: bold;

      td {
        border-bottom: 1px solid ${color.Colors.line};
      }
    }

    .etcItem {
      td {
        padding-top: 10px;
      }
    }
  }
`;
