import React, { useState, useEffect } from "react";
import "./index.scss";
import { Link } from "react-router-dom";

interface GOTableProps {
  data: {}[];
  columns: {}[];
  checkbox?: boolean;
  check: any[];
  setCheck: Function;
  id?: boolean;
  link?: string;
}

// MARK : GOTable 테이블 컴포넌트
// NOTE : 이 컴포넌트는

const GOTable = (props: GOTableProps) => {
  const [allCheck, setAllCheck] = useState<boolean>(false);
  const [innerData, setInnerData] = useState<any>([]);

  useEffect(() => {
    if (props.data?.length === props.check?.length) {
      setAllCheck(true);
    } else {
      setAllCheck(false);
    }

    // 알고리즘
    // 임시값에 columns-link에 있는 값만 복사하여 새로운 object 생성
    // 이후 innerData State로 전달
    let temp = props.data;
    let temp2: any[] = [];
    temp.forEach((t: any) => {
      let pu: any = {};
      pu.id = t.id;
      props.columns.forEach((c: any) => {
        pu[c.link] = t[c.link];
      });
      temp2.push(pu);
    });

    setInnerData(temp2);
  }, [props.check, props.data, props.columns]);

  const handleChange = (e: any) => {
    if (e.target.checked) {
      props.setCheck(props.check.concat(e.target.id));
    } else {
      props.setCheck(props.check.filter(d => d * 1 !== e.target.id * 1));
    }
  };

  const allCheckBox = (e: any) => {
    setAllCheck(!allCheck);
    if (props.data) {
      const IID = props.data.map((d: any) => JSON.stringify(d.id));
      if (e.target.checked) {
        props.setCheck(IID);
      } else {
        props.setCheck([]);
      }
    }
  };

  const nowChecked = (e: any) => {
    return props.check.filter((d: any) => d * 1 === e.id).length > 0;
  };

  return (
    <div className="GOTable">
      <table>
        <thead>
          <tr>
            {props.checkbox && (
              <th className="checkbox">
                <input
                  type="checkbox"
                  onChange={allCheckBox}
                  checked={allCheck}
                />
              </th>
            )}
            {props.columns?.map((e: any, index: any) => (
              <th key={index}>{e.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {innerData.map((e: any, index: any) => {
            return (
              <tr
                key={index}
                className={`${props.check &&
                  props.check.filter((d: any): any => d * 1 === e.id).length >
                    0 &&
                  "tr_selected"}`}
              >
                {props.checkbox && (
                  <td>
                    <input
                      id={e.id}
                      type="checkbox"
                      checked={nowChecked(e)}
                      onChange={handleChange}
                    />
                  </td>
                )}
                {Object.entries(e).map((d: any, index) => {
                  if (!props.id && d[0] === "id") {
                    return null;
                  } else {
                    return (
                      <td key={index}>
                        {props.link ? (
                          <Link to={`${props.link}/${e.id}`}>{d[1]}</Link>
                        ) : (
                          d[1]
                        )}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default GOTable;
