import styled from 'styled-components';
import * as color from '../../../color/index';
// import { darken } from 'polished';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const LeftUserlist = styled.div`
  .part {
    display: flex;
    justify-content: space-between;
    padding: 4px;
    transition: 0.35s;
    cursor: pointer;

    &:hover {
      transition: 0.35s;
      background: ${color.Colors.sub};
    }
  }
`;

export const ControlCustomStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${BreakPoints.sm} {
    .GODropbox {
      flex-grow: 1;
    }
  }
`;
