import styled, { css } from 'styled-components';
import * as color from '../../../color/index';

interface labelType {
  normalLabel?: boolean;
  bigLabel?: boolean;
  medium?: boolean;
  small?: boolean;
  xsmall?: boolean;
  xxsmall?: boolean;
}

export const Dropbox = styled.div<labelType>`
  display: flex;
  align-items: center;
  width: 100%;

  ${props => {
    if (props.normalLabel)
      return css`
        margin: 12px 0;
      `;
    if (props.bigLabel)
      return css`
        margin: 8px 0;
      `;
  }}

  ${props => {
    if (props.medium)
      return css`
        width: 237px;
        margin: 0;
      `;
    if (props.small)
      return css`
        width: 130px;
        margin: 8px 0;
      `;
    if (props.xsmall)
      return css`
        width: 114px;
        height: 32px;
        margin: 8px 0;
      `;
    if (props.xxsmall)
      return css`
        width: 74px;
        height: 32px;
        margin: 8px 0;
      `;
  }}
`;

export const DropboxLabel = styled.div`
  word-break: keep-all;
  flex-shrink: 0;
  width: 80px;
  font-size: 14px;
  font-weight: bold;
  color: ${color.Colors.primary};
`;

export const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding-left: 12px;
  border: 2px solid ${color.Colors.line};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  background: url(/images/icon_select_arrow@2x.png) no-repeat 94% 50% / 10px;
  overflow: hidden;
  padding-right: 32px;
  /* text-overflow: ellipsis; */

  option {
    font-weight: normal;
  }
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
`;

export const DropboxNormal = styled.div`
  margin: 8px 0;
`;

export const DropboxBigLabel = styled.div`
  margin: 12px 0;
`;
