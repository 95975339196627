import React, { useEffect, useState } from 'react';
import * as Styled from '../chartAreaStyles';
import { GOLoading } from '../..';
import PieChart from '../Chart/UserConfiguration/Pie';
import DoughnutChart from '../Chart/UserConfiguration/Doughnut';
import BarChart from '../Chart/UserConfiguration/Bar';
import UserSex from '../Convert/UserChart/sex';
import UserAge from '../Convert/UserChart/age';
import UserMix from '../Convert/UserChart/mix';

const SAUserChart = ({ loading, sex, age, sum }: any) => {
  // 성별 데이터 percentage 변환

  const [genderData, setGenderData] = useState<any>({
    labels: [`남성`, `여성`],
    datasets: [
      {
        label: '성별',
        data: [],
        backgroundColor: ['#6AA8E2', '#F5C4DE'],
        hoverOffset: 4,
      },
    ],
  });

  useEffect(() => {
    const sData = UserSex(sex);
    const genderData = {
      labels: [`남성`, `여성`],
      datasets: [
        {
          label: '성별',
          data: [sData?.male, sData?.female],
          backgroundColor: ['#6AA8E2', '#F5C4DE'],
          hoverOffset: 4,
        },
      ],
    };
    setGenderData(genderData);
  }, [sex]);

  const [ageData, setAgeData] = useState<any>({
    labels: [`20대 미만`, `30대`, `40대`, `50대`, `60대 이상`],
    datasets: [
      {
        label: '연령',
        data: [],
        backgroundColor: ['#D196EE', '#7465E7', '#2F76BA', '#204D80', '#173562'],
        hoverOffset: 4,
      },
    ],
  });

  useEffect(() => {
    const aData = UserAge(age);
    const ageData = {
      labels: [`20대 미만`, `30대`, `40대`, `50대`, `60대 이상`],
      datasets: [
        {
          label: '연령',
          data: [aData.count20s, aData.count30s, aData.count40s, aData.count50s, aData.count60s],
          backgroundColor: ['#D196EE', '#7465E7', '#2F76BA', '#204D80', '#173562'],
          hoverOffset: 4,
        },
      ],
    };
    setAgeData(ageData);
  }, [age]);

  const [mixData, setMixData] = useState<any>({
    labels: ['20대 미만', '30대', '40대', '50대', '60대 이상'],
    datasets: [
      {
        label: '남성',
        backgroundColor: '#6AA8E2',
        borderColor: '#6AA8E2',
        borderWidth: 1,
        data: [],
      },
      {
        label: '여성',
        backgroundColor: '#F5C4DE',
        borderColor: '#F5C4DE',
        borderWidth: 1,
        data: [],
      },
    ],
  });

  useEffect(() => {
    const mData = UserMix(sum);
    const mixData = {
      labels: ['20대 미만', '30대', '40대', '50대', '60대 이상'],
      datasets: [
        {
          label: '남성',
          backgroundColor: '#6AA8E2',
          borderColor: '#6AA8E2',
          borderWidth: 1,
          data: mData.maleArr,
        },
        {
          label: '여성',
          backgroundColor: '#F5C4DE',
          borderColor: '#F5C4DE',
          borderWidth: 1,
          data: mData.femaleArr,
        },
      ],
    };
    setMixData(mixData);
  }, [sum]);

  return (
    <Styled.Container>
      {loading ? (
        <GOLoading />
      ) : sex && age && sum ? (
        <>
          <Styled.FlexGroup>
            <PieChart label="성별" data={genderData} />
            <DoughnutChart label="연령" data={ageData} />
          </Styled.FlexGroup>

          <BarChart label="성 x 연령" data={mixData} />
        </>
      ) : (
        <div>데이터가 부족합니다!</div>
      )}
    </Styled.Container>
  );
};

export default SAUserChart;
