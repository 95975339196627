import NetworkManager from "../../../NetworkManager";

const List = (startDate: string, endDate: string) => {
  return new Promise((resolve, reject) => {
    NetworkManager(
      "get",
      `admin/plateAvailability?startDate=${startDate}&endDate=${endDate}`
    )
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default List;
