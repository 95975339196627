import React from 'react';
import { SignBox, SignUpBox } from '../../../components';
import './index.scss';

const SignIn = () => {
  return (
    <div className="sign">
      <SignBox />
      {/*feature/20211026/absentList  가맹점 가입은 별도로 하지 않는다. 회원등록 버튼 삭제 한다.*/}
      {/*<SignUpBox />*/}
      <br />
    </div>
  );
};

export default SignIn;
