import styled from 'styled-components';
import * as color from '../../../color/index';
import { darken } from 'polished';
import { BreakPoints } from '../../../GlobalUtilityStyles';

export const Panel = styled.div`
  position: relative;
  width: 256px;
  flex-basis: 256px;
  flex-grow: 1;
  height: 118px;
  padding: 18px 0 18px 20px;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  margin: 0 4px 8px;
  cursor: pointer;
  transition: 0.35s;
  color: ${color.Colors.primary};
  display: inline-block;

  &:hover {
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.16);
    transition: 0.35s;
    background: ${darken(0.02, `${color.Colors.white}`)};
  }

  hr {
    margin-right: 24px;
  }

  ${BreakPoints.sm} {
    padding: 15px;
  }
  ${BreakPoints.xs} {
    padding: 10px;
  }
`;

export const EmployeePanel = styled.div`
  height: 120px;
`;

export const PanelTop = styled.div`
  display: flex;
  /* height: 54px; */
  height: auto;
`;

export const PanelCircle = styled.div`
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background: #eee;
  margin-right: 12px;
`;

export const PanelInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

export const PanelName = styled.div`
  font-size: 14px;
  font-weight: bold;
  /* margin-top: 6px; */
  margin-top: 0;
`;

export const PanelDetail = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${color.Colors.effect};
`;

export const PanelBottom = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const PanelBottomBox = styled.div`
  display: flex;
`;

export const PanelBottomBoxLeft = styled.div`
  width: 60px;
  color: ${color.Colors.effect};
`;
