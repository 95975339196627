import styled, { css } from 'styled-components';
import * as color from '../../color/index';
import { BreakPoints } from '../../GlobalUtilityStyles';

/*********************************
  * 예약목록보기 팝업 공통 스타일
  - 개인레슨 예약 현황
  - 타석 예약 현황(삭제)
***********************************/

interface StyleProps {
  statusOn?: boolean;
  checkIn?: boolean;
  absent?: boolean;
}

export const Box = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .box_title {
    width: 100px;
    color: #5e6367;
    font-size: 14px;
    font-weight: bold;
    margin-right: 19px;
  }
  .dropbox_wrapper {
    flex: 1 1 auto;
    > div {
      margin-bottom: 0;
    }
  }

  .input_wrapper {
    width: 100px;
    flex: 1 1 auto;
    > div {
      margin-bottom: 0;
    }

    input {
      max-width: initial;
    }
  }
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 15px;
`;

export const ReservationPlateBox = styled.div`
  max-height: 60vh;
  overflow: auto;
`;

export const ReservationPlate = styled.div<StyleProps>`
  margin-bottom: 12px;
  // max-width: 500px;
  height: 55px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  transition: 0.25s;
  color: ${color.Colors.primary};

  h5 {
    font-weight: normal;
  }

  ${props => {
    if (props.checkIn)
      return css`
        border: 1px solid ${color.Colors.green};
        box-shadow: 0 3px 6px rgba(18, 184, 134, 0.3);
        color: ${color.Colors.white};
        background: ${color.Colors.green};
        transition: 0.25s;
      `;
    else if (props.absent)
      return css`
        border: 1px solid ${color.Colors.red};
        box-shadow: 0 3px 6px rgba(249, 83, 84, 0.3);
        color: ${color.Colors.white};
        background: ${color.Colors.red};
        transition: 0.25s;
      `;
    else if (props.statusOn)
      return css`
        transition: 0.25s;
        background: ${color.Colors.sub};
      `;
  }};
`;

export const Num = styled.h3`
  font-size: 20px;
  font-weight: 400;
  width: 40px;
`;

export const ProfileImage = styled.div`
  width: 50px;
  height: 50px;
  margin: 4px;
  margin-right: 24px;
  background: ${color.Colors.background};
  border-radius: 120px;
`;

export const Flex = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  font-weight: bold;

  h6 {
    font-weight: normal;
  }
`;
