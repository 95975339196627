import styled, { keyframes, css } from 'styled-components';
import * as color from '../../../color/index';
import { darken } from 'polished';
import { ContentBodyPadding, BreakPoints } from '../../../GlobalUtilityStyles';
interface boxProps {
  longHeight?: boolean;
  fullWidth?: boolean;
}

export const golayout = keyframes`
  0% {
    opacity: 0;
    left: -20px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
`;

export const LayoutWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // width: 100%;
  max-width: 1368px;
  margin: 0 auto;

  ${BreakPoints.lg} {
    margin: 0 -8px;
  }
  ${BreakPoints.sm} {
    margin: 0;
  }
`;

export const Box = styled.div<boxProps>`
  position: relative;
  flex: 1 1 auto;
  max-width: 100%;
  margin: 0 8px 16px;
  padding: 16px;
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  background: ${color.Colors.white};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  animation: golayout 0.35s;
  overflow: auto;
  animation: ${golayout} 0.35s;
  ${BreakPoints.lg} {
    width: calc(50% - 16px);
    min-width: initial;
  }
  ${BreakPoints.sm} {
    width: 100%;
    margin: 0 0 16px;
    .go_label {
      width: 60px;
    }
  }
  ${props => {
    if (props.longHeight)
      return css`
        height: 600px;
      `;
  }}

  ${props => {
    if (props.fullWidth)
      return css`
        width: 100%;
        max-height: 600px;
        overflow: auto;
      `;
  }}
`;

export const UserDetailProfile = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: space-between;
`;

export const ResetButton = styled.button`
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  margin-left: 50px;
  color: ${color.Colors.primary};
  font-size: 12px;
  font-weight: bold;
  background: ${color.Colors.background};
  padding: 5px 7px;
  cursor: pointer;
  transition: 0.35s;
  outline: none;

  &:hover {
    background: ${darken(0.05, `${color.Colors.background}`)};
    transition: 0.35s;
  }
`;

export const Text = styled.div`
  color: ${color.Colors.primary};
  h5 {
    color: ${color.Colors.primary};
  }
  h4 {
    line-height: 28px;
  }
  h6 {
    margin-bottom: 4px;
  }
`;

export const ConfirmLessonPanel = styled.div`
  background: ${color.Colors.background};
  border: 1px solid ${color.Colors.line};
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  padding: 8px 16px;
  min-height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
  ${BreakPoints.sm} {
    flex-wrap: wrap;
    & > div {
      flex: 1 1 auto;
    }
  }
`;

export const Info = styled.div`
  width: 50%;
  box-sizing: border-box;
  font-weight: bold;
`;

export const Start = styled.div`
  width: 25%;
  font-size: 12px;
  font-weight: bold;
  color: ${color.Colors.primary};
`;

export const End = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${color.Colors.primary};
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  text-align: right;

  .label {
    width: 100%;
  }

  > div + div {
    margin-left: 2px;
  }

  ${BreakPoints.lg} {
    font-size: 14px;
  }

  ${BreakPoints.sm} {
    justify-content: flex-start;
    flex-wrap: nowrap;
    text-align: left;
    width: 100%;
    .label {
      width: auto;
      margin-right: 6px;
    }
  }
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  ${BreakPoints.sm} {
    flex-wrap: wrap;
  }

  .GODropbox {
    margin-bottom: 0;
    margin-right: 4px;
    &:last-child {
      margin-right: 0;
    }

    ${BreakPoints.xs} {
      flex: 1 1 auto;
      width: 100px;
    }
  }

  .checkbox_group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    white-space: nowrap;
    line-height: 2;
    margin-left: 10px;

    ${BreakPoints.sm} {
      margin-left: 0;
      width: 100%;
      margin-top: 10px;
    }
  }

  .inputWrapper {
    display: flex;
    align-items: center;
    color: ${color.Colors.primary};
  }

  .checkLabel {
    margin: 0 10px 0 3px;
  }
`;
