import NetworkManager from "../../../NetworkManager";

const Edit = (pID: number | string, data: any) => {
  return new Promise((resolve, reject) => {
    NetworkManager("put", `admin/plateAvailability/${pID}`, data)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default Edit;
