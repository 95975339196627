import React from 'react';
import * as Styled from './styles';
import { useTranslation } from 'react-i18next';

interface dataType {
  value?: string | number | any[] | any;
  body?: string;
}

interface GODropboxProps {
  data?: dataType[];
  onClick?: () => {};
  name?: string;
  value?: string | number | any[];
  rref?: any;
  error?: any;
  onChange?: any;
  required?: boolean;
  isDefault?: boolean;
  style?: object;
  width?: string;
  height?: string;
  disabled?: boolean;

  label?: string;
  medium?: boolean;
  small?: boolean;
  xsmall?: boolean;
  xxsmall?: boolean;
}

const GODropbox = (props: GODropboxProps) => {
  const { t } = useTranslation();
  return (
    <Styled.Dropbox
      style={props.style}
      normalLabel={!props.label ? true : false}
      bigLabel={props.label ? true : false}
      medium={props.medium ? true : false}
      small={props.small ? true : false}
      xsmall={props.xsmall ? true : false}
      xxsmall={props.xxsmall ? true : false}
      className="GODropbox"
    >
      {props.label && <Styled.DropboxLabel>{props.label}</Styled.DropboxLabel>}
      <Styled.Select
        className={props.error ? 'GODropbox_error' : ''}
        onClick={props.onClick}
        name={props.name}
        value={props.value}
        style={{ width: props.width, height: props.height }}
        ref={props.rref}
        onChange={props.onChange}
        disabled={props.disabled}
        required={props.required}
      >
        {!props.isDefault && <option value="">{t('common.choice')}</option>}
        {props.data?.map((d, index) => {
          if (d?.body) {
            return (
              <option key={index} value={d.value}>
                {d.body}
              </option>
            );
          }
          return null;
        })}
      </Styled.Select>
    </Styled.Dropbox>
  );
};

export default GODropbox;
