import React, { useState } from 'react';
import { GOFloatingbox, GOInput, GOButton, GODropbox, SmallTitle } from '../../../components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { NetEmployeeAdd, NetSendSMS, NetVerifySMS } from '../../../actions';
import GOPostCode from '../../../components/common/GOPostCode';
import GOError from '../../../components/common/GOError';
import { useDispatch } from 'react-redux';
import { actionPutEmployee } from '../../../stores/actions';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

interface AddProps {
  onClickExit?: () => void;
  data?: any;
}
interface addDataType {
  phoneNumber: string;
  phoneNumberCheck: number;
  verificationNumber: string;
  address: any;
}

const EmployeeEdit = (props: AddProps) => {
  const defaultAddData = {
    phoneNumber: '',
    phoneNumberCheck: 0,
    verificationNumber: '',
    address: { roadAddress: '' },
  };
  const [addData, setAddData] = useState<addDataType>(
    props.data.EmployeeEdit
      ? {
          ...props.data.EmployeeEdit,
          phoneNumberCheck: 0,
        }
      : defaultAddData,
  );
  const { register, handleSubmit, errors } = useForm({
    defaultValues: props.data && {
      ...props.data,
      dateOfBirth: props.data.dateOfBirth.split('T')[0],
    },
  });
  const { t } = useTranslation();

  const [post, viewPost] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e: any) => {
    setAddData({
      ...addData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddressChange = (e: any) => {
    setAddData({
      ...addData,
      address: e,
    });
  };

  const onSubmit = (data: any) => {
    if (props.data) {
      // 수정시
      if (addData.phoneNumberCheck === 2 || addData.phoneNumberCheck === 0) {
        dispatch(
          actionPutEmployee(props.data.id, data, () => {
            if (props.onClickExit) {
              props.onClickExit();
            }
          }),
        );
      }
    } else {
      // 등록시
      // if (addData.phoneNumberCheck === 2) {
      //   if (addData.address.roadAddress) {
      NetEmployeeAdd({ ...addData, ...data }).then(res => {
        alert(t('user.addOK'));
        setAddData(defaultAddData);
        if (props.onClickExit) {
          props.onClickExit();
        }
      });
      //   } else {
      //     toast.error("주소를 입력해 주세요.");
      //   }
      // } else {
      //   toast.error(t("sign.registerPhoneError"));
      // }
    }
  };

  const checkPhoneNumber = () => {
    const PhoneVal = /^[0-9]{6,12}/;
    if (PhoneVal.test(addData.phoneNumber)) {
      NetSendSMS({
        type: 2,
        phoneNumber: addData.phoneNumber,
      }).then(res => {
        toast(t('sign.phoneCheckOK'));
        setAddData({ ...addData, phoneNumberCheck: 1 });
      });
    } else {
      toast.error(`${t('sign.phone')} : ${t('sign.phoneError')}`);
    }
  };

  const checkVerifySMS = () => {
    NetVerifySMS({
      type: 2,
      phoneNumber: addData.phoneNumber,
      verificationNumber: addData.verificationNumber,
    }).then(res => {
      toast(t('sign.verifySMSOK'));
      setAddData({ ...addData, phoneNumberCheck: 2 });
    });
  };

  return (
    <GOFloatingbox width={750}>
      <UFloatingContainer as="form" onSubmit={handleSubmit(onSubmit)}>
        <UFloatingBoxHeader>
          <h1>{props.data ? t('employee.edit') : t('employee.add')}</h1>
          <p>{props.data ? t('employee.editInfo') : t('employee.addInfo')}</p>
        </UFloatingBoxHeader>
        <UFloatingBoxBody flex>
          <div className="flex_box">
            <SmallTitle body={t('user.baseInfo')} />
            <GOInput
              label={t('employee.name')}
              name="name"
              rref={register({ maxLength: 8 })}
              error={errors.name}
              required
            />
            {errors.name && <GOError body={`${t('sign.nameErrorMaxLength')}`} />}
            <GODropbox
              label={t('user.sex')}
              name="sex"
              rref={register}
              required
              data={[
                { value: 0, body: t('common.male') },
                { value: 1, body: t('common.female') },
              ]}
            />
            <GOInput
              name="dateOfBirth"
              label={t('user.birthday')}
              type="date"
              rref={register}
              required
            />
            <GOInput
              name="phoneNumber"
              label={t('sign.phone')}
              // button={
              //   addData.phoneNumberCheck !== 2 ? t("common.verify") : undefined
              // }
              type="number"
              rref={register}
              onChange={handleChange}
              onClickButton={checkPhoneNumber}
              disabled={addData.phoneNumberCheck !== 2 ? false : true}
              value={addData.phoneNumber}
              required
            />
            {addData.phoneNumberCheck === 1 && (
              <GOInput
                name="verificationNumber"
                label={t('sign.phoneCheck')}
                button={t('common.ok')}
                type="number"
                onChange={handleChange}
                onClickButton={checkVerifySMS}
                value={addData.verificationNumber}
                required
              />
            )}
          </div>
          <div className="flex_box">
            <SmallTitle body={t('user.etcInfo')} />
            <GOInput
              label={t('user.address')}
              name="address"
              value={addData.address ? addData.address.roadAddress : addData.address}
              button={t('common.search')}
              onClickButton={() => {
                viewPost(true);
              }}
              disabled
            />
            <GOInput label={t('user.password')} name="password" rref={register} />
            {post && (
              <GOPostCode
                onClickExit={() => {
                  viewPost(false);
                }}
                handleChange={handleAddressChange}
              />
            )}
          </div>
        </UFloatingBoxBody>
        <UFloatingBoxFooter>
          <GOButton
            buttontype="green"
            body={props.data ? t('employee.edit') : t('employee.add')}
            type="submit"
          />
          <GOButton body={t('common.no')} onClick={props.onClickExit} />
        </UFloatingBoxFooter>
      </UFloatingContainer>
    </GOFloatingbox>
  );
};

export default EmployeeEdit;
