import styled from 'styled-components';

export const GridList = styled.div`
  padding: 8px 6px;
  display: flex;
  flex-wrap: wrap;

  &::after {
    content: '';
    flex: auto;
  }

  .gridlist-placeholder {
    width: 256px;
    flex-basis: 256px;
    flex-grow: 1;
    height: 0px;
    margin: 0px 4px;
  }
`;
