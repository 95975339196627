import styled from 'styled-components';

export const GridList = styled.div`
  padding: 8px 6px;
  display: flex;
  flex-wrap: wrap;

  &::after {
    content: '';
    flex: auto;
  }
`;

export const Placeholder = styled.div`
  width: 240px;
  flex-basis: 260px;
  flex-grow: 1;
  height: 2px;
  margin: 4px;
`;
