import NetworkManager from "../../../NetworkManager";

const LoadPlate = () => {
  return new Promise((resolve, reject) => {
    NetworkManager("get", `admin/plateAvailability/plate`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {});
  });
};

export default LoadPlate;
