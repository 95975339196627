import React, { useEffect, useState } from 'react';
import * as Styled from '../reservationListStyles';
import GOFloatingbox from '../../common/GOFloatingbox';
import GOButton from '../../common/GOButton';
import { useTranslation } from 'react-i18next';
import {
  NetUserTicketSearch,
  NetLessonUserRes,
  NetLessonDetail,
  NetLessonResCancel,
  NetLessonResCheckIn,
  NetLessonResAbsent,
  NetLessonResRestore,
} from '../../../actions';
import SmallTitle from '../../common/SmallTitle';
import { toast } from 'react-toastify';
import GODropbox from '../../common/GODropbox';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import isSameAfter from 'dayjs/plugin/isSameOrAfter';
import GOLoading from '../../common/GOLoading';
import GOInput from '../../common/GOInput';
import { phoneNumberFormatting } from '../../../middlewares';
import { Link } from 'react-router-dom';
import { GOEmoji } from '../../index';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

interface ListProps {
  id: string;
  onClickExit?: any;
  update?: any;
  employee?: boolean;
}

dayjs.locale('ko');
dayjs.extend(isSameAfter);

const ResPlate = ({
  data,
  onResAbsent,
  onResCancel,
  onResCheckIn,
  onResRestoreCheckIn,
  onResRestoreAbsent,
}: any) => {
  const { t } = useTranslation();

  console.log(data);
  console.log(data.status !== 0);
  console.log(data.startDate);
  console.log(dayjs().isSameOrAfter(data.startDate));
  //예약 시간보다 후에 발생한 기록만 변경시킬수 있다.
  //송도는 35일전까지의 레슨 데이타는 수정가능하게 한다.
  const originDate =
    data.branchID !== null &&
    data.branchID !== undefined &&
    (data.branchID === 0 || data.branchID === 13)
      ? dayjs().subtract(35, 'day')
      : dayjs();

  const isShowRestoreButton =
    data.LessonReservation !== null &&
    data.LessonReservation.status !== 0 &&
    data.startDate &&
    !originDate.isSameOrAfter(data.startDate);

  console.log(isShowRestoreButton);

  const status = () => {
    if (data.LessonReservation) {
      switch (data.LessonReservation.status) {
        case 1:
          return t('reservation.resCheckIn');
        case 2:
          return t('reservation.resAbsent');
      }
    } else {
      switch (data.status) {
        case 0:
          return t('reservation.resUsing');
        case 1:
          return t('reservation.resAbled');
      }
    }
  };
  return (
    <Styled.ReservationPlate
      statusOn={data.status === 0 ? true : false}
      checkIn={data.LessonReservation?.status === 1 ? true : false}
      absent={data.LessonReservation?.status === 2 ? true : false}
    >
      <Styled.Num>01</Styled.Num>
      <Link to={`/admin/user/${data.LessonReservation.User.id}`}>
        <Styled.ProfileImage
          style={{
            background:
              data.LessonReservation?.User?.profileImageURL &&
              `url(${data.LessonReservation?.User?.profileImageURL}) center / cover`,
          }}
        />
      </Link>
      <Styled.Flex>
        {data.status === 0 && data.LessonReservation && (
          <>
            <div>
              <div>
                <Link to={`/admin/user/${data.LessonReservation.User.id}`}>
                  {data.LessonReservation.User.name}{' '}
                  {data.LessonReservation.User?.vaccineUser?.isVaccinated === 1 ? (
                    <GOEmoji symbol="✅" />
                  ) : (
                    <GOEmoji symbol="" />
                  )}
                </Link>
              </div>
              <h6>
                <Link to={`/admin/user/${data.LessonReservation.User.id}`}>
                  {phoneNumberFormatting(data.LessonReservation.User.phoneNumber)}
                </Link>
              </h6>
            </div>
            <Styled.Flex />
            {/* 출석 : 1 , 결석 : 2 */}
            {data.LessonReservation.status === 0 && (
              <>
                <GOButton body="출석" onClick={onResCheckIn} size="xsmall" />
                <GOButton body="결석" onClick={onResAbsent} size="xsmall" />
                <GOButton body="취소" buttontype="red" onClick={onResCancel} size="xsmall" />
              </>
            )}

            {data.LessonReservation.status === 1 && isShowRestoreButton && (
              <>
                <GOButton
                  body="출석 취소"
                  buttontype="red"
                  onClick={onResRestoreCheckIn}
                  size="xsmall"
                />
              </>
            )}
            {data.LessonReservation.status === 2 && isShowRestoreButton && (
              <>
                <GOButton body="결석 취소" onClick={onResRestoreAbsent} size="xsmall" />
              </>
            )}
          </>
        )}
      </Styled.Flex>
      {/*<h5>{status()}</h5>*/}
      {isShowRestoreButton ? (
        <></>
      ) : (
        <>
          {' '}
          <h5>{status()}</h5>{' '}
        </>
      )}
    </Styled.ReservationPlate>
  );
};

const List = ({ id, onClickExit, update, employee }: ListProps) => {
  const { t } = useTranslation();

  const [data, setData] = useState<any>();
  const [searchState, setSearchState] = useState<boolean>(false);
  const [totalUser, setTotalUser] = useState<any>([]);
  const [user, setUser] = useState<any>({ search: '' });
  const [inupdate, isUpdate] = useState(false);

  useEffect(() => {
    NetLessonDetail(id).then((res: any) => setData(res.data.Data.LessonAvailability));
  }, [id, inupdate]);

  const handleChange = (e: any) => {
    if (e.target.name === 'userID') {
      totalUser?.filter((tUser: any) => {
        if (tUser.id === JSON.parse(e.target.value)) {
          setUser({
            ...user,
            name: tUser.name,
            [e.target.name]: e.target.value,
            lessonAvailabilityID: id,
            tickets: tUser.tickets,
          });
        }
      });
    } else setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSearch = () => {
    NetUserTicketSearch(user.search, 0).then((res: any) => {
      const userData = res.data.Data.users;
      if (userData.length > 0) {
        toast(`${userData.length}건이 검색되었습니다.`);
        setTotalUser(userData);
        setSearchState(true);
        setUser({
          ...user,
          userID: 0,
          ticketID: 0,
          lessonAvailabilityID: 0,
          tickets: [],
        });
      } else {
        toast.error('검색 결과가 없습니다.');
        setUser({ search: user.search });
        setSearchState(false);
      }
    });
  };

  const onReservation = (e: any) => {
    e.preventDefault();
    if (window.confirm(`${user.name}\n${t('reservation.isRes')}`)) {
      NetLessonUserRes(user)
        .then(res => {
          isUpdate(!inupdate);
        })
        .catch((err: any) => {});
    }
  };

  const onResCancel = (e: any) => {
    if (window.confirm('예약을 취소하시겠습니까?')) {
      NetLessonResCancel(e).then(res => {
        isUpdate(!inupdate);
      });
    }
  };

  const onResCheckIn = (e: any) => {
    if (window.confirm('출석 처리하시겠습니까?')) {
      NetLessonResCheckIn(e).then(res => isUpdate(!inupdate));
    }
  };

  const onResAbsent = (e: any) => {
    if (window.confirm('결석 처리하시겠습니까?')) {
      NetLessonResAbsent(e).then(res => isUpdate(!inupdate));
    }
  };

  const onResRestoreCheckIn = (e: any) => {
    if (window.confirm('출석 취소 처리하시겠습니까?')) {
      NetLessonResRestore(e).then(res => isUpdate(!inupdate));
    }
  };

  const onResRestoreAbsent = (e: any) => {
    if (window.confirm('결석 취소 처리하시겠습니까?')) {
      NetLessonResRestore(e).then(res => isUpdate(!inupdate));
    }
  };

  return (
    <>
      <GOFloatingbox width={1020}>
        <UFloatingContainer>
          <UFloatingBoxHeader>
            <h1>{t('reservation.personalList')}</h1>
            <p>{t('reservation.personalListInfo')}</p>
          </UFloatingBoxHeader>
          <UFloatingBoxBody flex>
            {data && !data.LessonReservation ? (
              <>
                <div className="flex_box">
                  <SmallTitle body="개인레슨 예약" />
                  <Styled.Box>
                    <div className="box_title">회원검색</div>
                    <div className="input_wrapper">
                      <GOInput
                        placeholder="회원이름"
                        name="search"
                        type="text"
                        value={user.search}
                        onChange={handleChange}
                        onClickButton={onSearch}
                      />
                    </div>
                    <GOButton body="검색" buttontype="gray" onClick={onSearch} />
                  </Styled.Box>
                  {totalUser?.length > 0 && (
                    <form onSubmit={onReservation}>
                      <Styled.Box>
                        <div className="box_title">
                          검색결과: {searchState ? totalUser?.length : 0}건
                        </div>
                        <div className="dropbox_wrapper">
                          <GODropbox
                            name="userID"
                            data={
                              searchState
                                ? totalUser?.map((tUser: any) => {
                                    return {
                                      value: tUser.id,
                                      body: `${tUser.name} / ${tUser.phoneNumber}`,
                                    };
                                  })
                                : null
                            }
                            value={user.userID}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </Styled.Box>
                      <hr />
                      <Styled.Box>
                        <div className="box_title">이용권 선택</div>
                        <div className="dropbox_wrapper">
                          <GODropbox
                            name="ticketID"
                            data={user.tickets?.map((ticket: any) => {
                              const expired: boolean =
                                (dayjs(data.startDate) <=
                                  dayjs(ticket.startDate).subtract(9, 'h') &&
                                  dayjs(data.endDate) <=
                                    dayjs(ticket.startDate).subtract(9, 'h')) ||
                                (dayjs(data.startDate) >= dayjs(ticket.endDate).add(15, 'h') &&
                                  dayjs(data.endDate) >= dayjs(ticket.endDate).add(15, 'h'));
                              return {
                                value: ticket.id,
                                body: `${expired ? '[ 기간 외 이용권 ] ' : ' '}${
                                  ticket.name
                                } / ${dayjs(ticket.startDate).format('MM. DD')} ~ ${dayjs(
                                  ticket.endDate,
                                ).format('MM. DD')} / ${
                                  ticket.isUnlimited
                                    ? t('product.unlimited')
                                    : `${t('product.remainingCount')} : ${ticket.remainingCount}`
                                }`,
                              };
                            })}
                            value={user.ticketID}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </Styled.Box>
                      <Styled.ButtonWrapper>
                        <GOButton body="예약" buttontype="green" type="submit" />
                      </Styled.ButtonWrapper>
                    </form>
                  )}
                </div>
                <div className="flex_box">
                  <SmallTitle
                    body={`${data.name} | 
     ${dayjs(data.startDate).format('YYYY.MM.DD a hh:mm')} ~ ${dayjs(data.endDate).format(
                      'a hh:mm',
                    )}`}
                  />
                </div>
              </>
            ) : (
              <div className="flex_box">
                {data?.LessonReservation ? (
                  <>
                    <SmallTitle
                      body={`${data.name} | 
     ${dayjs(data.startDate).format('YYYY.MM.DD a hh:mm')} ~ ${dayjs(data.endDate).format(
                        'a hh:mm',
                      )}`}
                    />
                    <Styled.ReservationPlateBox>
                      <ResPlate
                        data={data}
                        onResAbsent={() => onResAbsent(data.LessonReservation.id)}
                        onResCancel={() => onResCancel(data.LessonReservation.id)}
                        onResCheckIn={() => onResCheckIn(data.LessonReservation.id)}
                        onResRestoreAbsent={() => onResRestoreAbsent(data.LessonReservation.id)}
                        onResRestoreCheckIn={() => onResRestoreCheckIn(data.LessonReservation.id)}
                      />
                    </Styled.ReservationPlateBox>
                  </>
                ) : (
                  <GOLoading />
                )}
              </div>
            )}
          </UFloatingBoxBody>
          <UFloatingBoxFooter>
            <GOButton
              body={t('common.no')}
              onClick={() => {
                onClickExit();
                update();
              }}
              noMarginLeft
            />
          </UFloatingBoxFooter>
        </UFloatingContainer>
      </GOFloatingbox>
    </>
  );
};
export default List;
