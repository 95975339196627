import NetworkManager from "../../../NetworkManager";
import { toast } from "react-toastify";

const TicketPauseAdd = (data: object, id: any) => {
  return new Promise((resolve, reject) => {
    NetworkManager("post", `admin/user/pauses/${id}`, data)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
      });
  });
};

export default TicketPauseAdd;
