import NetworkManager from '../../../NetworkManager';
import { toast } from 'react-toastify';

const UserTicketSearch = (keyword: string, ticketType: number = 99) => {
  return new Promise((resolve, reject) => {
    if (keyword) {
      NetworkManager(
        'get',
        `admin/user/ticket/search/users?keyword=${keyword}&ticketType=${ticketType}`,
      )
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {});
    } else {
      toast.error('검색어가 없습니다.');
    }
  });
};

export default UserTicketSearch;
