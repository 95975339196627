import React, { useState, useEffect } from 'react';
import {
  GOFloatingbox,
  SmallTitle,
  GOInput,
  GOSwitch,
  GODropbox,
  GOButton,
} from '../../../components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actionPutUserTicketDetail } from '../../../stores/actions';
import { NetEmployeeList } from '../../../actions';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import {
  UFloatingContainer,
  UFloatingBoxHeader,
  UFloatingBoxFooter,
  UFloatingBoxBody,
} from '../../../GlobalUtilityStyles';

interface ticketEditProps {
  data?: any;
  onClickExit?: () => void;
}

const TicketEdit = ({ data, onClickExit }: ticketEditProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [ticketData, setTicketData] = useState<any>({
    ...data.data,
    // startDate: data.data.startDate,
    // endDate: data.data.endDate,
  });
  const [employeeList, setEmployeeList] = useState([{ id: 0, name: '', type: 0 }]);

  const employeeListFilter = employeeList.filter((e: any) => e.type === 2);
  const handleDate = data.handleDate;

  const goEdit = () => {
    handleDate(data.startDate, ticketData.period, ticketData);
    if (data.update) {
      data.update();
    }
    if (onClickExit) {
      onClickExit();
    }
  };

  const handleChange = (e: any) => {
    setTicketData({ ...ticketData, [e.target.name]: e.target.value });
  };

  const ticketHandleChange = (e: any) => {
    setTicketData({
      ...ticketData,
      [e.target.name]: e.target.value,
      remainingCount: e.target.value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (ticketData.endDate > ticketData.startDate) {
      if (window.confirm('수정하시겠습니까?')) {
        dispatch(
          actionPutUserTicketDetail(ticketData.id, ticketData, () => {
            if (onClickExit) {
              onClickExit();
            }
          }),
        );
      }
    } else {
      toast.error(t('sign.EndDateBiggerStartDateError'));
    }
  };

  useEffect(() => {
    if (!data.sales) {
      NetEmployeeList().then((res: any) => setEmployeeList(res.data.Data.Employees));
    }
  }, [data.sales]);

  return (
    <GOFloatingbox width={750}>
      {data.setData ? (
        <>
          <UFloatingContainer>
            <UFloatingBoxHeader>
              <h1>이용권 정보 수정</h1>
              <p>포함 이용권 정보 수정</p>
            </UFloatingBoxHeader>
            <UFloatingBoxBody flex>
              <div className="flex_box">
                <SmallTitle body="기간 수정" />
                <GOInput
                  label="기간"
                  name="period"
                  unit="일"
                  type="number"
                  min="0"
                  max="999"
                  value={ticketData.period}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex_box">
                <SmallTitle body="일반 이용권" />
                <GOSwitch
                  label="무제한 여부"
                  status={ticketData.isUnlimited}
                  onClick={() => {
                    setTicketData({
                      ...ticketData,
                      isUnlimited: !ticketData.isUnlimited,
                    });
                  }}
                />
                <GOInput
                  label="전체횟수"
                  type="number"
                  unit="번"
                  min="0"
                  max="999"
                  name="totalCount"
                  value={ticketData.totalCount}
                  onChange={ticketHandleChange}
                  disabled={ticketData.isUnlimited}
                />
                <GOSwitch
                  label={t('product.onlyOnceToday')}
                  status={ticketData.onlyOnceToday}
                  onClick={() => {
                    setTicketData({
                      ...ticketData,
                      onlyOnceToday: !ticketData.onlyOnceToday,
                    });
                  }}
                />
              </div>
            </UFloatingBoxBody>
            <UFloatingBoxFooter>
              <GOButton
                body={t('common.edit')}
                buttontype="green"
                type={'submit'}
                onClick={data.setData && goEdit}
              />
              <GOButton
                body={t('common.no')}
                onClick={() => {
                  if (onClickExit) {
                    onClickExit();
                  }
                }}
              />
            </UFloatingBoxFooter>
          </UFloatingContainer>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <UFloatingBoxHeader>
              <h1>이용권 정보 수정</h1>
              <p>포함 이용권 정보 수정</p>
            </UFloatingBoxHeader>
            <UFloatingBoxBody flex>
              <div className="flex_box">
                <SmallTitle body="날짜 수정" />
                <GOInput
                  label="시작 날짜"
                  name="startDate"
                  type="date"
                  value={dayjs(ticketData.startDate).format('YYYY-MM-DD')}
                  onChange={handleChange}
                  required
                />
                <GOInput
                  label="종료 날짜"
                  name="endDate"
                  type="date"
                  value={dayjs(ticketData.endDate).format('YYYY-MM-DD')}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex_box">
                <SmallTitle body="일반 이용권" />
                <GOSwitch
                  label="무제한 여부"
                  status={ticketData.isUnlimited}
                  onClick={() => {
                    setTicketData({
                      ...ticketData,
                      isUnlimited: !ticketData.isUnlimited,
                    });
                  }}
                />
                <GOInput
                  label="잔여횟수"
                  type="number"
                  unit="번"
                  min="0"
                  max="999"
                  name="remainingCount"
                  value={ticketData.remainingCount}
                  onChange={handleChange}
                  disabled={ticketData.isUnlimited}
                />
                <GOSwitch
                  label={t('product.onlyOnceToday')}
                  status={ticketData.onlyOnceToday}
                  onClick={() => {
                    setTicketData({
                      ...ticketData,
                      onlyOnceToday: !ticketData.onlyOnceToday,
                    });
                  }}
                />
                {ticketData.type === 0 ? (
                  <>
                    <GODropbox
                      name="employeeID"
                      label={t('product.employee')}
                      data={[
                        ...employeeListFilter.map((el: any) => {
                          return { body: el.name, value: el.id };
                        }),
                      ]}
                      onChange={handleChange}
                      value={ticketData.employeeID}
                      required
                    />
                  </>
                ) : (
                  <>
                    <GODropbox
                      name="employeeID"
                      label={t('product.employee')}
                      data={[
                        ...employeeList.map((el: any) => {
                          return { body: el.name, value: el.id };
                        }),
                      ]}
                      onChange={handleChange}
                      value={ticketData.employeeID}
                      required
                    />
                  </>
                )}
              </div>
            </UFloatingBoxBody>
            <UFloatingBoxFooter>
              <GOButton
                body={t('common.edit')}
                buttontype="green"
                type={data.setData ? 'button' : 'submit'}
                onClick={data.setData && goEdit}
              />
              <GOButton
                body={t('common.no')}
                onClick={() => {
                  if (onClickExit) {
                    onClickExit();
                  }
                }}
              />
            </UFloatingBoxFooter>
          </form>
        </>
      )}
    </GOFloatingbox>
  );
};

export default TicketEdit;
