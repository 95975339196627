import dayjs from 'dayjs';

export function convertToDayWithNumber(dayIndex: number) {
  switch (dayIndex) {
    case 1:
      return `월요일`;
    case 2:
      return `화요일`;
    case 3:
      return `수요일`;
    case 4:
      return `목요일`;
    case 5:
      return `금요일`;
    case 6:
      return `토요일`;
    case 7:
      return `일요일`;
    default:
      return `월요일`;
  }
}

export function makeYearDataForComboBox(
  // maxYear: number = dayjs().add(1,'year').year(),
  maxYear: number = dayjs().year(),
  minYear: number = 2021,
) {
  const size = maxYear - minYear + 1;
  if (size < 1) {
    return [
      { body: '2021년', value: 2021 },
      { body: '2022년', value: 2022 },
    ];
  } else {
    return Array.from({ length: size }, (_, i) => ({
      body: `${minYear + i}년`,
      value: minYear + i,
    }));
  }
}

export function makeMonthlyExamIDForComboBox() {
  const currentYear = dayjs().year();
  const maxYear: number = Number(currentYear.toString().slice(-2));

  const currentMonth = dayjs().month() + 1;
  const yearMonth = [];

  for (let month = 1; month < currentMonth + 1; month++) {
    if (month < 10) {
      yearMonth.push({ body: `${maxYear}년 ${month}월`, value: Number(`${currentYear}0${month}`) });
    } else {
      yearMonth.push({ body: `${maxYear}년 ${month}월`, value: Number(`${currentYear}${month}`) });
    }
  }
  return yearMonth;
}
