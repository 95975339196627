import React, { useState, useEffect } from 'react';
import * as Styled from './styles';
import {
  HeaderBottom,
  StatusTool,
  GOLayoutList,
  GOButton,
  Footer,
  GOLoading,
  UserGridView,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { NetUserSearch } from '../../../actions';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onModal } from '../../../stores/modals';

interface ParamsType {
  keyword: string;
}

const User = () => {
  const { t } = useTranslation();
  const [check, setCheck] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [userData, setUserData] = useState<any[]>([]);
  const [checkUserData, setCheckUserData] = useState<any>([]);

  let noticeSMS = window.localStorage.getItem('noticeSmsStatus');

  const params = useParams<ParamsType>();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let checkList: any = [];
    if (check.length > 0) {
      userData?.forEach((user: any) => {
        check?.forEach((c: any) => {
          if (user.id === parseInt(c)) {
            checkList.push(user);
          }
        });
      });
    }
    if (checkList.length > 0) {
      setCheckUserData(checkList);
    }
  }, [userData, check]);

  useEffect(() => {
    NetUserSearch(params.keyword).then((res: any) => {
      if (res.data && res.data.Data.users) {
        setUserData(res.data.Data.users);
      } else {
        setUserData([null]);
      }
      setCheck([]);
      setLoading(false);
    });
  }, [params.keyword]);

  const searchView = () => {
    if (userData[0] !== null) {
      return (
        <UserGridView
          setCheck={setCheck}
          check={check}
          data={userData}
          totalCount={userData.length}
        />
      );
    } else {
      return (
        <Styled.NoSearch>
          <h4>"{params.keyword}" 검색결과가 없습니다.</h4>
          <hr />
        </Styled.NoSearch>
      );
    }
  };

  return (
    <>
      <HeaderBottom
        status={
          <>
            <StatusTool link="/admin" type="prev" body={t('main-menu.home')} />
            <StatusTool type="arrow" />
            <StatusTool type="prev" body={t('user.search')} />
            <StatusTool type="arrow" />
            <StatusTool type="now" body={params.keyword} />
          </>
        }
        rightmenu={<></>}
      />
      <GOLayoutList
        leftbody={
          <>
            <h4>회원 검색</h4>
            <hr />
            <Styled.TotalUser onClick={() => history.push('/admin/user/grid')}>
              전체 회원
            </Styled.TotalUser>
          </>
        }
        rightbody={loading ? <GOLoading /> : searchView()}
      />
      {check.length > 0 && (
        <Footer
          body={
            <>
              <GOButton body="푸시 메시지" onClick={() => dispatch(onModal('userPush', check))} />
              {noticeSMS === 'true' && (
                <GOButton
                  body="문자 메시지"
                  onClick={() => dispatch(onModal('userSMS', { check, checkUserData }))}
                />
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default User;
